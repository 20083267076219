import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'Widgets/Modal.jsx';
import Message from 'Widgets/Message.jsx';

import { createChart } from '../actions';


class DatasetsModal extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			hasChart: (props.chart.leftDatasets !== undefined),
			showHelpText: false,
			err: ''
		};
	}

	handleChangeLeftYAxisLabel(e) {
		e.preventDefault();
		e.stopPropagation();
		
		const newLeftYAxisLabel = e.target.value;

		// Stop if the value hasn't changed
		if (newLeftYAxisLabel === e.target.defaultValue) {
			return;
		}

		this.props.updateChart(this.props.itemAnalysis.itemAnalysisID, this.props.chart.leftDatasets, newLeftYAxisLabel);
	}
	
	handleAddDataset(e) {
		const {chart, createDataset, createChart} = this.props;
		const itemAnalysisID = this.props.itemAnalysis.itemAnalysisID
		const fileInput = e.target;
		const file = e.target.files[0];
		const truncatedFilename = file.name.substring(0,22); // The max-length for dataset titles is set both here and also on the input in renderDatasets()

		var reader = new FileReader();
		var csv = '';

		reader.readAsText(file, "UTF-8");
		reader.onload = (e) => {
			csv = e.target.result;

			if (this.state.hasChart === true) {
				createDataset(itemAnalysisID, csv, truncatedFilename);
			} else {
				createChart(itemAnalysisID, csv, truncatedFilename);
				this.setState({hasChart: true}); // Is this a problem with the asynchronous API call?
			}
		};
		reader.onerror = (e) => {
			console.log("error reading file");
			this.setState({err: "There was an error reading the dataset file."});
		};
		fileInput.value = ''; // Clears the file input, so you can choose a new file.
	}

	handleChangeDatasetTitle(targetDatasetID, e) {
		e.preventDefault();
		e.stopPropagation();

		const {chart} = this.props;
		const newDatasetTitle = e.target.value;

		// Stop if the title value hasn't changed
		if (newDatasetTitle === e.target.defaultValue) {
			return;
		}

		const updatedLeftDatasets = _.mapValues(chart.leftDatasets, (dataset, datasetID) => {
			return (targetDatasetID === datasetID) ? Object.assign(dataset, {dsTitle: newDatasetTitle}) : dataset;
		});

		this.props.updateChart(this.props.itemAnalysis.itemAnalysisID, updatedLeftDatasets, chart.leftYAxisLabel);
	}

	handleDeleteDataset(datasetID, e) {
		e.preventDefault();
		e.stopPropagation();

		const {chart} = this.props;

		const updatedLeftDatasets = _.omit(chart.leftDatasets, datasetID);

		this.props.updateChart(this.props.itemAnalysis.itemAnalysisID, updatedLeftDatasets, chart.leftYAxisLabel);
	}

	toggleHelpTextVisibility(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({showHelpText: !this.state.showHelpText});
	}

	render() {
		const {loading, colorScale, chart, selectedItem} = this.props;

        /** TODO: Should be based on itemAnalysis  */
		if (selectedItem === undefined) return null;

		// Kind of misusing the 'size' prop on modal
		const modalClassName = classNames({
			'modal-md': true,
			'has-chart': this.state.hasChart,
			'no-chart': !this.state.hasChart,
			'loading': loading
		});

		return (
			<Modal size={modalClassName}>
				<div className="modal-header">
                	<div className="modal-subtitle">Data Visualization Settings</div>
                	<div className="modal-title">{selectedItem.title}</div>
				</div>
				<div className="modal-body">
					{this.renderLeftYAxisLabel()}
					{this.renderError()}
					<div className="row data-list-container">
						<div className="col-12">
							<ul className="list-group">
								{this.renderDatasets()}
								<li className="list-group-item" style={{borderBottom: 'none', marginTop: '1em'}} >
									<label htmlFor='add-file-input'>Add dataset:</label>
									<input
										type="file"
										id="add-file-input"
										disabled={loading}
										onChange={this.handleAddDataset.bind(this)}
									/>
								</li>
							</ul>
						</div>
						{this.renderHelpText()}
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={this.context.closeModal} >
						Done
					</button>
				</div>
			</Modal>
		);
	}

	renderError() {
		// TODO: Update to this.state.err when I figure out the error handling system.

		if (!this.props.err) {
			return;
		}

		return (
			<div className="row messages-container">
				<Message message={this.props.err} textClass='danger' dismissable={false} />
			</div>
		);
	}

	renderLeftYAxisLabel() {
		const {loading, chart} = this.props;
		
		// Don't display chart property editing if there's no chart.
		if (!this.state.hasChart) {
			return;
		}
		
		return (
			<div className="row data-list-container" style={{paddingTop: '0em'}}>
				<div className="col-12" >
					<label htmlFor="left-y-axis-label">Y Axis Label</label>
					<input
						defaultValue={this.props.chart.leftYAxisLabel || ''}
						disabled={loading}
						id="left-y-axis-label"
						onBlur={this.handleChangeLeftYAxisLabel.bind(this)}
						maxLength="22"
						name="leftYAxisLabel"
						placeholder="none"
						type="text"
						style={{marginBottom: '.5rem'}}
					/>
				</div>
			</div>
		);
	}

	renderDatasets = () => {
		const {loading, colorScale, chart} = this.props;

		// If there's no chart, render nothing
		if (!this.state.hasChart) {
			return;
		}

		const datasetIDs = (chart.leftDatasets !== undefined) ? Object.keys(chart.leftDatasets) : [];
		
		// If there are no datasets, show a friendly message
		if (datasetIDs.length === 0) {
			return;
		}

		const renderedDatasets = Object.values(_.mapValues(chart.leftDatasets, (dataset, datasetID) => {
			
			// We need the index so we can assign the correct color
			const index = datasetIDs.indexOf(datasetID);
			
			return (
				<li className="list-group-item" id={datasetID} key={datasetID}>
					<div>
						<span
							style={{
								backgroundColor: colorScale[ index % colorScale.length ],
								borderRadius: '1em',
								display: 'inline-block',
								height: '1em',
								marginRight: '.8em',
								width: '1em',
							}}
						/>
						<input
							defaultValue={dataset.dsTitle}
							disabled={loading}
							id={`dataset-input-${datasetID}`}
							name={`dataset_${datasetID}`}
							maxLength="22"
							onBlur={this.handleChangeDatasetTitle.bind(this, datasetID)}
							type="text"
							style={{borderBottom: '0rem'}}
						/>
					</div>
					<i
						className="far fa-times delete"
						onClick={this.handleDeleteDataset.bind(this, datasetID)}
					/>
				</li>
			);
		}));

		return renderedDatasets;
	}

	renderHelpText = () => {
		const helpIconClassName = classNames({
			'far': true,
			'fa-info-circle': !this.state.showHelpText,
			'fa-chevron-up': this.state.showHelpText
		});

		return (
			<div className="col-12 list-over-flow">
				<h6 id="help-title" style={{ padding: 0}}
					className={this.state.showHelpText ? "help-text-shown" : "help-text-hidden"}
					onClick={this.toggleHelpTextVisibility.bind(this)}>
					Help with dataset files
					<i className={helpIconClassName} />
				</h6>
				<div id="help-text"
					className={this.state.showHelpText ? "help-text-shown" : "help-text-hidden"}>
					<p>You can add data to the visualization by uploading a CSV file for each line you'd like to see on the graph.</p>
					<p>CSV requirements:</p>
					<ul>
						<li>Must be two columns</li>
						<li>The first column represents elapsed seconds (to match with the media item)</li>
						<li>The first column must have a header of "Time code"</li>
						<li>The second column represents the Y value you'd like graphed against time</li>
					</ul>
				</div>
			</div>			
		);
	};

}

function mapStateToProps(state) {
	return {
		selectedItem: state.folderItems.selectedItem,
		itemAnalysis: state.folderItems.selectedItemAnalysis,
		chart: state.itemAnalysisCharts.data,
		loading: state.itemAnalysisCharts.loading,
		messages: state.messages,
		err: state.itemAnalysisCharts.error // TODO: Figure out if I'm doing this wrong
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetsModal);