import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { INVITE_STATUS } from 'Constants/app';
const { ACCEPTED, INVITED, LEFT } = INVITE_STATUS

import Organization from './Organization.jsx'

class OrganizationsInfo extends Component {

	render () {
		const { isOrganization, organizations } = this.props

		// Loading || no organizations
		if (_.isEmpty(organizations)) return null

		let organizationsList = [];
		let nonDefaultOrganizations = []

		Object.keys(organizations).some((organizationID) => {
			const organization = organizations[organizationID]
			const status = organization.status
			const isDefaultOrganization = this.props.organizationID === organizationID;

			/**
			 * Only show LEFT orgs if dateLeft within last 5 mins (300000	milliseconds)
			 */
			if (ACCEPTED === status || INVITED === status ||
				(LEFT === status && (new Date()).getTime() - 300000 <= organization.dateLeft)) {
				
				
				let activeList = isDefaultOrganization ? organizationsList : nonDefaultOrganizations

				activeList.push(
					<Organization
						key={organizationID}
						isDefaultOrganization={isDefaultOrganization}
						isOrganization={isOrganization}
						organizationID={organizationID}
						organization={organization}
					/>
				)
			}
		})

		// Only revoked organizations remain
		if (0 === organizationsList.length && 0 === nonDefaultOrganizations.length) return null
		
		organizationsList = [
			<div className="row settings-div-row" key='title'>
				<div className="col-12">
					<h5>Your Organizations</h5>
				</div>
			</div>,
			...organizationsList,
			...nonDefaultOrganizations
		]

		return organizationsList ? organizationsList : null
	}

}

function mapStateToProps (state) {
	const { account } = state;
	return {
		isOrganization: account.isOrganization || false,
		organizations: account.organizations || {},
		organizationID: account.organizationID || null,
	};
}

export default connect(mapStateToProps, {})(OrganizationsInfo);