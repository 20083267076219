import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { INVITE_STATUS } from 'Constants/app';
import { inviteMember, revokeMember } from './actions/index';
import { openModal } from 'actions/app'
import BtnIcon from 'Widgets/ButtonIcon.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';

class MemberSettingsDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false,
			loading: false
		};
	}

	render() {
		const { showDropdown } = this.state;
		return (
			<div className="dropdown show contextMenu" onMouseLeave={() => this.handleDDLeave()}>
				<BtnIcon
					disabled={this.props.disabled}
					onClick={() => this.setState({ showDropdown: !showDropdown })}
					tooltip=""
					btnClass="btn btn-link"
					iconClass="far fa-ellipsis-h"
				/>
				{showDropdown && this.renderedButtons()}
			</div>
		);
    }
    
	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
        let {loading} = this.state;
        let {status} = this.props.member;
        const disable = (
            <MenuBtn
                label="Deactive membership"
				//title={"Disable user"}
                disabled={loading}
				onClick={e => { this.onActivationToggleClick(e, true) }}
				iconClass={ this.state.loading ? 'spinner fa-spin' : 'user-times text-danger'}
            />
		);

        const enable = (
            <MenuBtn
                label="Activate membership"
				//title={"Enable user"}
                disabled={loading}
				onClick={e => { this.onActivationToggleClick(e, false) }}
				iconClass={ this.state.loading ? 'spinner fa-spin' : 'user-plus text-success'}
            />
        );
        
		const info = (
            <MenuBtn
                label="Details"
				title={"Details"}
				onClick={e => { alert('info clicked') }}
				iconClass="person-sign text-primary"
            />
		);
        
		// renders all the options in a dropdown menu
		return (
			<div className="dropdown-menu dropdown-menu-right">
				{(status === INVITE_STATUS.LEFT ||
					status === INVITE_STATUS.DECLINED ||
					status === INVITE_STATUS.REVOKED )
						? enable 
						: disable }
				{/* {info} */}
			</div>
		);
    };

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
    };

    /** Revokes/Invites membership access for members & invitees */
    onActivationToggleClick = (e, deactivate = true ) => {
		e.preventDefault();
		e.stopPropagation();
		if( this.props.disabled ) return;
		
        this.setState({ loading: true });

        let activationFunction = deactivate ? this.props.revokeMember : this.props.inviteMember;

		activationFunction(this.props.member)
			.then(data => {
                this.setState({ loading: false });
                this.handleDDLeave();
			})
			.catch(err => {
                this.setState({ loading: false });
                this.handleDDLeave();
            })
    };
}

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, { inviteMember, revokeMember, openModal })(
	MemberSettingsDropdown
);
