import React, { Component } from 'react';
import PropTypes from 'prop-types'

import Modal from './Modal.jsx'

const ModalInfo = ({title, info, closeText}, context) => (
    <Modal size="modal-md">
        <div className="modal-header modal-header-edit">
            <div className="modal-title">{title}</div>
        </div>
        <div className="modal-body" style={{padding: '1rem 4rem'}}>
            <p>{info}</p>
        </div>
        <div className="modal-footer modal-footer-edit">
            <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={context.closeModal}>
                { closeText || "Close" }
            </button>
        </div>
    </Modal>
)

ModalInfo.contextTypes = {
    closeModal: PropTypes.func,
};

export default ModalInfo;
