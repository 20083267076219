import { BANDWIDTH_TEST } from '../config.js';

let QUEUE_SIZE = 1;
let UPLOAD_SPEED = 0;
const GOOD_CONNECTION = 300; // don't believe this calculates speed, thinks its more so latency.
// const GOOD_CONNECTION = 1000; //~4g+

const testConnection = cb => {
	const xhr = new XMLHttpRequest();
	xhr.onreadystatechange = function() {
		if (xhr.readyState === 4 && xhr.status === 200) {
			const endTime = new Date().getTime();
			const duration = (endTime - startTime) / 1000;

			const fileSize = xhr.responseText.length;
			const bitsLoaded = fileSize * 8;

			const speedBps = Math.floor(bitsLoaded / duration);
            const speedKbps = Math.floor(speedBps / 1024);
            UPLOAD_SPEED = speedKbps;

			switch (true) {
				case speedKbps <= 100:
					QUEUE_SIZE = 1;
					break;
				case speedKbps > 100 && speedKbps <= 150:
					QUEUE_SIZE = 2;
					break;
				case speedKbps > 150 && speedKbps <= 200:
					QUEUE_SIZE = 3;
					break;
				case speedKbps > 250:
					QUEUE_SIZE = 4;
					break;
				default:
					QUEUE_SIZE = 2;
			}

			console.log(`SGQ: ${speedKbps}/${GOOD_CONNECTION}/${QUEUE_SIZE}`); // Print out speed / good connection / queue size
			cb(QUEUE_SIZE);
		}
	};
	const startTime = new Date().getTime();
	xhr.open('GET', `${BANDWIDTH_TEST}?${Math.random()}`, true);
    xhr.send();
    
    return {
        
    }
};

export const getBandwidth = cb => testConnection(cb);
export const getQueueSize = () => QUEUE_SIZE;
export const getUploadSpeed = () => UPLOAD_SPEED;
