import React, { Component } from 'react';
import PropTypes from 'prop-types'

const Modal = (props, context) => (
	<div onDoubleClick={(e) => e.stopPropagation()}>
		<div className="modal-backdrop fade in" />
		<div
			id="generalModals"
			className="modal fade in"
			role="dialog"
			onClick={props.forceCloseButtonUse ? null : context.closeModal}>
			<div className={`modal-dialog ${props.size}`}>
				<div
					className="modal-content"
					onClick={
						// needed in order to have the modal close correctly.
						(e) => e.stopPropagation()}>

					{props.children}
					
				</div>
			</div>
		</div>
	</div>
)

Modal.contextTypes = {
    closeModal: PropTypes.func,
};

export default Modal;
