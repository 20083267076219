
import classNames from 'classnames'
import React, {Component} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func.isRequired,
	step: PropTypes.number,
	tabIndex: PropTypes.bool
};

const ALLOWED_KEYCODES = [
	8,		// backspace
	9,		// tab
	37,		// left arrow
	39,		// right arrown
	38,		// up arrow - increments
	40,		// down arrow - decrements
	46		// delete
]

const NUMERIC_KEYCODES = [48,49,50,51,52,53,54,55,56,57,190]; // 0-9 and fullstop . for decimal 

class Numbers extends Component {
	constructor(props) {
		super(props);
		this.state = {previousValue: this.props.defaultValue || ''}
	}

	render() {
		const {
			autoFocus,
			disabled, 
			defaultValue, 
			min,
			max,
			name, 
			onBlur,
			onClick,
			step,
			style,
			tabIndex,
		} = this.props;
		
		const classes = classNames({
			'form-numbers': true,
			'disabled': disabled,
		});

		return (
			<input 
				autoFocus={autoFocus}
				className={classes}		
				disabled={disabled}
				defaultValue={defaultValue} 
				id={name}
				max={max} 
				min={min} 
				name={name}
				onBlur={onBlur}
				onClick={onClick}
				onKeyDown={ disabled ? null : e => this.handleOnKeyDown(e)}
				onKeyUp={ disabled ? null : e => this.handleOnKeyUp(e)}
				ref={name} 
				step={step || 1}
				style={style}
				tabIndex={tabIndex}
			/>
		)
	}
	
    handleOnKeyDown = (e) => {
		const keyCode = e.keyCode;
		
		// Allowed keys - see ALLOWED_KEYCODES declaration for details
		if( ALLOWED_KEYCODES.includes(keyCode) ) return;

		// isEnter?
		if (keyCode == 13) {
			this.refs[this.props.name].blur();
		}

		// Continue only if a number, otherwise prevent prinitng of keycode and return
		if( !NUMERIC_KEYCODES.includes(keyCode) ) {
			e.preventDefault();
			return;
		}

		if( '0.01' !== e.target.step && keyCode === 190 ) { //DECIMAL_KEYCODE
			e.preventDefault();
			return;
		}

		this.setState({previousValue: e.target.value})
	}

	handleOnKeyUp = (e) => {
		// Check Max/Min - duplicate behaviour of using the input counter/arrows a
		const keyCode = e.keyCode;

		// Ignore Allowed keys - see ALLOWED_KEYCODES declaration for details and isEnter?
		if (keyCode == 13 || ALLOWED_KEYCODES.includes(keyCode) || !NUMERIC_KEYCODES.includes(keyCode)) return;

		const { max, min, step } = e.target;

		// Limit to 2 decimal points 
		if('0.01' === step) {
			// e.target.value = parseFloat(e.target.value).toFixed(2);
			var val = e.target.value
			val = val.slice(0, (val.indexOf("."))+3); 
			e.target.value = val;
		}

		const valueAsNumber = e.target.value*1;
		

		if ( valueAsNumber > max*1 )  {
			e.target.value = this.state.previousValue;
			e.preventDefault();
		}
		else if(valueAsNumber < min*1) {
			e.target.value = this.state.previousValue;
			e.preventDefault();
		}

	}
}

Numbers.propTypes = propTypes;

export default Numbers;
