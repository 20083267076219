import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const DashboardsMenuBtn = ({ handleOnClick, locked }) => (
	<MenuBtn
		label={I18n.get('Dashboards')}
		title={locked ? I18n.get('Remove lock to manage Dashboards') : I18n.get('Manage Dashboards')}
		disabled={locked}
		onClick={e => { handleOnClick(e, 'modalDashboardSelector');
		}}
		iconClass="bullseye-pointer text-primary"
	/>
);

export default DashboardsMenuBtn;