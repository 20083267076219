import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import IntegrationLti from './Integration.Lti.jsx'
import IntegrationExistingLti from './Integration.ExistingLti.jsx'
import IntegrationZoom from './Integration.Zoom.jsx'

import {
	dismissMessage,
} from 'actions/app';

import Message from 'Widgets/Message.jsx'

class Integrations extends Component {

	componentWillUnmount () {
		this.props.dismissMessage();
	}

	render () {
		const { isOrganization, organizationID } = this.props;

		return (
			<div className="settings settings-narrow">
				{this.renderPageHeader()}
				<div className="row">
					<div className="col-12 settings-align-center" style={{ paddingLeft: '0px !important', color: '#373c47', paddingBottom: '.5rem' }}>
						Connect Dreamaker.io with the tools you already use to get more done without switching between different apps.
					</div>
					{this.renderMessages()}
				</div>

				<div className='settings-body settings-preferences'>
					<IntegrationZoom />
					<IntegrationLti />
					{isOrganization ? <IntegrationExistingLti /> : null}
				</div>
			</div>
		);

		// card view return (
		// 	<div className="settings settings-medium">
		// 		{this.renderPageHeader()}
		// 		<div className="row">
		// 			<div className="col-12 settings-align-center" style={{ paddingLeft: '0px !important', color: '#373c47'}}>
		// 				Connect Dreamaker.io with the tools you already use to get more done without switching between different apps.
		// 			</div>
		// 		</div>
		// 		<div className='settings-body settings-preferences'>

		// 			<div className="row">
		// 				<div className="col-md-5">
		// 					<IntegrationZoom />
		// 				</div>

		// 				<div className="col-md-5">
		// 					<IntegrationLti />
		// 				</div>
		// 			</div>

		// 			{isOrganization ? <IntegrationExistingLti /> : null}
		// 		</div>
		// 	</div>
		// );
	}

	renderPageHeader = () => {
		return <div className="settings-head"><h1>Integrations</h1></div>
	}

	renderMessages = () => {
		let { message, error } = this.props.messages
		return (
			<Message message={message} textClass={error ? 'danger' : 'success'} dismissable={true} style={{ paddingBottom: '0rem' }} />
		);
	}
}

function mapStateToProps (state) {
	let { account } = state;
	return {
		isOrganization: account.isOrganization || false,
		messages: state.messages,
		organizationID: account.organizationID,
	};
}

const mapDispatchToProps = {
	dismissMessage,
};


export default connect(mapStateToProps, mapDispatchToProps)(Integrations);