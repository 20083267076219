import _ from 'lodash';

import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';

import { openModal } from 'actions/app'
import {
	updateEventTag,
	deleteEventTagItem,
	updateEventTagMarker,
	addEventTagReply,
	updateEventTagReply,
	deleteEventTagReply,
} from './actions/index';

import {playSnippet} from './actions/mediaplayer';

import { formatLocalDate, formatVideoTime } from 'utils/';

import EventTagItemMarkers from './Tabs.EventTagItem.Markers.jsx';
import EventTagItemReply from './Tabs.EventTagItem.Reply.jsx';
import Avatar from 'Widgets/Avatar.jsx';
import ModalConfirm from 'Widgets/Modal.Confirm.jsx';

class EventTagItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editingTitle: false,
			editingComment: false,
			editingReply: [],
			addingReply: false,
			displayModal: false
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ displayModal: nextProps.modalOpen.name });
	}

	componentDidMount() {
		if (this.props.id === undefined) return;
		if (this.props.id === this.props.activeTagID) {
			this.refs[this.props.id].scrollIntoView({
				block: 'end',
				behavior: 'smooth'
			});
		}
	}

	render() {
		const {
			currentUser,
			isItemAnalysisOwner,
            itemAnalysisOwner,
			itemMarkerOrder,
            locked,
			// hasCollaborators, // See note in <Analysis /> re future use of hasCollaborators
            modalOpen,
        } = this.props;

		// depending on if the user is the itemAnalysis Owner or not, we want to give them their own tags, or the analysis' tags.
		const dashboardTags = isItemAnalysisOwner ? this.props.userDashboardTags : this.props.itemAnalysisDbTags;

		// comes from eventTagItems reducer
		const {
            comment,
			eventTagID,
			dashboardTagID,
			dateCreated,
			markers,
			mediaTimestamp,
            ownerID,
			replies,
			title,
        } = this.props.eventTag;
        
        // var {comment }= this.props.eventTag;
        // comment = 'oneN\noneR\rthree'

		let color = 'default';
		let label = 'Comment';
		if (dashboardTagID !== 'root_comment') {
			color = dashboardTags[dashboardTagID].color;
			label = dashboardTags[dashboardTagID].label;
		}

		// Set eventTag owner data
		let ownership = false;
		let name;
		let { initials, avatar } = this.props;

		// EventTag owner is currentUser
		if (currentUser.userID === ownerID) {
			ownership = true;
			name = currentUser.firstName;
			initials = currentUser.initials;
			avatar = ''; //currentUser.avatarURL_32

		// EventTag owner is a collaborator
		} else if (this.props.collaborators[ownerID]) {
			name = this.props.collaborators[ownerID].name;
			initials = this.props.collaborators[ownerID].initials;
			avatar = ''; //this.props.collaborators[ownerID].avatarURL_32

		// EventTag owner is itemAnalysis owner
		} else if (itemAnalysisOwner.ownerID === ownerID) {
			name = itemAnalysisOwner.name;
			initials = itemAnalysisOwner.initials;
			avatar = '';
		}

		// create ordered replies
		let repliesArr = [];
		if (0 < _.keys(replies).length) {
			var replyOwnership, replyName, replyInitials, replyAvatar;
			let orderedReplies = _.orderBy(replies, 'dateCreated', ['asc']);
			_.forEach(orderedReplies, (value, index) => {
				// dm_print('-----> reply ', value)

				// ReplyOwner is current user
				if (currentUser.userID === value.ownerID) {
					replyOwnership = true;
					replyName = currentUser.firstName;
					replyInitials = currentUser.initials;
					replyAvatar = ''; //currentUser.avatarURL_32

				// ReplyOwner is a collaborator
				} else if (this.props.collaborators[value.ownerID]) {
					replyOwnership = false;
					replyName = this.props.collaborators[value.ownerID].name;
					replyInitials = this.props.collaborators[value.ownerID].initials;
					replyAvatar = '';

				// ReplyOwner is EvenTag owner
				} else {
					replyOwnership = false;
					replyName = itemAnalysisOwner.name;
					replyInitials = itemAnalysisOwner.initials;
					replyAvatar = ''; //avatarURL
				}

				repliesArr.push(
					<EventTagItemReply
						key={`rply-blk-${value.eventTagReplyID}`}

						handleReplyUpdate={this.handleReplyUpdate}
						handleReplyDelete={this.handleReplyDelete}
						openModal={this.props.openModal}

						{...value}

						avatar={replyAvatar}
						initials={replyInitials}
                        locked={locked}
						name={replyName}
						ownership={replyOwnership}
					/>
				);
			});
		}

		let eventTagClasses = classNames({
			card: true,
			commentblock: true,
			[`card-outline-${color}`]: true
		});

		let titleClasses = classNames({
			'commentblock-title': true,
			'cursor-input': ownership  && !locked,
			'cursor-default': !ownership || locked,
			'commentblock-title-editing': this.state.editingTitle && ownership && !locked
		});

		let commentClasses = classNames({
			'commentblock-comment': true,
			'cursor-input': ownership  && !locked,
			'cursor-default': !ownership || locked,
			'commentblock-comment-editing': this.state.editingComment && ownership  && !locked
		});

		let replyClasses = classNames({
			'commentblock-addreply': true,
			'commentblock-addreply-editing': this.state.addingReply && !locked
		});

		let playlistClasses = classNames({
			far: true,
			'fa-check-circle': true,
			'commentblock-status-icon': true,
			'commentblock-icon': true,
            'cursor-pointer': ownership && !locked,
			'text-muted': !this.props.eventTag.playlist
		});

		return (
			<div ref={this.props.id} className={eventTagClasses}>
				{this.state.displayModal === 'deleteEventTagModal' &&
				eventTagID === modalOpen.xID ? (
					<ModalConfirm
						displayModal={this.state.displayModal}
						onConfirm={() => {
							this.props.deleteEventTagItem(eventTagID);
						}}
						title={title}
						confirmBtn="Delete"
						confirmPhrase="Are you sure you want to delete"
						message="All associated replies will be deleted too."
					/>
				) : (
					''
				)}
				<div className="card-block">
					<div className="commentblock-head">
                        {/* TITLE LINE */}
						<Avatar size="sm" initials={initials} src={avatar} name={name}/>
                        {/* PLAY AND TIME LINE */}
						<div
							className={`commentblock-time cues-et-play commentblock-time-${color}`}
							onClick={this.playSnippet}
							data-toggle="tooltip"
							data-placement="left"
							title="Play">
							<i className="far fa-play-circle icon-play" />
							{formatVideoTime(mediaTimestamp)}
						</div>
						<div className={titleClasses}>
                            {/* ACTUAL TITLE */}
							<h6 onClick={() => this.handleInputClick('editingTitle', 'titleInput')}>
								{title}
							</h6>
							<form
								onSubmit={e => {
									e.preventDefault();
									this.refs.titleInput.blur();
								}}>
								<input
									ref="titleInput"
									className="form-control"
									type="text"
									onBlur={e => {
										this.handleInputChangeSubmit(
											e,
											'editingTitle',
											'title',
											title
										);
									}}
									defaultValue={title}
								/>
							</form>
                            {/* EVENT TAG OWNER & DATE */}
							<h5>{name}{'  '}<span>{formatLocalDate(dateCreated)}</span></h5>
						</div>
					</div>

					<div className='commentblock-places'>
						{ownership && !locked ? (
							<div className="commentblock-icons-right">
								<div
									className="icon-holder"
									data-toggle="tooltip"
									title={
										this.props.eventTag.playlist
											? 'Remove from Playlist'
											: 'Add to Playlist'
									}
									onClick={event => {
										event.preventDefault();
										this.handleTogglePlaylist(this.props.eventTag);
									}}>
									<i className={playlistClasses} />
								</div>
							</div>
						) : (
							<div className="commentblock-icons-right">
								<div className="icon-holder">
									<i className={playlistClasses} />
								</div>
							</div>
						)}

						<EventTagItemMarkers
							dashboardTagID={dashboardTagID}
							dashboardTags={dashboardTags}
							dashboardLabel={label}
							itemMarkerOrder={itemMarkerOrder}
							isItemAnalysisOwner={isItemAnalysisOwner}
							eventMarkers={markers}
							eventTagID={eventTagID}
							handleMarkerChange={this.handleMarkerChange}
							handleTagChange={this.handleDashboardTagChange}
							ownership={ownership}
                            locked={locked}
						/>
					</div>

					{ownership && !locked ? (
						<div className={commentClasses} ref="comment">
							<p onClick={() => this.handleInputClick('editingComment', 'commentInput')}>
								{ comment 
                                ? comment  //<span dangerouslySetInnerHTML={{ __html: comment.replace(/\r?\n/g, '<br />' )}} />
								: <small className="text-muted">Add a note</small>
								}
							</p>
							<textarea
								ref="commentInput"
								className="form-control"
								autoComplete="off"
								onKeyDown={e =>
									this.handleInputKeyDown(e, 'editingComment', 'commentInput')}
								onBlur={e =>
									this.handleInputChangeSubmit(
										e,
										'editingComment',
										'comment',
										comment
									)}
								defaultValue={comment}
								placeholder="Add a note..."
							/>
						</div>
					) : (
						<div className={commentClasses} ref="comment">
							<p>
								{comment ? comment : <small className="text-muted">No notes</small>}
							</p>
						</div>
					)}

					{repliesArr}

					<div className={replyClasses}>
						<span onClick={() => this.handleInputClick('addingReply', 'replyInput')}>
							{ locked 
                                ? ''
                                : 'Reply' }
						</span>
                        <textarea
                            ref="replyInput"
                            className="form-control"
                            type="text"
                            onKeyDown={e =>
                                this.handleInputKeyDown(e, 'addingReply', 'replyInput')}
                            onBlur={e =>
                                this.handleInputChangeSubmit(e, 'addingReply', 'reply', '')}
                            placeholder="Reply..."
                            rows={1}
                        />
					</div>
					{ (ownership || isItemAnalysisOwner) && !locked ? (
						<div
							className="icon-delete fa-pull-right cursor-pointer colorEaseIn"
							data-toggle="tooltip"
							title="Delete event tag"
							//onMouseLeave={this.handleDDleave}
							name="deleteEventTagModal"
							onClick={e => {
								e.preventDefault();
								this.props.openModal('deleteEventTagModal', eventTagID);
							}}>
							<i className="far fa-times" />
						</div>
					) : (
						''
					)}
				</div>
			</div>
		);
	}

	playSnippet = () => {
        const {eventTag, durationSeconds, preferences} = this.props
        this.props.playSnippet(
            durationSeconds,
            eventTag.mediaTimestamp,
            preferences.leadTime,
            preferences.lagTime
        );
	};

	handleInputKeyDown = (e, editing, ref) => {
        // // Cmd+Enter or Ctrl+Enter for a newline
        // if (e.keyCode === 13 && e.metaKey) {
        //     e.target.value = e.target.value + '\n'
        //     return;
        //     {/* <span dangerouslySetInnerHTML={{ __html: comment.replace(/\r?\n/g, '<br />' )}} /> */}
        // }
        
		// isEnter?
		if (e.keyCode == 13) {
			e.preventDefault();
			this.setState({
				[editing]: false
			});
			this.refs[ref].blur();
		}
	};

	handleInputClick = (editing, ref) => {
		this.setState({
			[editing]: true
        });
        ('titleInput' === ref) ? this.refs[ref].select() : null;
		setTimeout(() => {
			this.refs[ref].focus();
		}, 30);
	};

	handleInputChangeSubmit = (e, editing, key, oldValue) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			[editing]: false
		});

		if (oldValue === e.target.value) return;

		const newValue = _.trim(e.target.value);
		const length = newValue.length;

		// No blank title or reply
		if (0 >= length && ('title' === key || 'reply' === key)) {
			e.target.value = oldValue;
			return;
		}
		if ('title' === key) {
			this.props.updateEventTag(this.props.eventTag, key, newValue);
			return;
		}
		if ('reply' === key) {
			e.target.value = '';
			this.props.addEventTagReply(
				this.props.eventTag,
				this.props.currentUser.userID,
				newValue
			);
			return;
		}

		this.props.updateEventTag(this.props.eventTag, key, newValue);
	};

	handleReplyUpdate = (eventTagReplyID, updatedReply) => {
		this.props.updateEventTagReply(
			this.props.eventTag,
			this.props.eventTag.replies[eventTagReplyID],
			updatedReply
		);
	};

	handleTogglePlaylist = eventTag => {
		this.props.updateEventTag(this.props.eventTag, 'playlist', !eventTag.playlist);
	};

	handleMarkerChange = (markerID, value) => {
		this.props.updateEventTagMarker(this.props.eventTag, markerID, value);
	};

	handleDashboardTagChange = dashboardTagID => {
		this.props.updateEventTag(this.props.eventTag, 'dashboardTagID', dashboardTagID);
	};

	// handleDDleave = e => {
	// 	this.setState({
	// 		ddVisible: false
	// 	});
	// };

	// handleDDtoggleClick = e => {
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	this.setState({
	// 		ddVisible: !this.state.ddVisible
	// 	});
	// };

	handleReplyDelete = eventTagReplyID => {
		this.props.deleteEventTagReply(this.props.eventTag, eventTagReplyID);
	};
}

function mapStateToProps(state) {
    const selectedItemAnalysis = state.folderItems.selectedItemAnalysis;
    
	return {
		currentUser:    state.user.data,
        initials:       selectedItemAnalysis.initials,
        avatar:         '', //selectedItemAnalysis.avatar,
        
		collaborators:      selectedItemAnalysis.collaborators.users,
    
        itemMarkerOrder:    selectedItemAnalysis.markers,
        itemAnalysisDbTags: state.itemAnalysisDbTags.data,
        
        durationSeconds: state.folderItems.selectedItem.durationSeconds,
        modalOpen: state.modalOpen,
        preferences: state.user.data.preferences,
        userDashboardTags: state.userDashboardTags.data,
        
        // Loading - see Loading note above
        // itemAnalysisDbsLoading: state.itemAnalysisDbs.loading,
        // itemAnalysisDbTagssLoading: state.itemAnalysisDbTags.loading,
        // userDashboardsLoading: state.userDashboards.loading,
	};
}

const mapDispatchToProps = {
	updateEventTag,
	deleteEventTagItem,
	updateEventTagMarker,
	addEventTagReply,
	updateEventTagReply,
	deleteEventTagReply,
	playSnippet,
	openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTagItem);
