
import classNames from 'classnames'
import React, {Component} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	key: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
	checked: PropTypes.bool,
	onBlur: PropTypes.func,
	onClick: PropTypes.func.isRequired,
	style: PropTypes.object,
	tabIndex: PropTypes.bool,
	name: PropTypes.string.isRequired,
};

class Checkbox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isChecked: this.props.checked
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.checked !== this.props.checked) {
			this.setState({ isChecked: nextProps.checked  })
        }
	}

	render() {
		const {
			autoFocus,
			disabled, 
			className,
			key,
			onBlur,
			style,
			tabIndex,

			// tooltip
			dataToggle,
			dataPlacement,
			title,
		} = this.props;

		const isChecked = this.state.isChecked;
		
		const classes = classNames({
			...className,
			'form-checkbox far': true,
			'disabled': disabled,
			'fa-square': !isChecked,
			'fa-check-square': isChecked
		});

		return (
			<i
				autoFocus={autoFocus}
				className={classes} 
				disabled={disabled}
				key={key}
				onBlur={onBlur}
				onClick={ disabled ? null : this.handleOnClick}
				onBlur={onBlur}
				ref={name}
				style={style}
				tabIndex={tabIndex}

				data-toggle={dataToggle}
				data-placement={dataPlacement}
				title={title}
			/>
		)
	}

	handleOnClick = (e) => {
		const {name, onClick} = this.props;
		// Assign e.target variables (they're not auto created on a <i />)
		e.target.value = !this.state.isChecked;
		e.target.name = name;

		this.setState({isChecked: !this.state.isChecked})
		onClick ? onClick(e) : null;
	}
}

Checkbox.propTypes = propTypes;

export default Checkbox;
