import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from 'Constants/app';

const IconUpgrade = ({ history }) => (
	<button
		className='btn btn-transparent-success'
		data-toggle="tooltip"
		title="Upgrade!"
		onClick={() => history.push(ROUTES.UPGRADE)}
	>
		<i className="fal fa-hand-point-up" />
		{' '}UPGRADE
	</button>
);

export default IconUpgrade;