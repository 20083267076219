import classNames from 'classnames';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field, Form } from 'redux-form';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import PageAccess from '../Layout/page.access.jsx';
import { signinUser } from '../../actions';
import { clearUserMessages } from 'actions/app';
import { ROUTES } from 'Constants/app';
import Message from 'Widgets/Message.jsx';


class Signin extends Component {

	render() {
		const { handleSubmit, loading } = this.props;

		const displayLoading = classNames({
			'far fa-spinner fa-spin spinner': loading
		});

		const disabled = classNames({
			disabledTextInput: loading
		});

		return (
			<PageAccess>
				<div className="access">
					<div className="access-head">
						<a href="https://www.dreamaker.io" className="access-logo" target_="blank" ></a>
						<h2 style={{marginBottom: '2rem'}}>dreamaker.io</h2>
						<h1>{I18n.get('Welcome')}</h1>
					</div>
					<div className="access-body">
						<div className="form form-access">
							{this.renderMessage()}
							<Form onSubmit={handleSubmit(this.handleFormSubmit)}>
								<div className="form-group">
									<Field
										autoFocus={true}
										name="email"
										type="email"
										component={renderInput}
										label={I18n.get('Email')}
									/>
								</div>
								<div className="form-group">
									<Field
										name="password"
										type="password"
										component={renderInput}
										label={I18n.get('Password')}
									/>
								</div>
								<button
									action="submit"
									className="btn btn-secondary"
									disabled={disabled}>
									{I18n.get('Sign in')} <i className={displayLoading} />
								</button>
							</Form>
						</div>
						<br />
						{I18n.get('New to Dreamaker.io?')}{' '}<Link to={ROUTES.SIGNUP}>{I18n.get('Sign up')}</Link>
						{' '}| {I18n.get('Forgot your password?')} <Link to={ROUTES.RESET}>{I18n.get('Get a new one!')}</Link>
					</div>
				</div>
			</PageAccess>
		);
	}

	renderMessage() {
		var message = false;
		var msgClass = "success"
		if (this.props.message) message = this.props.message
		else if(this.props.err) {
			msgClass = "danger"
			message = this.props.err
		}

		if(message) return (
			<Message message={message} textClass={msgClass} dismissable={false} /> 
		)
	}

	handleFormSubmit = ({ email, password }) => {
		this.props.clearUserMessages();
        email = email.toLowerCase();
		this.props.signinUser({ email, password });
	};
}

const renderInput = field => {
	const { label, type, input, meta: { error, submitFailed } } = field;
	return (
		<div>
			<input
				{...input}
				type={type}
				className="form-control"
				placeholder={label}
				autoFocus={field.autoFocus}
			/>
			{submitFailed && error && <div className="text-danger">{error}</div>}
		</div>
	);
};

function validate(formProps) {
	const errors = {};

	if (!formProps.email) {
		errors.email = I18n.get('Please enter an email');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
		errors.email = I18n.get('Invalid email address');
	}

	if (!formProps.password) errors.password = I18n.get('Password required');;

	return errors;
}

function mapStateToProps(state) {
	return {
		initialValues: state.user,
		loading: state.user.loading,
		err: state.user.error,
		message: state.user.message,
	};
}

const mapDispatchToProps = { clearUserMessages, signinUser };

Signin = compose(
	reduxForm({ form: 'signin', validate }),
	connect(mapStateToProps, mapDispatchToProps)
)(Signin);

export default Signin;
