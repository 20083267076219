// Staging
module.exports = exports = {

    // Cognito
    Region:         'us-west-2',
    UserPoolId:     'us-west-2_z5Lndzr0W',
    ClientId:       '6017b7i4nbnptknrgqrc9bk1cb',
    Paranoia:       7,
    IdentityPoolId: 'us-west-2:96698818-4e25-4687-8803-aa7b48ec2c15',

    // S3
    BucketRegion:   'us-west-2',
	FileinBct: 		'staging-filein-dmio',
    TransinBct:     'staging-transin-dmio',
    TransoutBct:    'staging-transout-dmio',
    TransoutUrl:    'https://transout.staging.dreamaker.io/',
    TransoutSUrl:   'https://transouts.staging.dreamaker.io/',
    LookinBct:      'staging-lookin-dmio',
    LookoutBct:     'staging-lookout-dmio',
    // LookoutUrl:     'https://lookout.test.dreamaker.io/',
    LookoutUrl:     '',
    BandwidthTest:  'https://s3-us-west-2.amazonaws.com/staging-transin-dmio/bandwidth_test.png',

    // API
    ApiHost:        'https://api.staging.dreamaker.io/',

    // URL
    WebappUrl:      'https://staging.dreamaker.io/',
	
	// LTT
	MoodleToolUrl:	'https://api.staging.dreamaker.io/lti/cartridge',

    // 3RD Party
    StripeAPIKey: "pk_test_Ew4pbiOnveh9sh0X5TjQIRGs"    // Test
    // StripeAPIKey: "pk_live_ynmxGlzxkq5SLTwJZTHrgAgX" // Live
}