import React, { Component } from "react";
import { findDOMNode } from 'react-dom';
import { compose } from "redux";
import { DragSource, DropTarget } from "react-dnd";

const ItemTypes = {
  DEFAULTDB: "defaultdb"
};

// returns an object for the draggedSource
const dashboardSource = {
  beginDrag(props) {
    return {
      dashboardID: props.dashboardObj.dashboardID,
      defaultSortOrder: props.dashboardObj.defaultSortOrder,
      name: props.dashboardObj.title
    };
  }
};

// used for the dropped target
const dashboardTarget = {
  // props == the passed props to this component
  // monitor == item being dragged
  // component == this entire component
  hover(props, monitor, component) {
    const dragItem = monitor.getItem()
    const dragItemOrder = dragItem.defaultSortOrder
    const hoverItem = props.dashboardObj
    const hoverOrder = hoverItem.defaultSortOrder
    
    // does not replace item with itself
    if ( dragItemOrder === hoverOrder ) return;

    // determines rectangle on screen of component
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    // gets vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determines mouse position
    const mousePosition = monitor.getClientOffset();
    // Gets pixels to the top
    const hoverMouseY = mousePosition.y - hoverBoundingRect.top

    // Only move the item if the mouse has gone passed half the items height

    // Dragging down, only move when cursor is below 50% of item
    if (dragItemOrder < hoverOrder && hoverMouseY < hoverMiddleY) return;
		// Dragging upwards, only move when cursor is above 50% of item
    if (dragItemOrder > hoverOrder && hoverMouseY > hoverMiddleY) return;
    
    // Perform the actual move
    props.moveDashboard(dragItem, hoverItem)

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().defaultSortOrder = hoverOrder
  },
  drop(props){
    // on drop, will submit the new default order.
    props.submitNewDefaultOrder()
  }
};

function dashboardDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

function dashboardDropTarget(connect) {
  return {
    connectDropTaget: connect.dropTarget()
  };
}

class SingleDashboardDraggable extends Component {
  render() {
    const {
      className,
      connectDragPreview,
      connectDragSource,
      connectDropTaget,
      dashboardObj,
      isDragging
    } = this.props;
    // if the item is being dragged, the opacity will be set to 0.
    const opacity = isDragging ? 0 : 1;
    return connectDragPreview(
      connectDropTaget(
        <li
          onClick={() =>
            this.props.handleSelectDashboard(dashboardObj.dashboardID)}
          className={className}
          style={{ opacity }}>
          <div className="list-title-div">
            {connectDragSource(<i className="far fa-ellipsis-v draggable"/>)}
            {" "}
            {dashboardObj.title}
          </div>
          <span>{dashboardObj.default && <i className="fas fa-star" />}</span>
        </li>
      )
    );
  }
}

const SingleDashboardDraggableFinal = compose(
  DropTarget(ItemTypes.DEFAULTDB, dashboardTarget, dashboardDropTarget),
  DragSource(ItemTypes.DEFAULTDB, dashboardSource, dashboardDragSource)
)(SingleDashboardDraggable);

export default SingleDashboardDraggableFinal;
