import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { ROLES } from 'Constants/app';

import BottombarTabs from './Bottombar.Tabs.jsx';
import FileAssetsWrapper from './FileAssets/File.Assets.Wrapper.jsx'
import DashboardWrapper from './Dashboard/Dashboard.Wrapper.jsx';
import DataVisualizerWrapper from './DataVisualizer/DataVisualizer.Wrapper.jsx'
import EditorWrapper from './Editor/Editor.Wrapper.jsx'
import GradeWrapper from './Grade/Grade.Wrapper.jsx';
import TranscribeWrapper from './Transcribe/Transcribe.Wrapper.jsx'
import VTTWrapper from './VTT/VTT.Wrapper.jsx'
//import TimelineWrapper from './Timeline/Timeline.Wrapper.jsx';

import { openModal } from 'actions/app'

class Bottombar extends Component {
	constructor(props) {
		super(props);
		// console.log('Bottombar here: ',this.props.location)
		// console.log('Bottombar here: ',this.props.history)
		const locationHash = this.props.location.hash;
		this.state = {
			activeDashboardIndex: 0,
			displayModal: false,
			// activeTab: locationHash.substr(1, locationHash.length) || 'dashboard'
			// FOR DEVELOPMENT ONLY:
			// activeTab: 'data-visualizer'
			// activeTab: 'dashboard',
			// activeTab: 'fileAssets',
            // activeTab: 'editor',
			// activeTab: 'grade',
            // activeTab: 'transcribe',
			activeTab: 'vtt'
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ displayModal: nextProps.modalOpen.name });
		const { activeDashboardIndex } = this.state;
		// if the current active dashboard index is more than the number of selectable dashboards, we want to
		// go to the previous dashboard
		if (activeDashboardIndex > nextProps.itemAnalysis.dashboards.length - 1) {
			// if the active index is 0, we do not want to go lower.
			if (activeDashboardIndex === 0) return;
			this.setState({ activeDashboardIndex: activeDashboardIndex - 1 });
        }
	}

	render() {
		const {
			itemAnalysis,
			itemAnalysisDbsLoading,
			organizationID, 
			role,
			userID,
		} = this.props;

		if (itemAnalysisDbsLoading) {
			return (
				<div>
					<br />&nbsp;&nbsp;Loading...
				</div>
			);
        }
        
        /** Show Charts */
        var showCharts = false;
		if ('@dreamaker.io' === organizationID || "development" === process.env.NODE_ENV)
			showCharts = true;
			
		
		/** Show LTIGrades **/
		var showGrade = ROLES.AUTOTEACHER === role || false;
		// const collaborators = itemAnalysis.collaborators.users;
		// if( collaborators[userID] )
		// 	showGrade = ROLES.AUTOTEACHER === collaborators[userID].role


		
		return (
			<div className="row" style={{ margin: 0 }}>
                <div className="col-lg-1" id="bottombar-tabs">
                    <BottombarTabs className="col-lg-1" id="bottombar-tabs"
                        activeTab={this.state.activeTab} 
                        showCharts={showCharts}
						showGrade={showGrade}
                        handleChangeTabClick={this.handleChangeTabClick} 
                        />
                </div>
				<div className="col-lg-10">
					{ this.renderView() }
				</div>
                <div className="col-lg-1"  id="bottombar-tabs">
                    {/* Remove this and make above 11, then add sidebar to video section for cool stuff there :) */}
                </div>
			</div>
		);
	}
	
	renderView = () => {
		const {
			isItemAnalysisOwner,
			item,
			itemAnalysis,
			locked,
			role
		} = this.props;

		switch(this.state.activeTab) {

			/** Editor Tab */
			case 'editor': {
				var child = 
					<EditorWrapper 
						item={item}
						itemAnalysisID={itemAnalysis.itemAnalysisID}
						itemAnalysis={itemAnalysis}
						itemID={item.itemID}
						isItemAnalysisOwner={isItemAnalysisOwner}
						locked={locked}
					/>
				break;
			}

			/** File Assets / Attachment Tab */
			case 'fileAssets': {
				var child = 
					<FileAssetsWrapper 
						itemAnalysisID={itemAnalysis.itemAnalysisID}
						isItemAnalysisOwner={isItemAnalysisOwner}
						locked={locked}
						role={role}
					/>
				break;
			}

			/** LTI Grade Tab */
			case 'grade': {
				var child = 
					<GradeWrapper
						isItemAnalysisOwner={isItemAnalysisOwner}
					/>

				break;

			}

			/** Transcribe Tab */
			case 'transcribe': {
				const { isShare } = this.props
				var child =
					<TranscribeWrapper
						itemAnalysisID={itemAnalysis.itemAnalysisID}
						isItemAnalysisOwner={isItemAnalysisOwner}
						locked={locked}

						// TODO: Tmp addition to prevent transcribing of shared items
						isShare={isShare}
					/>
				break;
			}

			/** VTT Tab */
			case 'vtt': {
				const { isShare } = this.props
				var child = 
					<VTTWrapper 
						itemAnalysisID={itemAnalysis.itemAnalysisID}
						isItemAnalysisOwner={isItemAnalysisOwner}
						locked={locked}

						// TODO: Tmp addition to prevent transcribing of shared items
						isShare={isShare}
					/>
				break;
			}

			/** Data Visualizer Tab */
			case 'data-visualizer': {
				const { isResizingSidebar, isResizingVideo } = this.props;

				var child = 
					<DataVisualizerWrapper
						isItemAnalysisOwner={isItemAnalysisOwner}
						isResizingSidebar={isResizingSidebar}
						isResizingVideo={isResizingVideo}
						locked={locked}
					/>

				break;
			}

			/** Dashboard Tab **/
			default: {
				const { 
					itemAnalysisDbs,
					itemAnalysisDbTags,
					pause, 
					play, 
					openModal,
					userDashboards,
					userDashboardTags,
				} = this.props;

				// Get active dashboard & dashboard tags based on whether or not the user is the itemAnalysis owner
				const { activeDashboardIndex } = this.state;
				const { dashboards } = itemAnalysis;

				if( isItemAnalysisOwner ) {
					var selectedDashboard 	= userDashboards[dashboards[activeDashboardIndex]]
					var dashboardTags 		= userDashboardTags
				}
				else {
					var selectedDashboard 	= itemAnalysisDbs[dashboards[activeDashboardIndex]];
					var dashboardTags 		= itemAnalysisDbTags;
				}

				var child =                 
					<DashboardWrapper
						dashboardTags={dashboardTags}
						handleNextClick={this.handleNextClick}
						isItemAnalysisOwner={isItemAnalysisOwner}
						locked={locked}
						openModal={openModal}
						pause={pause}
						play={play}
						selectedDashboard={selectedDashboard}
					/>
				break;
			}
		}
        


		return (
			<CSSTransition
				in={true}
				appear={true}
				timeout={700}
				classNames="dashboard-collapser"
                >
				{child}
			</CSSTransition>
		);

	}
    
	handleChangeTabClick = viewName => {
		this.setState({ activeTab: viewName });
    };
    
	handleNextClick = direction => {
		const { activeDashboardIndex } = this.state;

		// puts all dashboards into an array so we can grab how many items are in the array
		const DashboardCnt = this.props.itemAnalysis.dashboards.length;
		if (direction === 'next') {
			// minus 1, because index's start at 0
			if (activeDashboardIndex + 1 > DashboardCnt - 1) {
				this.setState({ activeDashboardIndex: 0 });
				return;
			}
			this.setState({ activeDashboardIndex: activeDashboardIndex + 1 });
			return;
		}

		if (direction === 'prev') {
			if (activeDashboardIndex - 1 < 0) {
				this.setState({ activeDashboardIndex: DashboardCnt - 1 });
				return;
			}
			this.setState({ activeDashboardIndex: activeDashboardIndex - 1 });
			return;
		}
    };
    
}

function mapStateToProps(state) {
	
	return {
        item:               	state.folderItems.selectedItem,
        itemAnalysis:       	state.folderItems.selectedItemAnalysis,

		
		itemAnalysisDbs:    	state.itemAnalysisDbs.data,
		itemAnalysisDbsLoading: state.itemAnalysisDbs.loading,
		
		itemAnalysisDbTags: 	state.itemAnalysisDbTags.data,

		userDashboards:     	state.userDashboards.data,
        userDashboardTags:  	state.userDashboardTags.data,
        
		modalOpen: state.modalOpen,
		
		// lti: state._.lti || {},
		
		organizationID: state.account.organizationID || null,
		userID: state.user.data.userID,
	};
}

export default connect(mapStateToProps, { openModal })(Bottombar);
