import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

import { I18n } from '@aws-amplify/core';

import ProjectThumbStatus from './Project.Thumb.Status.jsx'

import { dismissMessage, openModal } from 'actions/app';
import { uploadMediaItem } from './actions/';

class ProjectUploader extends Component {

	render () {
		const { dismissMessage, uploading, status } = this.props;

		if (uploading) {
			if (status.status === 'Uploading' || status.status === 'Calculating') {
				return (
					<div className="uploader-thumb">
						<ProjectThumbStatus />
					</div>
				);
			}
			else
				return <div />;
		}
		else {
			return (
				<Dropzone
					onDrop={this.handleOnDrop}
				>
					{({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
						const dragClass =
							isDragAccept
								? "uploader-thumb-dragover"
								: isDragReject
									? ""
									: "";

						return (
							<div {...getRootProps({
								className: `uploader-thumb ${dragClass}`,
							})}>
								<input {...getInputProps()} />
								<div className="uploader-thumb-face">
									<i className="fal fa-cloud-upload fa-5x uploadIcon" />
									<span>{I18n.get('Drop or Select file')}</span>
								</div>
							</div>
						)
					}}
				</Dropzone>
			);
		}
	}

	handleOnDrop = (acceptedFiles) => {
		this.props.dismissMessage();
		const file = acceptedFiles[0];
		if (file === undefined || file === null) return;
		this.props.uploadMediaItem(this.props.folderID, file);
	};
}

function mapStateToProps (state) {
	return {
		lti: state._.lti || false,
		status: state.uploader.status,
		uploading: state.uploader.uploading,
	};
}

const mapDispatchToProps = { dismissMessage, openModal, uploadMediaItem };

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUploader);