import React from 'react';

export const LoadingLips = () => {	
	return (
		<div className='ellipsis-loader'>
			<i className={'fas fa-circle'} />
			<i className={'fas fa-circle'} />
			<i className={'fas fa-circle'} />
		</div>
	)
};
