import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import {
	request
} from 'actions/index';

import {
	apiConfig,
} from 'config';

import {
	createItem
} from './index'

import {
	//messaging
	ERROR_ADD,
	MESSAGE_ADD,

	//items
	ITEM_DELETE,

	//zoom oauth
	OAUTH_REHYDRATE,
	OAUTH_REVOKE,
	ZOOM_CLEAR_RECORDINGS,
	ZOOM_LIST_RECORDINGS,
	ZOOM_IMPORT,

	//zoom imports
	ZOOM_IMPORTS_DELETE,
	ZOOM_IMPORTS_FETCH,
	ZOOM_RETAIN_DATA

} from 'Constants/actions';

import { PLANID, PROGRESS_FLAGS, UNLIMITED } from 'Constants/app';

const ZOOM_ERROR = 'ZOOM_ERROR'

/**
 * Checks of for exceptionCode, dispatching actions based on exception code
 * @param {String} exceptionCode Exception error code (usually derived from `resp.code`)
 * @param {Function|Boolean} [dispatch=false] React dispatch function or false (default it false)
 * @param {Object[]} [dispatchActions=[]] Array of actions to dispatch only taken if dispatch !== false
 * @param {String} dispatchActions[].type Type of action to take
 * @param {String} dispatchActions[].payload Payload of the action
 * @returns {Boolean|String} {String} Error message if has exceptionCode is fatal, otherwise {Boolean} false
 * 
 * NOTE: Exception meanings
 * OAuthAccountNotSupported: User authorized but Zoo Basic plan, needs a Zoom Pro plan
 * OAuthNotAuthorizedException: User is not authorized by zoom
 * OAuthAlreadyAuthorizedException: user already authorized
 */
function _has200Exception (exceptionCode, dispatch = false, dispatchActions = []) {
	// No excpetionCode === no error > return
	if (undefined === exceptionCode) return false;

	switch (exceptionCode) {
		case 'OAuthAccountNotSupported': {
			if (false !== dispatch) {
				dispatch({
					type: ZOOM_LIST_RECORDINGS,
					payload: {}
				})
			}
			//https://support.zoom.us/hc/en-us/articles/203741855-Cloud-Recording
			dispatch({
				type: ERROR_ADD,
				payload: 'Use this feature by enabling Zoom cloud recordings in your Zoom account.'
				// payload: <span>Dreamaker.io imports your Zoom recordings from the Zoom cloud. Unfortunately, your Zoom account does not allow for Zoom cloud recordings.{' '}
				// 	<a href='https://support.zoom.us/hc/en-us/articles/203741855-Cloud-recording' target='_blank'>Click here for further info</a>.</span>
			});
			return true;
		}
		// case 'OAuthAlreadyAuthorizedException': {
		// 	return true;
		// }
		case 'OAuthNotAuthorizedException': {
			dispatch({ type: ZOOM_CLEAR_RECORDINGS })
			if (false !== dispatch) {
				dispatchActions.forEach(action => {
					dispatch(action)
				});
				dispatch(zoomInitiateAuthorization())
			}
			dispatch({ type: ERROR_ADD, payload: 'Zoom account not authorized... Redirecting to zoom.' });
			return true;
		}
	}

	//Exception code not found
	return false;
} 

/**
 * Clears retried list of zoom recordings
 */
export function zoomClearRecordings () {
	return { type: ZOOM_CLEAR_RECORDINGS }
}

/**
 * Fetches the recordings from Zoom.
 * @param {String} from date in the format yyy-mm-dd
 * @param {String} to date in the yyy-mm-dd
 * 
 * NOTE: API apiConfig.zoomFetchRecordings
 * @throws {200} OAuthAccountNotSupported - User authorized but Zoo Basic plan, needs a Zoom Pro plan
 * @throws {200} OAuthNotAuthorizedException - User is not authorized by zoom
 * 
 * NOTE: User messages are displayed via global props.messages
 */
export function zoomFetchRecordings (from = null, to = null) {
	return function (dispatch) {
		const requestObj = {
			method: 'GET',
			url: `${apiConfig.zoomFetchRecordings}`,
		};

		return request(requestObj, ZOOM_ERROR)
			.then(resp => {
				// Has an auth error? reject
				if (_has200Exception(resp.code, dispatch))
					return Promise.reject()

				dispatch({
					type: ZOOM_LIST_RECORDINGS,
					payload: resp
				})
				// messages displayed via global props.messages
				return Promise.resolve();
			})
			.catch(err => {
				// Errors displayed via global props.messages
				return Promise.reject();
			})
	}
}

/**
 * Initiates importing of recording of file from Zoom into current user's Dmio folderID
 * @param {String} folderID of folder to import into
 * @param {Object} file object containing Zoom file details
 * @param {String} topic title of the media item to be imported
 * 
 * NOTE: API apiConfig.zoomFetchRecordings
 * @throws {200} OAuthAccountNotSupported - User authorized but Zoo Basic plan, needs a Zoom Pro plan
 * @throws {200} OAuthNotAuthorizedException - User is not authorized by zoom
 * 
  * NOTE: User messages are displayed via global props.messages
 */
export function zoomImportRecording (folderID, file) {
	return function (dispatch, getState) {
		const { account: { usageData, planData, dateCreated } } = getState();

		const {
			download_url,
			file_type, file_size,
			chatDownloadUrl, chatRecordingOffsetSeconds, importChat, 
			vttDownloadUrl, transcriptDownloadUrl, topic
		} = file

		//TODO:
		// 'completed' !== file.status) return;

		// CHECK USAGE LIMITS
		let errorMsg = null;

		// Check if free plan - limit to 1 analysis projects if signup after 12AM GMT 24 October(1508803200000)
		if (UNLIMITED.value !== planData.maxStorage &&
			PLANID.FREE[planData.planID] &&
			dateCreated > 1508803200000 &&
			1 <= usageData.videoCount + usageData.audioCount)
			errorMsg = "Project limit reached. Upgrade to import further media."


		// Check usageData.storageLimit (We're giving them an allowance of 2 MBs)
		// file_size in bytes
		if (usageData.storageCount + (file_size/1000000) > planData.maxStorage + 2)
			errorMsg = "Storage limit reached. Upgrade to import further media."

		// Throw error if present	
		if (errorMsg) {
			dispatch({
				type: ERROR_ADD,
				payload: errorMsg
			});
			return Promise.reject();
		}
		


		// INITIALIZE VARIABLES
		const itemID = uuidV4(), s3FileName = new Date().getTime().toString();
		let thumbnailURL = `urlforaudiothumbs`, fileType = `AUDIO`
		if ('MP4' === file_type) {
			(file_size >= 40000000) // Thumbnails retrieved at 60 sec intervals in live - 13 second video was 2.8MB; file_size is bytes
				? thumbnailURL = `${s3FileName}-thumbnail-00002.jpg`
				: thumbnailURL = `${s3FileName}-thumbnail-00001.jpg`;
			fileType = 'VIDEO'
		}

		// INITIALIZE NEW DMIO ITEM DETAILS
		let newItem = {
			itemID,
			mediaSource: 'zoom',
			folderID,
			mediaType: fileType,
			title: topic,
			thumbnailURL,
			mediaURL: `${s3FileName}.m3u8`,
			transcodeProgress: PROGRESS_FLAGS.UPLOADSTARTED
		}; console.log('newItem: ', newItem)

		let importChatDetails = importChat
			? { chatDestination: 'BOTH', chatDownloadUrl, chatRecordingOffsetSeconds }
			: {}

		// CREATE ITEM & IF SUCCESSFUL, TRIGGER IMPORT, ELSE DELETE ITEM AND SHOW ERROR
		return dispatch(createItem({ ...newItem }))
			// Creation successful, trigger import
			.then((resp) => {
				// createItem() rehydrates account

				const requestObj = {
					method: 'POST',
					url: `${apiConfig.zoomImportRecording}`,
					data: {
						downloadUrl: download_url,
						itemID,
						...importChatDetails,
						vttDownloadUrl, transcriptDownloadUrl
					}
				}

				return request(requestObj, ZOOM_ERROR)
					.then(resp => {
						// Has an auth error? reject
						if (_has200Exception(resp.code, dispatch, [{ type: ITEM_DELETE, payload: itemID }]))
							return Promise.reject()
						else {
							dispatch({
								type: ZOOM_IMPORT,
								payload: resp
							})
							dispatch({ type: MESSAGE_ADD, payload: 'Dreamaker.io is importing your file from Zoom. We will notify you by email when it is ready.' });
							return Promise.resolve();
						}
					})
					.catch(err => {
						// delete createdItem
						dispatch({ type: ITEM_DELETE, payload: itemID });
						// Errors displayed via global props.messages
						return Promise.reject();
					})
			});
	}
}

/**
 * Initiates the Zoom authorization flow for currently logged in user.
 * NOTE: API apiConfig.zoomInitiateAuthorization
 * @throws {200} OAuthAlreadyAuthorizedException - user already authorized
 */
export function zoomInitiateAuthorization (folderID=0) {
	return function (dispatch) {
		const requestObj = {
			method: 'GET',
			url: `${apiConfig.zoomInitiateAuthorization}${folderID}`,
		};

		return request(requestObj, ZOOM_ERROR)
			.then(resp => {
				if ('OAuthAlreadyAuthorizedException' === resp.code) {
					dispatch({
						type: OAUTH_REHYDRATE,
						payload: {
							zoom: {
								isAuthorized: true
							}
						}
					})
					return Promise.resolve('OAuthAlreadyAuthorizedException');
				}
				else {
					(resp.Location) ? window.location = resp.Location : null;
					return Promise.resolve(resp);
				}
			})
			.catch(err => {
				// errors displayed via global props.messages
				return Promise.reject();
			})
	}
}

export function zoomRetainData (oauthUserID) {
	return function (dispatch) {
		const requestObj = {
			method: 'GET',
			url: `${apiConfig.zoomRetainData}${oauthUserID}`,
		};

		return request(requestObj, ZOOM_ERROR)
			.then(resp => {
				// Has an auth error? reject
				if (_has200Exception(resp.code, dispatch))
					return Promise.reject()

				dispatch({ type: ZOOM_RETAIN_DATA })
				// messages displayed via global props.messages
				return Promise.resolve();
			})
			.catch(err => {
				// Errors displayed via global props.messages
				return Promise.reject();
			})
	}
}

/**
 * Revokes zoom authorization and deleted global state user.data.oauth.zoom
 * If already revoked, returns success regardless. IE - can be called a number of times without failure.
 * Zoom Revoke URL: https://marketplace.zoom.us/user/installed
 * 
 * NOTE: API apiConfig.zoomFetchRecordings
 * TODO: check this is needed and implement @throws {200} OAuthNotAuthorizedException - User is not authorized by zoom
 */
export function zoomRevokeAuthorization () {
	// window.location = 'https://marketplace.zoom.us/user/installed'
	return function (dispatch, getState) {
		const requestObj = {
			method: 'GET',
			url: `${apiConfig.zoomRevokeAuthorization}`,
		};

		return request(requestObj, ZOOM_ERROR)
			.then(resp => {
				dispatch({ type: ZOOM_CLEAR_RECORDINGS })
				var newUserOauthObject = getState().user.data.oauth;
				delete newUserOauthObject.zoom
				dispatch({
					type: OAUTH_REVOKE,
					payload: newUserOauthObject
				})
				dispatch({ type: MESSAGE_ADD, payload: 'Zoom access successfully deauthorized.' });
				return Promise.resolve();
			})
			.catch(err => {
				// errors displayed via global props.messages
				return Promise.reject();
			})
	}
}

/**
 * Fetches imported items froms for given oauthUserID.
 * @param {*} oauthUserID 
 * @param {*} pendingDelete 
 */
export function zoomFetchImportedItems (oauthUserID=false, pendingDelete=false) {
	return function (dispatch) {
		/** Nov 2020, Zoom v1 launch - limiting this functionality to existance of oauthUserID */
		if (false === oauthUserID) return Promise.reject();
		
		/**
		 * /f056ea6c-c5a1-428f-aab4-257f2cf98b51
		 * /bf5cdb5c-c4f2-4489-bd89-210127495145
		 * /935a36ea-5fc8-4766-acd5-eeee3b5f7825
		 * /3e90a320-00e5-4f6d-be49-211036c8ba8c - current
		 */
		const requestObj = {
			method: 'GET',
			url: `${apiConfig.zoomFetchImportedItems}` +
				`${oauthUserID ? `?oauthUserID=${oauthUserID}` : ''}` +
				`${pendingDelete ? `?pendingDelete=${pendingDelete}` : ''}`
		};

		return request(requestObj, ZOOM_ERROR)
			.then(resp => {
			
				dispatch({
					type: ZOOM_IMPORTS_FETCH,
					payload: resp
					//payload: tmpZoomData
				});
				return Promise.resolve();
			})
			.catch(err => {
				// errors displayed via global props.messages
				return Promise.reject();
			})
	}
}


// TODO: below function
export function zoomDeleteImportedItems () {
	return { type: ZOOM_IMPORTS_DELETE }
}



// export function zoomDeleteImportedItems (oauthUserID) {
// 	return function (dispatch) {
// 		const requestObj = {
// 			method: 'DELETE',
// 			url: `${apiConfig.zoomDeleteImportedItems}`, //?oauthUserID, 
// 		};

// 		return request(requestObj, ZOOM_ERROR)
// 			.then(resp => {
// 				dispatch({ type: ZOOM_CLEAR_RECORDINGS })
// 				var newUserOauthObject = getState().user.data.oauth;
// 				delete newUserOauthObject.zoom
// 				dispatch({
// 					type: OAUTH_REVOKE,
// 					payload: newUserOauthObject
// 				})
// 				dispatch({ type: MESSAGE_ADD, payload: 'Zoom access successfully deauthorized.' });
// 				return Promise.resolve();
// 			})
// 			.catch(err => {
// 				console.log('FALIURE - zoomRevokeAuthorization err: ', err)
// 				// errors displayed via global props.messages
// 				return Promise.reject();
// 			})
// 	}
// }