import _ from 'lodash';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';

import {
	capitalize,
	formatLocalDate
} from 'utils/index'

// Landing subhead requires withDragDropContext
import withDragDropContext from 'Widgets/withDragDropContext.jsx';


import LandingSidebar from './Landing.Sidebar.jsx';
import LandingSubhead from './Landing.Subhead.jsx';
import { LoadingLips } from 'Widgets/LoadingLips.jsx';
import PageLanding from '../Layout/page.landing.jsx';
import ZoomImportsDropdown from './ZoomImports.Dropdown.jsx'

import Copyright from 'Widgets/Copyright.jsx';
import Message from 'Widgets/Message.jsx';
import ModalConfirmAction from './Modal.Confirm.Action.jsx'

import { ROUTES } from 'Constants/app'

import {
	closeModal,
	dismissMessage,
	openModal,
} from 'actions/app';

import {
	fetchFolders,
    selectItem,
	createFolderItem,
	updateItem
} from './actions/';
	
import { 
	zoomDeleteImportedItems,
	zoomFetchImportedItems,
	zoomRetainData
} from './actions/zoom';

/**
 * retainData: true
 * https://localhost:8080/z/f75a6e7d-69be-411f-b3cf-bfece6f120e2
 * https://localhost:8080/z/fce5e545-a95c-4c2c-9567-900fe4422a77
 * https://localhost:8080/z/3e90a320-00e5-4f6d-be49-211036c8ba8c
 * 
 * Delete Pending True:
 * https://localhost:8080/z/9b7e9bbb-0770-4d14-887b-4632291dd8b2
 */

class ZoomImports extends Component {
	constructor(props) {
		super(props);

		this.tblColStyle = {
			1: { width: '55%', textAlignLast: 'left' },
			2: { width: '20%', textAlignLast: 'left' },
			3: { width: '15%', textAlignLast: 'left' },
			5: { width: '10%', textAlignLast: 'right', overflow: 'initial' },
		}

		this.state = {
			displayModal: false,
			showRetainDataBtn: false,
			showRetainDataLoading: false,
			sort: {
				key: 'name',
				order: 'asc'
			},
		};
	}
	
	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext () {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
	};

	componentDidMount () {
		/**
		 * Load zoom imports. 
		 * NOTE: We do this each time component mounts to ensure the list is fresh
		**/

		const {
			zoomImports,
		} = this.props;

		if (null === zoomImports) {
			this.props.zoomFetchImportedItems(this.props.match.params.oauthUserID || false)
				// if this.props.match.params.oauthUserID is undefined, an error is thrown
				.catch(err => {
					this.props.history.push(`${ROUTES.LANDING}/0`);
				})
		}
	}

	static getDerivedStateFromProps (props) {
		if (props.modalOpen === undefined) return null;
		return {
			displayModal: props.modalOpen.name 
		}
	}

	componentWillUnmount() {
		this.props.zoomDeleteImportedItems();
		this.props.selectItem(null);
		this.props.closeModal();
		this.props.dismissMessage();
	}

	render() {
		const {
			zoomImports,
		} = this.props;

		const {
			showRetainDataLoading
		} = this.state

		let showRetainDataBtn = false;
		let zoomListMsg = 'Would you like to retain these imports from Zoom?'			// no retain or delete pending		

		if (null !== zoomImports) {
			// Prepare & order folder contents - ie, childItems
			let orderedItems = [];
			_.forEach(zoomImports, function (value, key, object) {
				if ('oauthUser' === key ) return
				orderedItems.push(value);
			});
			orderedItems = _.orderBy(orderedItems, 'dateCreated', 'desc');

			// Ordered list
			var renderedItemsList = this.renderItemsList(orderedItems)


			// Retain data button
			var {
				datePendingDelete = false,
				retainData,
			} = zoomImports.oauthUser;
			showRetainDataBtn = !retainData && 0 !== renderedItemsList.length;


			// List message
			if (retainData) {
				zoomListMsg = 'The below imports from Zoom are set to be retained.'			// retain set
			}
			else if (datePendingDelete) {
				zoomListMsg = 'The below imports from Zoom are set to be deleted. Would you like to retain them instead? '	// delete set
			}
		}

		let { sort } = this.state, tblColStyle = this.tblColStyle;

		return (
			<PageLanding folderTitle={'Your Zoom Imports'} >
				{this.renderModal()}

				<div className="dashboard" onClick={this.handleClickLanding}>
					<Copyright />

					<LandingSubhead zoom={true} />

					<div className="dashboard-content">
						<div className="project-thumbs">
						{ null === zoomImports 
								? <div className="container">
									{this.renderMessages()}
									<LoadingLips />
								</div>
								: <div className="container">
									{this.renderMessages()}
									<div style={{ paddingBottom: '1rem', fontStyle: 'unset', display: 'flex', 'justifyContent': 'space-between' }}>
										<Message textClass={showRetainDataBtn ? 'danger' : 'success'}
											style={{ fontStyle: 'unset' }}
											dismissable={false}>
											{ zoomListMsg }
										</Message>
										{showRetainDataBtn
											? <button
												//disabled={this.props.disabled}
												onClick={this.handleRetainDataClick}
												tooltip=""
												className="btn btn-primary" >
												<i style={{ paddingRight: '5px' }}
													className={`far ${showRetainDataLoading ? 'fa-spinner fa-spin spinner' : 'fa-check'}`}
												//className={`far fa-spin fa-spinner`}
												/>
													Retain Data
											</button>
											: null
										}
										</div>
									<div className="col-12" style={{ padding: '0px' }}>
										<div className="table dark" data-sort="table">
											<div className="table-header dark">
												<div style={tblColStyle[1]} className={`cell header ${sort.order}${'name' === sort.key ? 'Sort' : null}`}>
													<button className='btn-link' onClick={e => this.onHeaderClick(e, 'name')}>Import Name</button>
												</div>
												<div style={tblColStyle[2]} className={`cell header ${sort.order}${'email' === sort.key ? 'Sort' : null}`}>
													<button className='btn-link' onClick={e => this.onHeaderClick(e, 'email')}>Date Imported</button></div>
												<div style={tblColStyle[3]} className={`cell header text-primary`}>Status</div>
												<div style={tblColStyle[5]} className="cell header" />

											</div>
											<div className="table-body">
												{0 === renderedItemsList.length
													? <i style={{paddingTop: '1rem'}}><br />No imports to display</i>
													: renderedItemsList
												} 
											</div>
										</div>
									</div>
								</div>
						}
						</div>
					</div>
					<LandingSidebar />
				</div>
			</PageLanding>
		);
	}

	/** Sorts members table by key */
	onHeaderClick = (e, key) => {
		e.stopPropagation();
		e.preventDefault();
		this.setState({
			sort: {
				key,
				order: 'asc' === this.state.sort.order ? 'desc' : 'asc'
			}
		})
	}
	
	/** renders table body contents - list of items imported from zoom */
	renderItemsList = (orderedItems) => {
		return (orderedItems.map((item, i) => (
			<div className="table-row dark" key={i} onClick={ () => this.handleItemClick(item.itemID) }>
				<div className="cell" style={this.tblColStyle[1]}>{item.title}</div>
				<div className="cell" style={this.tblColStyle[2]}>{formatLocalDate(item.dateCreated)}</div>
				<div className="cell" style={this.tblColStyle[3]}>{this.renderStatus(item)}</div>
				<div className="cell" style={this.tblColStyle[5]}>
					<ZoomImportsDropdown
						disabled={false}
						itemID={item.itemID}
						handleItemOpen={this.handleItemOpen}
						onClick={() => this.handleItemClick(item.itemID)}
					/>
				</div>
			</div>
		)))
	}

	renderStatus = (item) => {
		const {
			datePendingDelete = false,
			dateRetainDataSet = false,
			retainData,
		} = this.props.zoomImports.oauthUser;

		let date, className = '', title = null;
		
		if (retainData) {
			title = <i>{capitalize('Retain set on')}</i>
			className = 'text-success'
			date = formatLocalDate(dateRetainDataSet)
		}
		else if (datePendingDelete) {
			title = <i>{capitalize('Delete Pending on')}</i>
			className = 'text-danger'
			date = formatLocalDate(datePendingDelete) 
		}
		else {
			title = <i>{capitalize('imported')}</i>
			className = 'text-success'
			date = ''
		}

		return (
			<span>
				<span className={className}>{title}</span>
				<small>{date}</small>
			</span>
		)
	}

    renderMessages = () => {
        const {message, error} = this.props.messages
        return (
            <Message message={message} textClass={error ? 'danger' : 'success'}/>
        );
	}

	/** Renders a modal if present */
	renderModal = () => {
		const data = this.props.modalOpen.data || null

		switch(this.state.displayModal) {
			case 'modalConfirmAction': return <ModalConfirmAction />;
			
			default: return null;			
		}
	}
    
	// handleClickLanding = e => {
    //     // sets selectedItem and selectedItemAnalysis to null
    //     // if (this.props.selectedItem !== null) this.props.selectItem(null);
	// };

	handleItemClick = (itemID, itemAnalysesKey=0) => {
		this.props.selectItem(this.props.zoomImports[itemID], itemAnalysesKey);
	};

	handleItemOpen = (itemID, itemAnalysesKey=0) => {
		const item = this.props.zoomImports[itemID]
		this.props.selectItem(item);
		this.props.history.push(`${ROUTES.ANALYSIS}/${item.itemAnalyses[itemAnalysesKey].itemAnalysisID }`);
	};
	
	handleRetainDataClick = () => {
		const oauthUserID = this.props.zoomImports.oauthUser.oauthUserID
		this.setState({ showRetainDataLoading: true })
		this.props.zoomRetainData(oauthUserID) 
			.finally(() => {
				this.setState({ showRetainDataLoading: false })
		})
	};
}

function mapStateToProps(state) {
	const {account, folderItems, folders, user} = state
	return {
        // folders:    folders,                      //loading, error, message
        
        // folderID:   folders.data.folderID,        //id of parent folder is not itemID=0
        // folderItem: folders.data.folderItem,      //details of current folder if its not itemID=0
        
        // selectedItem:  folderItems.selectedItem,  //Currently selected item, if any
        // selectedItemAnalysis: folderItems.selectedItemAnalysis,  //Currently selected item, if any
        
		// initials:	user.data.initials,
        // userError:  user.error,
		// userID:     user.data.userID,

		planData:   account.planData,
		usageData: 	account.usageData,

        modalOpen: state.modalOpen,
		messages: state.messages,
		
		zoomImports: state.zoomImports,
		// zoomIsAuthorized: state.zoom.isAuthorized || false,

	};
}

const mapDispatchToProps = {
    closeModal,
	createFolderItem,
    dismissMessage,
	fetchFolders,
    openModal,
    selectItem,
	updateItem,

	zoomDeleteImportedItems,
	zoomFetchImportedItems,
	zoomRetainData
};

// Landing subhead requires withDragDropContext
export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	), withDragDropContext)(ZoomImports);