import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const ExportAnalysisMenuBtn = ({ canExport, handleOnClick, loading }) => (
	<MenuBtn
		label={I18n.get('Export Analysis')}
		title={ I18n.get('Download Analysis')  + (!canExport ? I18n.get(' - available in upgraded plans.') : '')}
		onClick={e => handleOnClick(e)}
		disabled={loading || !canExport}
		iconClass={
			loading
				? 'spinner fa-spin'
				: 'file-download text-plum'
		}
	/>
);

export default ExportAnalysisMenuBtn;