import React from 'react';
import { Link } from "react-router-dom";

const MenuLink = ({ to, label, title, iconClass, iconTransform, }) => (
	<Link
        to={to}
		className='dropdown-item'>
		<i className={'far fa-fw fa-' + iconClass} data-fa-transform={iconTransform} />
		{' ' + (label || title) }
	</Link>
);

export default MenuLink;
