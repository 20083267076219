import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import BackToTheFutureButton from 'Widgets/BackToTheFutureButton.jsx'
import HelloUser from 'Widgets/Navbar.HelloUser.jsx';
import IconUpgrade from 'Widgets/IconUpgrade.jsx';
import IconUploadsInProgress from 'Widgets/IconUploadsInProgress.jsx'
import Page from './page.jsx'


import {PLANID} from 'Constants/app';

/**
 * NB!!! Used as page layout for more pages than just landing
 */
class PageLanding extends Component {

	render () {
		return (
			<Page pageClasses="page-dashboard">
				<div className="navbar fixed-top navbar-inverse bg-inverse">
					<div className="container-fluid">
						<div className="row">
							<div className="col">
								<div className="navbar-left">{this.renderNavbarLeft()}</div>
								<div className="navbar-right"><HelloUser /></div>
								<div className="navbar-engage">{this.renderNavbarEngage()}</div>
								<div className="navbar-title">{this.renderNavBarTitle()}</div>
							</div>
						</div>
					</div>
				</div>

				<div className="main main-dashboard">{this.props.children}</div>
			</Page>
		)
	}

	renderNavbarLeft = () => {
		// if we choose to render the back button, we need to pass down renderBackButton={true} otherwise,
		// it will not pop up
		if (this.props.renderBackButton === true) {
			return (
				<BackToTheFutureButton goBack={this.props.history.goBack} btf={false} />
			);
		}
	};

	renderNavbarEngage = () => {
		const { history, planType } = this.props;
		return (
			<div className="navbar-nav">
				<IconUploadsInProgress />
				{(PLANID.FREE[planType] || PLANID.TRIAL === planType)
					? <IconUpgrade history={history} />
					: null
				}
			</div>
		)
	}

	renderNavBarTitle = () => {
		// if ( true ){
		if (this.props.folderTitle) {
			return <h4>{this.props.folderTitle}</h4>;
		}
	};
}

function mapStateToProps(state) {
	return {
        planType: state.account.planType || 'BASIC-2017-01-13'
	};
}

PageLanding = compose(withRouter, connect(mapStateToProps, {}))(PageLanding);
export default PageLanding;
