import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import ReactQuill from 'react-quill';

import { Loading } from 'Widgets/Loading.jsx';

import {
	getAnalysisLob,
	itemAnalysisLobUpdate
} from '../actions/index';

const EMPTY_DELTA = {ops: []};

var modules = {
	toolbar: [
		[{header: [1, 2, 3, false]}],
        ['bold', 'italic', 'underline'],   //, 'strike'
        
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'indent': '-1'}, { 'indent': '+1' }],     

        [{ 'color': [] }, { 'background': [] }],

        ['link']
        
    ],

    clipboard: {
        matchVisual: false  // Avoids matching pasted goodies, decreasing paste time
    },

    // magicUrl: true
    magicUrl: {
        globalRegularExpression: /(https?:\/\/\.|mailto:)[\S]+/gi,
        urlRegularExpression: /(https?:\/\/[\S]+)|(mailto:[\S]+)/i
      }
};

class Transcription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autosaveInterval: false,    // false if no interval, otherwise setInterval for autosaving
            saved: true,                // True if save() && no onChange() calls. Assume no changes on construction
            saving: false,
            resetting: false,
			text: EMPTY_DELTA           // Text in editor. Start with an empty Delta
		};
    }

    componentDidMount() {
		// Prevents setState() being called on unmounted component while waiting for Promise to return
		this._isMounted = true;
		
		// Load transcription lob if not already loaded
		const {
            itemAnalysisLOBs,
            itemAnalysisID, 
            transcriptionKey,        // <Transcription /> is only called if transcriptionKey is set
        } = this.props;
		const lobs    = itemAnalysisLOBs[itemAnalysisID] || {}
		const loading = lobs[transcriptionKey + 'Loading']

		// 'LOAD' - needs loading. false | true has loaded
		if( 'LOAD' === loading ) {
			this.props.getAnalysisLob(itemAnalysisID, transcriptionKey)
		}		
    }

	componentWillUnmount() {
        this.save(); // Clears autosaveInterval
        clearInterval(this.state.autosaveInterval); // Clear incase save fails
        this._isMounted = false;
	}

	render() {
        const {
            maxHeight,
            locked, 
            isItemAnalysisOwner, 
            itemAnalysisLOBs,
            itemAnalysisID, 
            transcriptionKey,        // <Transcription /> is only called if transcriptionKey is set
        } = this.props;

        
        const lobs    = itemAnalysisLOBs[itemAnalysisID] || {}
        const loading = lobs[transcriptionKey + 'Loading'] || false;
        if( loading ) return <div style={{display: this.props.active ? 'inline' : 'none'}}><Loading /></div>
        
        const transcription = lobs[transcriptionKey] || EMPTY_DELTA; // Ops array for Editor composition
        const { resetting, saving} = this.state;

        const saveClass = classNames({
            fal: saving ? false : true, 
            'fa-save': saving ? false : true,
            saving: saving ? true : false
        })

        // FOR RESET
        // const resetClass = classNames({
        //     far: true, 
        //     'fa-clone': true,
        //     restting: resetting
        // })

		return (
            <div style={{
                maxHeight: maxHeight,
                display: this.props.active ? 'inline' : 'none'
                }}>
                {!isItemAnalysisOwner || locked
                ? null
                : <div className="editor-ql-btns">
                    <i className={saveClass} 
                        disabled={saving} 
                        onClick={this.save}
                        style={ saving ? {fontSize: '.8rem'} : {} }
                        title="Save. Cmd-S / Ctrl-S"
                        >{ saving ? 'Saving...' : null }
                    </i>
                    {/* FOR RESET
                    <i className={resetClass} 
                        disabled={resetting} 
                        onClick={this.reset}
                        title="Reset to original transcription"
                        >
                    </i> */}
                </div>
                }
                <ReactQuill
                    ref="reactQuillRef"
                    bounds={'.quill'}
                    defaultValue={transcription}
                    modules={modules}
                    readOnly={ !isItemAnalysisOwner || locked }
                    theme={!isItemAnalysisOwner || locked ? 'bubble' : 'snow'}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                />
            </div>
		);
    }

	handleKeyDown = e => {
		if (
			(e.keyCode === 13 && e.metaKey) ||              //Cmd+Enter or Ctrl+Enter to Submit
			(e.keyCode === 83 && (e.ctrlKey || e.metaKey))  //Ctrl-S to Save
		) {
			e.preventDefault();
			this.save();
		}
	};

	handleChange = (content, delta, source, editor) => {
		this.setState({
			text: editor.getContents(),
			saved: false
		});

		// Set autosaveInterval to every two minutes 120000 milliseconds
		if (!this.state.autosaveInterval) {
			this.setState({
				autosaveInterval: setInterval(this.save, 120000)
			});
        }
	};

	handleBlur = (previousRange, source, editor) => {
		this.save(editor);
	};

	save = () => {   
        if( this.state.saved === true ) return

        const {isItemAnalysisOwner, locked, itemAnalysisID, transcriptionKey } = this.props;      // loading indicates saving
        
        //Dont save if...
        if (!isItemAnalysisOwner || locked ) return;
        
        this.setState({saving: true})

        // Save... IE, update
        this.props.itemAnalysisLobUpdate(itemAnalysisID, transcriptionKey, this.refs['reactQuillRef'].getEditor().getContents())
        .then(resp => {
            // Clear autosave and update saving state
            clearInterval(this.state.autosaveInterval);
            this._isMounted && this.setState({
                saving: false,
                saved: true,
                autosaveInterval: false
                });
        })
        .catch( err => {
            this._isMounted && this.setState({
                saving: false
                });
        });
    };
    
    /**
     * NOTE: Unimplemented & untested
     *
	reset = () => {  
        console.log('reset triggered') 
        this.setState({resetting: true})
        const {isItemAnalysisOwner, locked, itemAnalysisID, transcriptionKey } = this.props;      // loading indicates saving
        
        //Dont reset if...
        if (!isItemAnalysisOwner || locked ) return;
        
        // Reset... IE, update
        this.props.itemTransciptionReset(itemAnalysisID, transcriptionKey)
        .then(resp => {
            // Clear autosave and update saving state
            clearInterval(this.state.autosaveInterval);
            this._isMounted && this.setState({
                resetting: false,
                saved: true,
                autosaveInterval: false
                });
        })
        .catch( err => {
            this._isMounted && this.setState({
                resetting: false
                });
        });
    };
    */
}

const mapStateToProps = state => {
	return {
        itemAnalysisLOBs: state.itemAnalysisLOBs,
	};
};

export default connect( mapStateToProps, {
		getAnalysisLob,
		itemAnalysisLobUpdate 
	})(Transcription);
