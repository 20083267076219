import {CUNAUTH_USER, OPEN_MODAL, CLOSE_MODAL} from 'Constants/actions';

const INITIAL_STATE = false;


export default function(state = INITIAL_STATE, action) {

	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
            return INITIAL_STATE;
            
		// should be a better way of doing this. Basically,
		// if it is not passed a name, it will just be set to true
		// otherwise it will be set to it's name
		case OPEN_MODAL:
			const {name, xID, data} = action.payload;
			if (typeof name === 'object') {
				return true;
			}
			if (typeof name === 'string') {
				return {name, xID, data};
			}
		case CLOSE_MODAL:
            return false;
            
		default:
			return state;
	}
}
