import React, {Component} from 'react';

/**
 * Renders a legend (color circles + dataset titles) for the chart.
 * This displays below the chart and zoom scrubber.
 */
class DataVisualizerLegend extends Component {
	render() {
		return (
			<div className={"datavisualizer-legend-container"}>
				<ul className={"legend"}>
					{this.props.leftDatasets.map( (dataset, index) => (
						<li key={dataset.dsTitle + '-' + index}>
							<span
								className={'color'}
								style={{
									display: 'inline-block',
									width: '1em',
									height: '1em',
									backgroundColor: this.props.colorScale[ index % this.props.colorScale.length ]
								}}
							/>
							{dataset.dsTitle}
						</li>
					))}
				</ul>
			</div>
		);
	}
}

export default DataVisualizerLegend;