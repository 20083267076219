import {
	CUNAUTH_USER,
	PAYMENT_ERROR,
	PAYMENT_INITIALIZE_STATE,
	PAYMENT_SET_STATUS,

	//unsused
	PAYMENT_CLEAR_MESSAGES,
	PAYMENT_LOADING,
	PAYMENT_MESSAGE
} from 'Constants/actions.js'

const INITIAL_STATE = {
	response: null,
	status: null,
	loading: false,
	
	data: null,
	error: null,
	message: null,
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {

		case CUNAUTH_USER:
		case PAYMENT_INITIALIZE_STATE:
			return INITIAL_STATE;

		case PAYMENT_SET_STATUS: {
			/** 
			 * @Object payload
			 * @Object payload.response - response from stripe or lambda API calls
			 * @String payload.status indicates what payment flow to put user through when they are upgrading:
			 
			 * 		null: 				payment flow not entered, or exited
			 *		CONFIRMING_CHANGE: 	changed confirmed by customer, confirming change with server (ie, trigger actual change is happening)
			 * 		PREVIEW_CHANGE: 	preview plan changes to customer before confirming change
			 * 		SETUP_INTENT: 		setup_intent{} exists - get card details and enter authentication
			 * 		CHANGE_SUCCESS: 	plan change successfully implemented
			 * */
			return {
				...state,
				response: action.payload.response ? action.payload.response : {...state.response},
				status: action.payload.status,
				loading: false,
			}
		}

		// use?
		case PAYMENT_LOADING: {
			// PAYMENT_LOADINGs payload needs to be either null or a string which will be used as a message.
			return {
				...state,
				loading: action.payload
			}
		}

		//unused

		case PAYMENT_ERROR: {
			return {
				...state,
				message: null,
				loading: null,
				error: action.payload
			}
		}

	

		case PAYMENT_MESSAGE: {
			return {
				...state,
				error: null,
				loading: null,
				message: action.payload,
			}
		}
		
		case PAYMENT_CLEAR_MESSAGES: {
			return {
				...state,
				message: null
			}
		}

		default:
			return state
	}
}