import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';


import AnalysisSettingsDropdown from '../Analysis/Analysis.Settings.Dropdown.jsx';
import HelloUser from 'Widgets/Navbar.HelloUser.jsx';
import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import IconUpgrade from 'Widgets/IconUpgrade.jsx';
import IconUploadsInProgress from 'Widgets/IconUploadsInProgress.jsx'
import Page from './page.jsx'

import { openModal } from 'actions/app';

import { PLANID } from 'Constants/app';

class PageAnalysis extends Component {
	render () {
		const { selectedItem } = this.props;

		// fixes silly bug breaking thing
		if (!selectedItem) return null;

		return (
			<Page pageClasses="page-project">
				<div className="navbar fixed-top navbar-inverse bg-inverse">
					<div className="container-fluid">
						<div className="row">
							<div className="col">
								<div className="navbar-left">{this.renderNavbarLeft()}</div>
								<div className="navbar-right"><HelloUser /></div>
								<div className="navbar-engage">{this.renderNavbarEngage()}</div>
								<div className="navbar-title">{this.renderNavBarTitle()}</div>
							</div>
						</div>
					</div>
				</div>

				<div className="main">{this.props.children}</div>
			</Page>
		)
	}

	renderNavbarLeft = () => {
		const { selectedItem, selectedItemAnalysis, userID, goBack } = this.props;
		
		// page is still loading
		if (!goBack) return <div className="navbar-nav" />

		return (
			<div className="navbar-nav">
				<ButtonIcon
					onClick={() => goBack()}
					tooltip="Back"
					btnClass="btn btn-outline-secondary"
					iconClass="fas fa-long-arrow-left"
				/>

				{userID === selectedItemAnalysis.ownerID
					? <AnalysisSettingsDropdown
						locked={selectedItem.locked} />
					: null}

				{selectedItem.confidential ? (
					<ButtonIcon
						onClick={() => this.props.openModal(
							'modalConfidentialityNotice', '',
							{
								title: 'Privacy Guard: Confidentiality Notice',
								info: 'This project contains confidential and proprietary data intended for the exclusive viewing and access by authorized collaborators.'
							}
						)}
						label="Confidential"
						btnClass="btn confidential text-danger"
						iconClass="far fa-shield-check"
						iconStyle={{marginRight: 0}}
					/>
				) : null}
			</div>
		);
	};

	renderNavbarEngage = () => {
		const { history, planType } = this.props;
		return (
			<div className="navbar-nav">
				<IconUploadsInProgress />
				{(PLANID.FREE[planType] || PLANID.TRIAL === planType)
					? <IconUpgrade history={history} />
					: null
				}
			</div>
		)
	}

	renderNavBarTitle = () => {
		const { selectedItem } = this.props;
		const title = selectedItem.title || '';
		return (
			<h4>
				{selectedItem.locked
					? <i className="far fa-lock-alt text-danger" style={{ 'paddingRight': '10px', fontSize: '1.2rem' }} />
					: ''}
				{title}
			</h4>
		)
	}
}

function mapStateToProps (state) {
	return {
		planType: state.account.planType || 'BASIC-2017-01-13',
		selectedItem: state.folderItems.selectedItem,
		selectedItemAnalysis: state.folderItems.selectedItemAnalysis,
		userID: state.user.data.userID
	};
}

export default compose(
	withRouter,
	connect(mapStateToProps, { openModal }))(PageAnalysis);
