import {
	ACCOUNT_REHYDRATE,
	PAYMENT_ERROR,
	PAYMENT_INITIALIZE_STATE,
	PAYMENT_SET_STATUS,

	//unused
	PAYMENT_CLEAR_MESSAGES,
	
	PAYMENT_LOADING,
	PAYMENT_MESSAGE,
} from 'Constants/actions';

import {PLANID} from 'Constants/app';

import {request, getBillingDetails} from 'actions/index';

import {apiConfig} from 'config';


export function billingDetailsUpdateSetupIntent() {
	return function(dispatch) {
		dispatch({type: PAYMENT_LOADING, payload: true})

		// POST with a string
		const url = `${apiConfig.billingDetailsUpdateSetupIntent}?`;

		const requestObj = {
			method: 'post',
			url
		};

		return request(requestObj, PAYMENT_ERROR)
			.then(resp => {
				dispatch({
					type: PAYMENT_SET_STATUS, 	// sets loading to false
					payload: {
						status: 'BILLING_SETUP_INTENT',
						response: {...resp}
					}});
				return Promise.resolve(resp);
			})
			.catch(err => {
				dispatch({type: PAYMENT_LOADING, payload: false});
				return Promise.reject(err)
			});
	};
}

// Updates users billing details
export function billingDetailsUpdate(stripePaymentMethodID) {
	return function(dispatch) {
		// clears any left over messages on beginning of billingDetailsUpdate
		dispatch(paymentReducerClearMessages());
		dispatch({type: PAYMENT_LOADING, payload: 'Updating billing details...'});

		const requestObj = {
			method: 'post',
			url: `${apiConfig.billingDetailsUpdate}?stripePaymentMethodID=${stripePaymentMethodID}`
		};

		request(requestObj, PAYMENT_ERROR)
		.then(resp => {
			dispatch({
				type: PAYMENT_MESSAGE,
				payload: 'Billing details successfully updated.'
			});
			dispatch(getBillingDetails());
			return;
		});
		// below not used, error handled by PAYMENT_ERROR
		// .catch(function(error) {
		// 		return error;
		// });
	};
}

// will clear the messages inside the payment reducer
export function paymentReducerClearMessages() {
	return {type: PAYMENT_CLEAR_MESSAGES};
}

/**
 * Initializes global payment object to its initial state
 */
export function paymentIntializeState() {
	return function (dispatch) {
		dispatch({type: PAYMENT_INITIALIZE_STATE})
	}
}

/**
 * Requests a payment intention
 * @param {String} planID 
 * @param {String} paymentPeriod 
 */
export function stripeSetupIntent(planID, paymentPeriod) {
	return function(dispatch) {
		dispatch({type: PAYMENT_LOADING, payload: true})

		// POST with a string
		const url = `${apiConfig.paymentChangePlan}?` +
					`changePlan=false&` +
					`planType=${planID}&` +
					`paymentPeriod=${paymentPeriod.toUpperCase()}`;

		const requestObj = {
			method: 'post',
			url
		};

		return request(requestObj, PAYMENT_ERROR)
			.then(resp => {
				// setupintent exists - go thru SETUP_INTENT authflow
				// NOTE: setupIntent.status === requires_payment_method - card does not exist
				// NOTE: setupIntent.status === requires_confirmation - card exists but needs confirming - we re-request card
				if(resp.setupIntent)
					dispatch({
						type: PAYMENT_SET_STATUS, 	// sets loading to false
						payload: {
							status: 'SETUP_INTENT',
							response: {...resp}
						}});
				// setupintent does not exist but 200 resp, go through to PREVIEW_CHANGE
				else 
					dispatch({
						type: PAYMENT_SET_STATUS, 	// sets loading to false
						payload: {
							status: 'PREVIEW_CHANGE',
							response: {...resp}
						}
					});

				return Promise.resolve(resp);
			})
			.catch(err => {
				dispatch({type: PAYMENT_LOADING, payload: false});
				return Promise.reject(err)
			});
	};	
}

/**
 * Calls _accountUpgrade() with condition to preview the change, not make the change (changePlan is false)
 * @param {Object} upgradeValues 
 */
export function accountPreviewUpgrade(upgradeValues) {
	return function(dispatch) {
		return dispatch(_accountUpgrade(upgradeValues, false))
	}
}

/**
 * Calles _accountUpgrade() with condition to actually make change (changePlan is true)
 * @param {Object} upgradeValues 
 */
export function accountProcessUpgrade(upgradeValues) {
	return function(dispatch) {
		return dispatch(_accountUpgrade(upgradeValues, true))
	}
}

/**
 * API call for account upgrade, both to preview upgrade and actually make upgrade.
 * @param {Object} upgradeValues 
 * @param {Boolean} changePlan true to upgrade account, false to preview account upgrade
 */
function _accountUpgrade(upgradeValues, changePlan) {
	return function(dispatch) {
		// Update status only when (changePlan) - not needed for Auth flow
		(changePlan)
			? dispatch({type: PAYMENT_SET_STATUS, payload: {status: 'CONFIRMING_CHANGE'}}) 
			: null

		const {coupon, planID, paymentPeriod, prorationDate, stripePaymentMethodID} = upgradeValues

		// POST with a string (posting object is broken)
		let url = `${apiConfig.paymentChangePlan}?` +
					`changePlan=${changePlan}&` +
					`planType=${planID}&` +
					`paymentPeriod=${paymentPeriod.toUpperCase()}&` +
					`proration_date=${prorationDate}&`;

		// stripePaymentMethodID only present when setupIntent returned (IE, new stripe customer)
		url = stripePaymentMethodID ? url = url + `stripePaymentMethodID=${stripePaymentMethodID}` : url;

		url = coupon ? url + `&coupon=${coupon}` : url;

		const requestObj = {
			method: 'post',
			url
		};

		return request(requestObj, PAYMENT_ERROR)
			.then(resp => {
				//changePlan === true: account upgrade success, display success, rehydrate account, get new billing info
				if(changePlan) {
					// get updated billing info for upcoming invouces, etc.
					dispatch(getBillingDetails());
					dispatch({type: ACCOUNT_REHYDRATE, payload: resp});
					dispatch({
						type: PAYMENT_SET_STATUS, 		//set loading to false
						payload: {
							status: 'CHANGE_SUCCESS',
							response: {...resp}
						}});
					updateIntercom(getState().user.data, resp)
				} 
				//changePlan === false: set payment.status to PREVIEW_CHANGE
				else {
					dispatch({
						type: PAYMENT_SET_STATUS, 		//set loading to false
						payload: {
							status: 'PREVIEW_CHANGE',
							response: {...resp}
						}});
				}
				return Promise.resolve(resp);
			})
			.catch(err => {
				// Invalid coupon (lambda err), error from Stripe
				// return err; // previously
				dispatch({type: PAYMENT_LOADING, payload: false});
				return Promise.reject(err)
			});
	};
}

/**
  * Used to cancel a subscription
  * @returns Promise
  */
export function accountPlanCancel() {
	return function(dispatch) {
		dispatch(paymentReducerClearMessages());
		dispatch({type: PAYMENT_LOADING, payload: 'Cancelling your subscription.'});

		const requestObj = {
			method: 'POST',
			url: `${apiConfig.paymentChangePlan}?changePlan=true&planType=${PLANID.FREE.LIVE}&paymentPeriod=MONTHLY`
		};

		return request(requestObj, PAYMENT_ERROR)
        .then(resp => {
            dispatch({
                type: PAYMENT_MESSAGE,
                payload: `Your subscription has been successfully cancelled!`
            });
            // Update the accounts plan with the new plan data (in the resp)
            dispatch({type: ACCOUNT_REHYDRATE, payload: resp});
            
            // Refresh billingDetails information after a successful upgrade / downgrade
            dispatch(getBillingDetails());
            return;
        })
        .catch(err => {
            return {err};
        });
	};
}