import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import _ from './_';
import AccountReducer from './AccountReducer';
import AppReducer from './AppReducer';
import BillingDetailsReducer from './BillingDetailsReducer';
import CognitoReducer from './CognitoReducer';
import CollaboratorsReducer from './CollaboratorsReducer';
import EventTagsReducer from './EventTagsReducer';
import FoldersReducer from './FoldersReducer';
import ItemAnalysisReducer from './ItemAnalysisReducer';
import ItemAnalysisChartsReducer from './ItemAnalysisChartsReducer';
import ItemAnalysisDbsReducer from './ItemAnalysisDbsReducer';
import ItemAnalysisDbTagsReducer from './ItemAnalysisDbTagsReducer';
import ItemAnalysisMarkersReducer from './ItemAnalysisMarkersReducer';
import ItemAnalysisLobsReducer from './ItemAnalysisLobsReducer';
import ItemsReducer from './ItemsReducer';
import MembersReducer from './MembersReducer'
import MessageReducer from './MessageReducer';
import ModalReducer from './ModalReducer';
import PaymentReducer from './PaymentReducer';
import PlayerStateReducer from './PlayerStateReducer';
import ReportsReducer from './ReportsReducer';
import TemplatesReducer from './TemplatesReducer'
import UploadReducer from './UploadReducer';
import UserDashboardsReducer from './UserDashboardsReducer';
import UserDashboardTagsReducer from './UserDashboardTagsReducer';
import UserMarkersReducer from './UserMarkersReducer';
import ZoomImportsReducer from './ZoomImportsReducer';
import ZoomReducer from './ZoomReducer';

const rootReducer = combineReducers({
    _,
    account: AccountReducer,
	app: AppReducer,
	billingDetails: BillingDetailsReducer,
	collaborator: CollaboratorsReducer,
	eventTagItems: EventTagsReducer,
	folders: FoldersReducer,
	folderItems: ItemsReducer,
    form,
	itemAnalysis: ItemAnalysisReducer,
	itemAnalysisCharts: ItemAnalysisChartsReducer,
	itemAnalysisDbs: ItemAnalysisDbsReducer,
	itemAnalysisDbTags: ItemAnalysisDbTagsReducer,
    itemAnalysisLOBs: ItemAnalysisLobsReducer,
    itemAnalysisMarkers: ItemAnalysisMarkersReducer,
    messages: MessageReducer,
    members: MembersReducer,
	modalOpen: ModalReducer,
	payment: PaymentReducer,
	playerState: PlayerStateReducer,
	reports: ReportsReducer,
    templates: TemplatesReducer,
	userDashboards: UserDashboardsReducer,
	userDashboardTags: UserDashboardTagsReducer,
	userMarkers: UserMarkersReducer,
    uploader: UploadReducer,
	user: CognitoReducer,
	zoom: ZoomReducer,
	zoomImports: ZoomImportsReducer
});

export default rootReducer;
