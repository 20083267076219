import classNames from 'classnames';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { DragSource, DropTarget } from 'react-dnd';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import BackToTheFutureButton from 'Widgets/BackToTheFutureButton.jsx'
import ButtonHome from 'Widgets/ButtonHome.jsx'
import DragOverlay from 'Widgets/DragOverlay.jsx';


import { moveToNewFolder } from './actions/';
import { openModal } from 'actions/app'

import { ROUTES } from 'Constants/app';

const ItemTypes = {
	ITEM: 'ProjectThumb'
};

// monitor.getItem() gives you the item that was being dragged
const folderDropTarget = {
	drop(props, monitor, component) {
		const draggedItem = monitor.getItem();
		const targetItem = props.parentFolder;
		// accepts then entire draggedItem, the targetItemsID (in this case it's the targetItems folderID), and the targetItems title.
		// since this is only moving up one folder, and we're not given an ID, we need to call something else.
		props.moveToNewFolder(draggedItem, targetItem.folderID, 'parent');
	},
	canDrop(props, monitor) {
		const draggedItem = monitor.getItem();
		const targetItem = props.parentFolder;
		// basically if the targetItem does not have a parent folder, you can't drop it here.
		// Should only happen when on the landing page.
		if (targetItem === undefined) return false;
		return true;
	}
};

function collectDrop(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		// isOver is needed for hover in dropTarget
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
}

const LandingSubheadWrapper = ({ children }) => {
	return (
		<div onClick={e => e.stopPropagation()} className="dashboard-subhead">
				{children}
		</div>
	)
}

const LandingSubHeadActions = ({ children }) => {
	return (
			<div className="dashboard-actions">
				{children}
			</div>
	)
}

const LandingSubHeadTitle = ({ children }) => {
	return (
		<h5 className="dashboard-title">
			{children}
		</h5>
	)
}

class LandingSubhead extends Component {
	render () {
		return (
			this.props.zoom
			? this.renderZoomSubHead()
			: this.renderStandardSubHead()
		)
		
	}

	renderStandardSubHead = () => {
		const {
			iloading,
			floading,
			handleCreateFolder,
			previousFolderLink,
			selectedItem
		} = this.props;

		// react-dnd props
		const { connectDropTarget, isOver, canDrop } = this.props;

		if (
			selectedItem !== null &&
			selectedItem !== undefined &&
			!iloading &&
			!floading
		) {
			var selectedItemTitle = selectedItem.title;
		}
		const linkClasses = classNames({
			'btn btn-outline-secondary': true,
			// will be given the disabled className if the folderItems are loading
			disabled: iloading || floading
		});

		return connectDropTarget(
			<div><LandingSubheadWrapper>
				<LandingSubHeadActions>
					{previousFolderLink}
					<ButtonIcon
						disabled={iloading || floading}
						onClick={handleCreateFolder}
						tooltip={I18n.get('New folder')}
						btnClass="btn btn-outline-secondary"
						iconClass="fas fa-folder-plus"
					/>
					<Link
						to={ROUTES.DASHBOARDEDITPAGE}
						data-toggle="tooltip"
						title={I18n.get('Dashboards')}
						className={linkClasses}>
						<i style={{ fontSize: '1rem' }} className="far fa-bullseye-pointer" />
					</Link>
					<Link
						to={ROUTES.MARKERSEDITPAGE}
						data-toggle="tooltip"
						title={I18n.get('Markers')}
						className={linkClasses}>
						<i style={{ fontSize: '1rem' }} className="far fa-bookmark" />
					</Link>
				</LandingSubHeadActions>

				<LandingSubHeadTitle>
					{canDrop ? (
						<span className="msg msg-success" style={{ textTransform: 'uppercase' }}>
							{I18n.get('Drop here to move to parent')}
						</span>
					) : (
							selectedItemTitle
						)}
				</LandingSubHeadTitle>
				{/* we need these weird top, height and width dimensions until the css has been corrected. */}
				{isOver &&
					canDrop && (
						<DragOverlay
							top="1px"
							left="0"
							height="100%"
							width="100%"
						/>
					)}
			</LandingSubheadWrapper></div>
		);
	}

	renderZoomSubHead = () => {
		const {
			iloading,
			floading,
			selectedItem
		} = this.props;


		if (
			selectedItem !== null &&
			selectedItem !== undefined &&
			!iloading &&
			!floading
		) {
			var selectedItemTitle = selectedItem.title;
		}
		const linkClasses = classNames({
			'btn btn-outline-secondary': true,
			// will be given the disabled className if the folderItems are loading
			disabled: iloading || floading
		});

		return (
			<LandingSubheadWrapper>
				<LandingSubHeadActions>
					<ButtonHome />
					<Link
						to={ROUTES.DASHBOARDEDITPAGE}
						data-toggle="tooltip"
						title={I18n.get('Dashboards')}
						className={linkClasses}>
						<i style={{ fontSize: '1rem' }} className="far fa-bullseye-pointer" />
					</Link>
					<Link
						to={ROUTES.MARKERSEDITPAGE}
						data-toggle="tooltip"
						title={I18n.get('Markers')}
						className={linkClasses}>
						<i style={{ fontSize: '1rem' }} className="far fa-bookmark" />
					</Link>
				</LandingSubHeadActions>

				<LandingSubHeadTitle>{selectedItemTitle}</LandingSubHeadTitle>
			</LandingSubheadWrapper>
		);
	}
}

function mapStateToProps(state) {
	return {
		// account: state.account,
		floading: state.folders.loading,
		iloading: state.folderItems.loading,
		selectedItem: state.folderItems.selectedItem
	};
}

export default compose(
	connect(mapStateToProps, { moveToNewFolder }),
	DropTarget(ItemTypes.ITEM, folderDropTarget, collectDrop)
)(LandingSubhead);
