import {
	CUNAUTH_USER,
    DASHBOARD_TAGS_LOADING,

	DASHBOARD_TAG_CREATE,
	DASHBOARD_TAG_UPDATE,
	DASHBOARD_TAG_DELETE,
	DASHBOARD_TAGS_FETCH,
} from 'Constants/actions';
import _ from 'lodash';

const INITIAL_STATE = {
    loading: true,
	data: {}
};

// dependent on the dashboardsReducer
export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
            return INITIAL_STATE;
            
        case DASHBOARD_TAGS_LOADING:
			return {...state, loading: true};

		case DASHBOARD_TAG_UPDATE:
			const {dashboardTagID, updatedProp} = action.payload;
			return {
                ...state,
                loading: false,
				data: {
					...state.data,
					[dashboardTagID]: {
						...state.data[dashboardTagID],
						...updatedProp
					}
				}
			};

		case DASHBOARD_TAG_CREATE:
			return {
				...state,
                loading: false,
				data: {
					[action.payload.dashboardTagID]: {
						...action.payload
					},
					...state.data
				}
			};

		// used to delete a single dashboard tag item
		case DASHBOARD_TAG_DELETE:
			let newTagState = Object.assign({}, state);
			// if you do not deeply set this, it will mutate the original state and create issues.
			newTagState.data = Object.assign({}, state.data);
			delete newTagState.data[action.payload];
			return newTagState;

		case DASHBOARD_TAGS_FETCH:
			// payload is an object of dashboard tags
			return {...state, loading: false, data: action.payload};

	}
	return state;
}
