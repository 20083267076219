import React, { Component } from 'react';
import { connect } from 'react-redux';

import { INVITE_STATUS } from 'Constants/app';
import {
	acceptOrganizationInvite,
	changeActiveOrganization,
	declineOrganizationInvite,
	leaveOrganization
} from '../actions/index'

import BtnIcon from 'Widgets/ButtonIcon.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';

class OrganizationSettingsDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false,
			loadingA: false,
			loadingB: false
		};
	}

	render () {
		const { showDropdown } = this.state;
		return (
			<div className="dropdown show contextMenu" onMouseLeave={() => this.handleDDLeave()}>
				<BtnIcon
					disabled={this.props.disabled}
					onClick={() => this.setState({ showDropdown: !showDropdown })}
					tooltip=""
					btnClass="btn btn-link"
					iconClass="fas fa-cog"  //fa-ellipsis-h
				/>
				{showDropdown && this.renderedButtons()}
			</div>
		);
	}

	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
		const { loadingA, loadingB } = this.state;
		const {
			acceptOrganizationInvite,
			changeActiveOrganization,
			declineOrganizationInvite,
			leaveOrganization,
			organizationID,
			status
		} = this.props;		

		// User has been Invited
		const accept = (
			<MenuBtn
				label="Accept invite"
				disabled={loadingA}
				onClick={e => { this.handleCallApi(e, acceptOrganizationInvite, organizationID, 'loadingA' ) }}
				iconClass={this.state.loadingA ? 'spinner fa-spin' : 'user-plus text-success'}
			/>
		);

		const decline = (
			<MenuBtn
				label="Decline invite"
				disabled={loadingB}
				onClick={e => { this.handleCallApi(e, declineOrganizationInvite, organizationID, 'loadingB') }}
				iconClass={this.state.loadingB ? 'spinner fa-spin' : 'users-slash text-danger'}
			/>
		);

		// User has Accepted
		const activateOrganization = (
			<MenuBtn
				label="Set as default"
				disabled={loadingA}
				onClick={e => { this.handleCallApi(e, changeActiveOrganization, organizationID, 'loadingA') }}
				iconClass={this.state.loadingA ? 'spinner fa-spin' : 'check-double text-primary'}
			/>
		);

		const leave = (
			<MenuBtn
				label="Leave organization"
				disabled={loadingB}
				onClick={e => { this.handleCallApi(e, leaveOrganization, organizationID, 'loadingB') }}
				iconClass={this.state.loadingB ? 'spinner fa-spin' : 'users-slash text-danger'}
			/>
		);

		const dropdownMenuStyle = {
			lineHeight: 'initial',
			marginTop: '-2px',
			top: 'unset'
		}

		// renders all the options in a dropdown menu
		if (status === INVITE_STATUS.ACCEPTED) {
			return (
				<div className="dropdown-menu dropdown-menu-right" style={dropdownMenuStyle}>
					{this.props.isDefaultOrganization ? null : activateOrganization}
					{leave}
				</div>
			)
		}
		else {
			return (
				<div className="dropdown-menu dropdown-menu-right" style={dropdownMenuStyle}>
					{accept}
					{decline}
				</div>
			);
		}
	};

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};


	/** 
	 * Calls all API functions for dropdowns
	 * @param (Object) e incoming event
	 * @param (Function) function to call
	 * @param (String) organizationID
	 * @param (String) loadingKey key of loader to set to loading
	 */
	handleCallApi = (e, apiFunctionCall, organizationID, loadingKey, ) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({ [loadingKey]: true });

		apiFunctionCall(organizationID, true)
			.then(resp => {
				this.setState({ [loadingKey]: false });
				this.handleDDLeave();
			})
			.catch(err => {
				this.setState({ [loadingKey]: false });
				this.handleDDLeave();
			})
	}
}

export default connect(
	() => { return {} },
	{
		acceptOrganizationInvite, changeActiveOrganization, declineOrganizationInvite, leaveOrganization
	})(
	OrganizationSettingsDropdown
);
