import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PROGRESS_FLAGS } from 'Constants/app';
const {
	CALCULATING,
	// COMPLETED,
	// ERROR,
	PROGRESSING,
	// WARNING,
	UPLOADSTARTED,
} = PROGRESS_FLAGS

class ProjectThumbStatus extends Component {
	render () {
		const { transcodeProgress } = this.props;
		
		// FOR status via global state uploader object.
		const { itemID, mediaSource, uploader } = this.props;

		var percentage = false, progressFlag = PROGRESSING;

		if (uploader[itemID]) {
			percentage = `${uploader[itemID].percentage}%`
			progressFlag = uploader[itemID].progressFlag
		}

		// switch (progressFlag) {
		switch (transcodeProgress) {
			case UPLOADSTARTED:
				var status = ['zoom'].includes(mediaSource) ? `Importing from ${mediaSource}` : 'Uploading file'
				break;

			case PROGRESSING:
				var status = 'Preparing file'
				break;

			case CALCULATING:
				var status = `Calulating`
				break;
		}

        return (
            <div className="uploader-thumb-face">
				{percentage
					? <h1 className={`uploader-text-brand-primary`}>{percentage}</h1>
					: null
				}
				<h6 className={`uploader-text-brand-primary`}>{status}...</h6>
            </div>
        );
	}
}

function mapStateToProps(state) {
	const { uploader } = state;
	return {
		uploads: uploader.uploads || [], // default is undefined
		uploader
	};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectThumbStatus);
