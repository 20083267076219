import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import {itemAnalysisUpdate} from '../Analysis/actions/index';
import Modal from 'Widgets/Modal.jsx';
import {ROUTES} from 'Constants/app.js';

class ModalMarkerSelector extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};
    
	render() {
		const {selectedItem, userMarkers} = this.props;
		if (userMarkers === undefined || selectedItem === undefined) return null;
		return (
			<Modal size="modal-md">
                <div className="modal-header">
                    <div className="modal-subtitle">{I18n.get('Marker settings')} </div>
                    <div className="modal-title">{selectedItem.title}</div>
				</div>
				<div className="modal-body">
					<div className="row data-list-container">
						<div className="col-12 list-over-flow">
							<ul className="list-group">{this.renderMarkers()}</ul>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={this.context.closeModal}>
						{I18n.get('Done')}
					</button>
					<Link to={ROUTES.MARKERSEDITPAGE}>
						{I18n.get('Edit markers')} <i className="far fa-external-link" />
					</Link>
				</div>
			</Modal>
		);
    }

	renderMarkers = () => {
		const {userMarkers, selectedItemAnalysis} = this.props;
		// if there are no markers to select from, returns the message
		if (_.isEmpty(userMarkers))
			return <span style={{textAlign: 'center'}}>There are no markers to choose from.</span>;
		// grabs the markerItems array of selected item
		const selectedMarkerItems = selectedItemAnalysis.markers;
		const renderedMarkers = Object.keys(userMarkers)
			.map(markerID => {
				// checking if the item is currently inside the selectedMarkers or not.
				const isChecked = selectedMarkerItems.includes(markerID);
				const selectedSquare = classNames({
					fal: true,
					'fa-square': !isChecked,
					'fa-check-square': isChecked
				});
				return (
					<li
						onClick={() => this.handleUpdate(markerID, isChecked)}
						className="list-group-item"
						key={markerID}>
						{userMarkers[markerID].name}
						<i className={selectedSquare} aria-hidden="true" />
					</li>
				);
			})
			// filters out any null elements
			.filter(item => item !== null)
			// sorts the markers by name
			.sort((a, b) => {
				// grabbing the first child item since it is the title of the marker
				const markerA = a.props.children[0].toUpperCase();
				const markerB = b.props.children[0].toUpperCase();
				if (markerA < markerB) return -1;
				if (markerA > markerB) return 1;
				return 0;
			});
		return renderedMarkers;
    };
    
	handleUpdate = (markerID, checked) => {
		let {selectedItem, selectedItemAnalysis} = this.props;
		let newMarkerItemsArr = selectedItemAnalysis.markers;

        // if the item is checked, we want to filter it out.
		checked && (newMarkerItemsArr = newMarkerItemsArr.filter(item => item !== markerID));

        // if the item is not checked, we should add it.
        !checked && (newMarkerItemsArr = [...newMarkerItemsArr, markerID]);
        
        selectedItem.itemAnalyses[0] = { ...selectedItemAnalysis, markers: [...newMarkerItemsArr] }

		this.props.itemAnalysisUpdate(selectedItem);
    };
}

function mapStateToProps(state) {
	return {
		folderItems: state.folderItems.data,
        selectedItem: state.folderItems.selectedItem,
        selectedItemAnalysis: state.folderItems.selectedItemAnalysis,   
		userMarkers: state.userMarkers.data,
	};
}

export default connect(mapStateToProps, {itemAnalysisUpdate})(ModalMarkerSelector);
