import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { 
    _getSharedItemCookies,
    _dmAxiosErrorHandler, 
    request 
} from 'actions/index';

import { accountRehydrate } from 'actions/app';

import { 
    apiConfig, 
} from 'config';

import { APP_OPTIONS, LOB_KEYS, PROGRESS_FLAGS } from 'Constants/app';

import {
	DASHBOARDS_ERROR,

    ITEM_ANALYSIS_DB_ADD,
    ITEM_ANALYSIS_DB_FETCH,
    ITEM_ANALYSIS_DB_LOADING,                    //itemAnalysis dashboard
    ITEM_ANALYSIS_DB_REMOVE,

    ITEM_ANALYSIS_DB_TAGS_FETCH,
    ITEM_ANALYSIS_DB_TAGS_LOADING,

    ITEM_ANALYSIS_LOADING,
    ITEM_ANALYSIS_ERROR,
    ITEM_ANALYSIS_SELECT,
    ITEM_ANALYSIS_UPDATE,

	ITEM_LOADING,
	
	ITEM_VTT_SET,

	LOB_ERROR,
    LOB_LOADING,
    LOB_SET,

    CHART_LOADING,
    CHART_GET,

    CHART_CREATE,
    CHART_UPDATE,
    CHART_DATASET_CREATE,
    CHART_ERROR,
    CHART_DELETE,
    CHART_CLEAR_STATE,

	EVENT_TAG_LOADING,
	EVENT_TAG_ERROR,
	EVENT_TAG_ADD,
	EVENT_TAG_UPDATE,
    EVENT_TAG_DELETE,
    
	EVENT_TAGS_LOADING,
	EVENT_TAGS_ERROR,
	EVENT_TAGS_FETCH,
    
	ITEM_ERROR,
    ITEM_SELECT,
    ITEM_UPDATE,
    
    ITEM_ANALYSIS_MARKERS_LOADING,
	ITEM_ANALYSIS_MARKERS_FETCH,
	
    MARKERS_ERROR,
    MESSAGE_ADD,
    UNMOUNT_ANALYSIS
} from 'Constants/actions';

// ITEMS for ANALYSIS

/**
 * Fetches item with itemID, gets _sharedCokkies and loads eventtags, Markers and Dashboards associated with itemAnalysisID
 * @param {String} itemID 
 * @param {String} itemAnalysisID 
 */
function _getItemWithAnalysis(itemID, itemAnalysis) {
	return function(dispatch) {
        dispatch({ type: ITEM_LOADING, payload: true });
        dispatch({ type: ITEM_ANALYSIS_LOADING, payload: true });

        const requestObj = {
            url: `${apiConfig.itemGet}${itemID}`
        };

        var item;
        return request( requestObj, ITEM_ERROR )
        .then(resp => {
            item = resp;

            // Sets selectedItem - needed incase browser back buttons are used to insure selectedItem is correct
            // Also sets selectedItemAnalysis
            dispatch({ type: ITEM_SELECT, payload: { item, itemAnalysesKey: 0 }});
            // NOTE ITEM_ANALYSIS_LOADING will still be true & must remain true until chain of then's complete
            // dispatch({ type: ITEM_LOADING, payload: false });
        })
        // Then get sharedCookies if needed
        .then(() => {
            return dispatch(_getSharedItemCookies(itemID, item.ownerID))
                // Need to embed .then() and .catch() here because _getSharedItemCookies branches a new Promise -
                // IE, not part of this chain of then's
                .then(() => {
                    dispatch({ type: ITEM_LOADING, payload: false }); // set false here - Analysis page should only load once cookies retrieved
                    dispatch(_fetchEventTags(itemAnalysis.itemAnalysisID));
                    dispatch(_fetchItemAnalysisDashboards(itemAnalysis.dashboards, itemAnalysis.itemAnalysisID));
                    dispatch(
                        fetchItemAnalysisMarkers(
                            itemAnalysis.markers,
                            itemAnalysis.itemAnalysisID
                        ))
                    /** 
                     * NOTE:
                     * ITEM_ANALYSIS_LOADING merely indicates that that analysis loading sequence has been initiated
                     * IE - dispatches above are in motion, not yet resolved
                     * */
                    dispatch({ type: ITEM_ANALYSIS_LOADING, payload: false });
                    dispatch(_initializeAnalysisLOBs(itemAnalysis.itemAnalysisID, itemAnalysis.availableLOBs));
                    return Promise.resolve();
                })
                // catch any errors and reject
                .catch(error => {
                    return Promise.reject(error);
                });
        })
	};
}

/**
 * Loads the current itemAnalysis with associated eventTags, markers & LOBS
 * Called from Analysis.jsx
 * @param String itemAnalysisID 
 */
export function loadAnalysis(itemAnalysisID) {
	return function(dispatch, getState) {
        dispatch({ type: ITEM_LOADING, payload: true });  // avoid analysis page loading before cookies retrieved.
        dispatch({ type: ITEM_ANALYSIS_LOADING, payload: true });

        const {selectedItem, selectedItemAnalysis} = getState().folderItems;

        // If selectedItem or selectedItemAnalysis are null, this is a direct page load and item & itemAnalysis needs to be loaded
        // NB: Need to getItem to establish itemOwner and associated permissions.
        if(null === selectedItem || null === selectedItemAnalysis) {
            // Get ItemAnalysis
            const requestObj = {
                url: `${apiConfig.analysisGet}${itemAnalysisID}`
            };
    
            return request( requestObj, ITEM_ANALYSIS_ERROR )
            .then(resp => { // resp is itemAnalysis

                // Sets selectedItemAnalysis - needed incase browser back buttons are used to insure selectedItem is correct
                // NOTE loading will still be true & must remain true until chain of then's complete
                dispatch({ type: ITEM_ANALYSIS_SELECT, payload: resp });

                // Get ItemWithAnalysis - it also loads cookies, markers and dashboards
                return dispatch( _getItemWithAnalysis(resp.itemID, resp))
            })
        } 
        else {
            return dispatch(_getSharedItemCookies(selectedItem.itemID, selectedItem.ownerID))
            // Need to embed .then() and .catch() here because _getSharedItemCookies branches a new Promise -
            // IE, not part of this chain of then's
            .then(() => {
                dispatch({ type: ITEM_LOADING, payload: false });
                dispatch(_fetchEventTags(selectedItemAnalysis.itemAnalysisID));
                dispatch(_fetchItemAnalysisDashboards(selectedItemAnalysis.dashboards, selectedItemAnalysis.itemAnalysisID));
                dispatch(
                    fetchItemAnalysisMarkers(
                        selectedItemAnalysis.markers,
                        selectedItemAnalysis.itemAnalysisID
                    ))
                    
                /** 
                 * NOTE:
                 * ITEM_ANALYSIS_LOADING merely indicates that that analysis loading sequence has been initiated
                 * IE - dispatches above are in motion, not yet resolved
                 * */
                dispatch({ type: ITEM_ANALYSIS_LOADING, payload: false });
                dispatch(_initializeAnalysisLOBs(selectedItemAnalysis.itemAnalysisID, selectedItemAnalysis.availableLOBs));
                return Promise.resolve();
            })
            // catch any errors and reject
            .catch(error => {
                return Promise.reject(error);
            });
        }
	};
}

/**
  * Fetch dashboards for itemAnalysis
  * @param {Array} dashboards array of dashboardID's to fetch
  * @param {String} itemAnalysisID itemAnalysisID for these dashboards
  */
 function _fetchItemAnalysisDashboards(dashboards, itemAnalysisID) {
	return function(dispatch) {
        dispatch({type: ITEM_ANALYSIS_DB_LOADING, payload: true});
        dispatch({type: ITEM_ANALYSIS_DB_TAGS_LOADING});

		const requestObj = {
			method: 'post',
			url: `${apiConfig.dashboardFetch}`,
			data: {
				dashboardIDs: dashboards,
				itemAnalysisID: itemAnalysisID
			}
		};

		request(requestObj, DASHBOARDS_ERROR).then(resp => {
			dispatch({
				type: ITEM_ANALYSIS_DB_TAGS_FETCH,
				payload: resp.dashboardTags
			});
			dispatch({
				type: ITEM_ANALYSIS_DB_FETCH,
				payload: resp.dashboards
			});
		});
	};
}

/**
  * Fetch markers for itemAnalysis
  * @param {Array} markersID's array of markers to fetch
  * @param {String} itemAnalysisID itemAnalysisID for these markers
  */
 export function fetchItemAnalysisMarkers(markers, itemAnalysisID) {
	return function(dispatch) {
		dispatch({type: ITEM_ANALYSIS_MARKERS_LOADING, payload: true});

		const requestObj = {
			method: 'post',
			url: `${apiConfig.markerFetch}`,
			data: {markerIDs: markers, itemAnalysisID}
		};

		request(requestObj, MARKERS_ERROR).then(resp => {
			dispatch({
				type: ITEM_ANALYSIS_MARKERS_FETCH,
				payload: resp
			});
		});
	};
}

/**
 * Returns the lob - Large OBject - from the ItemAnalysis where itemAnalysisID
 * @param {String} itemAnalysisID id of itemAnalysis
 * @param {String} lobKey to retrieve - see below notes
 * 
 * NOTES:
 * LOBS & LOB formats: see Constants/app.js
 * DOES UPDATE: chart, transcription, notes
 * DOES NOTE UPDATE: fileLobs
 */
export function getAnalysisLob(itemAnalysisID, lobKey, load=false) {
	return function(dispatch, getState) {

        // unique for old charts
        if( lobKey.startsWith('chartObject_') ) {
            dispatch({ type: CHART_LOADING, payload: true});
        } 

        const requestObj = {
            url: `${apiConfig.analysisGet}${itemAnalysisID}&lobsList=${lobKey}`
        };
		
        return request( requestObj, LOB_ERROR )
        .then(resp => {
            
            // also sets loading for this key to false
            dispatch({ 
                type: LOB_SET, 
                payload: { itemAnalysisID, lobKey, lob: resp[lobKey] }
			}); 
			
			// TODO: This is a needed hack to enable resuming of fileAsset uploads - adds the file_*lob to relevant fileAsset lobs goodies
			if (load) {
				dispatch({
					type: LOB_LOADING,
					payload: {
						itemAnalysisID,
						lobKey,
						value: false
					}
				});
			}
            
            // Special handling for chartObject_1 untill generic handling complete
            // I don't like it, but it's a first stab.
            if( lobKey === 'chartObject_1') {
				dispatch({ type: CHART_GET, payload: resp[lobKey] });
			}
        })
	};
}

/**
 * Initializes the 
 * 		1. state.analysisLOBs object availableLOBs ID's from itemAnalysis or sets default if none exist
 * 		2. If objects is a VTT, initializes 
 * @param {Object} itemAnalysis 
 */
function _initializeAnalysisLOBs(itemAnalysisID, availableLOBs){
    return function(dispatch) {
        // temp fix to set default for charts if none exist
        dispatch({ type: CHART_GET, payload: {}});

        for(var i=0, len=availableLOBs.length; i<len; i++) {
			if (availableLOBs[i].startsWith('chartInput')) continue;

			// SET LOB object
			if(	availableLOBs[i].startsWith(LOB_KEYS.FILE) ||
				availableLOBs[i].startsWith(LOB_KEYS.NOTES) ||
				availableLOBs[i].startsWith(LOB_KEYS.TRANSCRIPTION) ||
				availableLOBs[i].startsWith(LOB_KEYS.VTT) 					// In the case we allow for uploaded VTTs
			) {
				//Initialize LOBs object
				dispatch({ 
					type: LOB_LOADING, 
					payload: { 
						itemAnalysisID,
						lobKey: availableLOBs[i],
						value: 'LOAD'
					}
				});

				//Initialize VTT object
				if (availableLOBs[i].startsWith(LOB_KEYS.VTT)) {
						dispatch({ 
							type: ITEM_VTT_SET, 
							payload: { 
								itemAnalysisID,
								lobKey: availableLOBs[i] + '.vtt',
							}
						});					
					}

				continue;
			};
			
			// TODO: only chartObject goes thru?
            dispatch(getAnalysisLob(itemAnalysisID, availableLOBs[i]));
        }
    }
}

/**
 * Updates lob of type chart, transcription, and notes with lobKey for itemAnalysisID. State is updated regardless of a failure but an error is thrown.
 * @param {String} itemAnalysisID - Id of the ItemAnalysis which requires updating
 * @param {String} lobKey - key of the LOB to update
 * @param {Object} lob - the lob data... specific for each lob
 * 
 * NOTES:
 * LOBS & LOB formats: see Constants/app.js
 * DOES UPDATE: chart, transcription, notes
 * DOES NOTE UPDATE: fileLobs
 */
export function itemAnalysisLobUpdate(itemAnalysisID, lobKey, lob) {
	return function(dispatch) {
        /**  
         * NOTE: To preserve updates, no rollback here, Just throw an error 
         * NOTE: Loading state - aka saving - handled by local state
         * */

        dispatch({
            type: LOB_SET,
            payload: { itemAnalysisID, lobKey, lob }
        });
        
        const requestObj = {
            method: 'post',
            url: `${apiConfig.analysisUpdate}`,
            // url: `${apiConfig.appGetVersion}?${Math.random()}`, // error test - method must be get
            data: { 
                itemAnalysisID,
                [lobKey]: lob 
             },
        };

        return request( requestObj, LOB_ERROR )
        .then( resp => {
            return Promise.resolve();
        })
        .catch( err => {
            return Promise.reject();
        });
	};
}

/**
 * Creates a transcription for the given itemID where. languageCode and speakerCount required
 * @param {*} itemID itemID of item to transcribe
 * @param {*} languageCode languageCode for transcription. Required.
 * @param {*} speakerCount Number of speakers in media. Required
 * NOTE: Prev state not needed here - no pre-updates done
 * NOTE: Loading Handled in local state
 */
export function itemTransciptionCreate(itemID, languageCode, speakerCount) {
	return function(dispatch, getState) {

        const requestObj = {
            method: 'post',
            url: `${apiConfig.itemTranscribe}`,
            data: { 
                itemID,
                languageCode,   //en-US, es-US, etc
                speakerCount,   //1-10
            }
        };

        return request( requestObj, LOB_ERROR )
        .then(resp => {
            // Update global state of item
            // Using selectedItem because if on a page refresh, folderItems in {}
            const item = getState().folderItems.selectedItem;
            let updatedItem = _.assign(
                {}, 
                item, 
                { transcribeProgress: {
                    ...item.transcribeProgress,
                    [languageCode]: PROGRESS_FLAGS.INPROGRESS
                }}
            );
            dispatch({
                type: ITEM_UPDATE,
                payload: updatedItem
            });

            // Rehydrate account with usageLimits from resp & msg user.
            dispatch( accountRehydrate(resp) )
            dispatch( {
                type: MESSAGE_ADD,
                payload: "You will be emailed when your transcription is complete. At peak times and when files are long, this can take a few hours.",
            } )
            return Promise.resolve();
        })
        .catch(err => {
            // Err msg set in error handler
            return Promise.reject( err );
        })
	};   
}

/**
 * Resets item transcription to original
 * @param {String} itemID UUID itemID of transcription to reset
 * @param {String} languageCode 
 */
/**
 * NOTE: Unimplemented & untested
export function itemTransciptionReset(itemID, languageCode) {
	return function(dispatch) {

        const requestObj = {
            method: 'post',
            url: `${apiConfig.itemTranscribe}`,
            data: { 
                itemID,
                languageCode,   //en-US, es-US
                reset: true
            }
        };

        return request( requestObj, LOB_ERROR )
        .then(resp => {
            dispatch( accountRehydrate(resp))
            dispatch( {
                type: MESSAGE_ADD,
                payload: "Reset successful.",
            } )
            //TODO: fetch update
            return Promise.resolve();
        })
        .catch(err => {
            return Promise.reject( err );
        })
	};   
}
 */

/**
 * Updates itemAnalysis both in state and Dynamo
 * @param {Object} updatedItem The updated item containing the itemAnalyses updated.
 */
export function itemAnalysisUpdate(updatedItem, itemAnalysesKey=0) {
	return function(dispatch, getState) {
        dispatch({ type: ITEM_ANALYSIS_LOADING, payload: true });
		// grabs previous state items data so we can set it back to this if there are any errors.
        const prevStateItem = getState().folderItems.selectedItem;
        
		dispatch({
			type: ITEM_ANALYSIS_UPDATE,
			payload: updatedItem
        });
        
        const requestObj = {
            method: 'post',
            url: `${apiConfig.analysisUpdate}`,
            data: { ...updatedItem.itemAnalyses[itemAnalysesKey] },
        };

        request( requestObj, ITEM_ANALYSIS_ERROR )
        .then( resp => {
            dispatch({ type: ITEM_ANALYSIS_LOADING, payload: false });
        })
        .catch( err => {
            dispatch({ type: ITEM_ANALYSIS_LOADING, payload: false });
            // Rollback update
            dispatch({
                type: ITEM_ANALYSIS_UPDATE,
                payload: { updatedItem: prevStateItem }
            });
        });
	};
}

/**
 * Dispatches actions to add or remove dashboardID{} from current state.itemAnalysisDbs
 * when state.itemAnalysisDbs is not Empty (IE, on Analysis page)
 * @param {String} dashboardID 
 * @param {Boolean} add 
 */
export function itemAnalysisDbsUpdate(dashboardID, add) {
	return function(dispatch, getState) {
        // If Empty, we dont need to update.
        // Empty when not on Analysis page
        if( _.isEmpty(getState().itemAnalysisDbs.data) ) return;
        // add dashboard
        if(add) {
            dispatch({
                type: ITEM_ANALYSIS_DB_ADD, 
                payload: getState().userDashboards.data[dashboardID] 
            })
        }
        // remove dashboard
        else {
            dispatch({
                type: ITEM_ANALYSIS_DB_REMOVE, 
                payload: dashboardID
            })
        }
    }
}

export function exportItemAnalysis(itemAnalysisID) {
	return function(dispatch) {
        const requestObj = {
            url: `${apiConfig.analysisExport}${itemAnalysisID}`,
        };

        return request( requestObj, ITEM_ERROR )
	};
}

export function resetAnalysis() {
	return function(dispatch) {
        dispatch({ type: UNMOUNT_ANALYSIS});
    }  
}

//  EVENT TAGS
/**
 * 
 * @param {*} id 
 */
function _fetchEventTags(itemAnalysisID) {
	return function(dispatch) {
        dispatch({ type: EVENT_TAGS_LOADING });
        
        const requestObj = {
            url: `${apiConfig.eventTagsFetch}${itemAnalysisID}&noReplies=false&`,
            // url: `${apiConfig.appGetVersion}?${Math.random()}`  // fake a 400 - unknown err
            // url: `${apiConfig.eventTagsFetch}1234&noReplies=false&`, // fake a 400 - invalid id 
        };

        request( requestObj, EVENT_TAGS_ERROR )
        .then( resp => {
            dispatch({
                type: EVENT_TAGS_FETCH,
                payload: resp
            });
        })
        .catch( err => {
            dispatch({ type: EVENT_TAGS_ERROR });            
        })
	};
}

function _updateEventTag(update) {
	return function(dispatch) {
        const requestObj = {
            method: 'post',
            url: `${apiConfig.eventTagUpdate}`,
            data: update,
        };

        request( requestObj, EVENT_TAG_ERROR )
        .then( resp => {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

/**
 * Toggle the analysisVisibility mode for owners and collaborators of itemAnalysis. Updates global state on success only.
 * @param {String} mode one of 'PRIVATE' or 'PUBLIC'
 * @param {Boolean} isItemAnalysisOwner 
 * @returns {Promise}
 */
export function toggleEventTagVisibility(mode, currentUserID, isItemAnalysisOwner) {
	return function(dispatch, getState) {
		// grabs current state 
		// notes to var updatedItemAnalysis update selectedItem dure to js inheret copy by reference
		const {selectedItemAnalysis} = getState().folderItems;
		
        const requestObj = {
            method: 'post',
            url: `${apiConfig.analysisVisibility}`,
            data: { 
				itemAnalysisID: selectedItemAnalysis.itemAnalysisID, 
				mode
			}};

        return request( requestObj, ITEM_ANALYSIS_ERROR )
        .then( resp => {
			// update item & itemAnalysis
			if (isItemAnalysisOwner) {
				const currentAnalysisVisibility = selectedItemAnalysis.analysisVisibility || {}
				var updatedItemAnalysis = { 
					...selectedItemAnalysis,
					analysisVisibility: {
						...currentAnalysisVisibility,
						mode
					}
				}
			} else {
				const users = selectedItemAnalysis.collaborators.users
				const currentAnalysisVisibility = users[currentUserID].analysisVisibility || {}
				var updatedItemAnalysis = { 
					...selectedItemAnalysis,
					collaborators: {
						...selectedItemAnalysis.collaborators,
						users: {
							...users,
							[currentUserID]: {
								...users[currentUserID],
								analysisVisibility: {
									...currentAnalysisVisibility,
									mode
								}
							}
						}
					}
				}
			}

			let updatedItem = _.assign({}, getState().folderItems.selectedItem)
			updatedItem.itemAnalyses[0] = _.assign({}, updatedItemAnalysis )

			dispatch({
				type: ITEM_ANALYSIS_UPDATE,
				payload: updatedItem 
			});
			return Promise.resolve();
        })
        .catch( err => {
			return Promise.reject(); // Rollback update in component
        });
	};
}

/**
 * 
 * @param {*} eventTag 
 * @param {*} field field to update
 * @param {*} field new value to assign to field
 */
export function updateEventTag(eventTag, field, newValue) {
	return function(dispatch) {
		const updatedTag = _.assign({}, eventTag, { [field]: newValue });
		dispatch({ type: EVENT_TAG_LOADING, payload: true });
		dispatch({
			type: EVENT_TAG_UPDATE,
			payload: updatedTag
		});
		dispatch(
			_updateEventTag({
				itemAnalysisID: eventTag.itemAnalysisID,
				eventTagID: eventTag.eventTagID,
				[field]: newValue
			})
		);
	};
}

export function updateEventTagMarker(eventTag, marker, newValue) {
	return function(dispatch) {
		const updatedTag = _.assign({}, eventTag, {
			markers: _.assign({}, eventTag.markers, {
				[marker]: newValue
			})
		});

		dispatch({ type: EVENT_TAG_LOADING, payload: true });
		dispatch({
			type: EVENT_TAG_UPDATE,
			payload: updatedTag
		});
		dispatch(
			_updateEventTag({
				itemAnalysisID: eventTag.itemAnalysisID,
				eventTagID: eventTag.eventTagID,
				markers: _.assign({}, eventTag.markers, {
					[marker]: newValue
				})
			})
		);
	};
}

/**
 * 
 * @param {String} eventTagID to be deleted
 */
export function deleteEventTagItem(eventTagID) {
	return function(dispatch) {
        dispatch({ type: EVENT_TAG_LOADING, payload: true });
        dispatch({
            type: EVENT_TAG_DELETE,
            payload: eventTagID
        });

        const requestObj = {
            method: 'delete',
            url: `${apiConfig.eventTagDelete}?deleteDependencies=true&eventTagID=${eventTagID}`,
        };

        request( requestObj, EVENT_TAG_ERROR )
        .then( resp => {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

/**
 * 
 * @param Object new event tag item 
 */
export function addEventTag(props, comment) {
	const goodies = {
		title: _.upperFirst(props.label.toLowerCase()),
		dashboardTagID: props.dashboardTagID,
		itemAnalysisID: props.itemAnalysisID,
		mediaTimestamp: props.currentTime,
		markers: {},
		comment
	};

	return function(dispatch) {
        dispatch({ type: EVENT_TAG_LOADING, payload: true });

        const dateCreated = new Date().getTime();
        var sliceStart, sliceEnd;

        goodies.mediaTimestamp > APP_OPTIONS.LEAD_TIME
            ? (sliceStart = goodies.mediaTimestamp - APP_OPTIONS.LEAD_TIME)
            : (sliceStart = 0.001); // needs to be 0.001 due to react-video player having bug and not playing at just 0
        sliceEnd = goodies.mediaTimestamp + APP_OPTIONS.LAG_TIME;
        sliceEnd > goodies.duration ? (sliceEnd = goodies.duration) : sliceEnd;

        let item = {
            eventTagID: uuidV4(),
            dateCreated: dateCreated,
            dateUpdated: dateCreated,
            itemAnalysisID: goodies.itemAnalysisID,
            title: goodies.title,
            mediaTimestamp: goodies.mediaTimestamp,
            sliceEnd: sliceEnd,
            sliceStart: sliceStart,
            playlist: false,
            comment: goodies.comment || '',
            dashboardTagID: goodies.dashboardTagID,
            markers: goodies.markers,
            annotation: {}
        };

        dispatch({
            type: EVENT_TAG_ADD,
            payload: {
                [item.eventTagID]: _.assign({}, item, {
                    avatarURL_32: goodies.avatarURL_32,
                    initials: goodies.initials,
                    ownerID: props.currentUserID
                })
            }
        });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.eventTagCreate}`,
            data: item,
        };

        request( requestObj, EVENT_TAG_ERROR )
        .then( resp => {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

// EVET TAG REPLIES
/**
 * 
 * @param {Object} eventTag to which reply will be added
 * @param {String} ownerID of the reply. This is the userID of the person adding the reply
 * @param {String} reply is the actual reply
 */
export function addEventTagReply(eventTag, ownerID, reply) {
	return function(dispatch) {
        dispatch({ type: EVENT_TAG_LOADING, payload: true });

        const newReply = {
            eventTagReplyID: uuidV4(),
            eventTagID: eventTag.eventTagID,
            itemAnalysisID: eventTag.itemAnalysisID,
            text: reply
        };

        const updatedTag = _.assign({}, eventTag, {
            replies: _.assign({}, eventTag.replies, {
                [newReply.eventTagReplyID]: _.assign({}, newReply, {
                    dateCreated: new Date().getTime(),
                    ownerID: ownerID
                })
            })
        });

        dispatch({
            type: EVENT_TAG_UPDATE,
            payload: updatedTag
        });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.eventTagReplyCreate}`,
            data: newReply,
        };

        request( requestObj, EVENT_TAG_ERROR )
        .then( resp => {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

/**
 * Updates the eventTagReply.text with updatedReply
 * @param {Object} eventTag eventTag object with eventTagID embedded
 * @param {Object} eventTagReply eventTagReply object with eventTagID embedded 
 * @param {String} updatedReply 
 */
export function updateEventTagReply(eventTag, eventTagReply, updatedReply) {
	return function(dispatch) {
        dispatch({ type: EVENT_TAG_LOADING, payload: true });

        const updatedTag = {
            ...eventTag,
            replies: {
                ...eventTag.replies,
                [eventTagReply.eventTagReplyID]: {
                    ...eventTagReply,
                    text: updatedReply
                }
            }
        };

        dispatch({
            type: EVENT_TAG_UPDATE,
            payload: updatedTag
        });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.eventTagReplyUpdate}`,
            data: {
                eventTagReplyID: eventTagReply.eventTagReplyID,
                // eventTagID: eventTag.eventTagID,
                text: updatedReply
            },
        };

        request(requestObj)
        .then(function(resp) {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

export function deleteEventTagReply(eventTag, eventTagReplyID) {
	return function(dispatch) {
		dispatch({ type: EVENT_TAG_LOADING, payload: true });
        const updatedTag = {
            ...eventTag,
            replies: _.omit(eventTag.replies, eventTagReplyID)
        };

        dispatch({
            type: EVENT_TAG_UPDATE,
            payload: updatedTag
        });

        const requestObj = {
            method: 'delete',
            url: `${apiConfig.eventTagReplyDelete}${eventTagReplyID}`,
        };

        request(requestObj)
        .then(function(resp) {
            dispatch({ type: EVENT_TAG_LOADING, payload: false });
        })
	};
}

// CHARTS

/**
 * Fetch the chart for an item and dispatch to store it in global state
 * 
 * @param {String} itemAnalysisID The ID of the itemAnalysis (you would never have guessed, would you)
 */
// export function fetchChart(itemAnalysisID) {
//     return function(dispatch, getState) {
//         dispatch({ type: CHART_LOADING, payload: true });

//         const lobs = getState().itemAnalysisLOBs[itemAnalysisID]; // NOTE: lobs can be undefined while the analysis is loading.
// 		const chart = (lobs !== undefined) ? lobs.chartObject_1 : {}; // Currently just grabs the first chart (we only support one at this time)
        
//         dispatch({ type: CHART_GET, payload: chart});
//     };
// }

/**
 * Create a chart attached to an itemAnalysisID, and upload a CSV for a dataset
 * 
 * @param {String} itemAnalysisID The ID for the itemAnalysis that owns the chart
 * @param {String} csv The contents of the CSV of the first dataset, as a string.
 * @param {String} dsTitle The title of the new dataset.
 * @param {String} lobKey The key for the chart LOB
 */
export function createChart(itemAnalysisID, csv, dsTitle='New dataset', lobKey='chartObject_1') {
    return function(dispatch, getState) {
        dispatch({ type: CHART_LOADING, payload: true });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.chartCreate}`,
            params: {
                itemAnalysisID: itemAnalysisID,
                dsChartType: 'line',
                dsTitle: dsTitle,
                lobKey: lobKey
            },
            data: csv
        };

        request( requestObj, CHART_ERROR )
        .then( resp => {
            dispatch({ type: CHART_CREATE, payload: resp });
        })
        .catch( err => {
            console.log('err:', err);
            // Not sure why err is coming in undefined.
            // Temporarily dispatching the CHART_ERROR action manually,
            // with the contents of the global message.
            const error = getState().messages.message;
            dispatch({ type: CHART_ERROR, payload: error});
        });

    };
}

 /**
  * Update a chart and its existing datasets
  * 
  * @param {String} itemAnalysisID ID of the itemAnalysis
  * @param {Object} leftDatasets Datasets object to update {id: {dsTitle, dsChartType}}
  * @param {String} leftYAxisLabel Text for the left Y axis label
  * @param {Bool} sendDatasetData Whether to send full dataset data (x,y values) over the wire. Not necessary for non-data updates. Default false.
  * @param {String} lobKey Key of the LOB holding the chart data. Currently always 'chartObject_1'
  */
export function updateChart(itemAnalysisID, leftDatasets, leftYAxisLabel, sendDatasetData=false, lobKey='chartObject_1') {
    return function(dispatch, getState) {
        dispatch({ type: CHART_LOADING, payload: true });

        if (sendDatasetData === false) {
            // We'll only pass the modal the dataset metadata, not the data itself,
            // in order to avoid sending all that data over the wire.
            leftDatasets = _.mapValues(leftDatasets, (dataset) => _.omit(dataset, 'data'));
        }

        const requestObj = {
            method: 'post',
            url: `${apiConfig.chartUpdate}`,
            params: {
                itemAnalysisID: itemAnalysisID,
                lobKey: lobKey 
            },
            data: {
                leftDatasets: leftDatasets,
                leftYAxisLabel: leftYAxisLabel
            }
        };

        request( requestObj, CHART_ERROR )
        .then( resp => {
            dispatch({ type: CHART_UPDATE, payload:  resp});
        })
        .catch( err => {
            console.log('err:', err);
            // Not sure why err is coming in undefined.
            // Temporarily dispatching the CHART_ERROR action manually,
            // with the contents of the global message.
            const error = getState().messages.message;
            dispatch({ type: CHART_ERROR, payload: error});
        });

    }
}

/**
 * Add a new dataset to an existing chart.
 * 
 * If there is no chart attached to the intended itemAnalysis,
 * you should instead use createChart().
 * 
 * Dataset updates happen through updateChart().
 * 
 * @param {String} itemAnalysisID The ID of the itemAnalysis that owns the chart
 * @param {String} csv The CSV of a new dataset, as a string
 * @param {String} dsTitle The title of the new dataset.
 * @param {String} lobKey The key for the chart LOB
 */
export function createDataset(itemAnalysisID, csv, dsTitle='New dataset', lobKey='chartObject_1') {
    return function(dispatch, getState) {
        dispatch({ type: CHART_LOADING, payload: true });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.chartDatasetCreate}`,
            params: {
                itemAnalysisID: itemAnalysisID,
                dsChartType: 'line',
                dsTitle: dsTitle,
                lobKey: lobKey 
            },
            data: csv
        }

        request( requestObj, CHART_ERROR )
        .then( resp => {
            dispatch({ type: CHART_DATASET_CREATE, payload: resp });
        })
        .catch( err => {
            console.log('err:', err);
            // Not sure why err is coming in undefined.
            // Temporarily dispatching the CHART_ERROR action manually,
            // with the contents of the global message.
            const error = getState().messages.message;
            dispatch({ type: CHART_ERROR, payload: error});
        });
    }
}

/**
 * Sets loading state to true for the chart. This is useful early in the component lifecycle.
 */
export function setChartLoading() {
    return function(dispatch) {
        dispatch({ type: CHART_LOADING, payload: true });
    }
}

/**
 * Clears all chart data from global redux state.
 */
export function clearChartState() {
    return function(dispatch) {
        dispatch({ type: CHART_CLEAR_STATE });
    }
}