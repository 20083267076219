import _ from 'lodash';

import {
    ACCOUNT_REHYDRATE,
    MEMBERS_UPDATE,
    MEMBERS_ERROR,
    MEMBERS_FETCH,
} from 'Constants/actions';
import {INVITE_STATUS} from 'Constants/app'
import {request} from 'actions/index';
import {apiConfig} from 'config';

import { APP_OPTIONS, PROGRESS_FLAGS } from 'Constants/app';

// MEMBERS
/**
 * Fetches map of members from cloud.
 * @returns {Map} with format:
 * {
 *      organizationInvites: {},
 *      members: { userID_ABC: {}, userID_DEF: {} }
 * }
 */
 export function fetchMembers() {
	return function(dispatch) {
        // NOTE: LOADING status indicated by absence of state.members
		const requestObj = {
			url: `${apiConfig.organizationMembersFetch}`
		};

		request(requestObj, MEMBERS_ERROR)
		.then(resp => {
			dispatch({
				type: MEMBERS_FETCH,
				payload: resp
			});
		})
		.catch(err => { });
	};
}

/**
 * Invites members to organization
 * @param {String} member Email or userID of member
 * @param {String} key Invite key - one of 'email' or 'userID'
 * NOTE: Incomplete implementation of key=userID .
 */
export function inviteMember(member) {
	return function(dispatch) {
        let {key,value} = _getValidMemberKeyValuePair(member)
        dispatch(_updateStateOrganizationMember(member, value, 'LOADING'))

        // Trigger API
		const requestObj = {
			url: `${apiConfig.memberInvite}${key}=${value}`
		};

        return request(requestObj, MEMBERS_ERROR)
        .then(resp => {
            dispatch(_updateStateOrganizationMember(member, value, INVITE_STATUS.INVITED))
            return Promise.resolve();
        })
        .catch( err => {
            dispatch(_updateStateOrganizationMember(member, value, INVITE_STATUS.ERROR_INVITING))
            return Promise.reject();
        });
	};
}

/**
 * Revokes member from organization. Called on 'Disable', whether or not the person has ACCEPTED.
 * @param {String} member is either their email address or userID
 * @param {*} key is one of 'email' or 'userID'
 * @param {*} revokeType is one of 'member' or 'invite'
 * 
 * NOTE: key=userID not tested
 */
export function revokeMember(member) {
	return function(dispatch) {        
        let {key,value} = _getValidMemberKeyValuePair(member)
        dispatch(_updateStateOrganizationMember(member, value, 'LOADING'))
        
        // Trigger API
		const requestObj = {
            url: `${apiConfig.memberRevoke}${key}=${value}`
        };

		return request(requestObj, MEMBERS_ERROR)
        .then(resp => { 
            dispatch(_updateStateOrganizationMember(member, value, INVITE_STATUS.REVOKED))
            return Promise.resolve()
        })
        .catch( err => {
            dispatch(_updateStateOrganizationMember(member, value, INVITE_STATUS.ERROR_DEACTIVATING))
            return Promise.reject();
        });
	};
}

// ORGANIZATION SPECIFIC
export function updateOrgAutoMemberAtSignup(autoMemberAtSignup) {
	return function(dispatch, getState) {                
        // Trigger API
		const requestObj = {
            method: 'POST',
            url: `${apiConfig.organizationUpdate}`,
            data: { autoMemberAtSignup }
        };

		return request(requestObj, MEMBERS_ERROR)
        .then(resp => { 
            dispatch({
                type: ACCOUNT_REHYDRATE, 
                payload: {...getState().account, autoMemberAtSignup}
            })
            return Promise.resolve()
        })
        .catch( err => {
            // On error, reject so that caller can can handle
            return Promise.reject();
        });
	};
}

/** PRIVATE HELPERS */

/**
 * Adds member to state's members.organizationInvites {}, replacing exiting member if it present
 * @param {Object} member member object being added/updated
 * @param {String} key key of member object being added/updated
 * @param {String} status one of INVITE_STATUS
 */
function _updateStateOrganizationMember( member, key, status) {
    return function(dispatch, getState) {
        let currentMembers = getState().members.data || {};

        let dateKey;
        
        switch(status) {
            case INVITE_STATUS.ERROR_INVITING:
            case INVITE_STATUS.ERROR_DEACTIVATING:
            case INVITE_STATUS.LOADING: {
                dateKey: 'now'
            }
            case INVITE_STATUS.ACCEPTED: {
                dateKey = 'dateAccepted';
                break;
            }
            case INVITE_STATUS.LEFT: {
                dateKey = 'dateLeft';
                break;
            }
            case INVITE_STATUS.REVOKED: {
				dateKey = 'dateRevoked';
                break;
            } 
            case INVITE_STATUS.INVITED:
            default: {
                dateKey = 'dateInvited';
                break;
            }
        }
        
        let newInvites = {
            ...currentMembers,
            [key]: {
                ...member,
				[dateKey]: new Date().getTime(),
				datePendingRevoke: '',
                status
            }
        }
        
        dispatch({
            type: MEMBERS_UPDATE,
            payload: newInvites
        });
    }
}

/**
 * Checks member and returns the valid key-value pair to use in invites & revokes
 * @param {Object} member 
 * @returns {Object} key-value pair as {key: [key], value: [value]}
 */
function _getValidMemberKeyValuePair(member) {
    let key = member.userID ? 'userID' : 'email';
    let value = member.userID ? member.userID : member.email
    return {key, value}
}