import _ from 'lodash';

import { 
    _getSharedItemCookies,
    _dmAxiosErrorHandler, 
    request 
} from 'actions/index';

import { 
    apiConfig, 
} from 'config';

import {
	ITEM_ANALYSIS_UPDATE,
	LTI_ERROR,
} from 'Constants/actions';

/**
 * Gets grade of selectedItemAnalysis and sets it in local state. 
 * Expects caller to handle loading status
 * @returns Promise.resolve() on success, Promise.reject() on error
 */
export function getLtiGrade( refresh = false) {
	return function(dispatch, getState) {
		const selectedItem = getState().folderItems.selectedItem;
		const selectedItemAnalysis = getState().folderItems.selectedItemAnalysis;
		const itemAnalysisID = selectedItemAnalysis.itemAnalysisID;

		var updatedItem = selectedItem;

		if (refresh) {
			updatedItem.itemAnalyses[0] = { ...selectedItemAnalysis, ltiGrade: undefined }
			dispatch({
				type: ITEM_ANALYSIS_UPDATE,
				payload: updatedItem
			});
		}

        const requestObj = {
            url: `${apiConfig.ltiGradeGet}${itemAnalysisID}`
        };

        return request( requestObj, LTI_ERROR )
        .then(resp => {
			updatedItem.itemAnalyses[0] = { ...selectedItemAnalysis, ltiGrade: resp.resultScore*100 }
			dispatch({
				type: ITEM_ANALYSIS_UPDATE,
				payload: updatedItem
			});
			return Promise.resolve();
		})
		.catch( err => {
			selectedItem.itemAnalyses[0] = { ...selectedItemAnalysis, ltiGrade: 0 }
			dispatch({
				type: ITEM_ANALYSIS_UPDATE,
				payload: selectedItem
			});
			return Promise.reject(`Learning management system error: ${err.response.data.message}`);
		})
	};
}

/**
 * Passes LTI grade back to consumer
 * @param {Float} grade 
 */
export function passbackLtiGrade(grade) {
	return function(dispatch, getState) {
		let selectedItem = getState().folderItems.selectedItem;
		const selectedItemAnalysis = getState().folderItems.selectedItemAnalysis;
		const itemAnalysisID = selectedItemAnalysis.itemAnalysisID;

        const requestObj = {
			method: 'POST',
			url: `${apiConfig.ltiGradePassback}`,
			data: {
				resultScore:	(grade*1)/100, 			// *1 to force number
				itemAnalysisID: itemAnalysisID
			}
			
        };

        return request( requestObj, LTI_ERROR )
        .then(resp => {
			selectedItem.itemAnalyses[0] = { ...selectedItemAnalysis, ltiGrade: grade*1 }
			dispatch({
				type: ITEM_ANALYSIS_UPDATE,
				payload: selectedItem
			});
			return Promise.resolve();
		})
		.catch( err => {
			return Promise.reject(`Learning management system error: ${err.message}`);
		})
	};
}