import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import Editor from './Editor.jsx'
import { Loading } from 'Widgets/Loading.jsx';

class EditorWrapper extends Component {

	constructor(props) {
		super(props);
    }

	render() {
        const { 
            //??canNotes,
            locked, 
            isItemAnalysisOwner, 
            itemAnalysisLOBs, 
            itemAnalysisID, 
        } = this.props;

        // console.log('### EDITOR ###')
        // console.log('itemAnalysisLOBs: ', itemAnalysisLOBs)

        // itemAnalysisLOBs and/or itemAnalysisID not yet loaded
        if( undefined === itemAnalysisLOBs ||
            undefined === itemAnalysisID ) return <Loading />

        const maxHeight = this.props.bottombarHeight-100
        
		return (
            <div>
                <BottombarTitle
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    locked={locked}
                    // selectedDashboard={selectedDashboard}
                    activeTab='editor' 
                    title = 'Project Notes'
                />
                <div
                    className="col-12 editor"
                    id="quillContainer"
                    style={{
                        maxHeight: maxHeight,
                        height: 'auto'
                    }}>
                    {/** FYI Issue of movement when changing tabs due to transition height */}
                    <Editor
                        maxHeight={maxHeight}
                        itemAnalysisID={itemAnalysisID}
                        isItemAnalysisOwner={isItemAnalysisOwner}
                        locked={locked}
                    />
                </div>
            </div>
		);
    }
}

const mapStateToProps = state => {
	return {
        bottombarHeight: state.app.bottombarHeight,
        itemAnalysisLOBs: state.itemAnalysisLOBs,
	};
};

export default connect(mapStateToProps)(EditorWrapper);
