import React, {Component} from 'react';
import {StripeProvider, Elements} from 'react-stripe-elements';

import {STRIPEAPIKEY} from 'config.js';
import BillingInformation from './Billing.Information.jsx';

const Billing = () => (
	<StripeProvider apiKey={STRIPEAPIKEY}>
		<div className="settings">
			<div className="settings-head">
				<h1>Billing Details</h1>
			</div>
			<div className="settings-body settings-narrow">
				<Elements>
					<BillingInformation />
				</Elements>
			</div>
		</div>
	</StripeProvider>
);

export default Billing;
