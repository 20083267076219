import React, { Component } from 'react';

const defaultColors = [
    '#1997c6', // primary
    '#9F86FF', // info
    '#FFAE12',  //yellow
    '#E64759', // danger
    '#A70097', // purple
    '#a25397', //yum plum
    '#FB9800', //tangering
    '#1BC98E', // success
];

const sumChars = str => {
	let sum = 0;
	for (let i = 0; i < str.length; i++) {
		sum += str.charCodeAt(i);
	}
	return sum;
};

const AVATAR_SIZES = {
	default: {
		width: '28px',
		minWidth: '28px',
		height: '28px',
		lineHeight: '30px',
		fontSize: '.8rem'
	},
	lg: {
		width: '64px',
		height: '64px'
	},
	md: {
		width: '32px',
		minWidth: '32px',		
		height: '32px',
		lineHeight: '34px',
		fontSize: '.9rem'
	},
	sm: {
		width: '28px',
		minWidth: '28px',		
		height: '28px',
		lineHeight: '30px',
		fontSize: '.8rem'
	},
	xs: {
		width: '22px',
		minWidth: '22px',
		height: '22px',
		lineHeight: '24px',
		fontSize: '.6rem'
	}
};

const Avatar = ({ src='', size, initials, name='' }) => {
	const backgroundColor =
		defaultColors[sumChars(initials) % defaultColors.length];
	const sizeData = AVATAR_SIZES[size] || {};

	const imgStyle = {
		backgroundColor,
		...AVATAR_SIZES.default,
		...sizeData
	};

	return <span className="avatar" style={imgStyle} data-toggle='tooltip' title={name}>{initials}</span>;

    // For when we start using images
	// return (
	// 	<img
	// 		className="avatar"
	// 		src={src}
	// 		height={height}
	// 		width={width}
	// 		alt=""
	// 	/>
	// );
};

Avatar.defaultProps = {
	src: '',
	initials: '?'
};

export default Avatar;
