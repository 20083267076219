import { INTERCOM_APPID, DMIO_RELEASE } from '../config';
import { getQueueSize, getUploadSpeed } from '../utils/bandwidth';
import { formatStorage } from '../utils/index';

export function initConsumerism(user={}, account) {
        _initRollbar(user) 
		_initIntercom('boot', user, account)
}

export function exitConsumerism() {
    _initRollbar({}) 
    shutdownIntercom();
}

export function shutdownIntercom() {
    window.Intercom('shutdown');
    window.Intercom(
        'boot', 
        {   app_id: INTERCOM_APPID }
    )
}

/**
 * Updates user details in intercom
 * @param {Map} user Updated user map
 * @param {Map} account Updated account map
 */
export function updateIntercom(user, account) {
	_initIntercom('update', user, account)
}

function _initRollbar (user = {}){
    if ('development' === process.env.NODE_ENV) {
        Rollbar.configure({ enabled: false });
        return;
    }
    const na = 'Visitor'
    Rollbar.configure({
        payload: {
            person: {
                name: user.firstName || na,
                email: user.email || na,
                username: user.email || na,
                id: user.userID || na
            }
        }
    });
}

function _initIntercom(action, user, account) {
	window.Intercom( action, {   
		app_id: INTERCOM_APPID,
		custom_launcher_selector: "#chatnow",

		// User data
		accountID: user.accountID,
		created_at: Math.round((user.dateCreated / 1000)),
		email: user.email,
		company: (account.organizationID) 
		? {
			company_id: account.organizationID,
			name: account.organizationID,
		}
		: {},
		plan: account.planData.title,
		name: user.lastName ? `${user.firstName} ${user.lastName}` : `${user.firstName}`,
		planID: account.planData.planID,
		unsubscribed_from_emails: user.unsubscribedFromEmailsAtSignup,
		user_hash: user.intercomIdentityVerificationHash,
		user_id: user.userID,

		// Usage data
		videoCount: account.usageData.videoCount,
		audioCount: account.usageData.audioCount,
		collaboratorCount: account.usageData.collaboratorCount,
		shareCount: account.usageData.shareCount,
		storageCount: formatStorage(account.usageData.storageCount),
		dmioRelease: DMIO_RELEASE,
		uploadQueueSize: getQueueSize(),
		uploadSpeed: getUploadSpeed(),
	})
}

