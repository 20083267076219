import _ from 'lodash';
import classNames from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';


import { updateUser } from 'actions/index'
import {
    dismissMessage, 
} from 'actions/app';

import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx'
import Numbers from 'Widgets/Inputs/Numbers.jsx'
import Checkbox from 'Widgets/Inputs/Checkbox.jsx'

import { PLANID, ROUTES } from 'Constants/app';

class Preferences extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHelpText: false,
			loading: false,
			preferences: {
				...this.props.preferences
			}
        };
    }

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Message is returned from preferences saving - stop loading on message return
		if (nextProps.messages.message !== '') {
			this.setState({ loading: false });
		}

		// Ensure updated preferences are set
		if(this.props.preferences !== nextProps.preferences) {
			this.setState({
				preferences: {
					...nextProps.preferences
				}
			})
		}
	}

	componentWillUnmount() {
		this.props.dismissMessage();
	}

	render() {
        const {preferencesDisabled, preferences} = this.props;
        if (undefined === preferences) return this.renderLoading();

        const {
			leadTime,
			pauseOnTag,
			pauseOnType,
            scanFwd,
            scanBwd,
        } = this.state.preferences;

        const {loading, showHelpText} = this.state;

        const containerClasses = classNames({
            'row': true,
            'settings-disabled': preferencesDisabled
        })

		return (
			<div className="settings settings-narrow">
                {this.renderPageHeader()}
				<div className='settings-body settings-preferences'>
					<div className="row">
                        <div className="col-12" style={{paddingLeft: '0px !important'}}>
                            <div className='row>'>
                                { preferencesDisabled
                                ? <div style={{fontStyle: 'italic', fontWeight: 400, paddingBottom: '10px'}}>
                                    Preferences allow a finer control of your analysis experience. For more information, see the <a href='http://get.dreamaker.help/account-preferences-and-members/view-and-edit-your-preferences' target='_blank'>Help Center - Dreamaker.io Preferences</a>.<br /><br />
                                    <Link to={ROUTES.UPGRADE}>To use this feature, upgrade your plan.</Link>
                                </div>
                                : this.renderMessages()
                                }
                            </div>
						</div>
					</div>
                    <div className={containerClasses}>
                        <div className='col-12'>
                            {this.renderPreferenceHeader('Analysis')}
                            <div className="row" style={{paddingLeft: '1.3em'}}>
                                <div className='col-12 container'>
									{this.renderPreference('Pause on tag', 'pauseOnTag', pauseOnTag, null, null, 'Checkbox')}
									{this.renderPreference('Pause on type', 'pauseOnType', pauseOnType, null, null, 'Checkbox')}
                                    {this.renderPreference('Lead play time', 'leadTime', leadTime, 0, 61)}
                                </div>
                            </div>
                            {this.renderPreferenceHeader('Media Player')}
                            <div className="row" style={{paddingLeft: '1.3em'}}>
                                <div className='col-12 container'>
                                    {this.renderPreference('Replay', 'scanBwd', scanBwd, 1, 61)}
                                    {this.renderPreference('Forward play','scanFwd', scanFwd, 1, 61)}
                                </div>

                            </div>
                    
                        </div>
                    </div>
					<div className="row" style={{marginTop: '2rem', marginBottom: '0.8rem'}}>
						<div className='col-6 header' style={{ color: '#5bc0de'}}
							onClick={this.toggleHelpTextVisibility}
							>
							Preferences Info
							{showHelpText || preferencesDisabled 
							? <i className='info-icon far fa-chevron-up' />
							: <i className='info-icon far fa-chevron-down' />
							}
						</div>
						<div className='col-6' style={{ textAlign: 'right'}}>
                            <button
                                onClick={this.updateUserPreferences}
                                className="btn btn-success"
                                disabled={loading || preferencesDisabled}>
                                Save <i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
                            </button>
						</div>
					</div>
                    <div className={containerClasses}>
                        <div className="col-12 container" >
                            <div id="help-text" className={showHelpText || preferencesDisabled ? 'help-text-shown' : 'help-text-hidden'} >
								{this.renderPreferenceHelp('Pause on tag:', 'Pause media playback when tagging media')}
								{this.renderPreferenceHelp('Pause on type:', 'Pause media playback when typing in the dashboard comments area.')}
								{this.renderPreferenceHelp('Lead play time:', 'When previewing a tagged event, lead play time is the number of seconds to start play before timestamp of the tagged event.')}
                                {this.renderPreferenceHelp('Replay:', 'The number seconds to replay in the media player when using the Replay button')}
                                {this.renderPreferenceHelp('Forward play:', 'The number seconds to scan forward in media player when using the Forward Play button')}
                                {this.renderPreferenceHelp(<a href='http://get.dreamaker.help/account-preferences-and-members/view-and-update-your-preferences' target='_blank'>Help Center - Dreamaker.io Preferences</a>, '')}
                            </div>
                        </div>
                    </div>

				</div>
			</div>
		);
    }

	renderLoading = () => {
		return (
			<div className="settings">
                {this.renderPageHeader()}
				<div className="settings-body">
					<Loading
						icon="far fa-circle-notch fa-spin"
					/>
				</div>
			</div>
		);
    };

    renderPageHeader = () => { 
        return <div className="settings-head"><h1>Preferences</h1></div> 
    }

    renderPreference = (title, name, defaultValue, min, max, type='Number') => {
		const {preferencesDisabled} = this.props;
		const {loading} = this.state;
		
        return (
            <div className='preference'>
                <div style={{ minWidth: '110px'}}>{title}</div>
					{type === 'Number'
					? <div style={{  minWidth: '110px', color: loading ? '#cfd2da' : null}}>
						<Numbers 
							defaultValue={defaultValue} 
							disabled={preferencesDisabled || loading}
							name={name}
							onBlur={this.updatePreference}
							max={max} 
							min={min}
							ref={name} 
						/>{' '}seconds
					</div>
					: <div style={{  minWidth: '110px'}}>
						<Checkbox
							checked={defaultValue}
							disabled={preferencesDisabled || loading }
							name={name}
							onBlur={this.updatePreference}
							onClick={this.updatePreference}
							style={{paddingLeft: '0px'}}
							ref={name}
						/>
					</div>
					}			
            </div>
        )
    }

    renderPreferenceHeader = (header) => {
		const {preferencesDisabled} = this.props
		const infoIcon = <i className='info-icon far fa-info-circle' 
							disabled={preferencesDisabled} 
							onClick={this.toggleHelpTextVisibility}
						/>
        return (
            <div className="row ">
                <div className='col-12 header'>
                    {header}{infoIcon}
                </div>
            </div>
        )
    }

    renderPreferenceHelp = (title, help) => {
        return (
            <p><strong>{title}{' '}</strong>{help}</p>
        )
    }

    /** Displays msg if exists, or this.props.message if exists. */
    renderMessages = () => {
        let {message, error} = this.props.messages
        return (
            <Message message={message} textClass={error ? 'danger' : 'success'} dismissable={true}/>
        );
    }

	toggleHelpTextVisibility = e => {
		e.preventDefault();
        e.stopPropagation();
		this.setState({showHelpText: !this.state.showHelpText});
    };
    
	// handles submit of the preference
	updatePreference = e => {
        const { preferencesDisabled, preferences } = this.props;
        if(preferencesDisabled) return;

		let {name, value} = e.target;
		
		// clears messages so user has more clear experience.
		this.props.dismissMessage(); 
		
		if( value === '') {
			e.target.value = preferences[name]
			return;
		}

		this.setState({ 
			preferences: { 
				...this.state.preferences,
				[name]: e.target.valueAsNumber ? value*1 : value
				}
			})
	}

	updateUserPreferences = () => {
		this.setState({loading: true})

        if (this.props.preferences !== this.state.preferences ) {
			this.props.updateUser({ preferences: {...this.state.preferences} })
		};
	};

}

function mapStateToProps(state) {
    let {user, account} = state;
	return {
        preferencesDisabled: PLANID.FREE[account.planType],
        preferences: undefined === user.data.preferences ? undefined : user.data.preferences,  // during initial load, `undefined`. if no members, `{}`
        messages: state.messages,
	};
}

const mapDispatchToProps = {
    dismissMessage, 
    updateUser,
};


export default connect(mapStateToProps, mapDispatchToProps)(Preferences);