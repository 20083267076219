// Live
module.exports = exports = {

    // Cognito
    Region:         'us-west-2',
    UserPoolId:     'us-west-2_7iDpTTQ7X',
    ClientId:       '71hvj35enpt3llr7qamntrks96',
    Paranoia:       7,
    IdentityPoolId: 'us-west-2:f978073a-3ce8-4a3b-b7e8-26e731997f8e',

    // S3
	BucketRegion: 'us-west-2',
	FileinBct: 		'live-filein-dmio',
    TransinBct:     'live-transin-dmio',
    TransoutBct:    'live-transout-dmio',
    TransoutUrl:    'https://transout.app.dreamaker.io/',
    TransoutSUrl:   'https://transouts.app.dreamaker.io/',
    LookinBct:      'live-lookin-dmio',
    LookoutBct:     'live-lookout-dmio',
    // LookoutUrl:     'https://lookout.test.dreamaker.io/',
    LookoutUrl:     '',
    BandwidthTest:  'https://s3-us-west-2.amazonaws.com/live-transin-dmio/bp.png',

    // API
    ApiHost:        'https://api.app.dreamaker.io/',

    // URL
    WebappUrl:      'https://app.dreamaker.io/',
	
	// LTT
	MoodleToolUrl:	'https://api.app.dreamaker.io/lti/cartridge',

    // 3RD Party
    StripeAPIKey: "pk_live_ynmxGlzxkq5SLTwJZTHrgAgX" // test "pk_test_Ew4pbiOnveh9sh0X5TjQIRGs"  
}