import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const SendMenuBtn = ({handleOnClick}) => (
	<MenuBtn
		label={I18n.get('Send')}
		title={I18n.get('Send a copy')}
		onClick={e => {
			handleOnClick(e, 'modalSend');
		}}
		iconClass="share-square text-plum"
	/>
);

export default SendMenuBtn;