import 'assets/favicons/android-chrome-192x192.png';
import 'assets/favicons/android-chrome-512x512.png';
import 'assets/favicons/apple-touch-icon.png';
import 'assets/favicons/favicon-16x16.png';
import 'assets/favicons/favicon-32x32.png';
import 'assets/favicons/favicon.ico';
import 'assets/favicons/mstile-150x150.png';
import 'assets/favicons/safari-pinned-tab.svg';
import 'assets/styles/custom/custom.css';
import 'assets/styles/style.scss';

import {dm_print} from './utils/';

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import reduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import App from 'Components/App.jsx';
import reducers from './reducers';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';

import {getAppVersion} from './actions/app'

// Initiate Localization
import { I18n } from '@aws-amplify/core';
// import { I18n } from 'aws-amplify'
import dict from 'Constants/locale.js'
var language = window.navigator.userLanguage || window.navigator.language;
I18n.putVocabularies(dict)
// console.log(`I18n... Language is ${language}, Abbr is ${language.slice(0, 2)} config check returns ${I18n.checkConfig()} for `, dict )
// I18n.setLanguage('pl');
// I18n.setLanguage(language.slice(0, 2));

const middlewares = [];

middlewares.push(ReduxPromise, reduxThunk);

let composeEnhancers = compose;

// Turn on Development Tools
if ('development' === process.env.NODE_ENV) {
	// ReduxLogger
	const {createLogger} = require('redux-logger');
	const ReduxLogger = createLogger({
		collapsed: (getState, action, logEntry) => !logEntry.error,
		predicate: (getState, action) =>
			!action.type.startsWith('@@redux-form/') &&
			action.type !== 'SET_CURRENTTIME' &&
			action.type !== 'SET_PLAYER' &&
			action.type !== 'UPLOAD_PROGRESS'
	});
	middlewares.push(ReduxLogger);

	// Redux DevTools
	( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ )
	? composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			actionsBlacklist: [
				'@@redux-form/BLUR',
				'@@redux-form/CHANGE',
				'@@redux-form/FOCUS',
				'@@redux-form/INITIALIZE',
				'@@redux-form/REGISTER_FIELD',
				'@@redux-form/SET_SUBMIT_SUCCEEDED',
				'@@redux-form/TOUCH',
				'@@redux-form/UPDATE_SYNC_ERRORS',
				'@@redux-form/CHANGE',
				'SET_CURRENTTIME',
				'SET_PLAYER',
				'UPLOAD_PROGRESS'
			]
		})
	: null;
	Rollbar.configure({enabled: false});
}
// Turn off rollbar in production mode for dev* stages
else if (['paola', 'nat'].includes(process.env.DMIO_CONF)) Rollbar.configure({ enabled: false });

dm_print('process.env.DMIO_CONF:', process.env.DMIO_CONF);
dm_print('process.env.NODE_ENV:', process.env.NODE_ENV);

/** 
 * NOTE: Logger must be the last middleware in chain, otherwise it will log promise, not actual actions
 * */
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

getAppVersion(store);

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('app')
);
