import React from 'react';
import PageBland from './Layout/page.bland.jsx';
import { Link } from 'react-router-dom';

import { ChatNow } from './Widgets/ChatNow.jsx';

const NotFound = () => {
	return (
		<PageBland>
			<div className="settings settings-profile">
				<div className="settings-head">
					<h1>404 Whoopsie Doodles</h1>
				</div>
				<div className="settings-body">
					<div className="form form-settings">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<p>These aren't the pages you're looking for.</p>
							<p>
								This isn't <Link to="/">the page</Link> I was
								looking for.
							</p>
							<br />
							<br />
							<p>
								How did you get here? <ChatNow>Please let us know.</ChatNow>
							</p>
						</div>
					</div>
				</div>
			</div>
		</PageBland>
	);
};

export default NotFound;
