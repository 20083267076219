//Dev Nat
module.exports = exports = {

    // Cognito
    Region:         'us-west-2',
    UserPoolId:     'us-west-2_wAwW59OP8',
    ClientId:       '61o22bq1759gvc2m675dhr968b',
    Paranoia:       7,
    IdentityPoolId: 'us-west-2:6eac0d8c-272a-4328-8e96-390e596e8a10',    
    
    // S3
    BucketRegion:   'us-west-2',
	FileinBct:      'devnat-filein-dmio',
	TransinBct:     'devnat-transin-dmio',
    TransoutBct:    'devnat-transout-dmio',
    TransoutUrl:    'https://transout.devnat.dreamaker.io/',
    TransoutSUrl:   'https://transouts.devnat.dreamaker.io/',
    LookinBct:      'devnat-lookin-dmio',
    LookoutBct:     'devnat-lookout-dmio',
    // LookoutUrl:     'https://lookin.devnat.dreamaker.io/'
    LookoutUrl:     'https://s3-us-west-1.amazonaws.com/devnat-lookout-dmio/avatars/',
    BandwidthTest:  'https://s3-us-west-2.amazonaws.com/devnat-transin-dmio/bandwidth_test.png',

    // API
    ApiHost:        'https://api.devnat.dreamaker.io/',

    // URL
	WebappUrl:      'https://devnat.dreamaker.io/',
	
	// LTT
	MoodleToolUrl:	'https://api.devnat.dreamaker.io/lti/cartridge',

    // 3RD Party
    StripeAPIKey: "pk_test_Ew4pbiOnveh9sh0X5TjQIRGs"
}