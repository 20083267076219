import _ from 'lodash';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, Form } from 'redux-form';

import { updateUser } from 'actions/index';
import { clearUserMessages } from 'actions/app';

import Message from 'Widgets/Message.jsx';

import { ROUTES } from 'Constants/app';

class ProfilePage extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: false, prevVal: {} };
    }
    
	componentWillUnmount() {
		// will clear success message when user navigates away from profile page
		this.props.clearUserMessages();
    } 

	componentDidMount() {
        // initialization of the redux form
        // initData key must be the name you labeled the form element, value will be what it is called in reducer state
		const { firstName, lastName, location, email } = this.props.user;
		const { initialize } = this.props;
		const initData = {
			firstName: firstName,
			lastName: lastName,
			location: location,
			email: email
		};

		initialize(initData);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.messages.message !== '') {
			this.setState({ loading: false });
		}
	}

	render() {
		const { handleSubmit, messages } = this.props;
		const { loading } = this.state;
		const displayLoading = classNames({
			'far fa-spinner fa-spin spinner': loading
        });

		return (
			<div className="settings">
                <div className="settings-head">
					<h1>Your Profile</h1>
				</div>
				<div className='settings-body settings-narrow'>
                    { 
                    this.renderMessages()
                     }
					<div className="form form-settings">
						<Form onSubmit={handleSubmit(this.handleFormSubmit)}>
							<div className="row">
								<div className="col-sm-6">
									<div className="form-group">
										<Field
											name="firstName"
											type="text"
											component={renderInput}
											onFocus={this.focusPrevVal}
											onBlur={this.blurNextVal}
											label="First Name"
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<Field
											name="lastName"
											type="text"
											component={renderInput}
											onFocus={this.focusPrevVal}
											onBlur={this.blurNextVal}
											label="Last Name"
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<Field
                                            disabled={true}
											name="email"
											type="text"
											component={renderInput}
											onFocus={this.focusPrevVal}
											onBlur={this.blurNextVal}
											label="Email"
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group">
										<Field
											name="location"
											type="text"
											component={renderInput}
											onFocus={this.focusPrevVal}
											onBlur={this.blurNextVal}
											label="Location"
										/>
									</div>
								</div>
							</div>
							<div className="row form-group">
								<Link className="col-6" to={ROUTES.RESET}>
									<i>Reset your password</i>
								</Link>
							</div>
							<div className="form-actions settings-align-right">
								<button
									type="submit"
									className="btn btn-success"
									disabled={loading}>
									Save <i className={displayLoading} />
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		);
    }
    
    // displays success/error message
    renderMessages = () => {
        const {message, error} = this.props.messages
        return (
            <Message message={message} textClass={error ? 'danger' : 'success'}/>
        );
    }
    
	// on the focus of an element we, set the state of prevVal to the focus targets value,
	// allowing us to replace any empty fields with it's prevVal
	focusPrevVal = e => {
		const { name, value } = e.target;
		this.setState({ prevVal: { ...this.state.prevVal, [name]: value } });
	};

	// on blur of an element, we test it compared to the prevVal of this element,
	blurNextVal = e => {
		const { name, value } = e.target;
		// if the new value is blank, after trimming white space, we set it back to the prevVal
		const newValue = _.trim(value);
		if (newValue.length === 0) {
			// needed, otherwise this is called before redux-forms state change. Which will keep it the original value of a blank,
			// which is something we do not want.
			setTimeout(() => this.props.change(name, this.state.prevVal[name]), 0);
			return;
		}
		setTimeout(() => this.props.change(name, newValue), 0);
	};

	// handles submit of the profile information
	handleFormSubmit = formProps => {
		const { user, updateUser } = this.props;
		// clears messages so user has more clear experience.
		this.props.clearUserMessages();
		this.setState({ loading: true });
		let updatedData = {};
		if (
			user.firstName !== _.trim(formProps.firstName) ||
			user.lastName !== _.trim(formProps.lastName)
		) {
			updatedData = {
				lastName: _.trim(formProps.lastName),
				firstName: _.trim(formProps.firstName)
			};
		}
		if (user.location !== _.trim(formProps.location))
			updatedData.location = _.trim(formProps.location);
		// EMAIL CHANGE - DO NOT BELOW LINE - Used for backend email changing
        // if (user.email !== _.trim(formProps.email)) updatedData.email = _.trim(formProps.email);
        // EMAIL CHNAGE - for form: disabled={ (this.props.user.userID === "") ? false : true }
		updateUser(updatedData);
	};
}

// renders the input fields
const renderInput = field => {
	const { label, type, input, meta: { error, touched }, disabled } = field;
	return (
		<div>
			<input
				{...input}
				disabled={disabled}
				type={type}
				className="form-control"
				placeholder={label}
			/>
			{touched && error && <div className="text-danger">{error}</div>}
		</div>
	);
};

// validates the form before submission is allowed
function validate(formProps) {
	const errors = {};
	const validName = /([{}[\]^&*()#$%/\\<>:;~`!+=_|"?£€])/
					  
	if (!formProps.firstName) {
		errors.firstName = 'Please enter your first name';
	} else if (formProps.firstName.length < 2 || validName.test(formProps.firstName) ) {
		errors.firstName = 'Invalid first name.'
	}

	if (!formProps.lastName) {
		errors.lastName = 'Please enter your last name';
	} else if (formProps.lastName.length < 2 || validName.test(formProps.lastName) ) {
		errors.lastName = 'Invalid last name.'
	}

	if (!formProps.email) {
		errors.email = 'Please enter an email';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
		errors.email = 'Invalid email address';
	}

	return errors;
}

function mapStateToProps(state) {
	return {
		err: state.user.error,
		loading: state.user.loading,
        messages: state.messages,
		user: state.user.data,
	};
}

const mapDispatchToProps = { clearUserMessages, updateUser };

const ProfilePageFinal = compose(
	reduxForm({ form: 'profilepage', validate }),
	connect(mapStateToProps, mapDispatchToProps)
)(ProfilePage);

export default ProfilePageFinal;
