/**
 * HOC to require Authentication to access components 
 */

import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { ROUTES } from "Constants/app";

const RequiredAuth = ({ render: Component, authenticated, loading, ...rest }) =>
	<Route
		{...rest}
		render={props => {
			if (loading) return null;

			return authenticated
				? <Component
					// mme="requireAuth"
					authenticated={authenticated}
					{...props}
				/>
				: <Redirect to={ROUTES.SIGNIN} />;
		}}
	/>;

const mapStateToProps = state => ({
	loading: state.user.loading,
});

export default connect(mapStateToProps)(RequiredAuth);
