import _ from 'lodash';

import {
	CUNAUTH_USER,
	EVENT_TAG_LOADING,
	EVENT_TAG_ERROR,
	EVENT_TAG_ADD,
	EVENT_TAG_UPDATE,
	EVENT_TAG_DELETE,

    // TAGS
	EVENT_TAGS_ERROR,
	EVENT_TAGS_FETCH,
	EVENT_TAGS_LOADING,
	UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
	activeTag: null,
	data: {},
    error: null,
	message: null,
    updating: true,         // when a single tag is updating, creating, etc

    // tags sepcific
    loading: true,          // when tags are loading
    unmount: true,          // prevents loading of tags when true
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

        case CUNAUTH_USER:
		case UNMOUNT_ANALYSIS:
			return INITIAL_STATE;
            
        // SINGLE EVENT TAG
		case EVENT_TAG_LOADING: {
			// payload is true or false
			return {
				...state,
				error: null,
				message: null,
				updating: action.payload
			}};

		case EVENT_TAG_ERROR: {
			return {
				...state,
				error: action.payload,
				message: null,
				updating: false
			}};

		case EVENT_TAG_ADD: {
			// Payload is { itemID: {itemData} }
			// inserts payload.data at the top of NEW array containing all of state too
			return {
				...state,
				activeTagID: Object.keys(action.payload)[0],
				error: null,
				message: null,
				data: _.assign({}, action.payload, state.data)
			};
		}

		case EVENT_TAG_UPDATE: {
			// Payload is { itemID }
			// inserts payload.data at the top of NEW array containing all of state too
			return {
				...state,
				error: null,
				message: null,
				updating: false,
				data: {
					...state.data,
					[action.payload.eventTagID]: action.payload
				}
			};
		}

		case EVENT_TAG_DELETE: {
			// payload is key of item to delete
			// dm_print('ItemReducer: ITEM_DELETE invoked: ', action.payload)
			return {
				...state,
				error: null,
				message: null,
				updating: false,
				data: _.omit(state.data, action.payload)
			};
		}

        // MULTIPLE EVENT TAGS
		case EVENT_TAGS_FETCH: {
            // action.payload is eventTags {}
            if( state.unmount ) return INITIAL_STATE;
			return { 
                ...state, 
                data: action.payload, 
                loading: false,
                unmount: true
            }
        }; 

		case EVENT_TAGS_LOADING:
			return {
				...state,
                loading: true,
                unmount: false
			};

        case EVENT_TAGS_ERROR:
			return {
				...state,
                loading: false,
                unmount: true
			};
	}

	return state;
}
