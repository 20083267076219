import _ from "lodash";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { openModal } from 'actions/app'
import { updateDashboardTag } from "./actions";
import { COLORS } from "Constants/app.js";

class SidebarTagInfo extends Component {
  constructor(props) {
    super(props);

    this.state = { label: "", color: "", dropDown: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedTag, allTags } = nextProps;
    if (selectedTag === null) return null;
    const tagID = selectedTag.dashboardTagID;
    // grabs the tag items data from state, so it will update the color correctly
    const tagObjFromState = allTags[tagID];
    // This helps get rid of the error we're receiving when the selected tag is deleted.
    if (tagObjFromState === undefined) return;
    this.setState({
      label: tagObjFromState.label,
      color: tagObjFromState.color
    });
    if(document.getElementById("selectedTagInput")) document.getElementById("selectedTagInput").focus();
  }

  handleChange = e => {
    const { name, value } = e.target;
    // handles the change of data, sets the dropDown to false once something is clicked
    this.setState({ [name]: value, dropDown: false });
    // if the name is color, we want to call the handle save function
    if (name === "color") this.handleSave(e);
  };

  handleSave = e => {
    const { name, value } = e.target;
    const { selectedTag, allTags } = this.props;
    const tagID = selectedTag.dashboardTagID;
    const tagObjFromState = allTags[tagID];
    // grabs the tag items data from state, so it can correctly check if the new value is equal to the old value.
    if (tagObjFromState[name] === value) return;
    // checks if the field is empty, if it is, will set it back to it's previous state and then not save any changes
    if (_.trim(value) === "") {
      this.setState({ [name]: tagObjFromState[name] });
      return;
    }
    this.props.updateDashboardTag(tagID, { [name]: value });
  };

  // if key pressed is enter, will save the field.
  handleKeyDown = e => {
    if ( e.keyCode === 13 ){ return this.handleSave(e) }
  }

  renderDropDownButtons = () => {
    // color values are brought in through constants. If you'd like to implement
    // another color, you'll need to change it in multiple places in scss as well.
    // see /Constants/app.js
    return Object.keys(COLORS).map(color => {
      return (
        <button
          key={color}
          name="color"
          value={COLORS[color]}
          className="dropdown-item"
          onClick={e => this.handleChange(e)}
        >
          <i
            className={`fas fa-circle fa-lg pull-right btn-outline-${COLORS[
              color
            ]}`}
          />{" "}
          {color}
        </button>
      );
    });
  };

  render() {
    // class to allow drop down to show or hide
    const showDropDown = classNames({
      dropdown: true,
      show: this.state.dropDown
    });

    // if there is not a currently selected tag, returns "select a tag" text
    if (this.props.selectedTag === null)
      return (
        <div className="dbedit-sidebar-tag-info"><h6 id="h6-small">Select a Tag</h6></div>
      );

    return (
      <div
        className="dbedit-sidebar-tag-info"
        onMouseLeave={() => this.setState({ dropDown: false })}
      >

        <div className="field">
          <h6 id="h6-small">Name</h6>
          <input
            id='selectedTagInput'
            autoFocus
            maxLength="16"
            name="label"
            onBlur={this.handleSave}
            onKeyDown={this.handleKeyDown}
            onClick={() => this.setState({ dropDown: false })}
            value={this.state.label}
            onChange={this.handleChange}
          />
        </div>
        <div className="field">
          <h6 id="h6-small">Color</h6>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={showDropDown}>
              <button
                className="dropdown-toggle btn btn-outline-secondary"
                onClick={() =>
                  this.setState({ dropDown: !this.state.dropDown })}
              >
                <i
                  className={`fas fa-circle fa-lg btn-outline-${this.state
                    .color}`}
                />
              </button>
              <div className="dropdown-menu">
                {this.renderDropDownButtons()}
              </div>
            </div>
            <button
              className="btn btn-outline-secondary btn-delete-tag"
              onClick = { () => this.props.openModal('tag', this.props.selectedTag.dashboardTagID, {deleteDependency: false})}
            >
              {"Delete Tag"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allTags: state.userDashboardTags.data
  };
}

export default connect(mapStateToProps, { openModal, updateDashboardTag })(SidebarTagInfo);
