import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import Dashboard from './Dashboard.jsx';
import DashboardComment from './Dashboard.Comment.jsx';

import ArrowButton from 'Widgets/ArrowButton.jsx';

class DashboardWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pauseOnTag: this.props.preferences.pauseOnTag,
		};
	}

	render() {
        const {  
            dashboardTags,
            isItemAnalysisOwner, 
            handleNextClick,
            locked, 
            openModal,
            pause, 
            play, 
            selectedDashboard,
		} = this.props;
		
		const pauseOnTag = this.state.pauseOnTag;
        const { dashboards, itemAnalysisID } = this.props.itemAnalysis;
		// counting how many dashboards we can select from
        const numberOfSelectableDashboards = dashboards.length;
        
        return (
            <div>
                <BottombarTitle
                    handlePauseOnTag={this.handlePauseOnTag}
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    locked={locked}
                    selectedDashboard={selectedDashboard}
                    pauseOnTag={pauseOnTag}
                    activeTab='dashboard' 
                    title={(undefined !== selectedDashboard) ? 'Dashboard: ' + selectedDashboard.title : 'Dashboard'}
                />
                <div className="col-12 analysis-dashboard">
                    <div className="analysis-dashboard-content">
                        {numberOfSelectableDashboards >= 2 ? (
                            <ArrowButton
                                direction="left"
                                size="fa-4x"
                                className="col-1 analysis-dashboard-nav analysis-dashboard-nav-prev"
                                handleClick={() => handleNextClick('prev')}
                            />
                        ) : null}
                        <Dashboard
                            dashboardTags={dashboardTags}
                            selectedDashboard={selectedDashboard}
                            pause={pause}
                            handlePauseOnTag={this.handlePauseOnTag}
                            pauseOnTag={pauseOnTag}
                            itemAnalysisID={itemAnalysisID}
                            isItemAnalysisOwner={isItemAnalysisOwner}
                            locked={locked}
                        />
                        {numberOfSelectableDashboards >= 2 ? (
                            <ArrowButton
                                direction="right"
                                size="fa-4x"
                                className="col-1 analysis-dashboard-nav analysis-dashboard-nav-next"
                                handleClick={() => handleNextClick('next')}
                            />
                        ) : null}
                    </div>
                    {/** Comment Box **/}
                    { !locked 
                    ? <div className="analysis-dashboard-comment">
                        <DashboardComment
                            pause={pause}
                            play={play}
                            dashboardTagID="root_comment"
                            label="Note"
                            itemAnalysisID={itemAnalysisID}
                            color="default"
                        />
                        </div>
                    : null
                    }
                </div>
            </div>
        )
	}
	
	handlePauseOnTag = () => {
		this.setState({ pauseOnTag: !this.state.pauseOnTag });
	};
}

function mapStateToProps(state) {
	return {
        item: state.folderItems.selectedItem,
		itemAnalysis: state.folderItems.selectedItemAnalysis,
		preferences: state.user.data.preferences, 
	};
}

export default connect(mapStateToProps)(DashboardWrapper);

