import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatVideoTime } from '../../../utils';

import Message from 'Widgets/Message.jsx';
import Modal from 'Widgets/Modal.jsx';

import { itemTransciptionCreate } from '../actions/index.js';
import { dismissMessage } from 'actions/app';

import {PROGRESS_FLAGS, ROUTES, TRANSCRIBE_LANGUAGES, TRANSCRIBE_PROGRESS} from 'Constants/app.js';

// Renders <options /> for languages
const languageCodeOptions = (languages, transcriptionsProgress) => {
	var options = [
		<option value="0" key="spkCnt-1" disabled={true} >
			-- Languages -- 
		</option>
	];
	_.forEach(languages, (language, languageCode) => {
		var disabled = false;
		var desc = language;

        // Ignores ERRORS
		if (PROGRESS_FLAGS.INPROGRESS === transcriptionsProgress[languageCode]) {
			disabled = true;
			desc += ' - in progress';
		} else if (PROGRESS_FLAGS.COMPLETED === transcriptionsProgress[languageCode]) {
			disabled = true;
			desc += ' - completed';
		}

		options.push(
			<option value={languageCode} key={languageCode} disabled={disabled}>
				{desc}
			</option>
		);
	});
	return options;
};

// Renders <options /> for number of speakers
const speakerCountOptions = () => {
	let options = [
		<option value="0" key="spkCnt-1">
			-- Speaker Count --
		</option>
	];
	for (var x = 1; x < 11; x++) {
		options.push(
			<option value={x} key={x}>
				{x} {x > 1 ? 'speakers' : 'speaker'}
			</option>
		);
	}
	return options;
};
class TranscribeModal extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			languageCode: '0',
			loading: false,
			showHelpText: false,
			speakerCount: 1
		};
	}

	componentWillUnmount() {
		this.props.dismissMessage();
	}

	render() {
		const {
            itemDuration,
            messages,
			selectedItemAnalysis,
			transcriptionsProgress,
        } = this.props;
        
        // make this editable to toggle locally
        let {canTranscribe}  = this.props;

		const {languageCode, loading, speakerCount} = this.state;

		if (selectedItemAnalysis === undefined) return null;

		const renderSuccess = (false === messages.error && '' !== messages.message)
		
		// Disabled quotaCanTranscribe to remove limits
        // const quotaCanTranscribe = (itemDuration + transcriptionSeconds) <= maxTranscribe

        // Create relevant instructions if not rendering success message
        if( !renderSuccess ) {
            var instructions = <span>Welcome to Dreamaker.io Transcribe</span>
            if(!canTranscribe) {
                instructions = 
                    <Link to={ROUTES.UPGRADE}>
                        Transcriptions are available in upgraded plans. <i>Upgrade now.</i>
                    </Link>
            } 
            // else if( !quotaCanTranscribe ) {
            //     instructions = 
            //         <span>
            //             Congrats you've done it, you've reached (or with this transcription will reach) the 2-hour beta limit on transcriptions!
            //             <p>
			// 				<ChatNow>Now send us feedback, we'd love to hear it!</ChatNow>
            //             </p>
            //         </span>
            // }
            else if( 7200 <= itemDuration ) { // 1.5 hours - max is 2 but transcribe breaks when wav file bigger than 2GB (98mins)
                canTranscribe = false;
                instructions = 
                    <span>
                        Dreamaker.io Transcribe is limited to audio and video projects with a maximum duration of 2-hours. This project is {formatVideoTime(itemDuration)}.
                    </span>
            }
        }
        
		const modalClasses = classNames({
			'modal-md': true,
			loading: loading
        });

        return (
            <Modal size={modalClasses}>
                <div className="modal-header">
					<div className="modal-subtitle">Transcription Settings</div>
					<div className="modal-title">
                        {selectedItemAnalysis.title}
                    </div>
				</div>
				<div className="modal-body">
					<div className="row">
						<div className="col-12 text-justify">
							{ renderSuccess 
                            ? this.renderMessage()
                            : <div>{instructions}</div>
                            }
						</div>
                        { true === messages.error 
                        ? <div className="col-12 text-justify">
                            {this.renderMessage()}
						</div>
                        : null
                        }    
					</div>
                    { // Render Transcribe settings or success message
                        renderSuccess
                        ? null
                        : <div className="row">
                            <div className="col-12" style={{padding: '1em 2.3em 0em'}}>
                                Select primary language:{' '}
                                <select
									//disabled={!canTranscribe || !quotaCanTranscribe}
									disabled={!canTranscribe}
                                    id="languageCode"
                                    className="form-control"
                                    name="languageCode"
                                    tabIndex="1"
                                    autoFocus
                                    value={this.state.languageCode}
                                    onChange={e => this.setState({languageCode: e.target.value})}>
                                    {languageCodeOptions(TRANSCRIBE_LANGUAGES, transcriptionsProgress)}
                                </select>
                                <br />
                                Select number of speakers:{' '}
                                <select
                                    id="speakerCount"
                                    className="form-control"
                                    //disabled={!canTranscribe || !quotaCanTranscribe}
									disabled={!canTranscribe}
                                    name="speakerCount"
                                    tabIndex="2"
                                    value={this.state.speakerCount}
                                    onChange={e => this.setState({speakerCount: e.target.value})}>
                                    {speakerCountOptions()}
                                </select>
                                {/* <br />
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        data-dismiss="modal"
                                        onClick={this.context.closeModal}
                                        >
                                        Reset
                                    </button> */}
                            </div>
                            {this.renderHelpText()}                           
                        </div>
                    }
                    
                    {/* <i>< ChatNow text='Give us feedback.' chat={true} /></i> */}
                </div>
				{ 	// Display Transcribe or Done buttons
					//renderSuccess || '0' === languageCode || '0' === speakerCount || !canTranscribe || !quotaCanTranscribe
                    renderSuccess || '0' === languageCode || '0' === speakerCount || !canTranscribe
                    ? <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={this.context.closeModal}
                            type="button">
                            Done
                        </button>
                    </div>
                    : <div className="modal-footer">
                        <button
                            disabled={loading}
                            className="btn btn-success"
                            onClick={this.handleTranscribe}
                            tabIndex='3'
                            type="button">
                            Transcribe
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            data-dismiss="modal"
                            onClick={this.context.closeModal}
                            tabIndex='4'
                            type="button">
                            Cancel
                        </button>
                    </div>
                }
			</Modal>
        );
	}

	handleTranscribe = e => {
		this.setState({loading: true});

		const {languageCode, speakerCount} = this.state;

		this.props
			.itemTransciptionCreate(
				this.props.selectedItemAnalysis.itemID,
				languageCode,
				speakerCount
			)
			.then(resp => {
				this.setState({loading: false});
			})
			.catch(err => {
				this.setState({loading: false});
			});
	};

	renderHelpText = () => {
		const helpIconClassName = classNames({
			far: true,
			'fa-question-circle': !this.state.showHelpText,
			'fa-chevron-up': this.state.showHelpText
		});

		return (
            <div className="col-12 list-over-flow">
                <h6 id="help-title"
                    className={this.state.showHelpText ? 'help-text-shown' : 'help-text-hidden'}
                    onClick={this.toggleHelpTextVisibility}
                    >
                    Help with Dreamaker.io Transcribe
                    <i className={helpIconClassName} />
                </h6>
                <div id="help-text"
                    className={
                        this.state.showHelpText ? 'help-text-shown' : 'help-text-hidden'
                    }>
                    <p style={{marginTop: '0rem'}}>
                        Automated transcriptions require selecting the language spoken and number of speakers.
                    </p>
                    <ul>
                        <li><b>Primary language:</b> Only one language can be transcribed at a time. Select the dominant accent for higher accuracy.</li>
                        <li><b>Number of speakers:</b> The number of different people speaking.</li>
                        <li><b><a href='http://get.dreamaker.help/dreamaker-io-transcribe' target='_blank'>Help Center - Dreamaker.io Transcribe</a></b></li>
                    </ul>
                </div>
            </div>
		);
	};

	toggleHelpTextVisibility = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({showHelpText: !this.state.showHelpText});
	};

	renderMessage() {
		const {message, error} = this.props.messages;
        if (message) 
            return <Message message={message} style={{'marginTop': '0.5rem', padding: '0rem 1rem'}} textClass={error ? 'danger' : 'success'} dismissable={false} />;
	}
}

function mapStateToProps(state) {
	return {
		canTranscribe: state.account.planData.maxTranscribe > 0,
        itemAnalysisLOBs: state.itemAnalysisLOBs,
        itemDuration: state.folderItems.selectedItem.durationSeconds,
		// maxTranscribe: state.account.planData.maxTranscribe,
        messages: state.messages,
        selectedItemAnalysis: state.folderItems.selectedItemAnalysis,
        // transcriptionRequests: state.account.usageData.transcriptionRequests,
		// transcriptionSeconds: state.account.usageData.transcriptionSeconds,
        transcriptionsProgress: state.folderItems.selectedItem.transcribeProgress || {},
	};
}

const mapDispatchToProps = {
	dismissMessage,
	itemTransciptionCreate
};

export default connect(mapStateToProps, mapDispatchToProps)(TranscribeModal);
