import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { addEventTag } from '../actions/index';
import { buildTag } from '../../../utils';

class DashboardTag extends Component {
	render() {
		const { label, color } = this.props;
		const classes = 'btn btn-' + color + ' analysis-dashboard-single-button';
		// changed these to anchors to overcome Chrome focus issue
		return (
			<button
				className={classes}
				aria-label={label}
				onClick={this.handleClick}
				data-toggle="tooltip"
				title={ this.props.locked ? 'Project analyses is locked' : label}
                disabled={this.props.locked} >
				{label}
			</button>
		);
	}

	handleClick = event => {
        event.preventDefault();

        // Prevent updates if project is locked
        if (this.props.locked) return;
        
		this.props.addEventTag(this.props);
		// pause is passed down from media player.
		if (this.props.pauseOnTag === false) return;
		this.props.pause();
	};
}

function mapStateToProps(state) {
	return {
		// All below used in this.props.addEventTag()
		currentTime: state.playerState.currentTime,
		avatarURL_32: state.user.data.avatarURL_32,
		initials: state.user.data.initials,
		currentUserID: state.user.data.userID
	};
}

const mapDispatchToProps = { addEventTag };

// This container does not care about state, so simply put in null for the first guy
export default connect(mapStateToProps, mapDispatchToProps)(DashboardTag);
