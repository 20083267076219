// TODO: Change ROUTES.LANDING to browser.back
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import classNames from 'classnames'

class PageBland extends Component {
  render() {
    const { addClasses } = this.props
    const headerClassnames = classNames({
      "page": true,
      "page-bland": true,
      [addClasses]: addClasses && true
    })

    // const style = 
    //   border-top: 3px solid #fcd500;
    // }
    return (
      <div className={headerClassnames}>
			<a href="https://www.dreamaker.io" className="access-logo" target_="blank" ></a>
			<h3 style={{ marginBottom: '2rem' }}>dreamaker.io</h3>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-sm-10 offset-sm-1 col-md-11 offset-med-1">
              <div className="main main-bland">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageBland;
