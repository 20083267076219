/**
 * NOTE:
 * Initial state: null
 * After recordings fetch: Object 
 * Loading handled with local state
 */
import _ from 'lodash';

import {
	CUNAUTH_USER,
	ZOOM_CLEAR_RECORDINGS,
	ZOOM_LIST_RECORDINGS,
	ZOOM_IMPORT,
} from 'Constants/actions';



const INITIAL_STATE = null

export default function (state = INITIAL_STATE, action) {

	switch (action.type) {

		case CUNAUTH_USER:
		case ZOOM_CLEAR_RECORDINGS:
			return INITIAL_STATE;

		case ZOOM_LIST_RECORDINGS: {
			// payload is an Object of all recordings
			return {
				...action.payload,
			};
		}

	}

	return state;
}
