import _ from 'lodash';

import {
    ACCOUNT_REHYDRATE,
    REPORT_LTI_ATTACH_FETCH,
	REPORTS_ERROR
} from 'Constants/actions';
import {INVITE_STATUS} from 'Constants/app'
import {request} from 'actions/index';
import {apiConfig} from 'config';

import { APP_OPTIONS, PROGRESS_FLAGS } from 'Constants/app';

// REPORTS
/**
 * Fetches map of members from cloud.
 * @returns {Map} with format:
 * {
 *      [REPORT_TYPE]: {},
 *      members: { userID_ABC: {}, userID_DEF: {} }
 * }
 */
 export function fetchLtiAttachReport() {
	return function(dispatch) {
        // NOTE: LOADING status indicated by absence of state.members
		const requestObj = {
			url: `${apiConfig.ltiAttachReport}`
		};

		request(requestObj, REPORTS_ERROR)
		.then(resp => {
			dispatch({
				type: REPORT_LTI_ATTACH_FETCH,
				payload: resp
			});
		})
		.catch(err => { });
	};
}