import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import BtnIcon from 'Widgets/ButtonIcon.jsx';
// import MenuBtn from 'Widgets/MenuButton.jsx';
// import CollaborateMenuBtn from 'Menus/CollaborateMenuBtn.jsx'
// import DashboardsMenuBtn from 'Menus/DashboardsMenuBtn.jsx'
import DeleteItemMenuBtn from 'Menus/DeleteItemMenuBtn.jsx'
// import LeaveMenuBtn from 'Menus/LeaveMenuBtn.jsx'
// import MarkersMenuBtn from 'Menus/MarkersMenuBtn.jsx'
import OpenItemMenuBtn from 'Menus/OpenItemMenuBtn.jsx'
// import RenameItemMenuBtn from 'Menus/RenameItemMenuBtn.jsx'
// import SendMenuBtn from 'Menus/SendMenuBtn.jsx'

import { openModal } from 'actions/app';
// import { formatStorage } from 'utils/';

class ZoomImportsDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false,
			loading: false
		};
	}

	render() {
		const { showDropdown } = this.state;
		return (
			<div className="dropdown show contextMenu" onMouseLeave={() => this.handleDDLeave()} >
				<BtnIcon
					disabled={this.props.disabled}
					onClick={() => this.setState({ showDropdown: !showDropdown })}
					tooltip=""
					btnClass="btn btn-link"
					iconClass="far fa-ellipsis-h"
					style={{paddingRight: '10px', width: '100%', height: '100%'}}
				/>
				{showDropdown && this.renderedButtons()}
				</div>
		);
    }
    
	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
        let {loading} = this.state;
		const {
			handleItemOpen,
			itemID
		} = this.props;
		
		return (
			<div className="dropdown-menu dropdown-menu-right">
				<OpenItemMenuBtn handleOnClick={e => { handleItemOpen(itemID) }} />
				{/* 
					// NOTE: TODO: No lock pass thru here because unlock will be needed too...
					<DeleteItemMenuBtn handleOnClick={this.handleOpenModal} locked={locked} label='Delete now' /> 
				*/}
				<DeleteItemMenuBtn handleOnClick={this.handleOpenModal} locked={false} label='Delete now' />
			</div>
		);
	};
	
	handleOpenModal = (e, name, action) => {
		e.preventDefault();
		this.setState({ showDropdown: false });
		this.props.openModal(name, '', { action });
	};

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};
	
	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
	};
}

function mapStateToProps(state) {
	return {};
}

export default connect(
	mapStateToProps,
	{ openModal }
	)(ZoomImportsDropdown);
