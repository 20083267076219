import React from 'react';

const tagStyle = {
	color: '#F1D432',
	fontWeight: 800
};

// Props {show, startTag, endTag, note
const NoteToUser = props => (
	<small style={{display: props.show}}>
		{props.message ? <small>{props.message}</small> : null}
		<span style={tagStyle}>{props.startTag}</span>
		{props.note}
		<span style={tagStyle}>{props.endTag}</span>
	</small>
);

export default NoteToUser;
