import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import DataVisualizerGraph from './DataVisualizer.Graph.jsx';
import {openModal} from 'actions/app';

import {
	createChart,
	// fetchChart,
	updateChart,
	createDataset,
	setChartLoading,
	clearChartState
} from '../actions/index';

const colorScale = [
	"#fcd500",
	"#FB9800",
	"#9F86FF",
	"#1997c6",
	"#1BC98E",
	"#a25397",
	"#f5f5f5"
];


class DataVisualizerWrapper extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTopShadow: false,
			showBottomShadow: true
		};
    }

	componentWillUnmount() {
        //NOTE: This will require constant refetching of data from cloud. 
        //TODO: Needs to mov to in Analysis.jsx unmount
		// this.props.clearChartState();
	}

	componentDidUpdate(prevProps, prevState) {
		let container = this.refs.dataVisualizerContainer;
		if ( (prevState.showBottomShadow === true || prevState.showTopShadow === true)
		     && (container.scrollHeight <= parseInt(container.style.maxHeight)) ) {
			this.setState({showBottomShadow: false});
			this.setState({showTopShadow: false});
		}
	}

	handleScroll(e) {
		// Detect scrolling on the container div, and update classes to display overflow shadows

		// Top shadow:
		if (e.target.scrollTop > 0) {
			this.setState({showTopShadow: true});
		} else {
			this.setState({showTopShadow: false});
		}

		// Bottom shadow:
		if ( (e.target.scrollTop + e.target.clientHeight) < e.target.scrollHeight ) {
			this.setState({showBottomShadow: true});
		} else {
			this.setState({showBottomShadow: false});
		}
	}

	handleManageDatasetsClick(e) {
		e.preventDefault();
		e.stopPropagation();

		// Create a data object to pass to the openModal function,
		// so that the modal can have these props.
		const data = {
			colorScale: colorScale,
			createChart: this.props.createChart,
			updateChart: this.props.updateChart,
			createDataset: this.props.createDataset
		};

		this.props.openModal('datasetsModal', '', data);
	}

	render() {
		const {chart, locked, loading, isItemAnalysisOwner, isResizingSidebar, isResizingVideo} = this.props;
		// const itemAnalysisID = this.props.itemAnalysis.itemAnalysisID;
        const displayLoading = (loading || isResizingSidebar || isResizingVideo);
        
		const containerClass = classNames({
			'col-12': true,
			'top-shadow': this.state.showTopShadow,
			'bottom-shadow': this.state.showBottomShadow,
			'has-left-y-axis-label': (chart.leftYAxisLabel !== undefined && chart.leftYAxisLabel !== ''),
			'loading': displayLoading
		});

		return (
            <div>
                <BottombarTitle
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    locked={locked}
                    // selectedDashboard={selectedDashboard}
                    activeTab='data-visualizer' 
                    title = 'Data Visualization'
                />   
                <div
                    id={"analysis-data-visualizer"}
                    className={containerClass}
                    ref="dataVisualizerContainer"
                    style={{
                        maxHeight: this.props.bottombarHeight-60,
                        height: displayLoading ? this.props.bottombarHeight-60 : 'auto'
                    }}
                    onScroll={this.handleScroll.bind(this)}
                    >
                    {this.renderChart()}
                    {displayLoading
                    ? '' 
                    : <ButtonIcon
                        btnClass="dataset-upload-button btn btn-outline-secondary"
                        iconClass="far fa-cloud-upload"
                        label="Manage Datasets"
                        onClick={this.handleManageDatasetsClick.bind(this)}
                    />
                    }
                </div>
            </div>
		)
	}

	renderChart = () => {
		const {loading, isResizingSidebar, isResizingVideo, chart} = this.props;
		const displayLoading = (loading || isResizingSidebar || isResizingVideo);

		// Display loading indicator
		if (displayLoading) {
			return (
				<i className="loading-indicator fa fa-circle-notch fa-spin fa-3x fa-fw" />
			);
		}

		// Render the chart.
		// This works even with no real data displays a placeholder chart,
		// because the chart object was loaded up with dummy data earlier)
		return (
			<DataVisualizerGraph
				chart={chart}
				colorScale={colorScale}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		item: state.folderItems.selectedItem,
		itemAnalysis: state.folderItems.selectedItemAnalysis,
		itemAnalysisLOBs: state.itemAnalysisLOBs,
		bottombarHeight: state.app.bottombarHeight,
		chart: state.itemAnalysisCharts.data,
		loading: state.itemAnalysisCharts.loading
	};
}

const mapDispatchToProps = {
	createChart,
	// fetchChart,
	updateChart,
	createDataset,
	setChartLoading,
	clearChartState,
	openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DataVisualizerWrapper);