import React, {Component} from 'react';
import {connect} from 'react-redux';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

class MarkerTitle extends Component {
	render() {
		const {selectedMarker, handleUpdateMarker, handleDeleteMarker, markers} = this.props;
		return (
			<div className="markerEdit-title">
				<h6 id="h6-small">{I18n.get('Title')}</h6>
				<h3>
					{!selectedMarker ? (
						'Select Marker'
					) : (
						<input
							autoFocus={true}
							placeholder="Add Title"
							key={selectedMarker}
							maxLength="22"
                            onClick={ ()=>{this.props.dismissMessage()}}
							onKeyDown={this.handleKeyDown}
							onBlur={e => handleUpdateMarker('title', e.target.value)}
							defaultValue={markers[selectedMarker].name}
							tabIndex="1"
						/>
					)}
				</h3>
			</div>
		);
    }
	handleKeyDown = (e, value) => {
		if (e.keyCode === 13) {
			return this.props.handleUpdateMarker('title', e.target.value);
		}
	};
}

function mapStateToProps(state) {
	return {
		markers: state.userMarkers.data
	};
}

export default connect(mapStateToProps)(MarkerTitle);
