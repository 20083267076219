import _ from 'lodash';

import { 
    _getSharedItemCookies,
    _dmAxiosErrorHandler, 
    request 
} from 'actions/index';

import { 
    apiConfig, 
} from 'config';

import {
	ACCOUNT_REHYDRATE,
	LTI_ERROR,
} from 'Constants/actions';


/**
 * Adds an listConsumer to an organization. Returns Promise,
 * @param {String} ltiConsumerID ltiConsumerID to add
 */
export function addLTIConsumer(ltiConsumerID) {
	return function(dispatch, getState) {

        const requestObj = {
			method: 'POST',
			url: `${apiConfig.ltiConsumerAdd}`,
			data: {
				ltiConsumerID,
			}
		};

        return request( requestObj, LTI_ERROR )
        .then(resp => {
            dispatch({
                type: ACCOUNT_REHYDRATE, 
				payload: {
					...getState().account, 
					ltiOrganizationConsumers: {
						...getState().account.ltiOrganizationConsumers,
						[ltiConsumerID]: {
							ltiConsumerSecret: resp.ltiConsumerSecret
						}
					}
				}
			})
			
			return Promise.resolve();
		})
		.catch( err => {
			return Promise.resolve();
		})
	};
}

export function updateLTIConsumer(ltiConsumerID, rollConsumerSecret = true) {
	return function(dispatch, getState) {

        const requestObj = {
			method: 'POST',
			url: `${apiConfig.ltiConsumerUpdate}`,
			data: {
				ltiConsumerID,
				rollConsumerSecret,
			}
        };

        return request( requestObj, LTI_ERROR )
        .then(resp => {
			const account = getState().account;
            dispatch({
                type: ACCOUNT_REHYDRATE, 
				payload: {
					...account, 
					ltiOrganizationConsumers: {
						...account.ltiOrganizationConsumers,
						[ltiConsumerID]: {
							...account.ltiOrganizationConsumers[ltiConsumerID],
							ltiConsumerSecret: resp.ltiConsumerSecret
						}
					}
				}
			})
			return Promise.resolve();
		})
		.catch( err => {
			return Promise.reject();
		})
	};
}