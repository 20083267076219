import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';
import { v4 as uuidV4 } from 'uuid';

import {dismissMessage} from 'actions/app';
import {createNewMarker, createMarkerOption, deleteMarker, updateMarker} from './actions/';

import PageLanding from '../Layout/page.landing.jsx';
import MarkerSelects from './Marker.Selects.jsx';
import MarkerOptions from './Marker.Options.jsx';
import MarkerTitle from './Marker.Title.jsx';
import MarkerDefaultCheck from './Marker.Default.Check.jsx';
import DashboardMarkerLink from 'Widgets/DashboardMarkerLink.jsx';

class Markers extends Component {
	constructor(props) {
		super(props);
		this.state = {selectedMarker: null};
	}

	render() {
		const {selectedMarker} = this.state;
        const {markers} = this.props;
		return (
			<PageLanding renderBackButton={true} >
				<div className="dbedit">
					<div className="dbedit-landing-sidebar-layout">
						<DashboardMarkerLink />
						<MarkerSelects
							handleClick={this.handleMarkerSelect}
							selectedMarker={selectedMarker}
							handleCreateMarker={this.handleCreateMarker}
							dismissMessage={this.props.dismissMessage}
						/>
					</div>
					<div className="dbedit-content-subhead">
						<div className="dbedit-content-messages-div">
                            {this.renderMessages()}
                        </div>
						{this.renderSelectedMarker(selectedMarker, markers)}
					</div>
					<div className="dbedit-landing-sidebar-layout" />
				</div>
			</PageLanding>
		);
    }
    
	handleMarkerSelect = id => {
		this.props.dismissMessage();
		this.setState({selectedMarker: id});
	};

	// if doSave - marker is saved to dynamo, otherwise remains in local state only
	handleCreateMarker = (doSave = true) => {
		// If (!doSave), assume it's a new marker, so create it
		if (!doSave) {
			// creating the temporary marker bbject
			var newMarker = {
				options: {},
				name: '',
				markerID: uuidV4(),
				// we want to pass this through so we can check if it should be saved or not
				// this prop does not get saved by the database. Only used locally.
				doSave: false
			};
			// Other wise pickup the makrer from state via selectedMarker
		} else {
			var newMarker = this.props.markers.data[this.state.selectedMarker];
		}
		// doSave will either be true or false, which means we are saving it either only locally,
		// or also to the database.
		this.setState({selectedMarker: newMarker.markerID});
		this.props.createNewMarker(newMarker, doSave);
	};

	handleCreateMarkerOption = newOption => {
		const {selectedMarker} = this.state;
		const {markers} = this.props;
		// doesn't allow user to create an empty option.
		if (newOption === '') return;
		if (selectedMarker) {
			this.props.createMarkerOption(
				selectedMarker,
				newOption,
				markers.data[selectedMarker].doSave
			);
		}
	};

	handleUpdateMarker = (fieldType, toUpdateText, key) => {
		const {selectedMarker} = this.state;
		const {markers} = this.props;
		// trimming the data before we send it
		const updatedText = _.trim(toUpdateText);
		let newMarkerObject = {
			...markers.data[selectedMarker]
		};
		// if the fieldType we pass through is title, we know we're just updating the title.
		if (fieldType === 'title') {
			// checks if the current name is the same as the old name, if it is, we don't need to save anything.
			if (newMarkerObject.name === updatedText || updatedText === '') return;
			// need to edit the newMarkerObject with spread operator as to not mutate state
			newMarkerObject = {
				...newMarkerObject,
				name: updatedText
			};
			this.props.updateMarker(newMarkerObject, newMarkerObject.doSave);
			return;
		}
		// if the fieldType we pass through is delete, we know we're deleting the item.
		if (fieldType === 'delete') {
			// need to create the newOptions object as to not mutate state
			const newOptions = {...newMarkerObject.options};
			delete newOptions[key];
			newMarkerObject = {
				...newMarkerObject,
				options: {
					...newOptions
				}
			};
			this.props.updateMarker(newMarkerObject, newMarkerObject.doSave);
			return;
		}
		// checks if the current option text is the same as the updated text, if so we don't need to save.
		if (newMarkerObject.options[key] === updatedText) return;
		// otherwise, we're updating the options.
		// need this in order to not mutate state.
		newMarkerObject = {
			...newMarkerObject,
			options: {
				...newMarkerObject.options,
				[key]: updatedText
			}
		};
		this.props.updateMarker(newMarkerObject, newMarkerObject.doSave);
		return;
	};

	handleDeleteMarker = () => {
		const {selectedMarker} = this.state;
		if (selectedMarker) {
			this.setState({selectedMarker: null});
			this.props.deleteMarker(selectedMarker);
		}
	};

	handleDefaultSelect = () => {
		const {markers} = this.props;
		const {selectedMarker} = this.state;
		let biggestDefaultSortOrder = 0;
		// need to find the biggest default sort ordered number in order to correctly set the defaultSortOrder
		Object.keys(markers.data).forEach(item => {
			// if it's not set as a default, we don't care about it.
			if (!markers.data[item].default) return;
			if (biggestDefaultSortOrder < markers.data[item].sortOrder) {
				biggestDefaultSortOrder = markers.data[item].sortOrder;
			}
		});
		let newMarkerObject = {
			...markers.data[selectedMarker],
			default: !markers.data[selectedMarker].default,
			sortOrder: biggestDefaultSortOrder + 1
		};
		this.props.updateMarker(newMarkerObject, newMarkerObject.doSave);
	};

	renderSelectedMarker = (selectedMarker, markers) => {
		// if the selectedMarker is not falsy, we want to return the options and title edit.
		if (selectedMarker) {
			return (
				<div className="dbedit-content-dbtags markerEdit">
					<div>
						<MarkerTitle
							handleUpdateMarker={this.handleUpdateMarker}
							handleDeleteMarker={this.handleDeleteMarker}
							selectedMarker={selectedMarker}
                            dismissMessage={this.props.dismissMessage}
						/>
						<MarkerDefaultCheck
							handleDefaultSelect={this.handleDefaultSelect}
							selectedMarker={selectedMarker}
						/>
						{false === this.props.markers.data[selectedMarker].doSave ? (
							<button
								className="btn btn-outline-success"
								onClick={this.handleCreateMarker}
								tabIndex="99">
								{I18n.get('Save')}
							</button>
						) : (
							''
						)}
					</div>
					<MarkerOptions
						selectedMarker={selectedMarker}
						handleCreateMarkerOption={this.handleCreateMarkerOption}
						handleUpdateMarker={this.handleUpdateMarker}
                        dismissMessage={this.props.dismissMessage}
					/>
				</div>
			);
		}
		// otherwise we want to return the select a marker message
		return (
			<div className="markerEdit-noneSelected">
				<h2 id="h2-large">{I18n.get('Select a marker')} </h2>
			</div>
		);
	};

    renderMessages = () => {
        const {message, error} = this.props.messages
        return (
            <p id="dbedit-content-messages" 
                className={`text-${error ? 'danger' : 'success'}`}>
                {message}
            </p>
        );
	};
}

function mapStateToProps(state) {
	return {
		markers: state.userMarkers,
        ownerID: state.user.data.userID,
        messages: state.messages
	};
}

const mapDispatchToProps = {
	createNewMarker,
	createMarkerOption,
	deleteMarker,
	dismissMessage,
	updateMarker
};

export default connect(mapStateToProps, mapDispatchToProps)(Markers);
