/**
 * Notes on Quill Editor
 * NOTE: getContents() returns a delta.
 */

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import ReactQuill, {Quill} from 'react-quill';
import MagicUrl from 'quill-magic-url';
Quill.register('modules/magicUrl', MagicUrl);

import {Loading} from 'Widgets/Loading.jsx';

import {getAnalysisLob, itemAnalysisLobUpdate} from '../actions/index';

import {LOB_KEYS} from 'Constants/app'

const EMPTY_DELTA = { ops: [] };
const UPGRADE_DELTA = { "ops": [{ "insert": "Project notes - " }, { "attributes": { "link": "https://app.dreamaker.io/upgrade" }, "insert": "upgrade" }, { "insert": " to view and edit project notes.\n" }]}
// const EMPTY_DELTA = {"ops": [{"insert": "Empty delta\n"}]}

var modules = {
	toolbar: [
		[{header: [1, 2, 3, false]}],
        //[{ 'font': [] }],
        ['bold', 'italic', 'underline'],   //, 'strike'
        
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'indent': '-1'}, { 'indent': '+1' }],     

        [{ 'color': [] }, { 'background': [] }],

        ['link']
        
        // ['code-block'],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    ],

    clipboard: {
        matchVisual: false  // Avoids matching pasted goodies, decreasing paste time
    },

    // magicUrl: true
    magicUrl: {
        // Enable case insensitive replication
        globalRegularExpression: /(https?:\/\/\.|mailto:)[\S]+/gi,
        urlRegularExpression: /(https?:\/\/[\S]+)|(mailto:[\S]+)/i
        // globalRegularExpression: /(https?:\/\/\.|mailto:|www.)[\S]+/gi,
        // urlRegularExpression: /(https?:\/\/[\S]+)|(mailto:[\S]+)|(www.[\S]+)/i
        // globalRegularExpression: /((https?:\/\/\.|mailto:|www.)[\S]+)|(^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)/gi,
        // urlRegularExpression: /(https?:\/\/[\S]+)|(mailto:[\S]+)|(www.[\S]+)|(^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)/i
      }
};

class Editor extends Component {
	constructor(props) {
        super(props);
        
        // Placeholders for notes editor
        this.PLACEHOLDER = {
            NO_NOTES: 'No project notes.',
            ADD_NOTES: 'Add project notes...',
            // UPGRADE: 'Projects notes - available in upgraded plans.'
        }

		this.state = {
			autosaveInterval: false,    // false if no interval, otherwise setInterval for autosaving
            saved: true,                // True if save() && no onChange() calls. Assume no changes on construction
            saving: false,
            deltaComposed: false,
		};
    }

    componentDidMount() {
        // Prevents setState() being called on unmounted component while waiting for Promise to return
        this._isMounted = true;
		
		// Load notes lob if not already loaded
		const { 
			itemAnalysisID,
			itemAnalysisLOBs
		 } = this.props;  
		const lobs = itemAnalysisLOBs[itemAnalysisID] || {};
		
		// 'LOAD' - needs loading. false | true has loaded
		if('LOAD' === lobs.notesLoading) { 
			this.props.getAnalysisLob(itemAnalysisID, LOB_KEYS.NOTES)
		}
    }



	componentWillUnmount() {
        this.save(); //also clears autosaveInterval
        clearInterval(this.state.autosaveInterval); //clear incase save fails
        this._isMounted = false;
	}

	render() {
		const {
            canNotes, 
            locked, 
            isItemAnalysisOwner, 
            itemAnalysisLOBs,
            itemAnalysisID, 
            maxHeight
        } = this.props;      

        const lobs = itemAnalysisLOBs[itemAnalysisID] || {};

        const loading = lobs.notesLoading || false
        if( loading ) return <Loading />

        const {deltaComposed, saving } = this.state;

        var notes = lobs.notes || EMPTY_DELTA;

        let editorPlaceholder = !isItemAnalysisOwner || locked ? this.PLACEHOLDER.NO_NOTES : this.PLACEHOLDER.ADD_NOTES
		if (!canNotes && isItemAnalysisOwner) {
			// editorPlaceholder = this.PLACEHOLDER.UPGRADE
			notes = UPGRADE_DELTA
		}

        const saveClass = classNames({
            fal: saving ? false : true, 
            'fa-save': saving ? false : true,
            saving: saving ? true : false
        })
          
        // const saveHTMLClass = classNames({
        //     fal: saving ? false : true, 
        //     'fa-code': saving ? false : true,
        //     saving: saving ? true : false
        // })

		return (
            <div style={{
                maxHeight: maxHeight,
                // display: this.state.deltaComposed ? 'inline' : 'none'
                }}>
                {!isItemAnalysisOwner || locked || !canNotes
                ? null
                : <div className="editor-ql-btns">
                    <i className={saveClass} 
                        disabled={saving} 
                        onClick={this.save}
                        style={ saving ? {fontSize: '.8rem'} : {} }
                        title="Save. Cmd-S / Ctrl-S"
                        >{ saving ? 'Saving...' : null }
                    </i>
                    {/* <i className={saveHTMLClass} 
                        disabled={saving} 
                        onClick={this.saveHTML}
                        style={ saving ? {fontSize: '.8rem'} : {} }
                        title="Save. Cmd-S / Ctrl-S"
                        >{ saving ? 'HTML Saving...' : null }
                    </i> */}
                </div>
                }
                <ReactQuill
                    ref="reactQuillRef"
                    bounds={'.quill'}
                    defaultValue={notes}
                    modules={modules}
                    placeholder={editorPlaceholder}
                    readOnly={ !isItemAnalysisOwner || locked || !canNotes }
                    theme={!isItemAnalysisOwner || locked ? 'bubble' : 'snow'}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                />
            </div>
		);
    }

    // initiateLoad() {
    //     console.log('initiateLoad() called')

    //     const {
    //         itemAnalysisLOBs,
    //         itemAnalysisID, 
    //     } = this.props;      

    //     const lobs = itemAnalysisLOBs[itemAnalysisID] || {};    
        
    //     // return

    //     if(this.refs['reactQuillRef'] && lobs.notes) {
    //         console.log('initiateLoad() - YES >> ref set && lob.notes - ', lobs.notes)
    //         // console.log('initiateLoad() - YES >> ref set && lob.notes.ops - ', lobs.notes.ops)
    //         console.log('initiateLoad() - YES >> lob.notes.ops.length - ', lobs.notes.ops.length)

    //         let editor = this.refs['reactQuillRef'].getEditor();

    //         if(lobs.notes) {
    //             if(lobs.notes.ops.length < 512  ) {
    //                 editor.updateContents(
    //                     lobs.notes
    //                 )
    //                 return; 
    //             }
    //         }

    //         // Large Lob, lazy load it
    //         // for(var x=lobs.notes.ops.length; x>=0; x--) {
    //         for(var x=0; x<lobs.notes.ops.length; x++) {
    //             // console.log(`initiateLoad() for(${x})`, lobs.notes.ops[x])

    //             setTimeout((editor, content) => {
    //                 editor.updateContents(
    //                     {"ops": content}
    //                 )
    //             }, 0, editor, [lobs.notes.ops[x]]);
    //         }
    //         // this.refs['reactQuillRef'].getEditor().updateContents(
    //         //     lobs.notes
    //         // )
    //     }
    //     else{
    //         console.log('initiateLoad() - NOT >> ref set && lob.notes')
    //     }
    // }

	handleKeyDown = e => {
		if (
			(e.keyCode === 13 && e.metaKey) ||              //Cmd+Enter or Ctrl+Enter to Submit
			(e.keyCode === 83 && (e.ctrlKey || e.metaKey))  //Ctrl-S to Save
		) {
			e.preventDefault();
			this.save();
		}
	};

	handleChange = (content, delta, source, editor) => {
        // console.log('Editor: handleChange()')
		this.setState({
			// text: editor.getContents(),
			saved: false
		});

		// Set autosaveInterval to every two minutes.
		if (!this.state.autosaveInterval) {
			this.setState({
				autosaveInterval: setInterval(this.save, 120000)
			});
		}
	};

	handleBlur = (previousRange, source, editor) => {
		this.save(editor);
	};

	save = () => {   
        if( this.state.saved === true ) return

        const {isItemAnalysisOwner, canNotes, locked, itemAnalysisID } = this.props;      // loading indicates saving
        
        //Dont save if...
        if (!isItemAnalysisOwner || locked || !canNotes ) return;
        
        this.setState({saving: true})
        
        // Save... IE, update
        this.props.itemAnalysisLobUpdate(itemAnalysisID, LOB_KEYS.NOTES, this.refs['reactQuillRef'].getEditor().getContents())
        .then(resp => {
            // Clear autosave and update saving state
            clearInterval(this.state.autosaveInterval);
            this._isMounted && this.setState({
                saving: false,
                saved: true,
                autosaveInterval: false
                });
        })
        .catch( err => {
            this._isMounted && this.setState({
                saving: false
                });
        });
    };
    
	// saveHTML = () => {   
    //     const {isItemAnalysisOwner, canNotes, locked, itemAnalysisID } = this.props;      // loading indicates saving
        
    //     //Dont save if...
    //     if (!isItemAnalysisOwner || locked || !canNotes ) return;
        
    //     this.setState({saving: true})

    //     const editor = this.refs['reactQuillRef'].getEditor()
    //     const unprivilegedEditor = this.refs['reactQuillRef'].makeUnprivilegedEditor(editor);
        
    //     // Save... IE, update
    //     this.props.itemAnalysisLobUpdate(itemAnalysisID, LOB_KEYS.NOTES, unprivilegedEditor.getHTML())
    //     .then(resp => {
    //         // Clear autosave and update saving state
    //         clearInterval(this.state.autosaveInterval);
    //         this._isMounted && this.setState({
    //             saving: false,
    //             saved: true,
    //             autosaveInterval: false
    //             });
    //     })
    //     .catch( err => {
    //         this._isMounted && this.setState({
    //             saving: false
    //             });
    //     });
	// };
}

const mapStateToProps = state => {
	return {
        itemAnalysisLOBs: state.itemAnalysisLOBs,
        canNotes: state.account.planData.additionalLimits.notes,
	};
};

export default connect( mapStateToProps, {
		getAnalysisLob,
		itemAnalysisLobUpdate
	})(Editor);
