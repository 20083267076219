import { dm_print } from 'utils/';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, Field, Form } from 'redux-form';
import _ from 'lodash';

import classNames from 'classnames';

import PageAccess from '../Layout/page.access.jsx';
import NoteToUser from 'Widgets/NoteToUser.jsx';
import Message from 'Widgets/Message.jsx';
import { verifySignup, resendVerification } from '../../actions';
import { clearUserMessages } from 'actions/app';

class Verify extends Component {
	componentDidMount() {
        this.props.clearUserMessages();
    }
    
	render() {
		// dm_print('Verify Component props: ', this.props )
		const { handleSubmit, loading } = this.props;

		const displayLoading = classNames({
			'far fa-spinner fa-spin spinner': loading
		});

		const disabled = classNames({
			disabledTextInput: loading
		});

		return (
			<PageAccess>
				<div className="access">
					<div className="access-head">
						<div className="access-logo">Dreamaker</div>
						<h1>
							Verify your account{' '}
							<NoteToUser
								show=""
								startTag="("
								endTag=")"
								note=" check your email for the code "
							/>
						</h1>
					</div>
					<div className="access-body">
						<div className="form form-access">
							<Form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
								{this.renderMessage()}
								<br />
								<div className="form-group">
									<Field
										name="verificationCode"
										type="verificationCode"
										component={renderInput}
										label="Verification code"
										autoFocus={true}
									/>
								</div>

								<button
									action="submit"
									className="btn btn-secondary"
									disabled={loading}>
									Verify <i className={displayLoading} />
								</button>
							</Form>
						</div>
						<br />
						Code not arrive?{' '}
						<a href="" onClick={this.handleResendVerification}>
							Resend code
						</a>
					</div>
				</div>
			</PageAccess>
		);
	}

	handleFormSubmit = formProps => {
		//dm_print('handleFormSubmit - ', formProps);
		const trimmedVerificationCode = _.trim(formProps.verificationCode);
		const newFormProps = {
			...formProps,
			verificationCode: trimmedVerificationCode
		};
		this.props.verifySignup(newFormProps);
	};

	handleResendVerification = event => {
		// TODO: reset validation code field value to ''
		event.preventDefault();
		this.props.resendVerification(this.props.user);
	};

	renderMessage() {
		if (this.props.message) {
			return (
				<div className="text-success">
					<strong>
						<i>{this.props.message}</i>
					</strong>
					<br />
				</div>
			);
		} else if (this.props.err) {
			return (
				<div className="text-danger">
					<strong>
						<i>{this.props.err}</i>
					</strong>
					<br />
				</div>
			);
		}
	}
}

const renderInput = field => {
	const { label, type, input, meta: { error, submitFailed } } = field;
	return (
		<div>
			<input
				{...input}
				type={type}
				className="form-control"
				placeholder={label}
				autoFocus={field.autoFocus}
			/>
			{submitFailed && error && <div className="text-danger">{error}</div>}
		</div>
	);
};

function validate(formProps) {
	const errors = {};

	formProps.verificationCode = _.trim(formProps.verificationCode);
	if (!formProps.verificationCode || '' === !formProps.verificationCode) {
		errors.verificationCode = 'Oops, verification code required.';
	}
	return errors;
}

function mapStateToProps(state) {
	return {
		loading: state.user.loading,
		err: state.user.error,
		message: state.user.message,
		user: state.user.data.cognito,
		verify: state.user.verify
	};
}

const mapDispatchToProps = { verifySignup, resendVerification, clearUserMessages };

Verify = compose(
	reduxForm({ form: 'verification', validate }),
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Verify);

export default Verify;
