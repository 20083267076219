import {
	CUNAUTH_USER,
	MARKERS_LOADING,
	MARKERS_CREATE,
	MARKERS_DELETE,
	MARKERS_FETCH,
	MARKERS_UPDATE
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: true,
	data: {}
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return INITIAL_STATE;

		case MARKERS_LOADING:
			return {...state, loading: true};

		case MARKERS_CREATE:
			const {markerID} = action.payload;
			return {
				...state,
				data: {
					...state.data,
					[markerID]: action.payload
				},
				loading: false
			};

		case MARKERS_UPDATE:
			// since markerId is created inside markers_create, we are unable to create it again in here.
			return {
				...state,
				data: {
					...state.data,
					[action.payload.markerID]: action.payload
				},
				loading: false
			};

		case MARKERS_DELETE:
			// need to create copy of the new data so we can delete it
			const newStateData = {...state.data};
			delete newStateData[action.payload];
			return {
				...state,
				data: {
					...newStateData
				},
				loading: false
			};

		case MARKERS_FETCH:
			return {...state, data: action.payload, loading: false};

	}
	return state;
}