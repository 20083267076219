import _ from 'lodash';
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { INVITE_STATUS } from 'Constants/app';

import {
	acceptOrganizationInvite,
	declineOrganizationInvite,
} from '../Account/actions/index'

import Modal from 'Widgets/Modal.jsx';
import ModalConfirm from 'Widgets/Modal.Confirm.jsx';
import { Loading } from 'Widgets/Loading.jsx';

class ModalJoinOrganization extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			displayModal: true,
			doneMessage: false,
		}
	}

	/** Renders modal for accepting organisation invite */
	render () {
		const { displayModal, doneMessage } = this.state;

		if (!displayModal) return null;

		if (doneMessage) return this.renderMessage(doneMessage);

		const { organizations } = this.props;

		let modal = null;
		Object.keys(organizations).some((organizationID) => {
			if (INVITE_STATUS.INVITED === organizations[organizationID].status) {
				modal = 
					<ModalConfirm
						onCancel={(e) => this.handleInviteDecline(organizationID) }
						onConfirm={(e) => this.handleInviteAccept(organizationID) }
						title=''
						confirmBtn="Join"
						cancelBtn="Decide later"
						confirmPhrase={`Join the Dreamaker.io ${organizationID} organization`}
						subTitle="Organization Membership"
						>
						You've been invited to join the <b><i>{organizationID}</i></b> organisation on <strong>Dreamaker.io</strong>.
						<p>Join now for unlimited collaboration with all <b><i>{organizationID}</i></b> members!</p>
						<p>
							<button className='btn btn-link' style={{padding: 0}}
								onClick={(e) => this.handleInviteDecline(organizationID, true)} >
							Permanently decline this invite.
						</button></p>
					</ModalConfirm>
				return true;
			} 
		})
		return modal;
	}

	// handles accept invite
	handleInviteAccept = (organizationID) => {
		this.organizationInviteAccept(
			organizationID,
			this.props.acceptOrganizationInvite,
			true,
			`Congratulations, you are now a member of the ${organizationID} organization!`
		)
	}

	// handles temporary and permanent decline invite
	handleInviteDecline = (organizationID, permanentDecline = false) => {
		this.organizationInviteAccept(
			organizationID,
			this.props.declineOrganizationInvite,
			false,
			'',
			permanentDecline
		)
	}

	// calls accept/decline API funtion call
	organizationInviteAccept = (organizationID, acceptanceFunction, displayModal = false, doneMessage = '', permanentDecline = false) => {
		this.setState({ loading: true });
		
		acceptanceFunction(organizationID, permanentDecline)
			.then(resp => {
				this.setState({
					loading: false,
					displayModal, // here TODO: next time
					doneMessage
				});
			})
			.catch(err => {
				this.setState({ loading: false, displayModal: false })
			})
	}

	handleCloseModal = () => {
		this.setState({ displayModal: false })
	}

	renderMessage = (message) => {
		return (
			<Modal size="modal-sm">
				<div className="modal-body" style={{ padding: '1rem 2.5rem', textAlign: 'center' }}>
					<Loading icon='far fa-check' message={message} />
				</div>
				<div className="modal-footer">
					<button
						type="button"
						data-dismiss="modal"
						className="btn btn-primary"
						onClick={this.handleCloseModal}>
						Done
					</button>
				</div>
			</Modal>
		);
	}
}

function mapStateToProps (state) {
	return {
		organizations: state.account.organizations || {},
	}
}

const mapDispatchToProps = {
	acceptOrganizationInvite,
	declineOrganizationInvite
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ModalJoinOrganization);
