import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import { clearUserMessages } from 'actions/app';

import OrganizationsInfo from './OrganizationsInfo.jsx'
import PlanInfo from './PlanInfo.jsx'
import UsageInfo from './UsageInfo.jsx'

import { Loading } from 'Widgets/Loading.jsx';

class AccountInfo extends Component {
    
    constructor(props) {
		super(props);

        this.state = {
			loading: {},
			organizationMessage: false,
        }
    }

	componentWillUnmount() {
		// will clear success message when user navigates away from profile page
		this.props.clearUserMessages();
    }    

	render () {
		// account data not yet initialized
		// if (this.props.billingDetails === null) return this.renderLoading();

		// Props variables
		// const {
		// 	organizationID,
		// 	planData: { mergeWithOrgMemberPlan, title }
		// } = this.props;

		// Calculated variables		
		// var arePlanDataLimitsMerged = (mergeWithOrgMemberPlan && null !== organizationID)

		return (
			<div className="settings">
				<div className="settings-head">
					<h1>Your Account</h1>
				</div>
				<div className="settings-body settings-narrow">
					{/** PLAN INFO */}
					<PlanInfo />
					
					{/** ORG INFO */}
					<OrganizationsInfo />
					
					{/** USAGE INFO */}
					<div className="row settings-div-row">
						<div className="col-12 settings-align-center">
							<h5>Your usage</h5>
						</div>
					</div>
					<UsageInfo />
				</div>
			</div>
		);
    }
    
	renderLoading = () => {
		return (
			<div className="settings">
				<div className="settings-head">
					<h1>Your Account</h1>
				</div>
				<div className="settings-body settings-narrow">
					<Loading
						icon="far fa-circle-notch fa-spin"
						message="Loading your information..."
					/>
				</div>
			</div>
		);
	};
}

function mapStateToProps(state) {
    // const {account} = state;
	return {
		// planData: account.planData, // used purely to detect if loading has happened
		// billingDetails: state.billingDetails,
		// organizationID: account.organizationID || null,
	};
}

const mapDispatchToProps = {
    clearUserMessages,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
