/**
 * BASED ON... 
 * Axios Request Wrapper
 * ---------------------
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *

 * // Send a POST request
    axios({
        method: 'post',
        url: '/user/12345',
        data: {
            firstName: 'Fred',
            lastName: 'Flintstone'
        }
    });

 * // GET request for remote image
    axios({
        method:'get',
          url:'http://bit.ly/2mTM3nY',
            responseType:'stream'
        })

    // // TESTS 4xx or 5xx RESPONSES
    // axios({
    //     url: 'http://httpstat.us/400', // test 401 response; disable headers if using!!
    // })
    // // TESTS - other tests
    // axios({
    //     url: `${apiConfig.folderViewFetch}${folderID}`,  // Correct URL with no session creates 401
    //     // url: `${apiConfig.userGetCurrent}jfilajlk`,  // test 403 from AWS
    // 	headers: {
    //         // authorization: _session.getIdToken().getJwtToken() // Correct auth
    //         // authorization: ''  // No token
    //         // bad token:
    //         // authorization: 'eyJraWQiOiJWZmdPU3B3VGtUWVYwYjVaVjVZaXh5WmxpSkI2ZGV3a1VwRFpsNzViYjY0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhZjUyMWIxNC1mY2FjLTRlNTgtOGMxNi05NjM5ZTcwZmRiNjQiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfN2lEcFRUUTdYIiwiY29nbml0bzp1c2VybmFtZSI6Ijk3NWM1ODdlLTE2ODgtNGUxMi04ZTY4LTg5OWZjZDdiZDFkZCIsImdpdmVuX25hbWUiOiJOYXRoYW5pZWwiLCJhdWQiOiI3MWh2ajM1ZW5wdDNsbHI3cWFtbnRya3M5NiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTIxMTM4MjA1LCJuYW1lIjoiTmF0aGFuaWVsIEhvbGRlciIsImV4cCI6MTUyMTE0MTgwNSwiaWF0IjoxNTIxMTM4MjA1LCJmYW1pbHlfbmFtZSI6IkhvbGRlciIsImVtYWlsIjoibmF0aGFuaWVsQGRyZWFtYWtlci5pbyJ9.MMErfOuBhhV4kHp9SN1JSrZZknYpb_xicDWC_NL3ky8bSTLdo2_ySl27T1Ubmw-sneqZe5UwgLQp3IDusJF95tvhASLORwHF3UwxQJ8iKo1q2CpOJjpazd0t1h3ixiuqT3FYrmy0WYQSBVLm_wX5N-3hZgVAq6OzMiRXWBt_cN6ytex02dQNjkZSQHPzF-tisVxgmdBZrxXkvLjS6fkaeJAO3w3TZGhLj9DYefoLCVEa-yOF0Jt3lBcl331bRC1awQff0eRanNJ4Gp58R3mqKFI4WW6GyMBP07a_2c5WsrTZLcqDp9rdkJ_MAEyEmcns1t8gAC9OtuAelEh5NZRjsg'
    // 	}
    // })
**/

import axios from 'axios';


let client = null;
let _isSessionValid = false;
let _dispatch = false;
let _dmAxiosErrorHandler = false;

const initialize = function( accessToken, authToken, isSessionValid, dispatch, dmAxiosErrorHandler ) {
    client = axios.create({
        headers: {
			AccessToken: accessToken,
			Authorization: authToken,
			// baseURL: constants.api.url,
		}
    });
    
    _isSessionValid = isSessionValid;
    _dispatch = dispatch;
    _dmAxiosErrorHandler = dmAxiosErrorHandler

    return request;
}

const refresh = function( accessToken, authToken ) {
    client = axios.create({
        headers: {
			AccessToken: accessToken,
			Authorization: authToken,
			// baseURL: constants.api.url,
		}
    });

    return request;
}

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options, type) {
        const onSuccess = function(response) {
            // return response.data;
            return Promise.resolve(response.data);
        };

        const onError = function(error) {
            _dispatch( _dmAxiosErrorHandler(type, error) )
            return Promise.reject(error);
        };
        
        return _isSessionValid(_dispatch)
        .then(() => {
            return client(options)
                    .then(onSuccess)
                    .catch(onError);
        })
        .catch(err => {
            return Promise.reject(err);
        });

};

const Service = {
    initialize, refresh, request
}


export default Service;