import React from "react";

const Slider = ({ className, isChecked, handleClick }) => (
  <div className={`switch-container ${className}`}>
    <label>
      <input
        checked={isChecked}
        onChange={() => handleClick()}
        className="switch"
        type="checkbox"
      />
      <div>
        <div />
      </div>
    </label>
  </div>
);

export default Slider;
