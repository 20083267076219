/**
 * NOTE:
 * Initial state: null
 * After imports fetch: Object
 * Loading handled locally
 */
import _ from 'lodash';
import {
	CUNAUTH_USER,
	ITEM_DELETE,
	ZOOM_IMPORTS_DELETE,
	ZOOM_IMPORTS_FETCH,
	ZOOM_RETAIN_DATA,
} from 'Constants/actions';

const INITIAL_STATE = null

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		
		case CUNAUTH_USER:
		case ZOOM_IMPORTS_DELETE: {
			return INITIAL_STATE;
		}
            

		case ZOOM_IMPORTS_FETCH: {
			return { ...state, ...action.payload };
		}
			
		case ITEM_DELETE: {
			// payload is key of item to delete
			return {
				..._.omit(state, action.payload)
			};
		}
			
		case ZOOM_RETAIN_DATA: {
			return {
				...state,
				oauthUser: {
					...state.oauthUser,
					retainData: true,
					dateRetainDataSet: Date.now()
				}
			};
		}
	}

	return state;
}
