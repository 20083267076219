import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const LeaveMenuBtn = ({handleOnClick}) => (
	<MenuBtn
		label={I18n.get('Delete')}
		title={I18n.get('Delete item')}
		onClick={e => {
			handleOnClick(e, 'modalConfirmAction', 'leave');
		}}
		iconClass="trash-alt text-danger"
		iconTransform="flip-h"
	/>
);

export default LeaveMenuBtn;