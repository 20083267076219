/**
 * URLS
 * Organization Accept: http://localhost:8080/su?accept=@nownow.co.za&redirect=c#invite
 * Collaborator Invite: http://localhost:8080/su#invite
 * Redirect from website: http://localhost:8080/su#paola@nownow.co.za
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { openModal } from 'actions/app';

/**
 * note on: query-string
 * query-string is currently a common.js module
 * Added below to webpack bundlers to accommodate this
 * 		plugins: ['add-module-exports']
 * 		presets: ['@babel/env', {'modules': "commonjs"} ],
 * If this fails again at some point, try commonjs - 
 * const queryString = require('query-string');
 */
import queryString from 'query-string';

import { setOnlyUs, setUnderscoreVar } from 'actions/app'
import { ROUTES } from 'Constants/app';

class RouteListener extends Component {

	constructor(props) {
        super(props);

        // Maintenance access PLUS _.js
		// if( this.getQueryVariable('onlyus') == 1181) this.props.setOnlyUs(); 

		/** SET LISTENERS */
		this.unlisten = this.props.history.listen((location, action) => {
			// console.log('RouteListener componentWillMount location: ', location)
			// console.log('RouteListener componentWillMount action: ', action)
			// console.log('RouteListener componentWillMount location.pathname + location.hash: ', location.pathname + location.hash)
			// Redirect used, clear it
			location.pathname + location.hash === this.props.redirect ? this.props.setUnderscoreVar( 'redirect', false) : null;
        });
		
		/** PICK UP QUERY STRING */
		const queryStringValues = queryString.parse(window.location.search)
		// console.log('Query String: ', queryStringValues)

		// Reset Email
		if (ROUTES.RESET === location.pathname && queryStringValues.email) this.props.setUnderscoreVar('resetEmail', queryStringValues.email);

		// LTI Variables
		if( queryStringValues.ltiResultIdentifier ) this.setLTIVariables(queryStringValues)

        // Organization accept - simply used to pass to cognito on user signup & display 
		const organizationID = queryStringValues.accept;
		if (organizationID) this.props.setUnderscoreVar('organizationAccept', organizationID);
		
		//zoomAuthorization=success
		if ('success' === queryStringValues.zoomAuthorization) {
			this.props.openModal('modalZoomImport');
			//TODO: Clear query string & redirect
		}
			

        // if there is an explicit redirect, use it, otherwise just grab the url & set if not '/'
		let redirect = queryStringValues.redirect || this.props.location.pathname + this.props.location.hash;
		if(!redirect.startsWith('/')) redirect =`/${redirect}`

		if(redirect !== '/' && !redirect.startsWith(ROUTES.SIGNUP)) {
			// console.log('redirect set: ', redirect);
			this.props.setUnderscoreVar( 'redirect', redirect);
		}
    }
    
	// componentWillMount() {
	// 	this.unlisten = this.props.history.listen((location, action) => {
	// 		// console.log('RouteListener componentWillMount location: ', location)
	// 		// console.log('RouteListener componentWillMount action: ', action)
	// 		// console.log('RouteListener componentWillMount location.pathname + location.hash: ', location.pathname + location.hash)
	// 		// Redirect used, clear it
	// 		location.pathname + location.hash === this.props.redirect ? this.props.setUnderscoreVar( 'redirect', false) : null;
    //     });
    // }
    
	componentWillUnmount() {
		this.unlisten();
	}

	render() {
		return <div>{this.props.children}</div>;
    }
	
	/**
	 * Sets LTI variables. 
	 * ltiResultIdentifier
	 * ltiConsumerID - moodle.nownow.co.za 
	 * ltiConsumerName
	 * ltiUserID
	 * ltiUserEmail
	 * ltiCourseID
	 * ltiCourseTitle
	 * ltiAssignmentID
	 * ltiAssignmentTitle
	 */
	setLTIVariables = ( values ) => {
		// TODO: Differentiate a student from a teach
		const ltiMap = {
			ltiAssignmentID: values.ltiAssignmentID,
			ltiAssignmentTitle: values.ltiAssignmentTitle,
			ltiConsumerID: values.ltiConsumerID,
			ltiCourseID: values.ltiCourseID,
			ltiCourseTitle: values.ltiCourseTitle,
			ltiConsumerName: values.ltiConsumerName,
			ltiConsumerProduct: values.ltiConsumerProduct,
			ltiResultIdentifier: values.ltiResultIdentifier,
			ltiRoles: JSON.parse(values.ltiRoles),
			ltiUserFullname: values.ltiUserFullname,
			ltiUserEmail: values.ltiUserEmail,
			ltiUserID: values.ltiUserID,
		}
		this.props.setUnderscoreVar( 'lti', ltiMap);
	}
}

function mapStateToProps(state) {
	return {
		redirect: state._.redirect || '',
		user: state.user,
	};
}

RouteListener = compose(withRouter, connect(mapStateToProps, {openModal,setOnlyUs,setUnderscoreVar}))(RouteListener);
export default RouteListener;