import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import { UNLIMITED } from 'Constants/app';

import { leaveOrganization } from '../actions/index'
import { clearUserMessages } from 'actions/app';
import { formatStorage } from 'utils';

import { LoadingLips } from 'Widgets/LoadingLips.jsx';

class UsageInfo extends Component {
    
	render () {
		
		// account.planData not yet initialized (all account not yet initialized)
		if (_.isEmpty(this.props.planData)) return <LoadingLips />

		// Props variables
		const { organizationID, mergeWithOrgMemberPlan, planData, usageData } = this.props;
		const { maxCollaborators, maxStorage, maxDashboards, maxTranscribe } = planData;
		const { storageCount, collaboratorCount, dashboardCount, transcriptionSeconds } = usageData;

		// Calculated variables
		/**  
		 * isplanDataMerged: true when plan limits are merged with organization limits - IE user if on an upgraded plan & a member of an org
		 */
		const isplanDataMerged = (mergeWithOrgMemberPlan && null !== organizationID )				
		
		var collaborationTitle = <p>Collaborators</p>;
		if (null !== organizationID) {
			collaborationTitle = (
				<p>
					External Collaborators<br />
					<i style={{color: 'lightgray'}}>Unlimited organization collaboration</i>
				</p>
			);
		}

		return (
			<div>					
				{/* <div className="row settings-div-row">
					<div className="col-12 settings-align-center">
						<h5>Your usage</h5>
					</div>
				</div> */}
				{this.renderUsageLimit(<p>Storage</p>, formatStorage(storageCount), formatStorage(maxStorage), this.textColor(storageCount, maxStorage))}
				{this.renderUsageLimit(collaborationTitle, collaboratorCount, maxCollaborators, this.textColor(collaboratorCount, maxCollaborators))}
				{this.renderUsageLimit(<p>Dashboards</p>, dashboardCount, maxDashboards, this.textColor(dashboardCount, maxDashboards))}
				{/* {this.renderUsageLimit(<p>Transcription minutes</p>, Math.round(transcriptionSeconds / 60), maxTranscribe / 60, this.textColor(transcriptionSeconds, maxTranscribe))} */}
			</div>
		)
    }
    
	renderUsageLimit(title, usage, limit, color) {
		return (
			<div className="row">
				<div className="col-12 settings-div-row" />
				<div className="col-6">
					{title}
				</div>
				<div className="col-6 settings-align-right">
					<p><span className={color}>{usage}</span> / { UNLIMITED.value === limit ? UNLIMITED.word : limit}</p>
				</div>
			</div>
		);
    }
    
	/** 
	 * returns different text color depending on usage. If you're under half, color will be green.
	 * If you're over half, color will be yellow, if you're at max or higher, it will be red.
	 * */
	textColor = (currentUsage, maxUsage) => {
		if (currentUsage / maxUsage >= 1) return 'color-red';
		if (currentUsage / maxUsage >= 0.5) return 'color-orange';
		return 'color-green';
    };
}

function mapStateToProps(state) {
	const { account } = state;
	return {
		// isOrganization: account.isOrganization || false,
		organizationID: account.organizationID || null,
		mergeWithOrgMemberPlan: account.planData.mergeWithOrgMemberPlan || false,
		planData: account.planData,
		usageData: account.usageData
	};
}

const mapDispatchToProps = {
    clearUserMessages,
    leaveOrganization
};
export default connect(mapStateToProps, mapDispatchToProps)(UsageInfo);
