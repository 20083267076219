import _ from 'lodash'
import classNames from 'classnames';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';

import {injectStripe, CardElement} from 'react-stripe-elements';

import {StripeCountryCodes} from './StripeCountryCodes';

import Message from 'Widgets/Message.jsx';
import AddressInputs from './AddressInputs.jsx';
import {CouponCodeInput} from './CouponCodeInput.jsx';

const INITIAL_MESSAGES = { 
	message: '', 
	error: false, 
}
class BillingCardForm extends Component {
	constructor(props) {
		super(props);
        
		this.state = {
			name: '',
			loading: false,
			country: '',
			line1: '',
			line2: '',
			city: '',
			state: '',
			inputHasError: {},
			showCoupon: false,
			couponCode: '',
			couponMessage: null,
			messages: INITIAL_MESSAGES
		};
    }

	render() {
		// this.props.stripe automagically passed in via injectStrip
		const {footer, showCouponField, modalTitle, submitBtnTitle} = this.props;
		const {loading} = this.state;
		
		const modalBodyClasses = classNames({
			'modal-body': true,
			'modal-body-changePlan': true,
			disabled: loading
		})

		return (
			<form className="checkout-form" onSubmit={this.handleSubmit}>
				<div className={modalBodyClasses} style={{padding: '0rem 2.5rem'}}>	
					{this.props.children}
					<div className="group card-title">
						<h5>{modalTitle}</h5>
					</div>
					{this.renderMessages()}
					<AddressInputs
						handleChange={this.handleInputChange}
						state={this.state}
						countryCodes={StripeCountryCodes}
					/>
					<div className="card-details">
						<CardElement
							/* style is passed down to the CardElement, need 22px on both of these so card element disappears until it has loaded */
							style={{base: {fontSize: '16px'}}}
						/>
					</div>
					{showCouponField ? this.renderCouponField() : null}
				</div>
				<div className="modal-footer" style={{'justifyContent': 'space-between'}}>
					<button
						disabled={loading}
						type="reset"
						className="btn btn-danger"
						data-dismiss="modal"
						onClick={this.props.cancel}>
						Cancel
					</button>
					<button
						disabled={loading}
						type="submit"
						className="btn btn-primary">
						{submitBtnTitle + ' '}
						{loading && <i className="far fa-spin fa-spinner" />}
					</button>
				</div>
				{footer 
					? <small style={{marginTop: '15px', textAlign: 'center'}}>{footer}</small>
					: null 
				}
			</form>
		);
	}

	// Renders the coupon component
	renderCouponField = () => {
		const {couponCode, couponMessage, showCoupon} = this.state;
		return (
			<CouponCodeInput
				couponCode={couponCode}
				couponMessage={couponMessage}
				showCoupon={showCoupon}
				handleShow={() => this.setState({showCoupon: true})}
				handleChange={this.handleInputChange}
			/>
		)
	}

	// Handles input
	handleInputChange = e => {
		e.preventDefault();
		this.setState({messages: INITIAL_MESSAGES})
		const {name, value} = e.target;
		this.setState({
			[name]: value,
			// sets the input error of an item to false if it's been edited
			inputHasError: {...this.state.inputHasError, [name]: false}
		});
	};

	// Checks submitted payment method data for errors
	handleErrorCheck = () => {
		const validatedPaymentData = {};
		const emptyFields = {};
		// goes through the fields we need to check
		[
			'name',
			'country',
			'line1',
			'line2',
			'city',
			'state'
		].forEach(item => {
			const trimmed = _.trim(this.state[item]);

			if (_.isEmpty(trimmed)) {
				// if the item is line2, we don't care about it being empty, but we do need it
				if (item === 'line2') return;
				emptyFields[item] = true;
			}
			// sets validatedPaymentData to the trimmed item
			validatedPaymentData[item] = trimmed;
		});
		// sets the state of input fields to the trimmed values, then sets the inputHasError to the empty fields.
		this.setState({...validatedPaymentData, inputHasError: emptyFields});
		
		return {
			payment_method_data: {
					billing_details: {
					name: validatedPaymentData.name,
					address: {
						country: validatedPaymentData.country,
						line1: validatedPaymentData.line1,
						line2: validatedPaymentData.line2,
						city: validatedPaymentData.city,
						state: validatedPaymentData.state,
					}
				}
			},
			emptyFields
		}
	};

	/** Handles submission of form
	 * STRIPE TEST CARDS - https://stripe.com/docs/testing#international-cards
	 */
	handleSubmit = e => {
		e.preventDefault();
		this.setState({messages: INITIAL_MESSAGES})
		const {payment_method_data, emptyFields} = this.handleErrorCheck();

		// if emptyFields is not empty, we need to return and not allow submission.
		if (!_.isEmpty(emptyFields)) return;

		this.setState({loading: true});
			const coupon = _.trim(this.state.couponCode) || false;
		
		this.props.handleCardSetup(this.props.stripe.handleCardSetup, payment_method_data, coupon)
		.then(resp => {})
		.catch(err => {
			this.setState({
				messages: {error: true, message: err.message},
				loading: false
			});
		});
	};

	renderMessages = () => {
        const {message, error} = this.state.messages;
		return <Message message={message} textClass={error ? 'danger' : 'success'} dismissable={false} style={{fontSize: '.9rem'}}/>;
	};
}

function mapStateToProps(state) {
	return {
		//modalOpen: state.modalOpen,
	};
}

BillingCardForm = compose(injectStripe, connect(mapStateToProps, {}))(
	BillingCardForm
);

export default BillingCardForm;
