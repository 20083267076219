import React from 'react';

const MenuButton = ({
	disabled,
	iconClass,
	iconTransform,
	label,
	onClick,
	title,
}) => (
	<button
		data-toggle="tooltip"
		title={title}
		disabled={disabled || false}
		onClick={onClick}
		className='dropdown-item'>
		<i className={'far fa-fw fa-' + iconClass} data-fa-transform={iconTransform} />
		{' ' + (label || title)}
	</button>
);

export default MenuButton;
