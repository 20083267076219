import React, {Component} from 'react';
import { I18n } from '@aws-amplify/core';

import { ROLES } from 'Constants/app.js'

import Checkbox from 'Widgets/Inputs/Checkbox.jsx'

class BottombarTitle extends Component {
	constructor(props) {
		super(props);
	}

	render() {
        const {
            locked, 
            title
        } = this.props;
                
		return (
            <div className="row">
                <div className="col-8 bottombar-title left">
                    {this.props.children
                    ? this.props.children
                    : title
                    }
                </div>
                <div className="col-4 bottombar-title right">
                    { locked
                    ? <i className="fas fa-lock-alt text-danger" title='Project is locked'/>
                    : this.renderActionButtons()
                    }
                    </div>
            </div>
		);
    }

    /**
     * Calls render*() to diplays buttons relevant to activeTab
     */
    renderActionButtons = () => {
        const {
            activeTab, 
            isItemAnalysisOwner, 
			isShare,
			loading = false,
			role,
            selectedDashboard
		} = this.props;
		
		if (loading) return;

        switch(activeTab) {
            case 'dashboard': {
                if( undefined !== selectedDashboard) 
                    return this.renderPauseOnTag()
                break;
			}

			case 'fileAssets': {
				if (isItemAnalysisOwner || ROLES.AUTOTEACHER === role)
                	return this.renderManageButton(I18n.get('Manage Attachments'))
			}

			case 'transcribe': {
				// TODO: isShare is Tmp addition to prevent transcribing of shared items
				if( isItemAnalysisOwner && !isShare )
					return this.renderManageButton(I18n.get('Manage Transcriptions'))
				break;
			}

            default: 
                return;
        }
    }

    /**
     * Renders Manage button with title
     * Only called if transcriptions or fileAssets are activeTab
     */
    renderManageButton = (title) => {
        return (
            <span style={{ cursor: 'pointer',verticalAlign: '-.3em'}} onClick={this.props.handleOnManageBtnClick}>
                {title} <i className='far fa-cog' />
            </span>
        )
    }

    /**
     * Renders Pause on Tag checkbox.
     * Only called is dashboards is activeTab
     */
    renderPauseOnTag = () => {
        const { pauseOnTag } = this.props;
    
        return (
			<span className="checkbox"
				onClick={this.props.handlePauseOnTag} >
				{I18n.get('Pause on tag')}
				<Checkbox
					checked={pauseOnTag}
					className={{checkbox: true}}
					disabled={false}
					name='DbPauseOnTag'
					onClick={this.props.handlePauseOnTag}
					ref='DbPauseOnTag'
					data-toggle="tooltip"
					data-placement="top"
					title={I18n.get('Pause media when tagging')} 
				/>
			</span>
        )
    }
}

export default BottombarTitle;
