// import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { PLANID, ROUTES } from 'Constants/app';

import { formatLocalDate } from 'utils';

import { LoadingLips } from 'Widgets/LoadingLips.jsx';


class PlanInfo extends Component {

	render () {
		// billingDetails not yet initialized, so no props initialized
		if (this.props.billingDetails === null) return <LoadingLips />;

		const { isOrganization, title } = this.props

		return (
			<div>
				<div className="row">
					<div className="col-12">
						<h5>You're on the {title} Plan</h5>
					</div>
				</div>
				{isOrganization ? null : this.renderPlanDetails()}
			</div>
		)
	}

	renderPlanDetails = () => {

		// Props Variables
		const { planID, paymentPeriod, title, billingDetails: {upcomingInvoice} } = this.props

		// Free Plan - only display upgrade link
		if (PLANID.FREE[planID])
			return (
				<div className="row" style={{ marginBottom: '.8rem' }}>
					<div className="col-12 settings-align-right">
						<Link to={ROUTES.UPGRADE}>
							<i>Upgrade your plan</i>
						</Link>
					</div>
				</div>
			);

		// Trial period plan - display trial message
		// Assumes upcomingInvoice is present. IE, you can't put someone on trial if they dont have stripe
		if (PLANID.TRIAL === planID)
			return (
				<div className="row" style={{ marginBottom: '1.5rem' }}>
					<div className="col-12">
						<p>
							Good news! You're on a <b>14-day free trial</b> of our{' '}
							<b>Professional Plan</b>. The trial includes unlimited access to all {' '}
							<Link to={ROUTES.UPGRADE}>Professional Plan</Link> features.
						</p>
						<p>
							<i>
								Ready to upgrade? Awesome!{' '}
								<Link to={ROUTES.UPGRADE}>Upgrade here</Link>.
							</i>
						</p>
					</div>
					<div className="col-6">
						<p>Trial end date</p>
					</div>
					<div className="col-6 settings-align-right">
						<p>{formatLocalDate(upcomingInvoice.date * 1000, true)}</p>
					</div>
				</div>
			);

		// Any other plan with an upcomingInvoice
		if (upcomingInvoice)
			return (
				<div className="row" style={{ marginBottom: '1.5rem' }}>
					<div className="col-6">
						<p>{title} Plan Payment Period</p>
					</div>
					<div className="col-6 settings-align-right">
						<p>{paymentPeriod}</p>
					</div>
					<div className="col-6">
						<p>Next Payment Date</p>
					</div>
					<div className="col-6 settings-align-right">
						<p>
							{upcomingInvoice.cancel_at_period_end ? (
								<Link to={ROUTES.BILLING}>
									<i>Cancellation upcoming</i>
								</Link>
							) : (
									formatLocalDate(upcomingInvoice.date * 1000, true)
								)}
						</p>
					</div>
					<div className="col-12 settings-align-right">
						{upcomingInvoice.cancel_at_period_end ? (
							<Link to={ROUTES.UPGRADE}>
								{/* Change to Reactivate plan when reactivate applied (#DMIO-282)  */}
								<i>Change Plan</i>
							</Link>
						) : (
								<Link to={ROUTES.UPGRADE}>
									<i>Change Plan</i>
								</Link>
							)}
					</div>
				</div>
			);

		// Any other plan with no upcomingInvoice
		return (
			<div className="row" style={{ marginBottom: '.8rem' }}>
				<div className="col-12 settings-align-right">
					<Link to={ROUTES.UPGRADE}>
						<i>Upgrade your plan</i>
					</Link>
				</div>
			</div>
		);
	}
}

function mapStateToProps (state) {
	const { account, billingDetails } = state;
	return {
		billingDetails, // used purely for loading test,
		isOrganization: account.isOrganization || false,
		paymentPeriod: account.paymentPeriod,
		planID: account.planData.planID,
		title: account.planData.title,
	};
}


export default connect(mapStateToProps, {})(PlanInfo);
