import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { request } from 'actions/'
import { messageToast } from 'actions/app'

import { apiConfig } from 'config';

import {
    MARKERS_CREATE,
    MARKERS_DELETE,
    MARKERS_ERROR,
    MARKERS_UPDATE,
    MESSAGE_ADD,
	MARKERS_LOADING,
    ERROR_ADD,
} from 'Constants/actions';


// MARKERS
/**
 * Creates a new marker and adds to state. If doSave is true, saves to database
 * @param {Object} newMarker Object of newMakrer, icluding uuid
 * @param {Boolean} doSave True if marker should be saved to Database
 */
export function createNewMarker(newMarker, doSave) {
	return function(dispatch) {
		// If !doSave, simply add to redux state and return ID
		if (!doSave) {
			dispatch({
				type: MARKERS_CREATE,
				payload: newMarker
			});
			return;
		}

		// A title & an option is required
		if ('' === newMarker.name || _.isEmpty(newMarker.options)) {
			dispatch({ type: ERROR_ADD, payload: 'Title & Options are required.' });
			return;
		}

		// otherwise, we want to use that newMarker and save it on our database.
        dispatch({ type: MESSAGE_ADD, payload: 'Saving...' });
        
        // Delete doSave as its no longer needed
        delete newMarker['doSave'];
        
        const requestObj = {
            method: 'post',
            url: `${apiConfig.markerCreate}`,
            data: newMarker,
        };

        request( requestObj, MARKERS_ERROR )
        .then( resp => {            
            dispatch(messageToast(MESSAGE_ADD, 'Saved', 1500));
            dispatch({
                type: MARKERS_CREATE,
                payload: newMarker
            });
        })
	};
}

/**
 * Adds an option to a makrer and adds to state. If doSave is true, saves to database
 * @param {String} markerID of marker option is being added to
 * @param {String} newOption to be added. Assumes newOption is not ""
 * @param {Boolean} doSave True if marker should be saved to Database
 */
export function createMarkerOption(markerID, newOption, doSave = true) {
	return function(dispatch, getState) {
		const currentMarkerState = getState().userMarkers;
		const updatedMarker = {
			...currentMarkerState.data[markerID],
			options: {
				...currentMarkerState.data[markerID].options,
				[uuidV4()]: newOption
			}
		};
		// markers_create works by just passing in the newly created marker object, so we can reuse it here in the marker option create
		// we're going to create this in our redux instantly that way the user gets feedback immediately.
		dispatch({
			type: MARKERS_CREATE,
			payload: updatedMarker
		});

		// Save to database if doSave
		if (doSave) {
            const requestObj = {
                method: 'post',
                url: `${apiConfig.markerUpdate}`,
                data: updatedMarker,
            };

            request( requestObj, MARKERS_ERROR )
            .then( resp => {
                dispatch(messageToast(MESSAGE_ADD, 'Saved', 1500));
            })
		}
	};
}

/**
 * Updates a marker in redux state and if doSave is true, saves to database
 * @param {Object} updatedMarker 
 * @param {Boolean} doSave True if marker should be saved to Database
 */
export function updateMarker(updatedMarker, doSave = true) {
	return function(dispatch, getState) {
		dispatch({
			type: MARKERS_UPDATE,
			payload: updatedMarker
		});

    // Only save to Dynamo if doSave is true
    // TODO: Is failure handled by error system?
		if (doSave) {
            dispatch({ type: MESSAGE_ADD, payload: 'Saving...'});

            const requestObj = {
                method: 'post',
                url: `${apiConfig.markerUpdate}`,
                data: updatedMarker,
            };
    
            request( requestObj, MARKERS_ERROR )
            .then( resp => {
                dispatch(messageToast(MESSAGE_ADD, 'Saved', 1500));
            })
		}
	};
}

// TODO: finish this deleteMarker
export function deleteMarker(markerID) {
	return function(dispatch, getState) {
		// the deleteMarker should get a loading. Otherwise if you delete it immediately, and it comes back
		// it will be brought to the bottom of the list, and will create a strange visual experience.
		dispatch({ type: MARKERS_LOADING, payload: true });
		return;
		dispatch(_isSessionValid()).then(response => {
			const requestObj = {
				// TODO: method: delete, url: markerDelete added
				// method: 'post',
				// url: `${apiConfig.markerDelete}`,
				data: updatedMarker,
			};
			axios(requestObj)
				.then(function(response) {
					dispatch({
						type: MARKERS_DELETE,
						payload: markerID
					});
					dispatch(messageToast(MESSAGE_ADD, 'Deleted!', 1500));
				})
				.catch(function(err) {
					// Also sets ITEM_LOADING to false
					dispatch(_dmAxiosErrorHandler(MARKERS_ERROR, err));
					return;
				});
		});
	};
}
