import {
	CUNAUTH_USER,
	COLLABORATOR_LOADING,
	// COLLABORATOR_ERROR
	// COLLABORATOR_MESSAGE,
	// COLLABORATOR_SEARCH,
	// COLLABORATOR_SEARCH_CLEAR,
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: false,
	data: {}
	// error: null,
	// message: null,
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return INITIAL_STATE;
            
        case COLLABORATOR_LOADING:
            // Payload: null or false is no longer loading
            return {
                ...state, 
                loading: action.payload===undefined ? true : action.payload, 
                message: null, 
                error: null
            };
            
        // case COLLABORATOR_ERROR:
        //     return {...state, error: action.payload, loading: false, message: null};

        // case COLLABORATOR_MESSAGE:
        //     return {...state, message: action.payload, loading: false, error: null};
            
        // case COLLABORATOR_SEARCH:
        // 	return {...state, data: action.payload, loading: false, message: null, error: null};
            
		// case COLLABORATOR_SEARCH_CLEAR:
        //     return INITIAL_STATE;
            
		default:
			return state;
	}
}
