import React from 'react';

import classNames from 'classnames';

export const CouponCodeInput = ({
	couponCode,
	couponMessage,
	handleShow,
	handleChange,
	showCoupon
}) => {

	// disabled the field if either couponLoading is true, or if there is a couponMessage.type of success
	// const disabledField = () => {
	// 	if (couponMessage) return couponMessage.type === "success";
	// 	return false
	// }

	// checks the loading message. IF there is a message, will display it along with the styled type of message
	// i.e msg-success or msg-error
	const showMessage = () => {
		return (
			couponMessage && (
				<div className={`msg msg-${couponMessage.type}`}>{couponMessage.message}</div>
			)
		);
	};

	// if showCoupon is false, we don't want to show them the coupon fields yet.
	if (!showCoupon) {
		return (
			<p className="show-coupon-toggle" onClick={handleShow}>
				Do you have a coupon?
			</p>
		);
	}
	return (
		<div className="show-coupon-div">
			{showMessage()}
			<input
				// disabled={disabledField()}
				placeholder="Coupon Code"
				name="couponCode"
				value={couponCode}
				onChange={handleChange}
				autoFocus
			/>
			<p className="show-coupon-toggle" >
				Coupon will be applied on payment confirmation
			</p>
		</div>
	);
};
