import React from 'react';

const mainDivStyle = (divStyle) => ({
	textAlign: 'center',
	...divStyle
});

const faStyle = icon => ({
	// fontSize: '4rem',
	// if the icon is the fa-time, which is an 'x' for error, we want it to be red.
	color: icon === 'far fa-times' ? '#E64759' : '#1BC98E',
	margin: '1rem 0 2rem'
});

const h4Style = {};

export const Loading = ({message, icon='far fa-circle-notch fa-spin', size, divStyle}) => {
    let classes = icon;
    switch (size) {
      case "sm":
        classes = icon + " fa-1x";
        break;
      case "md": 
        classes = icon + " fa-3x";
        break;
      default:
        classes = icon + " fa-4x";
        break;
    }

    return (
        <div style={mainDivStyle(divStyle)}>
            <i className={classes} style={faStyle(icon)} />
            { message ? <h5 style={h4Style}>{message}</h5> : ''}
        </div>
    );
};
