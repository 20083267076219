import React from "react";

const RangeSlider = ({ className="", label, min, max, step="any", value, handleChange }) => (
	<div className={`range-slider ${className}`}>
		<label>{label}</label>
		<input
			type="range"
			min={min}
			max={max}
			step={step}
			value={value}
			onChange={handleChange}
		/>
	</div>
);

export default RangeSlider;