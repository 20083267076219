import _ from 'lodash';
import classNames from 'classnames'

import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import { TransitionGroup } from 'react-transition-group';
import { FadeUpTransition } from 'Widgets/FadeUpTransition.jsx';

import MarkersSelectsDefaults from './Markers.Selects.Defaults.jsx';

class MarkerSelects extends Component {
	renderSelects = data => {
		const selectables =  Object.keys(data).map(id => {
      if ( data[id].default ) return null;
			const listClass = classNames({
        "list-group-item": true,
        option: true,
        selected: id === this.props.selectedMarker,
        unsaved: data[id].doSave===false
      })
			return (
        <FadeUpTransition
          key={id}
          classNames="list-selects">
          <li ref={data[id].name} className={listClass} onClick={() => this.props.handleClick(id)}>
            <div>
              { data[id].doSave===false && <i className="far text-danger">~ </i> }
              { (data[id].name) ? data[id].name : ` ${I18n.get('Unsaved marker')}`}
            </div>
            <span>
              { data[id].default && <i className="fas fa-star" /> }
            </span>
          </li>
        </FadeUpTransition>
			);
    })
    // will filter out any item that is falsy, i.e. undefined / null
    .filter(item => item )
		.sort((a, b) => {
		// 	// a.props.children.ref is the title of the item. So we can use that to sort.
			const markerA = a.props.children.ref.toUpperCase();
			const markerB = b.props.children.ref.toUpperCase();
			if (markerA < markerB) return -1;
			if (markerA > markerB) return 1;
			return 0;
    });
		if (selectables.length <= 0){
			return (
        <FadeUpTransition classNames="list-defaults">
          <p className="no-defaults-selected">{I18n.get('No markers')}</p>
        </FadeUpTransition>
      )
    }
    return selectables
	};
	render() {
    if ( this.props.loading ) return null;
		return (
      <div className="dbedit-sidebar-dashboards">
        <h6 className="cursor-default list-group-item" data-toggle="tooltip" title="Default markers are automatically added to all new analysis projects.">
           {I18n.get('Default Markers')}
          {/**<i className="fa fa-question" title="Default markers are automatically added to all new analysis projects."/>**/}
        </h6>
        <MarkersSelectsDefaults
          handleClick={this.props.handleClick}
          selectedMarker={this.props.selectedMarker}
        />
        <h6
          id="addMarker"
          className="list-group-item"
          onClick={() => this.props.handleCreateMarker(false)}>
          {I18n.get('Create new marker')}
          <i className="far fa-plus-circle pull-right" />
        </h6>
        <ul className="list-group list-group-selects">
          <TransitionGroup>
            {this.renderSelects(this.props.markers)}
          </TransitionGroup>
        </ul>
      </div>
    )
	}
}

function mapStateToProps(state) {
	return {
    markers: state.userMarkers.data,
    loading: state.userMarkers.loading
  };
}

export default connect(mapStateToProps)(MarkerSelects);