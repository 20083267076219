import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

class MarkerOptions extends Component {
	handleInputUpdate = (e, key) => {
		const {markers, handleUpdateMarker, selectedMarker} = this.props;
		// if the targets value is empty. we want to set it back to it's original value
		// and make sure to not update the field
		if (_.trim(e.target.value) === '') {
			e.target.value = markers.data[selectedMarker].options[key];
			return;
		}
		// going to send it to be updated.
		handleUpdateMarker('option', e.target.value, key);
	};

	handleKeyDown = (e, key) => {
		if (e.keyCode === 13) {
			this.handleInputUpdate(e, key);
			this.refs.newOption.focus();
		}
	};

	handleNewOptionSubmit = e => {
		e.preventDefault();
		this.props.handleCreateMarkerOption(this.refs.newOption.value);
		this.refs.newOption.value = '';
	};

	renderOptions = (data, selectedMarker) => {
		const markerOptions = data[selectedMarker].options;
		return Object.keys(markerOptions).map((key, idx) => {
			return (
				<li key={key} className="list-group-item">
					<input
						onKeyDown={e => this.handleKeyDown(e, key)}
						onBlur={e => this.handleInputUpdate(e, key)}
						defaultValue={markerOptions[key]}
						maxLength="18"
						tabIndex={idx + 1}
					/>
					<button
						className="btn btn-outline-secondary"
						onClick={e => this.props.handleUpdateMarker('delete', null, key)}>
						<i className="far fa-times" />
					</button>
				</li>
			);
		});
	};
	render() {
		const {markers, selectedMarker} = this.props;
		if (selectedMarker === null) return <div>Select a Marker</div>;
		return (
			<div className="markerEdit-options">
				<h6 id="h6-small">Options</h6>
				<ul className="list-group">
					{this.renderOptions(markers.data, selectedMarker)}
					<li className="list-group-item ">
						<form onSubmit={this.handleNewOptionSubmit} id="form_addOption" onClick={ ()=>{this.props.dismissMessage()}}>
							<input
								id="addOptionInput"
								ref="newOption"
								onBlur={this.handleNewOptionSubmit}
								placeholder={I18n.get('Add an Option')}
								defaultValue=""
								maxLength="18"
								tabIndex="50"
							/>
							{'   '}
						</form>
					</li>
				</ul>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		markers: state.userMarkers
	};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarkerOptions);
