import { dm_print } from './utils/'

export let awsConfig = {}

switch( process.env.DMIO_CONF ) {
    case 'paola':
        awsConfig = require( './config/config.devpaola' )
        break
    case 'nat':
        awsConfig = require( './config/config.devnat' )
        break        
    case 'staging':
        awsConfig = require( './config/config.staging' )
        break
    case 'live':
        awsConfig = require( './config/config.live' )
        break
}

export const DMIO_RELEASE = process.env.DMIO_RELEASE
export const DMIO_STORE_KEY = `dmio.${awsConfig.ClientId}`
export const API_URL    = awsConfig.ApiHost
export const PROVIDER   = `cognito-idp.${awsConfig.Region}.amazonaws.com/${awsConfig.UserPoolId}`
export const WEBAPP_URL = awsConfig.WebappUrl
export const BANDWIDTH_TEST = awsConfig.BandwidthTest

// FIle uploading URLS
export const FILEIN_BCT    = awsConfig.FileinBct

// Transcoding URLS
export const TRANSIN_BCT    = awsConfig.TransinBct
export const TRANSOUT_BCT   = awsConfig.TransoutBct
export const TRANSOUT_URL   = awsConfig.TransoutUrl
export const TRANSOUT_SURL  = awsConfig.TransoutSUrl

// Profile URLS
export const LOOKIN_BCT     = awsConfig.LookinBct
export const LOOKOUT_BCT    = awsConfig.LookoutBct
export const LOOKOUT_URL    = awsConfig.LookoutUrl

// LTI URLS
export const MOODLE_TOOL_URL = awsConfig.MoodleToolUrl

// 3RD Party
export const INTERCOM_APPID = process.env.INTERCOM_ID
// export const APPCUES_TOUR = {
//     'l': '-Kqj8Hj_XS1yLMPouJ4O',    // 17.08.03 Landing Tour
//     'a': '-KqiZiuiFtBXoFHYYz8c',    // 17.08.03 Analysis Tour
//     'd': '-KtwiCtBH_yqF0fCoQjV',     // 17.09.13 - Dashboard Editor Tour
//     'm': '-L5MKBMNJ9uvAQkAGBwv'     // 18.02.14 - Markers Editor    
// }

// Stripe Payment API
export const STRIPEAPIKEY = awsConfig.StripeAPIKey

//  API
/**
 * NOTE: Naming Conventions
 * Use 'get' for returning a single item
 * Use 'fetch' for returning an object of multiple items
 */
export const apiConfig = {

    // app
    appFetchPlans       : `${API_URL}plan`,                          // gets all live Dmio plans
    appGetVersion       : `${API_URL}version`,
    appFetchTemplates   : `${API_URL}template`,                     // PATCH. Fetches all live templates.                                                             // GET. Gets all live templates with makrers, dashboards and dashboardTags 

    // anaylsis - event tags
    eventTagsFetch  : `${API_URL}folderitem/eventtag?itemAnalysisID=`,
    eventTagUpdate  : `${API_URL}folderitem/eventtag/update`,
    eventTagCreate  : `${API_URL}folderitem/eventtag/create`,
    eventTagDelete  : `${API_URL}folderitem/eventtag`,

    // analysis - replies
    eventTagReplyCreate : `${API_URL}folderitem/eventtag/reply/create`,
    eventTagReplyUpdate : `${API_URL}folderitem/eventtag/reply/update`,
    eventTagReplyDelete : `${API_URL}folderitem/eventtag/reply?eventTagReplyID=`,

    // analysis - itemAnalysis
    analysisGet     	: `${API_URL}folderitem/itemanalysis?itemAnalysisID=`, //&lobsList=transcription_en,transcription_fr,notes
    analysisCreate  	: `${API_URL}folderitem/itemanalysis/create`,
    analysisUpdate  	: `${API_URL}folderitem/itemanalysis/update`,
    analysisDelete  	: `${API_URL}folderitem/itemanalysis/delete`,
	analysisExport  	: `${API_URL}folderitem/itemanalysis/export?itemAnalysisID=`,
	analysisVisibility	: `${API_URL}folderitem/itemanalysis/visibility`, // POST { itemAnalysisID: 'abc-xde', mode: 'PUBLIC' || 'PRIVATE'}

    // charts
    //chartFetch
    chartCreate        : `${API_URL}folderitem/itemanalysis/chart/create`,
    chartUpdate        : `${API_URL}folderitem/itemanalysis/chart/update`, // Handles updating chart metadata as well as child datasets (incl. delete)
    chartDatasetCreate : `${API_URL}folderitem/itemanalysis/chart/dataset/create`,

    // dashboard tags + markers
    dashboardFetch  : `${API_URL}folderitem/dashboard`,
    dashboardCreate : `${API_URL}folderitem/dashboard/create`,
    dashboardDelete : `${API_URL}folderitem/dashboard`,
    allTagsFetch    : `${API_URL}folderitem/dashboard/tag`,
    tagDelete       : `${API_URL}folderitem/dashboard/tag`,
    tagCreate       : `${API_URL}folderitem/dashboard/tag/create`,
    tagUpdate       : `${API_URL}folderitem/dashboard/tag/update`,
    dashboardUpdate : `${API_URL}folderitem/dashboard/update`,
    markerFetch     : `${API_URL}folderitem/eventtag/marker`,
    markerUpdate    : `${API_URL}folderitem/eventtag/marker/update`,
    markerCreate    : `${API_URL}folderitem/eventtag/marker/create`,
	// markerDelete    : `${API_URL}folderitem/eventtag/marker/delete`,
	
	// FILE ASSETS
	fileAssetCreate	: `${API_URL}folderitem/itemanalysis/file/create`,				// POST {itemAnalysisID, title, fileType, locked}
	fileAssetGetLink: `${API_URL}folderitem/itemanalysis/file/download?fileID=`,	// GET 
	fileAssetUpdate	: `${API_URL}folderitem/itemanalysis/file/update`,				// POST {fileID, title, locked, confidential, deleted}

    // folders
    folderViewFetch     : `${API_URL}folderview/withitems?folderID=`,     // Fetches multiple

    // items
    itemCreate      : `${API_URL}folderitem/create`,
    itemDelete      : `${API_URL}folderitem?itemID=`,
    itemGet         : `${API_URL}folderitem?itemID=`,                 // Gets a single items
    itemUpdate      : `${API_URL}folderitem/update`,
    itemSend        : `${API_URL}folderitem/send`,                      // POST
    itemSignUrl     : `${API_URL}folderitem/signurl?`,                // currently not in use
    itemTranlate    : `${API_URL}folderitem/translate`,
	itemTranscribe  : `${API_URL}folderitem/transcribe`,
	
	// lti
	ltiAssignmentDetach: `${API_URL}lti/assignment/detach`,		// POST { itemAnalysisID: "97b16d18-1638-4de3-933c-8aa9977f46cf" }
	ltiAssignmentAttach: `${API_URL}lti/assignment/attach`,		// POST { 'itemAnalysisID', 'ltiConsumerID', 'ltiAssignmentID', 'ltiAssignmentTitle'}
	ltiConsumerAdd 	: `${API_URL}lti/consumer/add`,				// POST { ltiConsumerID: "moodle.nownow.co.za" }
	ltiConsumerUpdate: `${API_URL}lti/consumer/update`,				// POST { ltiConsumerID: "moodle.nownow.co.za", rollConsumerSecret: true }
	ltiGradeGet		: `${API_URL}lti/gradeget?itemAnalysisID=`, 	// GET e467ab06-f477-416c-9039-f6f70a509079`
	ltiGradePassback: `${API_URL}lti/gradepassback`,				// POST {resultScore": 0.01, itemAnalysisID: <itemAnalysisID> }

    // Organization members
    memberInvite: `${API_URL}organization/invite?`,         // Invite member to organization - email or userID
    memberRevoke: `${API_URL}organization/revoke?`,         // Revoke member from organization - email or userID
    memberAccept: `${API_URL}organization/accept?organizationID=`,		// Accept invitation to organizationID
	memberDecline: `${API_URL}organization/decline?organizationID=`, 	// Decline invitation to organizationID
    memberLeave : `${API_URL}organization/leave?organizationID=`,   	// Leave invitation to organizationID
	changeActiveOrganization: `${API_URL}organization/changeactiveorganization?organizationID=`, //

	// Organization admins
	organizationMembersFetch: `${API_URL}organization`,                 // Fetches all members in organization
	organizationUpdate: `${API_URL}organization/update`,    // POST with {autoMemberAtSignup Boolean true | false}
	
	// Reports
	ltiAttachReport:	`${API_URL}report/ltiassignmentattachment`,		// GET

    // Share & Collaborate
    userInvite  : `${API_URL}folderitem/collaboration/invite`,      // Bulk share & collaborator invite, post
    userRevoke  : `${API_URL}folderitem/collaboration/revoke?`,    // Revoke user access; (itemAnalysisID= OR itemID=)  AND &userID= 
    userLeave   : `${API_URL}folderitem/collaboration/leave?`,     // User chooses to leave a share or collaborate; itemAnalysisID= OR itemID=

    // user
    userGetCurrent      : `${API_URL}user`,
    userGetCookies      : `${API_URL}user/cookies`,
    userGetSharedCookies: `${API_URL}user/cookies?itemID=`,
    userUpdate          : `${API_URL}user`,
    userEmailContains   : `${API_URL}user?emailContains=`,           // Fetches all users where email contains entered string

    // user account & plan
    accountGet:						`${API_URL}account`,                       // Used for rehydrating the user account with new information
    paymentChangePlan: 				`${API_URL}payment/changeplan`,            // used for updating payment plan and create stripe setupIntent
	billingDetailsGet:				`${API_URL}payment/billing`,               // used to get billing details
	billingDetailsUpdateSetupIntent:`${API_URL}payment/billing/beforeupdate`, // for stripe SCA, used to create stripe setupIntent
	billingDetailsUpdate:			`${API_URL}payment/billing/update`,        // used to update billing details
	
	// Zoom
	zoomImportRecording: 		`${API_URL}zoom/recordings/import`,				// POST {downloadUrl: "https://zoom.us/rec/download/7sckIu2srz43HtGctgSDA_B-W9S4KK2s1HIf8vtcyxrmB3cLY1Sjb-MTNrSeXZSliHfLSBisID-l_i2o","itemID": "05e7e6a5-76c7-4ca6-8bfe-989f44b03fe8"}
	zoomInitiateAuthorization:	`${API_URL}oauth/zoom/initiateauthorization?folderID=`,	// GET
	zoomFetchRecordings: 		`${API_URL}zoom/recordings`,					// GET
	zoomRetainData: 			`${API_URL}oauth/zoom/userdata/retain?oauthUserID=`, //POST retains data imported from zoom (gives permission)
	zoomRevokeAuthorization: 	`${API_URL}oauth/zoom/revokeauthorization`,		// GET
	zoomFetchImportedItems: 	`${API_URL}oauth/zoom/userdata`,			// GET {oauthUserID: option, pendingDelete: true|false options}
	zoomDeleteImportedItems: 	`${API_URL}oauth/zoom/userdata?oauthUserID=`,			// DELETE {oauthUserID: option, pendingDelete: true|false options}
}