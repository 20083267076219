// SHARE, SEND & COLLABORATION ACTIONS

import { request } from './index'
import { accountRehydrate, messageToast } from './app'
import { getItem } from 'Components/Landing/actions/index.js'
import { apiConfig } from '../config';

import {INVITE_TYPES, INVITE_STATUS} from 'Constants/app'

import { 
    COLLABORATOR_ERROR,
    COLLABORATOR_LOADING,
    CLOSE_MODAL,
    ITEM_LOADING,
    ITEM_DELETE,
    ITEM_ERROR,
    ITEM_UPDATE,
    MESSAGE_ADD,
} from "Constants/actions"


/**
 * Sends an item to a list of email address.
 * @param {String} emails array of email addresses to invite
 * @param {Boolean} sendItemAnalysis whether or not to send the itemAnalysis too
 */
export function sendItem(emails, sendItemAnalysis = false) {
    return function(dispatch, getState) {

        // Loading is not functionally used - IE, only used as visual cue to user
        // And, invite will take a while if 20 users
        // So toast loading & close modal
        dispatch({type: COLLABORATOR_LOADING });
        dispatch({type: MESSAGE_ADD, payload: 'Sending...'});
        setTimeout(() => {
            dispatch({ type: COLLABORATOR_LOADING, payload: false });
            dispatch({ type: CLOSE_MODAL})
        }, 900);
        
        // Set itemAnalysis and item 
        const {selectedItemAnalysis, selectedItem}  = getState().folderItems;
        
        // Update via API
        const data = {
            itemID: selectedItem.itemID,
            title: selectedItem.title,
            emails,
            itemAnalysisIDs: []
        }

        if(sendItemAnalysis) data.itemAnalysisIDs = [ selectedItemAnalysis.itemAnalysisID ]

        const requestObj = {
            method: 'post',
            url: apiConfig.itemSend,
            data
        }

        return request( requestObj, ITEM_ERROR )
        .then( resp => {
            // Contruct user message
            let message = `Media sent to `;
            if (emails.length === 1)
                message = message + `one person`;
            else if (emails.length > 1)
                message = message + `${(emails.length)} people`
			message = message + `. Recipients will be notified by email.`;
			
            dispatch( messageToast(MESSAGE_ADD, message, 10000));
            return Promise.resolve();
        })
        .catch( err => {
            return Promise.resolve();
        })
    };
}

/**
 * Triggers collaboration & share invitiation by email address.
 * NOTE: Assumes selectedItemAnalysis is state.folderItems and that itemAnalysis.itemID exists in state.folderItems.data
 * @param {String} type is one of INVITE_TYPES.SHARE or INVITE_TYPES,COLLABORATE
 * @param {String} emails array of email addresses to invite
 * @param {String} PERMISSION - 'ANALYZE', 'COMMENT' or 'VIEW' for permission granted to emails
 */
export function userInvite(inviteType, emails, permission = 'ANALYZE') {
	return function(dispatch, getState) {
		dispatch({type: COLLABORATOR_LOADING});
        
        // Set itemAnalysis and item 
        const itemAnalysis  = getState().folderItems.selectedItemAnalysis;
        const item          = getState().folderItems.selectedItem;
        
        // Set variables based on inviteType...
        // inviteType is SHARE
        if( INVITE_TYPES.SHARE === inviteType ) {
            var key = 'itemIDs';
            var ID = item.itemID;
        }   
        // inviteType is COLLABORATE
        else {
            var key = 'itemAnalysisIDs';
            var ID = itemAnalysis.itemAnalysisID;
        }

        const requestObj = {
            method: 'post',
            url: apiConfig.userInvite,
            data: {
				suppressNotificationEmail: false,
				// notificationEmailTitle: "",
				[key]: [
					ID
				],
				permission,
				emails,
			}
        }

        return request( requestObj, COLLABORATOR_ERROR )
        .then( resp => {
			// Rehydrate from server 
			dispatch(accountRehydrate(resp, inviteType))
            dispatch({ type: COLLABORATOR_LOADING, payload: false });
			dispatch(getItem(item.itemID))
			return Promise.resolve(resp)
		})
		.catch( err => {
			// err handled in _dmApp error
			dispatch({ type: COLLABORATOR_LOADING, payload: false });
        })
	};
}


// LEAVE & REVOKE
/**
 * Generic function triggered when user LEAVES an item
 * @param {String} url API url to call
 * @param {String} itemID to remove from user's view
 */
export function userLeave(itemID, itemAnalysisID = false) {
	return function(dispatch, getState) {
        dispatch({ type: ITEM_LOADING, payload: true });

        // Delete item from state
        dispatch({ type: ITEM_DELETE, payload: itemID });
        
        // Delete via API
        if( itemAnalysisID === false )
            var url = `${apiConfig.userLeave}itemID=${itemID}`
        else
            var url = `${apiConfig.userLeave}itemAnalysisID=${itemAnalysisID}`

        request( {url}, COLLABORATOR_ERROR )
        .then( resp => {
            dispatch(accountRehydrate(resp))
        })        
	};
}

/**
 * Triggered when a user wants to REVOKE a collaborator/share on an itemAnalysisID.
 * NOTE: Assumes itemID is in state.folderItems and singular
 * NOTE: For collaboration - assumes itemID containes itemAnalysis being updated
 * @param {String} itemAnalysisID to LEAVE
 * @param {String} userID userID who is being revoked
 * @param {Object} item being left
 */
export function revokeUser(inviteType, itemID, userID) {
	return function(dispatch, getState) {

        // Set itemAnalysis and item 
        const itemAnalysis  = getState().folderItems.selectedItemAnalysis;
        const item          = getState().folderItems.data[itemAnalysis.itemID];
        
        // Consturct users object from emails. userObj is the accumaltive value returned
        // Same whether share or collaborate.... yay us!!
        const userObj = (oldUserObj) => {
                return {
                    ...oldUserObj,
                    [userID]: {
                        ...oldUserObj[userID],
                        status: 'REVOKED'
                    }
            }
        }
        
        if (INVITE_TYPES.SHARE === inviteType) {
            var key = 'itemID'
            var ID = itemID;
            const users = userObj( item.shares.users );
            var updatedItem = {
                ...item,
                shares: {
                    ...item.shares,
                    users,
                }
            }
        }
        else {
            var key = 'itemAnalysisID';
            var ID = itemAnalysis.itemAnalysisID;
            const users = userObj( itemAnalysis.collaborators.users )
            item.itemAnalyses[0] = {
                ...itemAnalysis,
                collaborators: {
                    ...itemAnalysis.collaborators,
                    users,
                }
            }
            var updatedItem = item;
            // var updatedItem = {
            //     ...item,
            //     itemAnalysis: {
            //         ...item.itemAnalysis,
            //         collaborators: {
            //             ...item.itemAnalysis.collaborators,
            //             users,
            //         }
            //     }
            // }
        }

        // Update State
        dispatch({
            type: ITEM_UPDATE,
            payload: updatedItem
        });

        // Let user know
        dispatch( messageToast(MESSAGE_ADD, 'Person successfully removed.', 1500));
        
        //Update API
        const url = `${apiConfig.userRevoke}${key}=${ID}&userID=${userID}`;

        request( {url}, COLLABORATOR_ERROR )
        .then( resp => {
            dispatch( accountRehydrate(resp, inviteType))
        })   
	};
}

// SEARCH FUNCTIONS
// export function collaboratorSearch(searchEmail) {
// 	return function(dispatch) {
// 		dispatch(_isSessionValid()).then(resp => {
// 			const url = `${apiConfig.userEmailContains}${searchEmail}`;
// 			axios
// 				.get(url)
// 				.then(function(resp) {
// 					dispatch({
// 						type: COLLABORATOR_SEARCH,
// 						payload: resp.data
// 					});
// 					return;
// 				})
// 				.catch(function(error) {
// 					dispatch(_dmAxiosErrorHandler(EVENT_TAGS_ERROR, error));
// 					return;
// 				});
// 		});
// 	};
// }

// export function collaboratorSearchClear() {
// 	return {
// 		type: COLLABORATOR_SEARCH_CLEAR
// 	};
// }