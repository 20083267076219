import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { I18n } from '@aws-amplify/core';

import { TAG_TYPES } from 'Constants/app';
import { EN } from '../../../lang/en';
import { addEventTag } from './../actions/index';
import Checkbox from 'Widgets/Inputs/Checkbox.jsx'

class DashboardComment extends React.Component {
	constructor(props) {
		super(props);
        this.state = { 
			newComment: '', 
			newCommentTimestamp: null,
			pauseOnType: this.props.preferences.pauseOnType
		}
	}

	render() {
		return (
			<div>
				<form
					onSubmit={this.handleSubmit}
					className="input-group analysis-dashboard-comment-inputgroup">
					<textarea
						placeholder={I18n.get('Your comments here')}
						className="form-control analysis-dashboard-comment-inputgroup"
						value={this.state.newComment}
						onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
					/>

					<span className="input-group-btn">
						<button
							className="btn btn-outline-secondary"
							onClick={this.handleSubmit}
							data-toggle="tooltip"
							data-placement="top"
							title={EN.DASHBOARD_COMMENT_SUBMIT_TOOLTIP}
							data-container="body">
							<i className="fas fa-sign-out" />
						</button>
					</span>
				</form>
                <div className="container">
				    <div className="row">
					    <div className="col-6" style={{padding: 0}}>
                            <span className="analysis-keyb-shortcuts">
                                &#8984;-Enter or Ctrl-Enter to submit
                            </span>
                        </div>
                        <div className="col-6" style={{padding: 0}}>
				            <span className="bottombar-title right checkbox"
								onClick={this.togglePauseOnType} >
								{I18n.get('Pause on type')}
								<Checkbox
									checked={this.state.pauseOnType}
									className={{checkbox: true}}
									disabled={false}
									name='DbPauseOnType'
									onClick={this.togglePauseOnType}
									ref='DbPauseOnType'
									data-toggle="tooltip"
                                    data-placement="top"
									title={I18n.get('Pause on type')}
								/>
                            </span>
                        </div>
                    </div>
                </div>
			</div>
		);
	}

	handleChange = e => {
		this.state.pauseOnType ? this.props.pause() : null;
		this.setState({
			newComment: e.target.value,
			// Ensures comment timestamp is taken at time typing starts
			newCommentTimestamp: '' === e.target.value  		// if value is blank, reset timestamp to null (assumes user no long want to input)
				? null
				: null === this.state.newCommentTimestamp		// if timestamp is null, set to current time else leave
					? this.props.currentTime
					: this.state.newCommentTimestamp
		});
	};

	handleKeyDown = (e) => {
        //Cmd+Enter or Ctrl+Enter to Submit
		if (e.keyCode === 13 && e.metaKey) {
            e.preventDefault();
			this.handleSubmit(e);
        }
    }

	handleSubmit = e => {
		e.preventDefault();
		var comment = _.trim(this.state.newComment);
		if (0 !== comment.length) {
			this.props.addEventTag(
				{
					...this.props,
					currentTime: this.state.newCommentTimestamp,
				},
				comment
			);
			this.setState({ newComment: '', newCommentTimestamp: null });
		}
		this.state.pauseOnType ? this.props.play() : null;
	};
	
	togglePauseOnType = e => {
		e.preventDefault();
		this.setState({ pauseOnType: !this.state.pauseOnType });
	};
}

function mapStateToProps(state) {
	const user = state.user.data;
	return {
		// all below used in addEventTag()
		currentTime: state.playerState.currentTime,
		avatarURL_32: user.avatarURL_32,
		initials: user.initials,
		currentUserID: user.userID,
		preferences: user.preferences, 
	};
}

const mapDispatchToProps = { addEventTag };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComment); // NOTICE!! Returns the C 0 N T A I N E R ! !
