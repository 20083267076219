import React, {Component} from 'react';
import {connect} from 'react-redux';

// import { I18n } from '@aws-amplify/core';

import {openModal} from 'actions/app';

import BtnIcon from 'Widgets/ButtonIcon.jsx';
import Divider from 'Widgets/DropdownDivider.jsx';

import CollaborateMenuBtn from 'Menus/CollaborateMenuBtn.jsx'
import DashboardsMenuBtn from 'Menus/DashboardsMenuBtn.jsx'
import DeleteItemMenuBtn from 'Menus/DeleteItemMenuBtn.jsx'
import LeaveMenuBtn from 'Menus/LeaveMenuBtn.jsx'
import MarkersMenuBtn from 'Menus/MarkersMenuBtn.jsx'
import OpenItemMenuBtn from 'Menus/OpenItemMenuBtn.jsx'
import RenameItemMenuBtn from 'Menus/RenameItemMenuBtn.jsx'
import SendMenuBtn from 'Menus/SendMenuBtn.jsx'

import { PLANID } from 'Constants/app';

class ProjectThumbDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.showDropdown !== this.props.showDropdown) {
			this.setState({showDropdown: this.props.showDropdown});
		}
	}

	render() {
		const {showDropdown} = this.state;
		return (
			<div className="dropdown show" onMouseLeave={() => this.handleDDLeave()}>
				<BtnIcon
					onClick={() => this.setState({showDropdown: !showDropdown})}
					onDoubleClick = {(e) => {e.preventDefault(); e.stopPropagation();}} 
					tooltip="Project Settings"
					btnClass="btn project-thumb-menu-btn"
					iconClass="far fa-ellipsis-h"
				/>
				{showDropdown && this.renderButtons(this.props)}
			</div>
		);
    }
    
	// Defines buttons will be rendered only if the dropdown show state is true.
	renderButtons = props => {
		const {
			canCollaborate, canRename,
			isOwner, locked, mediaType, planID
		} = props;

		// FOLDER => RETURN LIMITED MENU FOR FOLDER
		if (mediaType === 'FOLDER') {
			return (
				<div className="dropdown-menu">
					<OpenItemMenuBtn handleOnClick={props.handleOpenClick} />
					{canRename
						? <RenameItemMenuBtn handleOnClick={this.handleEditClick} />
						: null
					}
					<Divider id="1" />
					<DeleteItemMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
				</div>
			);
		}

		// will return all open, delete, and collab buttons as default
		// note: canCollaborate is from invite type of share
		if (isOwner || canCollaborate) {
			return (
				<div className="dropdown-menu">
					<OpenItemMenuBtn handleOnClick={props.handleOpenClick} />
					<Divider id="2" />
					<DashboardsMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
					<MarkersMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
					<CollaborateMenuBtn handleOnClick={this.handleOpenModal} />
					{!PLANID.FREE[planID]
						? <SendMenuBtn handleOnClick={this.handleOpenModal} />
						: null
					}
					{/* {'development' === process.env.NODE_ENV ? share : null} */}
					<Divider id="3" />
					{isOwner
						? <RenameItemMenuBtn handleOnClick={this.handleEditClick} />
						: null
					}
					<DeleteItemMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
				</div>
			);
		}

		// if the user is not the owner of the file, will only return the open button
		return (
			<div className="dropdown-menu">
				<OpenItemMenuBtn handleOnClick={props.handleOpenClick} />
				<LeaveMenuBtn handleOnClick={this.handleOpenModal} />
			</div>
		);
	};

	// HANDLES
	handleOpenModal = (e, name, action) => {
		e.preventDefault();
		this.setState({showDropdown: false});
		this.props.openModal(name, '', {action});
	};

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};

	handleEditClick = e => {
		this.handleDDLeave();
		this.props.handleEditClick(e);
	};
}

function mapStateToProps (state) {
	return {
		planID: state.account.planType,
    }
}

export default connect(mapStateToProps, {openModal})(ProjectThumbDropdown);
