import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { TransitionGroup } from 'react-transition-group';

import withDragDropContext from "../Widgets/withDragDropContext.jsx";

import SingleMarkerDraggable from "./SingleMarker.Draggable.jsx";
import { FadeUpTransition } from 'Widgets/FadeUpTransition.jsx';

import { updateMarker } from './actions';

class MarkersSelectsDefaults extends Component {
  constructor(props) {
    super(props);

    // will attempt to create the markers arr with current props
    const markersArr = this.createMarkersArr(this.props);
    this.state = { markersArr };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // needs to always rerender when sent new props.
    this.setState({ markersArr: this.createMarkersArr(nextProps) });
  }
  // accepts data in form of "props" to create an array of markers.
  createMarkersArr = props => {
    return (
      Object.keys(props.markers)
        .map(marker => {
          // saves the marker object to a variable so we can access it later in the map
          const markerObj = props.markers[marker];
          if (!markerObj.default) return null;

          let listItem = classNames({ "list-group-item": true, option: true });
          // if the selected marker is not null,
          if (props.selectedMarker !== null) {
            // will check if the selected markers ID is equivalent to the marker in the map
            listItem = classNames({
              "list-group-item": true,
              option: true,
              selected: props.selectedMarker === markerObj.markerID,
              unsaved: markerObj.doSave === false
            });
          }
          return { markerObj, className: listItem, key: marker };
        })
        // will filter out any item that is falsy, i.e. undefined / null
        .filter(item => item)
        .sort((a, b) => {
          const markerA = a.markerObj.sortOrder;
          const markerB = b.markerObj.sortOrder;
          if (markerA < markerB) return -1;
          if (markerA > markerB) return 1;
          return 0;
        })
    );
  };
  // actually moves the markers
  moveMarker = (dragItem, hoverItem) => {
    const { markersArr } = this.state
    // creates a new updated array
    const updatedArr = markersArr.map(item => {
      // the specific items marker ID
      const itemMarkerID = item.markerObj.markerID
      // replaces the dragged items sort order with the hovered items sort order
      if ( itemMarkerID === dragItem.markerID ){
        return {
          ...item,
          markerObj: {
            ...item.markerObj,
            sortOrder: hoverItem.sortOrder
          }
        }
      }
      // replaces the hovered items sort order with the dragged items sort order      
      if ( itemMarkerID === hoverItem.markerID ){
        return {
          ...item,
          markerObj: {
            ...item.markerObj,
            sortOrder: dragItem.sortOrder
          }
        }
      }
      return item;
    })
    .sort((a, b) => {
      const markerA = a.markerObj.sortOrder;
      const markerB = b.markerObj.sortOrder;
      if (markerA < markerB) return -1;
      if (markerA > markerB) return 1;
      return 0;
    })
    this.setState({ markersArr: updatedArr })
  }
  // Used to actually submit the new markers to be saved.
  submitNewDefaultOrder = () => {
    const allMarkers = this.props.markers
    this.state.markersArr.map( marker => {
      // checks if the order inside redux is the same as this states order, if not, will update it.
      if ( allMarkers[marker.key].sortOrder !== marker.markerObj.sortOrder ){
        // takes entire marker
        this.props.updateMarker(marker.markerObj)
      }
    })
  }
  renderDefaultSelects = () => {
    const { markersArr } = this.state

     // if the markersArr length is less than 1, we want to return that none are defaults.
    if ( markersArr.length < 1 ){
      return (
        <FadeUpTransition classNames="list-defaults">
          <p className="no-defaults-selected">None selected</p>
        </FadeUpTransition>
      )
    }

    // maps over markersArr to return draggable markers
    return markersArr.map( marker => {
      const { className, key, markerObj } = marker
      return (
        <FadeUpTransition
          key={key}
          classNames="list-defaults">
          <SingleMarkerDraggable
            className={className}
            markerObj={markerObj}
            handleClick={this.props.handleClick}
            key={key}
            moveMarker={this.moveMarker}
            submitNewDefaultOrder={this.submitNewDefaultOrder}
          />
        </FadeUpTransition>
      )
    })
  }
  render() {
    return (
      <ul className="list-group list-group-defaults">
        <TransitionGroup>
          {this.renderDefaultSelects()}
        </TransitionGroup>
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    markers: state.userMarkers.data,
    loading: state.userMarkers.loading
  };
};

const MarkersSelectsDefaultsFinal = compose(
  connect(mapStateToProps, { updateMarker }),
  withDragDropContext
)(MarkersSelectsDefaults);

export default MarkersSelectsDefaultsFinal;