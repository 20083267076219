import React from 'react';
import {connect} from 'react-redux';
import {dismissMessage} from 'actions/app';

/**
 * Displays a message, green is textClass is 'success', otherwise red.
 * @param {Object} props
 * 
 * props {
 *      message: message to diplay
 *      textClass: xxx class of text-xxx
 *      location: action to dimiss errors from, passed to dismissMessage
 *      dismissable: whether or not to display Dismiss link
 *      dimissMessage: non-usable
 * }
 * 
 * Default props: message='', textClass='danger', dismissable='true'
 *  
 */
const Message = (props) => {
	let {message='', textClass='danger', location, dismissable=true, dismissMessage, style, children } = props
	
	const defaultStyle = { paddingBottom: '.5rem', fontStyle: 'italic'};
    style = { ...defaultStyle, ...style }

    if(message === '' && !children)
        return <div className={`text-info`} style={style}>
            <strong>&nbsp;</strong>
        </div>
    return (
        <div className={`text-${textClass}`} style={style}>
            <strong>
                {message === '' ? children : message}
            </strong>
            { true === dismissable
            ? <span onClick={() => dismissMessage(location)} id="dismiss-error-btn">
                Dismiss
            </span>
            : null}
        </div>
    )
};

export default connect(null, {dismissMessage})(Message);
