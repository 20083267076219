import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { DropTarget } from "react-dnd";
import _ from "lodash";

import { dndItemTypes } from "Constants/app.js";
import { updateDashboard } from "./actions";
import TagButton from "./TagButton.jsx";

const folderDropTarget = {
  drop(props, monitor, component) {
    const { dashboardTags, dashboardID } = props.selectedDashboard;
    // if the length of the dashboard tags are greater than 8, we want to return, as it will just append to the back anyways
    if (dashboardTags.length >= 8) return undefined;
    // monitor.getItem() gives you the item that was being dragged
    const draggedItem = monitor.getItem();
    // grabbing the target items index
    const targetItemIndex = props.indexOfTag;
    // grabbing the dragged items index
    const draggedItemIndex = _.indexOf(dashboardTags, draggedItem.tagID);
    // if it's not above 0, it doesn't exist in array
    // if it's already in the array, we want to ignore it
    if (draggedItemIndex >= 0) return undefined;
    let newTagsArray = dashboardTags.concat(draggedItem.tagID);
    // if it happens to be larger than 8, we slice it
    newTagsArray.length > 8 && (newTagsArray = newTagsArray.slice(0, 8));
    // Sends new array through. updatedashboard accepts a dashboardID, and an updated prop.
    // ex { dashboardTags: [newTagsArray] }
    props.updateDashboard(dashboardID, { dashboardTags: newTagsArray });
  }
};

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class DashboardsMiddlebarUserTags extends Component {
  renderButtons = () => {
    const { selectedDashboard, allTags } = this.props;
    // if the selected dashboard is null, we won't go any further
    const { dashboardTags } = selectedDashboard;

    // renders an empty tag to be used in place of no tags
    const emptyTag = (tag, i) =>
      // emptyTag(tag, i)
      <TagButton
        indexOfTag={i}
        selectedDashboard={selectedDashboard}
        // empty tag should not be able to be dragged, but can be dropped on
        // tagLocation is where this tag is located. I.e. either in the allTags component, or the dashboardTags component
        dndProps={{
          canBeDragged: false,
          canBeDropped: true,
          tagLocation: "dashboardTags"
        }}
        // passing null tag item, because this shouldn't be clicked
        tagID={null}
        handleSelectTag={this.props.handleSelectTag}
        name="Drag New Tags Here"
        key={tag + i}
        className="btn btn-secondary btn-empty"
      />;

    // we ultimately need 8 spaces always. So we need to subtract the current length
    // of the renderedDashboardsTags array, and append however many buttons we need
    if (dashboardTags === undefined) return;
    let fillTagsAmount, filledTagsArr, filledDashboardTags;
    if (dashboardTags.length <= 8) {
      fillTagsAmount = 8 - dashboardTags.length;
      filledTagsArr = Array(fillTagsAmount).fill("empty");
      filledDashboardTags = _.concat(dashboardTags, filledTagsArr);
    } else {
      filledDashboardTags = dashboardTags.slice(0, 8);
    }

    // maps over the selected dashboards dashboardTags array
    let renderedDashboardTags = filledDashboardTags.map((tag, i) => {
      if (tag === undefined) return;
      // tagItem is set to the correct tag object from reducer
      const tagItem = allTags[tag];
      // tagItem is undefined or the tag type is not a button,
      // we do not want to render the tag
      if (tagItem === undefined || tagItem.type === "empty")
        return emptyTag(tag, i);
      if (tagItem.type !== "BUTTON") return;
      // otherwise returns the TagButton
      return (
        <TagButton
          indexOfTag={i}
          selectedDashboard={selectedDashboard}
          // dashboard tags should be able to be dragged, and dropped on.
          // tagLocation is where this tag is located. I.e. either in the allTags component, or the dashboardTags component
          dndProps={{
            canBeDragged: true,
            canBeDropped: true,
            tagLocation: "dashboardTags"
          }}
          tagID={tagItem.dashboardTagID}
          handleSelectTag={this.props.handleSelectTag}
          key={tagItem.dashboardTagID}
          name={tagItem.label}
          className={`btn btn-${tagItem.color}`}
        />
      );
    });
    return renderedDashboardTags;
  };
  render() {
    const { selectedDashboard } = this.props;
    // if the selected dashboard is null, we don't render the drop target
    if (selectedDashboard === null) {
      return (
        <div
          className="dbedit-content-dbtags"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h2 id="h2-large">
            Select a Dashboard
          </h2>
        </div>
      );
    }
    // props from react-dnd
    const { isDragging, connectDropTarget, canDrop } = this.props;

    return connectDropTarget(
      <div className="dbedit-content-dbtags">
        {/* child passed is just the title */}
        {this.props.children}
        <div className="dbedit-content-dbtags-dbTagDiv">
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allTags: state.userDashboardTags.data,
    allDashboards: state.userDashboards.data
  };
}

const DashboardsMiddlebarUserTagsFinal = compose(
  connect(mapStateToProps, { updateDashboard }),
  DropTarget(dndItemTypes.ITEM, folderDropTarget, collectDrop)
)(DashboardsMiddlebarUserTags);

export default DashboardsMiddlebarUserTagsFinal;
