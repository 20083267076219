import { dm_print } from '../utils/';

import _ from 'lodash';
import {
    CUNAUTH_USER,
    ERROR_ADD,
    MESSAGE_ADD,
    MESSAGES_CLEAR,
    UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
    message: '',
    error: false
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// Set state to initial state
        case CUNAUTH_USER:
        case UNMOUNT_ANALYSIS:
        case MESSAGES_CLEAR:
			return INITIAL_STATE;

        case MESSAGE_ADD:
            return { 
                ...state, 
                message: action.payload, 
                error: false 
            };

        case ERROR_ADD:
        return { 
            ...state, 
            message: action.payload, 
            error: true
        }

	}

	return state;
}
