import _ from 'lodash';
import {
	CUNAUTH_USER,
	FLOADING,
	FOLDERS_FETCH,
	ITEM_MOVE_FOLDER,
	ITEM_MOVE_FOLDER_UNDO
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: true,
	data: {}
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
            return { ...INITIAL_STATE, loading: false };
            
		case FLOADING:
			/** 
			 * NOTE: Only called prior to folderFetch
			 * Return initial state otherwise folder shows thumbs of previous folder when changing folders
			*/
			return INITIAL_STATE;
			// return { ...state, loading: true };

		case FOLDERS_FETCH: {
			return { ...state, data: action.payload, loading: false };
		}

		// TODO: this need to be fixed as we are just doing a temporary fix for the newChildren const.
		case ITEM_MOVE_FOLDER:
			// temporary fix for when childItems object is empty. This needs to be dixed.
			const newChildren = state.data.childItems || {};
			delete newChildren[action.payload.itemID];
			return {
				...state,
				data: {
					...state.data,
					childItems: newChildren,
					folderItemsResult: Object.keys(newChildren)
				}
			};

		case ITEM_MOVE_FOLDER_UNDO:
			const folderItemsResult = state.data.folderItemsResult;
			folderItemsResult.push(action.payload.itemID);
			return {
				...state,
				data: {
					...state.data,
					childItems: {
						...state.data.childItems,
						[action.payload.itemID]: action.payload.item
					},
					folderItemsResult: [...folderItemsResult]
				}
			};
	}

	return state;
}
