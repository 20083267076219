import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const CollaborateMenuBtn = ({handleOnClick}) => (
	<MenuBtn
		label={I18n.get('Collaborate')}
		title={I18n.get('Invite Collaborators')}
		onClick={e => { handleOnClick(e, "modalInvite") }}
		iconClass='users text-success'
	/>
);

export default CollaborateMenuBtn;