import _ from 'lodash';
import React from 'react';

const MenuItemUploadsInProgress = ({
	iconClass,
	iconTransform,
	percentage,
	progressStatsText,
	title,
}) => (
		<li
			className='dropdown-item progressbar'
			data-toggle="tooltip"
			title={title}
		>
			<div className='progressbar-title'>
				<i className={'far fa-fw fa-' + iconClass} data-fa-transform={iconTransform} />
				{title}
			</div>
			<div className="progressbar-bar" style={{ width: `${percentage}%` }}></div>
			<div className='progressbar-stats'>
				{progressStatsText}
			</div>
		</li>
	);

export default MenuItemUploadsInProgress;