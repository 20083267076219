import _ from 'lodash';
import classNames from 'classnames';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { REGEXP } from 'Constants/app';
import { MESSAGE_ADD } from 'Constants/actions';
import { messageToast } from 'actions/app';

import { inviteMember } from './actions/index'

import Modal from 'Widgets/Modal.jsx';
import Message from 'Widgets/Message.jsx';

class ModalMemberInvite extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
            emails: '', 
            // emails: 'test@nownow.co.za,paola@dreamaker.io',
            // emails: 'aaa@nownow.co.za, bbb@nownow.co.za',
            // emails: 'test1@nownow.co.za, test2@nownow.co.za, test3@nownow.co.za, test4@nownow.co.za, test5@nownow.co.za, test6@nownow.co.za, test7@nownow.co.za, test8@nownow.co.za, test9@nownow.co.za, 10@nownow.co.za, 11@nownow.co.za, 12@nownow.co.za, 13@nownow.co.za, 14@nownow.co.za, 15@nownow.co.za, 16@nownow.co.za, 17@nownow.co.za, 18@nownow.co.za, 19@nownow.co.za, 20@nownow.co.za',
            err: '',
            loading: false
		};
	}

	render() {
        const { loading } = this.state; 
        
		// will only display the invite button if there are no persons found for the typed in email
		// const displayInviteIfNoneAvailable = classNames({
        //     'btn btn-secondary': this.state.emails === '',
        //     'btn btn-primary': this.state.emails !== ''
		// 	//'hidden-content': Object.keys(data).length > 0
		// });

		const displayLoading = classNames({
			'far fa-spinner fa-spin spinner': loading
		});

		return (
			<Modal>
				<div className="modal-header">
                    <div className="modal-subtitle">Organization Members</div>
					<div className="modal-title">Invite Members</div>
				</div>
				<div className="modal-body">
                    <div className="row">
						<div className="col-12 text-left">
                        { '' === this.state.err
                        ? <div><strong>People</strong></div>
                        : this.renderMessage()
                        }
                        </div>
                    </div>
					{/* Single Text Box
                    <div className="row">
						<div className="col-12">
							<div className="input-group">
								<input
									autoFocus
									type="text"
									className="form-control"
									name="searchEmail"
									value={this.state.emails}
									onChange={this.handleChange}
                                    onKeyDown={this.handleOnKeyDown}
									placeholder="example@example.com"
								/>
								<span className="input-group-btn">
									<button
										className={displayInviteIfNoneAvailable}
										type="button"
										onClick={(e) =>
											this.handleInvite(this.state.emails)}
										disabled={loading}>
										Send invite <i className={displayLoading} />
									</button>
								</span>
							</div>
						</div>
					</div> */}
					<div className="row">
						<div className="col-12">
							<div className="input-group">
								<textarea
                                    style={{overflow: 'hidden', height: '60px'}}
                                    id='emails'
									autoFocus
									type="text"
									className="form-control"
									name="emails"
									value={this.state.emails}
									onChange={this.handleChange}
                                    onKeyDown={this.handleOnKeyDown}
									placeholder="Enter email addresses. Separate with comma or a new line..."
								/>
							</div>
						</div>
					</div>
				</div>
                { this.state.emails === ''
                    ? <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={this.context.closeModal}>
                            Done
                        </button>
                    </div>
                    : <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => this.handleInvite(this.state.emails)}
							disabled={loading}>
                            Send Invitations <i className={displayLoading} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={this.context.closeModal}>
                            Cancel
                        </button>
                    </div>
                }
			</Modal>
		);
    }

	handleChange = e => {
        this.setState({ err: '' });
		const target = e.target;
        this.setState({ emails: target.value });
    };
    
    handleOnKeyDown = e => {
        //TODO: if key code is enter or comma, check email and make it an <email />
        if (e.keyCode == 13) {
            e.preventDefault();
            this.handleInvite(this.state.emails)
            return
        }

        // autosize textarea based on input
        this.autosize();
    }

    //Autosizes text area
    autosize(){
        let el = document.getElementById('emails')
        setTimeout(function(){
          el.style.cssText = 'height:auto;';
          el.style.cssText = 'height:' + (10 + el.scrollHeight) + 'px';
        },0);
    }

    // Handles actual invite & logic
	handleInvite = (emails) => {
        this.setState({ err: '', loading: true });
        
        // Clean emails
        emails = emails.toLowerCase();                      //lowercase
        emails = emails.replace(/(?:\r\n|\r|\n)/g, ',' )    //replace newline with `,`
        emails = emails.replace(/\s+/g, '' )                //remove any whitespace
        emails = emails.split(',')                          //ensure unique emails
        emails = _.uniq(emails)
        emails = emails.filter(String)                      //remove empties

        let newInviteCount = emails.length;

        // Limit to 20 invites at a time
        if( 20 < newInviteCount ) {
            this.setState({ err: `Only 20 people can be invited at a time.`, loading: false});
            return;
        }


        // Check emails are valid using const function
        let lastValidated = '';
        const validateEmails = (email) => {
            // Used for failure message
            lastValidated = email;
            // Test and return false on failure, email true on success
            if ((email.match(/@/g) || []).length > 1) return false;
            return REGEXP.EMAIL_VALID.test(email) 
        }

        // If every email is valid to proceed with invite
        if(emails.every( validateEmails )) {
            // let errEmails = [], successEmails = [];
            emails.map((email, i) => {
                this.props.inviteMember({email})    // new user
                .then( resp=>{
                    // success and failure handled & displayed by each invite in table
                    // sucessEmails.push(resp);
                })
                .catch( err=>{
                    // success and failure handled & displayed by each invite in table
                    // errEmails.push(resp);
                })
            })

            // Contruct user sucessMsg
            // if(sucessEmails > 0 ) {
            //     let sucessMsg = `${sucessEmails[0]} `;
            //     if (sucessEmails.length === 2)
            //         sucessMsg = sucessMsg + `and one other `;
            //     else if (sucessEmails.length > 2)
            //         sucessMsg = sucessMsg + `and ${(sucessEmails.length - 1)} others `
            //     sucessMsg = sucessMsg + `successfully invited`;
            // }

            // if(errEmails > 0 ) {
            //     let errMsg = `Invite Failures${errEmails} fail`;
            //         errMsg = errMsg + `and ${(errEmails.length - 1)} others `
            //     errMsg = errMsg + `successfully invited`;
            // }


            // Give impression of loading before closing
            setTimeout(() => {
                this.props.messageToast(MESSAGE_ADD, `Invites sending...`, 4000);
                this.setState({ loading: false });
                this.context.closeModal();
            }, 900);
        } else {
            this.setState({ err: `Whoops, invalid email address found: ${lastValidated}` });
        }
    };

    renderMessage = () => {
        return (
            <Message message={this.state.err} textClass="danger" dismissable={false} style={{paddingBottom: 0}} />
        );
    }
}

function mapStateToProps(state) {
	return {
        modalOpen: state.modalOpen,
	};
}

export default connect(mapStateToProps, {inviteMember, messageToast})(ModalMemberInvite);
