import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTES } from 'Constants/app.js'

// If you'd like to add another path here, you'll need to add it inside the getCurrentPage
// as well as the renderSelectablePaths functions.
class DashboardMarkerLink extends Component {
  // returns the title depending on the current route
  getCurrentPage = (path) => {
    switch(path){
      case ROUTES.DASHBOARDEDITPAGE:
        return "Dashboards"
      case ROUTES.MARKERSEDITPAGE:
        return "Markers"
      default:
        return null
    }
  }
  renderSelectablePaths = () => {
    const { pathname } = this.props.location
    // maps over the passed paths to return a directory of sorts
    return [ROUTES.DASHBOARDEDITPAGE, ROUTES.MARKERSEDITPAGE].map(path => {
      // if it's same as current path, we will give it the "selected" classname
      const getClassname = classNames({
        "selected": pathname === path
      })
      return (
        <Link key={path} to={path} replace={true }>
          <h6 className={getClassname}>{this.getCurrentPage(path)}</h6>
        </Link>
      )
    })
  }
  render() {
    return (
      <div className="dashboard-marker-links">
        {this.renderSelectablePaths()}
      </div>
    )
  }
}

export default withRouter(DashboardMarkerLink)