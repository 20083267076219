import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const DeleteItemMenuBtn = ({ handleOnClick, locked}) => (
	<MenuBtn
		label={I18n.get('Delete')}
		title={locked ? I18n.get('Remove lock to enable Delete') : I18n.get('Delete')}
		disabled={locked}
		onClick={e => { handleOnClick(e, 'modalConfirmAction', 'delete');
		}}
		iconClass="trash-alt text-danger"
	/>
);

export default DeleteItemMenuBtn;