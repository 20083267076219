import {
    CUNAUTH_USER,
    ITEM_ANALYSIS_DB_TAGS_LOADING,
	ITEM_ANALYSIS_DB_TAGS_FETCH,
	UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
    data: {},
    loading: true,
    unmount: true, 
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

        case CUNAUTH_USER:
		case UNMOUNT_ANALYSIS:
			return INITIAL_STATE;
        
        case ITEM_ANALYSIS_DB_TAGS_LOADING: {
            return {
                ...state, 
                loading: true,
                unmount: false
            }};

        case ITEM_ANALYSIS_DB_TAGS_FETCH: {
            // payload is an object of dashboard tags
            if( state.unmount ) return INITIAL_STATE;
            return {
                ...state, 
                data: action.payload, 
                loading: false,
                unmount: true 
            }};
	}
	return state;
}
