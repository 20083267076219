import _ from 'lodash'

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {reduxForm, Field, Form} from 'redux-form';

import { v4 as uuidV4 } from 'uuid';
import classNames from 'classnames';

import {Loading} from 'Widgets/Loading.jsx';
import PageAccess from "../Layout/page.access.jsx";
import NoteToUser from "../Widgets/NoteToUser.jsx";
import { signupUser } from "../../actions";
import { clearUserMessages, fetchTemplates } from 'actions/app';
import { ROUTES, REGEXP } from "Constants/app";

class Signup extends Component {

    constructor(props) {
        super(props);

        const hash = this.props.location.hash;
        if( REGEXP.EMAIL_VALID.test(hash))
			this.props.initialize({ email: hash.substring(1) });
		
		const lti = this.props.lti;
		
		if(lti) {
			this.props.initialize({ email: lti.ltiUserEmail, name: lti.ltiUserFullname });
		} 
    }

    UNSAFE_componentWillMount() {
        if(undefined === this.props.templates) {
            this.props.fetchTemplates();
        } 
    }
    
	componentDidMount() {
		this.props.clearUserMessages();
	}
    
	render() {
        const {handleSubmit, loading} = this.props;
        let {templates} = this.props
        
        if (undefined === templates) templates = {} 
        else { 
            // create template <options />
            var options = []

            options.push(
                <option value={0} key='0' hidden>
                    Select a template to start with
                </option>
            )

            options.push(
                <option value={false} key='NoTemplateID'>
                    No template
                </option>
            )

            for (const key in templates) {
                // Domains
                options.push(
                    <option disabled value={key} key={key}>
                        {templates[key].title}
                    </option>
                )
                
                // Domain children - IE, actual templates
                for (const key2 in templates[key].templates) {
                    options.push(
                        <option key={key2} value={key2}>
                            - {templates[key].templates[key2].title}
                        </option>
                    )
                }
            }
        }
        
		return (
			<PageAccess>
				<div className="access">
					<div className="access-head">
						<a href="https://www.dreamaker.io" className="access-logo" />
                        {this.renderTitle()}
                        <NoteToUser
								show=""
								startTag="("
								endTag=")"
								// message='Sign up to get Started'
								note=" its free "
							/>
					</div>
					<div className="access-body">
						<div className="form form-access">
							{this.renderMessage()}
							<Form onSubmit={handleSubmit(this.handleFormSubmit)}>
								<div className="form-group">
									<Field
										name="name"
										type="text"
										component={renderInput}
										label="Fullname"
										autoFocus={true}
									/>
								</div>
								<div className="form-group">
									<Field
										name="email"
										type="email"
										component={renderInput}
										label="Email"
									/>
								</div>
								<div className="form-group">
									<Field
										name="password"
										type="password"
										component={renderInput}
										label="Password"
									/>
								</div>
								<div className="form-group">
									<Field
										name="passwordConfirm"
										type="password"
										component={renderInput}
										label="Confirm password"
									/>
								</div>
                                <div className="form-group">
                                    {_.isEmpty(templates) // IE possible error retrieving templates - just don't show them.
                                    ? <div style={{height: '0rem'}}>
                                        <Field
                                            type='hidden'
                                            name="noTemplates"
                                            component={renderInput}
                                        />
                                    </div>
                                    : <Field 
                                        name="initialTemplateID"
                                        component={renderSelectField} >
                                        {options}
                                    </Field>
                                    }
								</div> 
								<div className="form-group consent">
                                    <div style={{  marginRight: '.8rem'}}>
                                        Receive occasional{' '}<a href="https://www.dreamaker.io" target="_blank">Dreamaker.io</a>{' '}news, promotions and updates?{' '} 
                                    </div>
                                    <Field
                                        name="emailsUnsubscribed"
                                        type="radio"
                                        component={renderInput}
                                        label="Yes"
                                        value="false"
                                    />{' '}
                                    <Field
                                        name="emailsUnsubscribed"
                                        type="radio"
                                        component={renderInput}
                                        label="No"
                                        value="true"
                                    />
                                    
                                </div>
								<div className="form-group consent">
                                    <Field
                                        name="tcppConsent"
                                        type="checkbox"
                                        component={renderInput}
                                        value="tcppConsent"
                                    />{' '}
                                    <div className="info">
                                        I agree to  Dreamaker.io's{' '}
                                        <a target="_blank" href="https://dreamaker.io/terms" >
                                            Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a target="_blank" href="https://dreamaker.io/privacy" >
                                            Privacy Policy
                                        </a>.
                                    </div>
                                </div>
								<button
									type="submit"
									className="btn btn-secondary"
									disabled={loading}>
									Signup! <i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
								</button>
							</Form>
						</div>
						<br />
						Already signed up... <Link to={ROUTES.SIGNIN}>Sign in</Link>
					</div>
				</div>
			</PageAccess>
		);
	}

	renderTitle = () => {
        const {location, organizationAccept} = this.props
        
        let title = 'Signup'
        let subtitle = false

        if (organizationAccept && location.hash === '#invite') {
            title = <span style={{fontSize: '1.9rem'}}>Join the Dreamaker.io organization <b>{organizationAccept}</b></span>;
        }
        
        else if (location.hash === '#invite') {
            title = `Welcome to Dreamaker.io`
            subtitle = `Sign up to start collaborating`
        }
        
        return (
            <div>
                <h1>{title}</h1>
                {subtitle ? <h2>{subtitle}</h2> : null}
            </div>
        )
    };

	renderMessage() {
        let { message, err, submitFailed, tcppConsent, emailsUnsubscribed } = this.props

        if(submitFailed) {
            tcppConsent ? message = "Consent to Dreamaker.io's Terms and Privacy Policy is required to use our services." : null;
            emailsUnsubscribed ? message = "Dreamaker.io sends occasional information regarding product updates, promotions and news. Please let us know if you would like to receive these emails?" : null;
        }

		if (message) {
			return (
				<div className="text-success">
					<strong>
						<i>{message}</i>
					</strong>
					<br />
					<br />
				</div>
			);
		} else if (err) {
			return (
				<div className="text-danger">
					<strong>
						<i>{err}</i>
					</strong>
					<br />
					<br />
				</div>
			);
		}
    }
    
	handleFormSubmit = formProps => {
		// No userID? Create GUI for User
		if (!formProps.userID) {
			formProps.userID = uuidV4();
        }
		this.props.signupUser(formProps);
    };
}

const renderInput = ({autoFocus, input, label, type, meta: {error, submitFailed}}) => {
	return (
		<div>
			<input
				{...input}
				type={type}
				className="form-control"
				placeholder={label}
				autoFocus={autoFocus}
			/>
			{submitFailed && error && label !== 'No' && <div className="text-danger">{error}</div>}
            {' '}{ type==='radio' ? <span>{label}</span> : ''}{' '}
		</div>
	);
};

const renderSelectField = ({input, label, type, meta: {error, submitFailed}, children }) => (
    <div>
        <select {...input} className="form-control">
            {children}
        </select>
        {submitFailed && error && <div className="text-danger">{error}</div>}
    </div>
  )

function validate(formProps) {
    const errors = {};
    

	if (!formProps.name) {
		errors.name = 'Please enter your name';
    } 
    /**
     * Name limits:
     *      Not allowed: to start with 2 digits or special charactes
     *      Not allowed: special chars (excludes . and -)
     *      Not allowed: 3 or more digits
     */
    else if(formProps.name.length < 2 || formProps.name.length > 30) errors.name = 'Please enter your full name';   // Length test
    else if(
        !/^[^0-9<>()\[\]\\.,;:\s@!%&+\-="]{2}(([^<>()\[\]\\,;:\r\n\t@!%&+\="]*))/.test(formProps.name) ||           // Character and formation test
        /\d{4,}/g.test(formProps.name)                                                                              // Max 3 digits
        ) errors.name = 'Please enter a valid name';


	if (!formProps.email) {
		errors.email = 'Please enter an email';
	} else if (!REGEXP.EMAIL_VALID.test(formProps.email)) {     // Format test
		errors.email = 'Invalid email address';
	}

	if (!formProps.passwordConfirm) {
		errors.passwordConfirm = 'Please confirm your password';
	} else if (formProps.password !== formProps.passwordConfirm) {
		errors.password = 'Passwords must match';
	}

	if (!formProps.password) {
		errors.password = 'Please enter a password';
    } else if (!REGEXP.PASSWORD.test(formProps.password) || /\s/g.test(formProps.password)) {
		errors.password =
			'Password must have a length of at least 8 and contain uppercase & lowercase characters, numbers and special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ `';
	}

    if (!formProps.tcppConsent) {
        errors.tcppConsent = '*';
    }

    if (!formProps.emailsUnsubscribed) {
        errors.emailsUnsubscribed = '*';
    }

    // Disallow undefined, 0 or an id with white space (latter in the marginal case where the value does not intialize)
    if (!formProps.initialTemplateID || '0' === formProps.initialTemplateID || /\s+/g.test(formProps.initialTemplateID) ){
        errors.initialTemplateID = "Select a template to start with, or 'No template'.";
    }

    if( formProps.noTemplates ) {
        delete errors.initialTemplateID
    }
    
	return errors;
}

function mapStateToProps(state) {
    const {_, form, user, templates} = state;

    var tcppConsent = false;
    var emailsUnsubscribed = false;
    if (form.signup.syncErrors) {
        tcppConsent = form.signup.syncErrors.tcppConsent;
        emailsUnsubscribed = form.signup.syncErrors.emailsUnsubscribed;
    }

	return {
        err: user.error,
		initialValues: user,
		loading: user.loading,
		lti: _.lti,
        message: user.message,
        organizationAccept: state._.organizationAccept,
        submitFailed: form.signup.submitFailed,
        emailsUnsubscribed,
		tcppConsent,
        templates: templates.data
	};
}

const mapDispatchToProps = { clearUserMessages, fetchTemplates, signupUser};

Signup = compose(
	reduxForm({form: 'signup', validate}),
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Signup);

export default Signup;
