import { dm_print } from '../utils/';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import { refreshUser } from 'actions';
import { setQueueSize } from 'actions/app';
import { getBandwidth } from 'utils/bandwidth';

import RouteListener from './RouteListener.jsx';

import Account from './Account/Account.jsx';
import Analysis from './Analysis/Analysis.jsx';
import Dashboards from './Dashboards/Dashboards.jsx';
import Reset from './Auth/Reset.jsx';
import Landing from './Landing/Landing.jsx';
import Maintenance from './Maintenance.jsx';
import Markers from './Markers/Markers.jsx';
import NotFound from './NotFound.jsx';
import RequireAuth from './Auth/require.auth.jsx';
import Signin from './Auth/Signin.jsx';
import Signup from './Auth/Signup.jsx';
import UpgradePage from './Account/Upgrade.jsx';
import Verify from './Auth/Verify.jsx';
import ZoomImports from './Landing/ZoomImports.jsx'

import ModalJoinOrganization from 'Modals/Modal.Join.Organization.jsx'

import { ROUTES } from 'Constants/app';

class App extends Component {

	constructor(props, context) {
		super(props, context);

		// Detect & handle when internet down/up
		Offline.on("down up", this.toggleAppClickable)
	}

	componentDidMount() {		
		if (!this.props.authenticated) {
			this.props.refreshUser();
		}
		getBandwidth(queueSize => this.props.setQueueSize(queueSize));
	}

	shouldComponentUpdate(nextProps) {
        // if authenticated changed? -> update
		if (nextProps.authenticated !== this.props.authenticated) return true;
		return true;
	}

	componentWillUnmount() {
		// Unregister internet down/up events
		Offline.off("down up", this.toggleAppClickable);
	}

	render() {
		const { authenticated, loading, redirect, verify } = this.props;
        
		return (
			<div id="dmio-root" style={{ pointerEvents: 'auto', opacity: 100 }} >
				{authenticated ? <ModalJoinOrganization /> : null }
				<BrowserRouter>
					<RouteListener>
						{ !this.props.onlyus /** under maintenenace **/ 
                            ? (
                            <Switch>
                                <Route render={() => <Maintenance time="about an hour" />} />
                            </Switch>
						    ) 
                            : (
							<Switch>
                                
								<Route
									path={ROUTES.SIGNIN}
									exact
									render={() =>
										authenticated 
											? <Redirect to={ redirect ? redirect : ROUTES.LANDING + '/0'} />
											: <Signin />
										}
								/>

								<Route
									path={ROUTES.SIGNUP}
									render={() =>
										authenticated 
											? <Redirect to={ redirect ? redirect : ROUTES.LANDING + '/0'} />
											: verify 
												? <Redirect to={ROUTES.VERIFY} />
												: <Signup />
											}
								/>

								{/* Redirect for old forgot pages */}
								<Route path={'/forgot'} >
									<Redirect to={ROUTES.RESET} />
								</Route>
								<Route path={ROUTES.RESET} component={Reset} />
                                

                                {/** If direct access to VERIFY is attempted, redirect to signin **/}
								<Route path={ROUTES.VERIFY} 
                                    render={() =>
                                        verify 
											? <Verify />
                                        	: <Redirect to={`${ROUTES.SIGNIN}`} />
                                        }
                                />

								{/** NOTE: Including Switch in <RequireAuth /> was only way to enforce redirect of RequireAuth **/}
								<RequireAuth
									path={ROUTES.ANALYSIS}
									authenticated={authenticated}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.ANALYSIS_RT} component={Analysis} />
											<Redirect to={`${ROUTES.LANDING}/0`} />
										</Switch>
									)}
								/>

								<RequireAuth
									path={ROUTES.LANDING}
									authenticated={authenticated}
									loading={loading}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.LANDING_RT} component={Landing} />
											<Redirect to={`${ROUTES.LANDING}/0`} />
										</Switch>
									)}
								/>
									
								<RequireAuth
									path={ROUTES.ZOOM}
									authenticated={authenticated}
									//loading={loading}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.ZOOM_RT} component={ZoomImports} />
											<Route path={ROUTES.ZOOM} component={ZoomImports} />
										</Switch>
									)}
								/>

								<RequireAuth
									path={ROUTES.ACCOUNT}
									authenticated={authenticated}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.ACCOUNT} component={Account} />
										</Switch>
									)}
								/>

                                <RequireAuth
                                    path={ROUTES.UPGRADE}
                                    authenticated={authenticated}
                                    render={({ path }) => (
                                        <Switch>
                                            <Route path={ROUTES.UPGRADE} component={UpgradePage} />
                                        </Switch>
                                    )}
                                />

								<RequireAuth
									path={ROUTES.DASHBOARDEDITPAGE}
									authenticated={authenticated}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.DASHBOARDEDITPAGE} component={Dashboards} />
										</Switch>
									)}
								/>

								<RequireAuth
									path={ROUTES.MARKERSEDITPAGE}
									authenticated={authenticated}
									render={({ path }) => (
										<Switch>
											<Route path={ROUTES.MARKERSEDITPAGE} component={Markers} />
										</Switch>
									)}
								/>
								
                                
								<Route component={NotFound} />
							</Switch>
						)}
					</RouteListener>
				</BrowserRouter>
			</div>
		);
	}

	toggleAppClickable = () => {
		if (document.getElementById('dmio-root').style['pointer-events'] === "auto") {
			document.getElementById('dmio-root').style['pointer-events'] = "none";
			document.getElementById('dmio-root').style['opacity'] = 0.5;
		} else {
			document.getElementById('dmio-root').style['pointer-events'] = "auto";
			document.getElementById('dmio-root').style['opacity'] = 100;
		}
	}
}

function mapStateToProps(state) {
	return {
		authenticated: state.user.authenticated,
		loading: state.user.loading,
		onlyus: state._.onlyus,
		redirect: state._.redirect || false,
		verify: state.user.verify,
	};
}

const mapDispatchToProps = { refreshUser, setQueueSize };
export default connect(mapStateToProps, mapDispatchToProps)(App);