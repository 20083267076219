import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IMAGES } from 'Constants/app';
const {MOODLE_LOGO} = IMAGES;

import { addLTIConsumer } from './actions/index'

import { ChatNow } from 'Widgets/ChatNow.jsx';


class IntegrationLti extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMoodleIntegration: false,
			loading: {
				moodleLti: false
			},
		};
	}

	render () {
		const { loading, showMoodleIntegration } = this.state;
		
		return (
			<div>
				<div className="row settings-integrations" >
					<div className='col-10 company'>
						<img className='company-logo' src={MOODLE_LOGO} />Moodle LTI
					</div>
					<div className='col-2' style={{ textAlign: 'right' }}>
						<button
							onClick={showMoodleIntegration
								? e => { this.handleInstallLtiIntegration(e, 'moodleLti') }
								: this.ToggleEnterConsumerID
							}
							className={`btn ${showMoodleIntegration ? 'btn-success' : 'btn-primary'}`}
							disabled={loading.moodleLti}>
							{showMoodleIntegration ? 'Install' : 'Add'}<i className={loading.moodleLti ? 'far fa-spinner fa-spin spinner' : ''} />
						</button>
					</div>
					<div className="col-11 container">
						{this.renderEnterConsumerID()}
					</div>
				</div>
			</div>
		);

		// card return (
		// 	<div className="card text-center">
		// 		<img className='card-img-top logo' src={MOODLE_LOGO} />
		// 		<div className="card-body">
		// 			<h4 className="card-title">Moodle LTI</h4>
		// 			<p className="card-text">Create, grade and open Dreamaker.io assignments directly in Moodle.</p>
		// 			<div id="help-text" className={showMoodleIntegration ? 'help-text-shown' : 'help-text-hidden'} >
		// 				{this.renderIntegration('Enter your Moodle consumer ID: ', 'moodleLtiConsumerID', '')}
		// 			</div>
		// 			<button
		// 				onClick={showMoodleIntegration ? this.handleInstallLtiIntegration : this.ToggleEnterConsumerID}
		// 				className={`btn ${showMoodleIntegration ? 'btn-success' : 'btn-primary'}`}
		// 				disabled={loading.moodleLti}>
		// 				{showMoodleIntegration ? 'Install' : 'Add'}<i className={loading.moodleLti ? 'far fa-spinner fa-spin spinner' : ''} />
		// 			</button>
		// 		</div>
		// 	</div>
		// )
	}

	renderEnterConsumerID = () => {

		if (this.props.isOrganization)
			return (
				<div className="col-11" id="help-text"
					style={{ display: 'flex', 'flexDirection': 'column-reverse', alignItems: 'flex-end', marginTop: '10px' }}
					className={this.state.showMoodleIntegration ? 'help-text-shown' : 'help-text-hidden'} >
					<div style={{ minWidth: '110px', color: 'lightblue'}}>Enter your Moodle consumer ID</div>
					<div style={{ color: '#cfd2da' }}>
						<input
							className={'form-text'}
							defaultValue=''
							key='moodleLtiConsumerID'
							name='moodleLtiConsumerID'
							ref='moodleLtiConsumerID'
							id='moodleLtiConsumerID'
							size={30}
							style={{textAlign: 'right', color: '#373c47', cursor: 'text', margin: 0, fontWeight: 300}}
						/>
					</div>
				</div>
			)
		else 
			return (
				<div className="col-11" id="help-text"
					style={{ display: 'flex', 'flexDirection': 'column-reverse', alignItems: 'flex-end' }}
					className={this.state.showMoodleIntegration ? 'help-text-shown' : 'help-text-hidden'} >
					<div style={{ minWidth: '110px', marginTop: '5px' }}><ChatNow>Contact us to enable this integration</ChatNow></div>
					<div style={{ color: '#cfd2da' }}>
						<input hidden ref='moodleLtiConsumerID' />
					</div>
				</div>
			)
	}

	/** Toggles displaying the EnterConsumer ID <input /> */
	ToggleEnterConsumerID = e => {
		e.preventDefault();
		e.stopPropagation();
		this.refs['moodleLtiConsumerID'].focus();
		this.setState({ showMoodleIntegration: !this.state.showMoodleIntegration });
	};

	handleInstallLtiIntegration = (e, integration) => {
		// Toggle visibility if value is empty
		if ('' === this.refs['moodleLtiConsumerID'].value) {
			this.ToggleEnterConsumerID(e)
			return;
		}

		if (!this.props.isOrganization) return;

		this.setState({ loading: {
			...this.state.loading,
			[integration]: true
		}})

		switch ('moodleLti') {
			case 'moodleLti': {
				const ltiConsumerID = this.refs['moodleLtiConsumerID'].value;
				if('' !== ltiConsumerID) {
					this.props.addLTIConsumer( this.refs['moodleLtiConsumerID'].value)
					.then( resp => {
						this.refs['moodleLtiConsumerID'].value = '';
						this.setState({ showMoodleIntegration: false });
					})
					.finally(() =>{
						this.setState({ loading: {
							...this.state.loading,
							[integration]: false
						}})
					})
					return
				}
			}
			default: {
				this.setState({ loading: {
					...this.state.loading,
					[integration]: false
				}})
			}
		}
	};

}

function mapStateToProps (state) {
	let { account } = state;
	return {
		isOrganization: account.isOrganization || false,
	};
}

const mapDispatchToProps = {
	addLTIConsumer,
};


export default connect(mapStateToProps, mapDispatchToProps)(IntegrationLti);