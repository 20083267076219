import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

// Uploads
import Uploader from 'utils/uploader.js';
export var upload = null;
import { getFileData } from 'utils'
import { accountRehydrate, setShow } from 'actions/app'

import { 
	_dmAppErrorHandler,
    request,
	_session 
} from 'actions/index';

import { getAccount } from 'Components/Account/actions/'
import { getAnalysisLob } from 'Components/Analysis/actions/'


import { apiConfig, FILEIN_BCT, DMIO_STORE_KEY } from 'config';
import { LOB_KEYS, PLANID, PROGRESS_FLAGS, REGEXP } from 'Constants/app';
import {
	ERROR_ADD,
	LOB_FILEASSET_ERROR,
	LOB_ERROR,
	LOB_LOADING,
	LOB_SET,
	SET_SHOW,
	UPLOAD_ERROR,
	UPLOAD_INITIALIZING,
	UPLOAD_PROGRESS,
	UPLOAD_NAME_CHANGE,
} from 'Constants/actions';

import { DMIO_RELEASE } from '../../../../config';

/**
 * Creates a file asset db entry, setting uploadProgress to PROGRESS_FLAGS.UPLOADSTARTED
 * @param {String} itemAnalysisID to associate file asset with
 * @param {String} fileID UUID. Unique ID for file asset
 * @param {String} fileNameBase is the name of the file, without the extension. Eg: 'Consent Form'
 * @param {String} fileNameExtension is the extension of the file, with out the full stop. Eg: 'pdf'
 */
function _createFileAsset ({ fileID, fileNameBase, fileNameExtension, itemAnalysisID, ownerID }) {
	return function (dispatch, getState) {
		const data = {
			itemAnalysisID,
			fileID,
			fileNameBase,
			fileNameExtension,
			uploadProgress: PROGRESS_FLAGS.UPLOADSTARTED, // | PROGRESSING | COMPLETED | ERROR 
		}

		const lobKey = `${LOB_KEYS.FILE}_${fileID}`

		// NOTE: Dispatch _SET before _LOADING to avoid breakage
		dispatch({
			type: LOB_SET,
			payload: { 
				itemAnalysisID, 
				lobKey, 
				lob: {
					...data,
					// fixes race condition where small upload completes before all uploaded file data fetched
					ownerID
				}
			}
		});
		dispatch({ 
			type: LOB_LOADING, 
			payload: { 
				itemAnalysisID,
				lobKey,
				value: false
			}});

        const requestObj = {
			method: 'POST',
            url: `${apiConfig.fileAssetCreate}`,
            data,
		};

		
        return request( requestObj, LOB_FILEASSET_ERROR )
        .then( resp => {
			dispatch({
				type: LOB_SET,
				payload: { 
					itemAnalysisID, 
					lobKey, 
					lob: {...resp[fileID]}
				}
			});
			dispatch({ 
				type: LOB_LOADING, 
				payload: { 
					itemAnalysisID,
					lobKey,
					value: false
				}});
			return Promise.resolve(resp)
        })
			.catch(err => {
			// Set error flag and deleted to true (delete in fileAssets is archiving)
			data.uploadProgress = PROGRESS_FLAGS.ERROR;
			dispatch({
				type: LOB_SET,
				payload: {
					itemAnalysisID,
					lobKey,
					lob: {
						...data,
						deleted: true
					}
				}
			});
				// Pass on rejection to fileUploader to cease upload
			return Promise.reject()
        });

    };
}

/**
 * Gets the download link of file asset with fileID 
 * @param {String} fileID - fileID of file asset link to get 
 * @returns {Promise} String contains the file asset link, incl. https.
 */
export function getFileAssetLink( fileID ) {
	return function(dispatch, getState) {
        const requestObj = {
            url: `${apiConfig.fileAssetGetLink}${fileID}`,  
		};
		
        return request( requestObj, LOB_ERROR )
		.then(resp => {
			return Promise.resolve(resp.link)
		})
			.catch(err => {
			// Handled by global err processor
			return Promise.resolve();
        });

    }
}

/**
 * Updates file asset of given fileID
 * @param {*} fileID 
 * @param {*} key 
 * @param {*} newValue 
 */
export function updateFileAsset(itemAnalysisID, fileID, key, newValue) {
	return function (dispatch, getState) {
		const lobKey = `${LOB_KEYS.FILE}_${fileID}`
		const currentFileAssetLob = getState().itemAnalysisLOBs[itemAnalysisID][lobKey]

        const requestObj = {
            method: 'POST',
            url: `${apiConfig.fileAssetUpdate}`,  
            data: {
				fileID,
				[key]: newValue
            }
        };

        return request( requestObj, LOB_ERROR )
			.then(resp => {
			/**
			 * resp.usageData: {...[updated_usageData]}
			 * resp[fileID]: {...[update_fileAsset_data]}
			 */
			dispatch({
				type: LOB_SET,
				payload: { 
					itemAnalysisID, 
					lobKey, 
					lob: {
						...currentFileAssetLob,
						[key]: newValue
					} 
				}
			});
			dispatch(accountRehydrate(resp))
			return Promise.resolve();
        })
			.catch(err => {
			return Promise.reject(err);
        });

    }
}

/**
 * Returns a map used to dispatch LOB_LOADING specifically where lobKey is 'files'
 * @param {String} itemAnalysisID to update
 * @param {Boolean || String} value to which to 
 * @returns {Object}
 */
function _lobFilesLoading(itemAnalysisID, value=true) {
	return {
		type: LOB_LOADING, 
		payload: {
			itemAnalysisID,
			lobKey: 'files',
			value
		}
	}
}

/**
 * Fetches all availableLOBs from itemAnalysis or sets default if none exist
 * @param {Object} itemAnalysis 
 */
export function fetchFileAssetLobs(itemAnalysisID, filesAvailable){
    return function(dispatch, getState) {
        dispatch( _lobFilesLoading(itemAnalysisID) );
			
		// Asyncronous despatch of file lob gets to ensure all are loaded before setting `filesLoading` key to false
		_fetchFileAssetLobs( dispatch, itemAnalysisID, filesAvailable )
		.finally(()=>{
			dispatch( _lobFilesLoading(itemAnalysisID, false ));
		})
    }
}

/**
 * Async function to fetch all file lobs. Files lobs are fetch synchronously, but the function only completes once all are done
 * See below for more URLs for good info on async & wait
 * https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Async_await
 * https://lavrton.com/javascript-loops-how-to-handle-async-await-6252dd3c795/
 * @param {*} dispatch 
 * @param {*} itemAnalysisID 
 * @param {*} availableLOBs 
 */
async function _fetchFileAssetLobs( dispatch, itemAnalysisID, filesAvailable ) {
	var promises = []
	for( const lobKey of filesAvailable) {
		// if ( !lobKey.startsWith('file') ) continue;
		// Placing function calls in an array for later synchronoous dispatch by Promise.all() 
		promises.push( dispatch(getAnalysisLob(itemAnalysisID, lobKey)) )
	}
	// Promise.all() calls promises synchronously but awaits for all to complete before returning
	await Promise.all(promises)
}

/** FILE UPLOADS  */


// transcodeProgress = STARTED | PROGRESSING | COMPLETED | ERROR | WARNING
/**
 * Polls for the progress of the upload
 * @param {String} itemAnalysisID UUID. Item analysis related to the upload
 * @param {String} fileID UUID 
 * @returns 
 */
function _fileAssetProgressPoll (itemAnalysisID, fileID) {
	return function (dispatch, getState) {
		const lobKey = `${LOB_KEYS.FILE}_${fileID}`
		const url = `${apiConfig.analysisGet}${itemAnalysisID}&lobsList=${lobKey}`

		// Run this evey 5 seconds to check on transcodeProgress
		var checkProgress = setInterval(function () {
			request({ url }, LOB_ERROR)
				.then(resp => {
					const progressFlag = resp[lobKey].uploadProgress
					dispatch({
						type: UPLOAD_PROGRESS,
						payload: {
							id: fileID,
							progressFlag: progressFlag
						}
					});
					switch (progressFlag) {
						case PROGRESS_FLAGS.COMPLETED:
							clearInterval(checkProgress);
							dispatch(getAnalysisLob(itemAnalysisID, lobKey))
							dispatch(getAccount());
							return;

						case PROGRESS_FLAGS.PROGRESSING: {
							dispatch(getAnalysisLob(itemAnalysisID, lobKey))
							return;
						}

						case PROGRESS_FLAGS.WARNING:
						case PROGRESS_FLAGS.ERROR: {
							// Error shown in uploadsInProgress
							clearInterval(checkProgress);
							// Server side error - delete dynamoDB entries 
							// Handles Reydration
							dispatch( updateFileAsset( itemAnalysisID, fileID,'deleted', true ))  // Handles Reydration
							return;
						}
					}
					return;
				})
				.catch(function (error) {
					clearInterval(checkProgress);
					dispatch(_dmAppErrorHandler(UPLOAD_ERROR, error))
					return error;
				});
		}, 5000);
	};
}

/**
 * Uploads a file to S3 using dmio_evaporate.js. 
 * @param {String} folderID of parent folder into which file is uploaded
 * @param {Object} file being uploaded, as HTML file
 * @param {Bolean} attach true or false, indicating whether or not to attach uploaded file to an LTI assignment
 */
export function uploadFileAsset (file) {
	return function (dispatch, getState) {

		if (undefined === file) {
			dispatch({
				type: ERROR_ADD,
				payload: "Please select a file to upload."
			});
			return;			
		}
			
		// Get needed variables
		const { account: { userID, usageData, planData } } = getState();
		const { itemID, itemAnalysisID, ownerID } = getState().folderItems.selectedItemAnalysis

		//Get media file data
		const { fileNameBase, fileNameExtension, sizeMB } = getFileData(file)
		const fileName = `${fileNameBase}.${fileNameExtension}`;

		// ERROR CHECKS - set hasError to error string on error
		let hasError = false;

		// Check if free plan - no file uploads allowed
		if (PLANID.FREE[planData.planID])
			hasError = "Ah, you'll need to upgrade to upload project attachments."

		// Check for valid file extensions
		else if ('' === fileNameExtension || '' === fileNameBase)
			hasError = `Files must include a name and extension. Eg MyFile.csv`

		else if (!(new RegExp(REGEXP.FILEASSET_WHITELIST.source + '|' + REGEXP.IMAGE_WHITELIST.source)).test(fileNameExtension))
			hasError = `File type '.${fileNameExtension}' not accepted.`


		// Check usageData.storageLimit (We're giving them an allowance of 2 MBs)
		if (hasError === false &&
			usageData.storageCount + sizeMB > planData.maxStorage + 2)
			hasError = "Unfortunately you don't have enough storage space for this file."

		// Throw error if present	
		if (hasError) {
			dispatch({
				type: ERROR_ADD,
				payload: hasError
			});
			return;
		}

		// INITIALIZE VARIABLES
		const partSize = 1024 * 1024 * 5; // This is the default, cannot be smaller
		const fileID = uuidV4();
		const path = `${ownerID}/${itemID}/${itemAnalysisID}`;

		// Data strictly relavent for passing through  to uploader.
		const newFile = {
			itemAnalysisID,
			fileID,
			fileNameBase,
			fileNameExtension,
		}

		const updatedUsageData = {
			usageData: {
				storageCount: usageData.storageCount + sizeMB,
				fileCount: usageData.fileCount + 1
			}
		}

		dispatch({
			type: UPLOAD_INITIALIZING,
			payload: {
				id: fileID,
				name: fileName,
				progressFlag: PROGRESS_FLAGS.CALCULATING
			}
		});
		dispatch(setShow('uploadsInProgress', true))

		upload = Uploader.initialize({
			dispatch,
			bucket: FILEIN_BCT,
			maxConcurrentParts: getState().app.queueSize,
			partSize: partSize,
			sizeMB,
			storeKey: `${DMIO_STORE_KEY}.${userID}.uploads`,
		});

		upload({
			type: 'FILEASSET',
			bucket: FILEIN_BCT,
			file,
			fileID: fileID,
			name: `${path}/file_${ fileID}`,
			userID,

			// Creates new item
			createCallback: function (overrides = {}) {
				// console.log('CALLED createCallback ')
				var { newFile, ownerID, updatedUsageData } = this;
				return function (dispatch) {
					dispatch(
						_createFileAsset({...newFile, ownerID}))
						.then((resp) => {
							// Creation successful, update usage stats
							dispatch(accountRehydrate(updatedUsageData));
						})
				}
			}.bind({ newFile, ownerID, updatedUsageData }),

			// deleted item - currently not in use - impacts resumability by deleting bd record
			// deleteCallback: function (overrides = {}) {
			// 	// console.log('CALLED deleteCallback ')
			// 	const { fileID, itemAnalysisID } = this;
			// 	const { resumedID, resumedItemAnalysisID } = overrides
			// 	return function (dispatch) {
			// 		dispatch(updateFileAsset(
			// 			resumedItemAnalysisID ? resumedItemAnalysisID : itemAnalysisID,
			// 			resumedID ? resumedID : fileID,
			// 			'deleted', true
			// 		))
			// 	}
			// }.bind({ itemAnalysisID, fileID}),

			// checks progress of copying after upload
			preparationProgressCallback: function (overrides = {}) {
				// console.log('CALLED preparationProgressCallback ')
				const { fileID, itemAnalysisID } = this;
				const { resumedID, resumedItemAnalysisID } = overrides
				return function (dispatch) {
					dispatch(_fileAssetProgressPoll(
						resumedItemAnalysisID ? resumedItemAnalysisID : itemAnalysisID,
						resumedID ? resumedID : fileID
					))
				}
			}.bind({ fileID, itemAnalysisID }),

			// resumes an alreadu initiated upload
			resumeCallback: function (overrides = {}) {
				// console.log('CALLED resumeCallback ')
				const { fileID, updatedUsageData } = this;
				const { resumedID, resumedItemAnalysisID } = overrides
				return function (dispatch) {
					dispatch({ type: UPLOAD_NAME_CHANGE, payload: { oldID: fileID, resumedID } })
					dispatch(accountRehydrate(updatedUsageData))
					dispatch(getAnalysisLob(resumedItemAnalysisID, `${LOB_KEYS.FILE}_${resumedID}`, true))
				}
			}.bind({ fileID, updatedUsageData }),
		})
		.then(
				function (awsObjectKey) {
					// console.log('Upload success: ', awsObjectKey);
					// dispatch(setShow('uploadsInProgress', true))
				},
				function (err) {
					// console.log('Upload failed with reason ', err);
					dispatch({
						type: UPLOAD_PROGRESS,
						payload: {
							id: fileID,
							name: fileName,
							progressFlag: PROGRESS_FLAGS.ERROR
						}
					});
					Rollbar.error(
						`uploader.js error: `, err,
						{
							dmioRelease: DMIO_RELEASE,
						})
				}
			)
	};
}