import _ from "lodash";

import React, { Component } from "react";
import { connect } from "react-redux";

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import {INVITE_STATUS} from 'Constants/app'

import { closeModal } from 'actions/app';
import { deleteItem, selectItem } from './actions/';
import { ltiAttachMedia, ltiDetachMedia } from './actions/lti';
import { userLeave } from 'actions/share';

import ModalConfirm from 'Widgets/Modal.Confirm.jsx';

class ModalConfirmAction extends Component {

	constructor(props) {
		super(props);

        // Set state for confirm action
		const { data: { action, lti } } = this.props.modalOpen
		const {title} = this.props.selectedItem || ''
		
        switch( action ) {
            case 'attach': {
                this.state = {
					cancelBtn: I18n.get('Cancel'),
					confirmBtn: I18n.get('Submit'),
                    confirmPhrase: `Would you like to submit this file to activity '${lti.ltiAssignmentTitle}'`,
					onConfirm: e => this.handleAttachClick(e, true),
					message: `The media will be submitted to the activity '${lti.ltiAssignmentTitle}' and teachers associated with the activity will be given access.`,
					title: null
				}
				return;
			}

            case 'detach': {
                this.state = {
					cancelBtn: I18n.get('Cancel'),
					confirmBtn: I18n.get('Unsubmit'),
                    confirmPhrase: `Would you like to unsubmit this file from the activity '${lti.ltiAssignmentTitle}'`,
					onConfirm: e => this.handleDetachClick(e),
					message: `Instructors associated with the activity will not be able to access the file. The file can be re-submitted to the activity again later.`,
					title: null
                }
				return;
			}

            case 'delete':
                this.state = {
                    onConfirm: this.handleDeleteClick,
                    confirmBtn: I18n.get('Delete'),
                    confirmPhrase: I18n.get('Are you sure you want to delete'),
					message: `${I18n.get('All child items & associated analysis projects will be deleted.')}${' '}${I18n.get('Deleting an item is irreversible.')}`,
					title
                }
                return;
            
            /**
             * Note: Shared project wording: Remove.
             * Are you sure you want to remove the shared folder xxx from your Dropbox? This shared project will stay shared with other members.
             * */

            case 'leave':
                this.state = {
                    onConfirm: this.handleLeaveClick,
                    confirmBtn: I18n.get('Delete'),
                    confirmPhrase: I18n.get('Are you sure you want to delete'),
                    message: I18n.get('The project will be deleted from your workspace.'),
					title
                }
                return;

            case 'locked':
                this.state = {
                    onConfirm: this.handleItemUpdate,
                    confirmBtn: I18n.get('Unlock'),
                    confirmPhrase: I18n.get('Are you sure you want to unlock'),
                    message: I18n.get('Unlocking will re-enable project analysis, modifications and deleting.'),
					title
                }
                return;

            case 'confidential':
                this.state = {
                    onConfirm: this.handleItemUpdate,
                    confirmBtn: I18n.get('Remove'),
                    confirmPhrase: I18n.get('Are you sure you want to remove the Privacy Guard from'),
                    message: I18n.get('Removing the Privacy Guard will disable privacy notices and collaboration confirmations.'),
					title
                }
                return;
        }
	}

    render() {
        return ( 
            <ModalConfirm
				cancelBtn={this.state.cancelBtn || false }
                confirmBtn={this.state.confirmBtn}
                confirmPhrase={this.state.confirmPhrase}
                onCancel={this.state.onCancel || false}
				onConfirm={this.state.onConfirm}
                message={this.state.message}
                title={this.state.title}
            />
        )
	}
	
	handleAttachClick = (e, attach) => {
		e.preventDefault();
		e.stopPropagation();
		const { xID, data: { lti }} = this.props.modalOpen;

		// NOTE: Disabling upload with attach - attach acts as assignment submission 
		// const { data: { file, folderID, lti }} = this.props.modalOpen;
		// Upload with attaching
		// if( file && folderID )
		// 	this.props.uploadMediaItem(folderID, file, attach)
		// else if(attach)
		this.props.ltiAttachMedia(xID, lti.ltiAssignmentID)  //xID is the itemID
		.finally(() => {
			this.props.closeModal();
		});
	}

	handleDetachClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.ltiDetachMedia(this.props.modalOpen.xID)
		.finally(() => {
			this.props.closeModal();
		});
	}

	handleDeleteClick = e => {
		e.preventDefault();
		e.stopPropagation();
        this.props.deleteItem(this.props.selectedItem.itemID);
		this.props.selectItem(null);
    };
    
	handleLeaveClick = e => {
        const {selectedItem, selectedItemAnalysis, userID} = this.props
		e.preventDefault();
        e.stopPropagation();
        
        if (selectedItem.shares.users[userID]) {
            if (selectedItem.shares.users[userID].status === INVITE_STATUS.ACCEPTED) {
                    var itemAnalysisID = false;
            } else {
                var itemAnalysisID = selectedItemAnalysis.itemAnalysisID;
            }
		} else {
            var itemAnalysisID = selectedItemAnalysis.itemAnalysisID;
        }

		this.props.userLeave(
            selectedItem.itemID,
            itemAnalysisID
        );
        this.props.selectItem(null)
        this.props.closeModal();
    };
    
    // Specfically sets item.[action] to false
    handleItemUpdate = e => {
		e.preventDefault();
        e.stopPropagation();      
        const { xID, data: { action, onConfirm} } = this.props.modalOpen  
        onConfirm(xID, action, false);
        this.props.closeModal();
    }
}

function mapStateToProps(state) {
    return {
        selectedItem: state.folderItems.selectedItem,
        selectedItemAnalysis: state.folderItems.selectedItemAnalysis,
        userID: state.user.data.userID,
        modalOpen: state.modalOpen
    };
}

const mapDispatchToProps = {
	closeModal, deleteItem, 
	ltiAttachMedia, ltiDetachMedia,
	selectItem, userLeave,
}

export default connect(mapStateToProps, mapDispatchToProps )(ModalConfirmAction);