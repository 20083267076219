import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {ROUTES,PLANID} from 'Constants/app.js';
import Modal from 'Widgets/Modal.jsx';
import ModalConfirm from 'Widgets/Modal.Confirm.jsx';
import {Loading} from 'Widgets/Loading.jsx';
import {formatLocalDate, formatCurrency} from '../../utils';

class ModalEditPlan extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
    };
    
	renderCancel = data => {
		return (
			<ModalConfirm
				onConfirm={() => {
					this.props.onConfirm(data.selectedPlan, data.paymentPeriod, data.prorationDate);
				}}
				title="Free Plan"
                cancelBtn="No, I'll keep my plan"
				confirmBtn="Yes, cancel my plan"
				confirmPhrase="Are you sure you want to cancel & downgrade to the"
				message={`Cancelling will downgrade your subscription to the Free Plan at the end of the current billing period on ${data
					.item.cancelOn}.`}
				message2="The Free Plan has lower usage limits than your current plan - downgrading may require you to lower your account usage to within the Free Plan limits. View your usage in the Account section."
				message3="Are you sure you'd like to cancel your subscription and downgrade to the Free Plan now?"
			/>
		);
    };
    
	renderMessage = (loadingMessage, icon, showButton) => {
		return (
			<Modal size="modal-md">
				<div className="modal-body" style={{padding: '1rem 2.5rem', textAlign: 'center'}}>
					<Loading icon={icon} message={loadingMessage} />
				</div>
				{showButton ? (
					<div className="modal-footer">
						<button
							type="button"
							data-dismiss="modal"
							className="btn btn-primary"
							onClick={this.context.closeModal}>
							{showButton}
						</button>
					</div>
				) : null}
			</Modal>
		);
    };
    
	render() {
        const {account, data, payment, billingDetails} = this.props;
        
		// No Data - render loading
		if (data === null || data === undefined)
			return this.renderMessage('Loading...', 'far fa-circle-notch fa-spin', null);
        
        // Payment processing - render payment loading
		if (payment.loading)
			return this.renderMessage(payment.loading, 'far fa-circle-notch fa-spin', null);
        
        // Payment success - render payment message (if payment.message, that means there was a success)
		if (payment.message) return this.renderMessage(payment.message, 'far fa-check', 'Great!');
        
        // Payment error - render the payment error message
        if (payment.error) return this.renderMessage(payment.error, 'far fa-times', 'Done');
        
        // Cancel subscribtion - render cancel modal
        if (PLANID.FREE[data.selectedPlan.planID]) return this.renderCancel(data);
        
        // Render change plan modal.
		return (
			<Modal size="modal-md">
				<div className="modal-header">
                    <div className="modal-subtitle">Dreamaker.io Subscriptions</div>
					<div className="modal-title">Plan change confirmation</div>
				</div>
				<div className="modal-body modal-body-changePlan" style={{padding: '1rem 2.5rem'}}>
					<p style={{marginBottom: '1rem'}}>
						Changing to the{' '}
						<b>
							{data.paymentPeriod.toLowerCase()} {data.selectedPlan.title.toLowerCase()} Plan
						</b>{' '}
						from the{' '}
						<b>
							{account.paymentPeriod.toLowerCase()}{' '}
							{account.planData.title.toLowerCase()} Plan
						</b>{' '}
						will result in the below charges:
					</p>
					{data.upcomingInvoice.starting_balance ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								fontWeight: 400,
								borderBottom: '1px solid lightgrey',
								marginBottom: '5px'
							}}>
							<span>Account opening balance:</span>
							<span>{formatCurrency(-data.upcomingInvoice.starting_balance)}</span>
						</div>
					) : null}
					<div
						style={{display: 'flex', justifyContent: 'space-between', fontWeight: 600}}>
						<span>Charge due date:</span>
						<span>{formatLocalDate(data.upcomingInvoice.date * 1000, true)}</span>
					</div>
					<div
						style={{display: 'flex', justifyContent: 'space-between', fontWeight: 600}}>
						<span>Amount due:</span>
						<span>
							{formatCurrency(data.upcomingInvoice.amount_due)}
							{data.upcomingInvoice.ending_balance ? ' *' : null}
						</span>
					</div>
					<p>The following card will be charged:</p>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontWeight: 400, margin: 0}}>
							{billingDetails.card.brand} **** {billingDetails.card.last4}
						</p>
						<Link to={ROUTES.BILLING}>Change card?</Link>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-danger"
						data-dismiss="modal"
						onClick={this.context.closeModal}>
						Cancel
					</button>
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={() =>
							this.props.onConfirm(
								data.selectedPlan,
								data.paymentPeriod,
								data.prorationDate
							)}>
						Make Payment
					</button>
				</div>
				{data.upcomingInvoice.ending_balance ? (
					<div
						className="modal-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							fontWeight: 400,
							color: 'darkgrey'
						}}>
						<small>
							<i>
								Your account will have{' '}
								{formatCurrency(-data.upcomingInvoice.ending_balance)} credit after
								this charge. The credit is from the unused time on your current plan
								& will be applied to all upcoming invoices.
							</i>
						</small>
					</div>
				) : null}
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return {
		account: state.account,
		billingDetails: state.billingDetails,
		data: state.modalOpen.data,
		payment: state.payment
	};
}
export default connect(mapStateToProps, {})(ModalEditPlan);
