/**
 * Returns map containing an array of filesAvailable and boolean|string fileLoading
 * @param {Object} itemAnalysisLOBs 
 * @returns {Object} containing {filesAvaialble [Array], filesLoading [Boolean|String] } 
 */
export const fileAssetLobsData = (itemAnalysisLOBs = {}) => {
	return {
		filesAvailable: itemAnalysisLOBs.filesAvailable || [],	// empty: nothing to load
		loading: itemAnalysisLOBs.filesLoading,	// undefined: not yet loaded. false: done loading. 'LOAD' needs loading
	}
}