import { dm_print } from 'utils/';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatStorage } from '../../utils';

class LandingSidebarAccInfo extends Component {
	constructor(props) {
		super(props);
    }
	render() {
        const { usageData } = this.props;
        if (usageData === undefined || usageData === null)
            return <div className="dashboard-sidebar" />;
 
		return (
			<div onClick={e => e.stopPropagation()} className="dashboard-sidebar">
				<div className="dashboard-sidebar-head">
					<h6>Info</h6>
				</div>
				<div className="dashboard-sidebar-body">
					<div className="collaborators">
						<div className="collaborators-space-between">
							<h6 className="collaborators-title">Your Stats!</h6>
						</div>
						<div className="collaborators-space-between">
							Storage:&nbsp;{formatStorage(usageData.storageCount)}<br />
							Collaborators:&nbsp;{usageData.collaboratorCount}<br />
							Dashboards:&nbsp;{usageData.dashboardCount}<br />
                            --<br />
							Shares:&nbsp;{usageData.shareCount}<br />
                            Marker Count:&nbsp;{usageData.markerCount}<br />
                            --<br />
                            Video Count:&nbsp;{usageData.videoCount}<br />
                            Video Length:&nbsp;{usageData.videoLength}<br />
                            Audio Count:&nbsp;{usageData.audioCount}<br />
                            Audio Length:&nbsp;{usageData.audioLength}<br />
                            --<br />
                            transcriptionRequests&nbsp;{usageData.transcriptionRequests}<br />
                            transcriptionSeconds&nbsp;{usageData.transcriptionSeconds}<br />
						</div>
					</div>
				</div>
			</div>
		);
    }
}

function mapStateToProps(state) {
	return {
		user: state.user.data,
        usageData: state.account.usageData,
	};
}

export default connect(mapStateToProps)(LandingSidebarAccInfo);
