import classNames from 'classnames';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal.jsx';

class ModalConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingCancel: false
		};
	}

	render () {
		const { props } = this, { loading, loadingCancel } = this.state;

		const loadingIcon = classNames({
			'far fa-spinner fa-spin spinner': loading,
		});

		const loadingCancelIcon = classNames({
			'far fa-spinner fa-spin spinner': loadingCancel
		});

		return (
			<Modal size="modal-sm">
				<div className="modal-header">
					<div className="modal-subtitle">{props.subTitle ? props.subTitle : 'Confirmation'}</div>
					<div className="modal-title">
						{props.confirmPhrase}&nbsp;
						<span>{props.title}</span>?
					</div>
				</div>
				<div className="modal-body">
					{undefined !== this.props.children
						? this.props.children
						: <div className="row">
							<div className="col-12">
								{props.message ? props.message : null}
								{props.message2 ? <p>{props.message2}</p> : null}
								{props.message3 ? <p>{props.message3}</p> : null}
							</div>
						</div>
					}
				</div>
				<div className="modal-footer">
					<button
						className="btn btn-primary"
						data-dismiss="modal"
						disabled={loading}
						onClick={this.handleConfirm}
						type="button"
					>
						{props.confirmBtn}&nbsp;<i className={loadingIcon} />
					</button>
					<button
						className="btn btn-danger"
						data-dismiss="modal"
						disabled={loadingCancel}
						onClick={this.handleCancel}
						type="button"
					>
						{props.cancelBtn ? props.cancelBtn : 'Cancel'}&nbsp;<i className={loadingCancelIcon} />
					</button>
				</div>
			</Modal>
		);
	}

	handleConfirm = e => {
		this.setState({ loading: true });
		this.props.onConfirm(e);
	};

	handleCancel = e => {
		if (this.props.onCancel) {
			this.setState({ loadingCancel: true });
			this.props.onCancel(e);
		} else {
			this.context.closeModal(e)
		}
	};
}

ModalConfirm.contextTypes = {
	closeModal: PropTypes.func
};

export default ModalConfirm;
