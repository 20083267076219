import React, {Component} from 'react';
import {connect} from 'react-redux';

import {VictoryLine} from 'victory';

// NOTE: This file is currently an unused placeholder, because Victory
// components don't work like this. I'll update with new guidance from
// @boygirl of Formidable on Gitter.

class DataVisualizerPlayhead extends Component {
	render() {
		const currentTime = this.props.playerState.currentTime;

		const playheadStyles = {
			data: {
				stroke: "#E64759",
				cursor: "ew-resize",
			}
		};

		return (
			<VictoryLine
				name={this.props.name}
				data={[
					{x: currentTime, y: this.props.maxY},
					{x: currentTime, y: this.props.minY}
				]}
				style={playheadStyles}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		playerState: state.playerState
	};
}

export default connect(mapStateToProps)(DataVisualizerPlayhead);