import {
	CUNAUTH_USER,
	CHART_LOADING,
	CHART_GET,
	CHART_CREATE,
	CHART_UPDATE,
	CHART_DATASET_CREATE,
	CHART_ERROR,
	CHART_DELETE,
	CHART_CLEAR_STATE,
	UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
	data: {}
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return INITIAL_STATE;

		case UNMOUNT_ANALYSIS:
			return INITIAL_STATE;
		
		case CHART_LOADING:
			return {
				...state,
				loading: action.payload
			}
		
		case CHART_GET:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
				message: null
			};
		
		case CHART_CREATE:
			return {
				...state,
				data: action.payload,
				loading: false
			}
		
		case CHART_UPDATE:
			return {
				...state,
				data: action.payload,
				loading: false
			}
		
		case CHART_DATASET_CREATE:
			return {
				...state,
				data: action.payload,
				loading: false
			}
		
		case CHART_CLEAR_STATE:
			return {
				...state,
				data: {},
				loading: false,
				error: null,
				message: null
			}
		
		case CHART_ERROR:
			return {
				...state,
				error: action.payload,
				message: null,
				loading: false
			}

		default:
			return state;
	}
}