import React from 'react';

const ButtonIcon = ({
	btnClass,
	disabled,
	iconClass,
	iconStyle,
	label,
	onClick,
	onDoubleClick,
	style,
	tooltip
}) => (
	<button
		className={label ? 'button-icon ' + btnClass : btnClass }
		data-toggle="tooltip"
		disabled={disabled}
		onClick={onClick}
		onDoubleClick={onDoubleClick}
        style={style}
		title={tooltip}
        >
		<i className={iconClass} style={iconStyle}/>
		{label || ''}
	</button>
);

export default ButtonIcon;
