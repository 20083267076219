import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


import {INVITE_STATUS} from 'Constants/app'

import {
    clearUserMessages,
    closeModal, 
    openModal 
} from 'actions/app';

import {
    capitalize, 
    formatLocalDate
} from 'utils/index'


import {
    fetchMembers,
    updateOrgAutoMemberAtSignup,
} from './actions/index'

import MemberSettingsDropdown from './Members.Settings.Dropdown.jsx';
import ModalMemberInvite from './Modal.Member.Invite.jsx'
import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx'

class Members extends Component {
    constructor(props) {
		super(props);
		
		this.tblColStyle = {
			1: { width: '20%', textAlignLast: 'left' },
			2: { width: '15%', textAlignLast: 'left' },
			3: { width: '15%', textAlignLast: 'left' },
			4: { width: '15%', textAlignLast: 'left' },
			5: { width: '15%', textAlignLast: 'left' },
			6: { width: '5%', textAlignLast: 'center',   overflow: 'initial' },
		}

        this.state = {
            displayModal: false,
            inviteLoading: false,
            sort: {
                key: 'name',
                order: 'asc'
            },
        }
    }

	static contextTypes = {
		router: PropTypes.object,
		history: PropTypes.object
	};

	static childContextTypes = {
		closeModal: PropTypes.func
	};

    // TODO: add invite sending update message here and pass to invite modal
	getChildContext() {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = () => {
		this.props.closeModal();
	};

    componentDidMount() {
        if(undefined === this.props.members) {
            this.props.fetchMembers();
        } 
    }

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.modalOpen === undefined) return;
		this.setState({ displayModal: nextProps.modalOpen.name });
	}

	componentWillUnmount() {
		this.props.closeModal();
		this.props.clearUserMessages();
	}

	render() {
        const {
            autoMemberAtSignup,
            members,     // members structure: { memUserID-1: {}, memUserID-2: {} }
			organizationID,
			userID
        } = this.props; 

        if (undefined === members) return this.renderLoading()
        
        
        let sort = this.state.sort;
        let renderMembers = null;
        if (0 < _.keys(members).length) {
            let orderMembers = [];
            if (sort.key == '') orderMembers = members;
            else orderMembers = _.orderBy(members, [sort.key, 'name'], [sort.order]);

            renderMembers = orderMembers.map((member, i) => (
                <div className="table-row" key={i} >
                    <div className="cell" style={this.tblColStyle[1]}>{member.name}</div>
                    <div className="cell" style={this.tblColStyle[2]}>{member.email}</div>
                    <div className="cell" style={this.tblColStyle[3]}>
                        { this.renderStatus(member) }
                    </div>
                    <div className="cell" style={this.tblColStyle[4]}>
                        { member.lastLogin ? formatLocalDate(member.lastLogin) : null }
					</div>
					<div className="cell" style={this.tblColStyle[5]}>
						{member.datePendingRevoke ? formatLocalDate(member.datePendingRevoke) : null}
					</div>
					<div className="cell" style={this.tblColStyle[6]}>
						<MemberSettingsDropdown member={member} disabled={userID === member.userID} />
					</div>
                </div>
            ))
        }

		return (
			<div className="settings settings-wide">
                {this.state.displayModal === 'modalMemberInvite' ? <ModalMemberInvite /> : null}
                {this.renderPageHeader()}
				<div className="settings-body">
					<div className="row" style={{padding: '0px', marginBottom: '1rem'}}>
						<div className="col-7">
                            {this.renderMessages( renderMembers ? null : 'Your organization does not have any members yet.')}
                            
						</div>
						<div className="col-5" style={{ textAlign: 'right'}}>
                            <div className='text-default' style={{paddingBottom: '10px', fontStyle: 'italic', fontWeight: 400}} >
                                <input type='checkbox' id='autoMemberCheckbox'
                                    defaultChecked={autoMemberAtSignup}
                                    value={autoMemberAtSignup}
                                    onChange={this.toggleAutoMemberSignup}/>{' '} 
                                Auto add people with {organizationID} email addresses
                            </div>
							<button 
                                className="btn btn-primary"
                                onClick={ e => this.onInviteClick (e, 'name') }>Invite People
                            </button>
						</div>
					</div>
                    { renderMembers 
                    ? <div className="row settings-div-row">
						<div className="col-12" style={{padding: '0px'}}>
                            <div className="table" data-sort="table">
                                <div className="table-header">
                                    <div style={this.tblColStyle[1]} className={`cell header ${sort.order}${'name'===sort.key ? 'Sort' : null }`}>
                                        <button className='btn-link' onClick={ e => this.onHeaderClick(e, 'name') }>Name</button>
                                    </div>
                                    <div style={this.tblColStyle[2]} className={`cell header ${sort.order}${'email'===sort.key ? 'Sort' : null }`}>
                                        <button className='btn-link' onClick={ e => this.onHeaderClick(e, 'email') }>Email</button></div>
                                    <div style={this.tblColStyle[3]} className={`cell header ${sort.order}${'status'===sort.key ? 'Sort' : null }`}>
										<button className='btn-link' onClick={e => this.onHeaderClick(e, 'status')}>Status</button></div>
                                    <div style={this.tblColStyle[4]} className={`cell header ${sort.order}${'lastLogin' === sort.key ? 'Sort' : null}`}>
										<button className='btn-link' onClick={e => this.onHeaderClick(e, 'lastLogin')}>Last login</button></div>
									<div style={this.tblColStyle[5]} className={`cell header ${sort.order}${'datePendingRevoke' === sort.key ? 'Sort' : null}`}>
										<button className='btn-link' onClick={e => this.onHeaderClick(e, 'datePendingRevoke') }>Deactivation Date</button></div>
									<div style={this.tblColStyle[6]} className="cell header" />

                                </div>
                                <div className="table-body">
                                    {renderMembers}
                                </div>
                            </div>
						</div>
					</div>
                    : null
                    }
				</div>
			</div>
		);
    }

	renderLoading = () => {
		return (
			<div className="settings">
                {this.renderPageHeader()}
				<div className="settings-body">
					<Loading
						icon="far fa-circle-notch fa-spin"
					/>
				</div>
			</div>
		);
    };

    renderPageHeader = () => { 
        return <div className="settings-head"><h1>Manage Members</h1></div> 
    }

    /** Displays msg if exists, or this.props.message if exists. */
    renderMessages = ( msg ) => {
        let {message, error} = this.props.messages
        if(error) return <Message /> // supress errors - they're displayed in line with each user
        return (
            <Message message={msg || message} textClass={msg ? 'default' : 'success'} dismissable={msg ? false : true} style={{paddingBottom: 0}}/>
        );
    }

    renderStatus = (member) => {
        let date, className='', title=null;
        const {status} = member;
        
        switch (status) {
            case INVITE_STATUS.ERROR_INVITING:
            case INVITE_STATUS.ERROR_DEACTIVATING: {
                title = <i>{capitalize(status)}</i>
                className ='text-danger'
                date='now'
                break;
            }
            case INVITE_STATUS.LOADING: {
                title = <i className='far fa-spinner fa-spin' />
                className ='text-success'
                date='now'
                break;
            }
            case INVITE_STATUS.INVITED: {
                title = <i>Invitation sent</i>
                className ='text-teal'
                date = formatLocalDate(member.dateInvited);
                break;
			} 
			case INVITE_STATUS.DECLINED:
				title = <i>Declined</i>
				className = 'text-gray'
				date = formatLocalDate(member.dateDeclined);
				break;
            case INVITE_STATUS.LEFT: {
                title = <i>Left</i>
                className ='text-gray'
                date = formatLocalDate(member.dateLeft);
                break;
            } 
            case INVITE_STATUS.REVOKED: {
                title = <i>Deactivated</i>
                className ='text-gray'
                date = formatLocalDate(member.dateRevoked);
                break;
            } 
            case INVITE_STATUS.ACCEPTED:
            default: {
                title = this.props.userID === member.userID ? <b>Account Owner</b> : <b>Member</b>
                className ='text-success'
                date = undefined === member.dateAccepted ? '' : formatLocalDate(member.dateAccepted);
                break;
            } 
        }
        return <span>
                    <span className={className}>
                        {null !== title ? title : capitalize(status)}
                    </span>
                <small>{ '' === date ? null : date}</small>
            </span>
    }

    /** Invites and Re-invites member */
    onInviteClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
		this.props.openModal('modalMemberInvite', null, { handleSetStateSending: this.setStateSending});
    }
    
    /** Sorts members table by key */
    onHeaderClick = (e, key ) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ 
            sort: {
                key,
                order: 'asc' === this.state.sort.order ? 'desc' : 'asc'
        }})
    }

    toggleAutoMemberSignup = (e) => {
        const checked = e.target.checked;
        this.props.updateOrgAutoMemberAtSignup(checked)
        .catch( err => {    // reset checkbox on error
            document.getElementById("autoMemberCheckbox").checked = !checked;
        })
    }
}

function mapStateToProps(state) {
    let {members, user} = state;
	return {
        autoMemberAtSignup: state.account.autoMemberAtSignup || false,
        members: undefined === members.data ? undefined : members.data,  // while loading, `undefined`. if no members, `{}`
        messages: state.messages,
        modalOpen: state.modalOpen,
		organizationID: state.account.organizationID,
		userID: user.data.userID
	};
}

const mapDispatchToProps = {
    clearUserMessages,
    closeModal, 
    fetchMembers,
    formatLocalDate,
    openModal,
    updateOrgAutoMemberAtSignup,
};
export default connect(mapStateToProps, mapDispatchToProps)(Members);