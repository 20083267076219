/**
 * Account Settings page - contains tabs for the account pages.
 */
import React, {Component} from 'react';
import {Route, Switch, Link, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import classNames from 'classnames'

import PageSettings from './Layout/page.settings.jsx';
import AccountInfo from './AccountInfo/AccountInfo.jsx';
import Billing from './Billing/Billing.jsx';
import Integrations from './Integrations/Integrations.jsx'
import Members from './Members/Members.jsx';
import Preferences from './Preferences/Preferences.jsx'
import Profile from './Profile.jsx';
import Reports from './Reports/Reports.jsx';

import {clearUserMessages} from 'actions/app'

import {ROUTES} from 'Constants/app';

class Account extends Component {
    
	render() {
		return (
			<PageSettings>
				<div className="col-2 settings-nav">
					{this.renderTabs()}
				</div>
				<div className="col col-8">
					<div className="main">
						<Switch>
							<Route path={ROUTES.PROFILE} component={Profile} />
							<Route exact path={ROUTES.ACCOUNT} component={AccountInfo} />
                            <Route path={ROUTES.PREFERENCES} component={Preferences} />
                            <Route path={ROUTES.MEMBERS} component={Members} />
							<Route path={ROUTES.REPORTS} component={Reports} />
							<Route path={ROUTES.INTEGRATIONS} component={Integrations} />
							<Route path={ROUTES.BILLING} component={Billing} />
							<Redirect to={ROUTES.ACCOUNT} />
						</Switch>
					</div>
				</div>
			</PageSettings>
		);
	}

	renderTabs = () => {
		const {hasLtiOrganizationConsumers, isOrganization, isTeacher} = this.props;

		var tabs = ['Profile', 'Account', 'Preferences', 'Integrations']
        
		// Enterprise user account - dont include Billing tab
		if (isOrganization) tabs.push('Members')

		if (isTeacher || (isOrganization && hasLtiOrganizationConsumers)) tabs.push('Reports');

		// if (isOrganization ) tabs.push('Integrations');
        
        tabs.push('Billing');

		return tabs.map(linkItem => {
			// will check if the selected pages link path is the same as its own linkpath, if it is, will give it a fancy border
			const linksRoute = ROUTES[linkItem.toUpperCase()];
			let linkClasses = classNames ({
                tab: true,
                active: this.props.location.pathname === linksRoute
            })

            let iClasses = classNames({
                far: true,
                'text-gray': true,
                'fa-user': 'Account' === linkItem,
				'fa-credit-card': 'Billing' === linkItem,
				'fa-plug': 'Integrations' === linkItem,
                'fa-users-cog': 'Members' === linkItem,
                'fa-cog': 'Preferences' === linkItem,
				'fa-user-edit': 'Profile' === linkItem,
				'fa-table': 'Reports' === linkItem,
			})
			
			return (
				<Link
					key={linkItem}
					to={linksRoute}
					replace={true}
                    className={linkClasses}>
                        <i className={iClasses} />{linkItem}
				</Link>
			);
		});
	};
}

function mapStateToProps(state) {
	const {account} = state, user = state.user.data
	return {
		isOrganization: account.isOrganization || false,
		hasLtiOrganizationConsumers: undefined === account.ltiOrganizationConsumers ? false : true,  // during initial load, `undefined` if no integrations `{}`
		isTeacher: (account.organizationID && user.ltiConsumers && 
					Object.keys(user.ltiConsumers).some(e => typeof user.ltiConsumers[e].teacherAssignments === 'object')) ? true : false
	};
}

Account = compose(withRouter, connect(mapStateToProps, {clearUserMessages}))(
	Account
);

export default Account;
