import _ from 'lodash';

import {
    _UNSET_VAR,
    ACCOUNT_GET,
    // MEMBER_ACCEPT,
    MEMBER_LEAVE,
    MEMBERS_ERROR
} from 'Constants/actions';

import { request, getBillingDetails } from 'actions/index';
import { messageToast } from 'actions/app';
import {apiConfig} from 'config';

/**
 * Gets Account data from Cloud and Rehydrates
 */
export function getAccount() {
	return function(dispatch) {
		const requestObj = {
			method: 'get',
			url: apiConfig.accountGet,
		};

		return request(requestObj)
        .then(resp => {
            dispatch({
                type: ACCOUNT_GET,
                payload: resp
            });
        })
	};
}


/** ORGANIZATION RELATED MEMBERSHIP FUNCTIONS */

/**
 * Accepts inivitation to organizationID & rehydrates state.account. 
 * @param {String} organizationID of organization to accept member to
 * @returns Promise.resolve() on success; Promise.Reject() on failure.
 */
export function acceptOrganizationInvite( organizationID ) {
	return function(dispatch) {
        // Trigger API
		const requestObj = {
			url: `${apiConfig.memberAccept}${organizationID}`
        };
        return request(requestObj, MEMBERS_ERROR)
			.then(resp => {
            dispatch(getAccount());
            dispatch(getBillingDetails())
            return Promise.resolve();
        })
        .catch( err => {
            return Promise.reject();
        })
	};
}

/**
 * Chnages the active / default organization a person is a member of
 * @param {String} organizationID of organization to become active / default
 */
export function changeActiveOrganization (organizationID) {
	return function (dispatch) {
		// Trigger API
		const requestObj = {
			url: `${apiConfig.changeActiveOrganization}${organizationID}`
		};
		return request(requestObj, MEMBERS_ERROR)
			.then(resp => {
				dispatch(getAccount());
				dispatch(getBillingDetails())
				return Promise.resolve();
			})
			.catch(err => {
				return Promise.reject();
			})
	};
}

/**
 * Declines organization invite either for session or permanently
 * Refreshing page triggers invite to pop up again if NOT permanent decline
 * @param {String} organizationID - ID of organization to decline
 * @param {Boolean} permanentDecline whether or not this decline is permament - ie, trigger  the `organization/decline` API call
 * @returns Promise.resolve() on success; Promise.Reject() on failure.
 */
export function declineOrganizationInvite( organizationID, permanentDecline=false ) {
	return function(dispatch) {
		if (!permanentDecline) {
			return new Promise((resolve) => setTimeout(resolve, 1000))
		}
		else {
			const requestObj = {
				url: `${apiConfig.memberDecline}${organizationID}`
			};

			return request(requestObj, MEMBERS_ERROR)
				.then(resp => {
					// NOTE: no need to dispatch a MEMBER_DECLINE action - getAccount updates the state
					dispatch(getAccount());
					return Promise.resolve();
				})
				.catch(err => {
					return Promise.reject();
				});
		}
    }
}

/**
 * Used to leaves organsationID, rehydrating state.account, setting state.iser.organizationID to ''
 * @param {String} organizationID 
 * @returns Promise.resolve() on success; Promise.Reject() on failure.
 */
export function leaveOrganization( organizationID ) {
    return function(dispatch) {

		const requestObj = {
            url: `${apiConfig.memberLeave}${organizationID}`
		};

        return request(requestObj, MEMBERS_ERROR)
        .then(resp => {
            dispatch({
                type: MEMBER_LEAVE
			})
			// this.props.messageToast(
			// 	MESSAGE_ADD, 
			// 	<span>You've successfully left the <b>{organizationID}</b> Dreamaker.io organization.</span>,
			// 	4000
			// );
            dispatch(getAccount());
            return Promise.resolve();
        })
        .catch( err => {
            // Note that because MEMBER_LEAVE is not triggered, an error message is never displayed
            // We like it this way. 
            return Promise.reject();
        });
	};
}

