/**
 * NOTE:
 * * User messages are shown in Integrations.jsx
 */

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IMAGES, ROUTES } from 'Constants/app';
const {ZOOM_LOGO} = IMAGES;

import {
	closeModal,
	openModal
} from 'actions/app';

import {
	zoomInitiateAuthorization,
	zoomRevokeAuthorization,
} from 'components/Landing/actions/zoom'

import { Loading } from 'Widgets/Loading.jsx';
import ModalConfirm from 'Widgets/Modal.Confirm.jsx';
import ModalInfo from 'Widgets/Modal.Info.jsx';

class IntegrationZoom extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayModal: false,
			loading: false,
		};
	}

	static getDerivedStateFromProps (nextProps, prevState) {
		if ( //nextProps.modalOpen === undefined ||
			 nextProps.modalOpen === prevState.modalOpen )
			return null;
		return { displayModal: nextProps.modalOpen.name }
	}

	render () {
		const { loading } = this.state;	
		let authorizeVars = ['Connect', 'primary']
		if (this.props.zoomIsAuthorized) {
			authorizeVars = ['Deauthorize', 'danger']
		}

		return (
			<div>
				{ this.renderModal() }
				<div className="row settings-integrations" >
					<div className='col-10 company'>
						<img className='company-logo' src={ZOOM_LOGO} />Zoom
					</div>
					<div className='col-2' style={{ textAlign: 'right' }}>
						<button
							onClick={e => { this.handleInitiateRevokeAuthorizationClick(authorizeVars[0]) }}
							className={`btn btn-${authorizeVars[1]}`}
							disabled={loading}
						>
							{authorizeVars[0]}
							<i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
						</button>
					</div>
				</div>
			</div>
		);

		// card return (
		// 	<div className="card text-center">
		// 		<img className='card-img-top logo' src={ZOOM_LOGO} />
		// 		<div className="card-body">
		// 			<h4 className="card-title">Zoom</h4>
		// 			<p className="card-text">Import video and audio recordings of Zoom meetings directly into Dreamaker.io.</p>
		// 			<div id="help-text" className={showInfo ? 'help-text-shown' : 'help-text-hidden'} >
		// 				<ul>
		// 					<li>A Zoom account</li>
		// 					<li>Your Zoom account administrator must&nbsp;<a target="_blank" rel="noopener noreferrer" href="https://marketplace.zoom.us/apps/BF4eht18S3a0KTLiKM3P0A">pre-approve Calendly in the Zoom Marketplace</a></li></ul>
		// 			</div>
		// 			<button
		// 				onClick={e => this.handleInstallLtiIntegration('zoom')}
		// 				className={`btn btn-primary`}
		// 				disabled={loading}>
		// 				{'Add'}<i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
		// 			</button>
		// 		</div>
		// 	</div>
		// )
	}


	renderSectionHeader = (header) => {
		return 	(
			<div className="row">
				<div className="col-12 settings-align-center" style={{ paddingLeft: '0px !important' }}>
					<h5>{header}</h5>
				</div>
			</div>
		)
	}

	handleInitiateRevokeAuthorizationClick = (action) => {
		// Display Modal
		this.props.openModal('modalIntegrationZoom', null, { action })
		
		//If Connect, all initiateAuth while modal is being displayed
		'Connect' === action
			? this.triggerApiCall(this.props.zoomInitiateAuthorization, action)
			: null
	}

	triggerApiCall = (api, action) => {
		this.setState({ loading: true })
		api()
			.then(resp => {
				this.setState({
						messages:
						{
							error: false,
							message: 'Connect' === action ? 'Successfully connected' : 'Successfully revoked'
						}, loading: false
					})
			})
			.catch(err => {
				this.setState({ message: { error: true, message: err }, loading: false })
			})
		'Connect' === action ? null : this.props.closeModal();
	}

	renderModal = (e) => {
		const { name, data } = this.props.modalOpen
		if ('modalIntegrationZoom' === name) {
			if ('Connect' === data.action) {
				return (
					<ModalInfo
						title="Redirecting to Zoom..."
						info="You're being redirected to Zoom..."
					/>
				)
			}

			// if not Connect, assume Revoke

			return (
				<ModalConfirm
					onConfirm={() => { this.triggerApiCall(this.props.zoomRevokeAuthorization, 'Deauthorize') }}
					title=""
					confirmBtn="Deauthorize"
					confirmPhrase="Are you sure you would like to deauthorize Zoom access"
				>
					<div>
						<a href='https://zoom.us/marketplace_developer_agreement' target="_blank">{`Zoom's Marketplace Developer Agreement`}</a>{` requires Dreamaker.io to delete all user data shared with Dreamaker.io by Zoom if Zoom access is deauthorized. `}
						{`This data includes any audio and video files imported from Zoom, as well as all associated analysis.`}<br /><br />
						
						<Link disabled={this.state.loading} to={`${ROUTES.ZOOM}/${this.props.oauthUserID}`} style={{ fontSize: '.9rem' }}>{`View media files imported from Zoom`}</Link> <br /><br />
						{`After proceding with deauthorization, you will be given 9 days to give Dreamaker.io express permission to retain this data.`}<br /><br />
						{`Would you like to deauthorize Zoom access?`}
					</div>
				</ModalConfirm>
			)
		}
		return;
	};

}

function mapStateToProps (state) {
	const zoom = state.user.data.oauth.zoom || {}
	return {
		zoomIsAuthorized: zoom.isAuthorized || false,
		oauthUserID: zoom.oauthUserID,
		modalOpen: state.modalOpen,
	};
}

export default connect(
	mapStateToProps,
	{
		closeModal,
		openModal,
		zoomInitiateAuthorization,
		zoomRevokeAuthorization,
	}
)(IntegrationZoom);