import React from 'react'
import {Link} from 'react-router-dom';
import {ROUTES} from 'Constants/app';
import ButtonIcon from './../Widgets/ButtonIcon.jsx';


const ButtonHome = props => {
    return (
		<Link
			className='btn btn-outline-secondary btn-back'
			to={`${ROUTES.LANDING}/0`}
			tooltip='Home'>
			<i className='fas fa-home' />
		</Link>
    )
}

export default ButtonHome;