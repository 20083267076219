import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import _ from 'lodash';

import TagButton from './TagButton.jsx';
import { createDashboardTag } from './actions';

class DashboardsMiddlebarUserTags extends Component {
    
	render() {
		// renders an empty tag to be used in place of no tags
		const emptyTag = () =>
			<button
				name="emptyTag"
				onClick={this.handleCreateTag}
				className="btn btn-secondary btn-empty">
				Create Tag
			</button>;
		return (
			<div className="dbedit-content-alltags-div">
				{emptyTag()}
				{this.renderTags()}
			</div>
		);
    }

	renderTags = () => {
		const { allTags, account } = this.props;
		// maps over all the tags
		return Object.keys(allTags).map(tag => {
			const tagObject = allTags[tag];
			// if the type is not button, we don't want to return it at all
			if (tagObject.type !== 'BUTTON') return null;
			// if the tags owner is not the user, we will not render it.
			if (tagObject.ownerID !== account.userID) return null;
			return (
				<TagButton
					selectedDashboard={this.props.selectedDashboard}
					// All tags - tag, should be able to be dragged, but not dropped on.
					// tagLocation is where this tag is located. I.e. either in the allTags component, or the dashboardsTags component
					dndProps={{
						canBeDragged: true,
						canBeDropped: false,
						tagLocation: 'allTags'
					}}
					tagID={tagObject.dashboardTagID}
					handleSelectTag={this.props.handleSelectTag}
					key={tagObject.dashboardTagID}
					name={tagObject.label}
					className={`btn btn-${tagObject.color}`}
				/>
			);
		});
    };

	handleCreateTag = () => {
		// createing the newTag object to pass in
		const newTagData = {
			dashboardTagID: uuidV4(),
			color: 'secondary',
			label: 'New Tag',
			type: 'BUTTON'
		};
		// creating the dashboard tag
		this.props
			.createDashboardTag(newTagData)
			// response will return either true for success, or false for failure
			.then(
				response =>
					// selecting the dashboard tag, after the creation has been completed successfully on api
					response &&
					this.props.handleSelectTag(newTagData.dashboardTagID)
			);
	};
}

function mapStateToProps(state) {
	return {
		allTags: state.userDashboardTags.data,
		account: state.account
	};
}

export default connect(mapStateToProps, { createDashboardTag })(DashboardsMiddlebarUserTags);
