// Used on an array, takes an item and moves it from it's current position, to the new position.
// Then moves the other array items up an index.
// Need to pass in two arguments. The current index (old_index), and where you want the new_index.
Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        var k = new_index - this.length;
        while ((k--) + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this; // for testing purposes
};