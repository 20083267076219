import { dm_print } from 'utils/';
import _ from 'lodash';
import classNames from 'classnames';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { I18n } from '@aws-amplify/core';

import Bottombar from './Bottombar.jsx';
import MediaPlayer from './MediaPlayer/MediaPlayer.jsx';
import ModalInfo from 'Widgets/Modal.Info.jsx';
import PageAnalysis from '../Layout/page.analysis.jsx';
import Tab from './Tab.jsx';
import TabnavItem from './Tabnav.item.jsx';

import DatasetsModal from './DataVisualizer/DataVisualizer.Datasets.Modal.jsx';
import ModalInvite from 'Modals/Modal.Invite.jsx';
import ModalDashboardSelector from 'Modals/Modal.Dashboard.Selector.jsx';
import ModalFileAssets from './FileAssets/Modal.File.Assets.jsx';
import ModalMarkerSelector from 'Modals/Modal.Marker.Selector.jsx';
import TranscribeModal from './Transcribe/Transcribe.Modal.jsx';
import VTTModal from './VTT/VTT.Modal.jsx';

import Message from 'Widgets/Message.jsx';
import { Loading } from 'Widgets/Loading.jsx';

import { loadAnalysis, resetAnalysis } from './actions/';
import { closeModal, dismissMessage, setBottombarHeight, setSidebarWidth } from 'actions/app'

import { INVITE_TYPES, ROUTES, VISIBILITY } from 'Constants/app';

// Default values for sidebar (where in analysis appears) and bottombar (wherein dashboard appears)
const 
    sidebarWidth = 480,
    sidebarWidthMin = 250,
    sidebarWidthMax = 900,
    bottombarHeightMin = 230,
    bottombarHeightMax = 700;

// Default values for media container margin size
/**
 * live defaults max 
 *      432x640 (:)     = 0.675 (HLS 1M)
 *      640x960 (:)     = 0.666
 *      768x1024 (3:4)    = 0.75
 * Youtube defaults 
 *      380x640 (3:4)   = 0.75
 *      480x854 (9:16)  = 0.5625
 * **/
const 
    mediaVPad = 65,
    mediaDimensions = 0.75, // 768x1024 (3:4)    = 0.75
    playerHeight = 768,
    playerWidth = 1024;


class Analysis extends Component {
	constructor(props) {
		super(props);

		if (!props.match.params.itemAnalysisID) this.props.history.push(`${ROUTES.LANDING}/0`);
		
		// Initialise dimensions of media container and bars based on browser window dimension
        const 
            mediaHeight = window.innerHeight - playerHeight - mediaVPad,
			mediaWidth = mediaHeight / mediaDimensions,
            mediaMargin = (window.innerWidth - sidebarWidth - mediaWidth) / 2,
            bottombarHeight = bottombarHeightMin;

		this.state = {
			isResizingSidebar: false,
			isResizingVideo: false,

            sidebarWidth,
			bottombarHeight,

			mediaHeight,
			mediaMargin,

			// Initialize video dimensions to media dimensions until videoDimensions are received (componentWillReceiveProps)
			videoDimensions: mediaHeight / mediaWidth,

			activeTabIndex: 0,
			displayModal: false,
			playerPaused: true
		};
		this.props.setSidebarWidth(sidebarWidth);
	}

	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext() {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
	};

	// UNSAFE_componentWillMount() {
    //     if (!this.props.match.params.itemAnalysisID) this.props.history.push(`${ROUTES.LANDING}/0`);
	// }

	componentDidMount() {
		this.props.loadAnalysis(this.props.match.params.itemAnalysisID).catch(err => {
			this.props.history.push(`${ROUTES.LANDING}/0`);
        });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.modalOpen !== undefined)
			this.setState({ displayModal: nextProps.modalOpen.name });

		// Get acutal video dimensions & base media dimensions on this
		if (this.props.videoWidth != nextProps.videoWidth) {
            const { videoWidth, videoHeight } = nextProps;
            const videoDimensions = videoHeight / videoWidth;
            const useVideoHeight = Math.max(videoHeight, playerHeight)

            // Set media dimensions & size to video dimensions & size
            let offsetHeight = window.innerHeight - useVideoHeight - mediaVPad - 3;
			let bottombarHeight = Math.min(
                bottombarHeightMax,
				Math.max(bottombarHeightMin, offsetHeight)
            );
            let mediaHeight = window.innerHeight - bottombarHeight - mediaVPad;

            let mediaWidth = Math.min(
                mediaHeight / videoDimensions,
                document.getElementById('dragBar').clientWidth // + 30
            );

            let mediaMargin = (document.getElementById('dragBar').clientWidth - mediaWidth) / 2;

			this.setState({
				videoDimensions,
                mediaMargin,
                bottombarHeight,
                mediaHeight
            });
            this.props.setBottombarHeight(bottombarHeight);
		}
    }

	componentWillUnmount() {
        this.props.resetAnalysis();
        this.props.closeModal();
        this.props.dismissMessage();
	}

	render() {
		const {
            currentUserID,
            itemLoading,
			itemMessage,
			itemError,
		} = this.props;

		/** Need to have this.props.item check here - patch for there is a quirk when using browser back & forward 
        * buttons which result in itemLoading being bypassed **/
		if (itemLoading || !this.props.item) {
			return (
				<PageAnalysis>
                    <div className='workspace'><Loading /></div>
				</PageAnalysis>
			);
		}

		let { item, itemAnalysis, dashboards, item:{locked} } = this.props;

		// ITEM OWNERSHIP: Establish item ownership, itemAnalysis ownership & variables dependant in this
		const isItemAnalysisOwner = currentUserID === itemAnalysis.ownerID;
		const isItemOwner = currentUserID === item.ownerID;

		// ITEM ROLE, undefined if not set
		const role =
			!isItemAnalysisOwner
				? itemAnalysis.collaborators.users[currentUserID].role 
				: undefined

		// ANALYSIS VISIBILITY
		const analysisVisibility = 
			isItemAnalysisOwner 
			? (undefined === itemAnalysis.analysisVisibility)
				? VISIBILITY.COLLABORATORS
				: itemAnalysis.analysisVisibility.mode
			: (undefined === itemAnalysis.collaborators.users[currentUserID].analysisVisibility)
				? VISIBILITY.COLLABORATORS
				: itemAnalysis.collaborators.users[currentUserID].analysisVisibility.mode
				
        // TODO: Tmp addition to prevent transcribing of shared items 
        const isShare = item.shares.users[currentUserID] !== undefined || false;
		// hasCollaborators - being passed down to define whether or not collaborators filter, avatars, initials and names appear in event tags
        // const hasCollaborators = !_.isEmpty(itemAnalysis.collaborators.users)


		let tabNavLinks = ['Analysis', 'Playlist']; //'Tags',
		let contentStyles = {
			marginRight: this.state.sidebarWidth
		};

		let sidebarStyles = {
			width: this.state.sidebarWidth
		};

		// used for resizing the sidebar
		let sidebarClasses = classNames({
			'workspace-sidebar': true,
			'workspace-sidebar-resizing': this.state.isResizingSidebar
        });
        
		// used for resizing the bottomabar
		let bottombarClasses = classNames({
			'workspace-bottombar-handle': true,
			'workspace-bottombar-handle-resizing': this.state.isResizingVideo
        });
        
        // used to responsively resize media container (MediaPlayer)
        let mediaContainerStyle = {
			margin: `auto ${this.state.mediaMargin}px`, // Responsiveness is maintained with width margin
        }

		return (
			<PageAnalysis goBack={this.props.history.goBack} >
				{this.renderModal()}
				
				<div
					className="workspace"
					onMouseMove={this.handleMouseMove}
					onMouseUp={this.handleMouseUp}>
					<div className="workspace-content" style={contentStyles}>
						{this.renderMessage(itemMessage, itemError)}
						{this.renderMessages()}

						{/** Media Player **/}
						<div
							className="container-fluid workspace-mediaContainer"
							style={mediaContainerStyle}>
							<MediaPlayer
								isItemOwner={isItemOwner}
								play={this.play}
								pause={this.pause}
								playerPaused={this.state.playerPaused}
							/>
						</div>

                            {/** Bottombar & Dashboards **/}
					</div>

					{/** Bottombar & Dashboards **/}
					<div
						id="bottombarContainer" // NB Used to calc width for mediaContainer
						className="container-fluid workspace-bottombar"
						style={{height: this.state.bottombarHeight}}>
						<div style={{marginRight: this.state.sidebarWidth}}>
							<div
								id="dragBar" // NB Used to calc width for mediaContainer
								className={bottombarClasses}
								draggable="draggable"
								onMouseDown={() => this.handleMouseDown('Video')}
							/>
							<Bottombar	
								history={this.props.history}
								location={this.props.location}
								isItemAnalysisOwner={isItemAnalysisOwner}
								isItemOwner={isItemOwner}
								isResizingSidebar={this.state.isResizingSidebar}
								isResizingVideo={this.state.isResizingVideo}
								isShare={isShare}
								locked={locked}
								pause={this.pause}
								playerPaused={this.state.playerPaused}
								play={this.play}
								role={role}
							/>
						</div>
					</div>
					
					{/** Sidebar **/}
					<div className={sidebarClasses} style={sidebarStyles}>
						<div
							className="workspace-sidebar-handle"
							draggable="draggable"
							onMouseDown={() => this.handleMouseDown('Sidebar')}
						/>
						<div className="workspace-sidebar-head">
							<ul className="nav nav-tabs">
								{_.map(tabNavLinks, (tabNavItem, i) => (
									<TabnavItem
										active={this.state.activeTabIndex === i}
										key={i + 'tablink'}
										index={i}
										text={I18n.get(tabNavItem)}
										onItemClick={this.handleTabNavItemClick}
									/>
								))}
							</ul>
						</div>
						<div className="workspace-sidebar-body">
							<div className="tab-content workspace-tabs-content">
								<Tab
                                    id='aTab0'
                                    tabIndex={0}
									analysisVisibility={analysisVisibility}
									active={this.state.activeTabIndex}
									isItemAnalysisOwner={isItemAnalysisOwner}
									// hasCollaborators={hasCollaborators}
								/>
								<Tab
                                    id='aTab1'
                                    tabIndex={1}
									analysisVisibility={analysisVisibility}
									active={this.state.activeTabIndex}
									isItemAnalysisOwner={isItemAnalysisOwner}
									// hasCollaborators={hasCollaborators}
								/>
							</div>
						</div>
					</div>
				</div>
			</PageAnalysis>
		);
	}

	handleMouseUp = e => {
		this.resize(false, e);
	};

	handleMouseMove = e => {
		this.resize(true, e);
	};

	handleMouseDown = name => {
		// in order to create reusability, we need to pass down the name
		this.setState({
			[`isResizing${name}`]: true
		});
	};

	resize = (isMoving, e) => {
        // return if not resizing
        if( !this.state.isResizingSidebar && !this.state.isResizingVideo ) return
        
        // prevent page text selection when dragging
        if (document.selection) {
            document.selection.empty()
        } else {
            window.getSelection().removeAllRanges()
		}
		
        // resizing
		if (this.state.isResizingSidebar) {
			let offsetRight = window.innerWidth - e.pageX;
			let sidebarWidth = Math.min(sidebarWidthMax, Math.max(sidebarWidthMin, offsetRight));

			let mediaWidth = Math.min(
				this.state.mediaHeight / this.state.videoDimensions,
				document.getElementById('dragBar').clientWidth
			);
			let mediaMargin = (document.getElementById('dragBar').clientWidth - mediaWidth) / 2;

			this.setState({
				isResizingSidebar: isMoving,
				sidebarWidth,
				mediaMargin
			});
			this.props.setSidebarWidth(sidebarWidth);
		} else if (this.state.isResizingVideo) {
			let offsetHeight = window.innerHeight - e.pageY;
			let bottombarHeight = Math.min(
				bottombarHeightMax,
				Math.max(bottombarHeightMin, offsetHeight)
			);
			let mediaHeight = window.innerHeight - bottombarHeight - mediaVPad;

			let mediaWidth = Math.min(
				mediaHeight / this.state.videoDimensions,
				document.getElementById('dragBar').clientWidth // + 30
			);

			let mediaMargin = (document.getElementById('dragBar').clientWidth - mediaWidth) / 2;

			this.setState({
				isResizingVideo: isMoving,
				mediaMargin,
				bottombarHeight,
				mediaHeight
            });
            this.props.setBottombarHeight(bottombarHeight);
		}
    };

	handleTabNavItemClick = ix => {
		this.setState({
			activeTabIndex: ix
		});
	};

	renderMessage(message, error) {
        //TODO: Spacing & placing of these essages is fuct
		if (message) {
			return (
                <Message message={message} location='analysis' textClass='success' style={{padding: '.3rem'}}/>
			);
		} else if (error) {
			return <Message message={error} location="analysis" style={{padding: '.3rem'}}/>;
		}
    }
    
    renderMessages = () => {
        const {message, error} = this.props.messages
        if ("" !== message && !this.state.displayModal)
            return (
                <Message message={message} textClass={error ? 'danger' : 'success'} style={{padding: '.3rem'}} />
            );
	}
	
	/** Renders a modal if present */
	renderModal = () => {
		const data = this.props.modalOpen.data || null
		switch(this.state.displayModal) {
			case 'datasetsModal': {
				const {colorScale, createChart, createDataset, updateChart } = data
				return <DatasetsModal colorScale={colorScale} createChart={createChart} createDataset={createDataset} updateChart={updateChart} />
			}

			case 'modalConfidentialityNotice': return <ModalInfo title={data.title} info={data.info} /> 
			case 'modalDashboardSelector': return <ModalDashboardSelector />;
			case 'modalFileAssets': return <ModalFileAssets />;
			case 'modalInvite': return <ModalInvite inviteType={INVITE_TYPES.COLLABORATE } />;
			case 'modalMarkerSelector': return <ModalMarkerSelector />
			case 'modalSend': return <ModalInvite inviteType={INVITE_TYPES.SEND} />
			case 'transcribeModal': return <TranscribeModal />;
			case 'VTTModal': return <VTTModal />
			
			default: return null;			
		}


	}

	// used to set the state of the player to paused.
	pause = () => {
		this.setState({ playerPaused: true });
	};

	// used to set the state of the player to play.
	play = () => {
		this.setState({ playerPaused: false });
	};
}

function mapStateToProps(state) {
    const folderItems = state.folderItems;
	return {
        currentUserID:      state.user.data.userID,
        
        itemLoading:        folderItems.loading,
		itemError:          folderItems.error,
        itemMessage:        folderItems.message,
        item:               folderItems.selectedItem,
        itemAnalysis:       folderItems.selectedItemAnalysis,

        currentUserID:      state.user.data.userID,

        messages:           state.messages,
        modalOpen:          state.modalOpen,
        
        bottombarHeight:    state.app.bottombarHeight,
		videoWidth:         state.playerState.videoWidth,
		videoHeight:        state.playerState.videoHeight
	};
}

const mapDispatchToProps = {
    closeModal,
    dismissMessage,
    loadAnalysis,
    resetAnalysis,
	setBottombarHeight,
	setSidebarWidth
};

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);

//TAB2
// <TabTags active={this.state.activeTabIndex} />

// <div className="analysis-content">
//     <div className="container">
//         <div className="row">
//             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-centered" >
//                 <MediaPlayer itemID={item.itemID} />
//             </div>
//         </div>

//     </div>
// </div>


// Playlist Tab
{/* <TabPlaylist
    active={this.state.activeTabIndex}
    currentUserID={currentUserID}
    isItemAnalysisOwner={isItemAnalysisOwner}
    confidential={confidential}
    // hasCollaborators={hasCollaborators}
/> */}
