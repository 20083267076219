// This file helps us pass an issue where there is a complaint
// about having more than HTML5Backend's being used at once.

import HTML5Backend from 'react-dnd-html5-backend';

import {DragDropContext} from 'react-dnd';
export default DragDropContext(HTML5Backend);

// dnd-react upgrade - below throws error Uncaught TypeError: Object(...) is not a function
// import {DndContext} from 'react-dnd';
// export default DndContext(HTML5Backend);