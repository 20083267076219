import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { openModal } from 'actions/app';

class Page extends Component {
	render () {
		const { pageClasses='' } = this.props;

		// fixes silly bug breaking thing
		// if (!selectedItem) return null;

		return (
			<div className={`page ${pageClasses}`} >
				{this.props.children}
			</div>
		);
	}
}

function mapStateToProps (state) {
	return {};
}

export default compose(
	withRouter,
	connect(mapStateToProps, { openModal })
)(Page);
