import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import { openModal, closeModal } from 'actions/app';

import { deleteDashboard, deleteDashboardTag } from './actions/'

import PageLanding from '../Layout/page.landing.jsx';
import DashboardsMiddlebar from './Dashboards.Middlebar.jsx';
import DashboardsLeftSidebar from './Dashboards.LeftSidebar.jsx';
import DashboardsRightSidebarTagInfo from './Dashboards.RightSidebar.TagInfo.jsx';

import ModalConfirm from 'Widgets/Modal.Confirm.jsx';
import DashboardMarkerLink from 'Widgets/DashboardMarkerLink.jsx';

class Dashboards extends Component {
	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext () {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
	};

	constructor(props) {
		super(props);

		this.state = {
			selectedDashboard: null,
			selectedTag: null,
			displayModal: false
		};
	}

	componentDidMount () {
		// slices the '?' off the front of the search term.
		const searchTerm = this.props.location.search.slice(1);
		// if the search term (which is just the dashboards ID) is located inside of userDashboards,
		// we will set the search terms id to the selected dashboard
		if (this.props.userDashboards.hasOwnProperty(searchTerm)) {
			this.handleSelectDashboard(searchTerm);
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		// sets the displayModals name
		this.setState({ displayModal: nextProps.modalOpen.name });
		// allows state of selectedDashboard to update if there are new props
		if (this.state.selectedDashboard !== null) {
			const selectedDashboardID = this.state.selectedDashboard.dashboardID;
			const nextDashboard = nextProps.userDashboards[selectedDashboardID];
			// if there is a next dashboard, and that dashboard is not equal to the current dashboard,
			// we allow the selected dashboards state to update
			if (nextDashboard !== undefined && this.state.selectedDashboard !== nextDashboard) {
				this.setState({ selectedDashboard: nextDashboard });
			}
		}
		// allows state of selectedTag to update if there are new props
		if (this.state.selectedTag !== null) {
			const selectedTagID = this.state.selectedTag.dashboardTagID;
			const nextTag = nextProps.userTags[selectedTagID];
			// if there is a next tag, and that tag is not equal to the current tag,
			// we allow the selected tags state to update
			if (nextTag !== undefined && this.state.selectedTag !== nextTag) {
				this.setState({ selectedTag: nextTag });
			}
		}
	}

	render () {
		return (
			<PageLanding renderBackButton={true} >
				<div className="dbedit">
					<div className="dbedit-landing-sidebar-layout">
						<DashboardMarkerLink />
						<DashboardsLeftSidebar
							selectedDashboard={this.state.selectedDashboard}
							handleSelectDashboard={this.handleSelectDashboard}
						/>
					</div>
					<DashboardsMiddlebar
						handleSelectTag={this.handleSelectTag}
						selectedDashboard={this.state.selectedDashboard}
					/>
					<div className="dbedit-landing-sidebar-layout">
						<h6>{I18n.get('Tag Info')} </h6>
						<DashboardsRightSidebarTagInfo
							handleOpenDeleteModal={this.handleOpenDeleteModal}
							handleSelectTag={this.handleSelectTag}
							selectedTag={this.state.selectedTag}
						/>
					</div>
				</div>
				{this.renderDeleteConfirmModal()}

			</PageLanding>
		);
	}

	/**
    * Deletes dashboards
    * @param {Bool} Whether or not to confirm before deleting (in the case there are dependencies)
    */
	handleDeleteDashboard = (deleteDependency) => {
		const { dashboardID } = this.state.selectedDashboard;

		// We start off with a false
		this.props.deleteDashboard(dashboardID, deleteDependency)
			.then(resp => {
				this.props.closeModal() // close open modal to refresh contents

				// If it returns with a dependency, request confirmation, otherwise deletion successful
				resp.dependency
					? this.props.openModal('dashboard', dashboardID, { ...resp, deleteDependency: true })
					: this.handleSelectDashboard(null)
			})
			.catch(err => {
				this.handleSelectDashboard(null) // AND TODO: display success
			})
	};

	// handles the deletetion of a tag
	handleDeleteTag = deleteDependency => {
		const { dashboardTagID } = this.state.selectedTag;

		this.props.deleteDashboardTag(dashboardTagID, deleteDependency)
			.then(resp => {
				this.props.closeModal()  // close open modal to refresh contents
				// If it returns with a dependency, request confirmation, otherwise deletion successful
				resp.dependency
					? this.props.openModal('tag', dashboardTagID, { ...resp, deleteDependency: true })
					: this.handleSelectTag(null)
			})
			.catch(err => {
				this.handleSelectTag(null)
			})
	};

	// Selects current dashboard, IE - sets state.selectedDahsboard
	handleSelectDashboard = dashboardID => {
		if (dashboardID === null) return this.setState({ selectedDashboard: null });
		const selectedDashboard = this.props.userDashboards[dashboardID];
		this.setState({ selectedDashboard });
	};

	// Selects current tag, IE - sets state.selectedTag
	handleSelectTag = tagID => {
		if (tagID === null) return this.setState({ selectedTag: null });
		const selectedTag = this.props.userTags[tagID];
		this.setState({ selectedTag });
	};

	// Renders the delete confirmation modal for deleting dashboards & tags
	renderDeleteConfirmModal = () => {

		if (false === this.props.modalOpen) return

		const { selectedDashboard, selectedTag } = this.state;
		const { name, xID, data } = this.props.modalOpen;

		const deleteDependency = data.deleteDependency

		// DASHBOARDS
		if ('dashboard' === name && selectedDashboard.dashboardID === xID) {
			return (
				<ModalConfirm
					onConfirm={() => this.handleDeleteDashboard(deleteDependency)}
					title={`Delete ${name} ${selectedDashboard.title}`}
					//subTitle='Delete Confirmation'
					cancelBtn="Cancel"
					confirmBtn="Delete"
				>
					{data.dependency
						? this.renderDependencies(name, data.dependency)
						: <strong>Are you sure you would like to delete this {name}?</strong>
					}
					<p><i>Only the dashboard will be deleted. Tags and tagged events will <b>not</b> be deleted.</i></p>
				</ModalConfirm>
			)
		}

		// TAGS
		var cancelBtn = "Cancel",
			confirmBtn = "Delete",
			onConfirm = this.handleDeleteTag,
			title = `Delete ${name} ${selectedTag.label}`,
			confirmationMsg = <strong>Are you sure you would like to delete this {name}?</strong>;

		if (deleteDependency && data.dependency.hasOwnProperty('items') && !data.dependency.hasOwnProperty('dashboards')) {
			cancelBtn = "Cancel",
				confirmBtn = "Ok",
				onConfirm = this.props.closeModal,
				title = `Delete ${name} ${selectedTag.label}`,
				confirmationMsg = <strong>Delete restricted</strong>;
		}
		if ('tag' === name && selectedTag.dashboardTagID === xID) {
			return (
				<ModalConfirm
					cancelBtn={cancelBtn}
					confirmBtn={confirmBtn}
					onConfirm={() => onConfirm(deleteDependency)}
					title={title}
				>
					{data.dependency
						? this.renderDependencies(name, data.dependency)
						: confirmationMsg
					}
					<p><i>Tags cannot be deleted if assigned to tagged events in projects.</i></p>
				</ModalConfirm>
			)
		}

	}

	// Renders all delete dependencies
	renderDependencies = (name, dependencies) => {
		return (
			<div>
				<strong>This {name} is currently in use.</strong>
				{dependencies.dashboards && <p>
					Deleting it will remove it from these Dashboards:
               {this.renderDependency(dependencies.dashboards, 'dashboardID')}
				</p>}
				{dependencies.templates && <p>
					Deleting it will remove it from these templates:
               {this.renderDependency(dependencies.templates, 'templateID')}
				</p>}
				{dependencies.items && <p>
					{'dashboard' === name
						? 'It will be deleted from these projects:'
						: 'To complete deletion, delete it from these projects:'
					}
					{this.renderDependency(dependencies.items, 'itemAnalysisID')}
				</p>}
			</div>
		)
	}

	// renders single dependancy
	renderDependency = (dependency, key) => {
		if ('dashboardID' === key) {
			var list = dependency.map(item => {
				return <li key={item}>{this.props.userDashboards[item].title}</li>
			})
		} else {
			var list = dependency.map(item => {
				return <li key={item[key]}>{item.title}</li>
			})
		}

		return list;
	}
}

function mapStateToProps (state) {
	return {
		userDashboards: state.userDashboards.data,
		userTags: state.userDashboardTags.data,
		modalOpen: state.modalOpen
	};
}

const mapDispatchToProps = {
	deleteDashboard,
	deleteDashboardTag,
	openModal,
	closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
