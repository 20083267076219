import classNames from 'classnames';
import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {formatLocalDate} from 'utils/';

import {dismissMessage} from 'actions/app';
import {getLtiGrade, passbackLtiGrade} from './actions'

import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx'
import Numbers from 'Widgets/Inputs/Numbers.jsx'

class Grade extends Component {
    constructor(props) {
		super(props);
		
        this.state = {
			loading: false,
			error: false,
			message: '',
			ltiGrade: this.props.ltiGrade
        };
    }

    componentDidMount() {
        if(undefined === this.props.ltiGrade) {
			this.props.getLtiGrade()
			.then( resp => {})
 			.catch( err => {
				this.setState({
					error: true,
					message: err, 
				})
			});
        } 
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Ensure updated preferences are set
		if(this.props.ltiGrade !== nextProps.ltiGrade) {
			this.setState({
				loading: false,
				ltiGrade: nextProps.ltiGrade
			})
		}
	}
	
	render() {
		const {ltiDateAttached, name} = this.props.itemAnalysis;
		const {ltiGrade, loading} = this.state;
		const iconStyle = { fontSize: '1rem', color: '#5bc0de', fontWeight: 600, marginLeft: '.9rem', lineHeight: '25px', cursor: 'pointer', marginBottom: '.1rem'}
		return (
			<div className="col-12">
				<div className="grade">
					<div>
						{this.renderMessages()}
						<div style={{display: 'flex', 'justifyContent': 'space-between'}}>
							<div>Student: <span style={{color: '#fff', fontWeight: '300'}}>{name}</span></div>
							{ltiDateAttached
							? <div>Submitted: <span style={{color: '#fff', fontWeight: '300'}}>{formatLocalDate(ltiDateAttached)}</span></div>
							: null
							}
						</div>
						<div>Grade:{' '}
							{ undefined === ltiGrade 
							? 	<i className='far fa-circle-notch fa-spin' style={iconStyle}/>
							:	<span style={{ paddingLeft: '.5rem'}}>
									<Numbers 
										defaultValue={ltiGrade} 
										disabled={loading || undefined === ltiGrade}
										name={'grade'}
										onBlur={ this.handleGradeUpdate }
										onClick={ this.handleClearMessages }
										max={100} 
										min={0}
										step={1}
										style={{maxWidth: '50px'}}
										ref={'grade'} 
									/>
									{'   '}
									<i className='far fa-sync-alt' onClick={ ()=>this.props.getLtiGrade(true) } />
								</span>
							}
						</div>
						<div>
							<button style={{marginTop: '1rem'}}
                                onClick={this.handleGradePassback}
                                className="btn btn-success"
                                disabled={loading || undefined === ltiGrade}>
                                Save <i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
                        	</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handleClearMessages = () => {
		this.setState({
			error: false,
			message: ''
		})		
	}

	// updates grade in localstate
	handleGradeUpdate = e => {
        const { ltiGrade } = this.state;

		let {value} = e.target;
		
		if( value === '') {
			e.target.value = ltiGrade
			return;
		}

		this.setState({ 
			ltiGrade: value*1
		})
	}

	// Passes back grade, regardless of whether there has been an update
	handleGradePassback = () => {
		this.setState({
			loading: true,
			error: false,
			message: ''
		})

		const {ltiGrade} = this.state;

		this.props.passbackLtiGrade(ltiGrade)
		.then( resp => {
			this.setState({
				//loading: true handled by nextProps update
				error: false,
				message: 'Grade successfull submitted.'
			})
		})
		.catch( err => {
			this.setState({
				loading: false,
				error: true,
				message: err,
			})
		})
	}
	
    /** Displays msg if exists, or this.props.message if exists. */
    renderMessages = () => {
        let {message, error} = this.state
        return (
            <Message message={message} textClass={error ? 'danger' : 'success'} dismissable={false}/>
        );
    }
}

function mapStateToProps(state) {
	const {folderItems} = state;
	return {
        item: folderItems.selectedItem,
		itemAnalysis: folderItems.selectedItemAnalysis,
		ltiGrade: folderItems.selectedItemAnalysis.ltiGrade
	};
}

export default connect(mapStateToProps, {getLtiGrade, passbackLtiGrade})(Grade);