import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import { formatLocalDate } from 'utils/index'
import { clearUserMessages } from 'actions/app';
import { fetchLtiAttachReport } from './actions/index'

import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx'

class Reports extends Component {
    constructor(props) {
		super(props);
		
		this.tblColStyle = {
			1: { width: '25%', textAlignLast: 'left' },
			2: { width: '40%', textAlignLast: 'left' },
			3: { width: '25%', textAlignLast: 'left' },
			4: { width: '10%', textAlignLast: 'center' },
		}

        this.state = {
            activeConsumer: 0,
            sort: {
                key: 'name',
                order: 'asc'
            },
        }
    }

    componentDidMount() {
        if(undefined === this.props.ltiAttachReport) {
            this.props.fetchLtiAttachReport();
		} 
	}

	componentWillUnmount() {
		this.props.clearUserMessages();
	}

	render() {
        const {
			ltiAttachReport
        } = this.props; 

        if (undefined === ltiAttachReport) return this.renderLoading()
		
        let sort = this.state.sort, renderReport = [], renderConsumerOptions = [];
		const consumers = _.keys(ltiAttachReport);
        if (0 < consumers.length) {

			let keyX = 0; 		// for unique keys

			// CONSUMERS
			const orderedConsumers = _.orderBy(ltiAttachReport);
			orderedConsumers.map((consumer, consumerIndex) => {

				renderConsumerOptions.push(
					<option key={keyX++} value={consumerIndex}>Consumer: {consumers[consumerIndex]}</option>
				)

				// COURSES
				const orderedCourses = _.orderBy(consumer, [sort.key, 'dateCreated']);
				let renderAssignments = []
				
				orderedCourses.map((course, cIdx) => {
					
					// ASSIGNMENTS
					const orderedAssignments = _.orderBy(course.assignments);
					orderedAssignments.map((assignment, aIdx) => {
						var tblIdx =`asrTbl-${consumerIndex}-${cIdx}-${aIdx}`
						var icnIdx =`asrIcn-${consumerIndex}-${cIdx}-${aIdx}`

						// COMPILE STUDENTS
						const orderedUsers = _.orderBy(assignment.users, [sort.key, 'name'], [sort.order]);
						let renderUsers = [];
						orderedUsers.map((user, i) => {
							
							renderUsers.push(
								<div key={keyX++} className="table-row" key={i} >
									<div className="cell" style={this.tblColStyle[1]} >
										{user.name}
										<small>{user.email}</small>
									</div>
									<div className="cell" style={this.tblColStyle[2]} >{user.title}</div>
									<div className="cell" style={this.tblColStyle[3]} >
										{this.renderStatus(user.ltiAttachmentStatus, user.ltiDateAttached, user.ltiDateDetached)}
									</div>
									<div className="cell" style={this.tblColStyle[4]} >{user.ltiUserID}</div>
								</div>
							)
						})
						
						// RENDER STUDENTS TABLE PER COURSE & ASSIGN
						renderAssignments.push(
							<div className="row settings-div-row" 
								key={keyX++} 
								style={{paddingLeft: '20px', marginBottom: '1rem'}}
								
								>
								<div className="col-12" style={{padding: '0px'}}>
									{/* table heading */}
									<a style={{paddingLeft: '5px'}} 
										href="#" 
										//onClick={e => {e.preventDefault()}}
										onClick={e => this.onAssignmentClick(e, tblIdx, icnIdx)}
										>
										{`${course.ltiCourseTitle} - ${assignment.ltiAssignmentTitle} Report`}
										<i id={icnIdx} className='fas fa-chevron-down' style={{paddingLeft: '10px'}}/>
									</a>
									
									{/* table content */}
									<div id={tblIdx} className="table" data-sort="table" style={{display: 'none', paddingTop: '.8rem'}}>
										<div className="table-header">
											<div style={this.tblColStyle[1]} className={`cell header ${sort.order}${'name'===sort.key ? 'Sort' : null }`}>
												<button className='btn-link' onClick={ e => this.onHeaderClick(e, 'name') }>Name</button>
											</div>
											<div style={this.tblColStyle[2]} className={`cell header ${sort.order}${'title'===sort.key ? 'Sort' : null }`}>
												<button className='btn-link' onClick={ e => this.onHeaderClick(e, 'title') }>Media Title</button>
											</div>
											<div style={this.tblColStyle[3]} className={`cell header ${sort.order}${'ltiDateAttached'===sort.key ? 'Sort' : null }`}>
												<button className='btn-link' onClick={ e => this.onHeaderClick(e, 'ltiDateAttached') }>Date Submitted</button></div>
											<div style={this.tblColStyle[4]} className={`cell header ${sort.order}${'ltiUserID'===sort.key ? 'Sort' : null }`}>
												<button className='btn-link' onClick={ e => this.onHeaderClick(e, 'ltiUserID') }>LTI UserID</button></div>
										</div>
										<div className="table-body">
                                    		{renderUsers}
                                		</div>
									</div>
								</div>
							</div>
						)
					})
				})
				renderReport.push(
					<div key={consumerIndex +'-' +keyX++}  style={{
							paddingLeft: '20px', 
							borderTop: 'none', 
							display: this.state.activeConsumer == consumerIndex ? 'block' : 'none'
							}} >
						{/* <h4 style={{color:  'black'}} >
							{consumers[consumerIndex]}
						</h4> */}
						{renderAssignments}
					</div>
				)
			})
		}
		
		return (
			<div className="settings settings-wide">
                {this.state.displayModal === 'modalMemberInvite' ? <ModalMemberInvite /> : null}
                {this.renderPageHeader()}
				<div className="settings-body">
					<div className="row" style={{padding: '0px', marginBottom: '1rem'}}>
						<div className="col-7">
							{ 0 < renderConsumerOptions.length
								? <select 
									onChange={this.onConsumerChange} 
									className="form-control"
									style={{ color: '#373c47', backgroundColor: 'rgb(248, 248, 248)', width: 'unset'}}
									>
									{renderConsumerOptions}
								</select>
								: this.renderMessages('No report data')
							}
                            
						</div>
					</div>

					{renderConsumerOptions
                    ? renderReport
                    : null
                    }

				
				</div>
			</div>
		);
	}
	
	getCourses = () => {
		getStudents();
	}

	getStudents = () => {

	}

	/** Intiates render for consumer report */
	onConsumerChange = (e) => {
		this.setState({activeConsumer: e.target.value})
	}

	/** Toogle visibility of individual course assignment reports */
	onAssignmentClick = (e, tblInx, icnIdx) => {
		e.preventDefault();
		const display = document.getElementById(tblInx).style.display;
		if( 'none' === display ) {
			document.getElementById(tblInx).style.display = 'block'
			document.getElementById(icnIdx).className = 'fas fa-chevron-up';
		} else {
			document.getElementById(tblInx).style.display = 'none'
			document.getElementById(icnIdx).className = 'fas fa-chevron-down';
		}
	}

	renderLoading = () => {
		return (
			<div className="settings">
                {this.renderPageHeader()}
				<div className="settings-body">
					<Loading
						icon="far fa-circle-notch fa-spin"
					/>
				</div>
			</div>
		);
    };

    renderPageHeader = () => { 
        return <div className="settings-head"><h1>Activity Submission Reports</h1></div> 
    }

    /** Displays msg if exists, or this.props.message if exists. */
    renderMessages = ( msg ) => {
        let {message, error} = this.props.messages
        if(error) return <Message /> // supress errors - they're displayed in line with each user
        return (
            <Message message={msg || message} textClass={msg ? 'default' : 'success'} dismissable={msg ? false : true} style={{paddingBottom: 0}}/>
        );
	}
	
	/** Displays attachement date, detachment date */
	renderStatus = (ltiAttachmentStatus, ltiDateAttached, ltiDateDetached) => {
        let className, title, subTitle = false;
		
		switch(ltiAttachmentStatus) {

			case 'ATTACHED': {
				title =  `Submitted ${formatLocalDate(ltiDateAttached)}`
				className ='text-success'
				break
			}

			case 'DETACHED': {
				title =  <i>Unsubmitted {formatLocalDate(ltiDateDetached)}</i>
				subTitle = `Previously submitted ${formatLocalDate(ltiDateAttached)}`
				className ='text-tangerine'
				break
			}
			
			default: {
				title = "No submission"
				className ='text-danger'
				break
			}
		}
		
        return <span>
                    <span className={className}>
                        {title}
                    </span>
                {false === subTitle
					? null
					: <small>{subTitle}</small>
				}
            </span>
    }
    
    /** Sorts report table by key */
    onHeaderClick = (e, key ) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ 
            sort: {
                key,
                order: 'asc' === this.state.sort.order ? 'desc' : 'asc'
        }})
    }
}

function mapStateToProps(state) {
	return {
		ltiAttachReport: null === state.reports.ltiAttach ? undefined : state.reports.ltiAttach.data,  // while loading, `undefined`. if no report data, `{}`
		messages: state.messages,
	};
}

export default connect(mapStateToProps, { clearUserMessages, fetchLtiAttachReport })(Reports);