import { dm_print } from '../utils/';

import _ from 'lodash';
import {
    CUNAUTH_USER,
    SET_PLAYER,
	SEEK_TO,
	SET_CURRENTTIME,
	SET_DURATION,
	UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
	startTime: 0,       //player equivalent
	currentTime: 0,     //player equivalent
	seekStart: null,    //dmio Unique
	seekEnd: null,      //dmio Unique
    duration: 0,         //player equivalent
    videoHeight: 0,
    videoWidth: 0
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
		case UNMOUNT_ANALYSIS:
			return INITIAL_STATE;

        case SET_PLAYER:
            // paylaod contains react-video player {}
            return { ...state, ...action.payload };

		case SEEK_TO:
			// paylaod contains  {seekStart, seekEnd }
			return { ...state, ...action.payload };

		case SET_CURRENTTIME:
			// payload is current time
			return { ...state, currentTime: action.payload };

		case SET_DURATION:
			// payload is the duration of the video, increased to the next highest integer
			return { ...state, duration: Math.ceil(action.payload) };
	}

	return state;
}
