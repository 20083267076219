import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

class MarkerDefaultCheck extends Component {
  render(){
    const { markerInfo, handleDefaultSelect, selectedMarker } = this.props
    return (
      <div className="markerEdit-default-picker">
        <div className="picker-div">
          <h6 className="h6-small">{I18n.get('Default')}</h6>
          <i onClick={handleDefaultSelect}
            className={markerInfo.default ? "far fa-check-square" : "far fa-square"}/>
        </div>
        <h6 className="h6-small default-disclaimer">{I18n.get('Automatically added to new projects')}</h6>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps){
  return {
    markerInfo: state.userMarkers.data[ownProps.selectedMarker]
  }
}

export default connect(mapStateToProps)(MarkerDefaultCheck);