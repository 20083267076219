import classNames from 'classnames';
import _ from 'lodash';
import {Link} from 'react-router-dom';

import React, {Component} from 'react';
import DashboardTag from './Dashboard.Tag.jsx';

import {ROUTES} from 'Constants/app.js';

class Dashboard extends Component {
	render() {
		let {selectedDashboard, pauseOnTag} = this.props;
		// if the selected dashboard is undefined, it will return the NoDashboardMessage
		// this should only happen when there are no dashboard items inside of the selectedItems.itemAnalysis.dashboards.dashboardItems
		if (selectedDashboard === undefined) return this.renderNoDashboardMessage();
		return (
			<div className="col-10">
					<div className="analysis-dashboard-buttons">
						{this.renderTags(pauseOnTag, selectedDashboard)}
					</div>
			</div>
		);
    }
    
	renderTags = (pauseOnTag, selectedDashboard) => {
		const {pause, dashboardTags, isItemAnalysisOwner, itemAnalysisID} = this.props;
		const tagsToRender = _.map(selectedDashboard.dashboardTags, (value, key) => {
			const tag = dashboardTags[value];
			if (tag === undefined) return null;
			if ('BUTTON' === tag.type) {
				return (
					<DashboardTag
						pauseOnTag={pauseOnTag}
						// pause is passed down from media player
						pause={pause}
						id={tag.dashboardTagID}
						key={tag.dashboardTagID}
						itemAnalysisID={itemAnalysisID}
                        locked={this.props.locked}
						{...tag}
					/>
				);
			}
		});
		if (_.isEmpty(tagsToRender)) {
			// this link you to the dashboard page, with the selected dashboards ID as a search param
			// allowing us to render that dashboard for immediate editing
			if (this.props.isItemAnalysisOwner) {
				return (
					<Link to={`${ROUTES.DASHBOARDEDITPAGE}?${selectedDashboard.dashboardID}`}>
						<i>Add tags to this dashboard</i>
					</Link>
				);
			}
			return <i>This dashboard has no tags</i>;
		}
		return tagsToRender;
	};

	renderNoDashboardMessage = () => {
		return (
			<div className="row analysis-dashboard-content">
				{/* <div className="col-12 no-dashboards-message">
					<i>
						{this.props.isItemAnalysisOwner
							? 'You have no dashboards chosen for this page. Please add some in settings.'
							: ''}
					</i>
				</div> */}
			</div>
		);
	};
}

export default Dashboard;
