import { dm_print } from '../utils/';
import _ from 'lodash';
import {
    CUNAUTH_USER,
    ITEM_ANALYSIS_LOADING,
    UNMOUNT_ANALYSIS
} from 'Constants/actions';


const INITIAL_STATE = {
    loading: false
};

/**
 * 
 * @param {Object} state 
 * @param {Object} action 
 */
export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
        case UNMOUNT_ANALYSIS:
            return INITIAL_STATE;
        
		case ITEM_ANALYSIS_LOADING: {
			// payload is true or false
			return {
				...state,
				loading: action.payload
            };
        }
	}
	return state;
}
