import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { I18n } from '@aws-amplify/core';

import { exportItemAnalysis } from './actions/index';
import { openModal } from 'actions/app'

import BtnIcon from 'Widgets/ButtonIcon.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';
import Divider from 'Widgets/DropdownDivider.jsx';

import CollaborateMenuBtn from 'Menus/CollaborateMenuBtn.jsx'
import DashboardsMenuBtn from 'Menus/DashboardsMenuBtn.jsx'
import ExportAnalysisMenuBtn from 'Menus/ExportAnalysisMenuBtn.jsx'
// import DeleteItemMenuBtn from 'Menus/DeleteItemMenuBtn.jsx'
// import LeaveMenuBtn from 'Menus/LeaveMenuBtn.jsx'
import MarkersMenuBtn from 'Menus/MarkersMenuBtn.jsx'
// import OpenItemMenuBtn from 'Menus/OpenItemMenuBtn.jsx'
// import RenameItemMenuBtn from 'Menus/RenameItemMenuBtn.jsx'
import SendMenuBtn from 'Menus/SendMenuBtn.jsx'

import { PLANID } from 'Constants/app';

class AnalysisSettingsDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false,
			exportLoading: false // export loading
		};
	}

	render() {
		const { showDropdown } = this.state;
		return (
			<div className="dropdown show" onMouseLeave={() => this.handleDDLeave()}>
				<BtnIcon
					onClick={() => this.setState({ showDropdown: !showDropdown })}
					tooltip="Project Settings"
					btnClass="btn btn-outline-secondary"
					iconClass="fas fa-cog"
				/>
				{showDropdown && this.renderedButtons()}
			</div>
		);
	}

	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
		const { locked, canExport, planID } = this.props;
		const { exportLoading } = this.state;
		
		// renders all the options in a dropdown menu
		return (
			<div className="dropdown-menu">
				<DashboardsMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
				<MarkersMenuBtn handleOnClick={this.handleOpenModal} locked={locked} />
				<CollaborateMenuBtn handleOnClick={this.handleOpenModal} />
				{!PLANID.FREE[planID]
					? <SendMenuBtn handleOnClick={this.handleOpenModal} />
					: null
				}
				<Divider id="1" />
				<ExportAnalysisMenuBtn handleOnClick={this.handleExportAnalysisClick} loading={exportLoading} canExport={canExport} />
			</div>
		);
	};

	// HANDLES
	handleOpenModal = (e, name) => {
		e.preventDefault();
		this.setState({ showDropdown: false });
		this.props.openModal(name);
	};

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};

	handleExportAnalysisClick = e => {
		e.preventDefault();
		e.stopPropagation();
        this.setState({ exportLoading: true });

        // If item marked as confidential, confirm export.
        let exportConfirmed = true
        if( this.props.itemConfidential ) 
            exportConfirmed = confirm( `PRIVACY GUARD ALERT\nThis project has been marked as private and confidential.\n\nAre you sure you want to export the project analysis?\n` );
    
        if ( !exportConfirmed ) {
            this.setState({ exportLoading: false });
            this.handleDDLeave();
            return;
        }

		this.props
			.exportItemAnalysis(this.props.itemAnalysisID)
			.then(data => {
				data = 'text/csv;charset=utf-8,' + encodeURIComponent(data);

				// create an <a />
				let link = document.createElement('a');
				link.href = 'data:' + data;
				link.download = `${this.props.itemTitle}.csv`;
				link.click();
                this.setState({ exportLoading: false });
                this.handleDDLeave();
			})
			.catch(err => {
				//TODO: handle
                this.setState({ exportLoading: false });
                this.handleDDLeave();
            })
	};
}

function mapStateToProps(state) {
	return {
		itemAnalysisID: state.folderItems.selectedItemAnalysis.itemAnalysisID,
        itemTitle: state.folderItems.selectedItem.title,
        itemConfidential: state.folderItems.selectedItem.confidential,
		canExport: state.account.planData.additionalLimits.export,
		planID: state.account.planType,
	};
}

export default connect(mapStateToProps, { exportItemAnalysis, openModal })(
	AnalysisSettingsDropdown
);
