import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { DragSource, DropTarget } from "react-dnd";
import _ from "lodash";

import { dndItemTypes } from "Constants/app.js";
import { updateDashboard } from "./actions";
import "../../utils/moveFunction.js";

const itemSource = {
  // these props are based on the item being dragged
  beginDrag(props, dnd, element) {
    // the returned object is based on the items props.
    props.handleSelectTag(props.tagID)
    return {
      tagID: props.tagID,
      tagProps: props
    };
  },
  canDrag(props, monitor) {
    // if there is no dndProp or canBeDropped prop, we want to assume it can't be dragged
    if (
      props.dndProps === undefined ||
      props.dndProps.canBeDragged === undefined
    )
      return false;
    return props.dndProps.canBeDragged;
  }
};

// these props are based on the drop target
const folderDropTarget = {
  drop(props, monitor, component) {
    // monitor.getItem() gives you the item that was being dragged
    const draggedItem = monitor.getItem();
    // grabbing the target items index
    const targetItemIndex = props.indexOfTag;
    const { dashboardTags, dashboardID } = props.selectedDashboard;
    // grabbing the dragged items index
    const draggedItemIndex = _.indexOf(dashboardTags, draggedItem.tagID);
    // if the dragged item is inside of the array, we want to do this function, since it will
    // need to move the items inside of the array, as opposed to replacing the item
    if (draggedItemIndex >= 0) {
      // if it's not above 0, it doesn't exist in array
      let newTagsArray = dashboardTags
        .move(draggedItemIndex, targetItemIndex)
        // filters out any undefined, so we do not get an error.
        .filter(tagID => {
          return tagID !== undefined;
        });
      // slice so it's only a length of 8
      newTagsArray.length > 8 && (newTagsArray = newTagsArray.slice(0, 8));
      // updateDashboard needs to send these props, dashboards ID, and updatedProp
      props.updateDashboard(dashboardID, { dashboardTags: newTagsArray });
      return;
    }
    /* THIS FUNCTION WILL REPLACE THE ITEM IN THE ARRAY, INSTEAD OF SHIFTING THE ARRAY */
    /* KEEP IN CASE WE WANT TO CHANGE BACK TO OVERWRITING WHERE THEY ARE */
    // // create newTagsArray here, that way we can pass it through if the next props.tagID doesn't need it.
    // let newTagsArray = dashboardTags.map( (tagID, i) => {
    //   // if the mapped over tagID is the targets tagID, we need to replace that with
    //   // the new draggedItems tagID
    //   if (i === targetItemIndex) return draggedItem.tagID
    //   return tagID
    // }).filter(tagID => { return tagID !== undefined })

    /* THIS FUNCTION SHIFT THE ITEMS IN THE ARRAY DOWN ONE INSTEAD OF RELACING THEM */
    let newTagsArray = dashboardTags.slice(0, targetItemIndex);
    newTagsArray = newTagsArray
      .concat(draggedItem.tagID)
      .concat(dashboardTags.slice(targetItemIndex));
    // if it happens to be larger than 8, we slice it
    newTagsArray.length > 8 && (newTagsArray = newTagsArray.slice(0, 8));
    // Sends new array through. updatedashboard accepts a dashboardID, and an updated prop.
    // ex { dashboardTags: [newTagsArray] }
    props.updateDashboard(dashboardID, { dashboardTags: newTagsArray });
  },
  canDrop(props, monitor) {
    const draggedItem = monitor.getItem();
    const targetItemID = props.tagID;
    // if there is no dndProp or canBeDropped prop, we want to assume it can't be dropped on
    if (
      props.dndProps === undefined ||
      props.dndProps.canBeDropped === undefined
    )
      return false;
    // if the dropTargets id is the same as the dragged items id, we don't want to allow a drop
    if (targetItemID === draggedItem.tagID) return false;
    return props.dndProps.canBeDropped;
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop()
  };
}

class TagButton extends Component {
  constructor(props) {
    super(props);

    this.state = { hover: false };
  }
  removeTagFromDashboard = () => {
    const { tagID, selectedDashboard } = this.props;
    const newTagsArray = selectedDashboard.dashboardTags.filter(arrayTag => {
      if (arrayTag === tagID) return false;
      return true;
    });
    this.props.updateDashboard(selectedDashboard.dashboardID, {
      dashboardTags: newTagsArray
    });
  };
  render() {
    // props passed down from parent
    const { handleSelectTag, name, className, dndProps, tagID } = this.props;
    // props from react-dnd
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      src,
      canDrop
    } = this.props;

    const style = {
      border: canDrop ? "1px solid lightgray" : "",
      position: "relative"
    };

    const removeTagStyle = {
      // only want to display if they are in the upper dashboardTags content, AND it's being hovered over AND it's not an empty tag, so it can be dragged.
      display: dndProps.tagLocation === "dashboardTags" &&
        this.state.hover &&
        dndProps.canBeDragged
        ? ""
        : "none",
      position: "absolute",
      top: "-2px",
      right: "4px",
      fontSize: ".8rem"
    };

    return connectDragSource(
      connectDropTarget(
        <div
          onMouseOver={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={() => handleSelectTag(this.props.tagID)}
          style={style}
          name={name}
          className={className}
        >
          {name}
          <span onClick={this.removeTagFromDashboard} style={removeTagStyle}>
            {"x"}
          </span>
        </div>
      )
    );
  }
}

const TagButtonFinal = compose(
  connect(null, { updateDashboard }),
  DropTarget(dndItemTypes.ITEM, folderDropTarget, collectDrop),
  DragSource(dndItemTypes.ITEM, itemSource, collect)
)(TagButton);

export default TagButtonFinal;
