import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { TransitionGroup } from 'react-transition-group';

import withDragDropContext from "../Widgets/withDragDropContext.jsx";
import SingleDashboardDraggable from "./SingleDashboard.Draggable.jsx";
import { FadeUpTransition } from 'Widgets/FadeUpTransition.jsx';

import { updateDashboard } from './actions';

class SidebarDashboardsDefaults extends Component {
  constructor(props) {
    super(props);

    // will attempt to create the dashboard arr with current props
    const dashboardsArr = this.createDashboardsArr(this.props);
    this.state = { dashboardsArr };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // needs to always rerender when sent new props.
    this.setState({ dashboardsArr: this.createDashboardsArr(nextProps) });
  }
  // accepts data in form of "props" to create an array of dashboards.
  createDashboardsArr = props => {
    return (
      Object.keys(props.allDashboards)
        .map(dashboard => {
          // saves the dashboard object to a variable so we can access it later in the map
          const dashboardObj = props.allDashboards[dashboard];
          if (!dashboardObj.default) return null;

          let listItem = classNames({ "list-group-item": true, option: true });
          // if the selected dashboard is not null,
          if (props.selectedDashboard !== null) {
            // will check if the selected dashboards ID is equivalent to the dashboard in the map
            listItem = classNames({
              "list-group-item": true,
              selected: props.selectedDashboard.dashboardID === dashboard
            });
          }
          return { dashboardObj, className: listItem, key: dashboard };
        })
        // will filter out any item that is falsy, i.e. undefined / null
        .filter(item => item)
        .sort((a, b) => {
          const dashA = a.dashboardObj.defaultSortOrder;
          const dashB = b.dashboardObj.defaultSortOrder;
          if (dashA < dashB) return -1;
          if (dashA > dashB) return 1;
          return 0;
        })
    );
  };
  // actually moves the dashboard
  moveDashboard = (dragItem, hoverItem) => {
    const { dashboardsArr } = this.state
    // creates a new updated array
    const updatedArr = dashboardsArr.map(item => {
      // the specific items dashboard ID
      const itemDashID = item.dashboardObj.dashboardID
      // replaces the dragged items sort order with the hovered items sort order
      if ( itemDashID === dragItem.dashboardID ){
        return {
          ...item,
          dashboardObj: {
            ...item.dashboardObj,
            defaultSortOrder: hoverItem.defaultSortOrder
          }
        }
      }
      // replaces the hovered items sort order with the dragged items sort order      
      if ( itemDashID === hoverItem.dashboardID ){
        return {
          ...item,
          dashboardObj: {
            ...item.dashboardObj,
            defaultSortOrder: dragItem.defaultSortOrder
          }
        }
      }
      return item;
    })
    .sort((a, b) => {
      const dashA = a.dashboardObj.defaultSortOrder;
      const dashB = b.dashboardObj.defaultSortOrder;
      if (dashA < dashB) return -1;
      if (dashA > dashB) return 1;
      return 0;
    })
    this.setState({ dashboardsArr: updatedArr })
  }
  // Used to actually submit the new dashboards to be saved.
  submitNewDefaultOrder = () => {
    const { allDashboards } = this.props
    this.state.dashboardsArr.map( dashboard => {
      // checks if the order inside redux is the same as this states order, if not, will update it.
      if ( allDashboards[dashboard.key].defaultSortOrder !== dashboard.dashboardObj.defaultSortOrder ){
        const updatedProp = {
          defaultSortOrder: dashboard.dashboardObj.defaultSortOrder
        }
        // dashbaord.key === dashboardID
        // takes dashboardID and updatedProp
        this.props.updateDashboard(dashboard.key, updatedProp)
      }
    })
  }
  renderDefaultDashboards = () => {
    const { dashboardsArr } = this.state;

    // if the dashboardsArr length is less than 1, we want to return that none are defaults.
    if (dashboardsArr.length < 1) {
      return (
        <FadeUpTransition classNames="list-defaults">
          <p className="no-defaults-selected">None selected</p>
        </FadeUpTransition>
      )
    }
    // maps over dashboardsArr to return draggable dashboards
    return dashboardsArr.map(dashboard => {
      const { dashboardObj, className, key } = dashboard;
      return (
        <FadeUpTransition
          key={key}
          classNames="list-defaults">
          <SingleDashboardDraggable
            className={className}
            dashboardObj={dashboardObj}
            handleSelectDashboard={this.props.handleSelectDashboard}
            key={key}
            moveDashboard={this.moveDashboard}
            submitNewDefaultOrder={this.submitNewDefaultOrder}
          />
        </FadeUpTransition>
      );
    });
  };
  render() {
    return (
      <ul className="list-group list-group-defaults">
        <TransitionGroup>
          {this.renderDefaultDashboards()}
        </TransitionGroup>
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    allDashboards: state.userDashboards.data
  };
};

const SidebarDashboardsDefaultsFinal = compose(
	connect(mapStateToProps, { updateDashboard }),
  		withDragDropContext
	)
(SidebarDashboardsDefaults);

export default SidebarDashboardsDefaultsFinal;