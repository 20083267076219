import React from 'react';
import { CSSTransition } from 'react-transition-group';

// Need to pass fadeuptransition a key prop, as well as a classNames prop
export const FadeUpTransition = (props) => (
  <CSSTransition
    {...props}
    classNames={props.classNames}
    timeout={{ enter: 500, exit: 300 }}
  />
);