import _ from "lodash";
import AWS from 'aws-sdk/global';
const { createHmac } = AWS.util.crypto.lib;

import { MONTH, MONTHS_3AB, UNLIMITED} from 'Constants/app'

const hmacSha256 = (signingKey, stringToSign, encoding) => {
    return createHmac('sha256', signingKey)
        .update(stringToSign)
        .digest(encoding);
};

/**
 * Custom authorization method specifically for Evaporate.js
 * See https://docs.aws.amazon.com/general/latest/gr/signature-version-4.html 
 * See https://docs.amazonaws.cn/en_us/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html
 * @param {Object} signParams Object containing secretKey for signing
 * @param {Object} signHeaders unused
 * @param {String} stringToSign encoded string to sign strong to sign
 * @param {String} signatureDateTime Amz formatted date time sting
 * @param {String} canonicalRequest unused
 */
export const awsCustomAuth = (signParams, signHeaders, stringToSign, signatureDateTime, canonicalRequest) => {
	const stringToSignDecoded = decodeURIComponent(stringToSign)
    const requestScope = stringToSignDecoded.split('\n')[2];
    const [date, region, service, signatureType] = requestScope.split('/');

    // dm_print( '=== Dmio.awsCustomAuth() start === ')
    // dm_print( ' - signParams :', signParams )
    // dm_print( ' - signHeaders :', signHeaders)
    // dm_print( ' - signatureDateTime :', signatureDateTime)
    // dm_print( ' - canonicalRequest :', canonicalRequest)
    // dm_print( ' - stringToSign :', stringToSign)
    // dm_print( ' - stringToSignDecoded :', stringToSignDecoded)
    // dm_print( ' - signParams.secretKey :', signParams.secretKey )
    // dm_print( ' - requestScope :', requestScope )
    // dm_print( ' - date :', date)
    // dm_print( ' - region :', region)
    // dm_print( ' - service :', service)
	// dm_print( ' - signatureType :', signatureType)
	// dm_print( '=== Dmio.awsCustomAuth() end === ')

    return new Promise(function (resolve, reject) {   
		const DATE = hmacSha256(`AWS4${signParams.secretKey}`, date);
        const REGION = hmacSha256(DATE, region);
        const SERVICE = hmacSha256(REGION, service);
        const SIGNING = hmacSha256(SERVICE, signatureType);
        const FINAL = hmacSha256(SIGNING, stringToSignDecoded, 'hex');
        resolve( FINAL )
    });
}

/**
 * JWTtoken decode 
 * @param {Object} Valid JWTtoken 
 */
export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

/** DM SPECIFIC **/
/**
 * Converts dmError to AWS err Obj
 */
export function dmErrToAwsErr(str) {
  try {
    const obj = JSON.parse(
      str.substr(str.indexOf("{"), str.lastIndexOf("}") - str.indexOf("{") + 1)
    );
    return obj;
  } catch (e) {
    return "dmErr: Internal error bad format";
  }
}

export function dm_print(str, obj = "", z=0) {
   if ("development" === process.env.NODE_ENV || '3b676b67-651a-4170-b022-de0ade982d0b' === z || 'b395a10e-c18f-48e9-8c62-f8be64e84425' === z ) {
//  if ("development" === process.env.NODE_ENV) {
    console.log(`%c ${formatLocalDate(Date.now())}`, 'color: green; font-weight: bold;', str, obj);
  }
}

/** FORMAT **/
/**
 * Format seconds as a time string, 11hrs 7min 30s
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} seconds Number of seconds to be turned into a string
 * @return {String} time formatted as 1hrs 1min 0s or 1min 33s
 * @function formatReadableTime
 */
export function formatReadableTime (seconds = 0) { //}, guide = seconds) {
	let s = Math.floor(seconds % 60);
	let m = Math.floor(seconds / 60 % 60);
	let h = Math.floor(seconds / 3600);

	// handle invalid times
	if (isNaN(seconds) || seconds === Infinity) {
		// '-' is false for all relational operators (e.g. <, >=) so this setting
		// will add the minimum number of fields specified by the guide
		h = m = s = '-';
	}

	// Hrs - only show if present
	h = (h > 0 ? `${h}hr ` : '');

	return h + `${m}min ${s}s`;
}


/**
 * Format seconds as a time string, H:MM:SS or M:SS
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} seconds Number of seconds to be turned into a string
 * @param  {Number} guide   Number (in seconds) to model the string after
 * @param  {Boolean} addSuffix whether to add 'sec', 'mins', or 'hrs' to return
 * @return {String}         Time formatted as H:MM:SS or M:SS
 * @private
 * @function formatTime
 */
export function formatVideoTime(seconds = 0, guide = seconds, addSuffix = false) {
	let s = Math.floor(seconds % 60);
	let m = Math.floor(seconds / 60 % 60);
	let h = Math.floor(seconds / 3600);
	const gm = Math.floor(guide / 60 % 60);
	const gh = Math.floor(guide / 3600);

	var suffix = seconds > 59 ? ' min' : ' sec';

	// handle invalid times
	if (isNaN(seconds) || seconds === Infinity) {
		// '-' is false for all relational operators (e.g. <, >=) so this setting
		// will add the minimum number of fields specified by the guide
		h = m = s = "-";
	}

	// Check if we need to show hours
	//   h = h > 0 || gh > 0 ? `${h}:` : "";
	if(h > 0 || gh > 0 ) {
		h = `${h}:`;
		suffix = ' hrs'
	} else { h = "" }

	// If hours are showing, we may need to add a leading zero.
	// Always show at least one digit of minutes.
	m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

	// Check if leading zero is need for seconds
	s = s < 10 ? `0${s}` : s;

	return h + m + s + (addSuffix ? suffix : '');
}

/**
 * Formats a unix timestamp date to local time. if showYear is true, will also show the year.
 * @param {String} unixTs Unix timestamp
 * @param {Boolean} showYear If true returns four digit year
 */
export function formatLocalDate(unixTs = 0, showYear = false) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const d = new Date(unixTs);
  var yr = d.getFullYear();
  const months = ( (showYear) ?  MONTH : MONTHS_3AB )
  

  if (yr !== new Date().getFullYear() || showYear) {
    (showYear) ? yr = `, ${yr}` : yr = `, '${yr.toString().substr(2, 4)}`;
  }
  else yr = ", " + d.getHours() + ":" + ('0'+d.getMinutes()).slice(-2); //slice takes rightmost two chars so 012 = 12 but 03 = 03.

  return `${days[d.getDay()]} ${d.getDate()} ${months[d.getMonth()]}${yr}`;
}

/**
 * Converts a string to currency format with 2 decimal places and comma separating 
 * Example 1: formatCurrency(1020) returns 10.20
 * Example 2: formatCurrency(109920) returns 1,099.20
 * @param {String} amount of numbers without decimal points
 * @param {String} currency default is $
 */
export function formatCurrency( amount, currency='$' ) {
  return `${currency}${(amount/ 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}`;
}

/**
 * Returns storage in storage as either GB or MB, based in bytesUnit
 * @param {Number} storage in megabytes or bytes
 * @param {String} bytesUnit 'MEGA' | 'KILO' | BYTE. Default is 'MEGA'
 */
export function formatStorage( storage=0, bytesUnit='MEGA' ) {
	// if( UNLIMITED.value === storage ) return UNLIMITED.htmlCode
	if (UNLIMITED.value === storage) return UNLIMITED.word;
	if (isNaN(storage) || storage === Infinity) return storage;
	if (bytesUnit === 'BYTE') storage = storage / 1000000 // convert bytes to megabytes
	else if (bytesUnit === 'KILO') storage = storage / 1000 // convert kilobytes to mega bytes
	if (storage >= 1000) return `${(storage / 1000).toFixed(2)} GB`;
	if (storage < 1) return `${(storage * 1000).toFixed(0)} KB`;
    else return `${storage.toFixed(2)} MB`;
}



/** STRINGS */

export const capitalize = (str) =>  { return _.capitalize(str.toLowerCase()) }

/** FILES */

/** 
 * Returns object with FA icon and icon color for given fileName's extension. 
 * {icon}: is only is only the file extension part (eg -word for for fa-file-word).
 * {color}: is css color relevant to text- declarations. (eg -primary for text-primary)
 * @param (String) fileName including extension
 * @returns (Object) {icon: '-csv', color: 'danger'}
 * @example getExtension('MyHappyLife.doc') returns {icon: '-word', color: 'primary'}
 * FA File Icons
 * Powerpoint:	f1c4 || <i class="fas fa-file-powerpoint"></i>
 * Excel:		f1c3 || <i class="fas fa-file-excel"></i>
 * Word: 		f1c2 || <i class="fas fa-file-word"></i>
 * PDF: 		f1c1 || <i class="fas fa-file-pdf"></i>
 * CSV: 		f6dd || <i class="fas fa-file-csv"></i>
 * File: 		f15c || <i class="fas fa-file-alt"></i>
 * Image: 		f1c5 || <i class="fas fa-file-image"></i>
 * 
 * keynote
 */
export const getExtensionIcon = (fileName) => {
	switch( getExtension(fileName) ) {
		case ('csv'): return {icon: '-csv', color: 'info'}

		case ('doc'): case('pages'):
		case ('docx'): return {icon: '-word', color: 'primary'}

		case ('xls'): case ('numbers'):
		case ('xlsx'): return { icon: '-excel', color: 'success' }

		case ('pdf'): return {icon: '-pdf', color: 'danger'}
		
		case ('ppt'): case ('key'):
		case ('pptx'): return {icon: '-powerpoint', color: 'danger'}

		case ('bmp'): case ('gif'): case ('jpg'): case ('jpeg'): case ('svg'):
		case ('png'): return { icon: '-image', color: 'plum' } 
		
		case ('3gp'):
		case ('mp4'):
		case ('mov'):
		case ('mod'):
		case ('mts'):
		case ('ogv'):
		case ('mxf'): return { icon: '-video', color: 'primary' }

		case ('mp3'):
		case ('m4a'): return { icon: '-audio', color: 'success' }

		default: return {icon: '', color: 'none'}
	}
}
/**
 * Returns the extension of fileName, if one exists
 * @param {String} fileName 
 */
export const getExtension = (fileName) => { 
	return _.toLower( fileName.substring((fileName.lastIndexOf('.')+1), fileName.length)) 
}

// export const getTitle
/**
 * Returns the fileNameExtension, fileType, size in MB and fileNameBase of file
 * @param {File Object} file 
 * @returns Object:
 * {
 * 		fileNameBase: file.name without .[extension] OR empty where none exists
 * 		fileNameExtension: extension of the file.name without . OR empty where none exists
 * 		fileType: based on file.type; uppercased OR 'UNKNOWN' where file.type === ''
 * 		sizeMB: file.size conversted to MBs
 * }
 */
export function getFileData(file) {

	// postion of . otherwise str length
	var dotPos = file.name.lastIndexOf('.');
	switch(dotPos) {
		// No extension
		case -1: 
			var fileNameBase = _.trim(file.name)
			var fileNameExtension = ''
			break;

		// No file name, just an extension
		case 0:
			var fileNameBase = ''
			var fileNameExtension = _.trim(_.toLower(file.name.substring(0, file.name.length)));
			break;
		
		default:
			var fileNameBase = _.trim(file.name.substring(0, dotPos))
			var fileNameExtension = _.trim(_.toLower(file.name.substring(dotPos+1, file.name.length)));
	}

	const fileType = _.toUpper(_.split(file.type, '/')[0]) || 'UNKNOWN';
	const sizeMB = file.size / 1000000; // Convert file size to MBs

	return {
		fileNameBase,
		fileNameExtension,
		fileType,
		sizeMB,	
	}

}

/** ENCODING */
/**
 * Encodes base64 data to for diplay of an image
 */
// export function encode(data) {
//     let str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
//     return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
// }
