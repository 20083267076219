import _ from 'lodash'

import {
	CUNAUTH_USER,
    MEMBERS_UPDATE,
    MEMBERS_FETCH,
} from 'Constants/actions';

const INITIAL_STATE = {}; // NOTE: MEMBERS_LOADING indicated by lack of state.members.data

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return { ...INITIAL_STATE };

		case MEMBERS_FETCH: {
            return { 
                ...state,
                data: action.payload, 
                // loading: false 
            }};

        case MEMBERS_UPDATE: {
            // action.payload is {} containing updated members
            return { 
                ...state,
                data: action.payload,
            }};

        // case MEMBERS_LOADING: {
        //     return {
        //         ...state,
        //         loading: true
        //     }};
	}
    return state;
}
