import React, { Component } from 'react';

class PageAccess extends Component {
	render() {
		return <div className="page page-access">
			<div className="container">
				<div className="row">
					<div className="col col-sm-8 offset-sm-2">
						<div className="main main-access">
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default PageAccess;