import React, { Component } from 'react';
import { dm_print } from 'utils/';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { signoutUser, fetchUser } from '../../actions';
import { openModal } from 'actions/app'
import { ROUTES } from 'Constants/app';

import Avatar from 'Widgets/Avatar.jsx';
import Divider from 'Widgets/DropdownDivider.jsx';
import {Loading} from 'Widgets/Loading.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';
import MenuLnk from 'Widgets/MenuLink.jsx';

class HelloUser extends Component {
	state = {
		showDropdown: false
    };

	render() {
        const { showDropdown } = this.state;
        let { src, name, initials, loading } = this.props;
		name = name || 'Hello you!';

		if (loading) {
			return (
					<div className="dropdown hello-user">
						&nbsp;<Loading size="sm" />
					</div>
			);
        }

		return (
			<div className='navbar-nav'>
				<div className="dropdown show hello-user" onMouseLeave={() => this.handleDDLeave()}>
					<button
						onClick={() => this.setState({ showDropdown: !showDropdown })}
						className='btn'>
						<div>
							<Avatar src={src} size="sm" initials={initials} />
							<p>{name}</p>
						</div>
						<i className={(showDropdown) ? 'fal fa-ellipsis-h' : 'fal fa-ellipsis-v'} />
					</button>
					{showDropdown && this.renderButtons(this.props)}
				</div>
			</div>
        );
	}

	renderButtons = () => {
        const { isOrganization } = this.props;
        const accountsMenu = isOrganization ? 'Account & Members' : 'Account'
        return (
            <div className="dropdown-menu">
                <MenuLnk
                    title={accountsMenu}
                    to={ROUTES.ACCOUNT}
                    iconClass="user text-success"
                    iconTransform="flip-h"
                />

                {
                    // Enterprise user account - hide upgrade menu item
                    (!isOrganization)
                    ? <MenuLnk 
                        title="Upgrade" 
                        to={ROUTES.UPGRADE}
                        iconClass="hand-point-up text-info" 
                    />
                    : null
                }

                <Divider id="1" />

                <MenuLnk
                    title="Home"
                    to={`${ROUTES.LANDING}/0`}
                    iconClass="home text-success"
                />
                <MenuLnk
                    title="Dashboards"
                    to={ROUTES.DASHBOARDEDITPAGE} 
                    iconClass="bullseye-pointer text-primary"
                />
                <MenuLnk
                    title="Markers"
                    to={ROUTES.MARKERSEDITPAGE} 
                    iconClass="bookmark text-danger"
                />

                <Divider id="2" />

                <MenuBtn
                    title="Help Center"
                    onClick={e => { this.handleOpenNewWindow(e, 'http://get.dreamaker.help')}}
                    iconClass="question text-plum"
                />
                <MenuBtn
                    title="Terms & Privacy"
                    onClick={e => { this.handleOpenNewWindow(e, 'https://dreamaker.io/privacy')}}
                    iconClass="file-alt text-primary"
                />
                <Divider id="3" />

                <MenuBtn
                    title="Signout"
                    onClick={this.onSignout}
                    iconClass="sign-out text-danger"
                    iconTransform='flip-h'
			    />
            </div>
        );
	};

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
    };
    
    handleOpenNewWindow = (e, location) => {
        e.preventDefault();
        window.open(location);
    }

	onSignout = e => {
		e.preventDefault();
		let signout = true;
		if(this.props.evaporating) {
			signout = confirm('File uploads are in progress, signing out will abort them. Continue to sign out?')
		}
		signout ? this.props.signoutUser(null, true) : null;
	};
}

const mapDispatchToProps = { signoutUser };

function mapStateToProps(state) {
	const {account, uploader, user} = state;
	return {
		evaporating: uploader.evaporating, // default false
        initials: user.data.initials,
        isOrganization: account.isOrganization || false,
		loading: user.loading,
        name: user.data.firstName,
		src: user.data.avatarURL_32,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(HelloUser);
