import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { dm_print } from 'utils/';
import classNames from 'classnames';

class EventTagItemMarkers extends Component {
	state = {
		editingMarker: -1
	};

	render() {
		const {
			dashboardLabel,
			dashboardTags,
			dashboardTagID,
			eventMarkers,
			eventTagID,
			itemMarkers,
			itemMarkerOrder,
            isItemAnalysisOwner,
            locked,
			ownership,
			selectedItemDashboards,
			userDashboards,
			userMarkers,
			userDashboardTags
		} = this.props;

		// will either use the users markers or the items markers depending on if the user is the item analysis owner.
		const markersDetails = isItemAnalysisOwner ? userMarkers : itemMarkers;

		// if user is the owner, we want to check the users dashboards, otherwise check the loaded dashboard tags.
		const currentTags = isItemAnalysisOwner
			? userDashboardTags[dashboardTagID]
			: dashboardTags[dashboardTagID];
		const { editingMarker } = this.state;
		// const isEditing = editingMarker !== -1;

		let ul = [];
		let select = [];

		// DASHBOARD TAGS
		var linkKey = `etm#${eventTagID}`;
		if (ownership && !locked )
			ul.push(
				<li
					className="cursor-pointer"
					key={`mrkli-99${eventTagID}`}
                    data-toggle='tooltip'
                    title="Tag"
					onClick={e => {
						e.stopPropagation();
						this.handleClick(linkKey);
					}}>
					{dashboardLabel.toUpperCase()}
				</li>
			);
		else
			ul.push(
				<li 
                    className="cursor-default" 
                    key={`mrkli-99${eventTagID}`}
                    data-toggle='tooltip'
                    title="Tag">
					{dashboardLabel.toUpperCase()}
				</li>
			);

		let selectClasses = classNames({
			'form-control': true,
			'commentblock-places-select': !(editingMarker == linkKey),
			'commentblock-places-select-editing': editingMarker == linkKey
		});

		let options = [];
		options.push(
			<option key={eventTagID} disabled defaultValue>
				-- Dashboard Tags --
			</option>
		);

		// makes the createOption variable
		let createOption;
		if (isItemAnalysisOwner) {
			// creates a filtered dashboard tags array by grabbing all of the currently selected items dashboard IDs
			// then using those IDs to loop over the userDashboards reducer, and returning each of those dashboards subsequent tags.
			const filteredDashboardTags = selectedItemDashboards
				.map(dashboardId => {
					return userDashboards[dashboardId].dashboardTags.map(tagId => {
						return userDashboardTags[tagId];
					});
					// we then take the array and reducing it from multiple arrays, to a single array.
				})
				.reduce((accum, current) => {
					return accum.concat(current);
				}, []);

			// finally, we set the createOption var to the filteredTags map
			createOption = _.map(filteredDashboardTags, (dt, idz) => {
				return { dt, idz };
			});
		} else {
			// only use the ones in the currently selected dashboards.
			createOption = _.map(dashboardTags, (dt, idz) => {
				return { dt, idz };
			});
		}

		createOption.map(createOption => {
			if ('BUTTON' == createOption.dt.type) {
				options.push(
					<option key={createOption.idz} value={createOption.dt.dashboardTagID}>
						{_.capitalize(createOption.dt.label)}
					</option>
				);
			}
		});

		select.push(
			<select
				ref={linkKey}
				key={`mkrs-db-select-${dashboardTagID}-${eventTagID}`}
				className={selectClasses}
				onChange={this.handleChangeMarker}
				onBlur={this.handleBlur}
				value={dashboardTagID}>
				{options}
			</select>
		);

		// MARKERS
		itemMarkerOrder.forEach((markerID, index, array) => {
			var key = `mrkli-${index}${eventTagID}`;
			var linkKey = `${markerID}#${eventTagID}`;

			// uses the markerId to get the value out of the eventMarkerInfo to be used
			const eventMarkerInfo = eventMarkers[markerID];
			// then uses that eventMarkerInformation value to find the name from the markersDetails,
			// selected markerID, and it's options.
            // var liName = markersDetails[markerID].options[eventMarkerInfo] || markersDetails[markerID].name;
            var liName = markersDetails[markerID].options[eventMarkerInfo] || markersDetails[markerID].name;
            

			if (ownership  && !locked) {
                const liClasses = classNames ({
                    'unselected': markersDetails[markerID].name === liName,
                    'cursor-pointer': true,
                })

				ul.push(
					<li
						className={liClasses}
						key={key}
                        data-toggle='tooltip'
                        title={markersDetails[markerID].name}
						onClick={e => {
							e.stopPropagation();
							this.handleClick(linkKey);
						}}>
						{ markersDetails[markerID].name === liName ? <i className="fal fa-angle-double-down" /> : '' }
                        {liName}
					</li>
				);

				// key = `mrksop-${index}${eventTagID}`;
				const selectClasses = classNames({
					'form-control': true,
					'commentblock-places-select': !(editingMarker == linkKey),
					'commentblock-places-select-editing': editingMarker == linkKey
				});
				const createOptions = _.map(markersDetails[markerID].options, function(v2, k2) {
					return { k2, v2 };
				});

				select.push(
					<select
						ref={linkKey}
						autoFocus={true}
						key={`mkrs-select-${markerID}-${eventTagID}`}
						className={selectClasses}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						value={eventMarkers[markerID] || '_x17-'}>
						<option disabled value="_x17-">
							-- Select {markersDetails[markerID].name} --
						</option>
						{_.isEmpty(markersDetails[markerID].options) ? (
							<option disabled>No options available</option>
						) : (
							_.map(createOptions, createOption => (
								<option key={createOption.k2} value={createOption.k2}>
									{createOption.v2}
								</option>
							))
						)}
					</select>
				);
			} else
				ul.push(
					<li key={key} 
                        data-toggle='tooltip'
                        title={markersDetails[markerID].name}
                        className="cursor-default unselected">
						{liName}
					</li>
				);
		});

		return (
			<div>
				<ul>{ul}</ul>
				{ownership && !locked ? select : ''}
			</div>
		);
	}

	handleBlur = e => {
		this.setState({
			editingMarker: -1
		});
	};

	handleChange = e => {
		this.props.handleMarkerChange(_.split(this.state.editingMarker, '#')[0], e.target.value);
		this.setState({
			editingMarker: -1
		});
	};

	handleChangeMarker = e => {
		this.props.handleTagChange(e.target.value);
		this.setState({
			editingMarker: -1
		});
	};

	handleClick = i => {
		if (i === this.state.editingMarker && -1 !== this.state.editingMarker)
			this.setState({
				editingMarker: -1
			});
		else {
			// Give focus so that it can be blurred - creates a flicker tho
			setTimeout(() => {
				this.refs[i].focus();
			}, 30);
			this.setState({
				editingMarker: i
			});
		}
	};
}

function mapStateToProps(state) {
	return {
		itemMarkers         : state.itemAnalysisMarkers.data,
		userMarkers         : state.userMarkers.data,
        userDashboards      : state.userDashboards.data,
        
		// needed to create the selector array from selectable dashboards
		userDashboardTags   : state.userDashboardTags.data,
		// gets the selected items dashboards
		selectedItemDashboards: state.folderItems.selectedItemAnalysis.dashboards
	};
}

export default connect(mapStateToProps, {})(EventTagItemMarkers);
