import _ from 'lodash'

import {
	CUNAUTH_USER,
    REPORT_LTI_ATTACH_FETCH,
} from 'Constants/actions';

const INITIAL_STATE = {
	ltiAttach: null
}; 

/**
 * NOTE: REPORTS_LOADING indicated by lack of state[report] === null
 * NOTE: No data indicated by lack of state[report].data == {}
 */
export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return { ...INITIAL_STATE };

		case REPORT_LTI_ATTACH_FETCH: {
            return { 
                ...state,
                ltiAttach: {data: action.payload}
            }};
	}
    return state;
}
