import _ from 'lodash';
import classNames from 'classnames';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { formatLocalDate, formatStorage, formatVideoTime } from 'utils/';

import { openModal } from 'actions/app'
import { revokeUser } from 'actions/share';

import { SHARE_PERMISSIONS, INVITE_TYPES, INVITE_STATUS } from 'Constants/app.js';

import LandingSidebarAccInfo from './Landing.Sidebar.Acc.Info.jsx'
import Avatar from 'Widgets/Avatar.jsx';

class LandingSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: null,
			displayModal: false,
		};
	}

	render () {
		const { selectedItem, selectedItemAnalysis, user } = this.props;
		if (selectedItem === undefined || selectedItem === null) {
			if ('development' === process.env.NODE_ENV)
				return <LandingSidebarAccInfo />
			else
				return <div className="dashboard-sidebar" />;
		}

		const isMedia = 'FOLDER' !== selectedItem.mediaType

		// Set item ownership and collaboration permission
		const isOwner = selectedItem.ownerID === user.userID;

		// If current user is a sharee, set share permissions permision
		let canCollaborate = false;
		if (selectedItem.shares.users[user.userID]) {
			canCollaborate =
				selectedItem.shares.users[user.userID].permission ===
				SHARE_PERMISSIONS.INVITE_COLLABORATOR;
		}

		return (
			<div onClick={e => e.stopPropagation()} className="dashboard-sidebar">
				<div className="dashboard-sidebar-head">
					<h6>{I18n.get('Info')}</h6>
				</div>
				<div className="dashboard-sidebar-body">
					<div className="collaborators">

						<div className="collaborators-space-between">
							<h6 className="collaborators-title">{selectedItem.title}</h6>
						</div>
						{isMedia
							? this.renderMediaProjectInfo(selectedItem, selectedItemAnalysis)
							: this.renderFolderInfo(selectedItem)
						}
						<div className="collaborators-space-between">
							<p>{I18n.get('Created')}:&nbsp;{formatLocalDate(selectedItem.dateCreated)}</p>
						</div>
						{isMedia
							? this.renderCollabsAndSharesBlock(isOwner, canCollaborate)
							: null}
					</div>
				</div>
			</div>
		);
	}

	// this hides shares if you are not the owner of the item selected
	renderCollabsAndSharesBlock (isOwner, canCollaborate) {
		return (
			<div className="collaborators-list">
				<div className="collaborators-list-collab">
					<h5>{I18n.get('Collaborators')}
						{/* Not working 
                            {isOwner || canCollaborate ? (
                            <i
                                onClick={() =>
                                this.props.openModal(
                                    "collaborateModal",
                                    this.props.selectedItem.itemID
                                )}
                                className="cursor-pointer far fa-plus-circle fa-pull-right"
                            />
                            ) : null} */}
					</h5>
					{this.renderCollabsAndSharesList(
						this.props.selectedItemAnalysis.collaborators.users,
						INVITE_TYPES.COLLABORATE,
						isOwner,
						canCollaborate
					)}
				</div>
				{ ('development' === process.env.NODE_ENV)
					? <div className="collaborators-list-share">
						<h5>{I18n.get('Shares')}</h5>
						{this.renderCollabsAndSharesList(
							this.props.selectedItem.shares.users,
							INVITE_TYPES.SHARE,
							isOwner,
							canCollaborate
						)}
					</div>
					: null}
			</div>
		);
	}

	/**
	 * Renders the list of people shared/collaborated with
	 * @param {Object} users List of users
	 * @param {String} inviteType The type of the inivte - either INVITE_TYPES.SHARE or INVITE_TYPES.COLLABORATE
	 * @param {Bolean} isOwner True if the current user is the item owner
	 * @param {Boolean} canCollaborate True if the current user can invite collaborators 
	 */
	renderCollabsAndSharesList (users, inviteType, isOwner, canCollaborate) {
		// creating an array of users from the users object so we can filter
		let usersArr = Object.keys(users).map(item => ({
			userID: item,
			email: users[item].email,
			initials: users[item].initials,
			name: users[item].name,
			permission: users[item].permission,
			status: users[item].status,
		}));

		let statusArr = [INVITE_STATUS.ACCEPTED, INVITE_STATUS.LEFT, INVITE_STATUS.REVOKED];

		// if person is not the owner, they will only be shown people with accepted status
		if (!isOwner) statusArr = [INVITE_STATUS.ACCEPTED];

		// maps over the status array so we can filter results of the collaborators
		const userList = statusArr.map((status, i) => {
			if (status === 'REVOKED') return null;
			const filteredUsers = _.filter(usersArr, ['status', status]);
			if (filteredUsers.length < 1) return null;
			return (
				<div
					key={status}
					className={`collaborators-list-container collaborators-list-container-${status}`}>
					{/* will either display removed or the status of "left"*/}
					{status !== INVITE_STATUS.ACCEPTED ? (
						<p className="collaborators-list-subtitle">
							{status}
							{/* {status === 'REVOKED' ? 'REMOVED' : status} */}
						</p>
					) : null}
					<ul>
						<div className="collaborators-list-items">
							{this.renderOwnerListItems(
								filteredUsers,
								inviteType,
								status,
								isOwner,
								canCollaborate
							)}
						</div>
					</ul>
				</div>
			);
		});
		// if they are the owner, and all of the userList items return null, we want to display a No Collaborator message to the user
		// 1 is accepted, 2 is revoked, 3 is left
		if (isOwner) {
			// if (userList[0] === null && userList[1] === null && userList[2] === null)
			if (userList[0] === null && userList[2] === null)
				return <p className="collaborators-list-subtitle">{I18n.get('No')} {I18n.get('Collaborators')}</p>;
		}
		// if they are not the owner, and the userList items return null, we want to display a No Collaborator message to the user
		// don't believe we need this for a non owner, as they will never really see no users. (they will always be shown in side)
		else if (!isOwner) {
			if (userList[0] === null)
				return <p className="collaborators-list-subtitle">{I18n.get('No')} {I18n.get('Collaborators')}</p>;
		}
		return userList;
	}

	renderOwnerListItems (users, inviteType, status, isOwner, canCollaborate) {
		// gets keys of users in the collaborators or shares and turns into a map
		// then renders it out all pretty like.
		if (_.isEmpty(users)) return <div />;
		// gets a specific person from mapping over all users in the sidebar after they are sorted alphabetically
		return users
			.sort((a, b) => {
				const userA = a.name.toLowerCase();
				const userB = b.name.toLowerCase();
				if (userA < userB) return -1;
				if (userA > userB) return 1;
				return 0;
			})
			.map(person => {
				const listHover = classNames({
					'sidebar-delete-container': true,
					'hidden-content': this.state.hover === person.userID ? '' : 'none'
				});

				return (
					<li
						key={person.userID}
						onMouseEnter={() => this.handleMouseEnter(person.userID)}
						onMouseLeave={this.handleMouseLeave}>
						<div>
								<Avatar size="sm" initials={person.initials} />{' '}
								{person.userID === this.props.user.userID
									? I18n.get('You')
									: person.name || person.userID}
								{' '}
								<small>{person.email}</small>
						</div>

						{/* Only displays delete if status is accepted and you're the isOwner */}
						{status === 'ACCEPTED' && (isOwner || canCollaborate)
							? (
								<div className={listHover}>
									<button
										onClick={() => this.revokeUser(person.userID, inviteType)}
										className="dashboard-sidebar-body-delete">
										<i className="far fa-times" aria-hidden="true" />
									</button>
								</div>
							)
							: null
						}
						{/* Only displays delete if status is accepted and you're the not owner */}
						{/* Not working - modal does not open
                            {person.userID === this.props.user.userID && !isOwner  
                            ? (
                                <div className={listHover}>
                                    <button
                                        onClick={() =>
                                            this.props.openModal(
                                                'leaveModal',
                                                this.props.selectedItem.itemID
                                            )}
                                        className="dashboard-sidebar-body-delete">
                                        <div>{I18n.get('Leave')}</div>
                                    </button>
                                </div>
                            ) 
                            : null
                        } */}
					</li>
				);
			});
	}

	// Renders info of folder items
	renderFolderInfo = (selectedItem) => {
		return (
			<div>
				<div className="collaborators-space-between">
					<p>{I18n.get('Owner')}:&nbsp;{selectedItem.name}</p>
				</div>
				{ selectedItem.ltiCourseTitle
					? <div className="collaborators-space-between">
						<p>{I18n.get('Course')}:&nbsp;{selectedItem.ltiCourseTitle}</p>
					</div>
					: null}
			</div>
		)
	}

	// Renders info of video/audio items
	renderMediaProjectInfo = (selectedItem, selectedItemAnalysis) => {
		const size = formatStorage(selectedItem.s3inputSize || 0);
		const durationSeconds = formatVideoTime(selectedItem.durationSeconds || 0);

		return (
			<div>
				{ selectedItem.ownerID === selectedItemAnalysis.ownerID
					? <div className="collaborators-space-between">
						<p>{I18n.get('Owner')}:&nbsp;{selectedItemAnalysis.name}</p>
					</div>
					: <div>
						<div className="collaborators-space-between">
							<p>{I18n.get('Media Owner')}:&nbsp;{selectedItem.name}</p>
						</div>
						<div className="collaborators-space-between">
							<p>{I18n.get('Project Owner')}:&nbsp;{selectedItemAnalysis.name}</p>
						</div>
					</div>
				}
				{ selectedItem.ltiCourseTitle
					? <div className="collaborators-space-between">
						<p>{I18n.get('ltiCourseTitle')}:&nbsp;{selectedItem.ltiCourseTitle}</p>
					</div>
					: null}
				{ 'ATTACHED' === selectedItemAnalysis.ltiAttachmentStatus
					? <div>
						<div className="collaborators-space-between">
							<p>{I18n.get('Activity')}:&nbsp;{selectedItemAnalysis.ltiAssignmentTitle}</p>
						</div>
						{selectedItemAnalysis.ltiDateAttached
							? <div className="collaborators-space-between">
								<p>{I18n.get('Submitted')}:&nbsp;{formatLocalDate(selectedItemAnalysis.ltiDateAttached)}</p>
							</div>
							: null
						}
					</div>
					: null}
				<div className="collaborators-space-between">
					<p>{I18n.get('Size')}:&nbsp;{size}</p>
				</div>
				<div className="collaborators-space-between">
					<p>{I18n.get('Duration')}:&nbsp;{durationSeconds}</p>
				</div>
			</div>
		)
	}


	revokeUser = (userID, inviteType) => {
		const itemID = this.props.selectedItem.itemID;
		this.props.revokeUser(inviteType, itemID, userID);
	};

	handleMouseEnter = userID => {
		this.setState({ hover: userID });
	};

	handleMouseLeave = () => {
		this.setState({ hover: null });
	};
}

function mapStateToProps (state) {
	return {
		user: state.user.data,
		selectedItem: state.folderItems.selectedItem,
		selectedItemAnalysis: state.folderItems.selectedItemAnalysis,
	};
}

export default connect(mapStateToProps, { revokeUser, openModal })(LandingSidebar);
