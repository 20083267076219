// import _ from 'lodash'

import {
    TEMPLATES_FETCH
} from 'Constants/actions';

const INITIAL_STATE = {}; // NOTE: TEMPLATES_LOADING indicated by lack of state.templates.data

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		case TEMPLATES_FETCH: {
            return { 
                ...state,
                data: action.payload, 
            }};
    }
    
    return state;
}
