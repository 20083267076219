import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import { Loading } from 'Widgets/Loading.jsx';
import Transcription from './Transcription.jsx'
import TabnavItem from  '../Tabnav.item.jsx';

import {openModal} from 'actions/app';
import {itemAnalysisLobUpdate} from '../actions/index'

import { LOB_KEYS, PROGRESS_FLAGS, TRANSCRIBE_LANGUAGES } from 'Constants/app.js'

class TranscribeWrapper extends Component {

	constructor(props) {
        super(props);
        
        this.state = {
            activeTabIndex: false
        }
    }
    
	render() {
        const { 
            locked, 
            isItemAnalysisOwner, 
            isShare,
            item,
            itemAnalysisID, 
        } = this.props;
    
        // itemAnalysisLOBs and/or itemAnalysisID not yet loaded
        if( undefined === item ||
            undefined === itemAnalysisID ) return <Loading />

        let {activeTabIndex} = this.state;
        const maxHeight = this.props.bottombarHeight-100
        
        // transcriptionsProgress is a mapp of all transcription requests with their progress status
        const transcriptionsProgress = item.transcribeProgress || {};

        // Build tabs & tab contents
        var transcriptionTabs = [];     // Stores tab titles for BottombarTitle
        var transcriptionEditors = [];  // Stores a quill editor (<Transcription />) for each

        // Using Transcribe launguages here because it is already alpha sorted on written language 'French Canadian'
        _.forEach(TRANSCRIBE_LANGUAGES, (val, idx) => {  //idx is langugage code in format en-US, v is the written lang 'English US'
            
            // Does transcription exist for this item?
            if(undefined === transcriptionsProgress[idx]) return; // for _.forEach(), return is like continue; retrun false to break;
            
            // Get progress indicator
            const progress = transcriptionsProgress[idx]
            
            // Ignore errors
            if( PROGRESS_FLAGS.ERROR === progress ) return; 

            // If activeTab not set, set to first item that catches it
            if( false === activeTabIndex ) activeTabIndex = idx;

            transcriptionTabs.push(
                <TabnavItem
                    active={activeTabIndex === idx}
                    key={_.snakeCase(val)+'-'+idx}
                    index={idx}
                    text={val}
                    onItemClick={this.handleTabNavItemClick}
                />)

            transcriptionEditors.push(
                PROGRESS_FLAGS.INPROGRESS === progress 
                ? <div className="text-success"
                    key={idx}
                    style={{display: activeTabIndex === idx ? 'inline' : 'none'}}
                    >
                    <strong><i>{`${val} transcription in progress...`}</i></strong>
                    </div>
                : <Transcription
                    key={idx}
                    active={activeTabIndex === idx}
                    itemAnalysisID={itemAnalysisID}
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    locked={locked}
                    maxHeight={maxHeight}
                    transcriptionKey={LOB_KEYS.TRANSCRIPTION + idx}
                    />               
            )
        });

		return (
            <div>
                <BottombarTitle
                    activeTab='transcribe' 
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    isShare={isShare}
                    handleOnManageBtnClick={this.handleManageTranscriptionClick}
                    locked={locked}
                    title = 'Transcription'
                    >
                    { transcriptionTabs.length > 0
                    ? <ul className='nav nav-tabs'>{transcriptionTabs}</ul>
                    : null
                    }
                </BottombarTitle> 
                { // Only render big transcription button if no transcriptions exist
                    transcriptionTabs.length > 0
                    ? <div
                        className="col-12 editor"
                        id="quillContainer"
                        style={{
                            maxHeight: maxHeight,
                            height: 'auto'
                        }}>
                        {transcriptionEditors}
                        </div>
                    : this.renderManageTranscriptionsButton(isItemAnalysisOwner, isShare)
                }       
            </div>
		);
    }

    /**
     * Renders large Manage Transcriptions button or no transcriptions message if !isItemAnalysisOwner
     */
    renderManageTranscriptionsButton =(isItemAnalysisOwner, isShare) => {
        // TODO: isShare is Tmp addition to prevent transcribing of shared items
        if(isItemAnalysisOwner && !isShare)
            return (
                <ButtonIcon
                    style={{marginTop: '.7rem'}}
                    btnClass="dataset-upload-button btn btn-outline-secondary"
                    iconClass="far fa-bullhorn"
                    label='Manage Transcriptions'
                    onClick={this.handleManageTranscriptionClick}
            /> );
        else 
            return (<small style={{padding: '.7rem .2rem' }}>No transcriptions.</small>)
    }

    /**
     * Handles clicking of manage Transcription button both in this component & <BottombarTitle />
     */
	handleManageTranscriptionClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.openModal('transcribeModal', '');
    }
    
    /**
     * Handles changing of current active tab when tab is clicked
     */
	handleTabNavItemClick = ix => {
		this.setState({
			activeTabIndex: ix
		});
	};
}

const mapStateToProps = state => {
	return {
        bottombarHeight: state.app.bottombarHeight,
        item: state.folderItems.selectedItem,
	};
};

const mapDispatchToProps = {
    openModal,
    itemAnalysisLobUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(TranscribeWrapper);
