import _ from 'lodash'

import {
    _ONLYUS,
    _SET_VAR,
    _UNSET_VAR
} from 'Constants/actions';

const INITIAL_STATE = {
    // onlyus: false    // Maintenance access PLUS RouteListener.jsx
    onlyus: true
};

export default function(state = INITIAL_STATE, action) {
    const {type, payload} = action;

    switch(type) {

        // Resets state before its used with signup/signin variables
		// case CUNAUTH_USER: {
        //     return INITIAL_STATE
        // }

        case _ONLYUS: {
            // payload is binary true/false
            return {
                ...state,
                onlyus: true
            }
        }

        case _SET_VAR: {
            // payload is map of key value paid { key: [key], value: [value]}
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case _UNSET_VAR: {
            // payload is key to unset
            return {
                ..._.omit(state, action.payload)
            }
        }
    }
	return state;
}
