import React, { Component } from 'react';

class BottombarTabs extends Component {
	render() {
        const { 
            activeTab, 
			showCharts,
			showGrade 
        } = this.props;
		
		const listItem = (activeTabName, icon) => {
			return (
				<li className={`list-group-item ${activeTab === activeTabName ? 'active' : null}`}
					onClick={() => this.props.handleChangeTabClick(activeTabName)}>
					<i className={`far fa-${icon}`} />
				</li>
			)
		}
        
		return (
			<ul className="list-group">
				{ listItem('dashboard', 'bullseye-pointer') }
				{ listItem('editor', 'file-alt') }
				{ listItem('fileAssets', 'paperclip') }
				{ listItem('transcribe', 'bullhorn')}
				{ listItem('vtt', 'closed-captioning')}

				{ showGrade	 ? listItem('grade', 'hand-holding-seedling') : null }
				{ showCharts ? listItem('data-visualizer', 'chart-line')  : null }

				{/* { listItem('timeline','sliders-h') } */}
			</ul>
		);
	}
}

export default BottombarTabs;
