import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import { I18n } from '@aws-amplify/core';

import {toggleEventTagVisibility} from './actions/index';
import {INVITE_STATUS, VISIBILITY} from 'Constants/app'

const SHOW_ALL = <span>{_.upperFirst(I18n.get('Show all'))}</span>

class TabFilters extends Component {

	constructor(props) {
		super(props);

		this.state = {
			visibilityAnalysisMode: VISIBILITY.COLLABORATORS,
			analysisVisibilityDDVisible: false,
			collabFilterDDVisible: false,
			filterDDVisible: false,
			tagFilterDDVisible: false,
			sortDDVisible: false
		};
	}

	componentDidMount() {
		this.setState({visibilityAnalysisMode: this.props.analysisVisibility})
	}

	//NOTE: This is components shouldComponentUpdate() is called each time current tab becomes active due to this.props.activeTab
	shouldComponentUpdate(nextProps, nextState) {
		if(this.props.analysisVisibility !== nextProps.analysisVisibility) {
			this.setState({visibilityAnalysisMode: nextProps.analysisVisibility})
		}
		return true
	}

	render() {
		const {
			itemAnalysisDbsData,
			itemAnalysisDbTagsData,
			itemAnalysisMarkersData,
            isItemAnalysisOwner,
            
            userDashboardTagsData,
            userDashboardsData,
			userMarkersData,
			
			sort,	// sorting filters

			planLimits
        } = this.props;

		if(isItemAnalysisOwner) {
            var markersData = userMarkersData;
            var dbsData = userDashboardsData;
            var dbTagsData = userDashboardTagsData
        }
        else {
            var markersData = itemAnalysisMarkersData;
            var dbsData = itemAnalysisDbsData;
            var dbTagsData = itemAnalysisDbTagsData
		}

		// Dropdown Classes
		const analysisVisibilityDDClasses = classNames({
			dropdown: true,
			show: this.state.analysisVisibilityDDVisible
		});
		const collabDdClasses = classNames({
			dropdown: true,
			show: this.state.collabFilterDDVisible
		});
		const markerDdClasses = classNames({
			dropdown: true,
			show: this.state.filterDDVisible
		});
		const tagDdClasses = classNames({
			dropdown: true,
			show: this.state.tagFilterDDVisible
		});
		const sortDdClasses = classNames({
			dropdown: true,
			show: this.state.sortDDVisible
		});

		return (
			<div className="filterbar">
				{/** Analysis Visibility Toggle */}
				<div
					className={analysisVisibilityDDClasses}
					data-toggle="tooltip"
					title={`${I18n.get('Analysis visibility')}${!planLimits.confidential ? ' - availble on upgraded plans' : null}`}
					onMouseLeave={() => this.handleDDLeave('analysisVisibilityDDVisible')}>
					<button
						className="dropdown-toggle btn filterbar-btn btn-outline-secondary"
						disabled={!planLimits.confidential}
						onClick={e => this.handleDDClickToggle(e, 'analysisVisibilityDDVisible')}>
						<i className={`far ${VISIBILITY.COLLABORATORS === this.state.visibilityAnalysisMode ? `fa-eye text-success` : `fa-eye-slash text-danger`}`} />
					</button>
					<div className="dropdown-menu">{this.renderAnalysisVisibilityToggle()}</div>
				</div>


				{/** Tags Filter */}
				<div
					className={tagDdClasses}
					data-toggle="tooltip"
					title={I18n.get('Filter by tag')}
					onMouseLeave={() => this.handleDDLeave('tagFilterDDVisible')}>
					<button
						className="dropdown-toggle btn filterbar-btn btn-outline-secondary"
						onClick={e => this.handleDDClickToggle(e, 'tagFilterDDVisible')}>
						<i className="far fa-bullseye-pointer" />
					</button>
					<div className="dropdown-menu">{this.renderDashboardsFilter(dbsData, dbTagsData)}</div>
				</div>

				{/** Markers Filter */}
				<div
					className={markerDdClasses}
					data-toggle="tooltip"
					title={I18n.get('Filter by marker')}
					onMouseLeave={() => this.handleDDLeave('filterDDVisible')}>
					<button
						className="dropdown-toggle btn filterbar-btn btn-outline-secondary"
						onClick={e => this.handleDDClickToggle(e, 'filterDDVisible')}>
						<i className="far fa-bookmark" />
					</button>
					<div className="dropdown-menu">{this.renderMarkersFilter(markersData)}</div>
				</div>

				{/** Collaborators Filter */}
				<div
					className={collabDdClasses}
					data-toggle="tooltip"
					title={I18n.get('Filter by collaborator')}
					onMouseLeave={() => this.handleDDLeave('collabFilterDDVisible')}>
					<button
						className="dropdown-toggle btn filterbar-btn btn-outline-secondary"
						onClick={e => this.handleDDClickToggle(e, 'collabFilterDDVisible')}>
						<i className="far fa-users" />
					</button>
					<div className="dropdown-menu">{this.renderCollaboratorsFilter()}</div>
				</div>

				{/** Sort Button */}
				<div
					className={sortDdClasses}
					data-toggle="tooltip"
					title="Sort..."
					onMouseLeave={() => this.handleDDLeave('sortDDVisible')}>
					<button
						className="dropdown-toggle btn filterbar-btn btn-outline-secondary"
						onClick={e => this.handleDDClickToggle(e, 'sortDDVisible')}>
						<i className="far fa-sort-amount-down" />
					</button>
					<div className="dropdown-menu">
						<h6 key="srtHdr" className="dropdown-header">
							<i>{I18n.get('Sort by')}</i>
						</h6>
						<a
							href=""
							className="dropdown-item"
							onClick={e => {
								e.preventDefault();
								this.props.handleSort(
									'mediaTimestamp',
									sort.order === 'asc' ? 'desc' : 'asc'
								);
							}}>
							<span>{I18n.get('Time code')} </span>
							{sort.order === 'asc' ? (
								<i className="far fa-arrow-down" />
							) : (
								<i className="far fa-arrow-up" />
							)}
						</a>
					</div>
				</div>

				{/** Search Input */}
                <div className="filterbar-group" >
                    <input className="form-control" 
					    onBlur={this.handleSearchBlur}
                        onKeyDown={this.handleSearchInputKeyDown}
                        onKeyUp={this.handleSearchInputKeyUp}
                        type="text"
                        />
                    <div className="filterbar-group-icon" style={{cursor: 'pointer'}}>
                        <i className="far fa-search" onClick={this.handleSearchBlur} />
                    </div>
                </div>
			</div>
		);
	}

	/** RENDERERS */
	/** Creates & returns the analysisVisibility toggle and menu  */
	renderAnalysisVisibilityToggle = () => {
		const {visibilityAnalysisMode} = this.state;

		let filtersArr = [];

		filtersArr.push(
			<h6 key="tgFltrHdr" className="dropdown-header">
				<i>{I18n.get('Analysis visibility')}</i>
			</h6>
		);
		
		const option = (mode) => {
			const visibilityClasses = classNames({
				'dropdown-item': true,
				'text-danger': (VISIBILITY.PRIVATE === visibilityAnalysisMode) && (VISIBILITY.PRIVATE === mode),
				'text-success': (VISIBILITY.COLLABORATORS === visibilityAnalysisMode) && (VISIBILITY.COLLABORATORS === mode),
			})

			return (
				<a
					key={mode}
					href=""
					className={visibilityClasses}
					onClick={e => this.handleEventTagsVisibilityToggle(e, mode)}>{' '}
					<span>{mode}</span>
					{VISIBILITY.COLLABORATORS === mode 
					? <i className="far fa-eye pull-right" />
					: <i className="far fa-eye-slash pull-right" />
					}
				</a>
			)}

		filtersArr.push(option(VISIBILITY.COLLABORATORS))
		filtersArr.push(option(VISIBILITY.PRIVATE))

		return filtersArr;
	}

	/** Creates & returns the collaborators filter button and menu  */
	renderCollaboratorsFilter = () => {
		const {
			collaborators,
			currentUserID, 
			itemAnalysisOwner, 
			isItemAnalysisOwner,
			filterByCollaborator,
		} = this.props;

		let filtersArr = [];
		filtersArr.push(
			<h6 key="--cFa" className="dropdown-header">
				<i>{I18n.get('Filter by collaborator')}</i>
			</h6>
		);

		// Push current user as the first item
		filtersArr.push(
			<a
				key="--cFb"
				href=""
				className="dropdown-item"
				onClick={e => {
					e.preventDefault();
					this.props.handleFilterBy('filterByCollaborator', currentUserID);
				}}>
				<span>You {isItemAnalysisOwner ? ' (owner)' : null }</span>
				{filterByCollaborator === currentUserID ? (
					<i className="far fa-check pull-right" />
				) : null}
			</a>
		);

		// Push itemAnalysisOwner owner as the next item, only if the currentUser is not the same as the itemAnalysisOwner
        if (!isItemAnalysisOwner) {
			filtersArr.push(
				<a
					key={`${_.snakeCase(
						_.lowerCase(itemAnalysisOwner.name)
					)}-${itemAnalysisOwner.ownerID}-cF`}
					href=""
					className="dropdown-item"
					onClick={e => {
						e.preventDefault();
						this.props.handleFilterBy(
							'filterByCollaborator',
							itemAnalysisOwner.ownerID
						);
					}}>
					<span>Owner: {itemAnalysisOwner.name}</span>
					{filterByCollaborator === itemAnalysisOwner.ownerID ? (
						<i className="far fa-check pull-right" />
					) : null}
				</a>
			);
		}

		// Add itemAnalysis owner to collaborators map
		_.map(collaborators, (value, id) => {
			if (INVITE_STATUS.ACCEPTED === value.status && id !== currentUserID) {
				filtersArr.push(
					<a
						key={`${_.snakeCase(value.name)}-${id}-cF`}
						href=""
						className="dropdown-item"
						onClick={e => {
							e.preventDefault();
							this.props.handleFilterBy('filterByCollaborator', id);
						}}>
						<span>{value.name}</span>
						{filterByCollaborator === id ? (
							<i className="far fa-check pull-right" />
						) : null}
					</a>
				);
			}
		});
		filtersArr = _.orderBy(filtersArr, 'key', 'asc');
		filtersArr.push(<div key={`cF-div`} className="dropdown-divider" />);
		filtersArr.push(
			<a
				key="cFShowall"
				href=""
				className="dropdown-item"
				onClick={e => {
					e.preventDefault();
					this.props.handleFilterBy('filterByCollaborator', null);
				}}>
				{SHOW_ALL}
				{filterByCollaborator === null ? <i className="far fa-check pull-right" /> : null}
			</a>
		);

		return filtersArr;
	}

	/** Creates & returns the dashboard filter button and menu  */
	renderDashboardsFilter = (dbsData, dbTagsData) => {
		const {
			dashboards,
			filterByDashboardTag,
		} = this.props;

		let filtersArr = [];

		filtersArr.push(
			<h6 key="tgFltrHdr" className="dropdown-header">
				<i>{I18n.get('Filter by tag')}</i>
			</h6>
        );
        
        for(var index=0; index<dashboards.length; index++) { 
            let dashboardID = dashboards[index];
            filtersArr.push(
                <h6 key={`tgFltrHdr-${dashboardID}`} className='dropdown-header'>
                    <i>{dbsData[dashboardID].title}</i>
                </h6> 
            );

            for(var idx=0; idx<dbsData[dashboardID].dashboardTags.length; idx++) { 
                let dashboardTagID = dbsData[dashboardID].dashboardTags[idx]
                let tag = dbTagsData[dashboardTagID];
                if ('BUTTON' === tag.type) {
                    filtersArr.push(
                        <a
                            key={`${dashboardID}-${dashboardTagID}`}
                            href=""
                            className="dropdown-item"
                            onClick={e => {
                                e.preventDefault();
                                this.props.handleFilterBy('filterByDashboardTag', dashboardTagID);
                            }}>{' '}
                            <span>{_.capitalize(tag.label)}</span>
                            {filterByDashboardTag == dashboardTagID ? (
                                <i className="far fa-check pull-right" />
                            ) : null}
                        </a>
                    );
                }
            }
        }

		filtersArr.push(<div key={`tgFltr-div`} className="dropdown-divider" />);
		filtersArr.push(
			<a
				key="tgFltr-showall"
				href=""
				className="dropdown-item"
				onClick={e => {
					e.preventDefault();
					this.props.handleFilterBy('filterByDashboardTag', null);
				}}>
				{SHOW_ALL}
				{null === filterByDashboardTag ? <i className="far fa-check pull-right" /> : null}
			</a>
		);

		return filtersArr;
	}

	/** Creates and returns the markers filter and menu */
	renderMarkersFilter = (markersData) => {
		const {
			filterByMarkers,
			markers
		} = this.props;

		let filtersArr = [];

		filtersArr.push(
			<h6 key="mrkrFltrHdr" className="dropdown-header">
				<i>{I18n.get('Filter by marker')}</i>
			</h6>
		);
		markers.forEach((markerID, index, array) => {
			_.map(markersData[markerID].options, (value, key) => {
				const id = `mrkrFltr-${index}-${markerID}-${key}`;
				filtersArr.push(
					<a
						key={id}
						href=""
						className="dropdown-item"
						onClick={e => {
							e.preventDefault();
							this.props.handleMarkerFilter(markerID, key);
						}}>
						<span>{value}</span>
						{filterByMarkers[markerID] == key ? (
							<i className="far fa-check pull-right" />
						) : (
							''
						)}
					</a>
				);
			});
			filtersArr.push(
				<div key={`mrkrFltrDiv-${markerID}`} className="dropdown-divider" />
			);
		});

		filtersArr.push(
			<a
				key="fltr-a-showall"
				href=""
				className="dropdown-item"
				onClick={e => {
					e.preventDefault();
					this.props.handleMarkerFilter();
				}}>
				{SHOW_ALL}
				{_.isEmpty(filterByMarkers) ? <i className="far fa-check pull-right" /> : null}
			</a>
		);

		return filtersArr;
	}

	/** HANDLES */
	handleDDClickToggle = (e, dropdown) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			[dropdown]: !this.state[dropdown]
		});
	};

	handleDDLeave = dropdown => {
		this.setState({
			[dropdown]: false
		});
	};
	
	handleEventTagsVisibilityToggle = (e, mode) => {
		e.preventDefault();
		e.stopPropagation();
		
		this.setState({visibilityAnalysisMode: mode })

		const {currentUserID, isItemAnalysisOwner} = this.props;
		
		this.props.toggleEventTagVisibility( mode, currentUserID, isItemAnalysisOwner )
		.then( resp => {})
		.catch( err => {
			this.setState({visibilityAnalysisMode: mode === VISIBILITY.COLLABORATORS ? VISIBILITY.PRIVATE : VISIBILITY.COLLABORATORS })
		})
		this.handleDDLeave('analysisVisibilityDDVisible')
	}

	/** SEARCH HANDLES */
	handleSearchInputKeyDown = (e) => {
		// isEnter?
		if (e.keyCode == 13) {
            e.preventDefault();
            this.handleSearchBlur(e)
        }
	};

    // debounces the calling search
    debounceSearch = _.debounce(this.props.handleSearch, 100);

    handleSearchInputKeyUp = (e) => {
        this.debounceSearch(e.target.value);
    }
    
	handleSearchBlur = e => {
        this.debounceSearch(e.target.value);
        this.debounceSearch.cancel;
	};
}

function mapStateToProps(state) {
	return {
        markers: state.folderItems.selectedItemAnalysis.markers,
        dashboards: state.folderItems.selectedItemAnalysis.dashboards,
        itemAnalysisDbsData: state.itemAnalysisDbs.data,
		itemAnalysisDbTagsData: state.itemAnalysisDbTags.data,
		itemAnalysisMarkersData: state.itemAnalysisMarkers.data,
        userDashboardsData: state.userDashboards.data,
        userDashboardTagsData: state.userDashboardTags.data,
        userMarkersData: state.userMarkers.data
	};
}

export default connect(mapStateToProps, {toggleEventTagVisibility})(TabFilters);

// Sort by title
// <a
// 	href=""
// 	className="dropdown-item"
// 	onClick={event => {
// 		event.preventDefault();
// 		this.props.handleSort(
// 			'title',
// 			'asc'
// 		);
// 	}}>
// 	Title <i className="far fa-arrow-down" />
// 	{sort.iteratee === 'title' &&
// 		sort.order === 'asc'
// 		? <i className="far fa-check pull-right" />
// 		: ''}
// </a>
// <a
// 	href=""
// 	className="dropdown-item"
// 	onClick={event => {
// 		event.preventDefault();
// 		this.props.handleSort(
// 			'title',
// 			'desc'
// 		);
// 	}}>
// 	Title <i className="far fa-arrow-up" />
// 	{sort.iteratee === 'title' &&
// 		sort.order === 'desc'
// 		? <i className="far fa-check pull-right" />
// 		: ''}
// </a>
