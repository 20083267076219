import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const MarkersMenuBtn = ({ handleOnClick, locked }) => (
	<MenuBtn
		label={I18n.get('Markers')}
		title={locked ? I18n.get('Remove lock to manage Markers') : I18n.get('Manage Markers')}
		disabled={locked}
		onClick={e => {
			handleOnClick(e, 'modalMarkerSelector');
		}}
		iconClass="bookmark text-danger"
	/>
);

export default MarkersMenuBtn;