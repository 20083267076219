import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const RenameItemMenuBtn = ({ handleOnClick }) => (
	<MenuBtn
		title={I18n.get('Rename')}
		onClick={e => { handleOnClick(e) }}
		iconClass="edit text-info"
	/>
);

export default RenameItemMenuBtn;