import _ from 'lodash'
import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {StripeProvider, Elements} from 'react-stripe-elements';
import {STRIPEAPIKEY} from 'config.js';

import {
	accountPreviewUpgrade, 
	accountProcessUpgrade, 
	paymentIntializeState, 
	stripeSetupIntent
} from './actions/payment'

import BillingCardForm from './Payment/BillingCardForm.jsx'
import Message from 'Widgets/Message.jsx';
import Modal from 'Widgets/Modal.jsx';
import {Loading} from 'Widgets/Loading.jsx';

import {ROUTES} from 'Constants/app.js';
import {formatLocalDate, formatCurrency} from '../../utils';

class ModalChangePlan extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {loading: false};  // only used for onMakePaymentClick()
	}
	
	UNSAFE_componentWillMount() {
		const {paymentPeriod, selectedPlan, prorationDate } = this.props.upgradeData;
		this.props.stripeSetupIntent(selectedPlan.planID, paymentPeriod)
		.then( resp => {
			const upgradeValues = {
				planID: selectedPlan.planID, 	
				paymentPeriod, prorationDate, coupon: ''
			}
			this.setState({upgradeValues})
		})
		.catch( err => {
			this.setState({
				messages: {error: true, message: 'Failed to create payment intention. Please try again.'}
				});
		});
	}

	componentWillUnmount() {
		this.props.paymentIntializeState();
	}
    
	render() {
        const {account, upgradeData, messages, payment} = this.props;
		const {loading, response, status} = payment;

		if(loading || status === null) 
			return this.renderMessageModal('Loading...', 'far fa-circle-notch fa-spin', null);

		// Stripe not yet loaded
		if(window.Stripe === undefined)
			return this.renderMessageModal('Loading payment provider...', 'far fa-circle-notch fa-spin', null);

		// TODO: Does this need doing? handle when source exists but setupIntent.status !== requires_payment_method but requires 3D (status == requires_confirmation). See https://stripe.com/docs/payments/intents#intent-statuses
		switch(status) {
			// Step 1A: Render Billing Card Form
			case null:
			case 'SETUP_INTENT': {
				return (
					<Modal size="modal-lg" forceCloseButtonUse={true}>
						<div className="modal-header">
							<div className="modal-subtitle">Dreamaker.io Subscriptions</div>
							<div className="modal-title">Upgrade confirmation</div>
						</div>
						<StripeProvider apiKey={STRIPEAPIKEY}>
							<Elements>
								<BillingCardForm 
									cancel = {this.context.closeModal}
									cs = {response.setupIntent.client_secret}
									handleCardSetup={this.handleCardSetup}
									modalTitle = 'Card details' 
									showCouponField = {true} 
									submitBtnTitle = 'Proceed'
									>
									{this.renderPlanSelection()}
								</BillingCardForm>
							</Elements>
						</StripeProvider>
					</Modal>
				)}
			
			// Step 1B: Render preview of plan change and costs 
			case 'PREVIEW_CHANGE':
			// Step 2: Render 'Processing' model
			case 'CONFIRMING_CHANGE': {
				const modalBodyClasses = classNames({
					'modal-body': true,
					'modal-body-changePlan': true,
					disabled: this.state.loading
				})
				return (
					<Modal size="modal-md" forceCloseButtonUse={true} >
						<div className="modal-header">
							<div className="modal-subtitle">Dreamaker.io Subscriptions</div>
							<div className="modal-title">Plan change confirmation</div>
						</div>
						<div className={modalBodyClasses} style={{padding: '1rem 2.5rem'}}>
							<p style={{marginTop: 0, marginBottom: '1rem'}}>
								Changing to the{' '}
								<b>
									{upgradeData.paymentPeriod.toLowerCase()} {upgradeData.selectedPlan.title.toLowerCase()} Plan
								</b>{' '}
								from the{' '}
								<b>
									{account.paymentPeriod.toLowerCase()}{' '}
									{account.planData.title.toLowerCase()} Plan
								</b>{' '}
								will result in the below charges:
							</p>
							{ messages.error ? <Message message={messages.message} textClass='danger' style={{fontSize: '.9rem'}} /> : null}
							{ response.upcomingInvoice.starting_balance 
							? <div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										fontWeight: 400,
										borderBottom: '1px solid lightgrey',
										marginBottom: '5px'
									}}>
									<span>Account opening balance:</span>
									<span>{formatCurrency(-response.upcomingInvoice.starting_balance)}</span>
								</div>
							: null
							}
							<div
								style={{display: 'flex', justifyContent: 'space-between', fontWeight: 600}}>
								<span>Charge due date:</span>
								<span>{formatLocalDate(response.upcomingInvoice.date * 1000, true)}</span>
							</div>
							<div
								style={{display: 'flex', justifyContent: 'space-between', fontWeight: 600}}>
								<span>Amount due:</span>
								<span>
									{formatCurrency(response.upcomingInvoice.amount_due)}
									{response.upcomingInvoice.ending_balance ? ' *' : null}
								</span>
							</div>
							<p>The following card will be charged:</p>
							<div style={{display: 'flex', justifyContent: 'space-between'}}>
								<p style={{fontWeight: 400, margin: 0}}>
									{_.upperFirst(response.card.brand)} **** {response.card.last4}
								</p>
								{this.props.billingCard
									? <Link disabled={this.state.loading} to={ROUTES.BILLING} style={{fontSize: '.9rem'}}>Change card?</Link> 
									: <div />
								}
							</div>
						</div>
						<div className="modal-footer">
							<button
								disabled={this.state.loading}
								type="button"
								className="btn btn-danger"
								data-dismiss="modal"
								onClick={this.context.closeModal}>
								Cancel
							</button>
							<button
								disabled={this.state.loading}
								type="button"
								className="btn btn-primary"
								data-dismiss="modal"
								onClick={this.onMakePaymentClick}>
								Make payment{' '}
								{this.state.loading && <i className="far fa-spin fa-spinner" />}
							</button>
						</div>
						{response.upcomingInvoice.ending_balance 
						? <div
							className="modal-footer"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								fontWeight: 400,
								color: 'darkgrey'
							}}>
							<small>
								<i>
									Your account will have{' '}
									{formatCurrency(-response.upcomingInvoice.ending_balance)} credit after
									this charge. The credit is from the unused time on your current plan
									& will be applied to all upcoming invoices.
								</i>
							</small>
						</div>
						: null
						}
					</Modal>
				)}

			// Step 3: Render 'Success' model
			case 'CHANGE_SUCCESS': {
				return this.renderMessageModal('Plan successfully updated!', 'far fa-check', 'Start analyzing!');
			}
		}
	}
	
	// render modal contaiing a message
	renderMessageModal = (loadingMessage, icon, showButton) => {
		return (
			<Modal size="modal-md" forceCloseButtonUse={true} >
				<div className="modal-header">
					<div className="modal-subtitle">Dreamaker.io Subscriptions</div>
					<div className="modal-title">Plan change confirmation</div>
				</div>
				<div className="modal-body" style={{padding: '1rem 2.5rem', textAlign: 'center'}}>
					<Loading icon={icon} message={loadingMessage} />
				</div>
				{showButton 
				? <div className="modal-footer">
						<Link
							className="btn btn-primary"
							data-dismiss="modal"
							style={{color: '#fff'}}
							to={`${ROUTES.LANDING}/0`}
							>
							{showButton}
						</Link>
					</div>
				: null
				}
			</Modal>
		);
	};
	
	// Displays plan data selected
	renderPlanSelection = () => {
		const {paymentPeriod, selectedPlan} = this.props.upgradeData;
		
		return (
			<div style={{marginBottom: '1rem'}}>
				<p>Congratulations, the{' '}
					<b>
						{paymentPeriod.toLowerCase()}{' '}
						{selectedPlan.title.toLowerCase()} Plan
					</b>, great choice! Your card will be charged{' '}
					<b>${selectedPlan[`cost${paymentPeriod}`]} {paymentPeriod}.</b>
				</p>
			</div>
		)
	}

	/** Sends card details to stripe and handles SCA/3D 
	 * STRIPE TEST CARDS - https://stripe.com/docs/testing#international-cards
	 No Auth Card
	 * 4242424242424242: No authentication required
	 SCA Cards
	 * 4000002500003155: Requires authentication for one-time payments. No further authentication needed. 
	 * 4000002760003184: Requires authentication on all transactions, regardless of how the card is set up.
	 * 4000008260003178: Requires authentication for one-time payments. All payments will be declined with an insufficient_funds failure code even after being successfully authenticated or previously set up - 
	 * 					 NOTE: This card (the above card) triggers the email flow for insufficient funds when doing an change, rather than returning failure
	 3D Secure Cards
	 * 4000000000003220: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000000000003063: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000008400001629: 3D Secure required but payments will be declined with a card_declined failure code after authentication. By default, your Radar rules will request 3D Secure authentication for this card. 
	 * 378282246310005: 3D Secure is not supported on this card and cannot be invoked. The PaymentIntent will proceed without performing authentication.
	 */
	handleCardSetup = (stripeHandleCardSetup, payment_method_data, coupon) => {
		return stripeHandleCardSetup(
			this.props.payment.response.setupIntent.client_secret,
			{payment_method_data}
		)
		.then(resp => {
			if (resp.error) {
				return Promise.reject(resp.error)
			}
			const upgradeValues = {
				...this.state.upgradeValues,
				coupon, 
				stripePaymentMethodID: resp.setupIntent.payment_method
			}
			this.setState({upgradeValues})
			return this.previewUpgrade(upgradeValues)
		})
		// resp.error above falls thru to this too. Also, this would be a 400's, internet connection issues
		.catch(err => {	
			return Promise.reject(err)
		});
	};

	/** Called on SCA/3D Secure success to preview upgrade */
	previewUpgrade = (upgradeValues) => {
		return this.props.accountPreviewUpgrade(upgradeValues)
		.then(resp => {
			return Promise.resolve(resp);
		})
		.catch(err => {
			this.setState({
				loading: false,
				messages: {error: true, message: err}
				});
			return Promise.reject(err);
		});
	};

	/** Make the actual payment */
	onMakePaymentClick = () => {
		this.setState({loading: true});

		this.props.accountProcessUpgrade(this.state.upgradeValues)
		.then(resp => {
			this.setState({loading: false});
		})
		.catch(err => {
			this.setState({
				loading: false,
				messages: {error: true, message: err}
				});

		});
	}
}

function mapStateToProps(state) {
	return {
		account: state.account,
		billingCard: state.billingDetails.card || null,
		messages: state.messages,
		payment: state.payment,
		upgradeData: state.modalOpen.data,
	};
}

const mapDispatchToProps = {
	accountPreviewUpgrade,
	accountProcessUpgrade,
	paymentIntializeState,
	stripeSetupIntent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePlan);
