import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import Grade from './Grade.jsx';

class GradeWrapper extends Component {
	render() {
        const {  
			itemAnalysis,
            isItemAnalysisOwner, 
        } = this.props;

        return (
            <div>
                <BottombarTitle
                    isItemAnalysisOwner={isItemAnalysisOwner}
                    activeTab='grade' 
                    title={`Grade Activity: ${itemAnalysis.ltiAssignmentTitle}`}
                />
				<Grade />
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
        itemAnalysis: state.folderItems.selectedItemAnalysis
	};
}

export default connect(mapStateToProps)(GradeWrapper);