import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';

import BottombarTitle from '../Bottombar.Title.jsx';
import ButtonIcon from 'Widgets/ButtonIcon.jsx';
import { Loading } from 'Widgets/Loading.jsx';

import {openModal} from 'actions/app';

import{ getExtensionIcon } from 'utils/'

import { getFileAssetLink, fetchFileAssetLobs } from './actions/index';

import { fileAssetLobsData } from './utils/index';

import { PROGRESS_FLAGS, ROLES } from 'Constants/app.js'

class FileAssetsWrapper extends Component {
	
	componentDidMount() {
		// load file lobs if not
		const { 
			itemAnalysisLOBs,
			itemAnalysis: {itemAnalysisID},
		 } = this.props;  

		const {loading, filesAvailable} = fileAssetLobsData(itemAnalysisLOBs[itemAnalysisID])
		
		// 'LOAD' - needs loading. false | true has loaded. undefined - no files exist
		if('LOAD' === loading) { 
			this.props.fetchFileAssetLobs(itemAnalysisID, filesAvailable)
		}
    }
    
	render() {
		const { 
			bottombarHeight,
            isItemAnalysisOwner, 
			itemAnalysisLOBs,
			itemAnalysis: { itemAnalysisID },
            locked, 
			role,
		 } = this.props;  

		if (undefined === itemAnalysisID) return <Loading />
		
		const lobs = itemAnalysisLOBs[itemAnalysisID] || {};		
		const {loading} = fileAssetLobsData(lobs)
		const filesAssetsList = this.renderFileAssets()

		const maxHeight = bottombarHeight-60

		return (
			<div>
				<BottombarTitle
					activeTab='fileAssets'
					isItemAnalysisOwner={isItemAnalysisOwner}
					handleOnManageBtnClick={this.handleManageFileAssetsBtnClick}
					loading={loading}
					locked={locked}
					role={role}
					title='Attachments'
				/>

				<div
					className="col-12 editor"
					style={{
						height: 'auto',
						maxHeight: maxHeight,
						overflowY: 'auto',
					}}>
					{loading
						? <Loading />
						: filesAssetsList.length > 0				// Only render big manage button if no files exist
							? <ul className="list-group">
								{filesAssetsList}
							</ul>
							: this.renderManageFileAssetsButton()
					}
				</div>
            </div>
		);
    }

	// Renders a list of downloadable fileAssets, if they exist.
	renderFileAssets = () => {
		const { 
			currentUserID,
			isItemAnalysisOwner,
			itemAnalysisLOBs,
			itemAnalysis
		} = this.props;  
		
		const { itemAnalysisID, ltiCourseID } = itemAnalysis // ltiCourseID used to identify if this is an lti course
		const itemAnalysisOwnerID = itemAnalysis.ownerID

		const lobs = itemAnalysisLOBs[itemAnalysisID];
		const {loading, filesAvailable} = fileAssetLobsData(lobs)

		// If there are no assets or assets are loading, render nothing
		if (loading || 0 === filesAvailable.length ) return [];

		var renderedFileAssets = []

		for (const lobKey of filesAvailable) {
			// Undefined if its been deleted in session (has not been deleted from itemAnalysis)
			if (undefined === lobs[lobKey]) continue;

			const { deleted, fileID, fileNameBase, fileNameExtension, name, uploadProgress } = lobs[lobKey]
			if (deleted) continue;
			const fileOwnerID = lobs[lobKey].ownerID

			// Create title & get icon
			let title = `${fileNameBase}.${fileNameExtension}`
			const fileIcon = getExtensionIcon(title)
			switch (uploadProgress) {
				case PROGRESS_FLAGS.UPLOADSTARTED: {
					title = <i>{title} - uploading...</i>
					break;
				}
					
				case PROGRESS_FLAGS.PROGRESSING: {
					title = <i>{title} - preparing file...</i>
					break;
				}
					
				case PROGRESS_FLAGS.COMPLETED: {

					// Create file ownerName IF an lti course
					var subTitle = ''
					if (ltiCourseID !== undefined) {
						const ownerName =
							currentUserID === fileOwnerID 			// IE, itemAnalysisOwnerID === currentUserID === fileOwnerID
								? 'you'
								: itemAnalysisOwnerID === fileOwnerID
									? itemAnalysis.name
									: name 	
						subTitle =
							<span style={{ fontStyle: 'italic', color: '#6f7890', marginLeft: '0rem' }}>
								{` - uploaded by ${ownerName}`}
							</span>
					}
					
					title =
						<div>
							<button
								className='btn btn-link'
								onClick={e => this.handleFileAssetDownload(e, fileID, title)}
								style={{
									color: '#cfd2da',
									padding: 0,
									fontWeight: 300
								}}
							>{title}</button>{subTitle}
						</div>
					break;
				}
				default:

			}
			// title = PROGRESS_FLAGS.UPLOADSTARTED === uploadProgress ? <i>{title} - uploading...</i> : title 
			// title = PROGRESS_FLAGS.PROGRESSING === uploadProgress ? <i>{title} - preparing file...</i> : title 

			const style={
				display: 'inline-block',
				fontSize: '1.2rem',
				marginRight: '.5em',
			}

			// LINK
			renderedFileAssets.push(
				<li className="list-group-item" id={fileID} key={fileID} style={{ border: 'none', paddingBottom: '0px'}}>
					<div style={{ display: 'contents'}}>
						<i style={{ ...style }}
							className={`fas fa-file${fileIcon.icon} text-${fileIcon.color}`}
						/>
						{title}
					</div>
				</li>
			);
		}

		return renderedFileAssets;
	}

    /**
     * Renders large Manage Attachments button or no file attachments message if !isItemAnalysisOwner
	 * NOTE: only called if no file attachments
     */
	renderManageFileAssetsButton = () => {
		const {
			isItemAnalysisOwner,
			locked,
			role
		} = this.props

		if ((isItemAnalysisOwner && !locked) || ROLES.AUTOTEACHER === role)
            return (
                <ButtonIcon
                    style={{marginTop: '.5rem'}}
                    btnClass="dataset-upload-button btn btn-outline-secondary"
                    iconClass="far fa-paperclip"
                    label='Manage Attachments'
                    onClick={this.handleManageFileAssetsBtnClick}
            /> );
        else 
            return (<small style={{padding: '.7rem .2rem' }}>No attached files.</small>)
    }

    /**
     * Handles clicking of manage Transcription button both in this component & <BottombarTitle />
     */
	handleManageFileAssetsBtnClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.openModal('modalFileAssets', '');
	}

	handleFileAssetDownload = (e, fileID, filename) => {
		e.preventDefault();
		e.stopPropagation();

		this.props.getFileAssetLink(fileID)
			.then(link => {
				// create an <a /> and trigger click
				let a = document.createElement('a');
				a.style.display = "none";
				a.href = link;
				a.type = "application/octet-stream"
				a.download = filename;
				a.click();
			})
	};
}

const mapStateToProps = state => {
	return {
		bottombarHeight: state.app.bottombarHeight,
		canAttach: state.account.planData.additionalLimits.export, 
		currentUserID: state.user.data.userID,
		itemAnalysis: state.folderItems.selectedItemAnalysis,
		itemAnalysisLOBs: state.itemAnalysisLOBs,
		messages: state.messages,
	}
};

const mapDispatchToProps = {
	getFileAssetLink,
	fetchFileAssetLobs,
	openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileAssetsWrapper);
