import React from 'react'

export const ContactUs = props => (
    <a
        id={ props.chat ? 'chatnow' : ''}
        href= { !props.chat ? `mailto:${props.email || 'support@dreamaker.io' }?Subject=${props.subject || 'Hello Dreamaker.io!' }` : '' }
        data-rel='external'
        data-toggle='tooltip'
        title= { props.title ? props.title : 'Contact us'}
    >{props.children || 'Contact us'}</a>
)