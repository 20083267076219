import { dm_print } from '../utils/';
import _ from 'lodash';

import {
	CAUTH_USER,
	CAUTH_ERROR,
	CAUTH_MESSAGE,
	CLOADING,
	CVERIFY_USER,
	CVERIFY_PASSWORD_RESET,
	CUNAUTH_USER,
	// CUSER_CLEAR,
	CUSER_CLEAR_MESSAGES,
	CUSER_UPDATE,
	OAUTH_REVOKE,
	OAUTH_REHYDRATE,
} from 'Constants/actions';

import {
	DMUSER_LOADING,
	DMUSER_ERROR,
	DMUSER_FETCH
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: true, 		// True when loading from api, false on response
	error: null, 		// Error messages
	message: null, 		// Other messages
	authenticated: false, // true if authenticate
	verify: false,
	data: {
		userID: null // the guid - cognito username & dynamo userID
	},
};

// State is the Cognito object
export default function (state = INITIAL_STATE, action) {
	//dm_print( 'CognitoReducer: state = ', state)

	switch (action.type) {
		// COGNITO SPECIFI
		case CLOADING:
			return { ...state, error: '', message: '', loading: true };

		case CAUTH_ERROR: {
			return {
				...state,
				error: action.payload,
				message: '',
				loading: false
			};
		}

		case CAUTH_MESSAGE: {
			return {
				...state,
				error: '',
				message: action.payload,
				loading: false
			};
		}

		case CAUTH_USER: {
			return {
				...state,
				authenticated: true,
				error: '',
				message: '',
				loading: false
			};
		}

		// case CUSER_CLEAR: //Alias for UNAUTH
		case CUNAUTH_USER:
			return { ...INITIAL_STATE, loading: false };

		// case CUSER_CLEAR: //Alias for UNAUTH
		// 	return { ...INITIAL_STATE, loading: false };

		case CUSER_CLEAR_MESSAGES:
			return { ...state, error: '', message: '' };

		case CUSER_UPDATE: {
			// used to update the state of user.data inside the application
			// grabs all previous state places it in new state, then will set data to all previous state,
			// allowing it to spread over the new action.payload
			let newState = {
				...state,
				data: { ...state.data, ...action.payload },
				loading: false
			};
			// grabs the newstates first/last name and creates initials, then sets the initials
			const initials =
				newState.data.firstName[0].toUpperCase() + ((newState.data.lastName) ? newState.data.lastName[0].toUpperCase() : '');
			newState = { ...newState, data: { ...newState.data, initials } };
			// finally returning the newState
			return newState;
		}

		// Payload containts Saves cognitoUser & userID to data
		case CVERIFY_USER: {
			return {
				...state,
				data: action.payload,
				verify: true,
				error: '',
				message: '',
				loading: false
			};
		}

		case CVERIFY_PASSWORD_RESET: {
			// paylaod is either boolean true or false
			return {
				...state,
				verify: action.payload,
				loading: false
			};
		}

		// DM USER SPECIFIC
		case DMUSER_LOADING:
			return { ...state, error: '', message: '', loading: true };

		case DMUSER_ERROR: {
			return {
				...state,
				error: action.payload,
				message: '',
				loading: false
			};
		}

		case DMUSER_FETCH: {
			let newerState = Object.assign({}, action.payload);
			delete newerState.account;
			return {
				...state,
				data: newerState,
				error: '',
				message: '',
				loading: false
			};
		}
		
		// OAUTH
		case OAUTH_REHYDRATE: 
		case OAUTH_REVOKE: {
			return {
				...state,
				data: {
					...state.data,
					oauth: {
						...action.payload
					}
				}
			}
		}
	}

	return state;
}
