import React, {Component} from 'react';
import classNames from 'classnames';

class TabNavItem extends Component {

	render() {
		const classes = classNames({
			'nav-link': true,
			active: this.props.active
		});

		return (
			<li className="nav-item">
				<a onClick={this.handleClick} className={classes}>
					{this.props.text}
				</a>
			</li>
		);
    }
    
	handleClick = e => {
		e.preventDefault();
		this.props.onItemClick(this.props.index);
	};
}

export default TabNavItem;
