import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx';
import Modal from 'Widgets/Modal.jsx';

import{getExtensionIcon} from 'utils/'

import {dismissMessage} from 'actions/app';

import { PROGRESS_FLAGS, ROUTES } from 'Constants/app.js'

import {
	fetchFileAssetLobs, 
	updateFileAsset,
	uploadFileAsset,
} from './actions/index';

import { fileAssetLobsData } from './utils/index';

const fileAssetInputIDPrefix = 'file-asset-input-'

class ModalFileAssets extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			deleting: {},		// list of filesAvailableBeingDeleted
		};
	}

	componentDidMount() {
		this.props.dismissMessage();
		
		// load file lobs if not
		const { 
			itemAnalysisID,
			itemAnalysisLOBs,
		 } = this.props;  

		const {loading, filesAvailable} = fileAssetLobsData(itemAnalysisLOBs[itemAnalysisID])
		
		// 'LOAD' - needs loading. false | true has loaded. undefined - no files exist
		if('LOAD' === loading) { 
			this.props.fetchFileAssetLobs(itemAnalysisID, filesAvailable)
		}
    }

	componentWillUnmount() {
		this.props.dismissMessage();
	}

	render() {
		const {
			canAttach,
			itemAnalysisID,
			itemAnalysisLOBs,
			itemAnalysis: { title }
        } = this.props;      

        const lobs = itemAnalysisLOBs[itemAnalysisID] || {};
		const loading = lobs.filesLoading || false

		var instructions = '';
		if(!canAttach) {
			instructions = 
				<Link to={ROUTES.UPGRADE}>
					Attachments are available in upgraded plans. <i>Upgrade now.</i>
				</Link>
		} 

		return (
			<Modal size='modal-md' >
				<div className="modal-header">
                <div className="modal-subtitle">File Attachments</div>
                    <div className="modal-title">{title}</div>
				</div>
				<div className="modal-body">
					{instructions}
					{this.renderMessage()}
					<div className="row data-list-container">
						<div className="col-12 list-over-flow">
							<ul className="list-group">
								{this.renderFileAssets()}
								{ loading 
								? <Loading size='sm' divStyle={{  textAlign: `left`, margin: 0 }}/> 
								: null 
								}
							</ul>
						</div>
					</div>
					<div className="row" >
						<div className="col-12 text-justify" style={ !canAttach ? {opacity: '0.7'} : {}}>
							<label htmlFor='file-asset-upload' style={{padding: '0rem 1rem'}} >Upload attachment:</label>
							<input
								disabled={!canAttach}
								id="file-asset-upload"
								type="file"
								onChange={this.handleAddFile}
							/>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={this.context.closeModal} >
						Done
					</button>
				</div>
			</Modal>
		);
	}

	// Renders a list of downloadable fileAssets, if they exist.
	renderFileAssets = () => {
		const { 
			currentUserID,
			itemAnalysisLOBs,
			itemAnalysisID,
		 } = this.props;  

		const lobs = itemAnalysisLOBs[itemAnalysisID];
		const {filesAvailable} = fileAssetLobsData(lobs)

		// If there are no assets, render nothing
		if (0 === filesAvailable.length ) return;

		const filesAvailableBeingDeleted = this.state.deleting;

		var renderedFileAssets = []
		for (const lobKey of filesAvailable) {
			// Undefined if its been deleted in session (has not been deleted from itemAnalysis)
			if (undefined === lobs[lobKey]) continue;

			const { deleted, fileID, fileNameBase, fileNameExtension, uploadProgress } = lobs[lobKey]

			if (deleted || currentUserID !== lobs[lobKey].ownerID) continue;

			const deleting = filesAvailableBeingDeleted[lobKey] || false

			const fileAssetLoading =
				PROGRESS_FLAGS.COMPLETED !== uploadProgress
				? true
				: false
			var title = `${fileNameBase}.${fileNameExtension}`
			const fileIcon = getExtensionIcon(title)
			title = fileAssetLoading ? `${title} - uploading` : title
			title = deleting ? `${title} - deleting` : title

			renderedFileAssets.push(
				<li className="list-group-item" id={fileID} key={fileID}>
					<div className='fill-available' >
						<i style={{ display: 'inline-block', marginRight: '.5em', fontSize: '1.2rem' }}
							className={`fas fa-file${fileIcon.icon} text-${fileIcon.color}`}
						/>
						{ fileAssetLoading || deleting
						? <span style={{color: '#ccc', fontWeight: '400', fontStyle: 'italic'}}>
							{title}
						</span>
						: <input
							defaultValue={title}
							//disabled - <input /> does not display when deleting or loading
							id={`${fileAssetInputIDPrefix}${lobKey}`}
							name={`asset_${fileID}`}
							maxLength="40"
							onBlur={(e) => this.handleFileTitleUpdate(e, fileID)}
							onKeyDown={(e) => this.handleInputKeyDown(e, lobKey)}
							type="text"
							style={{ borderBottom: '0rem'}}
						/>
						} 
					</div>
					<div>
						{fileAssetLoading || deleting
						? null
						: <i className={`far ${ deleting ? 'fa-spinner' : 'fa-times'} delete`}
								onClick={(e) => this.handleFileDelete(e, fileID)}
							/>
						}
					</div>
				</li>
			);
		}

		return renderedFileAssets;
	}

	// Renders info messages
	renderMessage() {
		const {message, error} = this.props.messages;
        if (message) 
            return (
				<div className="row">
					<div className="col-12 text-justify">
						<Message message={message} style={{'marginTop': '0.5rem', padding: '0rem 1rem'}} textClass={error ? 'danger' : 'success'} dismissable={false} />
					</div>
				</div>
			)
	}

	// HANDLES
	handleAddFile = (e) => {
		// const fileInput = e.target;
		const file = e.target.files[0];
		// const truncatedFilename = file.name.substring(0,22); // The max-length for asset titles is set both here

		// var progress = document.querySelector('.percent');

		this.props.uploadFileAsset(file)
	}

	// isEnter? 
	handleInputKeyDown = (e, lobKey) => {
		if (e.keyCode == 13) {
			document.getElementById(`${fileAssetInputIDPrefix}${lobKey}`).blur();
		}
	};

	/**
	 * Handles updating of the fileAsset.fileBaseName.
	 * NOTE: Changing of extension is disabled - all extension edits are replaced with fileNameExtension
	 */
	handleFileTitleUpdate = (e, fileID ) => {
		e.preventDefault();
		e.stopPropagation();

		const {
			itemAnalysisLOBs,
			itemAnalysisID
        } = this.props;      

        const lobs = itemAnalysisLOBs[itemAnalysisID] || {};
		const { fileNameBase, fileNameExtension } = lobs[`file_${fileID}`]
		const title = `${fileNameBase}.${fileNameExtension}`

		// Trim white space and remove extension - extension is not editable.
		let titleInput = e.target.value.trim();

        // Check if title changed and/or reset to initial title if blank & return
		if ('' === titleInput) {
            e.target.value = title
			return;
		}

		// If extension has NOT changed, remove it from titleInput (we're only saving filenameBase)
		if(`.${fileNameExtension}` === titleInput.slice(titleInput.lastIndexOf("."), titleInput.length )) {
			titleInput = titleInput.slice(0, titleInput.lastIndexOf("."))
		}

		// Reset e.target.value to new trimmed & cleaned titleInput with fileNameExtension
		e.target.value = `${titleInput}.${fileNameExtension}`

		// API Update title if not same as old title
		if (titleInput !== fileNameBase ) {
			this.props.updateFileAsset(this.props.itemAnalysisID, fileID, 'fileNameBase', titleInput);
		}
	}

	// Deletes a file
	handleFileDelete = (e, fileID) => {
		e.preventDefault();
		e.stopPropagation();

		// NOTE: there is no need to set deleting[lobKey] to true - once deleted, it is simply not rendered
		// NOTE: known bug - if delete fails, state remains in deleting
		this.setState({
			deleting: {
				...this.state.deleting,
				[`file_${fileID}`]: true
			}
		})
		
		this.props.updateFileAsset(this.props.itemAnalysisID, fileID, 'deleted', true)
	}
}

function mapStateToProps(state) {
	return {
		canAttach: state.account.planData.additionalLimits.export, 
		currentUserID: state.user.data.userID,

		filesAvailable: state.itemAnalysisLOBs.filesAvailable || [],	// empty: nothing to load
		itemAnalysis: state.folderItems.selectedItemAnalysis,
		itemAnalysisID: state.folderItems.selectedItemAnalysis.itemAnalysisID, 
		itemAnalysisLOBs: state.itemAnalysisLOBs,
		loading: state.itemAnalysisLOBs.filesLoading,	// undefined: not yet loaded. false: done loading. 'LOAD' needs loading
		messages: state.messages,
	}
}

const mapDispatchToProps = {
	fetchFileAssetLobs,
	dismissMessage,
	updateFileAsset,
	uploadFileAsset,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFileAssets);