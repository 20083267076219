import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import { 
    closeModal, 
} from 'actions/app';

import HelloUser from 'Widgets/Navbar.HelloUser.jsx';
import BackToTheFutureButton from 'Widgets/BackToTheFutureButton.jsx'
import Page from 'Components/Layout/page.jsx'

class PageSettings extends Component {
	static contextTypes = {
		router: PropTypes.object,
		history: PropTypes.object
	};

	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext() {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
    };
    
	render() {
		return (
			<Page pageClasses="page-account">
				<div className="navbar fixed-top navbar-inverse bg-inverse">
					<div className="container-fluid">
						<div className="row">
							<div className="col">
								<div className="navbar-left">{this.renderNavbarLeft()}</div>
								<div className="navbar-right"><HelloUser /></div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row">
                        {this.props.children}
					</div>
				</div>
			</Page>
		);
	}

	renderNavbarLeft = () => {
		return (
			<div className="navbar-nav">
				<BackToTheFutureButton goBack={this.props.history.goBack} btf={true} />
			</div>
		);
	};
}

export default compose(withRouter, connect(null, {closeModal}))(PageSettings);
