/**
 * TESTING
 * =======
 * ( HTTP Response Tests: http://httpstat.us/423'   // Test 423 Locked response
 * ( HTTP 403 From AWS: `${apiConfig.userGetCurrent}jfilajlk`,  // IE- bad API end points. Returns a 403 from AWS)
 * ( JWT Token: https://jwt.io/  // unravel tokens
 * 
 * ( Error Credit Card: 4242 4242 4424 4424 - returns an error )
 * ( Good Credit Card: 4242 4242 4242 4242 - returns an error )
 */

// 3RD Party Imports
import _ from 'lodash';
// import { v4 as uuidV4 } from 'uuid';

import {dmErrToAwsErr, dm_print} from '../utils/';
import Service from '../utils/request';
export var request = null;

import AWS from 'aws-sdk/global';

import {
	CognitoUser,
	CognitoUserPool,
	CognitoUserAttribute,
	AuthenticationDetails
} from 'amazon-cognito-identity-js';

// DMIO - FUNCTIONS
import {initConsumerism, exitConsumerism, shutdownIntercom, updateIntercom} from '../utils/consumerism';
import {messageToast} from './app';
import {fetchFolders } from 'Components/Landing/actions/';

// DMIO - CONSTANTS
import {PROVIDER, awsConfig, apiConfig} from '../config';
import {DMIO_RELEASE, INTERCOM_APPID} from '../config';

// DMIO - ACTION CONSTANTS

import {
	_UNSET_VAR,
	
    CAUTH_USER, 
	CAUTH_ERROR,
	CAUTH_MESSAGE,
    CUNAUTH_USER,

    CVERIFY_USER, 
    CVERIFY_PASSWORD_RESET, 
    
    CLOADING,
    
	CUSER_UPDATE
} from 'Constants/actions';

import {
	DMUSER_LOADING,
	DMUSER_ERROR,
	DMUSER_COOKIES_ERROR,
	DMUSER_FETCH,
	DMUSER_UPDATE
} from 'Constants/actions';

import {
    COLLABORATOR_ERROR, 
    COLLABORATOR_LOADING,

    DASHBOARDS_LOADING,
    DASHBOARD_TAGS_LOADING,
	DASHBOARDS_ERROR,
	DASHBOARDS_FETCH,
	DASHBOARD_TAGS_FETCH,

    MARKERS_LOADING,
	MARKERS_ERROR,
	MARKERS_FETCH,
} from 'Constants/actions';

import {
    ERROR_ADD, 
    MESSAGE_ADD,
    
    ITEM_COOKIES_ERROR,
	UPLOAD_ERROR,
    UNMOUNT_ANALYSIS,
    PLANS_FETCH,
    APP_ERROR,
} from 'Constants/actions';

import {
    BILLING_GET,
	PAYMENT_ERROR,
} from 'Constants/actions.js';

import { ROUTES } from 'Constants/app';


/**
 * AWS Configuration 
 * NOTE: Set here to ensure initialisation
 * NOTE: Credentials set specific auth functions - signup, forgotpass, refreshUser, signinUser
 * */
AWS.config.update({
    region: awsConfig.Region,
    maxRetries: 20,
    correctClockSkew: true,
});

const _userPool = new CognitoUserPool({
	UserPoolId: awsConfig.UserPoolId,
	ClientId: awsConfig.ClientId,
	// The time limit, in days, after which the refresh token is no longer valid. Default apparently 30days.
	// this can be set in the aws cognito pool > General Settings > App Client
	// RefreshTokenValidity: 1
});

var _cognitoUser = null; // global cognitouser
export var _session = null; //
// export var bucket = null;

var _userCookiesRefresher; // global for clearInterval on current user cookies
var _userCookiesTime = Date.now(); // global time tracking - when _userCookiesRefresher was last called
var _shCookiesRefresher; // global var for clearInterval on cookies for shares & collaborations
var _shCookiesTime = null; // global time tracking - when _shCookiesRefresher was last called
var _shCookiesItems = {}; // global map of items shared cookies are being called for

// PAYMENT & PLANS
/**
  * Gets all plan information for plans inside the application.
  * @returns Promise
  */
 export function fetchPlans() {
	return function(dispatch) {
		const requestObj = {
			method: 'get',
			url: `${apiConfig.appFetchPlans}`
		};

		// TODO: get an error contant here
		request(requestObj, APP_ERROR).then(resp => {
            
            // Add an easy lookup map for valid planNames: { [planID-aaa]: true, [planID-zzz]: true }, etc
            var len = resp.length, planNames={};
            while (len--) {
                planNames = {
                    ...planNames,
                    [resp[len].planID]: true
                }
            }

            dispatch({
                type: PLANS_FETCH, 
                payload: {
                    details: resp,
                    planNames
                }
            });
		});
	};
}

/**
  * Gets users billing details
  * @returns Promise
  */
export function getBillingDetails() {
	return function(dispatch) {
		const requestObj = {
			method: 'get',
			url: `${apiConfig.billingDetailsGet}`
		};

		request(requestObj, PAYMENT_ERROR)
			.then(resp => {
				dispatch({type: BILLING_GET, payload: resp});
				return resp.data;
			})
			// CHECK: is this return used
			.catch(function(error) {
				return error;
			});
	};
}



// FETCH USER GOODIES....
export function fetchUserMarkers() {
	return function(dispatch) {
        dispatch({ type: MARKERS_LOADING, payload: true });

        const requestObj = {
            method: 'post',
            url: `${apiConfig.markerFetch}`,
            data: {},
        }

        request( requestObj, MARKERS_ERROR )
        .then( resp => {
            dispatch({
                type: MARKERS_FETCH,   // Also sets loading to false
                payload: resp
            });
        })
	};
}

export function fetchUserDashboards() {
	return function(dispatch) {
        dispatch({ type: DASHBOARDS_LOADING });

		const requestObj = {
			method: 'post',
			url: `${apiConfig.dashboardFetch}`,
			// strangely enough, you need the data:{} otherwise it breaks
			data: {}
		};

		request(requestObj, DASHBOARDS_ERROR).then(resp => {
			dispatch({
				type: DASHBOARDS_FETCH,
				payload: resp.dashboards
			});
		});
	};
}

export function fetchUserDashboardTags() {
	return function(dispatch) {
        dispatch({ type: DASHBOARD_TAGS_LOADING, payload: true }); 

		const requestObj = {
			method: 'get',
			url: `${apiConfig.allTagsFetch}`
		};

		request(requestObj, DASHBOARDS_ERROR).then(resp => {
			dispatch({
				type: DASHBOARD_TAGS_FETCH,
				payload: resp
			});
		});
	};
}


// DM USER
/**
  * Fetches the dmUser of the current session
  * return   on success: dmUser object on success, 
  *          on failure: CAUTH_ERROR
  */
 function _fetchUser() {
	return function(dispatch, getState) {
		dispatch({type: DMUSER_LOADING});

		// API Update
		const requestObj = {
			url: `${apiConfig.userGetCurrent}`,
			withCredentials: true
		};

        return request(requestObj, DMUSER_ERROR)
        .then(resp => {

			const { _, user } = getState();
			
			// Capture email reset if present
			if (_.resetEmail && _.resetEmail !== user.data.email) {
				dispatch(signoutUser('', true));
				return Promise.reject(false);
			}
			

			// Check LTI status
			const lti = _.lti || false
			let isLtiValid = true;

			
			// validate current user matches ltiUserID passed through && ltiConsumerID passed through is present in resp.ltiConsumers
			if(lti && resp.ltiConsumers && resp.ltiConsumers[lti.ltiConsumerID]) {
				const ltiConsumerUserID = resp.ltiConsumers[lti.ltiConsumerID].ltiUserID ? resp.ltiConsumers[lti.ltiConsumerID].ltiUserID || false : false;
				if(lti.ltiUserID !== ltiConsumerUserID){
					isLtiValid = false
				}
			} 
			else if(lti) isLtiValid = false;

			if (!isLtiValid) {
				// console.log("LTI INVALID YOU'RE OUT")
				dispatch(signoutUser('Sign in using the Dreamaker.io account associated with your learning management system.'));
				return Promise.reject(false);
			}
			
			// Set preferences
            let preferences = {
                leadTime: 2,
				lagTime: 2,
				pauseOnTag: true,
				pauseOnType: true,
                scanFwd: 5,
				scanBwd: 5,
            }
            
            // Prefrences not at all set or new preferences not ? set them
			resp = (undefined === resp.preferences) 
			? { ...resp, preferences } 
			: { ...resp, 
				preferences: {
					...preferences, 
					...resp.preferences 
				}}

            dispatch({
				type: DMUSER_FETCH,
				payload: resp
			});
		})
	};
}

/**
 * Updates users information for currently logged in user.
 * Note: Located in this file because use of _session is required.
 * @param {Object} updatedUserData 
 */
export function updateUser(updatedUserData) {
    return function(dispatch, getState) {
        const successMsg = 'Update successful!'

		if (_.isEmpty(updatedUserData)) {
			dispatch(messageToast(MESSAGE_ADD, successMsg, 5000));
			return;
        }

        if(!_.isEmpty(updatedUserData.preferences)) {
            updatedUserData.preferences = {
                ...getState().user.data.preferences, 
                ...updatedUserData.preferences
            }
        }
        
		const requestObj = {
			method: 'post',
			url: `${apiConfig.userUpdate}`,
			data: updatedUserData,
        };
        
		request(requestObj, DMUSER_ERROR)
		.then(resp => {
			// NOTE: DO NOT DELETE
			// This is a in-house, backend only email address updater.
			// Needs modal implementation and comprehensive error handling before being implemented
			if (updatedUserData.email) {
				var verificationCode = prompt('Please input verification code: ', '');
				_cognitoUser.verifyAttribute('email', verificationCode, {
					onSuccess: function(result) {},
					onFailure: function(err) {
						_dmCognitorErrorHandler(err);
					}
				});
			}

			updateIntercom(
				{...getState().user.data, ...updatedUserData},
				getState().account
			)
			
			dispatch(messageToast(MESSAGE_ADD, successMsg, 5000));

			dispatch({type: CUSER_UPDATE, payload: updatedUserData});
			return;
		});
	};
}

// AUTHENTICATION - KEEP IN INDEX.JS TO HANDLE COGNITO
export function signinUser({email, password}) {
	return function(dispatch) {
		// UNAUTH is inherent
		dispatch({type: CLOADING});

		// path '/' is inherent
        _createCognitoUser(email);
        const authenticationData = {
            Username: email,
            Password: password
        };
		var authenticationDetails = new AuthenticationDetails(authenticationData);

		// Authenticate User
		_cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: function(session) {
				// Assign session to global session & initialise state
                _session = session;

                // Set AWS.config.credentials (results in 2 POSTS to https://cognito-identity.us-west-2.amazonaws.com/)
				// NAT - SEE HERE FOR GOOFLE SSO ###
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: awsConfig.IdentityPoolId,
                    Logins: {
                        [PROVIDER]: session.getIdToken().getJwtToken() // UserPool Provided
                    }
                });
     
                // Refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh((error) => {
                    if (error) {
                        dispatch(signoutUser('Your session has expired. Please sign back in.'));
                    } else {
                        dispatch(_initUserAppState(session)); // initializes Service with session token                         
                    }
                });
			},
			onFailure: function(err) {
				dispatch(_dmCognitorErrorHandler(err, 'SIGNIN'));
			}
		});
	};
}

/**
  * Refreshes the current session if _cognitoUser && session are valid, otherwise unauths user 
  *   NOTE: GetSession() retrieves tokens from localStorage if they're valid
  *   If the access token has expired, it'll try to get a new one with the refresh token. 
  *   If that's expired, it'll throw an exception
  */
export function refreshUser() {
	return function(dispatch, getState) {
		dispatch({type: CAUTH_USER});
		dispatch({type: CLOADING});

		// path is unknown
		_cognitoUser = _userPool.getCurrentUser();

        // New sign in
		if (_cognitoUser == null) {
			dispatch(signoutUser()); // No signout message - expiry not valid
			return;
		}

		// Get session - session expiry is as per previous expiry, unless already expired
		_cognitoUser.getSession((err, session) => {
			if (err) {
				dispatch(signoutUser());
				return;
			}

            // Refresh session to reset expirty time to an hr from NOW
            _cognitoUser.refreshSession(session.refreshToken, (err, session) => {
                if (err) {
                    dm_print('refreshUser() - refreshSession() failed: ', err, _cognitoUser.getUsername());
                    dispatch(signoutUser());
                    return;
                }

                // Assign session to global session
                _session = session;
                
                // Set AWS.config.credentials (results in 2 POSTS to https://cognito-identity.us-west-2.amazonaws.com/)
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: awsConfig.IdentityPoolId,
                    Logins: {
                        [PROVIDER]: session.getIdToken().getJwtToken() // UserPool Provided
                    }
                });
        
                // Refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh((err) => {
                    if (err) {
                        dm_print('refreshUser() config.credentials.refresh failed: ', err, _cognitoUser.getUsername());
                        dispatch(signoutUser());
                        return;
					} else {
						dispatch(_initUserAppState(session)); // initializes Service with session token
					}
                });
            });
		});
	};
}

function _initUserAppState(session) {
	return function(dispatch, getState) {
		request = Service.initialize(
			session.getAccessToken().getJwtToken(),
			session.getIdToken().getJwtToken(),
			_isSessionValid,
			dispatch,
			_dmAxiosErrorHandler
		);

        dispatch(_fetchUser())
        .then(() => {
            dispatch({type: CAUTH_USER});
            dispatch(fetchUserDashboards());
            dispatch(fetchUserDashboardTags());
            dispatch(fetchUserMarkers());
            dispatch(getBillingDetails());
            dispatch(fetchPlans());
            initConsumerism(getState().user.data, getState().account);
        })
        .catch(err => {
            if(err) dispatch(_dmAppErrorHandler(DMUSER_ERROR, err));
        });
    
        dispatch(_cookieRefresher());
	};
}

import {updateEvaportateCredentials} from 'Components/Landing/actions/index'
/**
  * Starts the userCookiesRefresher() setInterval() function, used to refresh cookies of logged in user at the given interval
  *   NOTE: GetSession() retrieves tokens from localStorage if they're valid
  *   If the access token has expired, it'll try to get a new one with the refresh token. 
  *   If that's expired, it'll throw an exception
  */
function _cookieRefresher() {
	console.time('CF');
	return function(dispatch, getState) {
		/** Set interval to 2 minutes - calling regularly to catch laptop on wake
         * Time in milli seconds
         *  : 1000    =1sec
         *  : 120000  =2min
         *  : 1800000 =30min - NOTE: sessionToken does NOT refresh tokens for 30 mins or less
         *  : 2700000 =45min - NOTE: sessionToken DOES refresh tokens for 45 mins or more
         *  : 3300000 =55min
         *  : 3600000 =1hrs
         */
		// LIVE
		const interval = 120000;    // 2 mins
		const duration = 3000000;   // 50 mins

		// TEST
		// const interval = 120000;   // TEST 2 mins
		// const duration = 2700000;  // TEST 45 minutes 

		// const interval = 10000;   // TEST 10 seconds 1000=1sec
		// const duration = 20000;  // TEST 20 seconds

		_userCookiesRefresher = window.setInterval(function() {
			// Check if its time to call. If so, refresh user cookies
			if (Date.now() - _userCookiesTime > duration) {
				// var z = parseJwt(session.getIdToken().getJwtToken())
				// dm_print('>>> _cookieRefresher() BEFORE AWS.config.credentials.accessKeyId: ', AWS.config.credentials.accessKeyId);
				// dm_print('>>> _cookieRefresher() BEFORE AWS.config.credentials.sessionToken: ', AWS.config.credentials.sessionToken);
                // dm_print('>>> _cookieRefresher() BEFORE session.exp: ', new Date(z.exp * 1e3).toLocaleString() , _cognitoUser.getUsername() )
                // dm_print('>>> _cookieRefresher() BEFORE credentials.expireTime: ', AWS.config.credentials.expireTime, _cognitoUser.getUsername());
                // dm_print('>>> _cookieRefresher() BEFORE credentials: ', AWS.config.credentials, _cognitoUser.getUsername());
				console.timeEnd('CF');

				// TODO: Should we be calling this here??
                _isSessionValid(dispatch)
                .then(resp => {
                    // dm_print('_cookieRefresher() - _isSessionValid() resolved with resp, calling refreshSession ', '', _cognitoUser.getUsername());
					_cognitoUser.refreshSession(_session.refreshToken, (err, session) => {
						if (err) {
							// dm_print('_cookieRefresher() - refreshSession() failed: ', err, _cognitoUser.getUsername());
							dispatch(signoutUser());
							return;
                        }
                        AWS.config.credentials.params.Logins[PROVIDER] = session.getIdToken().getJwtToken();
                        AWS.config.credentials.refresh((err) => {
                            if(err)  {
                                // dm_print( '_cookieRefresher() credentials failed to refresh - dispatching signout and returning false.', '', _cognitoUser.getUsername() );
                                dispatch(signoutUser('Your session has expired... Please sign back in.'));
                                return;
                            } else {
								// var z = parseJwt(session.getIdToken().getJwtToken())
								// dm_print('>>> _cookieRefresher() AFTER session.exp: ', new Date(z.exp * 1e3).toLocaleString() , _cognitoUser.getUsername() )
								// dm_print('>>> _cookieRefresher() AFTER credentials.expireTime: ', AWS.config.credentials.expireTime, _cognitoUser.getUsername());
								// dm_print('>>> _cookieRefresher() AFTER credentials: ', AWS.config.credentials, _cognitoUser.getUsername());

								// Update Evaportate if active
								// dm_print('>>> _cookieRefresher() AFTER AWS.config.credentials.accessKeyId: ', AWS.config.credentials.accessKeyId);
								// dm_print('>>> _cookieRefresher() AFTER AWS.config.credentials.sessionToken: ', AWS.config.credentials.sessionToken);

								// Assign global _session, updateCognitoIdentityCredentials
								_session = session;
								request = Service.refresh(
									session.getAccessToken().getJwtToken(),
									session.getIdToken().getJwtToken()
								);
								
								// Update Evaporate session
								// dispatch(updateEvaportateCredentials());

								// Update user Cookies
								const requestObj = {
									url: `${apiConfig.userGetCookies}`,
									withCredentials: true
								};
								request(requestObj, DMUSER_COOKIES_ERROR)
								.then(resp => {
									dm_print('_cookieRefresher() - SUCCESS', '', _cognitoUser.getUsername());
									
								})
								.catch(err => {
									//TODO: Test
									// dm_print('_cookieRefresher() - FAILED with err ', err, _cognitoUser.getUsername());
									clearInterval(_userCookiesRefresher);
									return;
								})

								dm_print('_cookieRefresher() - resetting _userCookiesTime and CF', '', _cognitoUser.getUsername());
								// Set _userCookiesTime to now to know when next to call
								_userCookiesTime = Date.now();
								console.time('CF');
							}
                        });
                    });
                });
			}
		}, interval);
	};
}

/**
  * Axios request for cookies on itemID for itemOwnerID. 
  * This is Promise based - returns resolve() and rejects(error)
  * @param {String} itemID 
  * @param {String} itemOwnerID 
  * @return Promise.resolve() on sucess, Promise.reject(error) on error
  */
export function _getSharedItemCookies(itemID, itemOwnerID) {
	return function(dispatch) {
		// If current user is onwer, shared cookies not required
		if (_cognitoUser.username == itemOwnerID) {
			return Promise.resolve();
		}

		const requestObj = {
			url: `${apiConfig.userGetSharedCookies}${itemID}`,
			withCredentials: true
		};

		return request(requestObj, ITEM_COOKIES_ERROR).then(resp => {
			// Add itemID to _shCookiesItems (to include in setInternval refreshes)
			_shCookiesItems = {
				..._shCookiesItems,
				[itemID]: Date.now()
			};
			//console.log('_getSharedItemCookies() called; _shCookiesItems: ', _shCookiesItems);
			// Initialise _shCookiesTime if not already initialized & invoke _shCookiesRefresher
			if (_shCookiesTime === null) {
				_shCookiesTime = Date.now();
				_getSharedItemCookiesRefresher();
			}
			return Promise.resolve();
		});
	};
}

/**
  * Gets cookies for shared item itemID of logged in user and sets an interval to at which to refresh them
  *   NOTE: getSession() retrieves tokens from localStorage if they're valid
  *   If the access token has expired, it'll try to get a new one with the refresh token. 
  *   If that's expired, it'll throw an exception
  */
function _getSharedItemCookiesRefresher() {
	console.time('CFS');
	//console.log('_getSharedItemCookiesRefresher() called');
	return function(dispatch) {
		/** Set interval to 2 minutes - calling regularly to catch laptop on wake
          * Time in milli seconds
          *  : 1000    =1sec
          *  : 120000  =2min
          *  : 1800000 =30min
          *  : 2700000 =45min
          *  : 3300000 =55min
          *  : 3600000 =1hrs
          * */
		const interval = 120000;
		const duration = 2700000;
		// const interval = 5000; // TEST 1000=1sec
		// const duration = 1000; // TEST

		_shCookiesRefresher = window.setInterval(function() {
			//console.log('_getSharedItemCookiesRefresher() refreshing');

			// Check if its time to call. If so, refresh share cookies
			if (Date.now() - _shCookiesTime > duration) {
				console.timeEnd('CFS');

				// key is itemID, value is blank
				_.map(_shCookiesItems, (value, key) => {
					dispatch(_getSharedItemCookies(key, null))
						.then(() => {})
						.catch(error => {
							// TODO: test below
							// remove failed id from map
							_shCookiesItems.delete(key);
							// if map is empty, clear _shCookiesRefresher interval, reset _shCookieTime
							if (0 === _shCookiesItems.size) {
								_shCookiesRefresherReset();
								// return here to avoid _shCookieTime being set again
								return;
							}
						});
				});
				// Rest _shCookiesTime to current time
				_shCookiesTime = Date.now();
				console.time('CFS');
			}
		}, interval);
	};
}

/**
  * Clears the _shCookiesRefresher interval and resets _shCookiesTime to null.
  */
function _shCookiesRefresherReset() {
	clearInterval(_shCookiesRefresher);
	_shCookiesTime = null;
}

/**
  * Signs up user 
  * @param {Object} param containing: name, email, password, userID, emailsUnsubscribed
  * param.emailsUnsubscribed: true is unsubscribed, false is subscribed 
  */
export function signupUser({name, email, password, userID, emailsUnsubscribed, initialTemplateID}) {
	// If email does not exist - create new user
	return function(dispatch, getState) {
        dispatch({type: CLOADING});
        
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	        IdentityPoolId: awsConfig.IdentityPoolId
        });

		// Create list of cognito user attributes
		var userAttributes = [];
		userAttributes.push(new CognitoUserAttribute({Name: 'email', Value: email.toLowerCase()}));
		userAttributes.push(new CognitoUserAttribute({Name: 'name', Value: name}));
		userAttributes.push(new CognitoUserAttribute({Name: 'custom:emailsUnsubscribed', Value: emailsUnsubscribed}));
		
        
        // Set initialTemplateID if initialTemplateID is present
        ( initialTemplateID && 'false' != initialTemplateID )
        ? userAttributes.push(new CognitoUserAttribute({Name: 'custom:initialTemplateID', Value: initialTemplateID}))
		: null;
		
		// Set LTI data if present. NB - LTI supercedes organizationAccept if both are present
		if(getState()._.lti) {
			const ltiConsumerID = getState()._.lti.ltiConsumerID, ltiUserID = getState()._.lti.ltiUserID;
			if( undefined !== ltiConsumerID && undefined !== ltiUserID) {
				userAttributes.push(new CognitoUserAttribute({Name: 'custom:ltiVariables', Value: JSON.stringify(getState()._.lti) }))
			}
		} else {
			// Set organizationID if oprganizationAccept is present & then unset it
			if (getState()._.organizationAccept) {
				userAttributes.push(new CognitoUserAttribute({ Name: 'custom:organizationID', Value: getState()._.organizationAccept }))
				dispatch({
					type: _UNSET_VAR,
					payload: 'organizationAccept'
				})
			}
		}

		// Signup & confirm flow
		// NAT - SEE HERE FOR GOOFLE SSO ###
		_userPool.signUp(userID, password, userAttributes, null, function(err, result) {
			if (err) {
				dispatch(_dmCognitorErrorHandler(err));
				return;
			}
            dispatch({
                type: CVERIFY_USER,
                payload: {cognito: result.user, email}
            })
		});
	};
}

/**
  * 
  * @param {*} cognitoUser 
  * @param {*} verificationCode 
  * @param {*} forceAliasCreation 
  */
export function verifySignup({verificationCode}, forceAliasCreation = false) {
	return function(dispatch, getState) {
		const cognitoUser = getState().user.data.cognito

		if (cognitoUser == null) {
			dispatch(signoutUser());
			return;
        }

		dispatch({type: CLOADING});
		if (verificationCode) {
			cognitoUser.confirmRegistration(verificationCode, forceAliasCreation, function(
				err,
				result
			) {
				if (err) {
					dispatch(_dmCognitorErrorHandler(err));
					return;
                }

				AWS.config.credentials.clearCachedId();
				dispatch({type: CUNAUTH_USER}); //reset cognito user to blank

				
				// Specific Message for LTI... (IE User signed up via LTI - we need to close or refresh windows)
				if(getState()._.lti) {
					if( undefined !== getState()._.lti.ltiConsumerID && undefined !== getState()._.lti.ltiUserID) {
						dispatch(authMessage('Signup and verification successful! Refresh page & sign in to access course.'));
					}
				} else {

					dispatch(authMessage('Signup and verification successful! Sign in below.'));
				}
                
                // Shutdown
                shutdownIntercom();
			});
		}
	};
}

export function resendVerification(cognitoUser) {
	return function(dispatch, getState) {
		if (cognitoUser == null) {
			dispatch(signoutUser());
			return;
		}

		dispatch({type: CLOADING});
		cognitoUser.resendConfirmationCode(function(err, result) {
			if (err) {
				dispatch(_dmCognitorErrorHandler(err));
				return;
			}
			dispatch(authMessage(`Code re-sent to ${getState().user.data.email}. Be sure to check your spam & junk mail folders.`));
		});
	};
}

/**
  * Sends password verification
  * @param {*} email 
  */
export function forgotPassword({email}, resend=false) {
	return function(dispatch) {
        dispatch({type: CLOADING});
		
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	        IdentityPoolId: awsConfig.IdentityPoolId
        });

		_createCognitoUser(email.toLowerCase());
		_cognitoUser.forgotPassword({
			onSuccess: function(result) {
				if(resend)
					dispatch({
						type: CAUTH_MESSAGE,
						payload: `Code re-sent to ${email}. Be sure to check your spam & junk mail folders.`
					})
				dispatch({type: CVERIFY_PASSWORD_RESET, payload: true});
			},
			onFailure: function(err) {
				dispatch(_dmCognitorErrorHandler(err));
				return;
			}
		});
	};
}

export function unVerifyPasswordReset() {
	return {type: CVERIFY_PASSWORD_RESET, payload: false};
}

/**
  * 
  * TODO: FIX: UserLambdaValidationException on success 
  * @param {*} {email, password, verificationCode} 
  */
export function confirmPassword({email, password, verificationCode}) {
	return function(dispatch) {
		verificationCode = _.trim(verificationCode);
		if (!verificationCode || '' === verificationCode) {
			dispatch(_dmAppError(CAUTH_ERROR, 'Oops, verification code required,'));
			return;
		}
		dispatch({type: CAUTH_MESSAGE, payload: ''});
		dispatch({type: CLOADING});
		_cognitoUser.confirmPassword(verificationCode, password, {
			onSuccess: function (result) {
				dispatch({
					type: _UNSET_VAR,
					payload: 'resetEmail'
				})
				dispatch({
					type: _UNSET_VAR,
					payload: 'redirect'
				})
				dispatch(signoutUser('Password reset successful! Sign in below.'));
			},
			onFailure: function(err) {
				dispatch(_dmCognitorErrorHandler(err));
			}
		});
	};
}

/**
 * Signs a user out of their current session on Dreamaker.io
 * @param {String} msg Message to display to user on signout
 * @param {Boolean} globalSignout If true, signs user out of all devices & invalidates refresh tokens. See https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html
 */
export function signoutUser(msg = null, globalSignout = false) {
	return function(dispatch, getState) {
		const evaporate = getState().uploader.evaporate;
		evaporate ? evaporate.pause(undefined, {force: true}) : null;
		clearInterval(_userCookiesRefresher);
		_shCookiesRefresherReset();
		console.timeEnd('CF');
		console.timeEnd('CFS');
		dispatch({type: CUNAUTH_USER});
		dispatch({type: UNMOUNT_ANALYSIS}); // TODO: Is this needed here?
		dispatch({type: CAUTH_MESSAGE, payload: msg});
		if (_cognitoUser) {
			(globalSignout) 
				? _cognitoUser.globalSignOut({ 
					onFailure: (err) => {_cognitoUser.signOut()}, 
					onSuccess: (res) => {}
				})
				: _cognitoUser.signOut();
		}
		let cognitoCurrentUser = _userPool.getCurrentUser();
		if (cognitoCurrentUser) cognitoCurrentUser.signOut();
		if (AWS.config.credentials) AWS.config.credentials.clearCachedId();
		exitConsumerism();
		return;
	};
}

// AUTHENTICATION PRIVATE HELPERS

/**
  * 
  * @param {*} email 
  */
function _createCognitoUser(email) {
	const userData = {
		Username: email,
		Pool: _userPool // global created at top of file
	};
	_cognitoUser = new CognitoUser(userData);
}

/**
  * Commeted out because unused
  * @param {*} cognitoUser valid cognito user
  * @param {*} attributeName attribute name to retrieve the value of
  * Returns null on falure, value of attributeName on success
  */
// function getUserAttributes(cognitoUser, attributeName) {
// 	return new Promise((resolve, reject) => {
// 		cognitoUser.getUserAttributes((err, result) => {
// 			if (err) reject(err);
// 			else {
// 				for (var i = 0; i < attributes.length; i++) {
// 					if (attributeName === attributes[i].getName())
// 						resolve(attributes[i].getValue());
// 				}
// 				reject(false);
// 			}
// 		});
// 	});
// }



/**
  * Checks the global _session for validity. Promise based. 
  * On validity, returns Promise.resolve()
  * On invalidity, returns Promise.reject().
  * NOTE: cognito.getSession(err, session) - session values differs to AWS.config.credentials
  * session: used for API gate ways calls
  * credentials: aws federated ID and needed for AWS S3 uploading
  * Added AWS.config.credentials refresh is critical for AWS S3 uploads
  */
export function _isSessionValid(dispatch) {
    // Based on _session
    return new Promise((resolve, reject) => {
        if (_session.isValid()) return resolve();

        dm_print('_isSessionValid() - _session.isValid() returned false - ', _session, _cognitoUser.getUsername());

        // Refresh session - getSession() refreshes it when its invalid
        return _cognitoUser.getSession((err, session) => {
            if (err) {
                dm_print( '_isSessionValid() session expired - dispatching signout.', '', _cognitoUser.getUsername() );
                dispatch(signoutUser('Your session has expired, please sign back in.'));
                return reject("Session failed to refresh")
            }
            _session = session;
            request = Service.refresh(
				session.getAccessToken().getJwtToken(),
				session.getIdToken().getJwtToken()
				);

            // NEW update & refresh config.credentials
            AWS.config.credentials.params.Logins[PROVIDER] = session.getIdToken().getJwtToken();
            AWS.config.credentials.refresh((err) => {
                if (err) {
                    dm_print('_isSessionValid() config.credentials.refresh failed: ', err, _cognitoUser.getUsername());
                    dispatch(signoutUser('Your session has expired. Please sign back in.'));
                    return reject("Credentials failed to refresh") //Refresh error occuring here.
                } 
                return resolve();
            });
            // END NEW
        });
    });
};

/**
  * 
  * @param {*} intervals 
  */
// function clearIntervals(intervals) {
// 	_.map(intervals, (value, key) => {
// 		clearInterval(value);
// 	});
// }

/**
  * 
  * @param {*} message 
  */
export function authMessage(message) {
	return {
		type: CAUTH_MESSAGE,
		payload: message
	};
}

// PRIVATE _DM HELPERS

/** 
 * AXIOS specific error handler
 * @param String type
 * @param Object error - error object
 */
export function _dmAxiosErrorHandler(type, error) {
	return function(dispatch) {
		dm_print(`_dmAxiosErrorHandler(${type}) +++ error: `, error);
		// dm_print(` +++ error.response: `, error.response);
		// dm_print(` +++ error.message: `, error.message);
		// dm_print(` +++ error.code: `, error.code);

		// TEST Rollbar - Direct straight to _dmAppErrorhanlder
		// dispatch(_dmAppErrorHandler(type, error));
		// return;
		// END TEST

		// A request was made and the server responded with a status code out of the range of 2xx
		if (error.response) {
			dm_print(' + error.response: ', error.response);

			switch (error.response.status) {
				case 400: // Bad Request
					dm_print(' ++ status 400 caught');
					dispatch(_dmLambdaErrorHandler(type, error));
					return;

				case 402: //
					dm_print(' ++ status 402 caught');
					dispatch({type, payload: error.response.data.message});
					return;

				case 401: // Unauthorized
					dm_print(' ++ status 401');
				case 403: // Forbidden
					dm_print(' ++ status 403 caught - signingout');
					dispatch(signoutUser('Ah, your session timed out. Please sign in again.'));
					return

				case 423: // Locked: Returned when account.lock.status is partial
					dm_print(' ++ status 423 caught - ');
					// Do not redirect - will get caught in a look because of cookies beiing loaded in getUser().
					if (DMUSER_COOKIES_ERROR === type) {
						dispatch(
							_dmAppError(
								ITEM_ERROR,
								'The item you are trying to view has been locked.'
							)
						);
						return;
					}
					dispatch(_dmAppErrorHandler(type, error));
					return;

				case 502: //{"message": "Internal server error"}
					dm_print(' ++ status 502 caught - ');
					// Do not redirect - will get caught in a look because of cookies beiing loaded in getUser().
					if (DMUSER_COOKIES_ERROR === type) {
						dispatch(
							_dmAppError(
								ITEM_ERROR,
								'The item you are trying to view has been locked.'
							)
						);
						return;
					}
					dispatch(
						_dmAppError(
							ERROR_ADD,
							"Well that's embarrasing, something's gone wrong. Sorry about this, we're working on it & will fix it as soon as we can."
						)
					);
					return;

				case 504: //Endpoint request timed out.
					dm_print(' ++ status 504 caught - ');
					if (error.response.data.message) {
						if (
							'Endpoint request timed out' === error.response.data.message &&
							COLLABORATOR_ERROR === type
						) {
							dispatch({type: COLLABORATOR_LOADING, payload: false});
							dispatch(messageToast(MESSAGE_ADD, 'Invites sent.', 4000));
							return;
						}
					}
					dispatch(
						_dmAppError(
							ERROR_ADD,
							'Dreamaker.io cloud services are temporarily unavailable. Please try again in a few moments.'
						)
					);
					return;
			}
		}
		dispatch(_dmAppErrorHandler(type, error));
	};
}

/**
  * Specific Error Handler for Lambda Errors
  * @param {*} type 
  * @param {*} error 
  * Note: Axios will pass thru an error with an error.response object. The lambda code is in response.data
  * Note: Cognito will pass thru an error with an error,message string. The lambda code is embedded in the string
  */
function _dmLambdaErrorHandler(type, error) {
	return dispatch => {
		dm_print(`_dmLambdaErrorHandler(${type}) >>> error: `, error);
		dm_print(` > error.response: `, error.response);
		dm_print(` > error.message: `, error.message);
		dm_print(` > error.code: `, error.code);

		let errObj = {};
		if (_.isObject(error.response)) errObj = error.response.data;
		else errObj = dmErrToAwsErr(error.message);

		dm_print(` > errObj.code: `, errObj.code);

		switch (errObj.code) {
			// SUPRESSED ERRORS ---------------------------
			// LTI Errors - we display inline to user in Grade.jsx
			case 'LTILaunchRequestInvalid': 
			case 'LTILaunchSignatureInvalid':
			case 'LTILaunchSignatureExpired':
			case 'LTIOrganizationNotFound': 
			case 'LTIOutcomeServiceFailed':
			case 'LTIOutcomeServiceForbidden':
			case 'LTIOutcomeServiceGradeOutOfRange': 
			case 'LTIOutcomeServiceUnexpectedError':
			case 'LTIUserInvalid':
				// Item & item Analysis
			case 'ItemAnalysisIdNoLongerExists':
			case 'ItemAnalysisIdInvalidException':
			case 'ItemIdInvalidException':
			{
				return;
			}
			
			// FORWARDE TO USER ERRORS ----------------------
			// CHART Errors
			case 'ChartDataInvalidCSV':
			case 'ChartDataInvalidTimeCode':
			case 'ChartDataMissingRequiredColumns':
			case 'ChartDataInvalidYValue':
			// LTI
			case 'LTIOrganizationConsumerAlreadyExists':
			case 'LTIOrganizationConsumerIDAlreadyExists':
			case 'LTIOrganizationConsumerKeyAlreadyExists':
			case 'LTILaunchMustUseSSL':
			case 'FileSaveNoPermission':
			// OAuth
			case 'OAuthUserIdInvalidException':
			{
				dispatch({
					type: ERROR_ADD,
					payload: errObj.message
				});
				return;
			}

			// CUSTOMIZED ERRORS ------------------------------
            //Organization errors
            case 'OrganizationInvalidOrganizationID':
            case 'OrganizationAcceptNotInvited': {
				dispatch(
					_dmAppError(ERROR_ADD, "Contact the organization admin to join this organization.")
				);
				return;
            }
			// CAUTH_ERRORs: Signup Errors
			case 'UserSaveFailedEmailAlreadExists':
				// err message with resend verification code?
				// TODO: rollbar this and err on nth attempt from same IP?
				dispatch(_dmAppError(type, 'Hm. An account with that email address exists.'));
				return;

			case 'UserIdInvalidException': 			// User does not have permission to do this
			case 'FolderViewInvalidException': {	// Invalid object ID exceptions
				dispatch(fetchFolders(0));
				history.replaceState({}, '', `${ROUTES.LANDING}/0`);
				return;
			}

			// Payment Errors
			case 'PaymentUpcomingInvoiceRetrieveFailed':
				dispatch({
					type: ERROR_ADD,
					payload:
						'Sorry about this, but the payment gateway is currently unavailable. Please try again later.'
				});
				return;

			// Invalid Coupon for Payment
			case 'PaymentCouponRetrieveFailed':
			case 'PaymentCouponInvalid':
				dispatch(_dmAppError(type, 'The coupon you entered is invalid.'));
				return;

			case 'ItemDeleteLocked':
				dispatch({
					type: ERROR_ADD,
					payload:
						'Failed to delete this item: either its locked or an item it contains is locked. Remove the lock to re-enable deleting.'
				});
				// being rerouted inside from getItem() Analysis.jsx componentDidMount
                return;
                


            case 'ItemTranscribeFailed':
				dispatch({
					type: ERROR_ADD,
					payload: "An error has occured transcribing this project. Apologies about this, we are looking into it.",
				});
				return;
				

			default:
				// TODO: Rollbar this with more LambdaException Specifics
				dispatch(_dmAppErrorHandler(type, error));
				return;
		}
	};
}

/**
  * ALL critical errors eventually funnelled here
  * ROLLBAR: Arbitrary log messages. 'critical' is most severe; 'debug' is least.
  * Can include custom data with any of the below.
  * It will appear as `custom.postId` in the Occurrences tab
  * Rollbar.info("Post published", {postId: 123});
  * Rollbar.critical("Connection error from remote Payments API");
  * Rollbar.error("Some unexpected condition");
  * Rollbar.warning("Connection error from Twitter API");
  * Rollbar.info("User opened the purchase dialog");
  * Rollbar.debug("Purchase dialog finished rendering");
  * 
  * ROLLBAR: Test
  * Run this is console window: window.onerror("TestRollbarError: testing window.onerror", window.location.href)
  */
export function _dmAppErrorHandler(type, error = '') {
	dm_print(`_dmAppErrorHandler(${type}) === error: `, error);
	dm_print(' == error.message', error.message);
	dm_print(' == error.status', error.status);
	dm_print(' == error.code', error.code);
	dm_print(' == error.response', error.response);
	dm_print(' == error.request', error.request);
	dm_print(' == error.stack', error.stack);

	// Generic error message
	let userMsg =
		"Well that's embarrasing, something's gone wrong. Sorry about this, we're working on it & will fix it as soon as we can.";
	let errMsg = `${error}`;
	let errMap = {};

	// A request was made and the server responded with a status code out of the range of 2xx
	if (error.response) {
		dm_print(' == Response error: ', error.response);
		if (error.response.data.message) errMsg = `${error.response.data.message}.`;
		errMap = {
			...error.response
		};
	}

	// The request was made but no response was received
	else if (error.request) {
		dm_print(' == Request error', error.request);
		errMap = {...error.request};
		if (!error.status && 'Network Error' === error.message) {
			// true for cors && network issue && 502 :(
			dm_print(' == Network error with request');

			userMsg = 'Network connection not found. Try again in a few minutes.';
			errMsg = `${error.message} w/ Request. Offline.state is ${Offline.state}`;
			errMap = {...error};
		}
	}
		
	// Network error
	else if (!error.status && 'Network Error' === error.message) {
		// true for cors && network issue && 502 :( 
		dm_print(' == Network Error ');
			
		userMsg = 'Network connection not found.';
		errMsg = `${error.message}. Offline.state is ${Offline.state}`;
		errMap = {...error};

	}
	// Something happened in setting up the request that triggered an Error
	else {
		dm_print(' == Unknown error: ', error.message);
		errMap = {...error};
	}

	if (UPLOAD_ERROR === type) {
		dm_print('== Upload error. ', error.message);
		errMap = {...error};
		userMsg = `An error occured with the upload: ${error.message} Error code: ${error.code}.`;
	}

	errMap = {
		// trace: trace,
		type: `${type}`,
		stack: error.stack,
		dmioRelease: DMIO_RELEASE,
		...errMap
	};

	Rollbar.error(errMsg, errMap);

	return {
		type: ERROR_ADD, // supress erro with a non existent type,
		payload: userMsg
	};

	// Prior to message refactor
	// return {
	// 	type: type, // supress erro with a non existent type,
	// 	payload: userMsg
	// };
}

/**
  * Specific error handler for cognito
  * @param {Object} error Error object returned by AWS Cognito SDK
  * @param {String} src Source of the error: 'SIGNIN'
  */
function _dmCognitorErrorHandler(error, src = '') {
	return function(dispatch) {
		dm_print('>>> _dmCognitorErrorHandler() - error.code: ', error.code);
		// dm_print('>>> _dmCognitorErrorHandler() --- src: ', src);
		let message = error.message;

		switch (error.code) {
			// FORWARD TO USER
			case 'CodeMismatchException':
			case 'ExpiredCodeException':
			case 'InvalidParameterException':
			case 'LimitExceededException':
				break;

			case 'InvalidPasswordException':
				// thrown for each constraint individually
				message =
					'Passwords must have a length of at least 8, contain uppercase letters, lowercase letters, special characters & numbers';
				break;


			case 'MissingRequiredParameter':
				message = "Woops, missing required field!";
				break;

			case 'NotAuthorizedException':
				// Username valid but no/incorrect pass
				message = 'O dear, username or password are incorrect.';
				break;

			case 'UserNotFoundException':
				// TODO: Also called when user has signed up but not verified
				'SIGNIN' === src
					? (message = 'O dear, username or password are incorrect.')
					: (message = "Hum, that account doesn't exist.");
				break;

			case 'UserNotConfirmedException':
				// This exception is thrown when a user is not confirmed successfully.
				// dispatch(_dmAppError(CAUTH_ERROR, "O dear, account not yet confirmed!"));
				// return
				message = 'Hm, account not yet confirmed.';
				break;

			case 'AliasExistsException':
			case 'UsernameExistsException':
				// Triggered when email or phonenumber already exist
				// Signup confirm
				message = 'Woops, that account already exists.';
				break;

			// Forward to Lambda
			case 'UserLambdaValidationException':
				dispatch(_dmLambdaErrorHandler(CAUTH_ERROR, error));
				return;

			case 'PasswordResetRequiredException':
				message =
					'A password reset is required for this account. Please follow the Forgot Password link below.';
				break;

			// Critical - Forward to Rollbar
			case 'CodeDeliveryFailureException':
			case 'InternalErrorException':
			case 'InvalidEmailRoleAccessPolicyException':
			case 'InvalidLambdaResponseException':
			case 'NotAuthorizedException':
			case 'ResourceNotFoundException':
			case 'TooManyFailedAttemptsException':
			case 'TooManyRequestsException':
			case 'UnexpectedLambdaException':
			default:
				dispatch(_dmAppErrorHandler(CAUTH_ERROR, error));
				return;
		}
		dispatch(_dmAppError(CAUTH_ERROR, message));
	};
}

/**
  * Returns error message to user, trigger action creator with *type* containing payload of *error*
  * @param {String} type
  * @param {String} message 
  */
function _dmAppError(type, error) {
	error = '' + error;
	// dm_print('>>> _dmAppError()')
	// dm_print('>>> _dmAppError() --- type: ', type);
	// dm_print('>>> _dmAppError() --- error: ', error);
	return {
		type: type,
		payload: error
	};
}

/**
  * Returns message to user, triggering an action creator with *type* containing payload of *message*
  * @param {String} type
  * @param {String} message 
  */
function _dmAppMessage(type, message) {
	return {
		type: type,
		payload: message
	};
}