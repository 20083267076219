import React from 'react';
import PageBland from './Layout/page.bland.jsx';
import { Link } from 'react-router-dom';

const Maintenance = (props) => {
	return (
		<PageBland>
			<div className="settings settings-profile">
				<div className="settings-head">
					{/* <h1>Temporary Maintence Underway</h1> */}
                    <h1>Planned Outage & Upgrades Underway</h1>
				</div>
				<div className="settings-body">
					<div className="form form-settings">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
                                alignItems: 'center'
							}}>
                            <p style={ {'fontSize': '1.1rem', textAlign: 'center'} } >Dreamaking.io is under going scheduled maintenance & will be back online around<br />7pm PST, Feb 9th (<a href="https://www.timeanddate.com/worldclock/fixedtime.html?msg=Dreamaker.io+Scheduled+Maintenance&iso=20190209T16&p1=202&ah=3">see this in your timezone</a>).</p>
                            <p>If you would like to receive a notification when the upgrades are complete, drop an email to <a href="mailto:hello@dreamaker.io">hello@dreamaker.io</a>.</p>
						</div>
					</div>
				</div>
			</div>
		</PageBland>
	);
};

export default Maintenance;
