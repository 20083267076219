import React from 'react';

// renders the stripe country codes
const renderOptions = countryCodes => {
	return countryCodes.map(item => {
		return (
			<option value={item.code} key={item.name}>
				{item.name}
			</option>
		);
	});
};

const RenderedInputs = props => {
	const {placeholder, name, handleChange, passedState} = props;
	// if the passedState inputHasError object has the inputs name, it will check if the error is true (meaning there is an error)
	// and then will display the error message.
	const hasError = passedState.inputHasError[name] === true ? true : false;
	const value =
		passedState[name] === null || passedState[name] === undefined ? '' : passedState[name];
	return (
		<div className="inherit-div">
			<input
				autoFocus={props.autoFocus}
				type="input"
				className="field"
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
			/>
			{hasError && <div className="text-danger">Required</div>}
		</div>
	);
};

const RenderedSelect = props => {
	const {name, handleChange, passedState, options} = props;
	// if the passedState inputHasError object has the inputs name, it will check if the error is true (meaning there is an error)
	// and then will display the error message.
	const hasError = passedState.inputHasError[name] === true ? true : false;
	const value =
		passedState[name] === null || passedState[name] === undefined ? '' : passedState[name];
	return (
		<div className="inherit-div">
			<select name="country" value={value} onChange={handleChange}>
				<option default disabled hidden>
					Select Country
				</option>
				{renderOptions(options)}
			</select>
			{hasError && <div className="text-danger">Required</div>}
		</div>
	);
};

// Need to pass it the items state that you want it to take care of, as well as the handleChange function
// This should be fixed later on so that there can be more or less inputs if needed. For now you'll need to pass through
// states for: name, line1, line2, city, state, country
// as well as an inputHasError object which will have properties of the name and whether it is true or false, example below
// EXAMPLE: state.inputHasError: { name: true, country: false }
const AddressInputs = ({state, handleChange, countryCodes}) => (
	<div id="address-fields" className="group">
		<RenderedInputs
			autoFocus={true}
			passedState={state}
			name="name"
			placeholder="Name on card"
			handleChange={handleChange}
		/>
		<div id="address-fields-street" className="group">
			<RenderedInputs
				passedState={state}
				name="line1"
				placeholder="Address line 1"
				handleChange={handleChange}
			/>
			<RenderedInputs
				passedState={state}
				name="line2"
				placeholder="Address line 2"
				handleChange={handleChange}
			/>
		</div>
		<div id="address-fields-country" className="group">
			<RenderedInputs
				passedState={state}
				name="city"
				placeholder="City"
				handleChange={handleChange}
			/>
			<RenderedInputs
				passedState={state}
				name="state"
				placeholder="State/Province/County"
				handleChange={handleChange}
			/>
			<RenderedSelect
				passedState={state}
				name="country"
				handleChange={handleChange}
				options={countryCodes}
			/>
		</div>
	</div>
);

export default AddressInputs;
