export default {
	/** Auth pages */
	"Email": "Email",
	"Fullname": "Fullname",
	"First name": "First name",
	"Last name": "Last name",
	"Location": "Location",
	"Welcome": "Welcome",

	"Validation code": "Validation code",
	"check your email for the code": "check your email for the code",
	"Confirm reset": "Confirm reset",
	"Resend verification code": "Code not arrive? Resend verification code",

	"Password": "Password",
	"Confirm password": "Confirm password",
	"Reset password": "Reset password",
	"Reset your password": "Reset your password",
	"Password reset successful!": "Password reset successful! Sign in below.",
	"Password required": "Woops, password required.",
	"Forgot your password?": "Forgot your password?",
	"Get a new one!": "Get a new one!",
	"Incorrect username or password": "O dear, username or password are incorrect.",

	"Please enter an email": "Please enter an email",
	"Invalid email address": "Invalid email address",

	"Select a template": "Select a template to start with",
	"Sign in": "Let's go!",
	"Sign up": "Sign up!",

	"Already signed up? Sign in": "Already signed up... Sign in",
	"New to Dreamaker.io?": "New to Dreamaker.io?",
	"Sign in instead": "Here by mistake? Sign in instead",

	"Receive occasional Dreamaker.io news, promotions and updates?": "Receive occasional Dreamaker.io news, promotions and updates?",
	"I agree to Dreamaker.io's Terms of Service and Privacy Policy": "I agree to Dreamaker.io's Terms of Service and Privacy Policy.",

	// Menus
	"Open": "Open",
	"Rename": "Rename",
	"Delete": "Delete",
	"Upgrade": "Upgrade",
	"Help Center":"Help Center",
	"Terms & Privacy":"Terms & Privacy",
	

	/** Home Landing */
	"Owner": "Owner",
	"Media owner":"Media owner",
	"Project owner":"Project owner",
	"Created": "Created",
	"Size":"Size",
	"Duration":"Duration",
	
	"Info": "Info",
	"Drop or Select file": "Drop or Select file",
	"Drop here to move to parent": "Drop here to move to parent folder",

	"Lock": "Lock",
	"Unlock": "Unlock",
	"This project is locked":"This project is locked",
	"The owner of this project has locked it": "The owner of this project has locked it. You can view the project but analysis is disabled.",
	"Remove lock to enable Delete":"Remove lock to enable Delete",
	"Remove lock to manage Markers":"Remove lock to manage Markers",
	"Remove lock to manage Dashboards":"Remove lock to manage Dashboards",

	"Privacy Guard": "Privacy Guard",
	"Privacy Guard Notice":"Privacy Guard: Confidentiality Notice",
	"This project contains confidential data":"This project contains confidential and proprietary data intended for the exclusive viewing and access by authorized collaborators only.",
	
	"successfully invited":"successfully invited",

	// Uploading
	"Uploading": "Uploading...",
	"Preparing project": "Preparing project",

	/**  Markers / Dashboards */
	"Automatically added to new projects": "Automatically added to new projects",
	"Add an option":"Add an option",
	
	"Create new marker": "Create new marker",
	"Create new dashboard": "Create new dashboard",

	"No markers": "No markers",
	"No dashboards": "No dashboards",
	
	"Drop new tags here": "Drop new tags here",
	"Options": "Options",
	"Add an Option":"Add an Option",
	"Unsaved marker": "Unsaved marker",

	"Default Markers":"Default Markers",
	"Default Dashboards":"Default Dashboards",
	
	"Select a dashboard": "Select a dashboard",
	"Select a marker": "Select a marker",

	"Tag Info": "Tag Info",

	"Manage Markers":"Manage Markers",
	"Manage Dashboards":"Manage Dashboards",
	"Dashboard settings":"Dashboard settings",
	"Marker settings":"Marker settings",

	/** Analysis */
	"Analysis":"Analysis",
	"Playlist":"Playlist",
	"Your comments here":"Your comments here",
	"Add a note":"Add a note",
	"Filter by collaborator":"Filter by collaborator",
	"Filter by marker":"Filter by marker",
	"Filter by tag":"Filter by tag",
	"No tags match your filters.":"Aah you got it! No tags match your filters.",
	"No analysis yet.":"Aw no analysis yet",
	"Reply":"Reply",
	"Pause on tag": "Pause on tag",
	"Pause on type": "Pause on type",
	"Playlist":"Playlist",
	"Sort by": "Sort by...",
	"Show all":"Show all",
	"Time code": "Time code",


	/** LTI */
	"Activity":"Activity",
	"Add to activity":"Add to activity",
	"Remove from activity":"Remove from activity",
	// "Would you like to add this file to activity":"Would you like to add this file to activity",
	// "Would you like to remove this file from the activity":"Would you like to remove this file from the activity"
	
	// Common Dmio
	"Analysis":"Analysis",
	"Course":"Course",
	"Collaborate":"Collaborate",
	"Collaborator":"Collaborator",
	"Collaborators":"Collaborators",
	"Invite Collaborators":"Invite Collaborators",
	"Collaboration":"Collaboration",
	"Dashboard editor":"Dashboard editor",
	"Dashboard tags": "Dashboard tags",
	"Dashboard": "Dashboard",
	"Dashboards": "Dashboards",
	"Edit dashboards": "Edit dashboards",
	"Edit markers": "Edit markers",
	"Folder": "Folder",
	"Invite":"Invite",
	"Markers": "Markers",
	"Marker": "Marker",
	"Project Notes":"Project Notes",
	"Tag": "Tag",
	"Tags": "Tags",

	// Common Generic
	"and": "and",
	"Add":"Add",
	"Back": "Back",
	"Cancel": "Cancel",
	"Color": "Color",
	"Confirmation": "Confirmation",
	"Done":"Done",
	"Delete": "Delete",
	"Delete item": "Delete item",
	"Default": "Default",
	"Edit":"Edit",
	"Leave":"Leave",
	"Loading":"Loading",
	"New": "New",
	"New folder": "New folder",
	"No": "No",
	"People":"People",
	"Save": "Save",
	"Saving": "Saving",
	"Title": "Title",
	"Yes": "Yes",
	"You":"You",

	/** Delete Messages */
	"Are you sure you want to delete":"Are you sure you want to delete",
	"All child items & associated analysis projects will be deleted." :"All child items & associated analysis projects will be deleted.",
	"Deleting an item is irreversible.": "Deleting an item is irreversible.",
	"The project will be deleted from your workspace.":"The project will be deleted from your workspace.",
	"or delete files.":"or delete files.",

	/** Other Messages */
	"Are you sure you want to unlock":"Are you sure you want to unlock",
	"Unlocking will re-enable project analysis, modifications and deleting.":"Unlocking will re-enable project analysis, modifications and deleting.",
	"Are you sure you want to remove the Privacy Guard from":"Are you sure you want to remove the Privacy Guard from",
	"Removing the Privacy Guard will disable privacy notices and collaboration confirmations.":"Removing the Privacy Guard will disable privacy notices and collaboration confirmations.",

	"Enter email address.": "Enter email address. Separate with comma or newline...",
	"View your usage":"View your usage",
	"upgrade your plan":"upgrade your plan",
	"Collaborator limit reached": "You've reached your plan's collaborator limit of "
}