import _ from 'lodash';
import React, { Component } from 'react';

import { INVITE_STATUS } from 'Constants/app';
const { ACCEPTED, INVITED, LEFT } = INVITE_STATUS

import OrganizationSettingsDropdown from './Organization.Settings.Dropdown.jsx'

class Organization extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: {},
			organizationMessage: false,
		}
	}

	render () {
		// Props & state variables
		const {
			isDefaultOrganization,
			isOrganization,
			organization: {status},
			organizationID,
		} = this.props; //organization

		// Calculated variables
		const organisationRole = isOrganization && isDefaultOrganization ? 'owner' : 'a member';

		switch (status) {
			case INVITED:
				var statusString = <p>You've been invited to join <b>{organizationID}</b> organization</p>
				break;
			case LEFT:
				var statusString = <p>You have left the <b>{organizationID}</b> organization</p>
				break;
			default: {
				var statusString =
					<p>You're {organisationRole} of the <b>{organizationID}</b> organization
						{isDefaultOrganization
							? <i className='fas fa-check-double text-primary' style={{ paddingLeft: '.5rem' }} />
							: null
						}
					</p>
				break;
				}	
		}
		
		return (
			<div className='row settings-div-row' >
				<div className="col-9">{statusString}</div>
				<div className="col-3 settings-align-right">
					{LEFT !== status && !isOrganization
						? <OrganizationSettingsDropdown
							isDefaultOrganization={isDefaultOrganization}
							organizationID={organizationID}
							status={status}
						/>
						: null
					}
					
				</div>
			</div>
		)
	}
}

export default Organization;