import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { formatReadableTime, formatStorage, getExtensionIcon } from 'utils/'
import { setShow } from 'actions/app'

import Divider from 'Widgets/DropdownDivider.jsx';
import MenuItemUploadsInProgress from 'Widgets/MenuItemUploadsInProgress.jsx';

import { PROGRESS_FLAGS } from 'Constants/app';
const {
	CALCULATING,
	COMPLETED,
	ERROR,
	PROGRESSING,
	WARNING,
	UPLOADSTARTED,
} = PROGRESS_FLAGS

class IconUploadsInProgress extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dropdownShown: false,
		};
	}

	render () {
		const { showUploadsInProgress, uploads } = this.props
		
		if (0 >= uploads.length) return <span />;

		const { dropdownShown } = this.state;

		const buttonClasses = classNames({
			'btn': true,
			'btn-transparent-primary' : true,
			'zoomInOut' : !dropdownShown
		})
		const iconClasses = classNames({
			'far fa-arrow-to-top': true,
			'text-primary': true,	
			'zoomInOut': !dropdownShown
		})

		return (
			<div className="dropdown show"
				// onMouseLeave={this.handleDDLeave}
			>
				<button
					onClick={this.toggleShow}
					className={buttonClasses} 
					data-toggle="tooltip"
					title="Display the progress of ongoing uploads" 
				>
					<i className={iconClasses} />
				</button>
				{showUploadsInProgress && this.renderUploads(this.props)}
			</div>
		);
	}

	renderUploads = () => {
		const {
			uploads,
			uploader
		} = this.props;

		var renderedUploads = []

		for (const id of uploads) {

			const {
				name,
				percentage,
				progressFlag,
				stats: {
					fileSize = '-',
					readableSpeed = '-',
					secondsLeft = '-',
					totalUploaded = '-',
			}} = uploader[id]

			// Create title & get icon
			const fileIcon = getExtensionIcon(name)

			// Create progress stat text
			var progressStatsText = _.capitalize(progressFlag.toLowerCase())

			switch (progressFlag) {
				case UPLOADSTARTED:
					var progressStatsText = -1 === secondsLeft
						? `Calculating - ${formatStorage(totalUploaded / 1000000)} of ${formatStorage(fileSize / 1000000)} (${readableSpeed}/sec)`
						: `${formatReadableTime(secondsLeft)} left - ${formatStorage(totalUploaded / 1000000)} of ${formatStorage(fileSize / 1000000)} (${readableSpeed}/sec)`
					break;

				case PROGRESSING:
					var progressStatsText = 'Preparing file...'
					break;

				case ERROR:
					progressStatsText = `Error uploading file`
					break;
					
				case COMPLETED:
					progressStatsText = `File ready - ${formatStorage(fileSize / 1000000)}`
					break;
					
				case CALCULATING:
					progressStatsText = `${progressStatsText}...`
					break;
				
				case WARNING:
				default:
					break;
			}			

			renderedUploads.push(
				<MenuItemUploadsInProgress
					iconClass={`fas fa-file${fileIcon.icon} text-${fileIcon.color}`}
					key={id}
					percentage={percentage}
					progressStatsText={progressStatsText}
					title={name}
				/>
			);

			renderedUploads.push(<Divider key={`div-${id}`} />)
			
		}
		
		renderedUploads.push(
			<li className='dropdown-item progressbar' key='closeBtn'>
				<button
					className='btn btn-link'
					onClick={this.toggleShow}
				>
					Close
				</button>
			</li>
		)

		return (
			<div className="dropdown-menu dropdown-menu-right"
				style={{
					height: 'auto',
					maxHeight: '80vh',
					overflowY: 'auto'
				}} >
				{renderedUploads}
			</div>
		);
	};

	handleDDLeave = (e) => {
		this.toggleShow(e);
	};

	toggleShow = (e) => {
		e.preventDefault();
		const { setShow, showUploadsInProgress } = this.props
		this.setState({ dropdownShown: true })
		setShow('uploadsInProgress', !showUploadsInProgress)
	}
}



function mapStateToProps (state) {
	const { app: { show }, uploader } = state;
	return {
		showUploadsInProgress: show.uploadsInProgress,
		uploads: uploader.uploads || [], // default is undefined
		uploader
	};
}

export default connect(mapStateToProps, { setShow })(IconUploadsInProgress);
