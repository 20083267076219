import React from 'react';

const DragOverlay = props =>
    <div style={{
        position: 'absolute',
        top: props.top || '-4px',
        left: props.left || '-4px',
        height: props.height || '103%',
        width: props.width || '103%',
        zIndex: 1,
        border: `2px solid ${props.color|| '#1BC98E'}`,
        borderRadius: '.5rem'
    }} />;

export default DragOverlay;