import _ from 'lodash';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {injectStripe, CardElement} from 'react-stripe-elements';

import BillingCardForm from '../Payment/BillingCardForm.jsx'
import {StripeProvider, Elements} from 'react-stripe-elements';
import {STRIPEAPIKEY} from 'config.js';

import {Loading} from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx';

import ModalEditPlan from '../Modal.Edit.Plan.jsx';

import {ROUTES, PLANID} from 'Constants/app';

import {dismissMessage, openModal, closeModal} from 'actions/app';
import {
	getBillingDetails
} from 'actions/index';

import {
	accountPlanCancel,
	billingDetailsUpdate,
	billingDetailsUpdateSetupIntent,
	paymentIntializeState,
	paymentReducerClearMessages,
} from '../actions/payment';

import {formatLocalDate, formatCurrency} from 'utils/index';

import {StripeCountryCodes} from '../Payment/StripeCountryCodes';

class BillingInformation extends Component {
	static childContextTypes = {
		closeModal: PropTypes.func
    };
    
	getChildContext() {
		return {closeModal: this.onCloseModal};
    }
    
	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
		this.props.paymentReducerClearMessages();
    };
    
	constructor(props) {
		super(props);

		// sets the state
		this.state = {
			displayModal: false,
			showNewCardView: false,
			name: '',
			address_line1: '',
			address_line2: '',
			address_city: '',
			address_state: '',
			address_country: '',
			inputHasError: {}
		};
    }
    
	UNSAFE_componentWillReceiveProps(nextProps) {
		// checks if the state is empty, if it is, will set it to the new value
		// if not, will allow it to keep the old value without rewriting it.
		const checkIfEmpty = value => {
			const keyName = Object.keys(value)[0];
			return this.state[keyName] === '' ? value[keyName] : this.state[keyName];
        };
        
        // if there are no billing details, or the billing details is an empty object, 
        // or the card details are undefined, we want to return.
        if (nextProps.billingDetails === null || _.isEmpty(nextProps.billingDetails) ) return;
        if (nextProps.billingDetails.card === undefined) return
        
		const {
			name,
			address_line1,
			address_line2,
			address_city,
			address_state,
			address_country
        } = nextProps.billingDetails.card;
        
		this.setState({
			displayModal: nextProps.modalOpen.name === undefined ? false : nextProps.modalOpen.name,
			name: checkIfEmpty({name}),
			address_line1: checkIfEmpty({address_line1}),
			address_line2: checkIfEmpty({address_line2}),
			address_city: checkIfEmpty({address_city}),
			address_state: checkIfEmpty({address_state}),
			address_country: checkIfEmpty({address_country})
		});
    }
    
	componentWillUnmount() {
		// only clear the messages if there is a success message for the user.
		this.props.paymentIntializeState();
		if (this.props.payment.message) {
			this.props.paymentReducerClearMessages();
		}
    }

	render() {
		const {showNewCardView, displayModal} = this.state;
		const {dmioPlans, billingDetails, payment} = this.props;
		const {loading, response} = payment;
		
		// we pass it payment.loading because if there is no message in payment.loading, it will use the message we set above.
		if ( null === billingDetails || null === dmioPlans) return this.renderLoading(payment.loading);
        
        const {card, upcomingInvoice} = billingDetails;

		// Customer has no billing card on file
        if (card === undefined ) {
			return (
				<form>
					<h5>No billing info to display.</h5>
                    <p style={{textAlign: 'center'}}>Want to see some?{' '}
                        <Link to={ROUTES.UPGRADE}>
							<i>Upgrade</i>
						</Link> 😁</p>
				</form>
			);
		}

		// payment loading is either true or false
		if (loading && !displayModal) return this.renderLoading(payment.loading);

		// 'Change Card' clicked: show <BillingCardForm />
		if (showNewCardView) {
			if(loading) 
				return <Loading icon="far fa-check" message={payment.message} />;

			return (
				<StripeProvider apiKey={STRIPEAPIKEY}>
					<Elements>
						<BillingCardForm 
							cancel = {this.handleChangeCardCancelClick}
							cs = {response.setupIntent.client_secret}
							handleCardSetup={this.handleCardSetup}
							footer='This form will only update your billing details, your card will not be charged.'
							modalTitle='New card details'
							showCouponField = {false} 
							submitBtnTitle='Change card'
							>
						</BillingCardForm>
					</Elements>
				</StripeProvider>
			)
        }
		
		// Show current billing card info
		return (
			<div>
				<h5>Your payment card</h5>
				{payment.message ? <Message message={payment.message} textClass="success" dismissable={false} style={{paddingBottom: '.7rem', fontStyle: 'italic'}} /> : null }
				<div
					className="card-details"
					style={{display: 'flex', justifyContent: 'space-between'}}>
					<div>
						<h6 id="h6-small">Card</h6>
						<p>
							{card.name}
							<br />
							{card.brand} ending {card.last4}
							<br />
							Expiry: {card.exp_month}/{card.exp_year}
						</p>
					</div>
					<div
						className="card-details-address"
						style={{display: 'flex', flexDirection: 'column'}}>
						<h6 id="h6-small">Card Address</h6>
						<p>
							{card.address_line1}
							<br />
							{card.address_line2 ? (
								<span>
									{card.address_line2}
									<br />
								</span>
							) : null}
							{card.address_city}, {card.address_state}, {card.address_zip}
							<br />
							{this.getCountry(card.address_country)}
						</p>
						<a href="#"
							style={{alignSelf: 'flex-end', fontStyle: 'italic'}}
							onClick={this.handleChangeCardClick}>
							Change card
						</a>
					</div>
				</div>
                <h5>Next payment</h5>
                { undefined === upcomingInvoice 
                ? <p style={{textAlign: 'center'}}>You don't have an upcoming invoice.{' '}</p>
                : <div className="next-payment">
                        {this.renderUpcomingInvoiceInfo(upcomingInvoice)}
                        <div className="plan-links">
                            {upcomingInvoice.cancel_at_period_end 
							? 	<div />
                            :	<Link to="#"
                                    onClick={() =>
                                        this.props.openModal('cancelSubscription', null, {
                                            item: {
                                                title: 'free',
                                                cancelOn: formatLocalDate(
                                                    upcomingInvoice.date * 1000,
                                                    true
                                                )
                                            },
                                            selectedPlan: { planID: PLANID.FREE.LIVE }
                                        })}>
                                    Cancel subscription
                                </Link>
                            }
                            <Link to={ROUTES.UPGRADE}>Change plan</Link>
                        </div>
                    </div>
                }
				{this.renderModal()}
			</div>
		);
    }
    
	renderLoading = loadingMessage => {
		return (
			<Loading
				icon="far fa-circle-notch fa-spin"
				message={loadingMessage ? loadingMessage : 'Loading your information...'}
			/>
		);
	};

	renderError = () => {
		const {error} = this.props.payment;
		if (error)
			return (
				<h6 id="checkout-error" className="msg msg-error">
					Oops. {error} Please review & correct the details below.
				</h6>
			);
	};

	renderModal = () => {
		if (this.state.displayModal === 'cancelSubscription') {
			return (
				<ModalEditPlan
					onConfirm={() => {
						this.handleCancelSubscription();
					}}
				/>
			);
		}
	};

	/**
	 * Renders nest payment info
	 * upcomingInvoice (Object) containing upcomingInvoice info: 
	 * {
	 * 		amount_due:0
	 * 		date:1596362678
	 * 		cancel_at_period_end:true
	 * 		defaultPlan:"ENTERPRISE-PWI-SEAT-18-10-10"  // planID to be downgraded to
	 * 		defaultPlanTitle: "Processwork Institute",
	 * 		plan:"ENTERPRISE-PWI-SEAT-18-10-10"			// upcoming planID
	 *		planTitle: "Processwork Institute"
	 * }
	 */
	renderUpcomingInvoiceInfo = upcomingInvoice => {
        const {account, dmioPlans} = this.props;
        
        
        /**
         * Get title of upcoming plan from active plans.
         * NOTE: If active plans does not contain upcoming planID, upcomingPlanTitle is undefined (IE, inactive plan, custom plan, or organization plan)
         **/

		var upcomingPlanTitle = upcomingInvoice.planTitle

		/** OLD 
		var upcomingPlanTitle = _.find(dmioPlans, item => {
			return item.planID === upcomingInvoice.plan;
        });

        // Check if upcomingPlanTitle is an active plan, if not, assing it current plan title       
		(undefined === upcomingPlanTitle) ? upcomingPlanTitle = account.planData.title : upcomingPlanTitle = upcomingPlanTitle.title
		**/
        
		const upcomingPlan =
			upcomingInvoice.paymentPeriod
				? `${upcomingInvoice.paymentPeriod.toLowerCase()} ${upcomingPlanTitle} Plan`
				: `${upcomingPlanTitle} Plan`;
		
		const currentPlan = `${account.paymentPeriod.toLowerCase()} ${account.planData.title} Plan`;

		const dateDue = formatLocalDate(upcomingInvoice.date * 1000, true);
		const amountDue = `${formatCurrency(upcomingInvoice.amount_due)}`;

		// Cancellation from a paid plan coming
		if (upcomingInvoice.cancel_at_period_end) {
			return (
				<p>
					You're being billed for the <strong>{currentPlan}</strong>. Your subscription will end on{' '}
					{dateDue} and your plan changed to the <strong>{upcomingPlan}</strong>.
				</p>
			);
		}

		// upcomingInvoice account_balance shows a negative number if we owe the customer, therefore we need to divide by negative 100 in order to get the correct amount.
		// Ex: You are on the Starter plan with a Monthly subscription. You have a credit of $120 to apply to your next invoice. On Oct 25th, 18 you will be charged $0.
		if (upcomingInvoice.account_balance < 0) {
			return (
				<p>
					Your account has a credit of {formatCurrency(-upcomingInvoice.account_balance)}.
					On {dateDue} you will be charged {amountDue} for your subscription to the{' '}
					<strong>{currentPlan}</strong>.
				</p>
			);
		}

		// if the upcoming plan is not the same as the current plan
		// Ex: You're on the Starter Plan billed Yearly. On Oct 25, 2018 you will be changed to the Professional Plan and charged $15.
		if (upcomingPlanTitle !== account.planData.title) {
			return (
				<p>
					You're currently on the <strong>{currentPlan}</strong>. On {dateDue} your plan
					will be changed to the <strong>{upcomingPlan}</strong> and you will be charged{' '}
					{amountDue}.
				</p>
			);
		}
		
		// This should only show up when the plans are the same, but the payment periods are different.
		// Ex: You're on the Starter Plan billed Yearly. On Oct 25th, 2018 your account will be changed to the monthly payment period and charged $12.
		if (
			upcomingPlanTitle === account.planData.title &&
			account.paymentPeriod !== upcomingInvoice.paymentPeriod
		) {
			return (
				<p>
					On {dateDue} your plan will be changed to the <strong>{upcomingPlan}</strong>{' '}
					and you will be charged {amountDue}. You are currently on the{' '}
					<strong>{currentPlan}</strong>.
				</p>
			);
		}
		
		// Should be displayed if everything else fails. Generally this is for recurring subscriptions with no changes being made.
		// Ex: You are on the Professional Plan with a Yearly subscription. On Oct 25th, 2018 you will be charged $132.
		return (
			<p>
				On {dateDue} you will be charged {amountDue} for your subscription to the{' '}
				<strong>{currentPlan}</strong>.
			</p>
		);
	};

	getCountry = countryCode => {
		const country = StripeCountryCodes.find(item => {
			return item.code === countryCode;
		});
		if (country === undefined) return '';
		return country.name;
	};

	formatPlan = plan => {
		return plan.split('-')[0].toLowerCase();
    };
    
	handleCancelUpdatingDetails = () => {
		this.setState({showNewCardView: false});
		this.props.dismissMessage('payment');
    };
    
	handleCancelSubscription = () => {
		// do not need to pass throughany information
		this.props.accountPlanCancel()
		.then(res => {
			this.props.getBillingDetails();
			this.props.closeModal();
		});
	};
	
	handleChangeCardClick = (e) => {
		e.preventDefault()
		this.setState({showNewCardView: true})
		this.props.billingDetailsUpdateSetupIntent()
		// .then( resp => {})
		.catch( err => {
			// TODO: handle show error
			this.setState({showNewCardView: false})
			// console.log('billingDetailsUpdateSetupIntent ERROR!! err = ', err)
		});
	}

	handleChangeCardCancelClick = (e) => {
		this.props.paymentIntializeState();
		this.setState({showNewCardView: false})
	}
    
	/** STRIPE TEST CARDS - https://stripe.com/docs/testing#international-cards
	 
	 * 4242424242424242: No authentication required
	 
	 SCA Cards
	 * 4000002500003155: Requires authentication for one-time payments. No further authentication needed. 
	 * 4000002760003184: Requires authentication on all transactions, regardless of how the card is set up.
	 * 4000008260003178: Requires authentication for one-time payments. All payments will be declined with an insufficient_funds failure code even after being successfully authenticated or previously set up. 
	 
	 3D Secure Cards
	 * 4000000000003220: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000000000003063: 3D Secure required. By default, your Radar rules will request 3D Secure authentication for this card.
	 * 4000008400001629: 3D Secure required but payments will be declined with a card_declined failure code after authentication. By default, your Radar rules will request 3D Secure authentication for this card. 
	 * 378282246310005: 3D Secure is not supported on this card and cannot be invoked. The PaymentIntent will proceed without performing authentication.
	 */
	handleCardSetup = (stripeHandleCardSetup, payment_method_data) => {
		return stripeHandleCardSetup(
			this.props.payment.response.setupIntent.client_secret,
			{payment_method_data}
		)
		.then(resp => {
			if (resp.error) {
				return Promise.reject(resp.error)
			}
			this.setState({showNewCardView: false})
			return this.props.billingDetailsUpdate(resp.setupIntent.payment_method)
		})
		// resp.error above falls thru to this too. Also, this would be a 400's, internet connection issues
		.catch(err => {
			this.setState({loading: false});
			return Promise.reject(err)
		});
	};
}

function mapStateToProps(state) {
	return {
		account: state.account,
		billingDetails: state.billingDetails,
		modalOpen: state.modalOpen,
		payment: state.payment,
		dmioPlans: state.app.dmioPlans
	};
}

const mapDispatchToProps = {
	accountPlanCancel,
	billingDetailsUpdate,
	billingDetailsUpdateSetupIntent,
	closeModal,
	dismissMessage,
	getBillingDetails,
	openModal,
	paymentIntializeState,
	paymentReducerClearMessages,
};

BillingInformation = compose(
	injectStripe,
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(BillingInformation);

export default BillingInformation;
