import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {reduxForm, Field, Form} from 'redux-form';
import {I18n} from '@aws-amplify/core';

import PageAccess from '../Layout/page.access.jsx';
import NoteToUser from 'Widgets/NoteToUser.jsx';
import Message from 'Widgets/Message.jsx';
import {
	forgotPassword,
	confirmPassword,
	unVerifyPasswordReset
} from '../../actions';
import {
	clearUserMessages,
} from 'actions/app';

import {ROUTES} from 'Constants/app';

class Reset extends Component {
	constructor(props) {
		super(props)
		this.state = {email: false}
	}

	UNSAFE_componentWillMount() {
        this.props.clearUserMessages();
		// sets email for logged in user; initiliaze ir a redux-form goodies
		this.props.initialize({ email: this.props.email });
		this.setState({ email: this.props.email })
    }
    
	UNSAFE_componentWillReceiveProps(nextProps) {
		// checks if nextProps verify will be false, and previous was true,
		// if so, knows to push to home route. Might be better way to do this.
		if (nextProps.verify === false && this.props.verify === true) {
			this.props.history.push('/');
        }
		// Below fixes a bug when a logger in user refreshes on this page, their email still appears
        if(nextProps.email !== this.props.email ) {
			this.props.initialize({ email: nextProps.email });
			this.setState({ email: this.props.email })
        }
	}

	componentWillUnmount() {
		// will clear success message when user navigates away from profile page
		this.props.clearUserMessages();
		this.props.unVerifyPasswordReset();
	}

	render() {
		const {handleSubmit, loading, verify, authenticated} = this.props;

		const displayLoading = classNames({
			'far fa-spinner fa-spin spinner': loading
		});

		const disabled = classNames({
			disabledTextField: loading
		});

		return (
			<PageAccess>
				<div className="access">
					<div className="access-head">
						<a href="https://www.dreamaker.io" className="access-logo" target_="blank" ></a>
						<h2 style={{ marginBottom: '2rem' }}>dreamaker.io</h2>
						<h1>
							{I18n.get('Reset your password')}{' '}
							<NoteToUser
								show={verify === true ? '' : 'none'}
								startTag="("
								endTag=")"
								note=" check your email for the code "
							/>
						</h1>
					</div>
					<div className="access-body">
						<div className="form form-access">
							{this.renderMessage()}
							<Form onSubmit={handleSubmit(this.handleFormSubmit)}>
								{this.renderForm(displayLoading, disabled, verify, authenticated)}
							</Form>
						</div>
						<br />
						{ verify 
						? <span>Code not arrive?{' '}
							<a href="" onClick={this.handleReSend }>
								Resend verification code
							</a>{authenticated ? '' : ' | '}
						</span>
						: null
						}
						<span style={{display: authenticated ? 'none' : ''}} >Here by mistake? <Link to={ROUTES.SIGNIN}>Sign in</Link>{' instead'}</span>
					</div>
				</div>
			</PageAccess>
		);
	}

	renderForm = (displayLoading, disabled, verify, authenticated) => {
		const email = this.props.email;

		// Display whole form with password change for verification
		if (verify) {
			return (
				<div>
					<div className="form-group">
						<Field
							name="email"
							type="email"
							component={renderInput}
							label="Email"
							authenticated={true}
						/>
					</div>
					<div className="form-group">
						<Field
							name="verificationCode"
							type="input"
							component={renderInput}
							label="Validation Code:"
							autoFocus={true}
						/>
					</div>
					<div className="form-group">
						<Field
							name="password"
							type="password"
							component={renderInput}
							label="Password"
						/>
					</div>
					<div className="form-group">
						<Field
							name="passwordConfirm"
							type="password"
							component={renderInput}
							label="Confirm password"
						/>
					</div>
					<button action="submit" className="btn btn-secondary" disabled={disabled}>
						Confirm reset <i className={displayLoading} />
					</button>
				</div>
			);
		} else {
			return (
				<div>
					<div className="form-group">
						<Field
							name="email"
							type="email"
							component={renderInput}
							label="Email"
							authenticated={authenticated || email}
							autoFocus={true}
						/>
					</div>
					<div className="form-actions">
						<button
							type="reset"
							style={{marginRight: '10px'}}
							className="btn btn-secondary btn-danger"
							onClick={() => this.props.history.goBack()}>
							Cancel
						</button>
						<button action="submit" className="btn btn-secondary" disabled={disabled}>
							Reset password <i className={displayLoading} />
						</button>
					</div>
				</div>
			);
		}
	};

	renderMessage() {
		if (this.props.message) {
			return (
				<div className="text-success">
					<strong>
						<i>{this.props.message}</i>
					</strong>
					<br />
					<br />
				</div>
			);
		} else if (this.props.err) {
			return (
				<div className="text-danger">
					<strong>
						<i>{this.props.err}</i>
					</strong>
					<br />
					<br />
				</div>
			);
		}
	}

	handleReSend = e => {
		e.preventDefault();
		const email = this.state.email
		this.props.forgotPassword( {email}, true);
	}

	handleFormSubmit = formProps => {
		if (this.props.verify) {
			this.props.confirmPassword(formProps);
		} else {
			this.setState({email: formProps.email})
			this.props.forgotPassword(formProps);
		}
	};
}

const renderInput = field => {
	const {label, type, input, meta: {error, submitFailed}, authenticated} = field;
	return (
		<div>
			<input
				{...input}
				type={type}
				className="form-control"
				placeholder={label}
				disabled={authenticated}
				autoFocus={field.autoFocus}
			/>
			{submitFailed && error && <div className="text-danger">{error}</div>}
		</div>
	);
};

function validate(formProps) {
	// todo: UNCOMMENT & FIX!!
	//dm_print('Verify.validate called - ', formProps)

	const errors = {};

	if (!formProps.email) {
		errors.email = 'Please enter an email';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
		errors.email = 'Invalid email address';
	}

	if (!formProps.password) {
		errors.password = 'Please enter a password';
	} else if (
		!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}).*$/.test(
			formProps.password
		)
	) {
		errors.password =
			'Password must have a length of at least 8 and contain uppercase & lowercase characters, numbers and special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & /  , > < \' : ; | _ ~ `';
	}

	if (!formProps.passwordConfirm) {
		errors.passwordConfirm = 'Please confirm your password"';
	} else if (formProps.password !== formProps.passwordConfirm) {
		errors.password = 'Passwords must match';
	}

	return errors;
}

function mapStateToProps (state) {
	const {_, user} = state
	return {
		authenticated: user.authenticated,
		err: user.error,
		loading: user.loading,
		message: user.message,
        email: user.data.email || _.resetEmail,
		verify: user.verify,
	};
}

const mapDispatchToProps = {
	forgotPassword,
	confirmPassword,
	clearUserMessages,
	unVerifyPasswordReset
};

Reset = compose(
	reduxForm({form: 'forgotpass', validate}),
	connect(mapStateToProps, mapDispatchToProps)
)(Reset);

export default Reset;
