import { 
    ERROR_ADD,
    SEEK_TO, 
    SET_CURRENTTIME, 
    SET_PLAYER 
} from 'Constants/actions';

import {DMIO_RELEASE} from 'config';

// VIDEO
/**
 * Plays a snippet of the video
 * @param {Number} mediaDuration Duration of source media in seconds
 * @param {Number} mediaTimestamp Timestamp from which to calulate start time in seconds
 * @param {Number} leadTime Number of seconds befor mediaTimestamp to start play
 * @param {Number} lagTime Number of seconds after mediaTimestamp to stop play
 */
export function playSnippet(mediaDuration, mediaTimestamp, leadTime, lagTime) {
    var sliceStart;

    mediaTimestamp > leadTime
    ? (sliceStart = mediaTimestamp - leadTime)
    : (sliceStart = 0.001); // needs to be 0.001 due to react-video player having bug and not playing at just 0

    var sliceEnd = mediaTimestamp + lagTime;
    sliceEnd > mediaDuration ? (sliceEnd = mediaDuration) : sliceEnd;

	return {
        type: SEEK_TO,
        payload: { seekStart: sliceStart, seekEnd: sliceEnd }
	};
}

export function resetSeekStartEnd() {
	return {
		type: SEEK_TO,
		payload: { seekStart: null, seekEnd: null }
	};
}

// export function setCurrentTime(time) {
// 	return {
// 		type: SET_CURRENTTIME,
// 		payload: time
// 	};
// }

export function setPlayer(player) {
	return {
		type: SET_PLAYER,
		payload: player
	};
}

/**
 * NB: Should only be called on fata error !!!!
 * @param {Object} error 
 */
export function mediaPlayerErrorHandler(error) {
    const errMsg = `MediaPlayer FATAL ${error.type}: ${error.details}` 
    Rollbar.error(errMsg, {dmioRelease: DMIO_RELEASE, ...error});
    return {
        type: ERROR_ADD, // supress erro with a non existent type,
        payload: `${errMsg}... Try reloading this page.`
    };
}