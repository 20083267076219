import React, { Component } from 'react';

class DashboardsMiddlebarDashboardDefault extends Component {
  render(){
    const { handleDefaultSelect, selectedDashboard } = this.props
    return (
      <div className="dbedit-default-picker">
        {/* <div className="picker-div"> */}
        <div style={{display:"flex"}}>
          <h6 className="h6-small">Default</h6>
          <i onClick={handleDefaultSelect}
            style={{marginLeft: "1rem"}}
            title="Default dashboards are automatically added to all new analysis projects."
            className={selectedDashboard.default ? "far fa-check-square" : "far fa-square"}/>
        </div>
      </div>
    )
  }
}

export default DashboardsMiddlebarDashboardDefault;