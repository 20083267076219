import React from 'react';
import classNames from "classnames";

import Slider from './Slider.jsx';

const SliderWrapperText = ({ isChecked, handleClick, textLeft, textRight }) => {
  const leftHighlighted = classNames({
    // will be highlighted if false
    highlighted: !isChecked,
    notHighlighted: isChecked
  })
  const rightHighlighted = classNames({
    // will be highlighted if true
    highlighted: isChecked,
    notHighlighted: !isChecked
  })
  return (
    <div className="group toggle-switch">
		  <div className="group toggle-switch-div">
        <span className={leftHighlighted}>{textLeft}</span>
        <Slider
          style={{ margin: '0 5px' }}
          className="slider"
          isChecked={isChecked}
          handleClick={handleClick}
        />
        <span className={rightHighlighted}>{textRight}</span>
      </div>
    </div>
  )
}

export default SliderWrapperText