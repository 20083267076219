import _ from 'lodash';
import classNames from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TransitionGroup} from 'react-transition-group';

import { v4 as uuidV4 } from 'uuid';

import { I18n } from '@aws-amplify/core';

import DashboardsLeftSidebarDefaults from './Dashboards.LeftSidebar.Defaults.jsx';

// needed as we are using the ERROR_ADD const to send a toast.
import {ERROR_ADD} from 'Constants/actions';
import {createDashboard} from './actions';
import {messageToast} from '../../actions/app';
import {FadeUpTransition} from 'Widgets/FadeUpTransition.jsx';

import { UNLIMITED } from 'Constants/app'

class DashboardsLeftSidebar extends Component {

    
	render() {
		return (
			<div className="dbedit-sidebar-dashboards">
				<h6
					className="cursor-default list-group-item"
					data-toggle="tooltip"
					title="Default dashboards are automatically added to all new analysis projects.">
					{I18n.get('Default Dashboards')}
					{/**<i className="far fa-question" title="Default dashboards are automatically added to all new analysis projects."/>**/}
				</h6>
				<DashboardsLeftSidebarDefaults
					handleSelectDashboard={this.props.handleSelectDashboard}
					selectedDashboard={this.props.selectedDashboard}
				/>
				<h6 className="list-group-item" onClick={this.handleCreateDashboard}>
					{I18n.get('Create new dashboard')}
					<i className="far fa-plus-circle pull-right" />
				</h6>
				<ul className="list-group list-group-selects">
					<TransitionGroup>{this.renderDashboards()}</TransitionGroup>
				</ul>
			</div>
		);
    }

	renderDashboards = () => {
		const {allDashboards} = this.props;
		const {selectedDashboard} = this.props;
		// grabs all dashboards from the redux store
		const returnedDashboards = Object.keys(allDashboards)
			.map(dashboard => {
				// saves the dashboard object to a variable so we can access it later in the map
				const dashboardObj = allDashboards[dashboard];
				// if it's a default, we don't want to return it here
				if (dashboardObj.default) return null;

				let listItem = classNames({'list-group-item': true, option: true});
				// if the selected dashboard is not null,
				if (selectedDashboard !== null) {
					// will check if the selected dashboards ID is equivalent to the dashboard in the map
					listItem = classNames({
						'list-group-item': true,
						selected: selectedDashboard.dashboardID === dashboard
					});
				}
				return (
					<FadeUpTransition key={dashboard} classNames="list-selects">
						<li
							onClick={() =>
								this.props.handleSelectDashboard(dashboardObj.dashboardID)}
							ref={dashboardObj.title}
							className={listItem}
							key={dashboard}>
							{dashboardObj.title}
						</li>
					</FadeUpTransition>
				);
			})
			// will filter out any item that is falsy, i.e. undefined / null
			.filter(item => item)
			// sorts the returned dashboards by title
			.sort((a, b) => {
				// a.props.children.ref is the title of the is the title of the item
				const dashboardA = a.props.children.ref.toUpperCase();
				const dashboardB = b.props.children.ref.toUpperCase();
				if (dashboardA < dashboardB) return -1;
				if (dashboardA > dashboardB) return 1;
				return 0;
			});
		if (returnedDashboards.length <= 0) {
			return (
				<FadeUpTransition classNames="list-selects">
					<li className="no-defaults-selected">{I18n.get('No dashboards')}</li>
				</FadeUpTransition>
			);
		}
		return returnedDashboards;
	};
    
	handleCreateDashboard = () => {
		const {planData, usageData} = this.props;
		// how many you need to delete before you're below the plans data
		if (UNLIMITED.value !== planData.maxDashboards &&
			usageData.dashboardCount >= planData.maxDashboards) {
			const numberToDelete = usageData.dashboardCount + 1 - planData.maxDashboards;
            let message = `Your account allows a maximum of ${planData.maxDashboards} dashboard/s. You currently have ${usageData.dashboardCount}. Please delete ${numberToDelete} dashboard(s) before creating more.`
			this.props.messageToast( ERROR_ADD, message );
			return;
		}
		// creates the new dashboard object
		const newDashboardData = {
			dashboardID: uuidV4(),
			note: '',
			title: 'New Dashboard',
			dashboardTags: [],
			dashboardTagsItems: []
		};

		// creates the new dashboard, once it's created, it then selects it
		this.props
			.createDashboard(newDashboardData)
			.then( resp => {
				this.props.handleSelectDashboard(newDashboardData.dashboardID)
			});
	};
}

function mapStateToProps(state) {
	return {
		allDashboards: state.userDashboards.data,
		planData: state.account.planData,
		usageData: state.account.usageData,
		dashboardState: state.userDashboards
	};
}

export default connect(mapStateToProps, {createDashboard, messageToast})(DashboardsLeftSidebar);
