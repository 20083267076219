import React, { Component } from 'react';
import { connect } from 'react-redux';

import { I18n } from '@aws-amplify/core';

import { IMAGES } from 'Constants/app';
const { ZOOM_LOGO } = IMAGES;

import { openModal } from 'actions/app';
import { LoadingLips } from 'Widgets/LoadingLips.jsx'

import {
	zoomInitiateAuthorization,
} from '../Landing/actions/zoom'

class ProjectImporter extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		}
	}
	
	render () { 
		const {loading } = this.state
		return (
			<div className="uploader-thumb">
				{loading
					? <div className="uploader-thumb-face" >
						<LoadingLips />
						<span>
							<u>{I18n.get('Import from Zoom')}</u>
						</span>
					</div>
					: <div className="uploader-thumb-face"
						onClick={e => {
							this.handleOnClick(e, 'modalZoomImport')
						}} >
						<img className='company-logo' src={ZOOM_LOGO} />
						<span>{I18n.get('Import from Zoom')}</span>
					</div>
				}
			</div>
		);
	}
	
	handleOnClick = (e, name) => {
		e.preventDefault();
		const { 
			folderID,
			zoom,
			zoomInitiateAuthorization,
		} = this.props
		
		if (!zoom.isAuthorized) {
			this.setState({ loading: true });
			zoomInitiateAuthorization(folderID)
				.then(resp => {
					if ('OAuthAlreadyAuthorizedException' === resp) {
						this.setState({ loading: false });
						this.props.openModal(name)
					}
			})
		} else {
			this.props.openModal(name);
		}
	};
}

function mapStateToProps(state) {
	return {
		zoom: state.user.data.oauth.zoom || {}
	};
}

const mapDispatchToProps = {
	openModal,
	zoomInitiateAuthorization
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectImporter);