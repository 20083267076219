import _ from 'lodash'

import {
    ACCOUNT_LOADING,
    ACCOUNT_GET,
    ACCOUNT_REHYDRATE_COLLABS,
    ACCOUNT_REHYDRATE_SHARES,
	ACCOUNT_REHYDRATE_USAGEDATA,
    ACCOUNT_REHYDRATE_USAGEDATA_MTD,
	CUNAUTH_USER,
    DMUSER_FETCH,
    // MEMBER_ACCEPT,
    MEMBER_LEAVE,
	ACCOUNT_REHYDRATE,
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: false,
	accountID: null,
	userID: null,
	planType: null,
	paymentPeriod: null,
	usageData: {},
	collaborators: [],
	dateUpdated: null,
	planTypeUpdatedDate: null,
	planData: {},
	hasStripe: null
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return { ...INITIAL_STATE };

		case DMUSER_FETCH: {
            return { 
                ...action.payload.account, 
                loading: false 
            };
        }
          
        case ACCOUNT_REHYDRATE:
        case ACCOUNT_GET: {
            return { 
                ...action.payload, 
                loading: false 
            };
        }

		case ACCOUNT_REHYDRATE_COLLABS: {
			// payload is an array containing new collaborator array
			return {
				...state,
				collaborators: [...action.payload]
            };
        }
            
        case ACCOUNT_REHYDRATE_SHARES: {
			// payload is an array containing new share array
			return {
				...state,
				shares: [...action.payload]
			};
        }

		case ACCOUNT_REHYDRATE_USAGEDATA: {
			// payload is an object containing updated usageData
			return {
				...state,
				usageData: {
					...state.usageData,
					...action.payload
				}
            };
        }

        case ACCOUNT_REHYDRATE_USAGEDATA_MTD: {
			// payload is an object containing updated usageData
			return {
				...state,
				usageDataMTD: {
					...state.usageDataMTD,
					...action.payload
				}
            };
        }

        // case MEMBER_ACCEPT: {
        //     //payload is organizationID
        //     return {
        //         ...state,
        //         organizationID: action.payload
        //     }
        // }

        case MEMBER_LEAVE: {
            //payload is organizationID
            return {
                ..._.omit(state, 'organizationID')
            }
        }

		default:
			return state;
	}
}
