import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

// import { I18n } from 'aws-amplify'
import { I18n } from '@aws-amplify/core';

import {itemAnalysisDbsUpdate,itemAnalysisUpdate} from '../Analysis/actions/index';
import Modal from 'Widgets/Modal.jsx';
import {ROUTES} from 'Constants/app';

class ModalDashboardSelector extends Component {
	static contextTypes = {
		closeModal: PropTypes.func,
	};
    
	render() {
		const {selectedItem, userDashboards} = this.props;
		if (userDashboards === undefined || selectedItem === undefined) return null;
		return (
			<Modal size="modal-md">
				<div className="modal-header">
                    <div className="modal-subtitle">{I18n.get('Dashboard settings')} </div>
                    <div className="modal-title">{selectedItem.title}</div>
				</div>
				<div className="modal-body">
					<div className="row data-list-container">
						<div className="col-12 list-over-flow">
							<ul className="list-group">
                                {this.renderDashboards()}
                            </ul>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={this.context.closeModal}>
						{I18n.get('Done')}
					</button>
					<Link onClick={this.handleOpenEditDashboards} to={ROUTES.DASHBOARDEDITPAGE}>
						{I18n.get('Edit dashboards')} <i className="far fa-external-link"  />
					</Link>
				</div>
			</Modal>
		);
    }
    
	renderDashboards = () => {
		const {userDashboards, selectedItemAnalysis, folderItems} = this.props;
		// if there are no dashboarsd to select from, returns the message
		if (_.isEmpty(Object.keys(userDashboards)))
			return (
				<span style={{textAlign: 'center'}}>There are no dashboards to choose from.</span>
			);
		// grabs the dashboardItems array of selected item
		const selectedDashboardItems = selectedItemAnalysis.dashboards;
		const renderedDashboards = Object.keys(userDashboards)
			.map(dashboardID => {
				// checking if the item is currently inside the selectedDashboard or not.
				const isChecked = selectedDashboardItems.includes(dashboardID);
				const selectedSquare = classNames({
					fal: true,
					'fa-square': !isChecked,
					'fa-check-square': isChecked
				});
				return (
					<li
						onClick={() => this.handleUpdate(dashboardID, !isChecked)} // toggle isChecked when passing through
						className="list-group-item"
						key={dashboardID}>
						{userDashboards[dashboardID].title}
						<i className={selectedSquare} aria-hidden="true" />
					</li>
				);
			})
			// filters out any null elements
			.filter(item => item !== null)
			// sorts the non selected dashboards by name
			.sort((a, b) => {
				// grabbing the first child item since it is the title of the dashboard
				const dashboardA = a.props.children[0].toUpperCase();
				const dashboardB = b.props.children[0].toUpperCase();
				if (dashboardA < dashboardB) return -1;
				if (dashboardA > dashboardB) return 1;
				return 0;
			});
		return renderedDashboards;
    };

	handleUpdate = (dashboardID, checked) => {
		let {selectedItem, selectedItemAnalysis} = this.props;
        let newDashboardItemsArr = selectedItemAnalysis.dashboards;
        
		// if the item is not checked, we want to filter it out.
		!checked &&
			(newDashboardItemsArr = newDashboardItemsArr.filter(item => item !== dashboardID));

        // if the item is checked, we should add it.
        checked && (newDashboardItemsArr = [...newDashboardItemsArr, dashboardID]);

        selectedItem.itemAnalyses[0] = { ...selectedItemAnalysis, dashboards: [...newDashboardItemsArr] }

        // will need to pass analysis id, and updated prop.
        this.props.itemAnalysisDbsUpdate(dashboardID, checked);
		this.props.itemAnalysisUpdate(selectedItem);
	};
	
	/**
	 * Opens dashboard editor and closes modal. 
	 * Fixes race condition where new companonet called before close modal executes,
	 * resulting in an error in the new component
	 */
	handleOpenEditDashboards = (e) => {
		this.props.history.push(ROUTES.DASHBOARDEDITPAGE);
		this.context.closeModal(e);
	}
}

function mapStateToProps(state) {
	return {
		folderItems: state.folderItems.data,
		userDashboards: state.userDashboards.data,
        selectedItem: state.folderItems.selectedItem,
        selectedItemAnalysis: state.folderItems.selectedItemAnalysis,        
	};
}


ModalDashboardSelector = compose(
	withRouter, 
	connect(mapStateToProps, {itemAnalysisDbsUpdate,itemAnalysisUpdate}))(ModalDashboardSelector);

export default ModalDashboardSelector