import _ from 'lodash';

import {
	CUNAUTH_USER,
    DASHBOARDS_LOADING,

    DASHBOARD_CREATE,
    DASHBOARD_UPDATE,
	DASHBOARD_DELETE,
	DASHBOARD_REMOVE_TAG,
    
	DASHBOARDS_ERROR,
	DASHBOARDS_MESSAGE,
	DASHBOARDS_FETCH,
} from 'Constants/actions';

const INITIAL_STATE = {
	loading: true,
	error: null,
	message: null,
	data: {}
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return {...INITIAL_STATE, loading: false};
        
        case DASHBOARDS_LOADING:
			return {...state, loading: true};

		case DASHBOARDS_FETCH:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
				message: null
			};

		case DASHBOARDS_MESSAGE:
			return {
				...state,
				error: null,
				message: action.payload,
				loading: false
			};

		case DASHBOARDS_ERROR:
			return {
				...state,
				error: action.payload,
				message: null,
				loading: false
            };
            
        case DASHBOARD_CREATE:
			return {
                ...state,
                loading: false,
				data: {
					[action.payload.dashboardID]: {
						...action.payload
					},
					...state.data
				}
			};

        case DASHBOARD_UPDATE:
			return {
                ...state,
				// error: null,
				// message: null,
				loading: false,
				data: {
					...state.data,
					[action.payload.dashboardID]: {
						...state.data[action.payload.dashboardID],
						...action.payload.updatedProp
					}
				}
            };
            
        case DASHBOARD_DELETE:
			let newState = Object.assign({}, state);
			// if you do not deeply set this, it will mutate the original state and create issues.
			newState.data = Object.assign({}, state.data);
			delete newState.data[action.payload];
			return newState;

		case DASHBOARD_REMOVE_TAG:
			const updatedDashboards = state.data;
			const newDashboards = Object.keys(updatedDashboards).reduce((obj, dashboard) => {
				const newTags = updatedDashboards[dashboard].dashboardTags.filter(
					tagID => tagID !== action.payload
				);
				obj[dashboard] = updatedDashboards[dashboard];
				obj[dashboard].dashboardTags = newTags;

				return obj;
			}, {});

			return {
				...state,
				data: newDashboards
			};



		default:
			return state;
	}
}
