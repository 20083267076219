import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MOODLE_TOOL_URL } from 'config';
import { IMAGES } from 'Constants/app';
const {MOODLE_LOGO} = IMAGES;

import { addLTIConsumer, updateLTIConsumer } from './actions/index'

import {
	dismissMessage,
} from 'actions/app';

import { Loading } from 'Widgets/Loading.jsx';
import Message from 'Widgets/Message.jsx'

class IntegrationExistingLti extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMoodleIntegration: false,
			loading: {
				moodleLti: false
			},
		};
	}

	componentWillUnmount () {
		this.props.dismissMessage();
	}

	render () {
		const { ltiOrganizationConsumers } = this.props;

		/** Create existing integrations */
		const ltiIntegrations = []
		Object.keys(ltiOrganizationConsumers).forEach((item) => {
			const consumer = ltiOrganizationConsumers[item];
			const loading = this.state.loading[item]
			ltiIntegrations.push(
				<div className='row intergration' key={item}>
					<div className='col-12'>
						{this.renderIntegrationHeader(consumer.ltiConsumerName)}
						<div className="row" style={{ paddingLeft: '1.3em' }}>
							<div className='col-12 container'>
								{this.renderIntegration('Consumer ID', 'ltiConsumerID', item, true)}
								{consumer.ltiConsumerKey ? this.renderIntegration('Consumer key', 'ltiConsumerKey', consumer.ltiConsumerKey, true) : null }
								{this.renderIntegration('Tool URL', 'toolURL', MOODLE_TOOL_URL, true)}
								{this.renderIntegration('Secret key', 'ltiConsumerSecret', consumer.ltiConsumerSecret, true)}
							</div>
							<div className='col-12 container' style={{ marginTop: '.8rem', textAlign: 'right' }}>
								<button
									onClick={ () => this.handleUpdateSecretkey(item)}
									className="btn btn-success"
									disabled={loading}>
									Reset Secret Key <i className={loading ? 'far fa-spinner fa-spin spinner' : ''} />
								</button>
							</div>
						</div>
					</div>
				</div>
			)
		});

		const { loading, showMoodleIntegration } = this.state;

		return (
			<div>
				{/* { this.renderSectionHeader('Add an Integration') }
				{ this.renderMessages() } */}
				{ ltiIntegrations.length > 0 ? this.renderSectionHeader('Existing LTI Integrations') : null}
				{ ltiIntegrations}
			</div>
		);
	}

	renderIntegration = (title, name, defaultValue, disabled=false, type = 'input') => {
		return (
			<div className='preference'>
				<div style={{ minWidth: '110px' }}>{title}</div>
				<div style={{ color: '#cfd2da' }}>
					{/* <i className='far fa-paste pointer' onClick={()=>this.handleCopy(name)} />{'  '} */}
					<input
						className={ disabled ? 'form-code' : 'form-text'}
						defaultValue={defaultValue}
						disabled={disabled}
						key={`${defaultValue}-${name}`}
						//onKeyDown={this.handleOnKeyDown}
						name={name}
						ref={name}
						id={name}
						size={defaultValue.length > 0 ? defaultValue.length : 30}
						style={{ textAlign: 'right', color: 'grey', cursor: 'text' }}
					/>
				</div>
			</div>
		)
	}

	renderIntegrationHeader = (header, provider = 'moodle') => {
		return (
			<div className="row ">
				<div className='col-12 header company'>
					<img className='company-logo' src={MOODLE_LOGO} />
					{undefined === header ? 'Integration has not yet been used' : header}
				</div>
			</div>
		)
	}

	/** Displays msg if exists, or this.props.message if exists. */
	renderMessages = () => {
		let { message, error } = this.props.messages
		return (
			<Message message={message} textClass={error ? 'danger' : 'success'} dismissable={true} style={ {paddingBottom: '0rem'}}/>
		);
	}

	renderSectionHeader = (header) => {
		return 	(
			<div className="row">
				<div className="col-12 settings-align-center" style={{ paddingLeft: '0px !important' }}>
					<h5>{header}</h5>
				</div>
			</div>
		)
	}

	toggleMoodleIntegration = e => {
		e.preventDefault();
		e.stopPropagation();
		this.refs['moodleLtiConsumerID'].focus();
		this.setState({ showMoodleIntegration: !this.state.showMoodleIntegration });
	};

	// handleCopy = (id) => {
	// 	var copyText = document.getElementById(id);
	// 	copyText.select();
	// 	document.execCommand("copy");
	// 	debugger
	// }

	handleUpdateSecretkey = (ltiConsumerID) => {
		this.setState({ loading: {
			...this.state.loading,
			[ltiConsumerID]: true
		}})

		this.props.updateLTIConsumer(ltiConsumerID)
		.finally(() =>{
			this.setState({ loading: {
				...this.state.loading,
				[ltiConsumerID]: false
			}})
		})
	};

	handleInstallLtiIntegration = (integration) => {
		if (!this.props.isOrganization) return;

		this.setState({ loading: {
			...this.state.loading,
			[integration]: true
		}})

		switch(integration) {
			case 'moodleLti': {
				const ltiConsumerID = this.refs['moodleLtiConsumerID'].value;
				if('' !== ltiConsumerID) {
					this.props.addLTIConsumer( this.refs['moodleLtiConsumerID'].value)
					.then( resp => {
						this.refs['moodleLtiConsumerID'].value = '';
						this.setState({ showMoodleIntegration: false });
					})
					.finally(() =>{
						this.setState({ loading: {
							...this.state.loading,
							[integration]: false
						}})
					})
					return
				}
			}
			default: {
				this.setState({ loading: {
					...this.state.loading,
					[integration]: false
				}})
			}
		}
	};

}

function mapStateToProps (state) {
	let { account } = state;
	return {
		isOrganization: account.isOrganization || false,
		ltiOrganizationConsumers: undefined === account.ltiOrganizationConsumers ? {} : account.ltiOrganizationConsumers,  // during initial load, `undefined` if no integrations `{}`
		messages: state.messages,
	};
}

const mapDispatchToProps = {
	addLTIConsumer,
	dismissMessage,
	updateLTIConsumer,
};


export default connect(mapStateToProps, mapDispatchToProps)(IntegrationExistingLti);