// import _ from 'lodash';
// import { v4 as uuidV4 } from 'uuid';

import { request } from 'actions/';
import { accountRehydrate, messageToast } from 'actions/app';

import { apiConfig } from 'config';

import {
	ACCOUNT_REHYDRATE_USAGEDATA,

	DASHBOARD_CREATE,
	DASHBOARD_UPDATE,
	DASHBOARD_DELETE,
	DASHBOARD_REMOVE_TAG,

	DASHBOARD_TAG_CREATE,
	DASHBOARD_TAG_UPDATE,
	DASHBOARD_TAG_DELETE,

	DASHBOARDS_ERROR,
	DASHBOARDS_FETCH,
	MESSAGE_ADD
} from 'Constants/actions';


// DASHBOARDS

/**
  * 
  * @param {Object} newDashboardData 
  * @returns Promise
  */
export function createDashboard (newDashboardData) {
	return function (dispatch, getState) {
		// gets previous state before the item is updated
		const prevState = getState();
		const prevDashboardCount = prevState.account.usageData.dashboardCount;
		// we call this immediately, so someone can not click create new dashboard 10 times in a row
		// and get passed our limits.
		dispatch({
			type: ACCOUNT_REHYDRATE_USAGEDATA,
			payload: { dashboardCount: prevDashboardCount + 1 }
		});

		// we initially create the dashboard and then send it to redux immediately,
		// see resp
		dispatch({
			type: DASHBOARD_CREATE,
			payload: newDashboardData
		});

		const requestObj = {
			method: 'post',
			url: `${apiConfig.dashboardCreate}`,
			data: {
				...newDashboardData
			}
		};

		return request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {
				// since we already created the dashboard so that it immediately shows up, we now forward the new data (resp)
				// to redux, allowing it to fill in the data that was missed through our own creation.
				dispatch({
					type: DASHBOARD_UPDATE,
					payload: {
						dashboardID: newDashboardData.dashboardID,
						updatedProp: {
							...resp[newDashboardData.dashboardID]
						}
					}
				});
				dispatch(accountRehydrate(resp));
				return Promise.resolve()
			})
			.catch(err => {
				// if it fails to create a new dashboard, we reset the app back
				dispatch({
					type: DASHBOARD_DELETE,
					payload: newDashboardData.dashboardID
				});

				// if it fails to create a new dashboard, we set the count back to it's previous count.
				dispatch({
					type: ACCOUNT_REHYDRATE_USAGEDATA,
					payload: { dashboardCount: prevDashboardCount }
				});
				// return Promise.reject();
			});
	};
}

/**
  * Deletes the dashboard with dashboardID. 
  * If deleteDependency is false, delete is not executed IF there dependencies are data is returned, otherwise deletes
  * If deleteDependency is true, delete is executed regardless
  * @param {String} dashboardID dashbaordID of dashboard to delete
  * @param {Boolean} deleteDependency true if dependencies are to be deleted - IE, forces the delete 
  * @returns 
	* 	On success: resolved Promise with data object if their is a dependancy.
	*		On error: nothing. Errors handled by global error handler
  */
export function deleteDashboard (dashboardID, deleteDependency) {
	return function (dispatch) {
		let requestObj = {
			method: 'DELETE',
			url: `${apiConfig.dashboardDelete}?dashboardID=${dashboardID}&deleteDependency=${deleteDependency}`
		};


		return request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {

				// if there is a dependency, we want to return the responses - IE, do not update global state yet
				if (resp.hasOwnProperty('dependency')) return Promise.resolve(resp);

				// if the dashboard has no dependencies, then we will delete the dashboard
				dispatch({
					type: DASHBOARD_DELETE,
					payload: dashboardID
				});
				dispatch(messageToast(MESSAGE_ADD, 'Dashboard deleted.', 1500));
				dispatch(accountRehydrate(resp));
				return Promise.resolve();
			})
	};
}

/**
 * Updates dashboard with dashboardID.
 * @param {String} dashboardID dashboardID of dashboard to update
 * @param {*} updatedProp string or object that requires updated
 */
export function updateDashboard (dashboardID, updatedProp) {
	return function (dispatch, getState) {
		dispatch(messageToast(MESSAGE_ADD, 'Saving...', 1500));
		const prevState = getState().userDashboards.data[dashboardID];

		// Instant state update
		dispatch({
			type: DASHBOARD_UPDATE,
			payload: { dashboardID, updatedProp }
		});

		// API Update
		const requestObj = {
			method: 'post',
			url: `${apiConfig.dashboardUpdate}`,
			data: {
				dashboardID,
				...updatedProp
			}
		};

		request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {
				dispatch(messageToast(MESSAGE_ADD, 'Saved.', 1500));
			})
			.catch(err => {
				// Error display handled by global error handler
				// if there is an error, returns the dashboard to it's previous state
				dispatch({
					type: DASHBOARD_UPDATE,
					payload: { dashboardID, updatedProp: prevState }
				});
			});
	};
}


// TAGS
/**
  * Used for creating a blank dashboard tag.
  * @param {Object} newTagData includes dashboardTagID, color, label, and type
  * @returns Promise
  */
export function createDashboardTag (newTagData) {
	return function (dispatch) {
		// Instant state update
		dispatch({
			type: DASHBOARD_TAG_CREATE,
			payload: newTagData
		});

		// API Update
		const requestObj = {
			method: 'post',
			url: `${apiConfig.tagCreate}`,
			data: {
				...newTagData
			}
		};

		return request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {
				// since we already created the tag so that it immediately shows up, we now forward the new data (response)
				// to redux, allowing it to fill in the data that was missed through our own creation.
				dispatch({
					type: DASHBOARD_TAG_UPDATE,
					payload: {
						dashboardTagID: resp.dashboardTagID,
						updatedProp: { ...resp }
					}
				});
			})
			.catch(err => {
				// removes the recently created tag if the creation of the tag failed.
				dispatch({
					type: DASHBOARD_TAG_DELETE,
					payload: newTagData.dashboardTagID
				});
			});
	};
}


export function deleteDashboardTag (dashboardTagID, deleteDependency) {
	return function (dispatch, getState) {

		// API Update
		let requestObj = {
			method: 'DELETE',
			url: `${apiConfig.tagDelete}?dashboardTagID=${dashboardTagID}&deleteDependency=${deleteDependency}`
		};

		return request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {

				/** NOTE: {dependency} is returned in 2 cases:
				 * 1. deleteDependancy is {false} && dependencies exisit in either dashboards, itemAnalysis, or templates
				 * 2. deleteDependency is {true} && dependency exists in itemAnalysis
				 * In both cases, we return the dependecny to the user
				 */

				var deleteFromUserTags = true;

				if (resp.hasOwnProperty('dependency')) {
					// Confirmation required before deleting - return to user
					if (!deleteDependency) return Promise.resolve(resp);

					// Confirmation obtained but itemAnalysis dependancy exists - dont deleteFromUsertags
					if (resp.dependency.hasOwnProperty('items')) deleteFromUserTags = false;
				}

				// Tag has no depencies
				// 1. delete the tag from dashboards
				dispatch({
					type: DASHBOARD_REMOVE_TAG,
					payload: dashboardTagID
				});

				// and 2. delete from state.userTags if deleteFromUserTags is true
				if (deleteFromUserTags) {
					dispatch({
						type: DASHBOARD_TAG_DELETE,
						payload: dashboardTagID
					});
					dispatch(messageToast(MESSAGE_ADD, 'Tag deleted.', 1500));
				}

				return Promise.resolve(resp);
			})
	};
}

export function updateDashboardTag (dashboardTagID, updatedProp) {
	return function (dispatch, getState) {
		dispatch({ type: MESSAGE_ADD, payload: 'Saving...' });
		const prevState = getState().userDashboardTags.data[dashboardTagID];
		// State update instantly
		dispatch({
			type: DASHBOARD_TAG_UPDATE,
			payload: { dashboardTagID, updatedProp }
		});


		const requestObj = {
			method: 'post',
			url: `${apiConfig.tagUpdate}`,
			data: {
				dashboardTagID,
				...updatedProp
			}
		};

		return request(requestObj, DASHBOARDS_ERROR)
			.then(resp => {
				dispatch(messageToast(MESSAGE_ADD, 'Saved', 1500));
			})
			.catch(err => {
				// if the tag fails to update, we reset it back to it's previous state
				dispatch({
					type: DASHBOARD_TAG_UPDATE,
					payload: { dashboardTagID, updatedProp: prevState }
				});
			});
	};
}

