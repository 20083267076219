import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import BtnIcon from 'Widgets/ButtonIcon.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';

import { formatStorage } from 'utils/';

const RECORDINGS = { chatDownloadUrl: null, chatRecordingOffsetSeconds: 0, transcriptDownloadUrl: null, vttDownloadUrl: null }

/** NOTE: Known issue with component
 * If parent component closes before import Promise completed, warning throw:
 * Warning: Can't perform a React state update on an unmounted component.
*/
class ModalZoomImportDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			importChat: true,
			loading: false,
			showDropdown: false,
		};
	}

	render() {
		const { showDropdown } = this.state;
		return (
			<div className="dropdown show contextMenu"
				onMouseLeave={() => this.handleDDLeave()}
			>
				<BtnIcon
					disabled={this.props.disabled}
					onClick={() => this.setState({ showDropdown: !showDropdown })}
					tooltip=""
					btnClass="btn btn-link"
					iconClass="far fa-cloud-download"
					style={{ paddingLeft: '10px', padding: '0px 10px 0px 10px', width: '100%'}}
				/>
				{showDropdown && this.renderedButtons()}
			</div>
		);
    }
    
	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
		let { loading} = this.state;
		let { files, start_time } = this.props;
		
		let importBtns = null;
		
		if (0 < files.length) {
			let recordingsCnt = 0, recordingStart = null, recordings = {};
			files = _.orderBy(files, 'recording_start', 'asc');
			
			importBtns = files.map((file, idx) => {
				// file_size in bytes
				const { download_url, file_extension, file_size, file_type, id, recording_start, recording_type, status } = file;
				if ('completed' !== status || !['CC', 'CHAT', 'M4A', 'MP4', 'TRANSCRIPT'].includes(file_type)) return

				// New set of recordingsCnt, re/set vars 
				if (recording_start !== recordingStart) {
					recordingStart = recording_start, recordingsCnt = recordingsCnt + 1;
					recordings[recording_start] = {...RECORDINGS}
				}

				/* Zoom Chat: If file_type is the zoom Chat - set ChatDownloadURl & chatRecordingOffsetSeconds */
				if ('TXT' === file_extension) {
					recordings[recording_start].chatDownloadUrl = download_url;
					recordings[recording_start].chatRecordingOffsetSeconds = ((new Date(recording_start).getTime() - new Date(start_time).getTime()) / 1000)
					return;
				}

				/* Closed Captions: If file_type is Closed Caption - set VvttDoanloadURl */
				if ('VTT' === file_extension) {
					recordings[recording_start].vttDownloadUrl = download_url;
					return;
				}

				/* Transcript: If file_type is Closed Caption - set VvttDoanloadURl */
				if ('audio_transcript' === recording_type) {
					recordings[recording_start].transcriptDownloadUrl = download_url;
					return;
				}
				
				const isFileLoading = loading[id] || false
				
				const iconClasses = classNames({
					'spinner fa-spin': isFileLoading,
					'video text-primary': !isFileLoading && 'MP4' === file_type, 
					'microphone-stand text-success': !isFileLoading && 'M4A' === file_type, 
				})

				const recordingName = `${_.upperFirst(recording_type.replace(/_/g, ' '))} - Recording ${recordingsCnt}`;


				return (
					<MenuBtn
						key={idx}
						disabled={isFileLoading}
						iconClass={iconClasses}
						label={`${recordingName} (${formatStorage(file_size, 'BYTE')})`}
						onClick={e => {
							this.onImportClick(e,
								{
									...file,
									...recordings[recording_start],
									topic: `${this.props.topic} - ${recordingName}`,
								})
						}}
					/>
				)
			})
		}

		// renders all the options in a dropdown menu
		return (
			<div className="dropdown-menu dropdown-menu-right">
				{importBtns}
			</div>
		);
    };

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};
	
	/** Triggers importing of file */
    onImportClick = (e, file) => {
		e.preventDefault();
		e.stopPropagation();
		const { disabled, importRecording } = this.props
		
		if (disabled || 'completed' !== file.status) return;
		
		this.setState({ loading: { [file.id]: true } });

		// importRecording( { ...file, importChat: this.state.importChat }, topic)
		importRecording(file)
			.then(data => {
					this.setState({ loading: { [file.id]: false } });
			})
			.catch(err => {
					this.setState({ loading: { [file.id]: false } });
					this.handleDDLeave();
            })
    };
}

function mapStateToProps(state) {
	return {};
}

export default connect(
	mapStateToProps
	// {}
	)(ModalZoomImportDropdown);
