import _ from 'lodash';
import {
	CUNAUTH_USER,
	ITEM_ANALYSIS_DB_LOADING,
    
    ITEM_ANALYSIS_DB_FETCH,
    ITEM_ANALYSIS_DB_ADD,
    ITEM_ANALYSIS_DB_REMOVE,
	
	UNMOUNT_ANALYSIS,
} from 'Constants/actions';

const INITIAL_STATE = {
    data: {},
	loading: true,
    unmount: true,          // prevents loading of tags when true
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
        case CUNAUTH_USER:
        case UNMOUNT_ANALYSIS:
            return INITIAL_STATE;
            
		case ITEM_ANALYSIS_DB_LOADING: {
			// payload is boolean true or false
			return {
                ...state, 
                loading: action.payload,
                unmount: false
            }};

		case ITEM_ANALYSIS_DB_FETCH: {
            if( state.unmount ) return INITIAL_STATE;
			return {
				...state,
				data: action.payload,
				loading: false,
                unmount: true
            }};
            
        case ITEM_ANALYSIS_DB_ADD: {
            // payload is {} containing dashboard to add
			return {
				...state,
				data: {
                    ...state.data,
                    [action.payload.dashboardID]: action.payload
                }
            }};
        
        case ITEM_ANALYSIS_DB_REMOVE: {
            // payload is key (dashboardID) of dashboard to remove
            return {
                ...state,
                data: _.omit(state.data, action.payload)
            }};
        }
    return state;
}
