import _ from 'lodash';

import { 
    _getSharedItemCookies,
    _dmAxiosErrorHandler, 
    request 
} from 'actions/index';

import { 
    apiConfig, 
} from 'config';

import {
	ITEM_ANALYSIS_ERROR,
	ITEM_UPDATE,
	LTI_ERROR,
} from 'Constants/actions';

/**
 * Attaches the selectedItemAnalysis media to the selectedItemAnalysis parent folder
 * @param {String} itemID
 * @param {Number} ltiAssignmentID 
 */
export function ltiAttachMedia(itemID, ltiAssignmentID) {
	return function(dispatch, getState) {
		const {
			ltiConsumerID,
			ltiCourseAssignments,
			ltiCourseID,
		} = getState().folders.data.folderItem;

		const {ltiAssignmentTitle} = ltiCourseAssignments[ltiAssignmentID];

        const requestObj = {
			method: 'POST',
			url: `${apiConfig.ltiAssignmentAttach}`,
			data: {
				itemAnalysisID: getState().folderItems.selectedItemAnalysis.itemAnalysisID,
				ltiAssignmentID,
				ltiAssignmentTitle,
				ltiConsumerID,
				ltiCourseID,
			}
		};
		
        return request( requestObj, ITEM_ANALYSIS_ERROR )
        .then(resp => {
			let updatedItem = _.assign(
				{},
				getState().folderItems.data[itemID],
			);

			updatedItem.itemAnalyses[0] = { 
				...resp
			}

			dispatch({
				type: ITEM_UPDATE,
				payload: updatedItem
			})
			return Promise.resolve();
		})
		.catch( err => {
			return Promise.reject();
		})
	};
}

/**
 * Detaches selectedItemAnalysis from assigment
 */
export function ltiDetachMedia(itemID) {
	return function(dispatch, getState) {

        const requestObj = {
			method: 'POST',
			url: `${apiConfig.ltiAssignmentDetach}`,
			data: {
				itemAnalysisID: getState().folderItems.selectedItemAnalysis.itemAnalysisID
			}
		};

        return request( requestObj, LTI_ERROR )
        .then(resp => {
			// Response contains updated itemAnalysis with LTI goodies removed, but ltiDetachDate updated
			let updatedItem = _.assign(
				{},
				getState().folderItems.data[itemID],
			);

			updatedItem.itemAnalyses[0] = { 
				...resp
			}

			dispatch({
				type: ITEM_UPDATE,
				payload: updatedItem
			})

			return Promise.resolve();
		})
		.catch( err => {
			return Promise.reject();
		})
	};
}