import _ from 'lodash';

import { LOB_KEYS } from 'Constants/app';
import {
	CUNAUTH_USER,
    LOB_LOADING,
    LOB_SET,
} from 'Constants/actions';

/**
 * STATE FORMAT: !! NOTE !!
 * 	[payload.itemAnalysisID]: {
		[payload.lobKey]: payload.lob,
		[payload.lobKey + 'Loading']: false | true (loading state) | 'LOAD' (should load)
 * }
 */
const INITIAL_STATE = {};

/**
 * @param {Object} state 
 * @param {Object} action 
 */
export default function(state = INITIAL_STATE, action) {
    const payload = action.payload;

	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
            return { ...INITIAL_STATE };

        // Sets loading for itemAnalysis & loadingKey (notesLoading, transcriptionLoading, etc)
        case LOB_LOADING: {
			// payload contains payload.itemAnalysisID, loadingKey and value. Optional value of true | false | 'LOAD' (see above)
			const { itemAnalysisID, lobKey, value } = payload
			
			// If a file lob, add additional state
			let additionalFileAssetsState = {}
			if( lobKey.startsWith(`${LOB_KEYS.FILE}_`) ) {

				additionalFileAssetsState.filesLoading = undefined === value ? true : value;
				const filesAvailable = 
					state[itemAnalysisID]
					? state[itemAnalysisID].filesAvailable || [] 
					: []
				if( !filesAvailable.includes(lobKey) )
					additionalFileAssetsState.filesAvailable = [...filesAvailable, lobKey]
			} 

            return {
                ...state,
                [itemAnalysisID]: {
					...state[itemAnalysisID],
					...additionalFileAssetsState,
                    [lobKey + 'Loading']: undefined===value ? true : value
                }
			}
		}

        // Used for seting and updating lobKey
        case LOB_SET: {
            // payload contains payload.itemAnalysisID, payload.lobKey, payload.lob
			return {
				...state,
                [payload.itemAnalysisID]: {
                    ...state[payload.itemAnalysisID],
                    [payload.lobKey]: payload.lob,
                    [payload.lobKey + 'Loading']: false
                }
			}
		}
            
		default:
			return state;
	}
}
