import _ from "lodash";

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {VictoryLine, VictoryChart, VictoryAxis, VictoryBrushContainer, VictoryTooltip, VictoryLabel, LineSegment, Line, createContainer} from 'victory';

import {
    playSnippet, 
    // setCurrentTime
} from '../actions/mediaplayer';

import RangeSlider from 'Widgets/RangeSlider.jsx'; // TODO: Remove (unused)

import DreamakerTheme from './DataVisualizer.Theme.jsx';
import DataVisualizerLegend from './DataVisualizer.Legend.jsx';
import DataVisualizerPlayhead from './DataVisualizer.Playhead.jsx';

// A VictoryChart can only have one container component, so Victory has
// this kind of funky way of defining a single container made of multiple
// container types. You'll see this VictoryZoomVoronoiContainer used as the
// value for the containerComponent prop on the main VictoryChart.
// "zoom" is for the zooming and panning, "voronoi" calculates nearest point
// to the cursor for data hover flyouts.
const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

class DataVisualizerGraph extends Component {
	
	constructor(props) {
		super(props);
		this.entireXDomain = [0, props.item.durationSeconds]; // The range of the full chart will be the length of the media item
		this.state = {
			selectedDomain: {x: this.entireXDomain}, // We really only need to set x to start.
			playheadDragPosition: 0,
			playheadDragging: false
		};
	}

	render() {
		const {loading, chart, colorScale, bottombarHeight, sidebarWidth} = this.props;
		const currentTime = this.props.playerState.currentTime;
		const {playheadDragging, playheadDragPosition} = this.state;
		
		// Chart height will be no less than 100, no more than 350, and will resize with bottombarHeight
		// NOTE: Because of the redux state-based updating, this makes resizing unperformant.
		// Currently, we hide the entire chart during resize, which isn't ideal.
		// FUTURE: Instead of using the global state bottomBarHeight and sidebarWidth, use
		// local component state copies that only update after resize is finished. The SVG will
		// scale decently during resize, then adjust appropriately after resize is complete.
		const chartHeight = Math.min(350, Math.max(100, (bottombarHeight - 310)));
		const chartWidth = window.innerWidth - sidebarWidth - 310;

		// Prep some chart properties
		// Currently assumes one chart and only leftDatasets (no rightDatasets)
		const hasDatasets = (chart && (chart.leftDatasets !== undefined) && Object.values(chart.leftDatasets).length); // 1. There is a chart. 2. It has a leftDatasets prop. 3. There are 1 or more datasets.
		const leftDatasets = (hasDatasets) ? Object.values(this.props.chart.leftDatasets) : [];
		const allYValues = (hasDatasets) ? _.flatten(leftDatasets.map(dataset => dataset.data)).map(coord => coord.y) : []; // Smash all the y values from all the datasets into one array
		const minY = (hasDatasets) ? Math.min(...allYValues, 0) : -4; // Find the smallest y value or 0 if they're all >0. Or, if no data, set a nice fake min of -4.
		const maxY = (hasDatasets) ? Math.max(...allYValues, 0) : 10; // Find the largest y value or 0 if they're all <0. Or, if no data, set a nice fake max of 10.

		// Prep playhead positioning based on dragging or not
		const playheadX = playheadDragging ? playheadDragPosition : currentTime;

		// TODO: Consolidate and clean up theme (including moving this there)
		const yAxisStyles = {
			axis: {
				stroke: "transparent"
			},
			axisLabel: {
				padding: 44,
				fill: "#6f7890",
				fontSize: 16
			}
		};
		const playheadStyles = {
			data: {
				stroke: "#E64759",
				strokeWidth: 3,
				cursor: "ew-resize",
			}
		};
		
		return (
			<div id={"data-visualizer-charts"}>
				{/* The main chart */}
				<VictoryChart
					height={chartHeight}
					width={chartWidth}
					padding={{top: 20, right: 30, bottom: 10, left: 30}}
					theme={DreamakerTheme}
					domain={{
						x: this.entireXDomain,
						y: [minY, maxY]
					}}
					events={[
						{
							target: "parent",
							eventHandlers: {
								onMouseUp: this.handlePlayheadStopDrag.bind(this),  //plays dragged-to playhead position
								onMouseMove: this.handlePlayheadDrag.bind(this),    //plays dragged-to playhead position
                                onDoubleClick: this.handleDoubleClick.bind(this),   //plays dbl-clicked-on playhead position
							}
						}
					]}
					containerComponent={
						// This container handles zooming/pannning, and also the "Voronoi" calculation to find the nearest point to the cursor for data hover flyouts
						<VictoryZoomVoronoiContainer
							className="VictoryContainer main-chart"
							zoomDimension="x"
							zoomDomain={this.state.selectedDomain}
							onZoomDomainChange={this.handleZoom.bind(this)}
							//onMouseUp={this.handlePlayheadStopDrag.bind(this)} // We attach the mouseup event on the entire chart container, in case the mouse moves faster than the playhead
							radius={25}
							voronoiBlacklist={["main-playhead"]} // This prevents the Voronoi nearest-point calculation from including the playhead
						/>
					}
				>
					{/* The main chart's x axis, with tick labels for seconds */}
					<VictoryAxis
						tickFormat={
							(x) => x + 's'
						}
					/>
					{/* The main chart's left y axis */}
					<VictoryAxis
						dependentAxis
						crossAxis={false}
						style={yAxisStyles}
						label={chart.leftYAxisLabel}
					/>
					{/* The main chart's right y axis */}
					<VictoryAxis
						dependentAxis
						crossAxis={false}
						orientation="right"
						style={yAxisStyles}
					/>
					{/* This axis draws the subtle horizontal grid lines */}
					<VictoryAxis
						dependentAxis
						style={{
							grid: {
								stroke: "rgba(0,0,0,0.25)",
								strokeWidth: 1
							}
						}}
					/>

					{/* We create a VictoryLine for each dataset */}
					{leftDatasets.map( (dataset, index) => (
						// A line for a dataset in the main chart
						<VictoryLine
							key={"dataset" + index}
							data={this.prepData(dataset.data)} // Prep the data so we only use points we need
							labels={(d) => d.y}
							labelComponent={
								<VictoryTooltip
									// Changes orientation based on vertical location of the point.
									// If the point is more than 2/3 between min and max, then it will
									// display below the point, otherwise it displays above.
									orientation={(d) => d.y > ( minY + (maxY-minY)/1.5 ) ? "bottom" : "top"}
									dy={6}
									cornerRadius={4}
									flyoutStyle={{
										fill: "black",
										stroke: colorScale[ index % colorScale.length ],
										strokeWidth: 6,
										strokeOpacity: 0.1
									}}
									style={{
										fill: colorScale[ index % colorScale.length ]
									}}
								/>
							}
							style={{
								data: {
									stroke: colorScale[ index % colorScale.length ],
									strokeWidth: (d, active) => active ? 3 : 2,
									opacity: (d, active) => active ? 1.0 : 0.9,
									transitionProperty: "stroke-width, opacity",
									transitionDuration: ".5s",
									transitionTimingFunction: "ease"
								},
							}}
						/>
					))}

					{/* Playhead on main chart */}
					<VictoryLine
						name={"main-playhead"}
						data={[
							{x: playheadX, y: maxY},
							{x: playheadX, y: minY}
						]}
						style={playheadStyles}
						events={[
							{
								target: "parent",
								eventHandlers: {
									onMouseDown: this.handlePlayheadStartDrag.bind(this),
									onMouseUp: this.handlePlayheadStopDrag.bind(this)
								}
							}
						]}
					/>

				</VictoryChart>

				{/* Brush scrubber mini-chart */}
				<VictoryChart
					height={70}
					width={chartWidth}
					padding={{top: 0, right: 30, bottom: 30, left: 30}}
					domain={{
						x: this.entireXDomain,
						y: [minY, maxY]
					}}
					theme={DreamakerTheme}
					containerComponent={
						// Container that handles the brush functionality and display
						<VictoryBrushContainer
							className="VictoryContainer brush-scrubber"
							brushDimension="x"
							brushDomain={this.state.selectedDomain}
							onBrushDomainChange={this.handleBrush.bind(this)}
							brushStyle={{
								fill: "rgba(25,151,198,0.25)",
								cursor: "grab"
							}}
							handleStyle={{
								fill: "#1997c6",
							}}
						/>
					}
				>
					{/* The mini-chart's x axis */}
					<VictoryAxis
						crossAxis={false}
						orientation="bottom"
						tickFormat={
							(x) => x + 's'
						}
					/>
					
					{/* We draw a VictoryLine in the mini-chart for each dataset */}
					{leftDatasets.map( (dataset, index) => (
						// A line for the dataset in the minichart
						<VictoryLine
							key={"dataset" + index}
							data={this.prepData(dataset.data, this.entireXDomain, 80)} // Prep the data by reducing points, but keep the entire x range. We also show fewer points in the mini-chart than the main chart.
							style={{
								data: {
									stroke: colorScale[ index % colorScale.length ],
									opacity: 0.5
								}
							}}
						/>
					))}

					{/* Playhead on mini-chart timeline */}
					<VictoryLine
						name={"mini-playhead"}
						data={[
							{x: playheadX, y: maxY},
							{x: playheadX, y: minY}
						]}
						style={playheadStyles}
					/>

				</VictoryChart>
				<h4>Time (seconds)</h4>
				<DataVisualizerLegend
					colorScale={colorScale}
					leftDatasets={leftDatasets}
				/>
			</div>
		);
    }
    
	handleZoom = (domain) => {
		this.setState({selectedDomain: domain});
	}
	
	handleBrush = (domain) => {
		this.setState({selectedDomain: domain});
	}

	handlePlayheadStartDrag = e => {
		this.setState({playheadDragging: true});
		this.setState({playheadDragPosition: this.getPlayheadPositionFromMousePosition(e.clientX)});
	}

	handlePlayheadDrag = e => {
		if (!this.state.playheadDragging) {
			// If this isn't a playhead drag, do nothing
			return;
		}
		this.setState({playheadDragPosition: this.getPlayheadPositionFromMousePosition(e.clientX)});
	}

	handlePlayheadStopDrag = e => {
		if (!this.state.playheadDragging) {
			// If this isn't the end of a playhead drag, do nothing.
			return;
		}
        
        const {mediaDurationSeconds} = this.props
        
        // this.props.playSnippet(this.getPlayheadPositionFromMousePosition(e.clientX), this.entireXDomain[1]);

        this.props.playSnippet(
            mediaDurationSeconds,
            this.getPlayheadPositionFromMousePosition(e.clientX),
            0, 0    // no lead or lag time - we want it exact
        );
        

		// We add a brief delay before updating the playheadDragging state, because
		// updating it immediately causes it to jump back to its previous position
		// briefly before the Redux action updates currentTime
		_.delay( () => {
			this.setState({playheadDragging: false});
		}, 1); // Delay by 1ms (due to rendering, it ends up taking longer for the UI to update)
    }
    
    // Plays video at that play head position
    handleDoubleClick = e => {		
        const {mediaDurationSeconds} = this.props
        // this.props.playSnippet(this.getPlayheadPositionFromMousePosition(e.clientX), this.entireXDomain[1]);
        
        this.props.playSnippet(
            mediaDurationSeconds,
            this.getPlayheadPositionFromMousePosition(e.clientX),
            0, 0    // no lead or lag time - we want it exact
        );
    }

	/**
	 * Function that takes a pixel position in the window (i.e of the mouse)
	 * and returns the related time position of the chart.
	 * 
	 * @param {Number} px The left pixel count from the edge of the portal to the mouse pointer
	 * 
	 * @return {Number} Returns the number, in seconds, correlating to the mouse position.
	 */
	getPlayheadPositionFromMousePosition(px) {
		const {selectedDomain} = this.state;
		const chart = document.querySelector('.main-chart > svg > :first-child'); // The first child of the svg is the x axis line
		const chartWidth = chart.getBoundingClientRect().width;
		const mouseLeftPxInChart = px - chart.getBoundingClientRect().left; // Find the left pixel position in the chart
		const domainWidth = selectedDomain.x[1] - selectedDomain.x[0]; // Capturing the domain width (# of seconds displayed on the current zoom level of the chart)

		const playheadPosition = (mouseLeftPxInChart * domainWidth) / (chartWidth) + selectedDomain.x[0];

		return playheadPosition;
    }
    
	/**
	 * Prep data for use by removing points and returning the reduced set.
	 * This function has no side effects, and returns a reduced data array in the same format as passed.
	 * 
	 * @param {Array} data The array of points to prep. Each point is an object with an x and a y property. Format: [{x:val, y:val}]
	 * @param {Array} xDomain The x range to target (i.e. the zoom range of the chart). Format: [minX, maxX]
	 * @param {Number} maxPoints The target number of points you'd like in the data. No more than this number of points will be returned.
	 * 
	 * @return {Array} Returns an array of the same format as the data param. [{x:val ,y:val}]
	 */
	prepData = (data, xDomain=this.state.selectedDomain.x, maxPoints=100) => {

		// Stop if it's already a small dataset
		if (data.length < maxPoints) {
			return data;
		}

		// Find the closest x values just outside of range, so we can trim
		// while retaining lines that extend beyond the range. This lets
		// lines that extend beyond the range still display.

		const xValues = data.map(coord => coord.x);

		const xLeftOfRange = xValues.reduce( (prev, curr) => { // NOTE: array.prototype.reduce() begins with prev=array[0] and curr=array[1]
			const currDiff = curr - xDomain[0];       // Find the distance of the CURRENT point from the left (min) of the range
			const prevDiff = Math.abs(prev - xDomain[0]); // Find the distance of the PREVIOUS CLOSEST point from the left of the range
			return (currDiff < prevDiff) && (currDiff < 0) ? curr : prev; // Keep whichever is CLOSER and also LEFT of the range (i.e. the difference is negative)
		});
		const xRightOfRange = xValues.reduce( (curr, prev) => { // NOTE: array.prototype.reduce() begins with prev=array[0] and curr=array[1]
			const currDiff = curr - xDomain[1];       // Find the distance of the CURRENT point from the right (max) of the range
			const prevDiff = Math.abs(prev - xDomain[1]); // Find the distance of the PREVIOUS CLOSEST point from the right of the range
			return (currDiff < prevDiff) && (currDiff > 0) ? curr : prev; // Keep whichever is CLOSER and also RIGHT of the range (i.e. the difference is positive)
		});

		// Strip points outside of the range
		const filtered = data.filter(
			(d) => {
				return (d.x >= xLeftOfRange) && (d.x <= xRightOfRange);
			}
		);

		// Reduce number of visible points to maxPoints by only keeping
		// multiples of the filtered list length divided by maxPoints,
		// while also retaining the first and last point (the points just
		// outside of the domain).
		if (filtered.length > maxPoints) {
			const k = Math.ceil(filtered.length / maxPoints);
			return filtered.filter(
				(d, i, a) => {
					return (
						((i % k) === 0) ||     // Is the point a multiple of k?
						(i === 0) ||           // Is it the first point?
						(i === (a.length-1))   // Is it the last point?
					);
				}
			);
		}
		
		return filtered;
	}
}

function mapStateToProps(state) {
	return {
		bottombarHeight: state.app.bottombarHeight,
        item: state.folderItems.selectedItem,
        itemAnalysis: state.folderItems.selectedItemAnalysis,
        mediaDurationSeconds: state.folderItems.selectedItem.durationSeconds,
		playerState: state.playerState,
		sidebarWidth: state.app.sidebarWidth
	};
}

const mapDispatchToProps = {
	playSnippet,
	// setCurrentTime
};

export default connect(mapStateToProps, mapDispatchToProps)(DataVisualizerGraph);