/**
 * APP ROUTES
 */
export const ROUTES = {
	// ROOT
	INDEX: '/',         // folders view
	SIGNOUT: '/',
	SIGNIN: '/',
	APP: '/',

	// ROOT PAGES
	RESET: '/reset',
	PAYMENT: '/payment',
	PRICING: '/pricing',
	SIGNUP: '/su',
	UPGRADE: '/upgrade',
	VERIFY: '/v',

	// ACCOUNT PAGES
	ACCOUNT: '/c',
	BILLING: '/c/billing',
	INTEGRATIONS: '/c/integrations',
    MEMBERS: '/c/members',
    PROFILE: '/c/profile',
	PREFERENCES: '/c/preferences',
	REPORTS: '/c/reports',

	// ANALYSIS
    ANALYSIS_RT: '/a/:itemAnalysisID',
	ANALYSIS: '/a',

	// DASHBOARDS + MARKERS
	DASHBOARDEDITPAGE: '/d',
	MARKERSEDITPAGE: '/m',

	// ZOOM LIST
	ZOOM_RT: '/z/:oauthUserID',
	ZOOM: '/z',

	// LANDING
	LANDING_RT: '/l/:folderID',
	LANDING: '/l',
};

export const APP_OPTIONS = {
	LEAD_TIME: 2,   // for tagged event, default lead time to save
    LAG_TIME: 2,    // for tagged event, default lead time to save
};

/**
 * ADDING MORE COLORS:

 * variables.scss: as a $brand-[color]
 * _buttons.scss: as .btn-[color] and .btn-outline-[color]
 * _variables.scss: as $btn-[color]-color, $btn-[color]-bg, and $btn-[color]-border:
 * _card.scss: as .card-[color] and .card-outline-[color]
 * _commentblock.scss: as &-time-[color]:active, &-time-[color]:hover, &.card-outline-[color] &-icon, and &.card-outline-[color] &-places
 */
export const COLORS = {
	'Bot blue': 'primary',
	'Cheeky red': 'danger',
	'Just green': 'success',
	Limoncello: 'warning',
	'Luvly Lime': 'lime',
	'Not quite white': 'secondary',
	'Purple turtle': 'info',
	Tangerine: 'tangerine',
	'Yum Plum': 'plum',
};

// used for react-drag-n-drop.
export const dndItemTypes = {
	ITEM: 'ProjectThumb'
};

/**
 * PlanID's used in app...
 * planID: true --> when planID in DynamoDB is `active`
 * LIVE: planID --> `live` planID in DynamoDB
 */
export const PLANID = {
    FREE: {
        'BASIC-2017-01-13': true,               
        'BASIC-2017-09-07': true,
        'BASIC-2019-01-15': true,             
        LIVE: 'BASIC-2019-01-15'
    },
    TRIAL: 'TRIAL-PROFESSIONAL-2018-09-21'
}

import moodleLogo from 'assets/images/3rdparty/moodle-logo.png';
import zoomLogo from 'assets/images/3rdparty/zoom-icon-circle.png'
export const IMAGES = {
	ZOOM_LOGO: zoomLogo,
	MOODLE_LOGO: moodleLogo
}

/**
 * ERRORS specific to invites.
 * See https://dreamakerio.atlassian.net/browse/DMIO-362
 **/
export const INVITE_ERRORS = {
	EXCEEDED: 'EXCEEDED',			// invite type limit exceeded
	NOTIFICATION: 'NOTIFICATION',	// failure notifying client (invite succeeded)
	OTHER: 'OTHER'					// other error
}

/** Different kinds of invites */
export const INVITE_TYPES = {
    COLLABORATE: 1,
	SHARE: 2,
	SEND: 3
}

/** Different kinds of invite statuses */
export const INVITE_STATUS = {
    /** Official Lambda/Dynamo */
    ACCEPTED: 'ACCEPTED',   
    DECLINED: 'DECLINED',
    INVITED: 'INVITED',
    LEFT: 'LEFT',
    REVOKED: 'REVOKED',

    /** APP SPECIFIC */
    LOADING: 'LOADING',
    ERROR_INVITING: 'ERROR INVITING',			// used in Member invitations
    ERROR_DEACTIVATING: 'ERROR DEACTIVATING'	// used in Member invitations
}

// const LANGUAUGES = {
//     // AR:     { code: 'ar', name: 'Arabic' },
//     // CS:     { code: 'cs', name: 'Czech' },
//     // DE:     { code: 'de', name: 'German' },
//     // EN:     { code: 'en', name: 'English'},
//     EN_AU:  { code: 'en-AU', name: 'English Australian' },
//     EN_UK:  { code: 'en-UK', name: 'English British' },
//     EN_US:  { code: 'en-US', name: 'English US' },
//     // ES:     { code: 'es', name: 'Spanish' },
//     ES_US:  { code: 'es-US', name: 'Spanish US' },
//     // FR:     { code: 'fr', name: 'French' },
//     FR_CA:  { code: 'fr-CA', name: 'French Canadian' },
//     // IT:     { code: 'it', name: 'Italian' },
//     // JA:     { code: 'ja', name: 'Japanese' },
//     // PT:     { code: 'pt', name: 'Portuguese' },
//     // RU:     { code: 'ru', name: 'Russian' },
//     // TR:     { code: 'tr', name: 'Turkish' },
//     // ZH:     { code: 'zh', name: 'Chinese (Simplified)' },
//     // ZH_TW:  { code: 'za-TW', name: 'Chinese (Traditional)' }
// }


/** 
 * Used for <select /> options of transcription. 
 * NOTE: !! NB !! Order alphabetically by value !!
 */
export const TRANSCRIBE_LANGUAGES = {
	'pt-BR': 'Brazilian Portuguese',
	'fr-CA': 'Canadian French',
	'zh-CN': 'Chinese Mandarin - Mainland',
	'nl-NL': 'Dutch',
	'en-AU': 'English Australian',
	'en-GB': 'English British',
	'en-IN': 'English Indian',
	'en-IE': 'English Irish',
	'en-AB': 'English Scottish',
	'en-US': 'English US',
	'en-WL': 'English Welsh',
	'fa-IR': 'Farsi',
	'fr-FR': 'French',
	'de-DE': 'German',
	'ar-AE': 'Gulf Arabic',
	'he-IL': 'Hebrew',
	'hi-IN': 'Indian Hindi',
	'id-ID': 'Indonesian',
	'it-IT': 'Italian',
	'ja-JP': 'Japanese',
	'ko-KR': 'Korean',
	'ms-MY': 'Malay',
	'ar-SA': 'Modern Standard Arabic',
	'pt-PT': 'Portuguese',
	'ru-RU': 'Russian',
	'es-ES': 'Spanish',
	'es-US': 'Spanish US',
	'de-CH': 'Swiss German',
	'ta-IN': 'Tamil',
	'te-IN': 'Telugu',
	'tr-TR': 'Turkish',
}

/** 
 * Lannguages supported for translations
 * https:// docs.aws.amazon.com/translate/latest/dg/API_TranslateText.html
 */
// export const TRANSLATE_LANGUAGES = [
//     LANGUAUGES.AR,
//     LANGUAUGES.CS,
//     LANGUAUGES.DE,
//     LANGUAUGES.EN,
//     LANGUAUGES.FR,
//     LANGUAUGES.IT,
//     LANGUAUGES.JA,
//     LANGUAUGES.PT,
//     LANGUAUGES.RU,
//     LANGUAUGES.ES,
//     LANGUAUGES.TR,
//     LANGUAUGES.ZH_TW,
//     LANGUAUGES.ZH,
// ]

export const PROGRESS_FLAGS = {
	COMPLETED: 'COMPLETED',         // transcode, transcribe, file upload - successful completion
	CALCULATING: 'CALCULATING',		// transcode, file upload - upload initiated but not yet started
	ERROR: 'ERROR',                 // transcode, transcribe, file upload - unsuccessful completion
	INPROGRESS: 'IN_PROGRESS',      // transcribe in progress
	PROGRESSING: 'PROGRESSING',     // transcode, file upload - transcode progressing; file uploaded - copy & transcode progressing
	WARNING: 'WARNING',              // transcode - successful completion with warning
	UPLOADSTARTED: 'UPLOADSTARTED', // transcode, file upload - upload has started
 }
 

/** 
 * LOB constants used in temAnlaysis.GET and item.GET
 * 
 * LOBS Formats:
 * =============
 * LOBS.CHART:  JSON. 
 * LOBS.FILE: todo: ???
 * LOBS.NOTES:  JSON. See Deltas for quill.js
 * LOBS.TRANSCRIPTION: JSON
 * LOBS.TRANSCRIPTION_AWS: JSON
 * LOBS.TRANSLATION: JSON
 */
export const LOB_KEYS = {
	CHART:  'chartObject',                  // followed by a single digit for version
	FILE:	'file',
    NOTES:  'notes',                        // for project notes
	TRANSCRIPTION: 'transcription_',        // followed by TRANSCRIBE_LANGUAGES[lang].code
	VTT: 'subtitleVTT_'						// followed by TRANSCRIBE_LANGUAGES[lang].code
    // TRANSCRIPTION_AWS: 'transcription_aws', // orginal transcrption
    // TRANSLATION: 'translation_',         // followed by TRANSLATE_LANGUAGES[x].code
}

export const MONTHS_3AB = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

export const MONTH = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

/**
 * NOTE: Placing the regex expresison between // creates a Regex, so no need to use Regex(//)
 * NOTE: RegExp Creation
 * RegExp Test: https://www.regextester.com/15 or https://regex101.com/
 * Email RegExp:
 *      https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 *      http://emailregex.com/
 */
export const REGEXP = {
    EMAIL_VALID: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    // EMAIL_VALID: RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", 'i'),  // Old - validated email with no domain (example@nownow) 
	
	// Allowed uploads: FileAssets & Images
	FILEASSET_WHITELIST:/csv|doc|docx|epub|key|mp3|m4a|numbers|ods|pages|pdf|ppt|rtf|tsv|txt|xls|xlsx|xps/,	// extensions accesptable as file assets
	IMAGE_WHITELIST: 	/bmp|gif|jpg|jpeg|png|svg/,		// extensions accesptable as file assets & images

	// Allowed uploads: Video & Audio
    MEDIA_BLACKLIST: /m3u/,  // extensions which are interepretted as audio/video but are not transcodable
	MEDIA_WHITELIST: /mod|mts|ogv|mxf/,  // extensions which are not interepretted as audio/video but are transcodable
	
	// WHITE_SPACE: RegExp("\s+", 'g')
	
    PASSWORD: /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/
}

export const ROLES = {
	AUTOTEACHER: 'AUTO-TEACHER',	// Collaborators: Specific to LTI interations with Moodle - collaborators who are teachers take on this role
}

export const SHARE_PERMISSIONS = {
	INVITE_COLLABORATOR: 'INVITE-COLLABORATOR'
};

export const VISIBILITY = {
	COLLABORATORS: 'COLLABORATORS',
	PRIVATE: 'PRIVATE',
}

export const UNLIMITED = {
	value: 999999,
	word: 'Unlimited',
	// htmlCode: '\u221E' // U+0221E //'&infin;' 
	// getHtml: () => { return <span style={{content: '\u221E'}} /> } 
}

// export const THUMBS = {
// 	FOLDER: folderImg,
// 	AUDIO: audioImg
// };

/** 
 * Lannguages supported for transcription
 */