import {BILLING_GET, CUNAUTH_USER} from 'Constants/actions.js';

/**
 * billingDetails State:
 * Note: billingDetails = null if not yet initialized - IE, loaded via the API
 * Note: billingDetails = {} if is no card details on stripe or no associated stripe customer
 */
const INITIAL_STATE = null;

// we will be using these for toasts, so if the case of PAYMENT_MESSAGE or PAYMENT_ERROR handle any other state
// besides their respective state, it will cause issues.
export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		case CUNAUTH_USER:
			return INITIAL_STATE;
        
        case BILLING_GET:
			return {
				...action.payload
			};

		default:
			return state;
	}
}
