/**
 * NOTE: Naming Conventions
 * Use 'GET' for returning a single item
 * Use 'FETCH' for returning an object of multiple items
 */

// Applicaiton Constants
export const _ONLYUS = '_ONLYUS'
export const _SET_VAR = '_SET_VAR'
export const _UNSET_VAR = '_UNSET_VAR'
export const APP_ERROR = 'APP_ERROR'        // APP API CALL ERRORS - for fetchPlans and fetchTemplates
export const PLANS_FETCH = 'PLANS_FETCH' // Used to get all of the plan information from the databse
export const SET_QUEUE_SIZE = 'SET_QUEUE_SIZE'
export const SET_BOTTOMBAR_HEIGHT = 'SET_BOTTOMBAR_HEIGHT'
export const SET_SHOW = 'SET_SHOW'
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH'
export const TEMPLATES_FETCH = 'TEMPLATES_FETCH'

// ACCOUNT
export const ACCOUNT_GET = 'ACCOUNT_GET'
export const ACCOUNT_REHYDRATE = 'ACCOUNT_REHYDRATE' // OLD - REMOVE - DOES SAME AS ACCOUNT_GET
export const ACCOUNT_REHYDRATE_COLLABS = 'ACCOUNT_REHYDRATE_COLLABS'
export const ACCOUNT_REHYDRATE_SHARES = 'ACCOUNT_REHYDRATE_SHARES'
export const ACCOUNT_REHYDRATE_USAGEDATA = 'ACCOUNT_REHYDRATE_USAGEDATA'
export const ACCOUNT_REHYDRATE_USAGEDATA_MTD = 'ACCOUNT_REHYDRATE_USAGEDATA_MTD'

// ORGANISATION / MEMBERS
// export const MEMBER_ACCEPT = 'MEMBER_ACCEPT' 
export const MEMBER_LEAVE =  'MEMBER_LEAVE'                          // NOTE: MEMBERS_LOADING indicated by lack of state.members.data
export const MEMBERS_ERROR =  'MEMBERS_ERROR' 
export const MEMBERS_FETCH =  'MEMBERS_FETCH'
export const MEMBERS_UPDATE = 'MEMBERS_UPDATE'      // updates state.members for invites & revokes

// BILLING
export const BILLING_GET = 'BILLING_GET';

// USER & COGNITO AUTHENTICATION
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING'	// used to set the account reducer to loading or nah
export const CVERIFY_USER   = 'CAUTH_VERIFY'      	// cognito user created but needing email verification - verify: true
export const CVERIFY_PASSWORD_RESET = 'CVERIFY_PASSWORD_RESET'  // Verification code is sent for user to update password - verify: true; not: false
export const CAUTH_USER     = 'CAUTH_USER'          // verified cognito authed for access, ie signed in
export const CUNAUTH_USER   = 'CUNAUTH_USER'      	// verified cognito unauthed for access, ie signout
export const CAUTH_ERROR    = 'CAUTH_ERROR'        	// cognito error 
export const CAUTH_MESSAGE  = 'CAUTH_MESSAGE'    	// cognito message
export const CUSER_CLEAR_MESSAGES = 'CUSER_CLEAR_MESSAGES'
export const CUSER_UPDATE 	= 'CUSER_UPDATE'		// updates user in application state
export const CLOADING = 'CLOADING'              	// set to true when cognito request is made, set to false when response received
export const OAUTH_REHYDRATE = 'OAUTH_REHYDRATE'	// rehydrates the use.oauth{} object
export const OAUTH_REVOKE = 'OAUTH_REVOKE'			// triggers revoking of oauth authorization
// export const CGET_USER      = 'CGET_USER'            // Gets the user associated with a current dmioToken
// export const CUSER_CLEAR    = 'CUSER_CLEAR'        // Alias for CUNAUTH_USER

// COLLABORATOR ACTIONS
export const COLLABORATOR_LOADING   = 'COLLABORATOR_LOADING'
export const COLLABORATOR_ERROR     = 'COLLABORATOR_ERROR'  //NB Only user for error handling NOT an action
// export const COLLABORATOR_MESSAGE   = 'COLLABORATOR_MESSAGE'


// DM USER ACTIONS
export const DMUSER_LOADING         = 'DMUSER_LOADING'
export const DMUSER_ERROR           = 'DMUSER_ERROR'
export const DMUSER_COOKIES_ERROR   = 'DMUSER_COOKIES_ERROR'    // Has not associated action yet; used for err capturing
export const DMUSER_FETCH           = 'DMUSER_FETCH'
export const DMUSER_UPDATE			= 'DMUSER_UPDATE'


// FOLDER ACTIONS
export const FLOADING           = 'FLOADING'              // true when folders being fetched
export const FOLDERS_ERROR      = 'FOLDERS_ERROR'
export const FOLDERS_FETCH      = 'FOLDERS_FETCH'

// ITEM ACTIONS
export const ITEM_LOADING           = 'ITEM_LOADING'              // true when folders being fetched
export const ITEM_ERROR         = 'ITEM_ERROR'
export const ITEM_COOKIES_ERROR = 'ITEM_COOKIES_ERROR'  // Has not associated action yet; used for err capturing
export const ITEMS_FETCH        = 'ITEMS_FETCH'
export const ITEM_ADD           = 'ITEM_ADD'
export const ITEM_DELETE        = 'ITEM_DELETE'
export const ITEM_UPDATE        = 'ITEM_UPDATE'
export const ITEM_SELECT        = 'ITEM_SELECT'         //sets selectedItem (does NOT change loading); used for setting item from API and from user click
export const ITEM_SHARE_ADD     = 'ITEM_SHARE_ADD'
export const ITEM_SHARE_LEAVE   = 'ITEM_SHARE_LEAVE'
export const ITEM_MOVE_FOLDER   = 'ITEM_MOVE_FOLDER' // moves item to new folder
export const ITEM_MOVE_FOLDER_UNDO = 'ITEM_MOVE_FOLDER_UNDO' // moves item back into folder if there was an error

export const ITEM_VTT_SET = 'ITEM_VTT_SET' // used to initialize the folderItems.selectedVTTs object

// ITEM UPLOAD
export const UPLOAD_ERROR 		= 'UPLOAD_ERROR'
// export const EVAPORATE			= 'EVAPORATE'
// new
export const UPLOAD_INITIALIZING= 'UPLOAD_INITIALIZING'
export const UPLOAD_NAME_CHANGE = 'UPLOAD_NAME_CHANGE'
export const UPLOAD_PROGRESS	= 'UPLOAD_PROGRESS'

// ITEM ANALYSIS ACTIONS
export const ITEM_ANALYSIS_ERROR        = 'ITEM_ANALYSIS_ERROR'

export const ITEM_ANALYSIS_DB_ADD       = 'ITEM_ANALYSIS_DB_ADD'
export const ITEM_ANALYSIS_DB_FETCH     = 'ITEM_ANALYSIS_DB_FETCH'          // fetch an itemAnalysis' dashboards
export const ITEM_ANALYSIS_DB_LOADING   = 'ITEM_ANALYSIS_DB_LOADING'     //item analysis dashboards loading
export const ITEM_ANALYSIS_DB_REMOVE    = 'ITEM_ANALYSIS_DB_REMOVE'

export const ITEM_ANALYSIS_DB_TAGS_FETCH= 'ITEM_ANALYSIS_DB_TAGS_FETCH'     // fetch an itemAnalysis' dashboard tags
export const ITEM_ANALYSIS_DB_TAGS_LOADING  = 'ITEM_ANALYSIS_DB_TAGS_LOADING'

export const ITEM_ANALYSIS_LOADING      = 'ITEM_ANALYSIS_LOADING'       //item analysis loading
export const ITEM_ANALYSIS_UPDATE       = 'ITEM_ANALYSIS_UPDATE'            // updating an itemAnalysis
export const ITEM_ANALYSIS_SELECT       = 'ITEM_ANALYSIS_SELECT'            // currently selected an itemAnalysis

export const ITEM_ANALYSIS_MARKERS_FETCH   = 'ITEM_ANALYSIS_MARKERS_FETCH'
export const ITEM_ANALYSIS_MARKERS_LOADING = 'ITEM_ANALYSIS_MARKERS_LOADING'              // true when markers being fetched

// LTI
export const LTI_ERROR 		= 'LTI_ERROR'

// LOBs
export const LOB_DELETE 		= 'LOB_DELETE'
export const LOB_ERROR 			= 'LOB_ERROR'       // Error on any lob
export const LOB_FILEASSET_ERROR= 'LOB_FILEASSET_ERROR'
export const LOB_LOADING		= 'LOB_LOADING'     // Sets loading true on supplied lobKey
export const LOB_SET    		= 'LOB_SET'         // Sets state lob supplied itemAnalysisID & lobKey
export const TRANSCRIBE_LOADING = 'TRANSCRIBE_LOADING'  // true on creating or reseting any transcriptions or translations

export const TRANSCRIBE_GET     = 'TRANSCRIBE_GET'

export const ITEM_ANALYSIS_NOTES_SET    = 'ITEM_ANALYSIS_NOTES_SET'         
export const ITEM_ANALYSIS_NOTES_DELETE = 'ITEM_ANALYSIS_NOTES_DELETE'

export const ITEM_ANALYSIS_CHARTS_SET   = 'ITEM_ANALYSIS_CHARTS_SET'
export const ITEM_ANALYSIS_CHARTS_DELETE= 'ITEM_ANALYSIS_CHARTS_DELETE'

// DATA VISUALIZER CHART ACTIONS
export const CHART_LOADING        = 'CHART_LOADING'
export const CHART_GET          = 'CHART_GET'
export const CHART_CREATE         = 'CHART_CREATE'
export const CHART_UPDATE         = 'CHART_UPDATE'
export const CHART_DATASET_CREATE = 'CHART_DATASET_CREATE'
export const CHART_ERROR          = 'CHART_ERROR'
export const CHART_DELETE         = 'CHART_DELETE'
export const CHART_CLEAR_STATE    = 'CHART_CLEAR_STATE'

// USER DASHBAORD ACTIONS
export const DASHBOARD_CREATE   = 'DASHBOARD_CREATE'            // used for creating a brand new dashboard
export const DASHBOARD_UPDATE   = 'DASHBOARD_UPDATE'
export const DASHBOARD_DELETE   = 'DASHBOARD_DELETE'
export const DASHBOARD_REMOVE_TAG = 'DASHBOARD_REMOVE_TAG'      //delete

export const DASHBOARD_TAG_CREATE = 'DASHBOARD_TAG_CREATE'      // update a single dashboard tag button
export const DASHBOARD_TAG_UPDATE = 'DASHBOARD_TAG_UPDATE'      // update a single dashboard tag button
export const DASHBOARD_TAG_DELETE = 'DASHBOARD_TAG_DELETE'      // deletes a single dashboard tag

export const DASHBOARD_TAGS_FETCH = 'DASHBOARD_TAGS_FETCH'
export const DASHBOARD_TAGS_LOADING = 'DASHBOARD_TAGS_LOADING'                // true when user dashboard tags loading 

export const DASHBOARDS_ERROR       = 'DASHBOARDS_ERROR'
export const DASHBOARDS_FETCH  = 'DASHBOARDS_FETCH' // refactoring the above dashboards fetch
export const DASHBOARDS_LOADING           = 'DASHBOARDS_LOADING'                    // true when user dashboards are loading
export const DASHBOARDS_MESSAGE     = 'DASHBOARDS_MESSAGE'

// EVENT TAGS ACTIONS
export const EVENT_TAG_LOADING     = 'EVENT_TAG_LOADING'
export const EVENT_TAG_ERROR       = 'EVENT_TAG_ERROR'
export const EVENT_TAG_ADD         = 'EVENT_TAG_ADD'
export const EVENT_TAG_UPDATE      = 'EVENT_TAG_UPDATE'
export const EVENT_TAG_DELETE      = 'EVENT_TAG_DELETE'

export const EVENT_TAGS_ERROR      = 'EVENT_TAGS_ERROR'
export const EVENT_TAGS_FETCH      = 'EVENT_TAGS_FETCH'
export const EVENT_TAGS_LOADING    = 'EVENT_TAGS_LOADING'

export const FETCH_EVENT_TAGS = 'FETCH_EVENT_TAGS'

// USER MARKERS
export const MARKERS_CREATE     = 'MARKERS_CREATE'  //used to create marker
export const MARKERS_ERROR      = 'MARKERS_ERROR'
export const MARKERS_DELETE     = 'MARKERS_DELETE'
export const MARKERS_MESSAGE    = 'MARKERS_MESSAGE'
export const MARKERS_UPDATE     = 'MARKERS_UPDATE'
export const MARKERS_FETCH      = 'MARKERS_FETCH'
export const MARKERS_LOADING    = 'MARKERS_LOADING'              // true when user markers being fetched

// MESSAGES
export const ERROR_ADD = 'ERROR_ADD'                //errors
export const MESSAGE_ADD = 'MESSAGE_ADD'            //messages
export const MESSAGES_CLEAR = 'MESSAGES_CLEAR'        //clears all

// MODALS
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// PAYMENTS
export const PAYMENT_SET_STATUS = 'PAYMENT_SET_STATUS'
export const PAYMENT_INITIALIZE_STATE = 'PAYMENT_INITIALIZE_STATE'

// REPORTS
export const REPORT_LTI_ATTACH_FETCH = 'REPORT_LTI_ATTACH_FETCH'
export const REPORTS_ERROR = 'REPORTS_ERROR'

// unused
export const PAYMENT_CLEAR_MESSAGES = 'PAYMENT_CLEAR_MESSAGES'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const PAYMENT_LOADING = 'PAYMENT_LOADING'
export const PAYMENT_MESSAGE = 'PAYMENT_MESSAGE'

// VIDEO ACTIONS
export const SET_PLAYER         = 'SET_PLAYER'
export const SEEK_TO            = 'SEEK_TO'
export const SET_CURRENTTIME    = 'SET_CURRENTTIME'
export const SET_DURATION       = 'SET_DURATION'

// UNMOUNTS
export const UNMOUNT_ANALYSIS = 'UNMOUNT_ANALYSIS'

// ZOOM
export const ZOOM_CLEAR_RECORDINGS = 'ZOOM_CLEAR_RECORDINGS'
// export const ZOOM_ERROR = 'ZOOM_ERROR'	// Defined in zoom.js
export const ZOOM_LIST_RECORDINGS = 'ZOOM_LIST_RECORDINGS'
export const ZOOM_IMPORT = 'ZOOM_IMPORT'
export const ZOOM_IMPORTS_DELETE = 'ZOOM_IMPORTS_DELETE'
export const ZOOM_IMPORTS_FETCH = 'ZOOM_IMPORTS_FETCH'
export const ZOOM_RETAIN_DATA = 'ZOOM_RETAIN_DATA'