import React from 'react'
import { I18n } from '@aws-amplify/core';

import MenuBtn from 'Widgets/MenuButton.jsx';

const OpenItemMenuBtn = ({handleOnClick}) => (
	<MenuBtn
		title={I18n.get('Open')}
		onClick={e => { handleOnClick(e) }}
		iconClass="external-link-alt text-success"
	/>
);

export default OpenItemMenuBtn;