import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import { I18n } from '@aws-amplify/core';

import {TransitionGroup, CSSTransition} from 'react-transition-group';

import TabFilters from './Tab.Filters.jsx';
import EventTagItem from './Tabs.EventTagItem.jsx';


class Tab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterByDashboardTag: null,
            filterByCollaborator: null, //this.props.currentUserID
            filterByMarkers: {},
            searchTerm: false,
			sort: {iteratee: 'mediaTimestamp', order: 'asc'},
        }
    }
    
	render() {
		// First check loading status
		const {
            active, 
			analysisVisibility,
            eventTagsLoading, 
            itemAnalysisLoading,
            itemAnalysisDbsLoading,
            itemAnalysisDbTagsLoading,
			itemAnalysisMrksLoading, 
            userDbsLoading,
            userMarkersLoading, 
            userTagsLoading,
        } = this.props;

		// Checking if anything is still loading and if so, display loading
        if (eventTagsLoading || itemAnalysisLoading ||
            itemAnalysisDbTagsLoading || itemAnalysisDbsLoading || itemAnalysisMrksLoading || 
            userMarkersLoading || userDbsLoading || userTagsLoading ) {
			return (
				<div className={active === 0 ? 'tab-pane active' : 'tab-pane'}>
					<br />&nbsp;&nbsp;Loading...
				</div>
            );
		}

		let {
            tabIndex,  //0 = normal tab, 1 = playlist, 2 = tags
            locked,
			eventTags,
			collaborators,
			currentUserID,
			itemAnalysisOwner,
			isItemAnalysisOwner,
			planLimits
			// hasCollaborators, // See note in <Analysis /> re future use of hasCollaborators
		} = this.props;

		let {
			filterByDashboardTag, 
			filterByCollaborator, 
			filterByMarkers, 
			searchTerm, 
			sort, 
		} = this.state;

		let noTags = I18n.get("No analysis yet.")
        let eventTagsArr = new Array();

		// Build eventTags list to display
		if (0 < _.keys(eventTags).length) {
            let ordered = {};
            
			// Order the list
			if (sort.iteratee == '') ordered = eventTags;
            else ordered = _.orderBy(eventTags, [sort.iteratee, 'dateCreated'], [sort.order]);
            
            // Playlist only? 
            if (tabIndex === 1) ordered = _.filter(ordered, {playlist: true});

			eventTagsArr = ordered.filter((value, id) => {
				let rtn = false;
				if (
					(filterByCollaborator === null || filterByCollaborator === value.ownerID) &&
					(filterByDashboardTag === null || filterByDashboardTag === value.dashboardTagID)
				) {
					// Start off assuming all eventTags will be returned
					rtn = true;
					// Filter by Marker - forEach entererd only if a markerFilter has been selected
					_.forEach(filterByMarkers, function(marker, key) {
						// If the eventTag has the marker set but if the the marker value is not the value of the filter, set rtn to false
						if (value.markers[key]) {
                            if (value.markers[key] != marker) 
                                rtn = false;
						// If the eventTag does not have the marker set, set rtn to false
						} else {
							rtn = false;
						}
					});
                }
                
                // If rtn still true && searchTerm exists, search
                if( rtn && searchTerm ) {
                    // Search title
                    rtn = value.title.toLowerCase().includes(searchTerm);
                    // Search comment
                    if( !rtn && value.comment )
                        rtn = value.comment.toLowerCase().includes(searchTerm);
                }
                return rtn;
			});
		} else noTags = I18n.get("No analysis yet.");

		return (
			<div
				className={
					active === tabIndex
						? 'tab-pane tab-pane-no-overflow active'
						: 'tab-pane tab-pane-no-overflow'
				}>
				{/** Filters */}
				<TabFilters
					activeTab={active === tabIndex} //NOTE: Placed here to force shouldComponentUpdate call when TabFilters becomes active
					analysisVisibility={analysisVisibility}
					collaborators={collaborators}
					currentUserID={currentUserID}
					filterByCollaborator={filterByCollaborator}
					filterByDashboardTag={filterByDashboardTag}
					filterByMarkers={filterByMarkers}
					handleFilterBy={this.handleFilterBy}
					handleMarkerFilter={this.handleMarkerFilter}
                    handleSearch={this.handleSearch}
					handleSort={this.handleSort}
					itemAnalysisOwner={itemAnalysisOwner}
                    isItemAnalysisOwner={isItemAnalysisOwner}
					sort={sort}
					planLimits={planLimits}
				/>

				{/* Eventtags */}
				<div className="commentblocks-scroll-wrap">
					<TransitionGroup>
						{eventTagsArr.length == 0 ? (
							<CSSTransition
								in
								appear={true}
								timeout={500}
								classNames="commentblock-fade">
								<div className="card commentblock card-outline-default">
									<br />&nbsp;&nbsp;{noTags}
								</div>
							</CSSTransition>
						) : (
							eventTagsArr.map((taggingData, i) => (
								<CSSTransition
									in
									appear={true}
									key={taggingData.eventTagID}
									timeout={500}
									classNames="commentblock-fade">
									<EventTagItem
										activeTagID={this.props.activeTagID}
										id={taggingData.eventTagID}
										key={`etit-${taggingData.eventTagID}`}
										eventTag={taggingData}
										itemAnalysisOwner={itemAnalysisOwner}
										isItemAnalysisOwner={isItemAnalysisOwner}
                                        locked={locked}
										// hasCollaborators={hasCollaborators}  // See note in <Analysis /> re future use of hasCollaborators
									/>
								</CSSTransition>
							))
						)}
					</TransitionGroup>
				</div>
			</div>
		);
	}

	handleSort = (iteratee, order) => {
		this.setState({
			sort: {iteratee: iteratee, order: order}
		});
	};

	handleMarkerFilter = (markerID, value) => {
		let newFilters = _.assign({}, this.state.filterByMarkers, {[markerID]: value});
		// Untoggle existing
		if (this.state.filterByMarkers[markerID] === value)
			newFilters = _.omit(this.state.filterByMarkers, markerID);
		// Show all
		if (!markerID) newFilters = {};
		this.setState({filterByMarkers: newFilters});
	};

	handleFilterBy = (filter, id) => {
		this.state[filter] === id ? (id = null) : null;
		this.setState({[filter]: id});
    };
	
	// Handles search box. Setting to false to pick up search initiated on an empty - undefined - term
	handleSearch = (term=false) => {
        if( this.state.searchTerm === term) return;
        if (0 === term.length) term = false;
		this.setState({searchTerm: term});
	};
}

function mapStateToProps(state) {
    const eventTagItems = state.eventTagItems;
    const selectedItemAnalysis = state.folderItems.selectedItemAnalysis;
	return {
        activeTagID: eventTagItems.activeTagID,
        collaborators: selectedItemAnalysis.collaborators.users,
        currentUserID: state.user.data.userID,
        
        eventTags: eventTagItems.data,
		eventTagsLoading: state.eventTagItems.loading,

        itemAnalysisDbsLoading:     state.itemAnalysisDbs.loading,
        itemAnalysisDbsLoading:     state.itemAnalysisDbs.loading,
        itemAnalysisMrksLoading:    state.itemAnalysisMarkers.loading,
        itemAnalysisDbTagsLoading:  state.itemAnalysisDbTags.loading,
        
		itemAnalysisOwner: {
			ownerID: selectedItemAnalysis.ownerID,
			name: selectedItemAnalysis.name,
			initials: selectedItemAnalysis.initials
			// avatar: selectedItemAnalysis.avatarURL_32,
		},
		
		locked: state.folderItems.selectedItem.locked,
		
		planLimits: state.account.planData.additionalLimits,

        userDbsLoading:             state.userDashboards.loading,
        userTagsLoading:            state.userDashboardTags.loading,
		userMarkersLoading:         state.userMarkers.loading,
	};
}

export default connect(mapStateToProps)(Tab);
