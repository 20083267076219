import _ from 'lodash';
import classNames from 'classnames';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {DragSource, DropTarget} from 'react-dnd';

import {moveToNewFolder} from './actions/';
import {formatLocalDate, formatVideoTime} from 'utils/';
import {PROGRESS_FLAGS, SHARE_PERMISSIONS} from 'Constants/app';
import {TRANSOUT_URL} from '../../config';

import Avatar from 'Widgets/Avatar.jsx';
import DragOverlay from 'Widgets/DragOverlay.jsx';
import ProjectThumbStatus from './Project.Thumb.Status.jsx';
import ProjectThumbDropdown from './Project.Thumb.Dropdown.jsx';
import ProjectThumbLtiDropdown from './Project.Thumb.Lti.Dropdown.jsx'

const ItemTypes = {
	ITEM: 'ProjectThumb'
};

const itemSource = {
	// these props are based on the item being dragged
	beginDrag(props, dnd, element) {
		const {item, itemID} = props;
		
		// the returned object is based on the items props.
		return {
			itemID: itemID,
			item: item
		};
	},
	canDrag(props, monitor) {
		const {item} = props;

		// Dont drag is still transcoding
		if (PROGRESS_FLAGS.PROGRESSING === item.transcodeProgress) return false;
		return true;
	}
};

// monitor.getItem() gives you the item that was being dragged
// these props are based on the drop target
const folderDropTarget = {
	drop(props, monitor, component) {
		const {item, itemID} = props;

		const draggedItem = monitor.getItem();
		const targetItemID = itemID;
		const targetItemTitle = item.title;
		// here we're basically stating that if the drop target is not a mediaType of FOLDER,
		// nothing will happen. Since we only want folder items to be drop targets
		// as well as if the targetItem is the same as the dragged item, it will not do anything
		if (targetItemID === draggedItem.itemID || item.mediaType !== 'FOLDER') return;
		// accepts then entire draggedItem, the targetItems ID, and the targetItems title.
		props.moveToNewFolder(draggedItem, targetItemID, targetItemTitle);
	},
	// hover(props, monitor, component){},
	canDrop(props, monitor) {
		const {item, itemID} = props;

		const draggedItem = monitor.getItem();
		const targetItemID = itemID;
		// if targetItemID is the same as the draggedItems ID, will not allow a drop
		// if the target items mediaType is not a folder, will not allow a drop.
		if (targetItemID === draggedItem.itemID || item.mediaType !== 'FOLDER') return false;
		return true;
	}
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
}

function collectDrop(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		// isOver is needed for hover in dropTarget
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
}

class ProjectThumb extends Component {
	constructor(props) {
		super(props);

		this.isAnalysisOwner = false;
		this.isOwner = false;

		this.state = {
			isEditing: false,
			showDropdown: false
		};

		const { item, itemID, userID } = this.props;

		const {
			mediaType,
			ownerID,
			thumbnailURL,
			thumbnailSignedURL
		} = item;

		// Item ownership TODO: This does not limit to itemAnalysisOnwer!!
		this.isOwner = userID === ownerID;

		// MediaType & Thumbnail
		this.isVideo = false;
		switch (mediaType) {
			case 'FOLDER':
				this.thumbnail = <i className='thumbnail fal fa-folders' />
				break;
			case 'AUDIO':
				this.thumbnail = <i className='thumbnail fal fa-volume' />
				break;
			default:
				this.isVideo = true;
				this.itemThumbnail = thumbnailSignedURL
					? this.thumbnail = thumbnailSignedURL
					: this.thumbnail = `${TRANSOUT_URL}${ownerID}/${itemID}/${thumbnailURL}`
		}
	}

	render() {
		const { item, planLimits, selected, userID } = this.props;

		if(undefined === item) return <div />

		const {
			avatarURL_32,
			dateCreated,
			durationSeconds,
			initials,
			itemID,
			mediaSource,
			mediaType,
			name,
			shares,
			transcodeProgress,
            title,
		} = item;

		const {
			isAnalysisOwner,
			isOwner,
			isVideo,
			thumbnail
		} = this;

		var duration = durationSeconds || false
		duration = duration ? formatVideoTime(duration, duration, true) : false;

		if('FOLDER' !== mediaType) {
			this.isAnalysisOwner = PROGRESS_FLAGS.COMPLETED === item.transcodeProgress && userID === item.itemAnalyses[0].ownerID;
		}
		
		/** LTI ITEM **
		 * NOTE: 
		 * ltiCourseTitle !== false then this is an LTI course.
		 * ltiTitle !== false then this is an LTI item, either course folder or LTI activity.
		 */
		const {ltiCourseTitle} = item || false
		var ltiTitle = false, ltiAssignmentID=false, ltiAssignmentTitle = false, ltiAttachmentStatus = false;
		const itemAnalyses = item.itemAnalyses || []
		// LTI Course Check: ltiCourseTitle only present on FOLDERS
		if(ltiCourseTitle) {	
			ltiTitle = ltiCourseTitle
		}
		else if(itemAnalyses.length > 0) {
			// if( itemAnalyses[0].ltiAssignmentTitle ) {
			ltiAttachmentStatus = itemAnalyses[0].ltiAttachmentStatus;   // present on all video/audio files regardless of attachment
			if( 'ATTACHED' === itemAnalyses[0].ltiAttachmentStatus ) {
				ltiAssignmentTitle = itemAnalyses[0].ltiAssignmentTitle;
				ltiAssignmentID = itemAnalyses[0].ltiAssignmentID;
				ltiTitle = ltiAssignmentTitle;
			}
		}

		/** LTI PARENT FOLDER? **/
		const {ltiParentCourseAssignments, ltiParentCourseTitle} = this.props;

		const confidential = item.confidential || false;
		const locked = item.locked || false;

    	// Transcoding or a folder?
		const isClickable = 'COMPLETED' === transcodeProgress || 'FOLDER' === mediaType;

		// allow collaboration?
		let canCollaborate = false;
		if (shares.users[userID])
			canCollaborate =
				shares.users[userID].permission === SHARE_PERMISSIONS.INVITE_COLLABORATOR;

		// Dynamic classes
		let classes = classNames({
			'project-thumb': true,
			card: true,
			'cursor-not-allowed': !isClickable,
			'project-thumb-selected': selected
		});

		let cardClasses = classNames({
			'card-block': true,
			'cursor-default': !isOwner,
			'project-thumb-overlay': !isClickable,
		});

		// only show confidentiality is confidential or isOwner
		let confidentialClasses = classNames({
			'far fa-shield': !confidential && isOwner,
			'far fa-shield-check text-danger': confidential
		});

		// only show lock if is locked or isOwner
		let lockedClasses = classNames({
			'far fa-unlock': !locked && isOwner,
			'far fa-lock-alt text-danger': locked
		});

		let thumbnailImageClasses =  classNames({
			'project-thumb-image': true,
			'icon': !isVideo,
			'moodle': ltiTitle
		})

		let titleClasses = classNames({
			'project-thumb-title': true,
			'project-thumb-title-editing': this.state.isEditing,
			'cursor-input': isClickable && !ltiCourseTitle
		});

		const confidentialTitle =
            'Privacy Guard' + (!planLimits.confidential ? ' - available in upgraded plans.' : '');

        const lockedTitle = 
			'Item Lock' + (!planLimits.locked ? ' - available in upgraded plans.' : '');
			
		const {isDragging, connectDragSource, connectDropTarget, src, isOver, canDrop} = this.props;
		return connectDragSource(
			connectDropTarget(
				<div
					className={classes}
					onClick={isClickable ? this.handleBoxClick : null}
					onDoubleClick={isClickable ? this.handleBoxDblClick : null}
					onContextMenu={isClickable ? this.handleRightClick : null}
					onMouseLeave={isClickable ? this.handleMouseLeave : null}>

					{/* Dropdown */}
					{isClickable ? (
						<div className="project-thumb-menu">
							<ProjectThumbDropdown
								canCollaborate={canCollaborate}
								canRename={!ltiCourseTitle} // Canot rename ltiCourseFolders
								handleOpenClick={this.handleBoxDblClick}
								handleEditClick={this.handleTitleClick}
								isOwner={isOwner}
								locked={locked}
								mediaType={mediaType}
								showDropdown={this.state.showDropdown}
							/>
						</div>
					) : null}

					{/* LTI Dropdown - displayed on LTI items and non-items in LTI folder (where mediaTypeype not FOLDER) */}
					{ltiTitle || (ltiParentCourseAssignments && 'FOLDER' !== mediaType)
					? <div className="project-thumb-menu-right">
						<ProjectThumbLtiDropdown 
							isAnalysisOwner={isAnalysisOwner}
							isOwner={isOwner}
							itemID={itemID}
							ltiAttachmentStatus={ltiAttachmentStatus}
							ltiCourseTitle={ltiCourseTitle} 
							ltiAssignmentID={ltiAssignmentID}
							ltiAssignmentTitle={ltiAssignmentTitle}
							ltiParentCourseAssignments={ltiParentCourseAssignments}
							ltiParentCourseTitle={ltiParentCourseTitle}
							mediaType={mediaType}
						/>
					</div>
					: null
					}

					{/* Thumbnail image */}
					<div className={thumbnailImageClasses} 
						style={PROGRESS_FLAGS.COMPLETED === transcodeProgress && isVideo ? {backgroundImage: `url(${thumbnail})`} : null}
						>
						{(PROGRESS_FLAGS.PROGRESSING === transcodeProgress ||
							PROGRESS_FLAGS.UPLOADSTARTED === transcodeProgress )
							? <ProjectThumbStatus itemID={itemID} transcodeProgress={transcodeProgress} mediaSource={mediaSource}/>
							: 'AUDIO' === mediaType || 'FOLDER' === mediaType
								? thumbnail
								: null
						}
					</div>

					{/** Thumb Info */}
					<div className={cardClasses}>
						{/** Title */}
						<div className={titleClasses}>
							<p onClick={isClickable && !ltiCourseTitle ? this.handleTitleClick : null}>{title}</p>
							{isClickable && !ltiCourseTitle 
								? <form
									onSubmit={e => {
										e.preventDefault();
										this.refs.titleInput.blur();
									}}>
									<input
										ref="titleInput"
										className="form-control"
										name="titleInput"
										type="text"
										maxLength="50"
										onBlur={this.handleTitleUpdate}
										defaultValue={title}
									/>
								</form>
								: null }
						</div>

						{/** Avatar, date, duration, etc. */}
						<div className="project-thumb-details">
							<Avatar size="md" initials={initials} src={avatarURL_32} name={name} />
							<p style={{display: 'flex', justifyContent: 'space-between'}}>
								<b>{formatLocalDate(dateCreated)}</b>
								{duration ? <b>{duration}</b> : null}
							</p>
							<div style={{paddingTop: '8px'}}>
								{ltiTitle 
								? <div className='project-thumb-assignment-title'>{ltiTitle}</div>
								: null }
								
								{isClickable 
								? <div className="project-thumb-status">
									<i
										id="confidential"
										title={confidentialTitle}
										data-toggle="tooltip"
										disabled={!planLimits.confidential}
										className={confidentialClasses}
										onClick={
											isOwner
											? this.handleKeyValueToggle
											: this.handleModelOpen
										}
									/>
									<i
										id="locked"
										title={lockedTitle}
										data-toggle="tooltip"
										disabled={!planLimits.locked}
										className={lockedClasses}
										onClick={
											isOwner
												? this.handleKeyValueToggle
												: this.handleModelOpen
										}
									/>
								</div>
								: null }
							</div>
						</div>
					</div>
					{isOver && canDrop && <DragOverlay color="#1BC98E" />}
				</div>
			)
		);
	}

	handleTitleClick = e => {
		e.preventDefault();
		e.stopPropagation();

		// if the user is not the owner of the item, will not be able to edit the field.
		if (!this.isOwner) return;

		// const { item } = this.props;
		this.setState({
			isEditing: true,
			showDropdown: false
		});

		// selects whole title for immediate replace
		this.refs.titleInput.select();
		setTimeout(() => this.refs.titleInput.focus(), 30);
	};

	handleTitleUpdate = e => {
		this.setState({isEditing: false});
		this.props.handleTitleUpdate(e, this.props.itemID);
	};

	handleKeyValueToggle = e => {
		if (!this.isOwner || !this.props.planLimits[e.target.id]) return;
		this.props.handleKeyValueToggle(e, this.props.itemID);
	};

	handleModelOpen = e => {
		this.props.openNoticeModal(e);
	};

	handleBoxClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({showDropdown: false});
		this.props.handleThumbClicked(this.props.itemID);
	};

	handleBoxDblClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({showDropdown: false});
		if (this.state.isEditing) return;
		this.props.openItem(this.props.itemID);
	};

	handleRightClick = e => {
		e.preventDefault();
        this.setState({showDropdown: !this.state.showDropdown});
        this.props.handleThumbClicked(this.props.itemID);
	};

	handleMouseLeave = e => {
		this.setState({showDropdown: false});
	};
}


function mapStateToProps(state) {
    return {
		planLimits: state.account.planData.additionalLimits,
    }
}

const mapDispatchToProps = {moveToNewFolder};

const ProjectThumbFinal = compose(
	connect(mapStateToProps, mapDispatchToProps),
	DropTarget(ItemTypes.ITEM, folderDropTarget, collectDrop),
	DragSource(ItemTypes.ITEM, itemSource, collect)
)(ProjectThumb);

export default ProjectThumbFinal;
