import axios from 'axios';
import { request } from './index'
import { apiConfig, DMIO_RELEASE } from '../config';
import { signoutUser } from './index'

import {INVITE_TYPES} from 'Constants/app';
import {
    _ONLYUS,
    _SET_VAR,
    _UNSET_VAR,
    ACCOUNT_GET,
    ACCOUNT_REHYDRATE_USAGEDATA,
    ACCOUNT_REHYDRATE_USAGEDATA_MTD,
	ACCOUNT_REHYDRATE_COLLABS,
	ACCOUNT_REHYDRATE_SHARES,
	CAUTH_ERROR,
	CLOSE_MODAL,
	CUSER_CLEAR_MESSAGES,
	DASHBOARDS_ERROR,
	FOLDERS_ERROR,
	ITEM_ERROR,
	MESSAGES_CLEAR,
	OPEN_MODAL,
	// PAYMENT_ERROR,
	SET_BOTTOMBAR_HEIGHT,
	SET_SIDEBAR_WIDTH,
	SET_SHOW,
    SET_QUEUE_SIZE,
    TEMPLATES_FETCH,
	UPLOAD_ERROR
} from 'Constants/actions';

// APP API CALLS
/**
 * Gets the current app version from the cloud. If the the client version is not the same as the server version, forces a hard refresh.
 * Version currently (Sep 2018) hard coded into a cloud deployment.
 */
export function getAppVersion(store) {
    // console.log('getAppVersion. Client version is ', DMIO_RELEASE);
    // console.log(`Analysis page: ${window.location.pathname.startsWith('/a/')}`)

    var checkVersion = setInterval(function() {
        axios.get(`${apiConfig.appGetVersion}?${Math.random()}`)
        .then(resp => {
            clearInterval(checkVersion);
            if(DMIO_RELEASE !== resp.data.version) {
                
                // Push to root if on analysis to avoid killing dynamoDB
                // if( window.location.pathname.startsWith('/a/') || window.location.pathname.startsWith('/l/') ) 
                //     window.location = '/'
                // else 
                //     window.location.reload(true)
                
                // force signout
                store.dispatch(signoutUser());
                window.location.reload(true)
            }
            Promise.resolve();
        })
        .catch(err => {
            clearInterval(checkVersion);
            Promise.resolve('Version Get Failure.')
        });
    }, 1000);
}

/**
 * Fetches application templates. Data returned depends on whether GET or PATCH method used. Default is PATCH
 * @param {String} method either PATCH or GET
 * @returns Promise
 */
export function fetchTemplates( method='PATCH' ) {
	return function(dispatch) {

        // Use axios because request service not yet initialized.
        axios({
			method,
			url: `${apiConfig.appFetchTemplates}`
        })
        .then(resp =>  {
            dispatch({
                type: TEMPLATES_FETCH, 
                payload: resp.data
            });
            Promise.resolve();
        })
        .catch(err => {
            dispatch({
                type: TEMPLATES_FETCH, 
                payload: {}
            });
            Promise.resolve('Template Fetch Failure.')
        });
	};
}


// OTHER
export const setQueueSize = queueSize => ({
	type: SET_QUEUE_SIZE,
	queueSize
});


export const setOnlyUs = () => ({
	type: _ONLYUS
}); 

export const setUnderscoreVar = (key, value) => ({
    type: _SET_VAR,
    payload: {key, value}
}); 

// UI Vars
export const setBottombarHeight = height => ({
	type: SET_BOTTOMBAR_HEIGHT,
	height
});

export const setShow = (key, value) => ({
	type: SET_SHOW,
	payload: { key, value }
}); 

export const setSidebarWidth = width => ({
	type: SET_SIDEBAR_WIDTH,
	width
});

/**
 * Updates Account.usageStats, Account.shares, account.collaborators. 
 * Typically called from successful RESPONSE and passed response.data
 * @param {Object} data Object containing but not requiring usageData{}, shares[], collaborators[]
 * @param {int} inviteType Not required. Responds only to INVITE_TYPES
 */
export function accountRehydrate(data, inviteType = false) {
	return function(dispatch) {
		if (data.usageData) {
			dispatch({
				type: ACCOUNT_REHYDRATE_USAGEDATA,
				payload: data.usageData
			});
        }
		if (data.usageDataMTD) {
			dispatch({
				type: ACCOUNT_REHYDRATE_USAGEDATA_MTD,
				payload: data.usageDataMTD
			});
		}
		switch (inviteType) {
			case false:
				return;
			case INVITE_TYPES.COLLABORATE:
				dispatch({
					type: ACCOUNT_REHYDRATE_COLLABS,
					payload: data.collaborators
				});
				return;
			case INVITE_TYPES.SHARE:
				dispatch({
					type: ACCOUNT_REHYDRATE_SHARES,
					payload: data.shares
				});
				return;
			default:
				return;
		}
	};
}

// MODALS
export function openModal(name, xID, data) {
	return {
		type: OPEN_MODAL,
		payload: {name, xID, data}
	};
}

// on close of modal, needs to get rid of error messages as well
export function closeModal() {
	return function(dispatch) {
		dispatch({type: CLOSE_MODAL});
	};
}

// MESSAGING
export function clearUserMessages() {
	return {type: CUSER_CLEAR_MESSAGES};
}

// will take the passed location and decide what errors it needs to clear.
export function dismissMessage(location) {
	return function(dispatch) {
		// will iterate over each object, dispatching an action for that object.
		const clearError = (...args) => args.forEach(type => dispatch({type, payload: null}));
		switch (location) {
			case 'analysis':
			case 'folders':
                return clearError(FOLDERS_ERROR, ITEM_ERROR );
			case 'dashboards':
				return clearError(DASHBOARDS_ERROR);
			case 'user':
				return clearError(CAUTH_ERROR);
			// case 'payment':
			// 	return clearError(PAYMENT_ERROR);
			case 'uploader':
				return clearError(UPLOAD_ERROR);
			default:
				dispatch({
					type: MESSAGES_CLEAR
				});
		}
	};
}

/**
 * Dispatches actionType with payload mesage. If timeOpen is larger than 0, dispatches MESSAGE_CLEAR are timeOpen milliseconds
 * @param {String} actionType action constant
 * @param {String} message 
 * @param {Int} timeOpen milliseconds to display message for. If 0 or less, message displays until dimissed by user or new page navigation
 */
export function messageToast(actionType, message, timeOpen = 0) {
	return function(dispatch) {
		dispatch({
			type: actionType,
			payload: message
        });
        if( 0 < timeOpen ) {
            setTimeout(() => {
                dispatch({
                    type: MESSAGES_CLEAR,
                    payload: null
                });
            }, timeOpen);
        }
	};
}


