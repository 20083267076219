import React, {Component} from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import {connect} from 'react-redux';

import Avatar from 'Widgets/Avatar.jsx';
import ModalConfirm from 'Widgets/Modal.Confirm.jsx';
import {formatLocalDate} from '../../utils';

class EventTagItemReply extends Component {
	state = {editing: false, displayModal: false};

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({displayModal: nextProps.modalOpen.name});
	}

	render() {
		const {
            dateCreated,
			eventTagReplyID, 
			initials,
            locked,
			ownership, 
			name,
            text, 
        } = this.props;

		const classes = classNames({
			replyblock: true,
			'replyblock-editing': this.state.editing && ownership && !locked
		});

		return (
			<div className={classes}>
				{this.state.displayModal === 'deleteEventTagReplyModal' &&
				eventTagReplyID === this.props.modalOpen.xID ? (
					<ModalConfirm
						onConfirm={() => {
							this.props.handleReplyDelete(eventTagReplyID);
						}}
						title="reply"
						confirmBtn="Delete"
						confirmPhrase="Are you sure you want to delete this"
						message={`"${this.props.text}"`}
					/>
				) : (
					''
				)}
				<div className="replyblock-info">
					<Avatar size="xs" initials={initials} />
					<span className="replyblock-name">{name}</span>
					<span className="replyblock-date">{formatLocalDate(dateCreated)}</span>
				</div>
				<div className="replyblock-text" onClick={this.handleReplyClick}>
					{text}
				</div>
				<textarea
					ref="editingReplyInput"
					className="form-control"
					type="text"
					onKeyDown={this.handleInputKeyDown}
					onBlur={this.handleBlur}
					placeholder="Reply..."
					defaultValue={text}
                    rows={1}
				/>

				{ownership && !locked ? (
					<div
						className="replyblock-delete text-muted cursor-pointer colorEaseIn"
						data-toggle="tooltip"
						title="Delete reply"
						onClick={e => {
							e.preventDefault();
							this.props.openModal('deleteEventTagReplyModal', eventTagReplyID);
						}}>
						<i className="far fa-times" />
					</div>
				) : (
					''
				)}
			</div>
		);
	}

	handleReplyClick = e => {
		// dm_print( 'ReplyBlock.handleReplyClick() triggered')
		this.setState({
			editing: true
		});

		setTimeout(() => {
			this.refs.editingReplyInput.focus();
		}, 30);
	};

	handleInputKeyDown = e => {
		// dm_print( 'ReplyBlock.handleInputKeyDown() triggered')
		// isEnter?
		if (e.keyCode == 13) {
			e.preventDefault();
			this.setState({
				editing: false
			});
		}
	};

	handleBlur = e => {
		// dm_print( 'ReplyBlock.handleBlur() triggered')
		this.setState({
			editing: false
		});
		const newValue = _.trim(e.target.value);
		if (0 < newValue.length) {
			this.props.handleReplyUpdate(this.props.eventTagReplyID, newValue);
		}
	};
}

function mapStateToProps(state) {
	return {
		modalOpen: state.modalOpen
	};
}

export default connect(mapStateToProps)(EventTagItemReply);
