/**
 * TODO: Clean up code and here. split out video. lots!
 * TODO: VideoControls - sort out visible current time playing
 * TODO: volume control
 * TODO: CSS - font of timers
 * 
 * */

// 3RD Party Imports
import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	BigPlayButton,
	ClosedCaptionButton,
	ControlBar,
	CurrentTimeDisplay,
	// ForwardControl,
	LoadingSpinner,
	PlaybackRateMenuButton,
	Player,
	// ReplayControl,
	TimeDivider,
	VolumeMenuButton
} from 'video-react';

import { TRANSOUT_URL, TRANSOUT_SURL } from 'config';

import MediaSource from './MediaSource.jsx';
import ScanControl from './ScanControl.jsx';

import { 
	mediaPlayerErrorHandler,
    resetSeekStartEnd, 
    setPlayer 
} from '../actions/mediaplayer';

import 'video-react/dist/video-react.css';

// TESTING
// Sandbox: https://codesandbox.io/s/n07n0v8ljp
// var url = '//vjs.zencdn.net/v/oceans.mp4'; // widescrean birds
var devURL = '//d2zihajmogu5jn.cloudfront.net/elephantsdream/ed_hd.mp4'   //translations
var devVttArr = ['../assets/vtt/subtitleVTT_en-GB.vtt', '../assets/vtt/subtitleVTT_de-DE.vtt']
// var url = 'http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_2160p_60fps_normal.mp4'
// var url = 'http://peach.themazzone.com/durian/movies/sintel-1024-surround.mp4'

// var url = 'https://dqwp3xzzbfhtw.cloudfront.net/vod/smil:audio.smil/playlist.m3u8'  // AUDIO ONLY

// Test Error URLS - comment out withCredentials in <MediaSource /> to get it to NOT err
// var url = 'https://video-dev.github.io/streams/x36xhzz/x36xhzz.m3u8'

import { LOB_KEYS, PROGRESS_FLAGS, TRANSCRIBE_LANGUAGES } from 'Constants/app.js'


class MediaPlayer extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = { player: null };

		// Define media URLS
		const { itemID, ownerID, mediaURL } = props.item;

		props.isItemOwner
			? this.baseURL = `${TRANSOUT_URL}${ownerID}/${itemID}/`
			: this.baseURL = `${TRANSOUT_SURL}${ownerID}/${itemID}/`;

		this.mediaSrc = this.baseURL + mediaURL
		this.baseURL = this.mediaSrc.replace(/\.m3u8$/, '');
		this.vttArr = this.props.vtts;

		if ("development" === process.env.NODE_ENV && 'localhost' === window.location.hostname) {
			this.baseURL = '';
			this.mediaSrc = devURL;
			this.vttArr = [];
		}

		/** add your own shortcuts
        this.newShortcuts = [
            // Press number 1 to jump to the postion of 10%
            {
                keyCode: 49, // Number 1
                // handle is the function to control the player
                // player: the player's state
                // actions: the player's actions
                handle: (player, actions) => {
                    const duration = player.duration;
                    // jump to the postion of 10%
                    actions.seek(duration * 0.1);
                }
            },
            // Ctrl/Cmd + Right arrow to go forward 30 seconds
            {
                keyCode: 39, // Right arrow
                ctrl: true, // Ctrl/Cmd
                handle: (player, actions) => {
                    if (!player.hasStarted) {
                        return;
                    }

                    // this operation param is option
                    // helps to display a bezel
                    const operation = {
                        action: 'forward-30',
                        source: 'shortcut'
                    };
                    actions.forward(30, operation); // Go forward 30 seconds
                }
            }
        ]; **/
	}

	componentDidMount() {
		this.refs.player.subscribeToStateChange(this.handleStateChange);
	}

	componentDidUpdate (prevProps, prevState) {
		const { playerPaused, playerState: { seekStart }, resetSeekStartEnd } = this.props;
		// HANDLE PLAY / PAUSE from tags and commenting
		if (playerPaused !== this.refs.player.getState().player.paused) {
			playerPaused ? this.pause() : this.play();
		}

		if (this.props.playerState.seekStart !== null) {
			this.seek(this.props.playerState.seekStart);
			resetSeekStartEnd();
		}
	}

	handleStateChange = (state, prevState) => {
		if (state !== prevState) this.props.setPlayer(state);

        // Required to pause() and play() from video-react player
        if (state.paused !== prevState.paused) {
			state.paused ? this.pause() : this.play();
		}

		/** DEBUG INFO
		 * networkState
		 * ============
		 * NETWORK_EMPTY 		0 	There is no data yet. Also, readyState is HAVE_NOTHING.
		 * NETWORK_IDLE 		1 	HTMLMediaElement is active and has selected a resource, but is not using the network.
		 * NETWORK_LOADING 		2 	The browser is downloading HTMLMediaElement data.
		 * NETWORK_NO_SOURCE 	3 	No HTMLMediaElement src found. 
		 * 
		 * readyState
		 * ========== 
		 * HAVE_NOTHING 		0 	No information is available about the media resource.
		 * HAVE_METADATA 		1 	Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no longer raise an exception.
		 * HAVE_CURRENT_DATA 	2 	Data is available for the current playback position, but not enough to actually play more than one frame.
		 * HAVE_FUTURE_DATA 	3 	Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example).
		 * HAVE_ENOUGH_DATA 	4 	Enough data is available—and the download rate is high enough—that the media can be played through to the end without interruption.
		 * */		
		// if (state.readyState !== prevState.readyState || state.networkState !== prevState.networkState) {
		// 	console.log("MP Buffered: ", state.buffered);
		// 	console.log("MP readyState: ", state.readyState);
		// 	console.log("MP networkState: ", state.networkState);
		// }
	};

	render () {
		const { mediaPlayerErrorHandler, preferences: { scanBwd, scanFwd } } = this.props;
		let { startTime } = this.props.playerState;

		return (
			<Player
				autoPlay={false}
				crossOrigin={
					("development" === process.env.NODE_ENV && 'localhost' === window.location.hostname)
						? null
						: "use-credentials"
					}
				fluid={true}
				playsInline={true}
				ref="player"
				preload="auto"
				startTime={startTime}
				src={this.mediaSrc}
				use-credentials={true}>
				<BigPlayButton position="center" />
				<MediaSource
					isVideoChild
					src={this.mediaSrc}
					mediaPlayerErrorHandler = {mediaPlayerErrorHandler}
				/>
				{this.vttArr.length == 0
					? null
					: (this.vttArr.map((vttFileName, i) => (
						<track
							default
							key={i}
							kind="captions"
							label={TRANSCRIBE_LANGUAGES[_.split(_.trimEnd(vttFileName, '.vtt'), '_')[1]]}
							src={this.baseURL + vttFileName}
							srcLang={_.split(_.trimEnd(vttFileName, '.vtt'), '_')[1]}
						/>)))
				}
				<LoadingSpinner />
				<ControlBar autoHide={false}>
					<ScanControl order={1.1} seconds={scanBwd} replay={true} />
					<ScanControl order={1.2} seconds={scanFwd} />
					<CurrentTimeDisplay order={4.1} />
					<TimeDivider order={4.2} />
					<PlaybackRateMenuButton rates={[0.25, 0.5, 1, 1.5, 3, 5]} order={7.1} />
					<VolumeMenuButton vertical={true} />
					<ClosedCaptionButton order={7} />
				</ControlBar>
			</Player>
		);
	}

	pause = () => {
		this.refs.player.pause();
		// props received from analysis, we are telling the analysis state the player has paused/
		this.props.pause();
	};

	play = () => {
		this.refs.player.play();
		// props received from analysis, we are telling the analysis state the player is currently playing.
		this.props.play();
    };
    
	seek = seconds => {
		seconds > 0 ? this.refs.player.seek(seconds) : '';
		this.play();
    };
    
}

function mapStateToProps(state) {
	return {
        playerState: state.playerState,
        preferences: state.user.data.preferences, 
		item: state.folderItems.selectedItem,
		vtts: state.folderItems.selectedVTTs
	};
}

const mapDispatchToProps = {
	mediaPlayerErrorHandler,
	resetSeekStartEnd,
	setPlayer
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaPlayer);
