import {
	CUNAUTH_USER,
	ITEM_ANALYSIS_MARKERS_LOADING,
	ITEM_ANALYSIS_MARKERS_FETCH,
	UNMOUNT_ANALYSIS
} from 'Constants/actions';

const INITIAL_STATE = {
	data: {},
	loading: true,
    unmount: true, 
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		// unauths the user and removes all redux state
        case CUNAUTH_USER:
		case UNMOUNT_ANALYSIS:
			return INITIAL_STATE;

		case ITEM_ANALYSIS_MARKERS_LOADING: {
			return {
                ...state, 
                loading: true, 
                unmount: false
            }};

		case ITEM_ANALYSIS_MARKERS_FETCH: {
            if( state.unmount ) return INITIAL_STATE;
			return {
                ...state, 
                data: action.payload, 
                loading: false,
                unmount: true
            }};
	}
	return state;
}
