import _ from 'lodash';
import {
    CUNAUTH_USER,
	ITEM_LOADING,
	ITEM_ADD,
	ITEM_DELETE,
	ITEM_MOVE_FOLDER_UNDO,
	ITEM_UPDATE,
	ITEM_SELECT,
	
	ITEM_VTT_SET,

    ITEM_ANALYSIS_UPDATE,
    ITEM_ANALYSIS_SELECT,

	ITEMS_FETCH,

	ZOOM_IMPORTS_DELETE,
	ZOOM_IMPORTS_FETCH,
} from 'Constants/actions';


const INITIAL_STATE = {
	loading: true,
    selectedItem: null,
	selectedItemAnalysis: null,
	selectedVTTs: [],
    data: {}
};

/**
 * 
 * @param {Object} state 
 * @param {Object} action 
 */
export default function (state = INITIAL_STATE, action) {
	const { payload, type } = action;

	switch (type) {
		// unauths the user and removes all redux state
		case CUNAUTH_USER:
		case ZOOM_IMPORTS_DELETE:
            return { ...INITIAL_STATE, loading: false };
            
		case ITEM_LOADING:
			// payload is true or false
			return {
				...state,
				loading: payload
			};

		case ZOOM_IMPORTS_FETCH:    
        case ITEMS_FETCH:
			return {
                ...state,
				data: payload,
				loading: false
            };
            
		case ITEM_SELECT:
            // payload is selected item object
            // selectedItemAnalyses set to [0] by default
            // NB: Setting to does not change loading - should be set to true already if using for api get to prevent media from loading before cookies have been retireved

            if( null === payload.item) {
                return { 
                    ...state, 
                    selectedItem: null,
                    selectedItemAnalysis: null,
                };
            }

            const item = payload.item;
			return { 
                ...state, 
                selectedItem: item,
                selectedItemAnalysis: item.mediaType === 'FOLDER' ? null : item.itemAnalyses[payload.itemAnalysesKey]
            };

        case ITEM_ANALYSIS_SELECT:
            // payload itemAnalysis object
			return { 
                ...state, 
                selectedItemAnalysis: payload
			};
		
		case ITEM_VTT_SET:
            // payload is,,, 
			
			return { 
                ...state, 
				selectedItemVTTs: state.selectedVTTs.push(payload.lobKey)
            };

		case ITEM_ADD:
			// Payload is { itemID: {itemData} }
			// inserts payload.data at the top of NEW array containing all of state too
			return {
				...state,
				data: {
					...state.data,
					...payload
				}
			};

        case ITEM_ANALYSIS_UPDATE:
		case ITEM_UPDATE:
			// Payload is updated item {itemData} which includes itemID
            // inserts payload.data at the top of NEW array containing all of state too
            // Also updates selectedItem && selecteditemAnalysis
            var updatedState = {
                ...state,
                loading: false,
                selectedItem: payload,
				data: {
					...state.data,
					[payload.itemID]: payload
				}
            };
            
            // assign selected itemAnalyses
            if(payload.mediaType !== "FOLDER") 
                updatedState = {
                    ...updatedState,
                    selectedItemAnalysis: payload.itemAnalyses[0],
                };
            
            return updatedState;

		case ITEM_DELETE:
			// payload is key of item to delete
			return {
				...state,
				loading: false,
				selectedItem: null,
				data: _.omit(state.data, payload)
            };
            
        case ITEM_MOVE_FOLDER_UNDO:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.itemID]: payload.item
                }
            };

		default:
			return state;
	}
	return state;
}
