import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { I18n } from '@aws-amplify/core';

import { getFileData } from 'utils'
import Uploader from 'utils/uploader.js';
export var upload = null;

import { _dmAppErrorHandler, request } from 'actions/index';
import { closeModal, setShow } from 'actions/app'
import { getAccount } from 'Components/Account/actions/'
import { apiConfig, TRANSIN_BCT, DMIO_STORE_KEY } from 'config';
import { REGEXP, PLANID, PROGRESS_FLAGS, UNLIMITED } from 'Constants/app';
import { DMIO_RELEASE } from '../../../config';

import { accountRehydrate, messageToast } from 'actions/app';
import { itemAnalysisUpdate } from '../../Analysis/actions/index'

import {
	ERROR_ADD,
	FLOADING,
	FOLDERS_ERROR,
	FOLDERS_FETCH,
	ITEM_LOADING,
	ITEM_ADD,
	ITEM_DELETE,
	ITEM_ERROR,
	ITEM_MOVE_FOLDER_UNDO,
	ITEM_SELECT,
	ITEM_UPDATE,
	ITEMS_FETCH,
	ITEM_ANALYSIS_SELECT,
	ITEM_VTT_SET,
	MESSAGE_ADD,
	SET_SHOW,
	UPLOAD_ERROR,
	UPLOAD_INITIALIZING,
	UPLOAD_NAME_CHANGE,
	UPLOAD_PROGRESS,
} from 'Constants/actions';

export function moveToNewFolder (child, newParentID, newParentTitle) {
	return dispatch => {
		const newItem = child.item;
		newItem.folderID = newParentID;

		// Delete from current parent
		dispatch({ type: ITEM_DELETE, payload: newItem.itemID });

		// all that's needed to move folder is an itemID, and folderID
		const requestObj = {
			method: 'post',
			url: `${apiConfig.itemUpdate}`,
			data: {
				itemID: child.itemID,
				folderID: newParentID
			}
		};

		request(requestObj, ITEM_ERROR)
			.then(resp => {
				dispatch(
					messageToast(
						MESSAGE_ADD,
						`'${child.item.title}' moved into '${newParentTitle}' folder`,
						5000
					)
				);
				return;
			})
			.catch(err => {
				dispatch({
					type: ITEM_MOVE_FOLDER_UNDO,
					payload: child
				});
				return;
			});
	};
}

/**
 * Fetaches all items within Folder folderID
 * @param {*} folderID 
 */
export function fetchFolders (folderID) {
	return function (dispatch) {
		dispatch({ type: FLOADING });

		// // TESTS 4xx or 5xx RESPONSES
		//  url: 'http://httpstat.us/400', // test 401 response; disable headers if using!!

		// // TESTS - other tests
		// axios({
		//     url: `${apiConfig.folderViewFetch}${folderID}`,  // Correct URL with no session creates 401
		//     // url: `${apiConfig.userGetCurrent}jfilajlk`,  // test 403 from AWS
		// 	headers: {
		//         // authorization: _session.getIdToken().getJwtToken() // Correct auth
		//         // authorization: ''  // No token
		//         // bad token:
		//         // authorization: 'eyJraWQiOiJWZmdPU3B3VGtUWVYwYjVaVjVZaXh5WmxpSkI2ZGV3a1VwRFpsNzViYjY0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhZjUyMWIxNC1mY2FjLTRlNTgtOGMxNi05NjM5ZTcwZmRiNjQiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfN2lEcFRUUTdYIiwiY29nbml0bzp1c2VybmFtZSI6Ijk3NWM1ODdlLTE2ODgtNGUxMi04ZTY4LTg5OWZjZDdiZDFkZCIsImdpdmVuX25hbWUiOiJOYXRoYW5pZWwiLCJhdWQiOiI3MWh2ajM1ZW5wdDNsbHI3cWFtbnRya3M5NiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTIxMTM4MjA1LCJuYW1lIjoiTmF0aGFuaWVsIEhvbGRlciIsImV4cCI6MTUyMTE0MTgwNSwiaWF0IjoxNTIxMTM4MjA1LCJmYW1pbHlfbmFtZSI6IkhvbGRlciIsImVtYWlsIjoibmF0aGFuaWVsQGRyZWFtYWtlci5pbyJ9.MMErfOuBhhV4kHp9SN1JSrZZknYpb_xicDWC_NL3ky8bSTLdo2_ySl27T1Ubmw-sneqZe5UwgLQp3IDusJF95tvhASLORwHF3UwxQJ8iKo1q2CpOJjpazd0t1h3ixiuqT3FYrmy0WYQSBVLm_wX5N-3hZgVAq6OzMiRXWBt_cN6ytex02dQNjkZSQHPzF-tisVxgmdBZrxXkvLjS6fkaeJAO3w3TZGhLj9DYefoLCVEa-yOF0Jt3lBcl331bRC1awQff0eRanNJ4Gp58R3mqKFI4WW6GyMBP07a_2c5WsrTZLcqDp9rdkJ_MAEyEmcns1t8gAC9OtuAelEh5NZRjsg'
		// 	}

		const requestObj = {
			url: `${apiConfig.folderViewFetch}${folderID}`
		}

		request(requestObj, FOLDERS_ERROR)
			.then(resp => {
				dispatch({
					type: FOLDERS_FETCH,
					payload: resp
				});
				dispatch({
					type: ITEMS_FETCH,
					payload: resp.childItems
				});
			})
			.catch(err => { })
	};
}

/**
 * Sets the state.folderItems.selectedItem and state.folderItems.selectedItemAnalyses
 * @param {Object} item object to set to selectedItem
 * @param {Integer} itemAnalysesKey array key of item.itemAnalyses to set selectedItemAnalyses to
 */
export function selectItem (item, itemAnalysesKey = 0) {
	return {
		type: ITEM_SELECT,
		payload: { item, itemAnalysesKey }
	};
}

/**
 * Sets the state.folderItems.selectedItemAnalysis to itemAnalysis.
 * @param {String} item 
 */
export function selectItemAnalysis (itemAnalysis) {
	return {
		type: ITEM_ANALYSIS_SELECT,
		payload: itemAnalysis
	};
}

/**
 * 
 * @param String id 
 * @param Object update 
 */
function _updateItem (update) {
	return function (dispatch) {

		const requestObj = {
			method: 'post',
			url: `${apiConfig.itemUpdate}`,
			data: update,
		};

		request(requestObj, ITEM_ERROR)
			.then(resp => {
				//dispatch saved message here?
				dispatch({ type: ITEM_LOADING, payload: false });
			})
	};
}

/**
 * Generic update function for items. Also updates selectedItem
 * @param {String} itemID to update
 * @param {Object} itemID[key] to update
 * @param {String} value to update itemID[key] to
 * @param {Number} itemAnalysesKey key of item.itemAnalyses[] to update. Defaults to 0
 */
export function updateItem (itemID, key, value, itemAnalysesKey = 0) {
	return function (dispatch, getState) {
		dispatch({ type: ITEM_LOADING, payload: true });

		let updatedItem = _.assign(
			{},
			getState().folderItems.data[itemID],
			{ [key]: value }
		);

		// If key is 'locked' or 'title', propogate update to itemAnalysis
		if (('locked' === key || 'title' === key) && updatedItem.mediaType !== 'FOLDER') {
			updatedItem.itemAnalyses[itemAnalysesKey][key] = value;
			dispatch(itemAnalysisUpdate(updatedItem)); // also updates item state
		}
		// otherwise update item
		else {
			dispatch({
				type: ITEM_UPDATE,
				payload: updatedItem
			});
		}

		dispatch(_updateItem({ itemID, [key]: value }));
	};
}

/**
 * Creates an item of type folder, with parent folderID
 * @param {String} folderID 
 */
export function createFolderItem (folderID) {
	return function (dispatch, getState) {
		const { userID, initials, firstName, lastName } = getState().user.data
		const name = `${firstName} ${lastName || ''}`;
		var newItem = {
			folderID,
			mediaType: 'FOLDER',
			title: I18n.get('New folder'),
			ownerID: userID,
			initials,
			name
		};
		dispatch(createItem(newItem));
	};
}

/**
 * Calls API to creates a new item from newItem
 * @param {Object} newItem is the new item to be created
 * On success, calls accountRehydrate if item is not FOLDER
 */
export function createItem (newItem) {
	return function (dispatch, getState) {
		dispatch({ type: ITEM_LOADING, payload: true });
		
		const itemID = newItem.itemID || uuidV4();

		const dateCreated = new Date().getTime();
		let item = {
			//itemID: true, //newItem.itemID || uuidV4(),  // USE TO FAKE A RESPONSE ERROR
			itemID,
			dateCreated: dateCreated,
			dateUpdated: dateCreated,
			shares: { teams: {}, users: {} },
			itemAnalyses: [],
			...newItem
		};

		const { initials, name, userID } = getState().user.data;
		dispatch({
			type: ITEM_ADD,
			payload: {
				[itemID]: {
					...item,
					initials,
					name,
					ownerID: userID,
				}
			}
		});

		const requestObj = {
			method: 'post',
			url: `${apiConfig.itemCreate}`,
			data: item,
		};
		
		return request(requestObj, ITEM_ERROR)
			.then(resp => {
				dispatch({ type: ITEM_LOADING, payload: false });
				if ('FOLDER' !== item.mediaType)
					dispatch(accountRehydrate(resp))
				return Promise.resolve();
			})
			.catch(err => {
				// Roll back ITEM_ADD of the item
				dispatch({ type: ITEM_DELETE, payload: itemID });
				return Promise.reject(err);
			})
	}
}

/**
 * Deletes itemID
 * @param String itemID 
 */
export function deleteItem (itemID) {
	return function (dispatch, getState) {
		dispatch({ type: ITEM_LOADING, payload: true });
		dispatch(closeModal());

		// prevents race condition during upload progress checks where delete 
		// is triggered by two seperate upload progress check calls
		// To repeat: run many concurrent uploads then one that will fail (IE - no sound)
		if(undefined === getState().folderItems.data[itemID]) return

		dispatch({ type: ITEM_DELETE, payload: itemID });

		let requestObj = {
			method: 'delete',
			url: `${apiConfig.itemDelete}${itemID}&deleteDependencies=true`
		};

		request(requestObj, ITEM_ERROR)
			.then(resp => {
				dispatch(accountRehydrate(resp))
				dispatch({ type: ITEM_LOADING, payload: false });
			})
			.catch(err => {
				/**
				 * TODO: BUG: Dec 2020 - If delete fails, item is not returned to folder.
				 * To replicate: attempt deleting a folder containing a locked item
				 * */
			})
	};
}

function _createItemAnalysis (item, title = null) {
	return function (dispatch) {
		const itemAnalysis = {
			itemID: item.itemID,
			title: title || item.title
		};
		const requestObj = {
			method: 'post',
			url: `${apiConfig.analysisCreate}`,
			data: itemAnalysis,
		};

		request(requestObj, ITEM_ERROR)
		.then(resp => {
			dispatch({ type: ITEM_LOADING, payload: false });
		})
	};
}

/**
 * Simple function to rehydrate/getItem based on itemID
 * @param {String} itemID of item to get
 */
export function getItem(itemID) {
	return function (dispatch, getState) {
		const url = `${apiConfig.itemGet}${itemID}`;
		request({ url }, ITEM_ERROR)
		.then(resp => {
			const selectedItem = getState().folderItems.selectedItem || {}
			dispatch({type: ITEM_ADD, payload: { [resp.itemID]: resp }});
			if(itemID === selectedItem.itemID) 
				dispatch(selectItem(resp))

		})
	}
}

/** FILE UPLOADS  */

// transcodeProgress = STARTED | PROGRESSING | COMPLETED | ERROR | WARNING
function _fileItemProgressPoll (itemID) {
	return function (dispatch, getState) {
		const url = `${apiConfig.itemGet}${itemID}`;

		// Run this evey 5 seconds to check on transcodeProgress
		var checkProgress = setInterval(function () {
			request({ url }, ITEM_ERROR)
				.then(resp => {
					const progressFlag = resp.transcodeProgress
					dispatch({
						type: UPLOAD_PROGRESS,
						payload: {
							id: itemID,
							progressFlag: progressFlag
						}
					});
					switch (progressFlag) {
						case PROGRESS_FLAGS.COMPLETED:
							clearInterval(checkProgress);
							dispatch({
								type: ITEM_ADD,
								payload: { [resp.itemID]: resp }
							});

							// Rehydrate usage stats from cloud
							dispatch(getAccount());
							return;

						case PROGRESS_FLAGS.PROGRESSING:
							dispatch({
								type: ITEM_ADD,
								payload: { [resp.itemID]: resp }
							});
							return;

						case PROGRESS_FLAGS.WARNING:
						case PROGRESS_FLAGS.ERROR:
							// Error shown in uploadsInProgress
							clearInterval(checkProgress);
							// Server side error - delete dynamoDB entries 
							// Handles Reydration
							dispatch(deleteItem(itemID)); 
							return;
					}
					return;
				})
				.catch(function (error) {
					clearInterval(checkProgress);
					dispatch(_dmAppErrorHandler(UPLOAD_ERROR, error))
					return error;
				});
		}, 5000);
	};
}

/**
 * Uploads a file to S3 using dmio_evaporate.js. 
 * @param {String} folderID of parent folder into which file is uploaded
 * @param {Object} file being uploaded, as HTML file
 * @param {Bolean} attach true or false, indicating whether or not to attach uploaded file to an LTI assignment
 */
export function uploadMediaItem (folderID, file, attach) {
	return function (dispatch, getState) {

		// Get needed variables
		const { account: { userID, usageData, planData, dateCreated } } = getState();

		//Get media file data
		let { fileNameBase, fileNameExtension, fileType, sizeMB } = getFileData(file)
		const fileName = `${fileNameBase}.${fileNameExtension}`;

		// ERROR CHECKS - set hasError to error string on error
		let hasError = false;

				// Check for valid file extensions
		if ('' === fileNameExtension || '' === fileNameBase)
			hasError = `Files must include a name and extension. Eg MyVideo.mp4`

		// Check MEDIA_BLACKLIST first - files that are interpretted as video/audio but not accepted
		else if (REGEXP.MEDIA_BLACKLIST.test(fileNameExtension)) hasError = `Files type of '${fileNameExtension}' not accepted.`
		else if ('VIDEO' !== fileType && 'AUDIO' !== fileType) {
			// Check MEDIA_WHITELIST - valid accepted video files which dont default to video type
			if (REGEXP.MEDIA_WHITELIST.test(fileNameExtension)) fileType = 'VIDEO';
			else hasError = "Only audio or video files accepted."
		}

		// Check if free plan - limit to 1 analysis projects if signup after 12AM GMT 24 October  (1508803200000)
		if (hasError === false && UNLIMITED.value !== planData.maxStorage &&
			PLANID.FREE[planData.planID] &&
			dateCreated > 1508803200000 &&
			1 <= usageData.videoCount + usageData.audioCount)
			hasError = "Ah, you'll need to upgrade to upload further analysis projects."


		// Check usageData.storageLimit (We're giving them an allowance of 2 MBs)
		if (hasError === false &&
			usageData.storageCount + sizeMB > planData.maxStorage + 2)
			hasError = "Unfortunately you don't have enough storage space for this file."

		// Throw error if present	
		if (hasError) {
			dispatch({
				type: ERROR_ADD,
				payload: hasError
			});
			return;
		}

			// INITIALIZE VARIABLES
		const partSize = 1024 * 1024 * 5; // This is the default, cannot be smaller
		const itemID = uuidV4();
		const s3FileName = new Date().getTime().toString();
		const path = `${userID}/${itemID}`;
		let thumbnailURL = `urlforaudiothumbs`;
		if ('VIDEO' === fileType) {
			(sizeMB >= 40) // Thumbnails retrieved at 60 sec intervals in live - 13 second video was 2.8MB; 
				? thumbnailURL = `${s3FileName}-thumbnail-00002.jpg`
				: thumbnailURL = `${s3FileName}-thumbnail-00001.jpg`;
		}

		// Create item now - to ensure item is created by the time Lambda picks it up
		let newItem = {
			itemID,
			folderID,
			mediaType: fileType,
			title: fileNameBase,
			thumbnailURL,
			mediaURL: `${s3FileName}.m3u8`,
			transcodeProgress: PROGRESS_FLAGS.UPLOADSTARTED
		};

		const mediaCount = fileType.toLowerCase() + 'Count';
		const updatedUsageData = {
			usageData: {
				storageCount: usageData.storageCount + sizeMB,
				[mediaCount]: usageData[mediaCount] + 1
			}
		}

		// Add LTI info if present
		// if (attach && getState()._.lti) {
		// 	const { lti } = getState()._
		// 	newItem = {
		// 		...newItem,
		// 		ltiAssignmentID: lti.ltiAssignmentID,
		// 		ltiAssignmentTitle: lti.ltiAssignmentTitle,
		// 		ltiConsumerID: lti.ltiConsumerID,
		// 		title: `${lti.ltiAssignmentTitle} - ${newItem.title}`
		// 	}
		// }

		dispatch({
			type: UPLOAD_INITIALIZING,
			payload: {
				id: itemID,
				name: fileName,
				progressFlag: PROGRESS_FLAGS.CALCULATING
			}
		});
		dispatch(setShow('uploadsInProgress', true))

		upload = Uploader.initialize({
			dispatch,
			bucket: TRANSIN_BCT,
			maxConcurrentParts: getState().app.queueSize,
			partSize: partSize,
			sizeMB,
			storeKey: `${DMIO_STORE_KEY}.${userID}.uploads`,
		});

		//console.log('Uploader Initialized')

		upload({
			bucket: TRANSIN_BCT,
			// bucket: 'bad_bucket_test',
			file,
			fileID: itemID,
			name: `${path}/${s3FileName}.${fileNameExtension}`,
			userID,

			// Creates new item
			createCallback: function (overrides = {}) {
				const { newItem, updatedUsageData} = this
				return function (dispatch) {
					dispatch(createItem({ ...newItem }))
						// Creation successful, update usage stats
						.then((resp) => {
							dispatch(accountRehydrate(updatedUsageData))
						});
				}
			}.bind({ newItem, updatedUsageData}),

			/// deleted item - currently not in use - impacts resumability by deleting bd record
			// deleteCallback: function (overrides = {}) {
			// 	const { itemID } = this;
			// 	const { resumedID } = overrides
			// 	return function (dispatch) {
			// 		console.log('CALLED deleteCallback ')
			// 		dispatch(deleteItem(resumedID ? resumedID : itemID))
			// 	}
			// }.bind({ itemID }),

			/**
			 * checks progress of copying & transcoding after upload
			 * NOTE: Although fileID is updated, need to bind this for future proofing against other uploader calls needing it
			 */
			preparationProgressCallback: function (overrides = {}) {
				const { itemID } = this;
				const { resumedID } = overrides
				return function (dispatch) {
					dispatch(_fileItemProgressPoll(resumedID ? resumedID : itemID))
				}
			}.bind({ itemID }),

			// resumes an alreadu initiated upload
			resumeCallback: function (overrides = {}) {
				const { itemID } = this;
				const { resumedID } = overrides
				return function (dispatch) {
					dispatch({ type: UPLOAD_NAME_CHANGE, payload: { oldID: itemID, resumedID } })
					dispatch(accountRehydrate(updatedUsageData))
					dispatch(getItem(resumedID))
				}
			}.bind({ itemID }),
		})
		.then(
			function (awsObjectKey) {
				// console.log('Upload success: ', awsObjectKey);
				// dispatch(setShow('uploadsInProgress', true))
			},
			function (err) {
				dispatch({
					type: UPLOAD_PROGRESS,
					payload: {
						id: itemID,
						name: fileName,
						progressFlag: PROGRESS_FLAGS.ERROR
					}
				});
			}
		)
	};
}
