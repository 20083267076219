/** ALL APP RELEVANT DATA
 * NOTES:
 * If the data is a huge map, rather store in its own state object. EG - templates
 */
import {getQueueSize} from '../utils/bandwidth';
import {
    CUNAUTH_USER,
    PLANS_FETCH, 
	SET_BOTTOMBAR_HEIGHT,
	SET_SHOW,
	SET_SIDEBAR_WIDTH,
    SET_QUEUE_SIZE, 
} from 'Constants/actions';

const INITIAL_STATE = {
	dmioPlans: null,
	bottombarHeight: 230,
	queueSize: getQueueSize(),
	show: {
		uploadsInProgress: true
	}
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

		// unauths the user and removes all redux state
		case CUNAUTH_USER:
			return INITIAL_STATE;
        
        case PLANS_FETCH:
			return {
				...state,
                dmioPlans: action.payload.details,
                dmioPlanNames: action.payload.planNames
			};
        
        case SET_BOTTOMBAR_HEIGHT:
            return {
				...state,
				bottombarHeight: action.height
			};
		
		case SET_QUEUE_SIZE:
			return {
				...state,
				queueSize: action.queueSize
			};
		
		case SET_SHOW:
			// payload is {key: [key_to_set], value: value_to_set}
			const { key, value } = action.payload
			return {
				...state,
				show: {
					...state.show,
					[key]: value
				}
			}

		
		case SET_SIDEBAR_WIDTH:
			return {
				...state,
				sidebarWidth: action.width
			}

        default:
			return state;
	}

	return state;
}
