import React from 'react'

const ArrowButton = props => (
	<button
    onClick={props.handleClick}
    className={props.className}>
    <i 
      className={`far fa-angle-${props.direction} ${props.size}`} />
  </button>
)

export default ArrowButton;