import _ from 'lodash';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {I18n} from '@aws-amplify/core';

import {TransitionGroup, CSSTransition} from 'react-transition-group';
import withDragDropContext from 'Widgets/withDragDropContext.jsx';

import LandingSidebar from './Landing.Sidebar.jsx';
import LandingSubhead from './Landing.Subhead.jsx';
import {Loading} from 'Widgets/Loading.jsx';
import PageLanding from '../Layout/page.landing.jsx';
import ProjectThumb from './Project.Thumb.jsx';
import ProjectUploader from './Project.Uploader.jsx';
import ProjectImporter from './Project.Importer.jsx'

import Copyright from 'Widgets/Copyright.jsx';
import Message from 'Widgets/Message.jsx';
import ModalConfirmAction from './Modal.Confirm.Action.jsx'
import ModalInvite from 'Modals/Modal.Invite.jsx';
import ModalInfo from 'Widgets/Modal.Info.jsx';
import ModalZoomImport from 'Modals/Modal.Zoom.Import.jsx'
import ModalDashboardSelector from 'Modals/Modal.Dashboard.Selector.jsx';
import ModalMarkerSelector from 'Modals/Modal.Marker.Selector.jsx';

import { INVITE_TYPES, ROUTES, UNLIMITED } from 'Constants/app'

import {
	closeModal,
	dismissMessage,
	openModal,
} from 'actions/app';

import {
	fetchFolders,
    selectItem,
	createFolderItem,
	updateItem
} from './actions/';

class Landing extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			displayModal: false
		};
	}

	static contextTypes = {
		router: PropTypes.object,
		history: PropTypes.object
	};

	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext() {
		return { closeModal: this.onCloseModal };
	}

	onCloseModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.closeModal();
	};

	componentDidMount () {
		this.props.fetchFolders(this.props.match.params.folderID)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.modalOpen === undefined) return;
		this.setState({ displayModal: nextProps.modalOpen.name });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.folderID !== this.props.match.params.folderID) {
			this.props.fetchFolders(this.props.match.params.folderID);
			this.props.dismissMessage();
		}
	}

	componentWillUnmount() {
		this.props.closeModal();
		this.props.dismissMessage();
	}

	render() {
        let { loading } = this.props.folders;
		let {
			folderID,
			folderItem,
            childItems,
			organizationID,
            userError,
		} = this.props;

        // Prepare folder details
        let folderTitle = null;
		let previousFolderLink = '';
		if (folderID != 0 && folderItem !== undefined) {
			folderTitle = loading || folderItem.title;
			var {ltiCourseAssignments, ltiCourseTitle} = folderItem;
			previousFolderLink = (
				<Link
					to={`${ROUTES.LANDING}/${folderItem.folderID}`}
					data-toggle="tooltip"
					title={I18n.get('Back')}
					className="btn btn-outline-secondary"
					onClick={() => {
						this.props.selectItem(null);
					}}>
					<i className="fas fa-long-arrow-left" />
				</Link>
			);
		}

        // Prepare & order folder contents - ie, childItems
		let orderedItems = [];
		_.forEach(childItems, function(value, key, object) {
			orderedItems.push(value);
		});
		orderedItems = _.orderBy(orderedItems, 'dateCreated', 'desc');
		

		let renderedItems = this.renderItems(orderedItems, ltiCourseAssignments, ltiCourseTitle)


		return (
			<PageLanding folderTitle={folderTitle} >
				{this.renderModal()}

				<div className="dashboard" onClick={this.handleClickLanding}>
					<Copyright />

					<LandingSubhead
						previousFolderLink={previousFolderLink}
						handleCreateFolder={this.handleCreateFolder}
						parentFolder={folderItem}
					/>

					<div className="dashboard-content">
						<div className="project-thumbs">
						{ loading 
						? <Loading size="lg" />
						: <div className="container">
							{this.renderPermanentMessages()}
							{this.renderMessage(null, userError)}
							{this.renderMessages()}
							<div className="row project-thumbs-transition-group">
								<TransitionGroup>
									{renderedItems}
									<CSSTransition in appear={true} timeout={1000} classNames="project-thumb-fade">
										<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" onDragOver={e=> e.stopPropagation() } >
											<ProjectUploader folderID={folderID} deselectItem={this.handleClickLanding} />			
										</div>
									</CSSTransition>
 									<CSSTransition
										in
										appear={true}
										timeout={1000}
										classNames="project-thumb-fade">
										<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
											<ProjectImporter folderID={folderID} deselectItem={this.handleClickLanding} />
										</div>
									</CSSTransition>
								</TransitionGroup>
							</div>
						</div>
						}
						</div>
					</div>
					<LandingSidebar />
				</div>
			</PageLanding>
		);
	}
	
	renderItems = (orderedItems, ltiCourseAssignments, ltiCourseTitle) => {
		let {
			initials,
			selectedItem,
			userID,
		} = this.props;

		const selected = (item) => {
			if (selectedItem === null) return false;
			return item.itemID === selectedItem.itemID;
		}

		
		return ( orderedItems.map(item => (
			<CSSTransition
				in
				appear={true}
				exit={false}
				timeout={500}
				key={item.itemID}
				classNames="project-thumb-fade">

				<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
					key={`itm-thm-${item.itemID}`}>
					<ProjectThumb
						handleTitleUpdate={this.handleTitleUpdate}
						handleKeyValueToggle={this.handleKeyValueToggle}
						handleThumbClicked={this.handleThumbClicked}
						ltiParentCourseTitle={ltiCourseTitle || false}
						ltiParentCourseAssignments={ltiCourseAssignments || false}
						openItem={this.handleItemOpen}
						openNoticeModal={this.handleOpenNoticeModal}
						initials={initials}
						item={item}
						itemID={item.itemID}
						selected={selected(item)}
						userID={userID}
						key={item.itemID}
					/>
				</div>

			</CSSTransition>
		)))
	}
    
	renderMessage(message, error) {
		if (message) {
			return (
                <Message message={message} location='folders' textClass='success'/>
			);
		} else if (error) {
			return (
				<Message message={error} location="folders" />
			);
		}
    }

    renderMessages = () => {
        const {message, error} = this.props.messages
        return (
            <Message message={message} textClass={error ? 'danger' : 'success'}/>
        );
	}

	/** Renders a modal if present */
	renderModal = () => {
		const data = this.props.modalOpen.data || null

		switch(this.state.displayModal) {
			case 'modalConfirmAction': return <ModalConfirmAction />;
			case 'modalDashboardSelector': return <ModalDashboardSelector />;
			case 'modalInvite': return <ModalInvite inviteType={INVITE_TYPES.COLLABORATE } />;
			case 'modalMarkerSelector': return <ModalMarkerSelector />
			case 'modalNotice': return <ModalInfo title={data.title} info={data.info} />
			case 'modalSend': return <ModalInvite inviteType={INVITE_TYPES.SEND} />
			case 'modalZoomImport': return <ModalZoomImport folderID={this.props.match.params.folderID} />
			
			default: return null;			
		}
	}
	
    renderPermanentMessages = () => {
		const {planData, usageData} = this.props
		if(UNLIMITED.value !== planData.maxStorage && usageData.storageCount > planData.maxStorage) 
			return (
				<Message textClass='info'
					style={{fontStyle: 'unset'}}
					dismissable={false}>
					{I18n.get('Your Dreamaker.io storage is full.')}{' '}
					<Link to={ROUTES.ACCOUNT} 
						className='text-info'
						style={{textDecoration: 'underline'}}>{I18n.get('View your usage')}</Link>{' '}{I18n.get('and')}{' '}
					<Link to={ROUTES.UPGRADE} 
						className='text-info'
						style={{textDecoration: 'underline'}}>{I18n.get('upgrade your plan')}</Link>{' '}
						{I18n.get('or delete files.')}
				</Message>
			);
    }

    /**
     * Updates Item
     * @param {String} id is the Item.itemId to update
     * @param {String} key item[key] in Dynamo & state to update
     * @param {Object} value value with which item[key] will be updated
     */
	handleItemUpdate = (itemID, key, value) => {
        this.props.updateItem(itemID, key, value);
    };
    
    handleTitleUpdate = (e, itemID) => {
		e.preventDefault();
		e.stopPropagation();
        this.setState({ isEditing: false});

        const { title } = this.props.childItems[itemID];
        let titleInput = e.target.value

        titleInput = _.trim(titleInput);
        
        // Reset to initial title if blank
		if (titleInput === '' ) {
            e.target.value = title
			return;
		}

        // Update titleInput here incase string was trimmed
        e.target.value = titleInput
        
		// API Update title if not same as old title
		if (titleInput !== title) {
			this.handleItemUpdate(itemID, 'title', titleInput);
		}
    }

    /**
     * Toggles key value between true and false
     * Key obtained from e.target.id 
     */
    handleKeyValueToggle = (e, itemID) => {
        const action = e.target.id;
        const item = this.props.childItems[itemID];

        // Confirm disabling of locked/confidential
        if( item[action] ) {
            this.props.openModal(
                'modalConfirmAction', 
                itemID, 
                {
                    action, 
                    onConfirm: this.handleItemUpdate
                });
            return;
        }

        this.handleItemUpdate( 
            itemID, 
            action, 
            true
        )
    }

	handleClickLanding = e => {
        // sets selectedItem and selectedItemAnalysis to null
        if (this.props.selectedItem !== null) this.props.selectItem(null);
	};

	handleThumbClicked = (itemID, itemAnalysesKey=0) => {
        this.props.selectItem(this.props.childItems[itemID], itemAnalysesKey);
	};

    
	handleCreateFolder = e => {
		e.preventDefault();		
		this.props.createFolderItem(this.props.folderID);
        if (this.props.selectedItem !== null) this.props.selectItem(null);
	};

	handleItemOpen = (itemID, itemAnalysesKey=0) => {
        const item = this.props.childItems[itemID]
		if ('FOLDER' === item.mediaType) {
			this.props.history.push(`${ROUTES.LANDING}/${itemID}`);
            this.props.selectItem(null);
		} else {
            this.props.selectItem(item);
			this.props.history.push(`${ROUTES.ANALYSIS}/${ item.itemAnalyses[itemAnalysesKey].itemAnalysisID }`);
		}
    };

    handleOpenNoticeModal = e => {
        let notice = { 
            title: I18n.get('Privacy Guard Notice'),
            info: I18n.get('This project contains confidential data')
        }
        if(e.target.id === 'locked') 
            notice = { 
                title: I18n.get('This project is locked'),
                info: I18n.get('The owner of this project has locked it')
            }
        this.props.openModal(
            'modalNotice', '',
            notice
        )
    }
    

}

function mapStateToProps(state) {
	const {account, folderItems, folders, user} = state
	return {
		childItems: folderItems.data,             //Children of current folder
        folders:    folders,                      //loading, error, message
        
        folderID:   folders.data.folderID,        //id of parent folder is not itemID=0
        folderItem: folders.data.folderItem,      //details of current folder if its not itemID=0
        
        selectedItem:  folderItems.selectedItem,  //Currently selected item, if any
        selectedItemAnalysis: folderItems.selectedItemAnalysis,  //Currently selected item, if any
        
		initials:	user.data.initials,
        userError:  user.error,
		userID:     user.data.userID,

		organizationID: account.organizationID,
		planData:   account.planData,
		usageData: 	account.usageData,

        modalOpen: state.modalOpen,
		messages: state.messages,
	};
}

const mapDispatchToProps = {
    closeModal,
	createFolderItem,
    dismissMessage,
	fetchFolders,
    openModal,
    selectItem,
	updateItem,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withDragDropContext)(Landing);

// export default LandingFinal;