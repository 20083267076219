import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import withDragDropContext from 'Widgets/withDragDropContext.jsx';

import { dismissMessage, openModal } from 'actions/app'
import { updateDashboard } from "./actions";
import DashboardTags from "./Dashboards.Middlebar.DashboardTags.jsx";
import UserTags from "./Dashboards.Middlebar.UserTags.jsx";
import DashboardDefault from "./Dashboards.Middlebar.Dashboard.Default.jsx";

class DashboardsMiddlebar extends Component {
	constructor(props) {
		super(props);

		this.state = { title: "", note: "" };
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		const { selectedDashboard, allDashboards } = nextProps;
		if (selectedDashboard === null) return null;
		const dashboardID = selectedDashboard.dashboardID;
		// grabs the dashboards data from state, so it will update the note and title correctly
		const dashboardObjFromState = allDashboards[dashboardID];
		// This helps get rid of the error we're receiving when the selected dashboard is deleted.
		if (dashboardObjFromState === undefined) return;
		this.setState({
			title: dashboardObjFromState.title,
			note: dashboardObjFromState.note
		});
	}

	render () {
		return (
			<div className="dbedit-content-subhead">
				<div className="dbedit-content-messages-div">
					{this.renderMessages()}
				</div>
				<DashboardTags
					handleSelectTag={this.props.handleSelectTag}
					selectedDashboard={this.props.selectedDashboard}
				>
					{/* pass down the title element */}
					{this.renderTitle()}
				</DashboardTags>
				<div className="dbedit-content-alltags">
					<div className="dbedit-content-alltags-title">
						<h6>Dashboard Tags</h6>
					</div>
					<UserTags handleSelectTag={this.props.handleSelectTag} />
				</div>
			</div>
		);
	}

	renderMessages = () => {
		const { message, error } = this.props.messages
		return (
			<p id="dbedit-content-messages"
				className={`text-${error ? 'danger' : 'success'}`}>
				{message}
			</p>
		);
	};

	// renders the title and note fields for the selected dashboard
	renderTitle = () => {
		// if the selected dashboard is null, we will return only the "select a dashboard" text
		if (this.props.selectedDashboard === null) return null;

		return (
			<div className="dbedit-content-dbtags-inputs">
				<div>
					<div className="dbedit-content-dbtags-inputs-title">
						<h6 id="h6-small">Title</h6>
						<input
							maxLength="34"
							name="title"
							onBlur={this.handleSave}
							onKeyDown={this.handleKeyDown}
							onChange={this.handleChange}
							value={this.state.title}
						/>
					</div>
					<DashboardDefault
						handleDefaultSelect={this.toggleDefaultSelect}
						selectedDashboard={this.props.selectedDashboard} />
					{/*<div className="dbedit-content-dbtags-inputs-note">
                  <h6 id="h6-small">Note</h6>
                  <input
                     name="note"
                     onBlur={this.handleSave}
                     onChange={this.handleChange}
                     value={this.state.note}/>
               </div>*/}
				</div>
				<button id="delete-button"
					className="btn btn-outline-secondary"
					onClick={() => this.props.openModal('dashboard', this.props.selectedDashboard.dashboardID, { deleteDependency: false })}
				>
					Delete Dashboard
            </button>
			</div>
		);
	};


	// handles the change of input
	handleChange = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	// if key pressed is enter, will save the field.
	handleKeyDown = e => {
		if (e.keyCode === 13) { return this.handleSave(e) }
	}

	handleSave = e => {
		const { name, value } = e.target;
		const { selectedDashboard, allDashboards } = this.props;
		const dashboardID = selectedDashboard.dashboardID;
		// grabs the dashboards data from state, so it can correctly check if the new value is equal to the old value.
		const dashboardObjFromState = allDashboards[dashboardID];
		// if the updated value is the same as the previous value (based on redux obj),
		// we will return without saving
		if (dashboardObjFromState[name] === value) return;
		// checks if the field is empty, if it is,
		// will set it back to it's previous state (based on redux obj) and then not save any changes
		if (_.trim(value) === "") {
			this.setState({ [name]: dashboardObjFromState[name] });
			return;
		}
		// updates the dashboards information
		this.props.updateDashboard(dashboardID, { [name]: value });
	};

	toggleDefaultSelect = () => {
		const { allDashboards, selectedDashboard } = this.props
		let biggestDefaultSortOrder = 0;
		// need to find the biggest default sort ordered number in order to correctly set the defaultSortOrder
		Object.keys(allDashboards).forEach(item => {
			// if it's not set as a default, we don't care about it.
			if (!allDashboards[item].default) return;
			if (biggestDefaultSortOrder < allDashboards[item].defaultSortOrder) {
				biggestDefaultSortOrder = allDashboards[item].defaultSortOrder
			}
		})
		// toggles the dashboards default status.
		// NOTE: needs a defaultSortOrder, otherwise it gets upset. Maybe need to figure out a better way of setting this up in API?
		this.props.updateDashboard(
			selectedDashboard.dashboardID,
			{
				default: !selectedDashboard.default,
				defaultSortOrder: biggestDefaultSortOrder + 1
			}
		);
	}
}

function mapStateToProps (state) {
	return {
		allDashboards: state.userDashboards.data,
		dashboardInfo: state.userDashboards,
		messages: state.messages
	};
}

const mapDispatchToProps = {
	openModal,
	updateDashboard
};

const DashboardsMiddlebarFinal = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDragDropContext
)(DashboardsMiddlebar);

export default DashboardsMiddlebarFinal;