// Dev Paola
module.exports = exports = {

    // Cognito
    Region:         'us-west-2',
    UserPoolId:     'us-west-2_YDQc2tqjm', 
    ClientId:       '53sj74f35956sunlkg1aqkmup', 
    Paranoia:       7,
    IdentityPoolId: 'us-west-2:b07b251e-55e2-4192-8d24-6a77d7783971', 

    // S3
	BucketRegion:   'us-west-2',
	FileinBct:      'devpaola-filein-dmio',
    TransinBct:     'devpaola-transin-dmio',
    TransoutBct:    'devpaola-transout-dmio',
    TransoutUrl:    'https://transout.devpaola.dreamaker.io/',
    TransoutSUrl:   'https://transouts.devpaola.dreamaker.io/',
    LookinBct:      'devpaola-lookin-dmio',
    LookoutBct:     'devpaola-lookout-dmio',
    //LookoutUrl:     'https://lookout.devpaola.dreamaker.io/',
    LookoutUrl:     'https://s3-us-west-2.amazonaws.com/devpaola-lookout-dmio/avatars/',
    BandwidthTest:  'https://s3-us-west-2.amazonaws.com/devpaola-transin-dmio/bandwidth_test.png',

    // API
    ApiHost:        'https://api.devpaola.dreamaker.io/',

    // URL
    WebappUrl:      'https://devpaola.dreamaker.io/',
	
	// LTT
	MoodleToolUrl:	'https://api.devpaola.dreamaker.io/lti/cartridge',

    // 3RD Party
    StripeAPIKey: "pk_test_Ew4pbiOnveh9sh0X5TjQIRGs"    
}