import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

import {ROUTES, PLANID} from 'Constants/app';
import {openModal, closeModal} from 'actions/app';

import {getBillingDetails} from 'actions/index';

import {
	accountPlanCancel,
	paymentReducerClearMessages,
} from './actions/payment';

import PageSettings from './Layout/page.settings.jsx';
import { ChatNow } from 'Widgets/ChatNow.jsx';
import { ContactUs } from 'Widgets/ContactUs.jsx';
import ModalChangePlan from './Modal.Change.Plan.jsx'
import SliderWrapperText from 'Widgets/Slider.Wrapper.Text.jsx';
import {Loading} from 'Widgets/Loading.jsx';

const lowerCased = word => word.toLowerCase();

class UpgradePage extends Component {
	static childContextTypes = {
		closeModal: PropTypes.func
	};

	getChildContext() {
		return { closeModal: this.onCloseModal };
	}

	constructor(props) {
		super(props);
		this.state = {displayModal: false, isChecked: true};
	}

	UNSAFE_componentWillMount() {
		// Prevent organizational users from Upgrading
		if (this.props.isOrganization !== null) this.props.history.push(`${ROUTES.LANDING}/0`);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// used to open / close the modal
		this.setState({displayModal: nextProps.modalOpen.name});
	}

	componentWillUnmount() {
		this.props.closeModal();
		this.props.paymentReducerClearMessages();
	}

	render() {
		const {billingDetails, dmioPlans} = this.props;
		const {displayModal} = this.state;

		return (
			<PageSettings>
                <div className="col-sm-12">
					<div className="main settings">
						<div className="settings-head">
							<h1>A Plan for Every Need</h1>
						</div>

						<div className='settings-body settings-upgrade'>
							<h4 className='settings-align-center'>Level-up with with collaboration, more storage & dashboards, and other awesome features!</h4>

							{null === billingDetails || null === dmioPlans 
							? 	<Loading icon="far fa-circle-notch fa-spin" message="Loading..." />	
							: 	this.renderUpgradePageBody()
							}

						</div>
					</div>
                </div>
				{displayModal === 'ModalChangePlan' ? <ModalChangePlan /> : null }
			</PageSettings>
		);
	}


	// Renders main page body with plans
	renderUpgradePageBody = () => {
		const {isChecked} = this.state;

		return (
			<div>
				<SliderWrapperText
					isChecked={isChecked}
					handleClick={this.handleClick}
					textLeft={'MONTHLY'}
					textRight={'YEARLY (SAVE 10%)'}
				/>

				{/* {this.renderMessages()} */}

				<div className="row settings-upgrade-plans">
					{this.renderPlans()}
				</div>

				<div className="upgrade-notes">
					<p>
						<strong>
							Interested in our <a href='https://dreamaker.io/pricing' target='_blank'>Enterpise Plans</a> or looking for something different? 
						</strong>&nbsp;&nbsp;
						<ContactUs email="hello@dreamaker.io" subject="We're Upgrading and Looking for Something More"
						>Drop us an email</ContactUs> or <ChatNow />.
					</p>
					<p>
						<strong>
							Check out our{' '}
							<a href="https://dreamaker.io/pricing#faq" target="_blank">
								Frequently Asked Questions
							</a>{' '}
							for more information on all features.
						</strong>
					</p>
					<p>
						<span>
							<strong>GET THE STUDENT DEAL</strong>
						</span>{' '}
						Students save 30% on any plan, pay as little as $8.40 a month.{' '}
						<i>
							<ContactUs email="hello@dreamaker.io" subject="Sign me up for the 840 Student Deal!"
							>Email</ContactUs> or <ChatNow />
						</i>{' '}
						to get started!
					</p>
					{/* <div style={{textAlign: 'center', margin: '3.2rem'}}>
					<i style={{color: 'darkgrey'}}>Not ready to select a plan?</i>
					<br />
					<br />
					<h4 className="text-info">Try out Dreamaker.io for free</h4>
					<p>
						1 Analysis Project, 1 Dashboard, 1 Collaborator, 2 GB Storage, free
						forever
					</p>
				</div> */}
				</div>
			</div>
						
		)
	}

	// Redners table with Dmio plans
	renderPlans = () => {
		const {dmioPlans} = this.props;
		// don't try to render if no plan data is present
		if (dmioPlans === null) return;

		const {isChecked} = this.state;

		// Define users plan data to be passed to renderUpgradeButton()
		const {account, billingDetails} = this.props;
		
		const selectedPaymentPeriod = isChecked ? 'YEARLY' : 'MONTHLY';
		const currentPlan = {
			planID: account.planType,
			title: account.planData.title,
			paymentPeriod: account.paymentPeriod
		};
		const selectedPeriodIsCurrentPeriod = currentPlan.paymentPeriod === selectedPaymentPeriod;

		// USER'S UPCOMING PLAN DETAILS
		const upcomingInvoice = billingDetails.upcomingInvoice || {};
		const selectedPeriodIsUpcomingPeriod = selectedPaymentPeriod === upcomingInvoice.paymentPeriod;

		// undefined === upcomingInvoice.cancel_at_period_end when there is no upcomingInvoice or plan has been changed
		const hasUpcomingInvoice =
			true === upcomingInvoice.cancel_at_period_end ||
			undefined === upcomingInvoice.cancel_at_period_end
				? false
				: true;

		const usersPlanData = {
			billingDetails,
			currentPlan,
			hasUpcomingInvoice,
			selectedPaymentPeriod,
			selectedPeriodIsCurrentPeriod,
			selectedPeriodIsUpcomingPeriod,
		}
		//


		const backgroundColor = {
			0: 'red',
			1: 'green',
			2: 'gray'
		};

		return dmioPlans.map((item, i) => {
			var {planID, title, additionalLimits} = item;

			const costYearly = item.costYearly / 12;
			const color = backgroundColor[i];

			let price = 'Free';
			let priceTag = '';
			if (!PLANID.FREE[planID]) {
				price = isChecked ? `$${costYearly}` : `$${item.costMonthly}`;
				priceTag = isChecked ? `/Mth, $${item.costYearly} billed annually` : '/Mth';
			}

			// creates class for buttons
			const buttonClass = classNames({
				btn: true,
				'settings-upgrade-plan-btn': true,
				[`settings-upgrade-plan-btn-${color}`]: true
			});

			const containerClass = `col-sm-6 col-md-3 settings-upgrade-plan ${color}`;
			const iconClass = `fa fa-check ${color}`;
			const upgradeButton = this.renderUpgradeButton(item, buttonClass, usersPlanData);

			return (
				<div key={title + i} className={containerClass}>
					<h2 className={color}>{item.title}</h2>
					<div className="settings-upgrade-plan-content">
						<p>{item.description}</p>
						<div className="settings-upgrade-plan-price">
							<span className={color}>{price}</span>
							<small>{priceTag}</small>
						</div>

						<div className="settings-upgrade-plan-button">{upgradeButton}</div>

						<hr />
						<small className={color}>
							<strong>ESSENTIAL FEATURES</strong>
						</small>

						<ul>
							<li>
								<i className={iconClass} />
								{item.maxProjects}
							</li>
							<li>
								<i className={iconClass} />
								{item.maxStorage / 1000} GB Storage
							</li>
							<li>
								<i className={iconClass} />
								{item.maxDashboards} Analysis Dashboards
							</li>
                            {PLANID.FREE[planID] ? null : (
							<li>
								<i className={iconClass} />
								{item.maxCollaborators} Collaborators
							</li>
                            )}
						</ul>
						{PLANID.FREE[planID] ? null : (
							<div>
								<hr />
								<small className={color}>
									<strong>ADVANCED FEATURES</strong>
								</small>
								<ul className="advanced">
									{additionalLimits.notes ? (
										<li>
											<i className={iconClass} />Project Notes
										</li>
									) : (
										''
									)}
									{additionalLimits.export ? (
										<li>
											<i className={iconClass} />Export Analysis
										</li>
									) : (
										''
									)}
									{additionalLimits.confidential ? (
										<li>
											<i className={iconClass} />Privacy Guard
										</li>
									) : (
										''
									)}
									{additionalLimits.chat ? (
										<li>
											<i className={iconClass} />
											{additionalLimits.chat === true
												? 'Chat Support'
												: additionalLimits.chat}
										</li>
									) : (
										''
									)}
									{additionalLimits.locked ? (
										<li>
											<i className={iconClass} />Project Lock
										</li>
									) : (
										''
									)}
									{additionalLimits.baa ? (
										<li>
											<i className={iconClass} />HIPAA BAA Ready
										</li>
									) : (
										''
									)}
								</ul>
								<hr />
								<div className="settings-upgrade-plan-button">{upgradeButton}</div>
							</div>
						)}
					</div>
				</div>
			);
		});
	};

	/**
     * Returns appropriate upgrade button - button text, handle, color.
     * TODO: These notes are incomplete - need finalizing after bugs fixed. See below:
     * Google Drive: https://docs.google.com/document/d/1TL2qAJNB8WDQvyi664XnKkPKIG91p3SMOxo6WhXmPlk/edit#
     * Slack: https://dreamakerio.slack.com/archives/D3K4BP0KB/p1536348951000100
     * 
     * NOTE: upcomingInvoice is present after trial ends & after plan cancelled
     * NOTE: upcomingInvoice.cancel_at_period_end is true or false, depending on if a cancellation is called
     * Anotomy of upcomingInvoice:
     * upcomingInvoice	{
     *      amount_due:	0
     *      date: 1536513817
     *      cancel_at_period_end: true   // true when plan is upcomingPlan - IE, cancellation at end of period. Not present on plan change
     *      plan: BASIC-2017-09-07
     * }
     */
	renderUpgradeButton = (buttonPlan, buttonClass, usersPlanData) => {

		/** PLAN IS FREE - RETURN NO BUTTON **/
		if (PLANID.FREE[buttonPlan.planID]) return;

		const {
			billingDetails,
			currentPlan,
			hasUpcomingInvoice,
			selectedPaymentPeriod,
			selectedPeriodIsCurrentPeriod,
			selectedPeriodIsUpcomingPeriod,
		} = usersPlanData;

		// BUTTON DEFAULTS
		let buttonTitle = 'Select Plan';
		let buttonDisabled = false;

		/** NOT PAYING CUSTOMER - IE - DONT HAVE CARD DETAILS
         * Note: billingDetails = {} if is no card details on stripe or no associated stripe customer
         * Note: For upgrades to any plan
         */
		if (undefined === billingDetails.card) {
			// return (
			// 	<Link
			// 		to={`${ROUTES.PAYMENT}#${lowerCased(
			// 			buttonPlan.title
			// 		)}-${selectedPaymentPeriod}`}>
			// 		<button className={buttonClass}>{buttonTitle}</button>
			// 	</Link>
			// )
			return (
				<button 
					className={buttonClass}
					
					onClick={() => this.handleOpenModal(buttonPlan)} 
					>
					{buttonTitle}
				</button>
			);
		}

		/** CUSTOMERS WITH CARD DETAILS AND... **/

		/**
         * CUSTOMERS WITH CARD DETAILS AND... CANCELLED TO FREE PLAN WITH STRIPE & BILLING CARD - IE PREVIOUSLY PAYING CUSTOMERS
         * Note: we also need to check if the account is on the free plan, if it is, we forward them to the payment page.
         * Note: Previously checked hasStripe & made sure has a card, but no longer needed
         */
		if (PLANID.FREE[currentPlan.planID]) {
			return (
				<button 
					className={buttonClass}
					onClick={() => this.handleOpenModal(buttonPlan)} >
					{buttonTitle}
				</button>
			);
		}

		const upcomingInvoice = billingDetails.upcomingInvoice || {};
		/**
         * CUSTOMERS WITH CARD DETAILS AND... BUTTON PLAN === CURRENT PLAN
         * Note: Button is disabled IF selectedPeriodIsCurrentPeriod or selectedPeriodIsUpcomingPeriod
         * Note: Doing a title check hear because planID's change
         */
		if (buttonPlan.title === currentPlan.title) {
			// CURRENT CUSTOMER
			if (hasUpcomingInvoice) {
                // BUTTON PLAN/PERIOD == CURRENT && UPCOMING'
				if (
					selectedPeriodIsCurrentPeriod ||
					(currentPlan.planID === upcomingInvoice.plan && selectedPeriodIsUpcomingPeriod)
				) {
					buttonTitle = 'Current Plan';
                    buttonDisabled = true;
                    
				// PLAN HAS CHANGED but BUTTON PLAN is CURRENT
				} else if (selectedPeriodIsCurrentPeriod) {
					buttonTitle = 'Current Plan';
					buttonDisabled = true;
                }
                
			// CANCELLATION COMING... 
			} else if (selectedPeriodIsCurrentPeriod) {
				buttonTitle = 'Current Plan';
				buttonDisabled = true;
            }
            
		// CUSTOMERS WITH CARD DETAILS AND... PLAN HAS CHANGED - BUTTON PLAN !== CURRENT PLAN && BUTTON PLAN IS UPCOMING 
		} else if (
			hasUpcomingInvoice &&
			selectedPeriodIsUpcomingPeriod &&
			buttonPlan.planID === upcomingInvoice.plan
		) {
			buttonTitle = 'Upcoming Plan';
			buttonDisabled = true;
		}

		return (
			<button
				disabled={buttonDisabled}
				className={buttonClass}
				onClick={() => this.handleOpenModal(buttonPlan)}>
				{buttonTitle}
			</button>
		);
	};


	handleClick = e => {
		this.setState({isChecked: !this.state.isChecked});
	};

	handleOpenModal = selectedPlan => {
		const {billingDetails} = this.props;
		const paymentPeriod = this.state.isChecked ? 'Yearly' : 'Monthly';
		// setting proration date here to keep it constant between calls to preview upgrade and make upgrade
		const prorationDate = Math.floor(Date.now() / 1000);

		this.props.openModal(
			'ModalChangePlan', null, {
				paymentPeriod,
				prorationDate,
				selectedPlan,
			});

		return;
	};
}

function mapStateToProps(state) {
	return {
		account: state.account,
		billingDetails: state.billingDetails,
		modalOpen: state.modalOpen,
		dmioPlans: state.app.dmioPlans,
		isOrganization: state.account.isOrganization || null
	};
}

const mapDispatchToProps = {
	closeModal,
	getBillingDetails,
	openModal,
	paymentReducerClearMessages,
	accountPlanCancel,
};

UpgradePage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UpgradePage);

export default UpgradePage;
