import React from 'react'
import ButtonIcon from './../Widgets/ButtonIcon.jsx';

const BackToTheFutureButton = ({btf, goBack}) => {
    return (
        <ButtonIcon
			btnClass="btn btn-outline-secondary btn-back"
			iconClass="fas fa-long-arrow-left"
			onClick={() => goBack()}
			label={btf ? ` Back to the Future` : ''}
			tooltip="Go back"
        />
    )
}

export default BackToTheFutureButton;