import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { INVITE_ERRORS, INVITE_TYPES, REGEXP, ROUTES } from 'Constants/app';
import { MESSAGE_ADD } from 'Constants/actions';

import Modal from 'Widgets/Modal.jsx';
// import EmailAddress from 'Widgets/Modal.jsx';
import Message from 'Widgets/Message.jsx';

import { messageToast } from 'actions/app';
import { sendItem, userInvite } from 'actions/share';

const _upgradeLink = <Link to={ROUTES.UPGRADE}>View plans and upgrade here.</Link>
const _accountLink = <Link to={ROUTES.ACCOUNT}>your plan's usage limit</Link>

const COMPONENT_COPY = {
	[INVITE_TYPES.COLLABORATE]: {
		modalTitle: 'Invite collaborators',
		modalSubTitle: 'Collaboration',
		modalSendBtn: 'Invite collaborators',
		privacyGuardAction: 'collaborate with'
	},
	[INVITE_TYPES.SEND]: {
		modalTitle: 'Send media to others',
		modalSubTitle: 'Send a copy',
		modalSendBtn: 'Send',
		privacyGuardAction: 'send a copy to'
	}
}

class ModalInvite extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};


	constructor(props) {
		super(props);
		this.state = {
			emails: '',
			// emails: 'xx9@dreamaker.io',
			// emails: 'test@nownow.co.za,paola@dreamaker.io',
			// emails: 'aaa@nownow.co.za, bbb@nownow.co.za',
			// emails: '1@nownow.co.za, 2@nownow.co.za, 3@nownow.co.za, 4@nownow.co.za, 5@nownow.co.za, 6@nownow.co.za, 7@nownow.co.za, 8@nownow.co.za, 9@nownow.co.za, 10@nownow.co.za, 11@nownow.co.za, 12@nownow.co.za, 13@nownow.co.za, 14@nownow.co.za, 15@nownow.co.za, 16@nownow.co.za, 17@nownow.co.za, 18@nownow.co.za, 19@nownow.co.za, 20@nownow.co.za, 21@nownow.co.za',
			err: '',
			loading: false,
			sendIncludeAnalysis: false
		};

		// Disable invite when zero collabs & no @org
		const { maxCollaborators, organizationID } = this.props;
		if (maxCollaborators === 0 && organizationID === null)
			this.collaborationDisabledMsg =
				<Link to={ROUTES.UPGRADE}>
					Collaboration is availble in upgraded plans. <i>Upgrade now.</i>
				</Link>
		else
			this.collaborationDisabledMsg = false;
		
		//Initialize component copy based on inviteType
		this.componentCopy = COMPONENT_COPY[this.props.inviteType]
		
	}

	// componentWillReceiveProps(nextProps) {
	// 	    this.setState({ data: nextProps.collaborator });
	// }

	// componentWillUnmount() {
	// 	// this.props.collaboratorSearchClear();
	// }

	// debounces the calling of the api
	// debounceCollaboratorSearch = _.debounce(term => this.props.collaboratorSearch(term), 200);

	render () {
		const { inviteType } = this.props;

		const loading = (this.props.loading || this.state.loading)

		const { modalSendBtn, modalSubTitle, modalTitle } = this.componentCopy
		switch (inviteType) {
			case INVITE_TYPES.COLLABORATE: {
				break;
			}

			case INVITE_TYPES.SEND: {
				const { sendIncludeAnalysis } = this.state;
				const sendIncludeAnalysisCheckBoxClass = classNames({
					'text-primary fal': true,
					'fa-square': !sendIncludeAnalysis,
					'fa-check-square': sendIncludeAnalysis
				});
				var child = (
					<p style={{ padding: '0 .6em', fontWeight: 400 }}
						onClick={() => this.handleSendIncludeItemAnalysisToggle()} >
						<i className={sendIncludeAnalysisCheckBoxClass} aria-hidden="true" />
						{'  '}Include analysis, project notes, attachments and transcriptions
					</p>
				)
				break;
			}

			// case INVITE_TYPES.SHARE: {
			//     var modalTitle = 'Share with others';
			//     var modalSubTitle = 'Sharing'
			//     break;
			// }  
		}

		const modalBodyClasses = classNames({
			'modal-body': true,
			'disabled': loading
		});

		return (
			<Modal>
				<div className="modal-header">
					<div className="modal-subtitle">{modalSubTitle}</div>
					<div className="modal-title">{modalTitle}</div>
				</div>
				<div className={modalBodyClasses} >
					<div className="row">
						<div className="col-12 text-left">
							{'' === this.state.err
								? this.collaborationDisabledMsg
									? <div><strong>{this.collaborationDisabledMsg}</strong></div>
									: <div><strong>People</strong></div>
								: this.renderMessage()
							}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="input-group">
								<textarea
									autoFocus
									disabled={loading || this.collaborationDisabledMsg}
									className="form-control"
									id='emails'
									name="emails"
									onChange={this.handleChange}
									onKeyDown={this.handleOnKeyDown}
									placeholder="Enter email addresses. Separate with comma or a new line..."
									style={{ overflow: 'hidden', height: '60px' }}
									type="text"
									value={this.state.emails}
								/>
							</div>
						</div>
					</div>
					{child
						? <div className="row">
							<div className="col-12">
								{child}
							</div>
						</div>
						: null
					}
					{/* <div className="row data-list-container" style={{padding: '.4em .9em 0 .9em'}}>
						<div className="col-12 list-over-flow">
							<ul className="list-group">
								<li
									className="list-group-item"
									key='1'
									onClick={() => alert('this.handleUpdate(collaboratorID, isChecked)')}>
									Joe Soap
									<i className='fal fa-check-square' aria-hidden="true" />
								</li>
								<li
									className="list-group-item"
									key='2'
									onClick={() => alert('this.handleUpdate(collaboratorID, isChecked)')}>
									Henriet soup
									<i className='fal fa-check-square' aria-hidden="true" />
								</li>
							</ul>
						</div>
					</div> */}
				</div>
				{this.state.emails === ''
					? <div className="modal-footer">
						<button
							className="btn btn-primary"
							data-dismiss="modal"
							disabled={loading}
							type="button"
							onClick={this.context.closeModal}>
							Done
                        </button>
					</div>
					: <div className="modal-footer">
						<button
							className="btn btn-success"
							disabled={loading || this.collaborationDisabledMsg}
							type="button"
							onClick={(e) => this.handleInvite(e, this.state.emails)}>
							{`${modalSendBtn} `}
							{loading ? <i className='far fa-spinner fa-spin spinner' /> : null}
						</button>
						<button
							className="btn btn-danger"
							disabled={loading}
							data-dismiss="modal"
							type="button"
							onClick={this.context.closeModal}>
							Cancel
                        </button>
					</div>
				}
			</Modal>
		);
	}

	handleChange = e => {
		this.setState({ err: '' });
		const target = e.target;
		this.setState({ emails: target.value });
		this.autosize();
	};

	handleSendIncludeItemAnalysisToggle = () => {
		this.setState({ sendIncludeAnalysis: !this.state.sendIncludeAnalysis })
	}

	// capture 'Enter' to submit invite
	handleOnKeyDown = e => {
		//TODO: if key code is enter or comma, check email and make it an <email />
		if (e.keyCode == 13) {
			e.preventDefault();
			this.handleInvite(e, this.state.emails)
			return
		}
	}

	//Autosizes text area
	autosize = () => {
		let el = document.getElementById('emails')
		setTimeout(() => {
			el.style.cssText = 'height:auto;';
			el.style.cssText = 'height:' + (10 + el.scrollHeight) + 'px';
		}, 0);
	}

	// Handles actual invite & logic
	handleInvite = (e, emails) => {
		this.setState({ err: '', loading: true });
		const {
			accountCollaborators,
			collaboratorCount,
			inviteType,
			maxCollaborators,
			organizationID,
			selectedItem
		} = this.props;

		// Clean emails
		const emailStr = emails;                            // for use in confidentiality confirm()
		emails = emails.toLowerCase();                      //lowercase
		emails = emails.replace(/(?:\r\n|\r|\n)/g, ',')    //replace newline with `,`
		emails = emails.replace(/\s+/g, '')                //remove any whitespace
		emails = emails.split(',')                          //ensure unique emails
		emails = _.uniq(emails)
		emails = emails.filter(String)                      //remove empties

		let newInviteCount = emails.length;

		// Limit to 20 invites at a time
		if (20 < newInviteCount) {
			this.setState({ err: `Only 20 people can be invited at a time.`, loading: false });
			return;
		}

		// Check emails are valid using const function
		let lastValidated = '';
		const validateEmail = (email) => {
			// Decrement newInviteCount if this email is a current collaborator
			if (accountCollaborators.includes(email)) newInviteCount = newInviteCount - 1;

			// Used for failure message
			lastValidated = email;

			// Test and return false on failure, email true on success
			if ((email.match(/@/g) || []).length > 1) return false;
			return REGEXP.EMAIL_VALID.test(email)
		}


		// If every email passes validateEmail,  proceed with invite
		if (emails.every(validateEmail)) {	

			// Confirm before send/invite if privacy guard set
			let invite = true
			if (selectedItem.confidential)
				invite = confirm(`PRIVACY GUARD ALERT\nThis item is marked as confidential. Are you sure you want to ${this.componentCopy.privacyGuardAction} these people?\n\n${emailStr}`);
			if (!invite) {
				this.setState({ loading: false });
				return
			}

			// If sending, do send goodies and return. Rest is INVITE specific
			if (INVITE_TYPES.SEND === inviteType) {
				this.props.sendItem(emails, this.state.sendIncludeAnalysis)
				return
			}

			// If invite is Collaboration, check enforce maxCollaborators limit
			if (INVITE_TYPES.COLLABORATE === inviteType) {

				// APPLY COLLABORATION LIMIT IF NOT IN ORGANIZATION
				if (null === organizationID && ((collaboratorCount + newInviteCount) > maxCollaborators)) {
					this.setState({
						err: <span>You've reached {_accountLink} of {maxCollaborators} collaborators. <br />{_upgradeLink}</span>,
						loading: false
					});
					return;
				}
			}

			this.props.userInvite(inviteType, emails, 'ANALYZE')
				/**
				 * resp.failures indicates any failures that may have occured
				 */
				.then((resp = {}) => {
					// CONSTRUCT UI MESSAGE
					let message = [];

					// FAILURES MESSAGE
					const failures = resp.failures || {}
					let failuresCnt = 0;
					for (let [key, value] of Object.entries(failures)) {
						// console.log(`${key}: ${value}`);
						if (failures[key].length > 0) {
							failuresCnt += failures[key].length;
							const failedEmails = value.join(', ');
							switch (key) {
								case INVITE_ERRORS.EXCEEDED: {
									if (null === organizationID)
										message.push(
											<span key='1' style={{ display: 'block' }}>
												{value.length} not invited - {failedEmails}: you've reached {_accountLink} of {maxCollaborators} collaborators.<br />{_upgradeLink}
											</span>);
									else
										message.push(
											<span key='1' style={{ display: 'block' }}>
												{value.length} not invited - {failedEmails} {value.length > 1 ? 'are not members' : 'is not a member'} of the <b><i>{organizationID}</i></b> organization and you have also reached {_accountLink} for non-organizational collaborators.<br />Contact your organization admin for more info.
											</span>);
									continue;
								}
								case INVITE_ERRORS.NOTIFICATION:
									message.push(<span key='2' style={{ display: 'block' }}>{value.length} invited but email notification failed: {failedEmails}</span>)
									continue;
								default:
									message.push(<span key='3' style={{ display: 'block' }}>{value.length} not invited: {failedEmails}</span>)
									continue;
							}
						}
					}

					// SUCCESS MESSAGE
					const successCnt = emails.length - failuresCnt
					const successMsgStr = `${successCnt} ` + (1 < successCnt || 0 === successCnt ? `people` : `person`) + ` invited. Recipients will be notified by email.`;

					// DISPLAY MESSAGE IN UI
					// All invites successful, close modal and display message in main page
					if (0 === failuresCnt) {
						this.props.messageToast(MESSAGE_ADD, <span>{successMsgStr}</span>, 4000);
						this.context.closeModal(e);
					}
					// Some invites failures, keep modal open and display errors in modal
					else {
						message.unshift(<span key='4' className='text-success' style={{ display: 'block' }}>{successMsgStr}</span>)
						this.setState({ emails: '', err: message, loading: false });
						this.autosize();
					}

					return;
				})
			//Errors handled by demioAppErr
			// .catch(err=>{});

			return;
		}

		this.setState({ err: `Whoops, invalid email address found: ${lastValidated}`, loading: false });
		return;
	};

	renderMessage = () => {
		return (
			<Message message={this.state.err} textClass="danger" dismissable={false} style={{ paddingBottom: 0 }} />
		);
	}
}

function mapStateToProps (state) {
	return {
		organizationID: state.account.organizationID || null,
		accountCollaborators: state.account.collaborators,                  //An array of userIDs/email addresses
		collaboratorCount: state.account.usageData.collaboratorCount,
		loading: state.collaborator.loading,
		maxCollaborators: state.account.planData.maxCollaborators,
		messages: state.messages,
		selectedItem: state.folderItems.selectedItem,
		selectedItemAnalysis: state.folderItems.selectedItemAnalysis,
		userEmail: state.user.data.email
	};
}

export default connect(
	mapStateToProps,
	{ messageToast, sendItem, userInvite }
)(ModalInvite);
