import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

import {DMIO_RELEASE} from 'config';

const propTypes = {
	src: PropTypes.string.isRequired,
	video: PropTypes.object
};

const hlsConfig = {
	// debug: true,
	// manifestLoadingTimeOut: 10000, 	// DEFAULT
	manifestLoadingMaxRetry: 3, // DEFAULT: 2
	// manifestLoadingRetryDelay: 1000, 	// DEFAULT
	// manifestLoadingMaxRetryTimeout: 64000, 	// DEFAULT
	// levelLoadingTimeOut: 10000, 	// DEFAULT
	// levelLoadingMaxRetry: 4, 	// DEFAULT
	// levelLoadingRetryDelay: 1000, 	// DEFAULT
	// levelLoadingMaxRetryTimeout: 64000, 	// DEFAULT
	// fragLoadingTimeOut: 20000, 	// DEFAULT
	// fragLoadingMaxRetry: 6, 	// DEFAULT
	// fragLoadingRetryDelay: 1000, 	// DEFAULT
	// fragLoadingMaxRetryTimeout: 64000, 	// DEFAULT
	// appendErrorMaxRetry: 3, 	// DEFAULT
	// nudgeOffset: 0.1 // DEFAULT, in seconds
	nudgeMaxRetry: 10, // DEFAULT is 3 

	xhrSetup: function(xhr, url) {
		xhr.withCredentials = true; // do send cookies
	}
};

export default class MediaSource extends Component {
	constructor(props, context) {
		super(props, context);
		this.hls = new Hls(hlsConfig);

		// Detect & handle when internet down/up
		Offline.on("down", this.hlsStopLoad);
		Offline.on("up", this.hlsStartLoad);
	}

	/**
	 * `src` is the property get from this component
	 * `video` is the property insert from `Video` component
	 * `video` is the html5 video element
	 */
	componentDidMount() {
		const { src, video } = this.props;

		let type = src.substring(1 + src.lastIndexOf('.'), src.length);
		switch (type) {
			case 'mp4':
				type = 'video/' + type;
				break;
			case 'mp3':
				type = 'audio/' + type;
				break;
			case 'm3u8':
				type = 'application/x-mpegURL';
				break;
			default:
				type = undefined;
		}

		console.log('Hls.isSupported: ', Hls.isSupported())
		if (Hls.isSupported() && 'application/x-mpegURL' === type) {
			// This order according to hls.js docs (differs on video-react sample code)
			
			// ATTACH VIDEO TO HLS
			this.hls.attachMedia(video);	

			// HLS EVENT HANDLERS
			this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                // console.log('MP MEDIA_ATTACHED')
				this.hls.loadSource(src);
			});
			
			this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
				// console.log('MP MANIFEST_PARSED')
			});

			this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
				// console.log('MP MANIFEST_PARSED')
			});

			this.hls.on(Hls.Events.DESTROYING, () => {
				// console.log('MP DESTROYING')
			});
	
			/** ERROR HANDLING - see https://github.com/video-dev/hls.js/blob/master/docs/API.md#Errors
			 * data.type: 
			 * 		Hls.ErrorTypes.NETWORK_ERROR for network related errors
			 * 		Hls.ErrorTypes.MEDIA_ERROR for media/video related errors
			 * 		Hls.ErrorTypes.OTHER_ERROR for all other errors
			 * data.fatality: 
			 * 		false: if error is not fatal, hls.js will try to recover it
			 * 		true: if error is fatal, an action is required to (try to) recover it.
			 * data.details: see above link
			 */
			this.hls.on(Hls.Events.ERROR, (event, data) => {
				const fatal = data.fatal;
				console.log(`MediaPlayer ${fatal?'FATAL':'NON-FATAL'} ${data.type}: ${data.details}`, data) 
				// If fatal, throw error & try recover or destroy
				// this prevents video & manifest losing place & reloding on recoverable error.
				if(!fatal) {
					Rollbar.warning(`MediaPlayer NON-FATAL ERROR ${data.type}: ${data.details}` , {dmioRelease: DMIO_RELEASE, ...data});
					return
				}
				// this.props.mediaPlayerErrorHandler(data);
				switch(data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR: {
						Rollbar.warning(`MediaPlayer RECOVERABLE FATAL ${data.type}: ${data.details}` , {dmioRelease: DMIO_RELEASE, ...data});
						console.log(`MP Network error, trying to restart load...`)
						this.hls.startLoad();
						break;
					}
					case Hls.ErrorTypes.MEDIA_ERROR: {
						Rollbar.warning(`MediaPlayer RECOVERABLE FATAL ${data.type}: ${data.details}` , {dmioRelease: DMIO_RELEASE, ...data});
						console.log(`MP Media error, trying to swap & recover...`)
						this.hls.swapAudioCodec();
						this.hls.recoverMediaError();
						break;
					}
					default: {
						//TODO: Possibly recover at this point by recreating hls
						this.props.mediaPlayerErrorHandler(data);
						console.log(`MP Other error..`)
						// this.hls.destroy();
						
						break;
					}
				}
			});
		}
	}

	componentWillUnmount() {
		// Unregister internet down/up events
		Offline.off("down", this.hlsStopLoad);
		Offline.off("up", this.hlsStartLoad);
		this.hls.destroy();
	}

	render() {
		return (
			<source
				// src={srced}
				src={this.props.src}
				type={this.props.type}
			/>
		);
	}

	// stop hls from loading frags
	hlsStopLoad =() => {
		console.log('Offline: Stopping load')
		this.hls.stopLoad();
	}

	// start hls loading frags
	hlsStartLoad =() => {
		console.log('Online: Starting load')
		this.hls.startLoad();
		// console.log('Online... Recovering load.')
		// this.hls.recoverMediaError();		
	}
}

MediaSource.propTypes = propTypes;