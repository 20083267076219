import _ from 'lodash';

import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatStorage } from 'utils/';

import { Loading } from 'Widgets/Loading.jsx';
import Modal from 'Widgets/Modal.jsx';
import ModalZoomImportDropdown from './Modal.Zoom.Import.Dropdown.jsx'
import Message from 'Widgets/Message.jsx';

import {
	dismissMessage,
} from 'actions/app';

import {
	zoomClearRecordings,
	zoomImportRecording,
	zoomFetchRecordings,
	zoomInitiateAuthorization,
	zoomRevokeAuthorization,
} from '../Landing/actions/zoom'


class ModalZoomImport extends Component {
	static contextTypes = {
		closeModal: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			importChat: true,
			loading: false,
			sort: {
				key: 'start_time',
				order: 'desc'
			},
		}
	}

	componentDidMount () {
		/**
		 * load zoom recordings. 
		 * NOTE: We do this each time component mounts to ensure the list is fresh
		**/

		const {
			zoomRecordings,
		} = this.props;

		// If Zoom not authorized, redirection to zoom occurs via zoomFetchRecordings
		if (null === zoomRecordings) {
			this.props.zoomFetchRecordings()
				.catch(err => {
					this.setState({ loading: false })
				})
		}
	}

	componentWillUnmount () {
		/**
		 * Clear Zoom List
		 * NOTE: We do this each time component unmounts to ensure the list is fresh
		**/
		this.props.zoomClearRecordings()
		this.props.dismissMessage();
	}

	render () {
		const { importChat, loading } = this.state
		const { zoomRecordings } = this.props;

		return (
			<Modal size="modal-xl">
				<div className="modal-header">
					<div className="modal-subtitle">{I18n.get('Zoom Integration')} </div>
					<div className="modal-title">Import Your Zoom Cloud Recordings</div>
				</div>
				<div className="modal-body">
					<div className="row data-list-container" style={{ padding: '.4em 1em 0em 1em' }}>
						<div className="col-12">
							{this.renderMessage()}
							{/* style={{ display: 'flex', justifyContent: 'space-between' }} */}
							<div>
								<a onClick={this.toggleImportChat} style={{ paddingRight: '10px' }} href=''>
									Import Chat
									<i className={`text-primary fas fa-${importChat ? 'check-square' : 'square'}`} style={{ paddingLeft: '10px' }} /></a>
								{'|'}
								<a onClick={e => { this.triggerApiCall(e, this.props.zoomFetchRecordings) }} style={{ paddingLeft: '10px' }} href=''>
									Refresh List<i className='fas fa-redo' style={{ paddingLeft: '10px', paddingRight: '5px' }} /></a>
							</div>

							{null === zoomRecordings || loading
								? <Loading />
								: this.renderRecordingsList()
							}

						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						data-dismiss="modal"
						onClick={this.context.closeModal}>
						{I18n.get('Done')}
					</button>
				</div>
			</Modal>
		);
	}

	renderRecordingsList () {
		const { zoomRecordings: { meetings } } = this.props;

		let sort = this.state.sort;
		let recordingsList = null;
		if (undefined !== meetings && 0 < meetings.length) {
			let orderedRecordings = meetings;
			// if (sort.key == '') orderedRecordings = meetings;
			// else orderedRecordings = _.orderBy(meetings, [sort.key, 'topic'], [sort.order]);
			orderedRecordings = _.orderBy(meetings, [sort.key, 'topic'], [sort.order]);

			recordingsList = orderedRecordings.map((meeting, i) => {
				const { topic, id, start_time, total_size, recording_files } = meeting;
				const processing = "processing" === recording_files[0].status
				return (
					<tr key={i} >
						<td>{topic}</td>
						<td>{id}</td>
						<td>{new Date(start_time).toLocaleString().replace(/(\:[0-9][0-9])(?!.*\:[0-9][0-9])/, '')}</td>
						<td>{processing ? <i>Processing</i> : formatStorage(total_size, 'BYTE')}</td>
						<td style={{ overflow: 'visible', paddingLeft: 0, paddingRight: 0 }}>
							{'completed' === recording_files[0].status
								? <ModalZoomImportDropdown
									disabled={false}
									files={recording_files}
									importRecording={this.importRecording}
									topic={topic}
									start_time={start_time}
								/>
								: null
							}

						</td>
					</tr>
				)
			})
		}

		const tableStyle = {
			gridTemplateColumns:
				'minmax(300px,	3fr) ' +
				'minmax(95px, 	1fr) ' +
				'minmax(150px, 	1.2fr) ' +
				'minmax(75px, 	0.7fr) ' +
				'minmax(25px, 	0.6fr) '
		}

		return (
			<div className="list-over-flow" style={{paddingBottom: '3rem'}}>
				<table style={tableStyle} data-sort="table">
					<thead >
						<tr>
							<th data-type="text-long" className={`${sort.order}${'topic' === sort.key ? 'Sort' : null}`} >
								<button className='btn-link' onClick={e => this.onHeaderClick(e, 'topic')}>Topic</button>
								{/* <span className="resize-handle" /> */}
							</th>
							<th data-type="numeric" className={`${sort.order}${'id' === sort.key ? 'Sort' : null}`} >
								<button className='btn-link' onClick={e => this.onHeaderClick(e, 'id')}>ID</button>
								{/* <span className="resize-handle" /> */}
							</th>
							<th data-type="text-short" className={`${sort.order}${'start_time' === sort.key ? 'Sort' : null}`} >
								<button className='btn-link' onClick={e => this.onHeaderClick(e, 'start_time')}>Start Time</button>
								{/* <span className="resize-handle" /> */}
							</th>
							<th data-type="text-long" className={`${sort.order}${'total_size' === sort.key ? 'Sort' : null}`} >
								<button className='btn-link' onClick={e => this.onHeaderClick(e, 'total_size')}>Total Size</button>
								{/* <span className="resize-handle" /> */}
							</th>
							<th data-type="text-short" >
								{' '}
							</th>
						</tr>
					</thead>
					<tbody>
						{recordingsList}
					</tbody>
				</table>
			</div>
		)
	}

	toggleImportChat = (e) => {
		e.preventDefault();
		this.setState({ importChat: !this.state.importChat })
	}
	
	triggerApiCall = (e, api) => {
		e.preventDefault();
		this.props.dismissMessage();
		this.setState({ loading: true })
		api()
			.then(resp => {
				this.setState({ loading: false })
			})
			.catch(err => {
				this.setState({ loading: false })
			})
	}

	renderMessage = () => {
		const { message, error } = this.props.messages
		if (message)
			return (
				<Message textClass={error ? 'danger' : 'success'} dismissable={false}>{message}</Message>
			);
	}

	/** 
	 * Imports Recording
	 * User messages returned via global props.messages
	 */
	importRecording = (file) => {
		return this.props.zoomImportRecording(this.props.folderID, { ...file, importChat: this.state.importChat})
			.then(data => {
				return Promise.resolve();
			})
			.catch(err => {
				return Promise.reject();
			})
	};

	/** Sorts members table by key */
	onHeaderClick = (e, key) => {
		e.stopPropagation();
		e.preventDefault();
		this.setState({
			sort: {
				key,
				order: 'asc' === this.state.sort.order ? 'desc' : 'asc'
			}
		})
	}

}

function mapStateToProps (state) {
	return {
		messages: state.messages,
		zoomRecordings: state.zoom,
	};
}

export default connect(
	mapStateToProps,
	{
		dismissMessage,
		zoomClearRecordings,
		zoomImportRecording,
		zoomFetchRecordings,
		zoomInitiateAuthorization,
		zoomRevokeAuthorization,
	}
)(ModalZoomImport);
