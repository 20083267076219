import _ from 'lodash';
import {
	CUNAUTH_USER,
	// EVAPORATE,
	UPLOAD_PROGRESS,
	UPLOAD_NAME_CHANGE,
	UPLOAD_INITIALIZING,
} from 'Constants/actions';

const INITIAL_STATE = {
	uploading: false,		// true druing upload is in progress AND file is being prepared.
	status: {},
	evaporate: false, 		// contains evaporate object
	evaporating: false,		// true when evaporate is true AND evaporate.filesInProcess > 0 (IE - when files are actually being uploaded)
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {

        // unauths the user and removes all redux state
		case CUNAUTH_USER:
			return INITIAL_STATE;

		// NOTE: Must always be called first, by calling function NOT uploader.js
		// because it initializes the state.uploads and state with upload ID
		case UPLOAD_INITIALIZING: {
			// payload is { id, name, progressFlag }
			const { id, name, progressFlag } = action.payload;

			// Add to or create array of uploads in progress
			const uploads = state.uploads || []
			if (!uploads.includes(id))
				uploads.push(id)
			
			return {
				...state, 
				uploads,
				[id]: {
					name,
					percentage: 0,
					stats: {},
					progressFlag,
				}
			}
		}

		// called  with all frogress from uploader.js
		case UPLOAD_PROGRESS: {
			// payload is { id, name, percentage, stats, progressFlag }
			const { id } = action.payload;

			return {
				...state, 
				[id]: {
					...state[id],
					...action.payload
				}
			}
		}
			
		// ID of an upload has changed - called from uploader.js
		// should never be called wihtout state.uplaods[oldID] and state[uploads] being present
		case UPLOAD_NAME_CHANGE: {
			// payload is { oldID, resumedID }
			const { oldID, resumedID } = action.payload;

			var uploads = state.uploads;
			var newUploadInfo = state[oldID]

			_.remove(uploads, function (remove) { return remove === oldID; })
			uploads.push(resumedID)

			return {
				..._.omit(state, oldID),
				uploads,
				[resumedID]: {
					...newUploadInfo,
					id: resumedID,
				}
			}
		}
			
		// case EVAPORATE: {
		// 	// set evaporate
		// 	return { 
		// 		...state, 
		// 		evaporate: action.payload, 
		// 		evaporating: action.payload && action.payload.filesInProcess.length > 0 ? true : false 
		// 	}
		// }
	}

	return state;
}
