import classNames from 'classnames';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IMAGES } from 'Constants/app';
const {MOODLE_LOGO} = IMAGES;

import { inviteMember, revokeMember } from '../Account/Members/actions/index'
import { openModal } from 'actions/app'
import Divider from 'Widgets/DropdownDivider.jsx';
import MenuBtn from 'Widgets/MenuButton.jsx';

class ProjectThumbLtiDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false,
		};
	}

	render() {
		const { showDropdown } = this.state || true;
		const { 
			ltiAttachmentStatus, 			// only present on AUDIO/VIDOE
			ltiCourseTitle, 				// only present on FOLDER
			ltiParentCourseAssignments 		// we're in an LTI course folder
		} = this.props

		const ltiLogoClasses = classNames ({
			'lti-logo': true,
			'greyscale': ('ATTACHED' !== ltiAttachmentStatus && !ltiCourseTitle) && ltiParentCourseAssignments 		// Greyed out for not attached, not a coourseFolder and inside an lti parent folder
		})

		return (
			<div className="dropdown show" 
				onMouseLeave={this.handleDDLeave}
				onContextMenu = {e =>this.consumeClick(e)}
				onDoubleClick = {e =>this.consumeClick(e)} >
				<img
					className={ltiLogoClasses}
					onClick={(e) => this.setState({ 
						showDropdown: !showDropdown,
						cssLeft: e.pageX,
						cssTop: e.pageY })} 
					src={MOODLE_LOGO} 
				/>
				{showDropdown && this.renderedButtons()}
			</div>
		);
    }
    
	// Defines buttons will be rendered only if the dropdown show state is true.
	renderedButtons = () => {
		const {
			isAnalysisOwner,
			ltiAssignmentID,
			ltiAssignmentTitle,
			ltiAttachmentStatus, 	// false if folder, otherwise ATTACHED, DETACHED, NONE
			ltiCourseTitle, 		// if present, inidcates this a course folder
			ltiParentCourseAssignments,
			ltiParentCourseTitle,
		} = this.props;

		const detachBtn = 
			isAnalysisOwner && ('ATTACHED' === ltiAttachmentStatus)
			? <MenuBtn
					iconClass='file-times fa-lg text-danger'
					label={`Remove from activity '${ltiAssignmentTitle}'`}
					onClick={e => { this.handleOpenModal(e, 'detach', {ltiAssignmentTitle} ) }}
				/>
			: null;

		var attachBtns = []
		if(isAnalysisOwner && ltiParentCourseAssignments) {
			Object.keys(ltiParentCourseAssignments).forEach((keyAssignmentID) => {
				if(keyAssignmentID === ltiAssignmentID ) return;
				attachBtns.push(
					<MenuBtn
						key={keyAssignmentID}
						iconClass='file-plus  fa-lg  text-success'
						label={`Add to activity '${ltiParentCourseAssignments[keyAssignmentID].ltiAssignmentTitle}'`}
						onClick={e => { 
							this.handleOpenModal(e, 'attach', {
								ltiAssignmentID: keyAssignmentID, 
								ltiAssignmentTitle: ltiParentCourseAssignments[keyAssignmentID].ltiAssignmentTitle
							})}}
					/>					
				)
			});
		}
		
		const coursePrefix = <b>COURSE<br /></b>
		// renders all the options in a dropdown menu
		const menuStyle = {
			cursor: isAnalysisOwner ? 'pointer' : 'default', 
			// top: `${this.state.cssTop}px`, 
			// left: `${this.state.cssLeft || -25 }px`
			}

		return (
			<div className="dropdown-menu dropdown-menu-right" style={menuStyle} >

				{/* INFO DISPLAY: COURSE NAME AND TITLE */}
				{ltiCourseTitle 		? <div className='dropdown-header'>{coursePrefix}{ltiCourseTitle}</div>  : null }
				{ltiParentCourseTitle 	? <div className='dropdown-header'>{coursePrefix}{ltiParentCourseTitle}</div>  : null }

				{/* INFO DISPLAY: ATTACHED: ASSIGNMENT NAME  */}
				{'ATTACHED' === ltiAttachmentStatus ? <div className='dropdown-header'><b>ACTIVITY<br /></b>{ltiAssignmentTitle}</div>  : null }

				{/* INFO DISPLAY: NOT OWNER - DISPLAY DIVIDER */}
				{(ltiCourseTitle || ltiParentCourseTitle) && (!isAnalysisOwner && ('NONE' === ltiAttachmentStatus)) ? <Divider id="2" /> : null }
				
				{/* IS OWNER DISPLAY DIVIDER BEFORE CALLS TO ACTIONS  */}
				{/* {isAnalysisOwner && ((attachBtns || detachBtn) && !ltiCourseTitle) ? <Divider id="3" /> : null } */}
				{!ltiCourseTitle && isAnalysisOwner && ((attachBtns || detachBtn)) ? <Divider id="3" /> : null }

				{/* INFO DISPLAY: NOT ATTACHED */}
				{'NONE' === ltiAttachmentStatus 
					? <div className='dropdown-header'><b>Media not associated with an activity</b></div> 
					: null 
				}
				
				{/* CALL TO ACTION DISPLAY: DETACH / ATTACH */}
				{'ATTACHED' === ltiAttachmentStatus 
					? detachBtn 
					: attachBtns.length > 0 ? attachBtns : null }
			</div>
		);
	};
	
	consumeClick = (e) => {
		e.preventDefault(); 
		e.stopPropagation()
	}

	handleDDLeave = () => {
		this.setState({
			showDropdown: false
		});
	};

	handleOpenModal = (e, action, lti) => {
		e.preventDefault();
		this.setState({showDropdown: false});
		this.props.openModal(
			'modalConfirmAction', 
			this.props.itemID, 
			{ action, lti }
		);
	};
}

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, { inviteMember, revokeMember, openModal })(
	ProjectThumbLtiDropdown
);
